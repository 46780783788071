angular.module('deitz')
    .factory('checkFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var checkFactory = {};

        //Include validation helper
        var vh = validation_helper();

        checkFactory.validations = {
            label: {
                maxlength: 100,
                required: true,
            },
            description: {
                maxlength: 254,
                required: true
            },
            check_number:{
                maxlength: 254,
                required: true
            },
            payment_date:{
                required: true
            },
            new_check:{
                maxlength: 254,
                required: true
            },
            check_amount:{ 
                patterns: vh.numeric.patterns,
                required: true
            }              
        };

        checkFactory.messages = {
            label: {
                required: "Title is required.",
                maxlength: "Title not be upto 100 characters."
            },
            description: {
                maxlength: "Description must be at least 6 characters.",
                required: "Description is required."
            },
            check_number:{
                maxlength: "Reference number must be at least 6 characters.",
                required: "Reference number is required."
            },
            payment_date:{
                required: "Payment date is required."
            },
            new_check:{
                maxlength: "Reference number must be at least 6 characters.",
                required: "Reference number is required."
            },
            check_amount:{
                patterns: "Amount must be number.",
                required: "Amount is required."
            }
        };

        return checkFactory;
    }]);
