angular.module('deitz').controller('clientLevelController', [
    '$scope',
    '$rootScope',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$compile',
    '$uibModal',
    'Notification',
    '$filter',
    '$http',
    'apiUrl',
    function ($scope,
        $rootScope,
        DTOptionsBuilder,
        DTColumnBuilder,
        $compile,
        $uibModal,
        Notification,
        $filter,
        $http,
        apiUrl
    ) {

        $scope.dtInstance = {};

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        $scope.actionRoles = function (data, type, full) {
            return "<a class='btn btn-primary btn-circle' ui-sref='code_tables.client_level_edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";

        };

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                $rootScope.showLoader(true);
                // map your server's response to the DataTables format and pass it to
                $http.get(apiUrl + '/api/get_client_level')
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        callback(response);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        if (err.error)
                            $scope.reloadData();
                    });

            })
                      
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'desc'])
            .withOption('createdRow', $scope.createdRow)

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('level').withTitle('Level').withOption('searchable', true),
           // DTColumnBuilder.newColumn('days').withTitle('Days').withOption('searchable', true),
            DTColumnBuilder.newColumn('description').withTitle('Description').withOption('searchable', true),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles)
        ];


    }]);