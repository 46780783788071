
angular.module('deitz').controller('witnessController', [
    '$scope', '$rootScope', 'diaryFactory', 'commonFactory', '$state', 'Notification', '$uibModalInstance', 'witnessdata','JOB_NO',

    function(
        $scope, $rootScope, diaryFactory, commonFactory, $state, Notification, $uibModalInstance, witnessdata, JOB_NO
    ){

        $scope.witness = {Suffix:null};
        $scope.Witnesstitle = "Add Witness Detail";
        $scope.formSubmit = false;
        $scope.witness_name = [];
        $scope.witness_title = [{ id: 0, label: 'Dr' }, { id: 1, label: 'Mr' }, { id: 2, label: 'Mrs' }, { id: 3, label: 'Ms' }];
        $scope.witness_suffix = [];
        $scope.diarywitnesstype = [];

        $scope.validations = diaryFactory.validations;
        $scope.messages = diaryFactory.messages;

        //Getting all witnessname.
        commonFactory.get('/api/getWitnessname')
            .success(function(response) {
                if (response.result && response.result.witness_name) {
                    $scope.witness_name = response.result.witness_name || '';
                }
            })
            .error(function(err) {});

        //Getting all diary witnesstypes.
        commonFactory.get('/api/witnesstypes')
            .success(function(response) {
                if (response.result && response.result.witnesstype) {
                    $scope.diarywitnesstype = response.result.witnesstype || '';
                }
            })
            .error(function(err) {});

        //Getting all Suffixs.
        commonFactory.get('/api/getsuffixs')
            .success(function(response) {
                if (response.result && response.result.suffixs) {
                    $scope.witness_suffix = response.result.suffixs || '';
                }
            })
            .error(function(err) {});

        $scope.addWitness = function(witness, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var data = $scope.witness;
                data.JOB_NO = JOB_NO;
                commonFactory.post('/api/addwitnesstodiary', data)
                    .success(function(response) {
                        if (response) {
                            if (response.status == 200 && response.result && response.result.witness_info) {
                                if(witnessdata.length > 0){
                                    witnessdata.push(response.result.witness_info);
                                }
                                $uibModalInstance.close();
                                $rootScope.showLoader(false);
                                // swal('success!', 'Witness added successfully', 'success');
                            } else {
                                $rootScope.showLoader(false);
                                $uibModalInstance.close();
                            }
                        }
                    })
                    .error(function(err) {});
            }
        }
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
