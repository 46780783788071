angular.module('deitz')
    .controller('editnotesController', ['$scope', '$rootScope', 'firmFactory', '$state', '$uibModal', 'note_value', 'index', 'notes_data', '$uibModalInstance', function($scope, $rootScope, firmFactory, $state, $uibModal, note_value, index, notes_data, $uibModalInstance) {

        $scope.note = {};
        $scope.title = "Edit Note";
        $scope.formSubmit = false;

        $scope.validations = firmFactory.validations;
        $scope.messages = firmFactory.messages;

        if (note_value && note_value.status == 200 && note_value.data && note_value.data.result) {
            $scope.note = note_value.data.result.note;
        }

        $scope.addNote = function(note, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                firmFactory.post('/api/updateattorneynote', $scope.note)
                    .success(function(response) {
                        if (response.status == 200 && response.result && response.result.noteUpdate) {
                            if(response.result.noteUpdate.created_at){
                                response.result.noteUpdate.created_at = new Date(response.result.noteUpdate.created_at);
                            }
                            else{
                                response.result.noteUpdate.created_at = new Date();
                            }
                            notes_data[index] = response.result.noteUpdate;
                            $uibModalInstance.close();
                            swal('Success!', 'Note updated successfully', 'success');
                        }
                    })
                    .error(function(err) {});
            }
        }

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
