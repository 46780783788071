angular.module('deitz').controller('addspecialtiesController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal','$http', 'apiUrl', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification,$http, apiUrl , fromModal, $uibModalInstance
    ) {

        $scope.specialties = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Specialties';
        $scope.modalTitle = 'Add plaintiff/defendant'
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        commonFactory.get('/api/casetypes').then(function(response) { 
            $scope.casetypes = response.data.result.casetype;  
        });
        $scope.add = function(specialties, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/specialties', $scope.specialties)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("Specialties added successfully");

                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.specialties.id);
                                }else{
                                    $state.go("code_tables.specialties_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
