angular.module('deitz')
			.controller('elawStatusController', [
					'$scope',
					'$rootScope',
					'Upload',
					'$timeout',
					'Notification',
                    'apiUrl',
						function(
							$scope,
							$rootScope,
							Upload,
							$timeout,
							Notification,
                            apiUrl
						){

		$scope.formData = {};
        $scope.formSubmit = false;
        $scope.invalidFileType = 'false';
        $scope.file_name = "";
        $scope.url = apiUrl;

        $scope.submitForm = function(isValid) {
            $scope.formSubmit = true;
            $scope.file_name = "";
            if (isValid && $scope.elawStatusForm.lawFile.$valid && $scope.lawFile) {
                var tmpName = $scope.lawFile.name.split('.');
                var fileType = tmpName[tmpName.length-1];
                if(fileType != 'xls' && fileType != 'xlsx'){
                    $scope.invalidFileType = 'true';
                } else{
                    $rootScope.showLoader(true);
                    $scope.invalidFileType = 'false';
                    Upload.upload({
                        url: 'api/elaw/update-dispositions',
                        data: {elawFile: $scope.lawFile}                    
                    }).then(function (resp) {
                        $scope.elawStatusForm.$setPristine(true);
                        $scope.elawStatusForm.$setUntouched(true);
                        $scope.lawFile = "";
                        $rootScope.showLoader(false);
                        if(resp.data.error){ 
                            Notification.error(resp.data.result.message);
                        }else{
                            $scope.file_name = resp.data.result.fileName; 
                            Notification.success(resp.data.result.message);
                        }
                    }, function (resp) { 
                        $scope.resetForm();
                        Notification.error("File data inappropriate..!");
                        $rootScope.showLoader(false); 
                    });
                }

            }
        };

}]);