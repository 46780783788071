angular.module('deitz')
    .controller('scopistController', [
        '$scope',
        '$rootScope',
        '$state',
        'representativeFactory',
        'Notification',
        '$filter',
        '$sce',
        function($scope,
                 $rootScope,
                 $state,
                 representativeFactory,
                 Notification,
                 $filter,
                 $sce
                 ){

        //Model object that contains model data
        
        $scope.validations = representativeFactory.validations;
        $scope.messages = representativeFactory.messages; 
        $scope.scopistData = {};
        $scope.scopistForm = false;
        $scope.reptypehistory = [];        
        $scope.scopistData.start_date = moment().format('MM/DD/YYYY');
        $scope.alternateEmailValid = true;

        $scope.validateEmails = function(data) {
            var emails = data.target.value.split(/,|;/);
            if(emails == ""){
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
                return
            }
            
            var isValid = true;
            for (var i = 0; isValid && i < emails.length; i++) {
              if (!validateEmail(emails[i])) {
                isValid = false;
                $scope.alternateEmailValid = false;
                $scope.alternate_emails_error = 'Email ' + emails[i] + ' does not appear to be a proper email';
                console.log($scope.alternate_emails_error,"E");
              }
              else{
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
              }
            }
          
          }
          function validateEmail(email) {
            var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
            return EMAIL_REGEXP.test(email.trim());
          }

        $scope.tooltip = $sce.trustAsHtml('In Order To Print A Physical Check Print Check Needs To be Set To - YES' + '<br>' + '-------------------' + '<br>' + 'In Order To Create An Electronic Payment Print Check Needs To be Set To - NO');

        if ($state.params && $state.params.id) {
            $scope.title = 'Edit Scopist';
        } else {
            $scope.title = 'Add Scopist';
        }

        //Previous and Next page button control
        if ($state.params && $state.params.id) {
            $scope.goto_rate = function() {
                $state.go('representative.scopist.step_two', { id: $state.params.id });
            }
            $scope.goto_other = function() {
                $state.go('representative.scopist.step_three', { id: $state.params.id });
            }
            $scope.goto_address = function() {
                $state.go('representative.scopist.step_one', { id: $state.params.id });
            }
        } else {
            $scope.goto_rate = function() {
                $state.go('representative.scopist.step_two');
            }
            $scope.goto_other = function() {
                $state.go('representative.scopist.step_three');
            }
            $scope.goto_address = function() {
                $state.go('representative.scopist.step_one');
            }

            if($state.current.name !== 'representative.scopist.step_one' && ( typeof $scope.scopistData.first_name == 'undefined' || $scope.scopistData.first_name=='' )){
                $state.go('representative.scopist.step_one');
            }

        }

        //Save method for saving (SCOPIST) data into database.
        //UPdate method for updating (SCOPIST) data into DB if get ID.
        if ($state.params && $state.params.id) {
            $scope.save = function() {
                $scope.signupForm = true;
                //Add Representative type 'SC' For Representative type Scopist into DB
                $scope.scopistData.rep_typ = 'SC';
                $scope.scopistData.notary_expiration_date = moment($scope.scopistData.notary_expiration_date).format('YYYY-MM-DD');
                $scope.scopistData.curr_paydt = moment($scope.scopistData.curr_paydt).format('YYYY-MM-DD');

                var id = $state.params ? $state.params.id : '';
                $rootScope.showLoader(true);
                representativeFactory.put('/api/reptype/' + id, $scope.scopistData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptypeUpdate) {
                            $rootScope.showLoader(false);
                            Notification.success("Scopistdata updated successfully.");
                            // notify({
                            //     message: 'Scopistdata updated successfully.',
                            //     classes: 'alert alert-success',
                            //     templateUrl: 'views/common/notify.html',
                            //     duration: 100
                            // });
                            $state.go("representative.browse");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        }
        //Add method for adding (SCOPIST) data into DB if not Getting ID.
        else {
            $scope.save = function() {
                $scope.salesForm = true;
                //Add Representative type 'SC' For Representative type Scopist into DB
                $scope.scopistData.rep_typ = 'SC';
                $scope.scopistData.notary_expiration_date = moment($scope.scopistData.notary_expiration_date).format('YYYY-MM-DD');
                $scope.scopistData.curr_paydt = moment($scope.scopistData.curr_paydt).format('YYYY-MM-DD');

                $rootScope.showLoader(true);
                representativeFactory.post('/api/reptype', $scope.scopistData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptype) {
                            $rootScope.showLoader(false);
                            Notification.success("Scopistdata added successfully.");
                            // notify({
                            //     message: 'Account Executive added successfully.',
                            //     classes: 'alert alert-success',
                            //     templateUrl: 'views/common/notify.html',
                            //     duration: 100
                            // });
                            $state.go("representative.browse");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        }

        //Get Method for Getting (Account Executive) data into EDIT MODE.
        var id = $state.params ? $state.params.id : '';
        if (id) // Make sure we are in edit mode.
        {
            representativeFactory.get('/api/reptype/' + id + '/edit')
                .success(function(response) {
                    if (response && response.result && response.result.reptypeInfo) {

                        $scope.scopistData.title = response.result.reptypeInfo.TITLE;
                        $scope.scopistData.NAME_KEY = response.result.reptypeInfo.NAME_KEY;
                        $scope.scopistData.first_name = response.result.reptypeInfo.FIRST_NAME;
                        $scope.scopistData.last_name = response.result.reptypeInfo.LAST_NAME;
                        $scope.scopistData.Emai_Address = response.result.reptypeInfo.Emai_Address;
                        $scope.scopistData.alternate_emails = response.result.reptypeInfo.alternate_emails;
                        $scope.scopistData.phone = response.result.reptypeInfo.PHONE;
                        $scope.scopistData.addr_line1 = response.result.reptypeInfo.ADDR_LINE1;
                        $scope.scopistData.city = response.result.reptypeInfo.CITY;
                        $scope.scopistData.state = response.result.reptypeInfo.STATE;
                        $scope.scopistData.zip = response.result.reptypeInfo.ZIP;
                        $scope.scopistData.soc_sec_no = response.result.reptypeInfo.SOC_SEC_NO;
                        $scope.scopistData.cell = response.result.reptypeInfo.CELL;
                        $scope.scopistData.addr_line2 = response.result.reptypeInfo.ADDR_LINE2;

                        $scope.scopistData.rate_1 = response.result.reptypeInfo.RATE_1;
                        $scope.scopistData.rate_2 = response.result.reptypeInfo.RATE_2;
                        $scope.scopistData.medicalrate = response.result.reptypeInfo.MedicalRate;
                        $scope.scopistData.copy_rt = response.result.reptypeInfo.COPY_RT;
                        $scope.scopistData.appear = response.result.reptypeInfo.APPEAR;
                        $scope.scopistData.pay_stips = response.result.reptypeInfo.PAY_STIPS;
                        $scope.scopistData.rate_e = response.result.reptypeInfo.RATE_E;
                        $scope.scopistData.rate_d = response.result.reptypeInfo.RATE_D;
                        $scope.scopistData.rate_i = response.result.reptypeInfo.RATE_I;

                        $scope.scopistData.late_app = response.result.reptypeInfo.LATE_APP;
                        $scope.scopistData.payment_type = response.result.reptypeInfo.payment_type;
                        $scope.scopistData.notary_number = response.result.reptypeInfo.NOTARY_NUMBER;
                        $scope.scopistData.notary_expiration_date = response.result.reptypeInfo.NOTARY_EXPIRATION_DATE;
                        $scope.scopistData.rate_l = response.result.reptypeInfo.RATE_L;
                        $scope.scopistData.curr_pay = response.result.reptypeInfo.CURR_PAY;
                        $scope.scopistData.prev_pay = response.result.reptypeInfo.PREV_PAY;

                        $scope.scopistData.notes_1 = response.result.reptypeInfo.NOTES_1;
                        $scope.scopistData.routing_number = response.result.reptypeInfo.ROUTING_NUMBER;
                        $scope.scopistData.account_number = response.result.reptypeInfo.ACCOUNT_NUMBER;
                        $scope.scopistData.p1099 = response.result.reptypeInfo.P1099;
                        $scope.scopistData.curr_paydt = response.result.reptypeInfo.CURR_PAYDT;
                        $scope.scopistData.ytd_pay = response.result.reptypeInfo.YTD_PAY;
                        $scope.scopistData.status = response.result.reptypeInfo.STATUS;
                        $scope.scopistData.ytd_pgs = response.result.reptypeInfo.YTD_PGS;
                        $scope.scopistData.notes_2 = response.result.reptypeInfo.NOTES_2;
                        $scope.scopistData.rate_changed_by = response.result.reptypeInfo.RATE_CHANGED_BY;
                        $scope.scopistData.rate_change_date = response.result.reptypeInfo.RATE_CHANGE_DATE;
                        $scope.scopistData.rate_change_reason = response.result.reptypeInfo.RATE_CHANGE_REASON;
                        $scope.scopistData.start_date = response.result.reptypeInfo.created_at ? moment(response.result.reptypeInfo.created_at).format('MM/DD/YYYY hh:mm:ss a') : '--/--/---';


                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        }

        // if ($state.params && $state.params.id) {
        //     $scope.isDisabled = true;
        //     $scope.$watch('scopistData.rate_change_reason', function(newValue, oldValue) {
        //         if (newValue == null || newValue == '' || newValue == 'undefined' || newValue == undefined || (newValue && newValue.length == 0)) {
        //             $scope.isDisabled = true;

        //         } else {
        //             $scope.scopistData.rate_changed_by = $scope.Authuser.name;
        //             $scope.scopistData.rate_change_date = $filter('date')(new Date(),'yyyy-MM-dd');
        //             $scope.isDisabled = false;
        //         }
        //     });
        // }

        //Disabled Rate change Reason related fields.
        if ($state.params && $state.params.id) { //EDIT MODE
            $scope.isDisabled = true;
            $scope.$watch('scopistData.rate_change_reason', function(newValue, oldValue) {
                if (newValue == null || newValue == '' || newValue == 'undefined' || newValue == undefined || (newValue && newValue.length == 0)) {
                    $scope.isDisabled = true;

                } else {
                    $scope.scopistData.rate_changed_by = $scope.Authuser.name;
                    $scope.scopistData.rate_change_date = $filter('date')(new Date(), 'yyyy-MM-dd');
                    $scope.isDisabled = false;
                }
            });
        } else {
            $scope.isDisabled = false;
            $scope.$watch('scopistData.rate_change_reason', function(newValue, oldValue) { //ADD MODE
                if (newValue != null || newValue != '' || newValue != 'undefined' || newValue != undefined || (newValue && newValue.length != 0)) {
                    $scope.scopistData.rate_changed_by = $scope.Authuser.name;
                    $scope.scopistData.rate_change_date = $filter('date')(new Date(), 'yyyy-MM-dd');
                }
            });
        }

        //Get Method for Getting (SCOPIST) History data into EDIT MODE.
        var id = $state.params ? $state.params.id : '';
        if (id) // Make sure we are in edit mode.
        {
            representativeFactory.get('/api/reptype/' + id)
                .success(function(response) {
                    if (response && response.result && response.result.reptypehistory) {
                        $scope.reptypehistory = response.result.reptypehistory || '';
                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        }

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {

                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if($state.current.name == "representative.scopist.step_one" && !$scope.scopistForm.$invalid ){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "representative.scopist.step_two" && !$scope.scopistForm.$invalid ){
                            $scope.goto_other();
                        }
                        if($state.current.name == "representative.scopist.step_three" && !$scope.scopistForm.$invalid ){
                            $scope.save();
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                        break;

                    case 'p':
                        event.preventDefault();

                        if($state.current.name == "representative.scopist.step_two" && !$scope.scopistForm.$invalid ){
                            $scope.goto_address();
                        }

                        if($state.current.name == "representative.scopist.step_three" && !$scope.scopistForm.$invalid ){
                            $scope.goto_rate();
                        }

                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                        break;
                }
            }
        });

    }]);
