angular.module('deitz')
    .controller('emailContentController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'inv_no',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  email_log_id,
                  $uibModalInstance,
                  configFactory) {
            $scope.advisedPayment = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Add Advised Payment';

            $scope.inv_no = inv_no;

            if(inv_no){
                $rootScope.showLoader(true);
                var id = inv_no;
                commonFactory.get('/api/invoice/' + id)
                    .success(function(response) {
                        $rootScope.showLoader(false);

                        if (response.status == 200 && response.result) {
                            $scope.advisedPayment.advised_payment_check_num = response.result.invoice.advised_payment_check_num || '';
                            if(response.result.invoice.advised_payment_check_dt != '0000-00-00'){
                                $scope.advisedPayment.advised_payment_check_dt = response.result.invoice.advised_payment_check_dt || '';
                            }
                            if(response.result.invoice.advised_payment_cashed_dt != '0000-00-00'){
                                $scope.advisedPayment.advised_payment_cashed_dt = response.result.invoice.advised_payment_cashed_dt || '';
                            }
                            $scope.advisedPayment.advised_payment_check_filepath = response.result.invoice.advised_payment_check_filepath || '';
                            $scope.advisedPayment.is_have_advised_payment = response.result.invoice.is_have_advised_payment;
                            $scope.advisedPayment.invoice_notes = response.result.invoice.NOTES;
                            if(response.result.invoice.advised_payment_check_filepath){
                                $scope.isPdfUploaded = true;
                            }
                            if($scope.advisedPayment.is_have_advised_payment=='1'){
                                $scope.title = 'Edit Advised Payment';
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }


            $scope.viewAdvisedCheckFile = function(id){
                if(id){
                    $http.get(apiUrl + '/api/get-advised-checkfile-url', {
                        params: {
                            id: id
                        }
                    }).then(function(response) {
                        if(!response.data.error){
                            window.open(response.data.result,'_blank');
                        }else{
                            Notification.error(response.data.message);
                        }
                    });
                }
            }

            $scope.validateDate = function(){
                if($scope.advisedPayment.advised_payment_check_dt){
                    is_valid_check= moment($scope.advisedPayment.advised_payment_check_dt).isValid();
                    if(!is_valid_check){
                        $scope.advisedPayment.advised_payment_check_dt='';
                    }
                }
                if($scope.advisedPayment.advised_payment_cashed_dt){
                    is_valid_cash= moment($scope.advisedPayment.advised_payment_cashed_dt).isValid();
                    if(!is_valid_cash){
                        $scope.advisedPayment.advised_payment_cashed_dt='';
                    }
                }
            }

            $scope.removeAdvisedCheckFile = function(id){
                if(id){

                    swal({
                            title: "Are you sure want to remove this File?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it!",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $http.get(apiUrl + '/api/remove-advised-checkfile', {
                                    params: {
                                        id: id
                                    }
                                }).then(function(response) {
                                    if(!response.data.error){
                                        $scope.isPdfUploaded = false;
                                        Notification.success(response.data.message);
                                    }else{
                                        Notification.error(response.data.message);
                                    }
                                });
                            }
                        });

                }
            }

            $scope.editAdvisedPayment = function(advisedPayment, isValid) {
                $scope.formSubmit = true;
                if(typeof advisedPayment.advised_payment_cashed_dt != "undefined" && advisedPayment.advised_payment_cashed_dt != ""){
                    advisedPayment.advised_payment_cashed_dt = moment(advisedPayment.advised_payment_cashed_dt).format('YYYY-MM-DD');
                }
                if(typeof advisedPayment.advised_payment_check_dt != "undefined" && advisedPayment.advised_payment_check_dt != ""){
                    advisedPayment.advised_payment_check_dt = moment(advisedPayment.advised_payment_check_dt).format('YYYY-MM-DD');
                }
                if (isValid) {
                    $rootScope.showLoader(true);

                    if(inv_no){
                        var id = inv_no;
                        Upload.upload({
                            url: apiUrl+'/api/add-advised-payment/' + id,
                            data: $scope.advisedPayment
                        }).then(function (response) {
                            $rootScope.showLoader(false);
                            if (response) {
                                if (response.data.status == 200) {
                                    Notification.success("Advised Payment Add Successfully");
                                    $scope.closePopupWindowSimple(true);
                                }else if(response.data.status == 400){
                                    Notification.error(response.data.result.message);
                                }
                            }
                        },function(err){
                            $rootScope.showLoader(false);
                        });

                    }
                }
            }

            $scope.closePopupWindowSimple = function(isChanged){
                $uibModalInstance.dismiss({ isChanged : isChanged});
            };


        }]);
