angular.module('deitz')
    .controller('loginController', ['$scope', '$rootScope', 'loginFactory', '$state', 'Notification', '$cookies', '$stateParams', '$location','$localStorage', function ($scope, $rootScope, loginFactory, $state, Notification, $cookies, $stateParams, $location,$localStorage) {

        $scope.user = {};

        $scope.validations = loginFactory.validations;
        $scope.messages = loginFactory.messages;
        $scope.formSubmit = false;

        window.onbeforeunload = null;

        $scope.loginSubmit = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                let data = {};
                data.email = $scope.user.email;
                data.password = btoa($scope.user.password);

                loginFactory.login(data)
                    .success(function (response) {

                        if (response && response.status && response.status == 401) {
                            Notification.error(response.result);
                            $state.go("forgot_password");
                        } else if (response && response.result && response.result.token) {
                            // Setting a cookie if token available
                            Notification.success(response.result.message);
                            
                            $cookies.put('token', response.result.token);
                            $cookies.put('Authorization', 'Bearer ' + response.result.token);
                            if (response && response.result.user) {
                                var auth_user = {};
                                auth_user.id = response.result.user.id;
                                auth_user.name = response.result.user.name;
                                auth_user.email = response.result.user.email;
                                auth_user.is_production_person = response.result.user.is_production_person;
                                auth_user.depo_psd = response.result.user.depo_psd;
                                auth_user.timezone = response.result.user.timezone;
                                auth_user.roles_businessunits = response.result.roles_businessunits;
                                $rootScope.$broadcast('batchCountBroadcastEvent', response.result.user.invoice_batch_queue); // set the invoice queue from database
                                localStorage.setItem('environment', response.result.env);
                                $rootScope.env = response.result.env;
                                $rootScope.permissions_businessunits = response.result.permissions_businessunits;
                                $rootScope.permissions = response.result.permissions;
                                $rootScope.role = response.result.role;

                                $rootScope.isSalesLogin = false;
                                // role 10 is used for sales, 12 for sales manager 
                                if($rootScope.role[10] !== undefined || $rootScope.role[12] !== undefined){
                                    $rootScope.isSalesLogin = true;
                                    localStorage.setItem('isSalesLogin', true);
                                }

                                $rootScope.roles_businessunits = response.result.roles_businessunits;
                                $rootScope.auth_user = auth_user;
                                $cookies.put('authUser', JSON.stringify(auth_user));
                                $cookies.put('audioperson', JSON.stringify(response.result.audioperson));
                                
                                localStorage.setItem('permissions_data', JSON.stringify(response.result.permissions));
                                localStorage.setItem('permissions_businessunits_data', JSON.stringify(response.result.permissions_businessunits));
                                localStorage.setItem('roles_businessunits', JSON.stringify(response.result.roles_businessunits));
                            }

                            if($stateParams.next){
                                var dashboard_token = $cookies.get('dashboard_token');
                                if(dashboard_token){
                                
                                    $state.go("index.dashboard", { token : dashboard_token });    
                                    
                                }else{
                                    $location.path($stateParams.next).search({next: null});
                                }
                            } else {
                                $state.go("index.home");
                            }

                        } else {
                            Notification.error('These credentials do not match our records.');
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        var message = err.error ? err.error : 'Invalid login detail.';
                        Notification.error(message);
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
