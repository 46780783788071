angular.module('deitz')
    .controller('paryrollResourceListController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        'JOB_NO',
        '$uibModal',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            $state,
            JOB_NO,
            $uibModal,
        ) {

            
            $scope.JOB_NO =  JOB_NO ? JOB_NO : $state.params.job_no;

            if (!$scope.JOB_NO) {
                $state.go('payroll.add_payment');
            }
            $scope.title = 'Resource(s) listing of Job # -' + $scope.JOB_NO;
            
            $scope.excludeBilled = false;
            $scope.job_resources = [];

            $scope.dtInstance = {};
            $scope.showReporterList = function () {
                $http.get(apiUrl + '/api/getJobResources/' + $scope.JOB_NO).then(function (response) {
                    $scope.job_resources=response.data.job_resources;
                    angular.forEach($scope.job_resources, function(value, key) {
                        if(value.resource_type=='R'){
                            if(value.REP_PAYDT && value.REP_PAYDT !='0000-00-00'){
                                $scope.job_resources[key].paidDate=moment(value.REP_PAYDT).format('MM/DD/YYYY') ;
                            }
                        } 
                        else{
                            if(value.TYP_PAYDT && value.TYP_PAYDT !='0000-00-00'){
                                $scope.job_resources[key].paidDate=moment(value.TYP_PAYDT).format('MM/DD/YYYY') ;
                            }
                        }
                    });
                });
            }
            $scope.showReporterList();
            $scope.assignResource = function(jobNo){
                var addTomorrowDay = 1;
                if(moment().format('dddd') === 'Friday')
                    addTomorrowDay = 3;
                if(moment().format('dddd') === 'Saturday')
                    addTomorrowDay = 2;
                $scope.formData = {
                    tomorrowsRecord: true,
                    today: moment().format('MM/DD/YYYY'),
                    tomorrow: moment().add(addTomorrowDay, 'days').format('MM/DD/YYYY'),
                    todayText : moment().format('dddd'),
                    tomorrowText : moment().add(addTomorrowDay, 'days').format('dddd')
                };
                var assignResourceModal = $uibModal.open({
                    templateUrl: "modules/diary/caselist/assignReporter.html",
                    controller: 'assignReporterController',
                    resolve: {
                        JOB: function () {
                            return jobNo;
                        },
                        resources: function () {
                            return null;
                        },
                        formDate : function(){
                            return $scope.formData;
                        }
                    },
                    keyboard: false,
                    backdrop: false,
                    close: function () {
    
                    },
                     size: 'lg'
                });
    
                assignResourceModal.result.then(function (cbData) {
                }, function (cbData) {
                    if(cbData.isChanged){
                        // $scope.reloadData();
                        $scope.job_resources=cbData.job_resources;
                        angular.forEach($scope.job_resources, function(value, key) {
                            if(value.REP_TYP=='R'){
                                if(value.REP_PAYDT && value.REP_PAYDT !='0000-00-00'){
                                    $scope.job_resources[key].paidDate=moment(value.REP_PAYDT).format('MM/DD/YYYY') ;
                                }
                            } 
                            else{
                                if(value.TYP_PAYDT && value.TYP_PAYDT !='0000-00-00'){
                                    $scope.job_resources[key].paidDate=moment(value.TYP_PAYDT).format('MM/DD/YYYY') ;
                                }
                            }
                        });
                        $rootScope.showLoader(false);
                        
                    }
    
                });
            };

        }]);
