angular.module('deitz').controller('addtranslatorController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',

    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ){

        $scope.translator = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Interpreter';

        $scope.addTranslator = function(translator, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/translator', $scope.translator)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Interpreter added successfully");

                                $state.go("code_tables.translator_browse");
                            }else{
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
