angular.module('deitz').controller('editprioragencyController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.prioragency = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit AdditionalInfo';
        $scope.modalTitle = 'Edit plaintiff/defendant';
        $scope.modalId = plaintiffId;

        //close modal
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        //Edit Mode of prioragency.
        if ($state.params && $state.params.id) {
            var id = plaintiffId? plaintiffId:$state.params.id;
            commonFactory.get('/api/prioragency/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.prioragency_detail) {
                        $scope.prioragency.name = response.result.prioragency_detail.name || '';
                        // $scope.prioragency.slug = response.result.prioragency_detail.slug || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }
 
        //Update Mode of prioragency
        $scope.addPriorAgency = function(prioragency, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = plaintiffId? plaintiffId:$state.params.id;
                commonFactory.put('/api/prioragency/' + id, $scope.prioragency)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("AdditionalInfo updated successfully");
                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.prioragency_update.id);
                                }else{
                                    $state.go("code_tables.prioragency_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
