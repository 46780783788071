angular.module('deitz')
    .factory('payrollFactory', ['$http', 'apiUrl', function($http, apiUrl) {

    	var payrollFactory = {};
        function convertParams(params) {
            var reqData = {};
            if (params) {
                reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                reqData.limit = params.limit || 10; // Number of entries showed per page.
                reqData.search = params.search ? params.search.value : '';
                reqData.orderColumn = params.order ? params.order[0].column : '';
                reqData.orderDir = params.order ? params.order[0].dir : '';
                if (params.id) {
                    reqData.id = params.id ? params.id : '';
                }
            }
            return reqData;
        }

        payrollFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        payrollFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

    	// payrollFactory.sharedData = [];
    	payrollFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        payrollFactory.savePayment = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        payrollFactory.getPayment = function(url) {
            return $http.get(apiUrl + url);
        };

        payrollFactory.adjustPayment = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        payrollFactory.delete = function(url) {
            return $http.delete(apiUrl + url);
        };

        payrollFactory.authPayment = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        payrollFactory.payrollAdjustment = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        payrollFactory.showDataTable = function(url,data){
            return $http({
                method: 'POST',
                url: apiUrl + url,
                data: $.param(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
        };

        //browse all our suites
        payrollFactory.getAdjustmentHistory = function(url, params) {
            var reqData = convertParams(params);
            return $http({ "url": apiUrl + url, "method": "GET", "params": reqData });
        };
    	return payrollFactory;
    }]);
