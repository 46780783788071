angular.module('deitz')
    .controller('editAdjustmentController',[
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'payrollFactory',
        '$uibModalInstance',
        'ID',
        'ADJ_TYPE',
        'Notification',
        function(
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            payrollFactory,
            $uibModalInstance,
            ID,
            ADJ_TYPE,
            Notification) {

            $scope.title = "Edit an adjustment form";
            $scope.formData = {};


            async.series([
                    function(callback){

                        // get the payment date
                        $http.post(apiUrl + '/api/config/get-configurations', {
                            keys : ['pay_date']
                        }).then(function(response) {
                            $scope.formData.payment_date = response.data.configurations.pay_date;
                            callback(null, 'one');
                        });
                    },
                    function(callback){

                        // get the payment date
                        payrollFactory.get('/api/get-adjustment?ID='+ ID +"&adj_type="+ADJ_TYPE)
                            .success(function (response) {
                                if(response.error){
                                    Notification.error(response.result.message || 'Error while fetching job details!');
                                } else{
                                    if(response.result[0]){

                                        if(ADJ_TYPE === 'ADJ_WITHOUT_JOB'){
                                            $scope.formData.adjustment_date = response.result[0].DATE_TAKEN;
                                            $scope.formData.adjustment_note = response.result[0].CAPTION;

                                            if(response.result[0].resource_type=='R'){
                                                $scope.formData.adjustment = response.result[0].REP_PAYAMT;
                                                $scope.formData.payment_date = response.result[0].REP_PAYDT;
                                                $scope.formData.resource_type = 'R';
                                            }

                                            if(response.result[0].resource_type=='T'){
                                                $scope.formData.adjustment = response.result[0].TYP_PAYAMT;
                                                $scope.formData.payment_date = response.result[0].TYP_PAYDT;
                                                $scope.formData.resource_type = 'T';
                                            }
                                            $scope.formData.job_resource_id = response.result[0].id;
                                        }
                                        else if (ADJ_TYPE === 'ADJ_WITH_JOB'){
                                            $scope.formData = response.result[0];
                                            $scope.formData.adjustment_date = response.result[0].date;
                                        }
                                        $scope.formData.JOB_NO = response.result[0].JOB_NO;

                                    }

                                }
                                callback(null, 'two');
                            })
                            .error(function (response) {
                                Notification.error(response.result.message | 'Error while deleting the cash receipt!');
                                callback(null, 'two');
                            });
                    }
                ],
                // optional callback
                function(err, results){
                    // results is now equal to ['one', 'two']
                    $rootScope.showLoader(false);
                    console.log(results);
                });

            $scope.submitAdjustment= function($valid){

                if($valid && $scope.formData.adjustment != 0){

                    if(!$scope.formData.JOB_NO){
                        swal({
                            title: "Cancelled",
                            text: "Please enter an adjustment number.",
                            type: "error"
                        }, function(){
                            anchorSmoothScroll.scrollTo('adjustment_number_manually');
                        });
                        return;
                    }

                    swal({
                            title: "Are you sure ?",
                            text: "You are going to add an adjustment.",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $rootScope.showLoader(true);

                                payrollFactory.post('/api/adjustmentHasNoJob', $scope.formData)
                                    .success(function (response) {

                                        if(!response.error){
                                            $scope.resetForm(true);
                                            Notification.success(response.result.message || 'Adjustment has been added successfully.');
                                        } else{
                                            Notification.error(response.result.message || 'Something went wrong!');
                                        }
                                        $rootScope.showLoader(false);
                                    })
                                    .error(function (err) {
                                        Notification.error(response.result.message | 'Something went wrong!');
                                        $rootScope.showLoader(false);
                                    });
                            }
                        });


                }
            };

            $scope.cancel = function(isChanged) {
                $uibModalInstance.dismiss(isChanged);
            };

            $scope.updateAdjustment = function(valid){

                if(valid && $scope.formData.adjustment != 0){
                    $rootScope.showLoader(true);
                    $scope.formData.adj_type = ADJ_TYPE;
                    payrollFactory.post('/api/updateAdjustment', $scope.formData)
                        .success(function (response) {
                            if(response.error){
                                Notification.error(response.result.message || 'Error while updating an adjustment!');
                            } else{
                                Notification.success(response.result.message || 'Adjustment updated successfully.');
                                $scope.cancel(true);
                            }
                            $rootScope.showLoader(false);
                        })
                        .error(function (response) {
                            Notification.error(response.result.message | 'Something went wrong!');
                            $rootScope.showLoader(false);
                        });
                }
            };
        }]);
