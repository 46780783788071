angular.module('deitz')
    .controller('forgotPasswordController', ['$scope', '$rootScope', 'loginFactory', 'Notification', '$state', function($scope, $rootScope, loginFactory, Notification, $state) {

        $scope.password = {};

        $scope.validations = loginFactory.validations;
        $scope.messages = loginFactory.messages;
        $scope.formSubmit = false;

        $scope.forgotPasswordSubmit = function(isValid) {
            $scope.formSubmit = true;            
            if (isValid) {
                $rootScope.showLoader(true);
                loginFactory.forgotPassword($scope.password)
                    .success(function(result) {
                        if (result && result.status == 200) {
                            Notification.success("We have e-mailed your password reset link.");
                            // notify({
                            //     message: 'We have e-mailed your password reset link.',
                            //     classes: 'alert alert-success',
                            //     templateUrl: 'views/common/notify.html',
                            //     duration: 100
                            // });
                            $state.go("login");
                        } else if (result && result.status == 404) {
                            Notification.error("We can\'t find a user with that e-mail address.");
                            // notify({
                            //     message: 'We can\'t find a user with that e-mail address.',
                            //     classes: 'alert alert-danger',
                            //     templateUrl: 'views/common/notify.html'
                            // });
                            $scope.password.email = '';
                        } else if (result && result.status == 403) {
                            Notification.error(result.message);
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function(err) {
                        Notification.error(err.message ? err.message : 'Invalid e-mail.');
                        // notify({
                        //     message: err.message ? err.message : 'Invalid e-mail.',
                        //     classes: 'alert alert-danger',
                        //     templateUrl: 'views/common/notify.html'
                        // });
                        $rootScope.showLoader(false);
                        $state.go("login");
                    });
            }
        };

    }]);
