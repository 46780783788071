angular.module('deitz').controller('viewBatchController', [
    '$scope', 'commonFactory', '$compile', '$uibModal', 'Notification','$rootScope','id','batch_no','showAction','dtInstance','$uibModalInstance',
    function (
        $scope,  commonFactory, $compile, $uibModal, Notification,$rootScope,id,batch_no,showAction,dtInstance,$uibModalInstance,
    ) {

        $scope.id=id;
        $scope.showAction=showAction;
        $scope.batchNo=batch_no;
        $scope.dtInstance=dtInstance;
        $scope.spinnerLoad= false;
        $scope.getJobData = function(){
            $scope.spinnerLoad= true;
            commonFactory.get('/api/get_batch_job/'+$scope.id, { 
                batch_id : $scope.id , 
            }).success(function (response) {
                $scope.spinnerLoad= false;
                $scope.jobs = response.result.jobs; 
                $scope.batch= response.result.batch;
            }).error(function () {
                $scope.spinnerLoad= false;
            });
        }
        $scope.getDateFormat= function(date){
            var dte = moment(date).format('MM/DD/YYYY');
            if (dte == 'Invalid date') {
                return '-';
            } else {
                return dte;
            }
        }
        $scope.changeBatchJobStatus= function(id,status){
            if(status==3){
                return false;
            }
            var swltext = (status == 1) ? 'You are going to check in this job.' : 'You are going to checkout this job.';
            swal({
                    title: "Are you sure ?",
                    text: swltext,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                         $rootScope.showLoader(true);
                         var requestUrl = (status== 1) ? '/api/update_job_checkin' : '/api/update_job_checkout';
                         commonFactory.post(requestUrl,{id:id,status:status})
                            .success(function(response) {
                                if (response && response.result && !response.error) {
                                    if(status==1){
                                        Notification.success("Job check in successfully");
                                    }   
                                    else{
                                        Notification.success("Job check out successfully");
                                    }
                                    $scope.getJobData();
                                    if(response.result.batch_refresh==1){
                                        $scope.dtInstance.rerender();
                                    }
                                    $rootScope.showLoader(false);
                        
                                }else{
                                    Notification.error(response.result.message);
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        }
        $scope.getJobData();
    
        $scope.closePopupWindow = function(){
            $uibModalInstance.close(true);
        };
    }
])

