angular.module('deitz')
    .controller('editClaimrepnameController', [
        '$scope',
        '$rootScope',
        '$state',
        'codeFactory',
        'commonFactory',
        'Notification',
        'claim_rep_data',
        '$uibModalInstance',
        'invoiceFactory',
        'fromModal',
        'claimrepId',
        '$timeout',
        '$http',
        'apiUrl',
            function(
                $scope,
                $rootScope,
                $state,
                codeFactory,
                commonFactory,
                Notification,
                claim_rep_data,
                $uibModalInstance,
                invoiceFactory,
                fromModal,
                claimrepId,
                $timeout,
                $http,
                apiUrl
            ){

        $scope.claim_rep_name = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Claim Representative Name';
        $scope.modalId = claimrepId;

        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
 
        //Getting all claim representative company addresses.
        commonFactory.get('/api/getallClaimrep')
            .success(function(response) {
                if (response.result && response.result.claimrepcompany) {
                    $scope.claimrepcompany = response.result.claimrepcompany || '';
                }
            })
            .error(function(err) {});

        if(claim_rep_data && claim_rep_data != 'undefined' && claim_rep_data != null) {
            $state.params.id = claim_rep_data || '';
        }

        //Edit Mode of claim_rep_name.
        if (($state.params && $state.params.id) || claimrepId) {
            var id = claimrepId?claimrepId:$state.params.id;
            $scope.isNotclaimAddress = true;
            commonFactory.get('/api/claimrepresentativename/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.claim_rep_name) {
                        $scope.claim_rep_name = response.result.claim_rep_name || "";
                        $scope.frequency = {
                            ef_send_to : response.result.claim_rep_name.statement_send_to ? response.result.claim_rep_name.statement_send_to : 'none',
                            email_fax : response.result.claim_rep_name.statement_send_via ? response.result.claim_rep_name.statement_send_via : 'fax'                  
                        } 
                    
                        //assigning company email and fax.
                        $scope.claim_rep_name.company_fax = response.result.claim_rep_name.insurance_comp.MainFaxNumber || "";
                        $scope.claim_rep_name.company_email = response.result.claim_rep_name.insurance_comp.MainEmailAddress || "";

                        $scope.isNotclaimAddress = false;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //select claim rep company id and accordingly address information will auto fill.
        // $scope.$watch('claim_rep_name.claimrepresentativeaddress_id', function(newValue, oldValue) {
            $scope.changedInsuaranceCompany = function(){
                if ($scope.claim_rep_name.claimrepresentativeaddress_id !== 'undefined') {
                    var id = $scope.claim_rep_name.claimrepresentativeaddress_id;
                    commonFactory.get('/api/claimrepresentative/' + id + '/edit')
                        .success(function(response) {
                            $rootScope.showLoader(false);
                            $timeout(function() {
                                $scope.claim_rep_name.CR_Address1 = response.result.claim_rep.Address1 || "";
                                $scope.claim_rep_name.CR_Address2 = response.result.claim_rep.Address2 || "";
                                $scope.claim_rep_name.CR_City = response.result.claim_rep.City || "";
                                $scope.claim_rep_name.CR_State = response.result.claim_rep.State || "";
                                $scope.claim_rep_name.CR_ZipCode = response.result.claim_rep.ZipCode || "";
                                $scope.claim_rep_name.CR_Phone = response.result.claim_rep.MainPhoneNumber || "";
                                $scope.claim_rep_name.CR_Fax = response.result.claim_rep.MainFaxNumber || "";
                                $scope.claim_rep_name.CR_CompanyName = response.result.claim_rep.CompanyName || "";

                                //assigning company email and fax.
                                $scope.claim_rep_name.company_fax = response.result.claim_rep.MainFaxNumber || "";
                                $scope.claim_rep_name.company_email = response.result.claim_rep.MainEmailAddress || "";
                            }, 500);
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }
            };
        // });
        //Update Mode of claim_rep_name
        $scope.addClaimrepname = function(claim_rep_name, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                
                //set frequency to claim rep name object.
                $scope.claim_rep_name.statement_send_to = $scope.frequency.ef_send_to;
                $scope.claim_rep_name.statement_send_via = $scope.frequency.email_fax;

                var id = claimrepId?claimrepId:$state.params.id;
                commonFactory.put('/api/claimrepresentativename/' + id, $scope.claim_rep_name)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.result && response.result.claim_rep_name_update) {
                            // $uibModalInstance.close();
                            if (response.status == 200) {
                                Notification.success("Claim Representative updated successfully");
                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.claim_rep_name_update);
                                }else{
                                    $state.go("insurance.claim_rep_name_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };
        $scope.showARNote = function(claim_rep,claimrepresentativeaddress_id){
            localStorage.setItem('clmId',claim_rep);
            localStorage.setItem('cpid',claimrepresentativeaddress_id);
           
            $state.go("accountReceivableNotes.browse", {
                clmId: claim_rep,
                cpid: claimrepresentativeaddress_id
            });
        };
        $scope.showClaimRepInvoices = function(claim_rep,claim_rep_name){
            invoiceFactory.searchData = {
                searchValue : claim_rep,
                selectedValue : "ClaimRepID",
                claimRepName: claim_rep_name
            };

            $state.go('invoice.browse');
        };
    }]);
