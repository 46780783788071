angular.module('deitz').controller('claimrepController', [
    '$scope', '$rootScope', 'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', 'invoiceFactory','$compile','$state',
    function(
        $scope, $rootScope, DTOptionsBuilder, DTColumnBuilder, commonFactory, invoiceFactory, $compile, $state
    ){

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.excludeZeroBalDue = null;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        }; 
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$'+ '0.00';
            }
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('stateSave',true)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    excludeZeroBalDue : $scope.excludeZeroBalDue
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/claimrepresentative', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'ClaimrepDetails' },
                { extend: 'pdf', title: 'ClaimrepDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');
                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('CompanyName').withOption('defaultContent', '').withTitle('Company Name'),
            DTColumnBuilder.newColumn('Address1').withOption('defaultContent', '').withTitle('Address1').notSortable(),
            DTColumnBuilder.newColumn('City').withOption('defaultContent', '').withTitle('City').notSortable(),
            DTColumnBuilder.newColumn('State').withOption('defaultContent', '').withTitle('State').notSortable(),
            DTColumnBuilder.newColumn('ZipCode').withOption('defaultContent', '').withTitle('ZipCode').notSortable(),
            DTColumnBuilder.newColumn('MainPhoneNumber').withOption('defaultContent', '').withTitle('Phone'),
            DTColumnBuilder.newColumn('MainFaxNumber').withOption('defaultContent', '').withTitle('Fax').notSortable(),
            DTColumnBuilder.newColumn('INS_BAL_DUE').withOption('defaultContent', '').withTitle('Bal Due').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="insurance.claim_rep_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;'+
                        '<button class="btn btn-info btn-circle" uib-tooltip="View ClaimReps" ui-sref="insurance.claim_rep_name_browse({id : ' + data.id + '})" >' +
                        '<i class="fa fa-users"></i>' +
                        '</button> &nbsp;'+
                        '<button class="btn btn-info btn-circle" uib-tooltip="View Statements" ui-sref="statements.print_statements({clamId:'+data.id+'})" >' +
                        '<i class="fa fa-file"></i>' +
                        '</button> &nbsp;'+
                        '<button class="btn btn-info btn-circle" uib-tooltip="Invoice List" ng-click="showClaimRepInvoices(\''+data.id+'\' ,\''+escape(data.CompanyName)+'\')" >' +
                        '<i class="fa fa-envelope"></i>' +
                        '</button> &nbsp;';
                }

            })
        ];
        $scope.showClaimRepInvoices = function(claim_rep,claim_rep_name){ 
            $rootScope.batchInvoiceArray = [];
            $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
            
            invoiceFactory.searchData = {
                searchValue : claim_rep,
                selectedValue : "ClaimID",
                claimRepName: unescape(claim_rep_name)
            };
            $state.go('invoice.browse');
        };
    }]);