angular.module('deitz')
    .controller('diaryReportController', [
        '$scope',
        '$rootScope',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        '$http',
        function (
            $scope,
            $rootScope,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
            $http,
        ) {

            $scope.dtInstance = {};
            $scope.dateRange
            $scope.title = $state.current.data.pageTitle;
            $scope.downloadExcel = false;

            $scope.resetSearch = function () {
                $scope.dateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };
            $scope.lookingFor = '';
            $scope.assignmentTypes = [
                {'type':'','name':'-- ALL JOBS --'},
                {'type':'audio','name':'AUDIO JOBS'},
                {'type':'geico','name':'GEICO JOBS'},
                {'type':'reporter','name':'RESOURCE JOBS'} 
            ];


            $scope.report = {
                dateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                }
            }


            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});
            });
    
            $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
                $scope.JobStatusList = response.data.result.data;  
                $scope.JobStatusList.unshift({name:'All'});
            });
    
            $scope.selectedJobStatusIDs = [];
    
            $http.get(apiUrl + '/api/casetypes').then(function(response) { 
                $scope.CaseTypeList = response.data.result.casetype;  
                $scope.CaseTypeList.unshift({name:'All'});
            });
    
            $scope.selectedCaseTypeIDs = [];        
    
            $http.get(apiUrl + '/api/getJobCategory').then(function(response) { 
                $scope.JobCategoryList = response.data.result.jobcategory;  
                $scope.JobCategoryList.unshift({name:'All'});
            });
    
            $scope.selectedJobCategoryIDs = [];
    
            $scope.selectedJobTypeIDs = [];        
            $http.get(apiUrl + '/api/jobtypes').then(function(response) { 
                $scope.JobTypeList = response.data.result.jobtype;  
                $scope.JobTypeList.unshift({name:'All'});
            });
            $scope.selectedResourceTypeIDs=[];
            $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
                $scope.ResourceTypeList = response.data.result.data;  
                $scope.ResourceTypeList.unshift({name:'All'});
            });
    
            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            }else{
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }


            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };
            
            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.downloadExcelFile = function () {
                $scope.downloadExcel = true;
                $scope.reloadData();
            }

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    if ($scope.downloadExcel) {
                        $rootScope.showLoader(true);
                    }
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); $scope.inProcess = true;
                    data.downloadExcel = $scope.downloadExcel;
                    data.assignmentType = $scope.lookingFor;
                    data.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);
                    data.selectedCaseTypeIDs = $scope.selectedCaseTypeIDs.filter(Boolean);
                    data.selectedJobCategoryIDs = $scope.selectedJobCategoryIDs.filter(Boolean);
                    data.selectedJobTypeIDs = $scope.selectedJobTypeIDs.filter(Boolean);
                    data.selectedResourceTypeIDs = $scope.selectedResourceTypeIDs.filter(Boolean);
                    data.startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
                    commonFactory.showDataTable('/api/get-diary-report', data).success(function (res) {
                        if (!res.error) {
                            $rootScope.showLoader(false);
                            $scope.selectAll = true;
                            $scope.invoiceArray = {};
                            callback(res);
                        }
                        if ($scope.downloadExcel) {
                            $window.open(apiUrl + '/api/download-job-listing-report', '_blank');    
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        Notification.error(err.error)
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO JOB AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ JOBS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 JOBS",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL JOBS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ JOBS",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING JOBS FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searching', false)
                .withOption('searchDelay', 500)
                .withOption('order', [2, 'asc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job #').withOption('searchable', true),
                DTColumnBuilder.newColumn('legacy_job_no').withTitle('Legacy Job #').withOption('searchable', true),
                DTColumnBuilder.newColumn('DATE_TAKEN').withTitle('Job Date').withOption('defaultContent', '').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    if(data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if(dte == 'Invalid date'){
                            return '-';
                        }else{
                            return dte;
                        }
                    } 
                    else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('TIME_TAKEN').withTitle('Job Time').withOption('searchable', true),
                DTColumnBuilder.newColumn('timezone').withTitle('Timezone').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_status').withTitle('Job Status').withOption('searchable', true),
                DTColumnBuilder.newColumn('client_level').withTitle('Client Level').withOption('searchable', true),
                DTColumnBuilder.newColumn('scheduling_firm').withTitle('Scheduling Firm').withOption('searchable', true),
                DTColumnBuilder.newColumn('CAPTION').withTitle('Caption').withOption('searchable', true),
                DTColumnBuilder.newColumn('euo_of').withTitle('Euo/Statement').withOption('searchable', true).renderWith(function(data,type,full,meta){
                    return ( data=='Y') ? 'Euo' : 'Statement';
                }),
                DTColumnBuilder.newColumn('business_unit').withTitle('Business Unit').withOption('searchable', true),
                DTColumnBuilder.newColumn('salesperson').withTitle('Account Executive(s)').withOption('searchable', true),
                DTColumnBuilder.newColumn('witness_type').withTitle('PT/DF').withOption('searchable', true),
                DTColumnBuilder.newColumn('case_type').withTitle('Case Type').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_category').withTitle('Job Category').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_type').withTitle('Job Type').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_location').withTitle('Job Location').withOption('searchable', true),
                DTColumnBuilder.newColumn('venue').withTitle('Venue').withOption('searchable', true),
                DTColumnBuilder.newColumn('resources').withTitle('Resource(s)').withOption('searchable', true).withOption('min-width', "10%").renderWith(function(data,type,full,meta){
                    var response= ( full.typists) ? data+','+full.typists : data;
                    if(response){
                        response= response.replace(/,/g, '<br>');
                    }
                    return response;
                })
            );
        }
    ]);
