angular.module('deitz')
    .controller('advisedPaymentReportController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        '$filter',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
            $filter
        ) {

            $scope.dtInstance = {};
            $scope.dateRange
            $scope.title = $state.current.data.pageTitle;


            $scope.resetSearch = function () {
                $scope.dateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };
         
            $scope.reloadData = function () {

                $scope.dtInstance.rerender();
            };
            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };
            $scope.viewAdvisedCheckFile = function(id){
                if(id){
                    $http.get(apiUrl + '/api/get-advised-checkfile-url', {
                        params: {
                            id: id
                        }
                    }).then(function(response) {
                        if(!response.data.error){
                            window.open(response.data.result,'_blank');
                        }else{
                            Notification.error(response.data.message);
                        }
                    });
                }
            }

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {

                    commonFactory.showDataTable('/api/get-advised-payment-report', data).success(function (res) {

                        if (res.error) {
                            // $scope.reloadData();
                        }
                        else {
                            $scope.selectAll = true;
                            $scope.invoiceArray = {};
                            callback(res);
                        }
                    
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            //$scope.reloadData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ INVOICES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "Search :",
                    "sZeroRecords": "NO MATCHING INVOICE FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searchDelay', 500)
                .withOption('order', [1, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });


            $scope.dtColumns = [];
            $scope.ori_invoice = {};

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice No').withOption('searchable', true),
                DTColumnBuilder.newColumn('firm_name').withOption('name','ATTORNEY_tbl.NAME').withTitle('Billing Firm').withOption('searchable', true),
                DTColumnBuilder.newColumn('dte_invoice').withTitle('Invoice Date').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'INVOICE_tbl.dte_invoice').renderWith(function (data, type, full, meta) {
                    if(data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if(dte == 'Invalid date'){
                            return '-';
                        }else{
                            return dte;
                        }
                    } 
                    else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('INV_BAL_DUE').withTitle('Balance Due').withOption('searchable', false).notSortable().withClass('font-bold').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }
                }),
                DTColumnBuilder.newColumn('business_unit_name').withOption('name','business_units.name').withTitle('Business Unit').withOption('searchable', true),
                DTColumnBuilder.newColumn('advised_payment_check_dt').withTitle('Advised Check Dt').withOption('searchable', false).withOption('data', 'advised_payment_check_dt').withOption('name', 'INVOICE_tbl.advised_payment_check_dt').renderWith(function (data, type, full, meta) {
                    if(data) {
                        var dte = moment(full.advised_payment_check_dt).format('MM/DD/YYYY');
                        if(dte == 'Invalid date'){
                            return '---';
                        }else{
                            return dte;
                        }
                    } 
                    else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('advised_payment_check_num').withTitle('Advised Check Num').withOption('searchable', false).withOption('data', 'advised_payment_check_num').withOption('name', 'INVOICE_tbl.advised_payment_check_num').renderWith(function (data, type, full, meta) {
                    if(data) {
                    return data
                    } 
                    else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('advised_payment_cashed_dt').withTitle('Advised Cashed Dt').withOption('searchable', false).withOption('data', 'advised_payment_cashed_dt').withOption('name', 'INVOICE_tbl.advised_payment_cashed_dt').renderWith(function (data, type, full, meta) {
                    if(data) {
                        var dte = moment(full.advised_payment_cashed_dt).format('MM/DD/YYYY');
                        if(dte == 'Invalid date'){
                            return '---';
                        }else{
                            return dte;
                        }
                    } 
                    else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('NOTES').withTitle('Inv Note').withOption('searchable', false).withOption('data', 'NOTES').withOption('name', 'INVOICE_tbl.NOTES').renderWith(function (data, type, full, meta) {
                    if(data) {
                    return data
                    } 
                    else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn(null).withTitle('Image').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    if(full.is_have_advised_payment=='1'){
                        return '<a class="btn btn-warning btn-circle" ng-click="viewAdvisedCheckFile('+full.invoiceID+')" tooltip-trigger="focus mouseenter" uib-tooltip="Show File"><i class="fa fa-eye" aria-hidden="true"></i></a>';
                    }
                    else{
                        return'---';
                    }
    
                }),
            );

        }
    ]);
