angular.module('deitz')
            .controller('cancelledJobListController', [
                    '$scope',
                    '$rootScope',
                    'commonFactory',
                    '$http',
                    'apiUrl',
                    'SweetAlert',
                    '$state',
                    'Notification',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    '$compile',
                    '$uibModal',
                    '$filter',
                    '$q',
                    function(
                        $scope,
                        $rootScope,
                        commonFactory,
                        $http,
                        apiUrl,
                        SweetAlert,
                        $state,
                        Notification,
                        DTOptionsBuilder,
                        DTColumnBuilder,
                        $compile,
                        $uibModal,
                        $filter,
                        $q
                    ){

        $scope.title = 'Cancelled Jobs';
        $scope.dtInstance = {};
 
        $scope.cancelled_job_report = {
            startDate : moment().startOf('month').format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD')
        };
        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
            $scope.JobStatusList.unshift({name:'All'});
        });

        $scope.selectedJobStatusIDs = [];

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.resetSearch = function(){
            $scope.cancelled_job_report = {
                startDate : moment().startOf('month').format('YYYY-MM-DD'),
                endDate : moment().format('YYYY-MM-DD')
            };
    
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                data.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);
                data.startDate = moment($scope.cancelled_job_report.startDate).format('YYYY-MM-DD');
                data.startDate = data.startDate == 'Invalid date' ? null : data.startDate;
                data.endDate = moment($scope.cancelled_job_report.endDate).format('YYYY-MM-DD');
                data.endDate = data.endDate == 'Invalid date' ? null : data.endDate;

                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                commonFactory.showDataTable('/api/cancelledJobs', data)
                .success(function(response) {
                    $scope.inProcess = false;
                    if (response.error) {
                        $scope.reloadData();
                    }
                    else {
                        $scope.totalRecords = response.recordsFiltered;
                        callback(response);
                    }
                })
                .error(function(err) {
                    $scope.inProcess = false;
                    if (err.error !== "token_not_provided") {
                        // $scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', false)
            .withDisplayLength(50)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'desc'])
            .withOption('lengthMenu', [
                [10, 25, 50, 100, 200, 250, 300, 500],
                [10, 25, 50, 100, 200, 250, 300, 500]
            ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('scrollX', 'auto') 
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

            $scope.dtColumns = [];
            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('LAST_JOB').withTitle('Job #').withOption('searchable', true).withOption("width", "5%").renderWith(function(data,type,full,meta){
                    // var label;
                    // var status = full.pending_job_status ? full.pending_job_status.status.toLowerCase() : '';
                    // switch (status) {
                    //     case 'sent':
                    //         label="label label-primary";
                    //         break;
                         
                    //     case 'accepted':
                    //         label="label label-success";
                    //         break;

                    //     case 'declined':
                    //         label="label label-danger";
                    //         break;

                    //     default : 
                    //         break;   
                    // } 
                    return '<span class="" style="font-size:14px;"><a target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'</a></span>'
                }),
                DTColumnBuilder.newColumn('diary.TIME_TAKEN').withTitle('Job Time').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "5%"),
                DTColumnBuilder.newColumn('diary.timezone_usa.short_name').withTitle('Time Zone').withOption('name', 'diary.timezoneUsa.name').withOption('defaultContent', '').withOption("width", "4%").withOption('searchable', false),
                DTColumnBuilder.newColumn('diary.DATE_TAKEN').withTitle('Job Date').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "4%"),
                DTColumnBuilder.newColumn('diary.job_status.name').withTitle('Job Status').withOption('name', 'diary.jobStatus.name').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta){
                    if (data) {
                        return data;
                    } 
                }),
                DTColumnBuilder.newColumn('diary.attorney_firm.LevelOfPayment').withTitle('Client Level').withOption('name', 'diary.attorneyFirm.LevelOfPayment').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "4%"),
                DTColumnBuilder.newColumn('diary.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption('name', 'diary.attorneyFirm.SHORT_NAME').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var attorney_firm = "<div uib-tooltip='"+full.diary.attorney_firm.NAME+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:15}}</div>";
                        return attorney_firm;
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('CAPTION').withTitle('Caption').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                    if (data) {
                        data = escapeString(data);
                        var caption = "<div uib-tooltip='"+data+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>{{'"+data+"' | limitTocustom:13}}</div>";
                            caption += '<i style="color: black;">';
                            full.diary.audio_files.forEach(function(item){
                                if(item.filetype == "mp3" || item.filetype == "mp4"){
                                    caption += '<h5>'+item.duration +' - '+ $scope.niceBytes(item.filesize)+'</h5>';
                                }
                            });
                            caption +='<i>';
                            if(full.IndexNumber){
                                caption += "<a href='{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=display_on_elaw.php?indexNumber="+full.IndexNumber+"' target='_blank' title='Display On Elaw'>Elaw</a></div>";
                            }
                        return caption;
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', false),
                DTColumnBuilder.newColumn('diary.diary_reporting_salesman').withTitle('Account Executive(s)').withOption('name', 'diary.diaryReportingSalesman.reptype.FIRST_NAME').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                    if (data) {
                        var name = [];
                        angular.forEach(data,function(value,key){
                            if(value.reptype){
                                name[key] = escapeString(value.reptype.FIRST_NAME+' '+value.reptype.LAST_NAME);
                            }
                        });
                        return "<div uib-tooltip='"+name+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>{{'"+name+"' | limitTocustom:10}}</div>";
                        
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('witness_type.name').withTitle('Pt/Df').withOption('defaultContent', '-').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                    if(data){
                        var plaintiff_defendent = data.substring(0, 8);
                        return  '<span class="label label-warning" uib-tooltip="'+data+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top">'+plaintiff_defendent+'</span>';
                    }
                    return ''; 
                }),
                DTColumnBuilder.newColumn('case_type.name').withTitle('Case Type').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                    if(data){
                        var case_type = data.substring(0, 8);
                        return  '<span class="label label-warning" uib-tooltip="'+data+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top">'+case_type+'</span>';
                    }
                    return '';
                }),
                DTColumnBuilder.newColumn('diary.HELD_AT1').withTitle('Job Location').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var HELD_AT1 = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:13}}</div>";
                        return HELD_AT1;
                    } else {
                        return '---';
                    }
                }),
                DTColumnBuilder.newColumn('diary.location.name').withTitle('Venue').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "3%").renderWith(function(data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var HELD_AT1 = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:13}}</div>";
                        return HELD_AT1;
                    } else {
                        return '---';
                    }
                }),               
                DTColumnBuilder.newColumn('diary.connection_methods.name').withTitle('Connection Method').withOption('defaultContent', '').withOption('name', 'diary.connectionMethods.name').withOption('searchable', true).withOption("width", "3%").renderWith(function(data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var connection_methods = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"'}}</div>";
                        return connection_methods;
                    } else {
                        return '---';
                    }
                }), 
                DTColumnBuilder.newColumn('diary.service_request').withTitle('Requested Service(s)').withOption('name', 'diary.service_request.additional_info.name').notSortable().withOption('searchable', false).withOption('defaultContent', '').withOption('searchable', true).withOption("width", "3%").renderWith(function(data, type, full, meta) {
                    if (data) {
                        var name = [];
                        angular.forEach(data,function(value,key){
                            if(value.additional_info){
                                name[key] = escapeString(value.additional_info.name);
                            }
                        });
                        return "<div uib-tooltip='"+name+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>{{'"+name+"'}}</div>";
                        
                    } else {
                        return '---';
                    }
                }), 
                DTColumnBuilder.newColumn('diary.diary_attorney.name').withTitle('Attorney').withOption('defaultContent', '').withOption('searchable', true).withOption('name', 'diary.diaryAttorney.name').withOption("width", "3%").renderWith(function(data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var diary_attorney = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"'}}</div>";
                        return diary_attorney;
                    } else {
                        return '---';
                    }
                })                  
            );

        $scope.niceBytes = function (x){
            
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if(x === 1) return '1 byte';

              let l = 0, n = parseInt(x, 10) || 0;

              while(n >= 1000 && ++l){
                  n = n/1000;
              }
              //include a decimal point and a tenths-place digit if presenting
              //less than ten of KB or greater units
              return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        };

        /* This function is use for prinnt OPERATION METRICS REPORT */
        $scope.exportData = function(type){
            var printData = {};
            printData.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            printData.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);

            printData.startDate = moment($scope.cancelled_job_report.startDate).format('YYYY-MM-DD');
            printData.startDate = printData.startDate == 'Invalid date' ? null : printData.startDate;
            printData.endDate = moment($scope.cancelled_job_report.endDate).format('YYYY-MM-DD');
            printData.endDate = printData.endDate == 'Invalid date' ? null : printData.endDate;
            
            $rootScope.showLoader(true);
            commonFactory.post('/api/cancelledJobs-export', printData)
            .success(function(response) { 
                $rootScope.showLoader(false);
                if(response.error){
                    Notification.error(response.result.message || 'Something went wrong!');
                } else {
                    var url= response.result.path;
                    var save = document.createElement('a');
                    save.href = url;
                    save.target = '_blank';
                    save.download = response.result.name;
                    var event = document.createEvent('Event');
                    event.initEvent('click', true, true);
                    save.dispatchEvent(event);
                    save.click()
                    window.URL.revokeObjectURL(url);
                }
            })
            .error(function(err){   
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false);
            });
        };
    }]);
