angular.module('deitz')
    .controller('mergeRepresentativeController',
        [
            '$scope',
            '$rootScope',
            '$state',
            'representativeFactory',
            'commonFactory',
            'Notification',
            '$stateParams',
            'DTOptionsBuilder',
            'DTColumnBuilder',
            '$q',
            '$compile',
            'apiUrl',
            '$http',
            function(
                $scope,
                $rootScope,
                $state,
                representativeFactory,
                commonFactory,
                Notification,
                $stateParams,
                DTOptionsBuilder,
                DTColumnBuilder,
                $q,
                $compile,
                apiUrl,
                $http) {

                $scope.mergeResource = {
                    delete_resource : {},
                    keep_resource : {},
                    lookingFor : 'R'
            };
                $scope.selectedResourceId = null;

                $scope.resourceTypes = [{'type':'R','name':'Resource'},
                                        {'type':'T','name':'Typist'},
                                        {'type':'S','name':'Account Executive'}];
                
                $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};

                $scope.getResourceList = function($keyword){
                    return $http.post(apiUrl + '/api/search-resource-type', {
                        keyword : $keyword,
                        lookingFor : $scope.mergeResource.lookingFor,
                        selectedResourceId : $scope.selectedResourceId
                    }).then(function(response) {
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    });
                }

                $scope.selectResource = function($item, $model, $label, $event,resource_type){
                    $item.resource_name = $item.FIRST_NAME + ' '+$item.LAST_NAME;
                    if(resource_type == 'keepResourceType'){
                        $scope.mergeResource.keep_resource = angular.copy($item);
                        $scope.iskeepResourceTypeSelected = true;
                        $scope.keep_resource_id = $item.id;
                        
                    }else{
                        $scope.mergeResource.delete_resource = angular.copy($item);
                        $scope.isdeleteResourceTypeSelected = true;
                        $scope.delete_resource_id = $item.id;
                        
                    }

                };

                $scope.clearfield = function (key){

                    if(key == 'keepResourceType' && !$scope.iskeepResourceTypeSelected){
                        $scope.mergeResource.keep_resource = null;
                        $scope.iskeepResourceTypeSelected = false;
                    }
                    if(key == 'deleteResourceType' && !$scope.isdeleteResourceTypeSelected){
                        $scope.mergeResource.delete_resource = null;
                        $scope.isdeleteResourceTypeSelected = false;
                    }
                    

                };
                $scope.resetfield = function (key){
             
                    if(key =='keepResourceType'){ 

                        $scope.iskeepResourceTypeSelected = false;
                        $scope.keep_resource_id = null;  

                    }else{     

                        $scope.isdeleteResourceTypeSelected = false; 
                        $scope.delete_resource_id = null;  
                    
                    }

                    if($scope.keep_resource_id){

                        $scope.selectedResourceId = $scope.keep_resource_id; 
                    
                    }else if($scope.delete_resource_id){
                    
                        $scope.selectedResourceId = $scope.delete_resource_id;
                    
                    }else{
                    
                        $scope.selectedResourceId = null;
                    
                    }

                    $scope.mergeResourceTypeForm.$setPristine(true);
                    $scope.mergeResourceTypeForm.$setUntouched(true);
                }

                $scope.mergeResourceType = function($valid){
                    
                    if($valid){

                        swal({
                                title: "Are you sure ?",
                                text: "You want to merge resources?",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#09375f",
                                confirmButtonText: "Yes",
                                cancelButtonText: "No, It's clicked by mistake",
                                closeOnConfirm: true,
                                closeOnCancel: true
                            },
                            function (isConfirm) {
                                if (isConfirm) {
                                    $rootScope.showLoader(true);
                                    representativeFactory.post('/api/reptyp/merge-resource-type',$scope.mergeResource).success(function (response) {

                                        $rootScope.showLoader(false);
                                        if(response.result.status == 200){
                                            Notification.success(response.result.message);
                                            $scope.resetfield('keepResourceType');
                                            $scope.resetfield('deleteResourceType');
                                            $scope.clearfield('keepResourceType');
                                            $scope.clearfield('deleteResourceType');
                                        }else{
                                            Notification.error(response.result.message);
                                        }

                                    }).error(function (error) {
                                        $rootScope.showLoader(false);
                                        Notification.error(error.message);
                                    });
                                }
                            });

                    }
                }
                $scope.authMergeResource = function(){  
                    if($state.current.name == 'representative.merge_representative'){

                        return false;
                        
                    }else if($rootScope.havePermission('allow_access_without_password','allow.resource.merge')){

                        $state.go('representative.merge_representative');

                    }else{
                        
                        swal({
                            title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                            type: "input",
                            inputType: "password",
                            showCancelButton: true,
                            closeOnConfirm: true
                        }, function(inputValue) { 
                            if (inputValue === false){
                                return false;
                            } 
                            if (inputValue === "") {
                                Notification.error("You need to write something!");
                                return false
                            }
                            else {
                                $rootScope.showLoader(true);
                                commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                                    .success(function(response) {
                                        if (response['result']) {
                                            if (response['result']['auth']) {

                                                $rootScope.showLoader(false);
                                                $state.go('representative.merge_representative');
                                                
                                            }else {
                                                $rootScope.showLoader(false);
                                                Notification.error("Error ! Wrong Password");
                                            }
                                        }else{
                                            $rootScope.showLoader(false);
                                            Notification.error("Oops ! Something went wrong");
                                        }
                                    })
                                    .error(function(err) {
                                        $rootScope.showLoader(false);
                                        Notification.error("Oops ! Something went wrong");
                                    });

                            }
                        });        
                    } 
                } 

            }]);
