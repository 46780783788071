angular.module('deitz').controller('addAccountReceivableNotesAttorneyController', [
    '$scope', '$rootScope', '$state', '$http', 'apiUrl', 'Notification', 'commonFactory', '$uibModalInstance', 'ATTORNEY', 'ClaimRep',
    function(
        $scope, $rootScope, $state, $http, apiUrl, Notification, commonFactory, $uibModalInstance, ATTORNEY, ClaimRep
    ){

        $scope.formSubmit = false;
        $scope.title = 'Create a note';
        $scope.isEditMode = false;
        if(ATTORNEY){
            $scope.formData = {
                attorney_id : ATTORNEY.id,
                AttorneyFirm_FK : ATTORNEY.NAME_KEY,
                HideNote : 'Y',
                AR_ReminderDate : moment()
            };
        }
        else if(ClaimRep){
            $scope.formData = {
                ClaimRepID : ClaimRep,
                HideNote : 'Y',
                AR_ReminderDate : moment()
            };
        }

        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $http.get(apiUrl + '/api/getArPredefinedNotes').then(function(response) { 
            $scope.ArPredefinedNotes = response.data.result.data;  
            $scope.ArPredefinedNotes.unshift({label:'---'});
        });

        $scope.changePredefinedNote = function(){
            var filtered = $scope.ArPredefinedNotes.filter(function(obj) {
                return obj.id == $scope.ArPredefinedNotes_id;
            });

            if(typeof filtered[0] != undefined){
                $scope.formData.ARNote = filtered[0].note;
            }
        }

        $scope.submitNote = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                $scope.formData.AR_ReminderDate = moment($scope.formData.AR_ReminderDate).format('YYYY-MM-DD');
                var requestUrl = (ATTORNEY)? '/api/AccountsReceivableAttorneyNotes' : '/api/AccountsReceivableClaimRepNotes';

                commonFactory.post( requestUrl , $scope.formData )
                    .success(function(response) {
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Note created successfully");
                                $scope.cancel(true);
                            } else{
                                $rootScope.showLoader(false);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.cancel = function(isChanged) {
            $uibModalInstance.dismiss({ isChanged : isChanged});
        };
    }
])


.controller('editAccountReceivableNotesAttorneyController', [
    '$scope', '$rootScope', '$state', '$http', 'apiUrl', 'Notification', 'commonFactory', '$uibModalInstance', 'ID', 'NoteType',

    function(
        $scope,
        $rootScope,
        $state,
        $http,
        apiUrl,
        Notification,
        commonFactory,
        $uibModalInstance,
        ID,
        NoteType
    ){

        $scope.formSubmit = false;
        $scope.title = 'Edit a note';
        $scope.formData = {};
        $scope.isEditMode = true;

        $rootScope.showLoader(true);
        var requestUrl = (NoteType == 'attorney') ? '/api/AccountsReceivableAttorneyNotes/'+ID : (NoteType == 'claimrep') ? '/api/AccountsReceivableClaimRepNotes/'+ID : '/api/AccountsReceivableAttNameNotes/'+ID;

            commonFactory.get(requestUrl)
            .success(function(response) {
                if (response) {
                    if (response.status == 200) {
                        $scope.formData = response.result.data;
                    }
                    $rootScope.showLoader(false);
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $http.get(apiUrl + '/api/getArPredefinedNotes').then(function(response) { 
            $scope.ArPredefinedNotes = response.data.result.data;  
            $scope.ArPredefinedNotes.unshift({label:'---'});
        });

        $scope.changePredefinedNote = function(){
            var filtered = $scope.ArPredefinedNotes.filter(function(obj) {
                return obj.id == $scope.ArPredefinedNotes_id;
            });

            if(typeof filtered[0] != undefined){
                $scope.formData.ARNote = filtered[0].note;
            }
        }

        $scope.submitNote = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                $scope.formData.AR_ReminderDate = moment($scope.formData.AR_ReminderDate).format('YYYY-MM-DD');
                var requestUrl = (NoteType == 'attorney') ? '/api/AccountsReceivableAttorneyNotes/'+ID : (NoteType == 'claimrep') ? '/api/AccountsReceivableClaimRepNotes/'+ID : '/api/AccountsReceivableAttNameNotes/'+ID;

                commonFactory.put(requestUrl , $scope.formData)
                    .success(function(response) {
                        if (response) {
                            if (response.status == 200) {
                                Notification.success(response.result.message || "Notes updated successfully");
                                $scope.cancel(true);
                            } else{
                                $rootScope.showLoader(false);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.cancel = function(isChanged) {
            $uibModalInstance.dismiss({ isChanged : isChanged});
        };
    }
]);
