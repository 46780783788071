angular.module('deitz').controller('editServiceTypeController', ['$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromMod, $uibModalInstance
    ) {

        $scope.service_type = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Service Type';
     

        commonFactory.get('/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });
       

        //Edit Mode of service type.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/service-type/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.service_type_detail) {
                        $scope.service_type.name = response.result.service_type_detail.name || '';
                        // $scope.service_type.slug = response.result.service_type_detail.slug || '';
                        if(response.result.service_type_detail.resource_type.length){
                           
                           
                            
                            $scope.service_type.resource_type_ids = []
                            inx = 0
                            response.result.service_type_detail.resource_type.forEach(element => {
                                $scope.service_type.resource_type_ids[inx] =element.resource_type_id
                                inx++
                            });
                            
                            
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of service_type
        $scope.add = function(service_type, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/service-type/' + id, $scope.service_type)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Service Type updated successfully");
                                
                                    $state.go("code_tables.service_type_browse");
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
