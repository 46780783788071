angular.module('deitz').controller('addlocationController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        $uibModalInstance
    ){

        $scope.location = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Venue';

        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $scope.addLocation = function(location, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/locations', $scope.location)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Venue added successfully");

                                if(fromModal == true){  
                                    $uibModalInstance.close(response.result.location.id);
                                }else{
                                    $state.go("code_tables.location_browse");
                                }
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
