angular.module('deitz')
    .controller('viewPayrollController',
        [
            '$scope',
            '$rootScope',
            'payrollFactory',
            'diaryFactory',
            'firmFactory',
            'representativeFactory',
            '$state',
            '$http',
            'apiUrl',
            '$timeout',
            '$filter',
            '$uibModalInstance',
            function (
                $scope,
                $rootScope,
                payrollFactory,
                diaryFactory,
                firmFactory,
                representativeFactory,
                $state,
                $http,
                apiUrl,
                $timeout,
                $filter,
                $uibModalInstance,
            ) {
                $scope.title = "Edit Payment";
                $scope.editInvoiceData = {};

                $scope.selected = undefined;
                $scope.calcrate = [];
                $scope.linepay = [];
                $scope.calcrateT = [];
                $scope.linepayT = [];
                $scope.oCopies = [];
                $scope.oCopiesT = [];
                $scope.showCalcMethodDropdown = false;
                $scope.calcMethod = 'A';

                $scope.uibModalInstance = $uibModalInstance;

                $scope.isCalculateRate = false;
                $scope.saveAndAddInvoice = false;
                $scope.saveAndNewPayment = false;
                $scope.saveAndHome = false;
                $scope.lock_screen = false;

                $scope.check_number_locked = true;
                if ($rootScope.havePermission('allow_access_without_password', 'allow.unlock.check.number')) {
                    $scope.check_number_locked = false;
                }

                $scope.typistData = {};
                $scope.reporterData = {};
                $scope.witness_info = {};
                $scope.DiaryData = {};
                $scope.repPayDate = '';
                $scope.typPayDate = '';

                $scope.intDeductionPerWitness = 0;
                $scope.totalPaidPages = 0;

                $scope.jobResourceId = $state.params.id;

                // Options for STIPS
                $scope.DeductPerWitnessArray = [
                    { id: 0, label: '$0.00' },
                    { id: 0.70, label: '$0.70' }
                ];

                $scope.reporterType = {
                    'R': 'Reporter',
                    'T': 'Typist',
                    'SC': 'Scopist',
                    'S': 'Account Executive'
                };

                $scope.appearList = [
                    { id: 0, label: '0' },
                    { id: 1, label: '1' },
                    { id: 2, label: '2' },
                    { id: 3, label: '3' },
                    { id: 4, label: '4' },
                    { id: 5, label: '5' }
                ];

                $scope.ExpediteTypistRateArray = [
                    { id: '0.00', label: '$0.00' },
                    { id: '0.05', label: '$0.05' },
                    { id: '0.10', label: '$0.10' },
                    { id: '0.15', label: '$0.15' },
                    { id: '0.20', label: '$0.20' },
                    { id: '0.25', label: '$0.25' },
                    { id: '0.30', label: '$0.30' },
                    { id: '0.35', label: '$0.35' },
                    { id: '0.40', label: '$0.40' }
                ];

                $scope.ExpediteReporterRateArray = [
                    { id: '0.00', label: '$0.00' },
                    { id: '0.05', label: '$0.05' },
                    { id: '0.10', label: '$0.10' },
                    { id: '0.15', label: '$0.15' },
                    { id: '0.20', label: '$0.20' },
                    { id: '0.25', label: '$0.25' },
                    { id: '0.30', label: '$0.30' },
                    { id: '0.35', label: '$0.35' },
                    { id: '0.40', label: '$0.40' }
                ];

                $scope.StipsArray = [
                    { id: 'F', label: 'Federal' },
                    { id: 'S', label: 'State' },
                    { id: 'Y', label: 'None' }
                ];

                $scope.RatesArray = [
                    { id: 'D', label: 'D | Daily' },
                    { id: 'M', label: 'M | Manhattan' },
                    { id: 'MD', label: 'MD | Medical' },
                    { id: 'Q', label: 'Q | Queens' }
                ];

                $scope.refreshData = function () {
                    $scope.lock_screen = false;

                    $rootScope.showLoader(true);
                    async.series([
                        function (callback) {
                            // do some stuff ...
                            $http.get(apiUrl + '/api/get-job-by-job-resource-id', {
                                params: {
                                    jobResourceId: $scope.jobResourceId
                                }
                            }).then(function (response) {

                                $scope.editInvoiceData = response.data.result.job;
                                $scope.is_payment_required = response.data.result.job.is_payment_required.toString();
                                $scope.jobResourceData = response.data.result.resource;
                                $scope.typPayDate = $scope.editInvoiceData.TYP_PAYDT;
                                $scope.repPayDate = $scope.editInvoiceData.REP_PAYDT;
                                $scope.RepAppFee = $scope.editInvoiceData.RepAppFee || 'no';
                                $scope.TypAppFee = $scope.editInvoiceData.TypAppFee || 'no';
                                $scope.PAY_STIPS = $scope.editInvoiceData.PAY_STIPS || 'No';
                                $scope.AvgRepRate = $scope.editInvoiceData.avg_rep_rate;
                                $scope.AvgTypRate = $scope.editInvoiceData.avg_typ_rate;
                                $scope.PageRateFees = $scope.editInvoiceData.page_rate_fees;
                                $scope.TotalCopyFee = $scope.editInvoiceData.total_copy_fees;
                                $scope.REP_APPEAR = $scope.editInvoiceData.REP_APPEAR;
                                $scope.TYP_APPEAR = $scope.editInvoiceData.TYP_APPEAR;
                                $scope.totalPaidPages = $scope.editInvoiceData.REP_PGS;
                                $scope.totalPaidPagesT = $scope.editInvoiceData.TYP_PGS;
                                $scope.RepPayAmt = $scope.editInvoiceData.total_rep_pay_amt;
                                $scope.TypPayAmt = $scope.editInvoiceData.typ_pay_amt;
                                $scope.ActRepPayAmt = $scope.editInvoiceData.actual_rep_pay_amt;
                                $scope.Reimbursement = $scope.editInvoiceData.reimbursement;
                                $scope.adjNotes = $scope.editInvoiceData.reimbursement_note;
                                $scope.TotJobPay = $scope.editInvoiceData.total_job_pay_amt;
                                $scope.intDeductionPerWitness = ($scope.editInvoiceData.ded_per_wit == null || $scope.editInvoiceData.ded_per_wit === '') ? Number(0.7) : Number($scope.editInvoiceData.ded_per_wit);
                                $scope.REP_CHECK = $scope.editInvoiceData.REP_CHECK;
                                $scope.REP_NOTE = $scope.editInvoiceData.REP_NOTE;
                                $scope.TYP_CHECK = $scope.editInvoiceData.TYP_CHECK;
                                $scope.TYP_NOTE = $scope.editInvoiceData.TYP_NOTE;
                                $scope.resource_notes = $scope.editInvoiceData.resource_notes;
                                $scope.internalNote = $scope.editInvoiceData.INT_NOTE;

                                if (parseInt($scope.editInvoiceData.REP_CHECK) > 0) {

                                    if ($scope.editInvoiceData.REP_CHECK) {
                                        $scope.lock_resource = true;
                                    }

                                    $scope.lock_screen = true;
                                    if ($rootScope.havePermission('allow_access_without_password', 'allow.auth.payroll')) {
                                        $scope.lock_screen = false;
                                    }

                                    //Whenever reporter has typist (Typist select in payroll) the deduction will goes to zero.
                                    if ($scope.typistData.full_name) {
                                        $scope.deductedAmount = 0;
                                    }
                                    else {
                                        $scope.deductedAmount = $scope.roundOff(Number($scope.editInvoiceData.ded_per_wit) * Number($scope.editInvoiceData.WITNESS_NO), 2);
                                    }

                                }
                                else {

                                    //Whenever reporter has typist (Typist select in payroll) the deduction will goes to zero.
                                    if ($scope.typistData.full_name) {
                                        $scope.deductedAmount = 0;
                                    }
                                    else {
                                        $scope.deductedAmount = $scope.roundOff(Number($scope.editInvoiceData.ded_per_wit) * Number($scope.editInvoiceData.WITNESS_NO), 2);

                                    }
                                }

                                // get the reporter and typist
                                //Getting edit data from DIARY_tbl and CASE_tbl
                                var diaryDetails = {};
                                diaryDetails.id = $scope.editInvoiceData.job_no;
                                $state.$current.data.pageTitle = $scope.editInvoiceData.job_no;
                                $rootScope.$broadcast('changeTitle', $state);
                                $timeout(function () {

                                    diaryFactory.post('/api/getdiarydetails', diaryDetails)
                                        .success(function (response) {
                                            if (response && response.result && response.result.diaryDetails) {
                                                $scope.DiaryData = response.result.diaryDetails;

                                                var reporting_salesman = [];
                                                angular.forEach($scope.DiaryData.diary_reporting_salesman, function (value, key) {
                                                    if (value.reptype && value.reptype.name) {
                                                        reporting_salesman[key] = value.reptype.name;
                                                    }
                                                });
                                                $scope.editInvoiceData.SALESMAN = reporting_salesman.join(', ');
                                                if ($scope.repPayDate == "0000-00-00" || $scope.repPayDate == null || $scope.repPayDate == 'null') {
                                                    $scope.repPayDate = $scope.DiaryData.businessunit.pay_date
                                                    $scope.typPayDate = $scope.DiaryData.businessunit.pay_date
                                                }
                                                $scope.calculateAge($scope.DiaryData.DATE_TAKEN);
                                                async.series([
                                                    function (innerCallBack) {
                                                        // do some stuff ...
                                                        if ($scope.editInvoiceData.resource_type == 'R') {
                                                            $scope.editInvoiceData.reporter_id = $scope.editInvoiceData.resource_id;
                                                        }

                                                        $scope.rateInformation($scope.editInvoiceData.reporter_id, function (reporterData) {

                                                            $scope.reporterData = reporterData;
                                                            $scope.editInvoiceData.REPORTER = reporterData.NAME_KEY;
                                                            $scope.editInvoiceData.reporter_id = reporterData.id;

                                                            // Check if it already not done then we need to take first priority of override.
                                                            if ($scope.editInvoiceData.is_payment_done != 'y') {
                                                                $scope.intDeductionPerWitness = $scope.editInvoiceData.ded_per_wit != null ? Number($scope.editInvoiceData.ded_per_wit) : (reporterData.override_deduction == 'No') ? 0.7 : 0;
                                                                $scope.PAY_STIPS = $scope.editInvoiceData.PAY_STIPS ? $scope.editInvoiceData.PAY_STIPS : $scope.reporterData.pay_stips ? $scope.reporterData.pay_stips : $scope.PAY_STIPS;
                                                            }

                                                            // Get the attorney Details of Diary
                                                            firmFactory.get('/api/attorneys/' + $scope.DiaryData.attorney_id + '/edit')
                                                                .success(function (response) {
                                                                    innerCallBack(null, 'one');
                                                                    $scope.attorneyFirms = response.result.firmInfo;

                                                                    if ($scope.editInvoiceData.REP_PAYDT == '' || $scope.editInvoiceData.REP_PAYDT == null || $scope.editInvoiceData.REP_PAYDT == undefined || $scope.editInvoiceData.REP_PAYDT == '0000-00-00') {
                                                                        $scope.RepAppFee = $scope.reporterData.override_appear_rate_fee.toLowerCase();
                                                                    }
                                                                }).error(function (err) {
                                                                    innerCallBack(null, 'one');
                                                                    console.log(err);
                                                                });


                                                        });

                                                    },
                                                    function (innerCallBack) {


                                                        if ($scope.editInvoiceData.resource_type == 'T' || $scope.editInvoiceData.typist_id) {
                                                            if ($scope.editInvoiceData.resource_type == 'T' && !$scope.editInvoiceData.typist_id) {
                                                                $scope.editInvoiceData.typist_id = $scope.editInvoiceData.resource_id;
                                                            }
                                                        }
                                                        $scope.rateInformation($scope.editInvoiceData.typist_id, function (typistData) {
                                                            $scope.typistData = typistData;

                                                            $scope.editInvoiceData.TYPIST = $scope.typistData.NAME_KEY;
                                                            $scope.editInvoiceData.typist_id = $scope.typistData.id;

                                                            if ($scope.editInvoiceData.TYP_PAYDT == '' || $scope.editInvoiceData.TYP_PAYDT == null || $scope.editInvoiceData.TYP_PAYDT == undefined || $scope.editInvoiceData.TYP_PAYDT == '0000-00-00') {
                                                                $scope.TypAppFee = $scope.typistData.override_appear_rate_fee ? $scope.typistData.override_appear_rate_fee.toLowerCase() : 'no';
                                                            }
                                                            innerCallBack(null, 'two');
                                                        });


                                                    },
                                                    function (innerCallBack) {
                                                        payrollFactory.getPayment('/api/paymentinfo/' + $scope.jobResourceId).success(function (response) {
                                                            if (response && response['result']) {
                                                                $scope.paymentFound = response['result']['data']['payment_found'];
                                                                $scope.reporterPaymentData = response['result']['data']['reporter_payment'];
                                                                $scope.typistPaymentData = response['result']['data']['typist_payment'];
                                                                $scope.jobPaymentData = response['result']['data']['job_data'];
                                                                $scope.totalJobResource = response['result']['data']['total_job_resources'];
                                                                if ($scope.jobPaymentData) {
                                                                    // The rate which is blank or null into jobpayment data, will be assigned from reporter data.
                                                                    $scope.reporterData.SORn = Number($scope.jobPaymentData.SORn) > 1 ? Number($scope.jobPaymentData.SORn) : $scope.reporterData.SORn ? Number($scope.reporterData.SORn) : 2;
                                                                    $scope.reporterData.SORr = $scope.jobPaymentData.SORr || $scope.reporterData.SORr;
                                                                    $scope.reporterData.SOR = $scope.jobPaymentData.SOR || $scope.reporterData.SOR;
                                                                    $scope.reporterData.bust_fee = $scope.jobPaymentData.bust_fee || $scope.reporterData.bust_fee;
                                                                    $scope.editInvoiceData.APPEARS = Number($scope.jobPaymentData.appearance);
                                                                    $scope.editInvoiceData.T_APPEARS = Number($scope.jobPaymentData.T_APPEARS);
                                                                    $scope.intDeductionPerWitness = Number($scope.jobPaymentData.ded_per_wit || $scope.intDeductionPerWitness);
                                                                }
                                                                if ($scope.reporterPaymentData.length > 0) {
                                                                    $scope.showCalcMethodDropdown = false;

                                                                    $scope.reporterData.rate_2 = $scope.reporterPaymentData[0].Rate;
                                                                    $scope.reporterData.copy_rt = $scope.reporterPaymentData[0].copy_rate;

                                                                    $scope.calcMethod = $scope.reporterPaymentData[0].calc_method;
                                                                    if($scope.reporterPaymentData[0].calc_method == 'H'){
                                                                        $scope.reporterData.hourly_pay  = $scope.reporterPaymentData[0].appear_rate;
                                                                    }else{
                                                                        $scope.reporterData.appear = $scope.reporterPaymentData[0].appear_rate;
                                                                    }
                                                                    
                                                                    $scope.reporterData.medicalrate = $scope.reporterPaymentData[0].med_rate;
                                                                    $scope.reporterData.rate_e = $scope.reporterPaymentData[0].exp_rate;
                                                                    $scope.reporterData.rate_d = $scope.reporterPaymentData[0].daily_rate;
                                                                    $scope.reporterData.rate_i = $scope.reporterPaymentData[0].immed_rate;
                                                                }
                                                                if ($scope.typistPaymentData.length > 0) {
                                                                    $scope.typistData.rate_2 = $scope.typistPaymentData[0].TYP_RATE;
                                                                    $scope.typistData.copy_rt = $scope.typistPaymentData[0].copy_rate;
                                                                    $scope.typistData.appear = $scope.typistPaymentData[0].appear_rate;
                                                                    $scope.typistData.medicalrate = $scope.typistPaymentData[0].med_rate;
                                                                    $scope.typistData.rate_e = $scope.typistPaymentData[0].exp_rate;
                                                                    $scope.typistData.rate_d = $scope.typistPaymentData[0].daily_rate;
                                                                    $scope.typistData.rate_i = $scope.typistPaymentData[0].immed_rate;
                                                                }

                                                            }
                                                            $scope.witnesslist();
                                                            innerCallBack(null, 'three');
                                                        })
                                                            .error(function (err) {
                                                                innerCallBack(null, 'three');
                                                            });

                                                    }
                                                ],
                                                    // optional callback
                                                    function (err, results) {
                                                        // results is now equal to ['one', 'two']
                                                        callback(null, 'one');
                                                    });


                                                $scope.generalData = {};
                                                //Getting all jobcategory.
                                                diaryFactory.get('/api/jobcategories')
                                                    .success(function (response) {
                                                        if (response.result && response.result.jobcategories) {
                                                            $scope.jobcategories = response.result.jobcategories || '';
                                                            for (var i = 0; i < $scope.jobcategories.length; i++) {
                                                                if ($scope.jobcategories[i].isDefault == 1) {
                                                                    $scope.generalData.JobCategory = $scope.jobcategories[i].name;
                                                                }
                                                            }
                                                        }

                                                        //Getting All caseType
                                                        diaryFactory.get('/api/casetypes')
                                                            .success(function (response) {
                                                                if (response && response.result && response.result.casetype) {
                                                                    $scope.casetype = response.result.casetype || '';
                                                                    for (var i = 0; i < $scope.casetype.length; i++) {
                                                                        if ($scope.casetype[i].id == $scope.DiaryData.case_type_id) {
                                                                            $scope.generalData.CaseType = $scope.casetype[i].name;
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                            .error(function (err) {
                                                                $rootScope.showLoader(false);
                                                            });
                                                    })
                                                    .error(function (err) { });
                                            }
                                            else {
                                                callback(null, 'one');
                                            }

                                        })
                                        .error(function (err) {
                                            $rootScope.showLoader(false);
                                            callback(null, 'one');
                                        });
                                }, 1000);

                            });

                        },
                        function (callback) {

                            if ($scope.repPayDate == "0000-00-00" || $scope.repPayDate == null || $scope.repPayDate == 'null') {
                                $scope.repPayDate = $scope.DiaryData.businessunit.pay_date
                                $scope.typPayDate = $scope.DiaryData.businessunit.pay_date
                            }
                            else {
                                callback(null, 'two');
                            }


                        }
                    ],
                        // optional callback
                        function (err, results) {
                            // results is now equal to ['one', 'two']
                            $rootScope.showLoader(false);
                        });
                };

                // Get the New check number
                $scope.getCheckNumber = function () {
                    $http.get(apiUrl + '/api/get-next-check-number').then(function (response) {
                        $scope.REP_CHECK = response.data.result.check_number || '101';
                        $scope.TYP_CHECK = response.data.result.check_number || '101';
                    });
                };

                $scope.roundOff = function (input, limit) {
                    var output = "";
                    if (!limit || (limit < 0)) {
                        limit = 0;
                    }
                    var fraction = Math.pow(10, limit);
                    var output = Math.round(fraction * Number(input)) / fraction;
                    return output;
                };

                // function to calculate date diff
                $scope.DateDiff = {
                    inDays: function (d1, d2) {
                        var t2 = d2.getTime();
                        var t1 = d1.getTime();

                        return parseInt((t2 - t1) / (24 * 3600 * 1000));
                    },
                    inWeeks: function (d1, d2) {
                        var t2 = d2.getTime();
                        var t1 = d1.getTime();

                        return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
                    },
                    inMonths: function (d1, d2) {
                        var d1Y = d1.getFullYear();
                        var d2Y = d2.getFullYear();
                        var d1M = d1.getMonth();
                        var d2M = d2.getMonth();

                        return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
                    },
                    inYears: function (d1, d2) {
                        return d2.getFullYear() - d1.getFullYear();
                    }
                };
                $scope.calculateAge = function (date) {
                    var d1 = date ? new Date(date) : new Date();
                    var d2 = new Date();
                    $scope.today = $filter('date')(new Date(), "MM/dd/yyyy");
                    $scope.DiaryData.Age = $scope.DateDiff.inDays(d1, d2);
                };

                $scope.ownBase = function (stip) {
                    var oCopies = 0;
                    if (stip == 'S') {
                        oCopies = 2;
                    } else if (stip == 'F') {
                        oCopies = 1;
                    } else {
                        oCopies = 2;
                    }
                    return oCopies;
                };

                $scope.calPaidPages = function (pages, stips, isReporter) {
                    var n = 0;
                    if (isReporter) {
                        if ($scope.PAY_STIPS == 'Yes') {
                            n = 0;
                        } else {
                            if (stips == 'S') {
                                n = 2;
                            } else if (stips == 'F') {
                                n = 1;
                            } else {
                                n = 0;
                            }
                        }
                    } else {
                        if (stips == 'S') {
                            n = 4;
                        } else if (stips == 'F') {
                            n = 2;
                        } else {
                            n = 1;
                        }
                    }

                    var paidPages = pages - n;
                    return paidPages;
                };
                $scope.calLineRate = function (rate, medRate, copyRate, copies, extraRate, resourceType) {
                    var lineRate = 0;

                    if (resourceType == 'R') {
                        lineRate = rate + medRate + (copies * copyRate) + extraRate;
                    } else {
                        lineRate = rate + medRate + (copies * copyRate) + extraRate;
                    }
                    return lineRate;
                };
                $scope.calFinalRepRate = function (index) {
                    if ($scope.typistData.full_name) {
                        return $scope.witness_info[index].calcrate - $scope.witness_info[index].calcrateT;
                    } else {
                        return $scope.witness_info[index].calcrate;
                    }
                };
                $scope.calLineCopyRate = function (medRate, copyRate, copies, extraRate) {
                    var lineCopyRate = 0;
                    lineCopyRate = medRate + (copyRate * copies) + extraRate;
                    return lineCopyRate;
                };
                $scope.calRepAppearFee = function (appRate, appears) {
                    if ($scope.RepAppFee == "yes") {
                        return appRate * appears;
                    }
                    else {
                        return '0.00';
                    }
                };
                $scope.calTypAppearFee = function (appRate, appears) {
                    if ($scope.TypAppFee == "yes") {
                        return appRate * appears;
                    }
                    else {
                        return '0.00';
                    }
                };

                $scope.calcActualRepPayment = function () {

                    // Add the Charge of Complete additional Charge
                    if ($scope.editInvoiceData.is_payment_done != 'y') {
                        async.series([
                            function (callback) {
                                // do some stuff ...

                                $scope.RepPayAmt = $scope.witness_info.reduce(function (pv, cv) {
                                    return pv + parseFloat(cv.linepay);
                                }, 0.00);

                                callback(null, 'one');
                            },
                            function (callback) {
                                // do some more stuff ...
                                $scope.RepPayAmt = $scope.RepPayAmt + parseFloat($scope.REP_APPEAR) - parseFloat($scope.deductedAmount);
                                callback(null, 'two');
                            },
                            function (callback) {
                                // do some more stuff ...
                                var RepPayAmt = $scope.RepPayAmt ? Number($scope.RepPayAmt) : 0;
                                // var TypPayAmt = $scope.TypPayAmt ? Number($scope.TypPayAmt) : 0;
                                var Reimbursement = $scope.Reimbursement ? Number($scope.Reimbursement) : 0;
                                $scope.ActRepPayAmt = $scope.roundOff(RepPayAmt + Reimbursement, 2);
                                callback(null, 'three');
                            },
                            function (callback) {
                                // do some more stuff ...
                                $scope.calcTotalJobPay();
                                callback(null, 'two');
                            }
                        ],
                            // optional callback
                            function (err, results) {
                                // results is now equal to ['one', 'two']
                            });
                    }
                }
                $scope.calcTotalJobPay = function () {
                    var TypPayAmt = $scope.TypPayAmt ? Number($scope.TypPayAmt) : 0;
                    $scope.TotJobPay = $scope.roundOff($scope.RepPayAmt + TypPayAmt, 2);
                };

                $scope.calcRatePerWitness = function (id) {
                    $timeout(function () {
                        if ($scope.editInvoiceData.is_payment_done != 'y') {
                            if (id >= 0) {
                                if ($scope.witness_info[id].rateType == 'rate') {
                                    $scope.witness_info[id].rate = $scope.witness_info[id].rate > 0 ? Number($scope.witness_info[id].rate) : Number($scope.reporterData.rate_2);
                                } else {
                                    $scope.witness_info[id].rate = 0;
                                }
                                $scope.calcrate[id] = true;
                                $scope.linepay[id] = true;
                            }
                            // Total of line pay
                            $scope.totalLinePay = 0;
                            // Total paid pages based on stips
                            $scope.totalPaidPages = 0;
                            // Total page rate fees
                            $scope.PageRateFees = 0;
                            // Total copy fees by adding all line items rate except normal rate
                            $scope.TotalCopyFee = 0;

                            $scope.bustFee = Number($scope.reporterData.bust_fee);
                            $scope.sorRate = Number($scope.reporterData.SOR);
                            $scope.redSorRate = Number($scope.reporterData.SORr);
                            $scope.limRedSor = Number($scope.reporterData.SORn);

                            if($scope.calcMethod == 'H'){
                                $scope.REP_APPEAR = $scope.calRepAppearFee($scope.reporterData.hourly_pay, $scope.editInvoiceData.APPEARS);
                            }else{
                                $scope.REP_APPEAR = $scope.calRepAppearFee($scope.reporterData.appear, $scope.editInvoiceData.APPEARS);
                            }

                            $scope.countOfSor = 0;

                            // set total page count or calculate only when the reporter is selected
                            if ($scope.reporterData.full_name) {
                                angular.forEach($scope.witness_info, function (value, key) {

                                    value.rateType = value.rateType ? value.rateType : 'rate';
                                    value.addRate = value.addRate ? value.addRate : 'norm';
                                    value.invoiceStips = value.invoiceStips ? value.invoiceStips : 'S';
                                    value.med = value.med ? value.med : false;
                                    if (id == -1) {
                                        value.rate = Number($scope.reporterData.rate_2);
                                    } else {
                                        value.rate = value.rate ? Number(value.rate) : Number($scope.reporterData.rate_2);
                                    }
                                    value.additionalCopies = value.additionalCopies > 0 ? Number(value.additionalCopies) : 0;

                                    value.oCopies = $scope.ownBase(value.invoiceStips);

                                    var extraRate = 0;
                                    if (value.addRate == 'exp') {
                                        extraRate = Number($scope.reporterData.rate_e);
                                    } else if (value.addRate == 'daily') {
                                        extraRate = Number($scope.reporterData.rate_d);
                                    } else if (value.addRate == 'immed') {
                                        extraRate = Number($scope.reporterData.rate_i);
                                    } else {
                                        extraRate = 0;
                                    }

                                    if (value.rateType == 'sor') {
                                        // if user manually change the paid page total then we need to consider first to user change
                                        if (!value.manually_changed_reporter) {
                                            value.paidPages = 0;
                                        }
                                        $scope.countOfSor += 1;
                                        value.rate = 0;
                                        // if we have SOR selected for reporter then we need to deduct the typist line pay amount from the reporter line pay amount
                                        if ($scope.typistData.full_name) {
                                            value.linepay = ($scope.sorRate || 0) - (value.linepayT || 0);
                                        } else {
                                            value.linepay = ($scope.sorRate || 0);
                                        }

                                        if ($scope.countOfSor > $scope.limRedSor) {
                                            value.linepay = $scope.redSorRate;

                                            if ($scope.typistData.full_name) {
                                                value.linepay = (value.linepay || 0) - (value.linepayT || 0);
                                            }

                                        }
                                        // we convert rate to 0 because in SOR case we are not considering the rates into calculation
                                        value.calcrate = 0;
                                        value.finalRate = 0;
                                    } else if (value.rateType == 'bust') {
                                        // if user manually change the paid page total then we need to consider first to user change
                                        if (!value.manually_changed_reporter) {
                                            value.paidPages = 0;
                                        }
                                        value.rate = 0;
                                        // we convert rate to 0 because in SOR case we are not considering the rates into calculation
                                        value.calcrate = 0;
                                        value.finalRate = 0;
                                        value.linepay = $scope.bustFee;

                                        // Need to set the deduction as 0.00 and Pay stips to No
                                        $scope.intDeductionPerWitness = 0;
                                        $scope.PAY_STIPS = 'No';


                                    } else {
                                        // if user manually change the paid page total then we need to consider first to user change
                                        if (!value.manually_changed_reporter) {
                                            value.paidPages = (value.stip_manually_change_reporter) ? $scope.calPaidPages(value.PAGES, value.invoiceStips, true) : ($scope.paymentFound && $scope.reporterPaymentData[key] && $scope.reporterPaymentData[key]['paid_pages']) ? $scope.reporterPaymentData[key]['paid_pages'] : $scope.calPaidPages(value.PAGES, value.invoiceStips, true);
                                        }
                                        value.calcrate = $scope.calLineRate(value.rate, value.med ? Number($scope.reporterData.medicalrate) : 0, Number($scope.reporterData.copy_rt), value.additionalCopies, extraRate);
                                        value.finalRate = $scope.calFinalRepRate(key);
                                        value.linepay = value.finalRate * value.paidPages;
                                        value.calcrate = $scope.roundOff(value.calcrate, 2);
                                        value.linepay = $scope.roundOff(value.linepay, 3);
                                    }

                                    // if enright sync data then make line item zero
                                    if (value.rateType == 'sync_data') {
                                        value.addRate = '';
                                        value.additionalCopies = 0;
                                        value.calcrate = 0;
                                        value.finalRate = 0;
                                        value.linepay = 0;
                                        value.med = false;
                                    }
                                    if (value.rateTypeT == 'sync_data') {
                                        value.addRateT = '';
                                        value.additionalCopiesT = 0;
                                        value.calcrateT = 0;
                                        value.linepayT = 0;
                                        value.medT = false;
                                    }
                                    if($scope.is_payment_required == '0'){
                                        value.linepay = 0;
                                        value.linepayT = 0;
                                       }
                                    $scope.totalPaidPages = Number($scope.totalPaidPages) + Number(value.paidPages);
                                    $scope.PageRateFees = $scope.PageRateFees + (value.rate * value.paidPages);
                                    var lineCopyRate = $scope.calLineCopyRate(value.med ? Number($scope.reporterData.medicalrate) : 00, $scope.reporterData.copy_rt, value.additionalCopies, extraRate);
                                    $scope.TotalCopyFee = $scope.TotalCopyFee + (lineCopyRate * value.paidPages);
                                    $scope.totalLinePay = $scope.totalLinePay + value.linepay;


                                });
                            }

                            if ($scope.totalPaidPages == 0) {
                                $scope.AvgRepRate = 0;
                            } else {
                                $scope.AvgRepRate = Number($scope.totalLinePay / $scope.totalPaidPages);
                            }


                            $scope.intDeductionPerWitness = $scope.intDeductionPerWitness ? Number($scope.intDeductionPerWitness) : 0;

                            //Whenever reporter has typist (Typist select in payroll) the deduction will goes to zero.
                            if ($scope.typistData.full_name) {
                                $scope.deductedAmount = 0;
                            }
                            else {
                                $scope.deductedAmount = $scope.roundOff($scope.intDeductionPerWitness * $scope.witness_info.length, 2);
                            }
                            // $scope.RepPayAmt = $scope.PageRateFees + $scope.TotalCopyFee + $scope.REP_APPEAR - ($scope.intDeductionPerWitness * $scope.witness_info.length);
                            $scope.RepPayAmt = Number($scope.totalLinePay) + Number($scope.REP_APPEAR) - Number($scope.deductedAmount);

                            $scope.AvgRepRate = $scope.roundOff($scope.AvgRepRate, 2);
                            $scope.PageRateFees = $scope.roundOff($scope.PageRateFees, 2);
                            $scope.TotalCopyFee = $scope.roundOff($scope.TotalCopyFee, 2);
                            $scope.RepPayAmt = $scope.roundOff($scope.RepPayAmt, 2);

                            $scope.calcActualRepPayment();

                            $scope.isAvgRepRate = true;
                            $scope.isTotalCopyFee = true;
                            $scope.isRepPayAmt = true;
                            $timeout(function () {
                                if (id >= 0) {
                                    $scope.calcrate[id] = false;
                                    $scope.linepay[id] = false;
                                }
                                $scope.isAvgRepRate = false;
                                $scope.isTotalCopyFee = false;
                                $scope.isRepPayAmt = false;
                            }, 300);
                        }
                    }, 1000);
                };
                $scope.calcTRatePerWitness = function (id) {
                    $timeout(function () {
                        if ($scope.editInvoiceData.is_payment_done != 'y') {
                            if (id >= 0) {
                                if ($scope.witness_info[id].rateTypeT == 'rate') {
                                    $scope.witness_info[id].rateT = $scope.witness_info[id].rateT > 0 ? Number($scope.witness_info[id].rateT) : Number($scope.typistData.rate_2);
                                } else {
                                    $scope.witness_info[id].rateT = 0;
                                }
                                $scope.calcrateT[id] = true;
                                $scope.linepayT[id] = true;
                            }
                            // Total of line pay
                            $scope.totalLinePayT = 0;
                            // Total paid pages based on stips
                            $scope.totalPaidPagesT = 0;
                            // Total page rate fees
                            $scope.PageRateFeesT = 0;
                            // Total copy fees by adding all line items rate except normal rate
                            $scope.TotalCopyFeeT = 0;

                            $scope.bustFeeT = Number($scope.reporterData.bust_fee);
                            $scope.sorRateT = Number($scope.reporterData.SOR);
                            $scope.redSorRateT = Number($scope.reporterData.SORr);
                            $scope.limRedSorT = Number($scope.reporterData.SORn);

                            $scope.TYP_APPEAR = $scope.calTypAppearFee($scope.typistData.appear, $scope.editInvoiceData.T_APPEARS);

                            $scope.countOfSorT = 0;
                            // set total page count only when the Typist is selected
                            if ($scope.typistData.full_name) {
                                angular.forEach($scope.witness_info, function (value, key) {
                                    // Set the typist value
                                    value.PAGE_END_T = value.PAGE_END || 0;
                                    value.PAGE_START_T = value.PAGE_START || 0;
                                    value.PAGES_T = value.PAGES || 0;
                                    value.rateTypeT = value.rateTypeT ? value.rateTypeT : 'rate';
                                    value.addRateT = value.addRateT ? value.addRateT : 'norm';
                                    value.invoiceStipsT = value.invoiceStipsT ? value.invoiceStipsT : 'S';
                                    value.medT = value.medT ? value.medT : false;
                                    if (id == -1) {
                                        value.rateT = Number($scope.typistData.rate_2);
                                    } else {
                                        value.rateT = value.rateT ? Number(value.rateT) : Number($scope.typistData.rate_2);
                                    }
                                    value.additionalCopiesT = value.additionalCopiesT ? value.additionalCopiesT : 0;

                                    value.oCopiesT = $scope.ownBase(value.invoiceStipsT);

                                    var extraRateT = 0;
                                    if (value.addRateT == 'exp') {
                                        extraRateT = Number($scope.typistData.rate_e);
                                    } else if (value.addRateT == 'daily') {
                                        extraRateT = Number($scope.typistData.rate_d);
                                    } else if (value.addRateT == 'immed') {
                                        extraRateT = Number($scope.typistData.rate_i);
                                    } else {
                                        extraRateT = 0;
                                    }

                                    if (value.rateTypeT == 'sor') {
                                        if (!value.manually_changed_typist) {
                                            value.paidPagesT = 0;
                                        }
                                        $scope.countOfSorT += 1;
                                        value.rateT = 0;
                                        value.linepayT = $scope.sorRateT;

                                        if ($scope.countOfSorT > $scope.limRedSorT) {
                                            value.linepayT = $scope.redSorRateT;
                                        }

                                        value.calcrateT = $scope.calLineRate(value.rateT, value.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), value.additionalCopiesT, extraRateT);
                                        value.finalRate = $scope.calFinalRepRate(key);
                                    } else if (value.rateTypeT == 'bust') {
                                        if (!value.manually_changed_typist) {
                                            value.paidPagesT = 0;
                                        }
                                        value.rateT = 0;
                                        value.calcrateT = $scope.calLineRate(value.rateT, value.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), value.additionalCopiesT, extraRateT);
                                        value.finalRate = $scope.calFinalRepRate(key);
                                        value.linepayT = $scope.bustFeeT;
                                    } else {
                                        if (!value.manually_changed_typist) {

                                            value.paidPagesT = (value.stip_manually_change_typist) ? $scope.calPaidPages(value.PAGES_T, value.invoiceStipsT, false) : ($scope.paymentFound && $scope.typistPaymentData[key] && $scope.typistPaymentData[key]['paid_pages']) ? $scope.typistPaymentData[key]['paid_pages'] : $scope.calPaidPages(value.PAGES_T, value.invoiceStipsT, false);
                                        }
                                        value.calcrateT = $scope.calLineRate(value.rateT, value.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), value.additionalCopiesT, extraRateT);

                                        // Reporter calculation -> because typist is every time load after the reporter so
                                        value.finalRate = $scope.calFinalRepRate(key);
                                        value.linepay = value.finalRate * value.paidPages;

                                        value.linepayT = value.calcrateT * value.paidPagesT;
                                        value.calcrateT = $scope.roundOff(value.calcrateT, 2);
                                        value.linepayT = $scope.roundOff(value.linepayT, 3);
                                    }
                                    if (value.rateType == 'sor') {

                                        if ($scope.typistData.full_name) {
                                            value.linepay = ($scope.sorRate || 0) - (value.linepayT || 0);
                                        } else {
                                            value.linepay = ($scope.sorRate || 0);
                                        }

                                        // if we have SOR selected for reporter then we need to deduct the typist line pay amount from the reporter line pay amount
                                        if ($scope.countOfSor > $scope.limRedSor) {
                                            value.linepay = $scope.redSorRate;

                                            if ($scope.typistData.full_name) {
                                                value.linepay = (value.linepay || 0) - (value.linepayT || 0);
                                            }

                                        }

                                    }
                                    // if rate type is sync data thenmake line item zero
                                    if (value.rateTypeT == 'sync_data') {
                                        value.addRateT = '';
                                        value.additionalCopiesT = 0;
                                        value.calcrateT = 0;
                                        value.linepayT = 0;
                                        value.medT = false;
                                    }
                                    if (value.rateType == 'sync_data') {
                                        value.addRate = '';
                                        value.additionalCopies = 0;
                                        value.calcrate = 0;
                                        value.finalRate = 0;
                                        value.linepay = 0;
                                        value.med = false;

                                    }
                                    $scope.totalPaidPagesT = Number($scope.totalPaidPagesT) + Number(value.paidPagesT);
                                    $scope.PageRateFeesT = $scope.PageRateFeesT + (value.rateT * value.paidPagesT);
                                    var lineCopyRateT = $scope.calLineCopyRate(value.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), value.additionalCopiesT, extraRateT);
                                    $scope.TotalCopyFeeT = $scope.TotalCopyFeeT + (lineCopyRateT * value.paidPagesT);
                                    $scope.totalLinePayT = $scope.totalLinePayT + value.linepayT;
                                });
                            }

                            if ($scope.totalPaidPagesT == 0) {
                                $scope.AvgTypRate = 0;
                            } else {
                                $scope.AvgTypRate = Number($scope.totalLinePayT / $scope.totalPaidPagesT);
                            }

                            $scope.intDeductionPerWitness = $scope.intDeductionPerWitness ? Number($scope.intDeductionPerWitness) : 0;


                            //Whenever reporter has typist (Typist select in payroll) the deduction will goes to zero.
                            if ($scope.typistData.full_name) {
                                $scope.deductedAmount = 0;
                            }
                            else {
                                $scope.deductedAmount = $scope.roundOff($scope.intDeductionPerWitness * $scope.witness_info.length, 2);

                            }
                            $scope.TypPayAmt = Number($scope.totalLinePayT) + Number($scope.TYP_APPEAR);

                            $scope.AvgTypRate = $scope.roundOff($scope.AvgTypRate, 2);
                            $scope.PageRateFeesT = $scope.roundOff($scope.PageRateFeesT, 2);
                            $scope.TotalCopyFeeT = $scope.roundOff($scope.TotalCopyFeeT, 2);
                            $scope.TypPayAmt = $scope.roundOff($scope.TypPayAmt, 2);

                            $scope.calcActualRepPayment();

                            $timeout(function () {
                                if (id >= 0) {
                                    $scope.calcrateT[id] = false;
                                    $scope.linepayT[id] = false;
                                }
                            }, 300);
                        }
                    }, 1000);
                };

                //Getting list of witnesses for the same JOB_NO
                $scope.witnesslist = function () {
                    if ($scope.editInvoiceData.job_no) {
                        var data = {};
                        data.id = $scope.editInvoiceData.job_no;

                        diaryFactory.getwitnessinfo('/api/getwitnessfordiaryinvoice/', data.id)
                            .success(function (response) {
                                if (response.result && response.result.witnessDetails) {

                                    $scope.witness_info = response.result.witnessDetails;
                                    $scope.totalIndex = $scope.witness_info.length - 1;

                                    angular.forEach($scope.witness_info, function ($item, $index) {
                                        var $typist_item = angular.copy($item);
                                        var witness = $filter('filter')($scope.reporterPaymentData, { witness_id: $item.id })[0];

                                        if (witness) {
                                            $scope.witness_info[$index].PAGE_START = witness['start_page'];
                                            $scope.witness_info[$index].PAGE_END = witness['end_page'];
                                            $scope.witness_info[$index].PAGES = witness['REP_PGS'];
                                            $scope.witness_info[$index].paidPages = witness['paid_pages'];
                                            $scope.witness_info[$index].invoiceStips = witness['STIPS'];
                                            if (witness['STIPS'] == 'F') {
                                                $scope.witness_info[$index].oCopies = 1;
                                            } else {
                                                $scope.witness_info[$index].oCopies = 2;
                                            }
                                            $scope.witness_info[$index].additionalCopies = witness['copies'];
                                            $scope.witness_info[$index].rate = witness['Rate'];
                                            $scope.witness_info[$index].rateType = witness['rate_type'];
                                            $scope.witness_info[$index].med = (witness['is_med'] == 'y') ? true : false;
                                            if (witness['is_exp'] == 'y') {
                                                $scope.witness_info[$index].addRate = 'exp';
                                            } else if (witness['is_daily'] == 'y') {
                                                $scope.witness_info[$index].addRate = 'daily';
                                            } else if (witness['is_immed'] == 'y') {
                                                $scope.witness_info[$index].addRate = 'immed';
                                            } else {
                                                $scope.witness_info[$index].addRate = 'norm';
                                            }
                                            $scope.witness_info[$index].calcrate = witness['line_rate'];
                                            $scope.witness_info[$index].linepay = witness['line_pay_amt'];
                                        }
                                        else {
                                            $item.rateType = $item.rateType ? $item.rateType : 'rate';
                                            $item.addRate = $item.addRate ? $item.addRate : 'norm';
                                            $item.invoiceStips = $item.invoiceStips ? $item.invoiceStips : 'S';

                                            $item.med = $item.med ? $item.med : false;

                                            $item.rate = $item.rate ? Number($item.rate) : Number($scope.reporterData.rate_2);


                                            //Set extra copy as a zero as per requirement in the email by Mark & Jodi.
                                            // E-Mail content: Extra copies should always default to 0 .. it is now defaulting to 2!  It should be 0 until we want to pay extra copies so it should default to 0.   The on 0+ should default to 2 not the extra copies.

                                            $item.additionalCopies = 0;
                                            $item.oCopies = 2;

                                            var extraRate = 0;
                                            if ($item.addRate == 'exp') {
                                                extraRate = Number($scope.reporterData.rate_e);
                                            } else if ($item.addRate == 'daily') {
                                                extraRate = Number($scope.reporterData.rate_d);
                                            } else if ($item.addRate == 'immed') {
                                                extraRate = Number($scope.reporterData.rate_i);
                                            } else {
                                                extraRate = 0;
                                            }

                                            if ($item.rateType == 'sor') {
                                                $item.paidPages = 0;
                                                $scope.countOfSor += 1;
                                                $item.rate = 0;
                                                $item.linepay = $scope.sorRate;
                                                if ($scope.countOfSor >= $scope.limRedSor) {
                                                    $item.linepay = $scope.redSorRate;
                                                }
                                                $item.calcrate = $scope.calLineRate($item.rate, $item.med ? Number($scope.reporterData.medicalrate) : 0, Number($scope.reporterData.copy_rt), $item.additionalCopies, extraRate);
                                            } else if ($item.rateType == 'bust') {
                                                $item.paidPages = 0;
                                                $item.rate = 0;
                                                $item.calcrate = $scope.calLineRate($item.rate, $item.med ? Number($scope.reporterData.medicalrate) : 0, Number($scope.reporterData.copy_rt), $item.additionalCopies, extraRate);
                                                $item.linepay = $scope.bustFee;
                                            } else {
                                                // $item.paidPages = $scope.calPaidPages($item.PAGES, $item.invoiceStips, true);
                                                $item.paidPages = ($scope.paymentFound && $scope.reporterPaymentData[$index] && $scope.reporterPaymentData[$index]['paid_pages']) ? $scope.reporterPaymentData[$index]['paid_pages'] : $scope.calPaidPages($item.PAGES, $item.invoiceStips, true);;
                                                $item.calcrate = $scope.calLineRate($item.rate, $item.med ? Number($scope.reporterData.medicalrate) : 0, Number($scope.reporterData.copy_rt), $item.additionalCopies, extraRate);
                                                $item.linepay = $item.calcrate * $item.paidPages;
                                                $item.calcrate = $scope.roundOff($item.calcrate, 2);
                                                $item.linepay = $scope.roundOff($item.linepay, 3);
                                            }
                                            if ($scope.editInvoiceData.is_payment_done != 'y') {
                                                $scope.totalPaidPages = $scope.totalPaidPages + $item.paidPages;
                                            }
                                            $scope.PageRateFees = $scope.PageRateFees + ($item.rate * $item.paidPages);
                                            var lineCopyRate = $scope.calLineCopyRate($item.med ? Number($scope.reporterData.medicalrate) : 00, $scope.reporterData.copy_rt, $item.additionalCopies, extraRate);
                                            $scope.TotalCopyFee = $scope.TotalCopyFee + (lineCopyRate * $item.paidPages);
                                            $scope.totalLinePay = $scope.totalLinePay + $item.linepay;
                                            $scope.witness_info[$index] = $item;
                                        }

                                        var typist_witness = $filter('filter')($scope.typistPaymentData, { witness_id: $item.id })[0];
                                        if (typist_witness) {
                                            $scope.witness_info[$index].PAGE_START_T = typist_witness['start_page'];
                                            $scope.witness_info[$index].PAGE_END_T = typist_witness['end_page'];
                                            $scope.witness_info[$index].PAGES_T = typist_witness['REP_PGS'];
                                            $scope.witness_info[$index].paidPagesT = ($scope.paymentFound && $scope.typistPaymentData[$index] && $scope.typistPaymentData[$index]['paid_pages']) ? $scope.typistPaymentData[$index]['paid_pages'] : typist_witness['paid_pages'];
                                            $scope.witness_info[$index].invoiceStipsT = typist_witness['STIPS'];
                                            if (typist_witness['STIPS'] == 'F') {
                                                $scope.witness_info[$index].oCopiesT = 1;
                                            } else {
                                                $scope.witness_info[$index].oCopiesT = 2;
                                            }

                                            $scope.witness_info[$index].additionalCopiesT = typist_witness['copies'];
                                            $scope.witness_info[$index].rateT = typist_witness['TYP_RATE'];
                                            $scope.witness_info[$index].rateTypeT = typist_witness['rate_type'];
                                            $scope.witness_info[$index].medT = (typist_witness['is_med'] == 'y') ? true : false;
                                            if (typist_witness['is_exp'] == 'y') {
                                                $scope.witness_info[$index].addRateT = 'exp';
                                            } else if (typist_witness['is_daily'] == 'y') {
                                                $scope.witness_info[$index].addRateT = 'daily';
                                            } else if (typist_witness['is_immed'] == 'y') {
                                                $scope.witness_info[$index].addRateT = 'immed';
                                            } else {
                                                $scope.witness_info[$index].addRateT = 'norm';
                                            }
                                            $scope.witness_info[$index].calcrateT = typist_witness['line_rate'];
                                            $scope.witness_info[$index].linepayT = typist_witness['line_pay_amt'];
                                        }
                                        else {

                                            // Set the typist value
                                            $item.PAGE_END_T = $item.PAGE_END || 0;
                                            $item.PAGE_START_T = $item.PAGE_START || 0;
                                            $item.PAGES_T = $item.PAGES || 0;
                                            $item.rateTypeT = $item.rateTypeT ? $item.rateTypeT : 'rate';
                                            $item.addRateT = $item.addRateT ? $item.addRateT : 'norm';
                                            $item.invoiceStipsT = $item.invoiceStipsT ? $item.invoiceStipsT : 'S';
                                            $item.medT = $item.medT ? $item.medT : false;
                                            $item.rateT = $item.rateT ? Number($item.rateT) : Number($scope.typistData.rate_2);
                                            $item.additionalCopiesT = $item.additionalCopiesT ? $item.additionalCopiesT : 0;
                                            $item.oCopiesT = $scope.ownBase($item.invoiceStipsT);
                                            var extraRateT = 0;
                                            if ($item.addRateT == 'exp') {
                                                extraRateT = Number($scope.typistData.rate_e);
                                            } else if ($item.addRateT == 'daily') {
                                                extraRateT = Number($scope.typistData.rate_d);
                                            } else if ($item.addRateT == 'immed') {
                                                extraRateT = Number($scope.typistData.rate_i);
                                            } else {
                                                extraRateT = 0;
                                            }

                                            if ($item.rateTypeT == 'sor') {
                                                $item.paidPagesT = 0;
                                                $scope.countOfSorT += 1;
                                                $item.rateT = 0;
                                                $item.linepayT = $scope.sorRateT;
                                                if ($scope.countOfSorT >= $scope.limRedSorT) {
                                                    $item.linepayT = $scope.redSorRateT;
                                                }
                                                $item.calcrateT = $scope.calLineRate($item.rateT, $item.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), $item.additionalCopiesT, extraRateT);
                                            } else if ($item.rateTypeT == 'bust') {
                                                $item.paidPagesT = 0;
                                                $item.rateT = 0;
                                                $item.calcrateT = $scope.calLineRate($item.rateT, $item.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), $item.additionalCopiesT, extraRateT);
                                                $item.linepayT = $scope.bustFeeT;
                                            } else {
                                                $item.paidPagesT = $scope.calPaidPages($item.PAGES_T, $item.invoiceStipsT, false);
                                                $item.calcrateT = $scope.calLineRate($item.rateT, $item.medT ? Number($scope.typistData.medicalrate) : 0, Number($scope.typistData.copy_rt), $item.additionalCopiesT, extraRateT);
                                                $item.linepayT = $item.calcrateT * $item.paidPagesT;
                                                $item.calcrateT = $scope.roundOff($item.calcrateT, 2);
                                                $item.linepayT = $scope.roundOff($item.linepayT, 3);
                                            }

                                            $scope.witness_info[$index] = $item;
                                        }
                                        $scope.isCalculateRate = true;
                                        $scope.calcTRatePerWitness();
                                        $scope.calcRatePerWitness();
                                    });

                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                };
                $scope.rateInformation = function (id, cb) {
                    var nullData = {};
                    if (id && id != null) {
                        representativeFactory.get('/api/reptype/' + id + '/edit')
                            .success(function (response) {
                                if (response && response.result && response.result.reptypeInfo) {
                                    var reporterData = {};
                                    reporterData.NAME_KEY = response.result.reptypeInfo.NAME_KEY;
                                    reporterData.id = response.result.reptypeInfo.id;
                                    reporterData.title = response.result.reptypeInfo.TITLE;
                                    reporterData.first_name = response.result.reptypeInfo.FIRST_NAME;
                                    reporterData.last_name = response.result.reptypeInfo.LAST_NAME;
                                    reporterData.full_name = reporterData.first_name + " " + reporterData.last_name;
                                    reporterData.email_address = response.result.reptypeInfo.Email_Address;
                                    reporterData.phone = response.result.reptypeInfo.PHONE;
                                    reporterData.addr_line1 = response.result.reptypeInfo.ADDR_LINE1;
                                    reporterData.city = response.result.reptypeInfo.CITY;
                                    reporterData.state = response.result.reptypeInfo.STATE;
                                    reporterData.zip = response.result.reptypeInfo.ZIP;
                                    reporterData.soc_sec_no = response.result.reptypeInfo.SOC_SEC_NO;
                                    reporterData.cell = response.result.reptypeInfo.CELL;
                                    reporterData.addr_line2 = response.result.reptypeInfo.ADDR_LINE2;
                                    reporterData.rate_1 = response.result.reptypeInfo.RATE_1;
                                    reporterData.rate_2 = response.result.reptypeInfo.RATE_2;
                                    reporterData.medicalrate = response.result.reptypeInfo.MedicalRate;
                                    reporterData.copy_rt = response.result.reptypeInfo.COPY_RT;
                                    reporterData.appear = response.result.reptypeInfo.APPEAR;
                                    reporterData.hourly_pay = response.result.reptypeInfo.hourly_pay;

                                    if(reporterData.appear > 0 && reporterData.hourly_pay > 0){
                                        $scope.showCalcMethodDropdown = true;
                                    }else if(reporterData.hourly_pay > 0){
                                        $scope.calcMethod = 'H';
                                    }
                                    
                                    reporterData.pay_stips = response.result.reptypeInfo.PAY_STIPS || 'No';
                                    reporterData.override_appear_rate_fee = response.result.reptypeInfo.override_appear_rate_fee || 'No';
                                    reporterData.override_deduction = response.result.reptypeInfo.override_deduction || 'No';
                                    reporterData.rate_e = response.result.reptypeInfo.RATE_E;
                                    reporterData.rate_d = response.result.reptypeInfo.RATE_D;
                                    reporterData.rate_i = response.result.reptypeInfo.RATE_I;
                                    reporterData.beeper = response.result.reptypeInfo.BEEPER;
                                    reporterData.late_app = response.result.reptypeInfo.LATE_APP;
                                    reporterData.payment_type = response.result.reptypeInfo.payment_type;
                                    reporterData.notary_number = response.result.reptypeInfo.NOTARY_NUMBER;
                                    reporterData.notary_expiration_date = response.result.reptypeInfo.NOTARY_EXPIRATION_DATE;
                                    reporterData.rate_l = response.result.reptypeInfo.RATE_L;
                                    reporterData.curr_pay = response.result.reptypeInfo.CURR_PAY;
                                    reporterData.prev_pay = response.result.reptypeInfo.PREV_PAY;
                                    reporterData.date_added = response.result.reptypeInfo.DATE_ADDED;
                                    reporterData.last_chgd = response.result.reptypeInfo.LAST_CHGD;
                                    reporterData.notes_1 = response.result.reptypeInfo.NOTES_1;
                                    reporterData.notes_2 = response.result.reptypeInfo.NOTES_2;
                                    reporterData.routing_number = response.result.reptypeInfo.ROUTING_NUMBER;
                                    reporterData.account_number = response.result.reptypeInfo.ACCOUNT_NUMBER;
                                    reporterData.p1099 = response.result.reptypeInfo.P1099;
                                    reporterData.curr_paydt = response.result.reptypeInfo.CURR_PAYDT;
                                    reporterData.ytd_pay = response.result.reptypeInfo.YTD_PAY;
                                    reporterData.status = response.result.reptypeInfo.STATUS;
                                    reporterData.ytd_pgs = response.result.reptypeInfo.YTD_PGS;
                                    reporterData.rate_changed_by = response.result.reptypeInfo.RATE_CHANGED_BY;
                                    reporterData.rate_change_date = response.result.reptypeInfo.RATE_CHANGE_DATE;
                                    reporterData.rate_change_reason = response.result.reptypeInfo.RATE_CHANGE_REASON;
                                    reporterData.SORn = Number(response.result.reptypeInfo.SORn) > 1 ? Number(response.result.reptypeInfo.SORn) : 2;
                                    reporterData.SORr = response.result.reptypeInfo.SORr;
                                    reporterData.SOR = response.result.reptypeInfo.SOR;
                                    reporterData.bust_fee = response.result.reptypeInfo.bust_fee;
                                    reporterData.expidite_1_2_days_rate = response.result.reptypeInfo.expidite_1_2_days_rate;
                                    reporterData.dwi_flat_rate = response.result.reptypeInfo.dwi_flat_rate;
                                    reporterData.workers_comp_exp_rate = response.result.reptypeInfo.workers_comp_exp_rate;
                                    reporterData.workers_comp_over_min_page_rate = response.result.reptypeInfo.workers_comp_over_min_page_rate;
                                    reporterData.workers_comp_flat_pages_go_over = response.result.reptypeInfo.workers_comp_flat_pages_go_over;
                                    cb(reporterData);
                                } else {
                                    console.log("Not get any response");
                                    cb(nullData);
                                }
                            })
                            .error(function (err) {
                                console.log(err);
                                cb(nullData);
                            });
                    } else {
                        cb(nullData);
                    }
                };

                $scope.ChangeResorcePaymentValue =  function(id,value) {
           
                    if(value == '0'){
                    swal({
                        title: "Payment Not Required",
                        text: 'Enter Reasons Why Payment Not Required:',
                        type: 'input',
                        showCancelButton: false,
                        closeOnConfirm: true,
                        confirmButtonText: "Submit",
                        animation: "slide-from-top"
                      }, function(inputValue){
                       
                        if(inputValue){
                            $rootScope.showLoader(true);  
                            var postEntryData = {is_payment_required:value,id:id,payment_required_reason:inputValue} 
                            $http.post(apiUrl+'/api/updateJobResorcePayment',postEntryData).success(function(res){
                            $rootScope.showLoader(false);
                            if(res.error){
                                Notification.error(res.result.message);
                            }else{
                                $scope.refreshData();
                                Notification.success(res.result.message);
                            }
                            }).error(function(err){
                                $rootScope.showLoader(false);
                                Notification.error(err);
                            });
                        }else{
                            document.getElementById("ResorcePaymentValueYes"+id).selected=true;
                            $scope.is_payment_required = '1'
                           
                        }
                      });
                    }else{
                        $rootScope.showLoader(true);  
                        var postEntryData = {is_payment_required:value,id:id} 
                        $http.post(apiUrl+'/api/updateJobResorcePayment',postEntryData).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            $scope.refreshData();
                            Notification.success(res.result.message);
                        }
                        }).error(function(err){
                            $rootScope.showLoader(false);
                            Notification.error(err);
                        });
                    }
              
                }

                if ($scope.jobResourceId) {
                    $scope.refreshData();
                }

            }]);
