angular.module('deitz')
    .controller('addMarkedExhibitsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'JOB_NO',
        'depoUserId',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  JOB_NO,
                  depoUserId,
                  $uibModalInstance,
                  configFactory) {
            $scope.markedExhibit = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'MANAGE MARKED EXHIBITS';
            $scope.isPdfUploaded = false;
            $scope.markedExhibit.JOB_NO = JOB_NO;
            $scope.markedExhibit.depoUserId = depoUserId;
            $scope.markedExhibit.allFiles = [];

            $scope.clickUpload = function(){
                $("#file").trigger('click');
            }

            $scope.removeFile = function(index){
                var attachments = document.getElementById("file").files;
                var fileBuffer = new DataTransfer();

                for (let i = 0; i < attachments.length; i++) {
                    if (index !== i){
                        fileBuffer.items.add(attachments[i]);
                    }
                }
                
                document.getElementById("file").files = fileBuffer.files; 
                $scope.markedExhibit.file.splice(index, 1); 
                $scope.markedExhibit.allFiles.splice(index, 1);

                $scope.dropboxText();
            }
            
            $scope.fileChange = function(){
                var file = $('#file')[0].files;
                let filenames = [];
                let allFiles = [];
                for(j=0; j < file.length ; j++){
                    filenames.push(file[j].name);
                    let obj = {};
                    obj.filename = file[j].name;
                    obj.exhibit_type = '';
                    obj.exhibit_number = '';
                    allFiles.push(obj);
                }
                $scope.markedExhibit.allFiles = allFiles;

                $scope.dropboxText();
            }

            $scope.dropboxText = function(){
                var file = $('#file')[0].files;
                if(file.length > 0){
                    $(".upload_h1").html(file.length+" file(s) selected. <br> Provide a below information and press the 'save' button.");
                }else{
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            commonFactory.get('/api/getwitnessfordiary/' + JOB_NO)
                .success(function (response) {
                    if (response.result && response.result.witnessDetails) {
                        $rootScope.showLoader(false);
                        $scope.witness_info = response.result.witnessDetails;
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

            $scope.addMarkedExhibit = function(markedExhibit, isValid) {
                $scope.formSubmit = true;  
                if (isValid) {
                    $rootScope.showLoader(true);

                    Upload.upload({
                        url: apiUrl+'/api/marked-exhibits',
                        data: $scope.markedExhibit
                    }).then(function (response) { 
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.data.status == 200) {
                                Notification.success(response.data.result.message);
                                $scope.closePopupWindowSimple();
                            }else if(response.data.status == 400){
                                Notification.error(response.data.result.message);
                            }
                        }
                    },function(err){
                        $rootScope.showLoader(false);
                    });
                    
                }else{
                    if($scope.markedExhibitsCreateForm.$error.pattern){
                        Notification.error('Please upload accepted file only.');
                    }else{
                        Notification.error('Exhibit Type, Exhibit Number, Witness(es) - must be required.');
                    }
                }
            }
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.dismiss({ isChanged : true});
            };
        }]);