angular.module('deitz')
            .controller('attendeeQueueController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$uibModal',
                    'Notification',
                    '$filter',
                    '$timeout',
                    '$q', 
                    'SweetAlert',
                    '$state',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $uibModal,
                            Notification,
                            $filter,
                            $timeout,
                            $q,
                            SweetAlert,
                            $state
                        ) {
        
        $scope.dtInstance = {};
        $scope.dateRange
        $scope.AttdArray = {};
        $scope.selectedAttdArray= []
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function(){
            $scope.dateRange = null;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
        $scope.SelectAttd = function (is_checked, id) {

            const AttdObject = {id: id};
            const index = $scope.selectedAttdArray.findIndex(o =>
                 o.id == id
            );


            if (is_checked) {
                if (typeof index == 'undefined' || index <= -1) {
                    $scope.selectedAttdArray.push(AttdObject);
                }
            }
            else {

                if (typeof index !== 'undefined' && index > -1) {
                    $scope.selectedAttdArray.splice(index, 1);
                }
            }
            console.log($scope.selectedAttdArray)
        }

        $scope.invite_queue = function(){
          
                        $rootScope.showLoader(true);
                        //$close(JOB_NO);
                        commonFactory.post('/api/invite-attendee-queue', {'ids':$scope.selectedAttdArray}).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error)
                            {
                                Notification.error(res.result.message);// DataTables' callback
                            }
                            else{ 
                                $scope.selectedAttdArray = [];
                                Notification.success(res.result.message);// DataTables' callback
                                // location.reload(); 
                                $scope.reloadData();
                            }
                        }).error(function (err) {
                         $rootScope.showLoader(false);
                            if (err.error)
                            Notification.error(err.error);// DataTables' callback
                        });
                   
        }
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                function isValidDate(dateObject){
                    return new Date(dateObject).toString() !== 'Invalid Date';
                }
                if($scope.dateRange){
                    
                    data.startDate = moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                    data.endDate = moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                    if(isValidDate($scope.dateRange.startDate) && isValidDate($scope.dateRange.endDate)){
                        data.filterDate = true
                    }else{
                        data.filterDate = false

                    }
                }
                // map your server's response to the DataTables format and pass it to
                commonFactory.post('/api/get-attendee-queue', data)
                    .success(function(response) {
                        if (response.error) {
                            // $scope.reloadData();
                        }
                        else {
                            callback(response);
                        }
                    })
                    .error(function(err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadData();
                        }
                    });

            })
            .withOption('processing', true)
            .withLanguage({

                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [10, 25, 50, 100, 200, 250, 300, 500],
                [10, 25, 50, 100, 200, 250, 300, 500]
            ])
            .withOption('order', [2, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtColumns = [];

        $scope.dtColumns.push(
            DTColumnBuilder.newColumn(null).withTitle("#").notSortable()
            .renderWith(function (data, type, full, meta) {

               
                $scope.AttdArray[full.id] = full;
                $scope.AttdArray[full.id].is_checked = (typeof is_checked === 'undefined') ? false : true;


                return '<input type="checkbox" icheck ng-model="AttdArray[' + full.id + '].is_checked" ng-change="SelectAttd(AttdArray[' + full.id + '].is_checked,' + full.id + ')">';
            }).withOption("width", "1%").withOption('searchable', false),
            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job #').withOption('searchable', true).withOption("width", "5%").renderWith(function(data,type,full,meta){
                return '<span style="padding:5px 10px 7px 10px;font-size:14px;"><a target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'</a></span>'
            }),
            DTColumnBuilder.newColumn('dte_taken').withOption('defaultContent', '').withTitle('Date').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Name').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('email').withOption('defaultContent', '').withTitle('Email').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('count').withOption('defaultContent', '').withTitle('Count').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('cell').withOption('defaultContent', '').withTitle('Cell').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption("width", "5%").notSortable().renderWith(function (data, type, full, meta) {
                
                return '<a href="javascript:void(0);" class="btn btn-primary btn-circle" ng-click="editMeetingAttendee('+full.id+')"><i class="fa fa-pencil"></i></a> <a href="javascript:void(0);" class="btn btn-danger btn-circle" ng-click="deleteMeetingAttendee('+full.id+')"><i class="fa fa-trash"></i></a>';

            })
        );

        $scope.editMeetingAttendee = function(id){
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/diary/addAttendee/attendee_modal.html',
                controller: 'editAttendeeController',
                keyboard: false,
                backdrop: false,
                resolve: {
                    id: function() {
                        return id;
                    },
                    attendee_type: function() {
                        return 'attorney';
                    }
                }
            });

            modalInstance.opened.then(function () {
                $timeout(function(){
                    $rootScope.showLoader(false);
                },1000);
            });

            modalInstance.result.then(function () {
                $scope.resetSearch();
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        }

        $scope.deleteMeetingAttendee = function(id){
            swal({
                title: "Are you sure",
                text: "You want to delete?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.delete('/api/meeting-attendee-info/'+ id).success(function (response) {
                        $rootScope.showLoader(false);
                        if (!response.error) {
                            Notification.success(response.result.message);
                            $scope.resetSearch();
                        }else{
                            Notification.error(response.result.message);
                        }
                    });
                }
            });
        }
    }
]);
