angular.module('deitz').controller('addadditionalinfoController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.additionalinfo = {
            is_default:0
        };
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add RESOURCE TYPES';
        $scope.modalTitle = 'Add plaintiff/defendant'
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        commonFactory.get('/api/service-type-list').then(function(response) { 
            $scope.service_type_list = response.data.result.data;  
            $scope.service_type_list = [{id:null,name:"None"}].concat($scope.service_type_list)
        });

        $scope.addAdditionalInfo = function(additionalinfo, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/additionalinfo', $scope.additionalinfo)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("AdditionalInfo added successfully");

                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.additionalinfo.id);
                                }else{
                                    $state.go("code_tables.additionalinfo_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
