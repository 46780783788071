angular.module('deitz').controller('createBatchController', [
    '$scope', 'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', '$compile', '$uibModal', 'Notification', '$rootScope', '$state',
    function (
        $scope, DTOptionsBuilder, DTColumnBuilder, commonFactory, $compile, $uibModal, Notification, $rootScope, $state
    ) {


        $scope.dtInstance = {};
        $scope.jobArray = {};
        $scope.batchJobDisplayArray = [];
        $scope.currentPageJobArray = [];
        $scope.id = $state.params.id;
        $scope.title = ($scope.id) ? "Edit Batch" : "Add Batch";
        $scope.formData = {
            batch_no: '',
            assign_to: '',
        }
        $scope.showDT = true;

        $scope.userBusinessUnit=$scope.auth_user.roles_businessunits.map(a => a.business_unit_id);
        $scope.personBusinessUnit=$scope.userBusinessUnit;
        $scope.dtInstanceCallback = function(dtInstance) {
            $scope.dtInstance=dtInstance;
        }
           
        if ($scope.id) {
             $scope.showDT = false;
            commonFactory.get('/api/edit_job_batch/' + $scope.id)
                .success(function (response) {
                    if (response.result.batch.status == 3) {
                        var message = '';
                        if (response.result.batch.status == 2) {
                            message = "In Process"
                        }
                        if (response.result.batch.status == 3) {
                            message = "Completed"

                        }
                        // Notification.error('Batch Already ' + message + ' !');
                        // $state.go('production.browse_batch');
                    }
                    $scope.formData.batch_no = response.result.batch.batch_no;
                    $scope.formData.assign_to = response.result.batch.prod_person;
                    $scope.formData.assign_by = response.result.batch.prod_manager;
                    $scope.batchJobDisplayArray = response.result.jobs

                    const personBu= response.result.batch.assign_to.businessunits.map(a => a.id);
                    var filteredBU = personBu.filter(function(n) {
                        return  $scope.userBusinessUnit.indexOf(n) !== -1;
                    });
                    $scope.personBusinessUnit=filteredBU;
                    $scope.showDT = true;
                    

                }).error(function (err,status) {
                    if (status==404) {
                        Notification.error('Batch  Not Found !');
                        $state.go('production.browse_batch');
                    }
                });

        }

        // Search JOBS for batch.
        $scope.searchingJobForBatch = function ($query) {
            $scope.searchingJobs = true;
            var data = {
                search_job: $query,
                business_unit_ids:$scope.personBusinessUnit
            };
            if ($scope.id) {
                data.is_edit = true;
                data.batch_id = $scope.id;
            }
            return commonFactory.post('/api/get_all_downloadedjobs', data).then(function (response) {
                $scope.searchingJobs = false;
                return response.data.result.jobs.map(function (item) {
                    return item
                });

            });

        };

        $scope.getPersonBusinessUnitJobs = function(){
            const selectedPerson = $scope.productionPersons.filter(function (o) {
                return o.id == $scope.formData.assign_to;
            })[0];
            const personBu= selectedPerson.businessunits.map(a => a.id);
            var filteredBU = personBu.filter(function(n) {
                return  $scope.userBusinessUnit.indexOf(n) !== -1;
            });
            $scope.personBusinessUnit=filteredBU;
           
            var batchJobDisplayArray = $scope.batchJobDisplayArray.filter(function(n) {
                return  $scope.personBusinessUnit.includes(n.business_unit_id);
            });
            $scope.batchJobDisplayArray=batchJobDisplayArray;
            $scope.reloadData();
        }

        $scope.toggleJobFromTable = function (is_checked, jobNo) {
            ($scope.batchJobDisplayArray === undefined) ? $scope.batchJobDisplayArray = [] : ''
            if (is_checked) {
                const index = $scope.batchJobDisplayArray.findIndex(function (o) {
                    return o.JOB_NO == jobNo;
                });

                if (index < 0) {
                    $scope.batchJobDisplayArray.push($scope.jobArray[jobNo]);
                }
            }
            else {
                const index = $scope.batchJobDisplayArray.findIndex(function (o) {
                    return o.JOB_NO == jobNo;
                });
                $scope.batchJobDisplayArray.splice(index, 1);
            }
            $scope.checkAllselect();
        }
        $scope.toggleJobFromBatch = function (is_checked, jobNo) {
            ($scope.batchJobDisplayArray === undefined) ? $scope.batchJobDisplayArray = [] : ''
            if (is_checked) {
                $scope.jobArray[jobNo]['is_checked'] = true;
            }
            else {
                $scope.jobArray[jobNo]['is_checked'] = false;
            }
            $scope.checkAllselect();
        };
        $scope.checkAllselect = function () {
            keepGoing = true;
            angular.forEach($scope.currentPageJobArray, function (value, key) {
                if (keepGoing) {
                    if (!$scope.jobArray[key].is_checked) {
                        $scope.selectAll = false;
                        keepGoing = false;
                    }
                    else {
                        $scope.selectAll = true;
                    }
                }

            });
        }
        $scope.toggleAllJobs = function (isChecked) {
            angular.forEach($scope.currentPageJobArray, function (value, key) {
                $scope.jobArray[key].is_checked = isChecked;
                $scope.toggleJobFromTable(isChecked, $scope.currentPageJobArray[key].JOB_NO);
            });
        };

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function () {

            $scope.reloadData();
        };

        // $scope.checkBatch = function (event){
        //     if (($(event.target).get(0).selectionStart >= 0 && $(event.target).get(0).selectionStart < 10 && (event.keyCode < 35 || event.keyCode > 40)) || ($(event.target).get(0).selectionStart >= 1 && $(event.target).get(0).selectionEnd <= 10 && event.keyCode === 8)) {
        //         event.preventDefault();
        //     }
        // }

        $scope.headerCompiled = false;

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to

                data.is_datatable = true;
                if ($scope.id) {
                    data.is_edit = true;
                    data.batch_id = $scope.id;
                }
                data.business_unit_ids=$scope.personBusinessUnit;
                commonFactory.showDataTable('/api/get_all_downloadedjobs', data).success(function (res) {
                    if (res.error) {
                        // $scope.reloadData();
                    }
                    else {
                        $scope.selectAll = true;
                        callback(res);
                    }
                }).error(function (err) {
                    if (err.error !== "token_not_provided") {
                        //$scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sEmptyTable": "NO JOB AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ JOBS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 JOBS",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL JOBS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ JOBS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING JOB FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACtrueTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('pageLength', 25)
            // .withOption('searching', false)
            .withOption('order', [5, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('drawCallback', function (settings, json) {
                $scope.currentPageJobArray = [];
                this.api().data().each(function (item, index) {
                    $scope.currentPageJobArray[item.JOB_NO] = item;
                });
            });

        $scope.dtColumns = [];
        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllJobs(selectAll)">';

        $scope.dtColumns.push(
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable()
                .renderWith(function (data, type, full, meta) {
                    ($scope.batchJobDisplayArray === undefined) ? $scope.batchJobDisplayArray = [] : ''
                    const is_checked = $scope.batchJobDisplayArray.filter(function (o) {
                        return o.JOB_NO == full.JOB_NO;
                    })[0];
                    $scope.jobArray[full.JOB_NO] = full;
                    $scope.jobArray[full.JOB_NO].is_checked = (typeof is_checked === 'undefined') ? false : true;
                    if (!$scope.jobArray[full.JOB_NO].is_checked) {
                        $scope.selectAll = false;
                    }
                    return '<input type="checkbox"  icheck ng-model="jobArray[' + full.JOB_NO + '].is_checked" ng-change="toggleJobFromTable(jobArray[' + full.JOB_NO + '].is_checked,' + full.JOB_NO + ', true)">';
                }).withOption('searchable', false),


            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('searchable', true),
            DTColumnBuilder.newColumn('CAPTION').withTitle('Caption').withOption('searchable', false),
            DTColumnBuilder.newColumn('dte_taken').withTitle('Job Date').withOption('searchable', false).renderWith(function (data, type, full, meta) {

                if (data) {
                    var dte = moment(full.dte_taken).format('MM/DD/YYYY');
                    if (dte == 'Invalid date') {
                        return '-';
                    } else {
                        return dte;
                    }
                }
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('diary.resources').withOption('defaultContent', '').withTitle('Resource').notSortable().withOption('name','diary.resources.FIRST_NAME').withOption('searchable', true).withOption("width", "11%").withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){
                html='';
                data.forEach(resource => {
                    html+= '<p>'+  resource.reporter +'</p>';    
                });
                return html;
            }),
            // DTColumnBuilder.newColumn('reporter').withTitle('Reporter').withOption('searchable', false),

            DTColumnBuilder.newColumn('dte_downloaded').withTitle('Date Download').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                if (data) {
                    var dte = moment(full.dte_downloaded).format('MM/DD/YYYY');
                    if (dte == 'Invalid date') {
                        return '-';
                    } else {
                        return dte;
                    }
                }
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn('attorney_firm').withTitle('Attorney Firm').withOption('searchable', false),

        );

        $scope.batchStatus =function (data, type, full) { 
            if(full.job_batch_status==1){
                return "<label class='label label-danger'>Pending</label>";
            }
            else if(full.job_batch_status==2){
                return "<label class='label label-warning'>In Progress</label>";

            }
            else if(full.job_batch_status==3){
                return "<label class='label label-success'>Completed</label>";

            }
            else{
                return "";
            }
        }
        if ($scope.id) {
            $scope.dtColumns.push(
                DTColumnBuilder.newColumn("status").withTitle("Status").withOption('searchable', false).renderWith($scope.batchStatus),
            )
        }

        //Getting all production persons .
        commonFactory.post('/api/get_production_persons',{
            business_unit_ids:$scope.userBusinessUnit
            })
            .success(function (response) {
                if (response.result && response.result.productionPersons) {
                    $scope.productionPersons = response.result.productionPersons || '';
                }
            })
            .error(function (err) { });

        if (!$scope.id) {
            //generate batch no 
            commonFactory.get('/api/generate_batch_no')
                .success(function (response) {
                    if (response.result && response.result.finalNewBatch) {
                        $scope.formData.batch_no = response.result.finalNewBatch || '';
                    }
                })
                .error(function (err) { });
        }


        /* Use for save data */
        $scope.save = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                $scope.formData.jobs_array = $scope.batchJobDisplayArray.map(a => a.JOB_NO);
                $scope.formData.assign_by = $scope.auth_user.id;
                if ($scope.id) {
                    commonFactory.post('/api/update_job_batch/' + $scope.id, $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Batch Updated Successfully!');
                            $state.go('production.browse_batch');
                        }
                    })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err);
                        });

                } else {

                    commonFactory.post('/api/create_job_batch', $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Batch Added Successfully!');
                            $state.go('production.browse_batch');
                        }
                    })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err);
                        });

                }

            }
        }

    }

])

