angular.module('deitz').controller('editWitnessResponseController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',

    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ){

        $scope.our_suite = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Witness Response';
        $scope.witnessResponse = {};

        //Edit Mode of our_suite.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/witness_name/' + id + '/edit')
                .success(function(response) {

                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.witnessname_detail) {
                        $scope.witnessResponse = response.result.witnessname_detail;

                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of our_suite
        $scope.submitWitnessResponse = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/witness_name/' + id, $scope.witnessResponse)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Witness Response updated successfully");

                                $state.go("code_tables.witness_responses_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
