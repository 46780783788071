angular.module('deitz')
    .controller('editPredepoExhibitsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'id',
        'JOB_NO',
        'depoUserId',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  id,
                  JOB_NO,
                  depoUserId,
                  $uibModalInstance,
                  configFactory) {
            $scope.predepoExhibit = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'MANAGE PRE-PROCEEDING DOCUMENTS (POTENTIAL EXHIBITS)';
            $scope.isPdfUploaded = false;
            $scope.predepoExhibit.JOB_NO = JOB_NO;
            $scope.predepoExhibit.depoUserId = depoUserId;
            $scope.predepoExhibit.allFiles = [];

            if (id) {
                $http.get(apiUrl + '/api/predepo-exhibits/' + id + '/edit')
                    .success(function (response) {
                        if (response.status !== 200){
                            Notification.error(response.result.message);
                            $scope.closePopupWindowSimple();
                            return false;
                        }
                        $scope.predepoExhibit.exhibit_type = response.result.exhibit_document.exhibit_type;
                        $scope.predepoExhibit.exhibit_number = response.result.exhibit_document.exhibit_number;
                        $scope.predepoExhibit.filename = response.result.exhibit_document.filename;

                    }).error(function (err) {
                    if (err.error) {
                        Notification.error(err.error);
                    }
                });
            }

            $scope.clickUpload = function(){
                $("#file").trigger('click');
            }

            $scope.removeFile = function(index){
                var attachments = document.getElementById("file").files;
                var fileBuffer = new DataTransfer();

                for (let i = 0; i < attachments.length; i++) {
                    if (index !== i){
                        fileBuffer.items.add(attachments[i]);
                    }
                }
                
                document.getElementById("file").files = fileBuffer.files; 
                $scope.predepoExhibit.file.splice(index, 1); 
                $scope.predepoExhibit.allFiles.splice(index, 1);

                $scope.dropboxText();
            }

            $scope.fileChange = function(){
                console.log($scope.predepoExhibit.file);
                $scope.dropboxText();
            }

            $scope.dropboxText = function(){
                var file = $('#file')[0].files;
                if(file.length > 0){
                    $(".upload_h1").html(file.length+" file(s) selected. <br> Provide a below information and press the 'save' button.");
                }else{
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            $scope.editPredepoExhibit = function(predepoExhibit, isValid) {
                $scope.formSubmit = true;
                console.log($scope.predepoExhibit);
                if (isValid) {
                    $rootScope.showLoader(true);
                    Upload.upload({
                        url: apiUrl+'/api/predepo-exhibits/' + id + '/update',
                        data: $scope.predepoExhibit
                    }).then(function (response) { 
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.data.status == 200) {
                                Notification.success(response.data.result.message);
                                $scope.closePopupWindowSimple();
                            }else if(response.data.status == 400){
                                Notification.error(response.data.result.message);
                            }
                        }
                    },function(err){
                        $rootScope.showLoader(false);
                    });
                    
                }else{

                    if($scope.predepoExhibitsCreateForm.$error.pattern){
                        Notification.error('Please upload accepted file only.');
                    }else{
                        Notification.error('Exhibit Type and Potential Exhibit Number - must be required.');
                    }
                }
            }
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.dismiss({ isChanged : true});
            };
        }]);