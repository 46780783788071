angular.module('deitz')
    .controller('findInvoiceController',
        [
            '$scope',
            '$rootScope',
            '$http',
            'apiUrl',
            '$timeout',
            '$state',
            '$stateParams',
            '$uibModal',
            'invoiceFactory',
            'Notification',
            function ($scope,
                      $rootScope,
                      $http,
                      apiUrl,
                      $timeout,
                      $state,
                      $stateParams,
                      $uibModal,
                      invoiceFactory,
                      Notification) {

                $scope.validations = invoiceFactory.validations;
                $scope.messages = invoiceFactory.messages;
                $scope.getcasebycaption = function(val) {
                    return $http.get(apiUrl + '/api/getcasebycaption', {
                        params: {
                            caption: val
                        }
                    }).then(function(response) {
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    });
                };

                //Getting Attorneys list
                $scope.attorneyslist = function(val) {
                    $rootScope.showLoader(true);
                    return $http.get(apiUrl + '/api/attorneySearchByName', {
                        params: {
                            name: val
                        }
                    }).then(function(response) {
                        $rootScope.showLoader(false);
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    });
                };


                //Getting witness name in witness_tbl.
                $scope.getcasebywitnessname = function(val) { 
                    return $http.get(apiUrl + '/api/getcasebywitnessname', {
                        params: {
                            name: val
                        }
                    }).then(function(response) { 
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    });
                };

                $scope.resetObj = function(){
                   
                    if($scope.formData.selectedValue === "dte_taken" || $scope.formData.selectedValue === "PAID_DT" || $scope.formData.selectedValue === "dte_invoice"){
                        $scope.formData.searchValue = {};
                    }else{
                        $scope.formData.searchValue = null;
                    }
               
                }
                $scope.findInvoice = function(isValid){
                    $scope.formSubmit=true;
                    if(isValid){
                        $rootScope.showLoader(true);

                        var data = $scope.formData;
                        if($scope.formData.selectedValue === "PAID_DT"){
                        
                            data.searchValue = moment($scope.formData.searchValue).format('MM/DD/YYYY'); 
                        
                        }
                        else if($scope.formData.selectedValue === "dte_invoice" || $scope.formData.selectedValue === "dte_taken"){
                        
                            data.searchValue = moment($scope.formData.searchValue).format('YYYY-MM-DD');
                        
                        }else if(data.selectedValue ==='INVOICE_tbl.JOB_NO' || data.selectedValue ==='INVOICE_tbl.INV_AMT' || data.selectedValue ==='INV_NO'){
                        
                            if(isNaN(data.searchValue)){ 
                                $scope.formData.searchValue = null;
                                Notification.error('Plz enter number only');  
                                $rootScope.showLoader(false); return false;
                            }
                        
                        } 
                        if(data.searchValue === "Invalid date"){ 
                        
                            $scope.formData.searchValue = {};
                            Notification.error('INVALID DATE');
                            $rootScope.showLoader(false); return false;
                        
                        }

                        invoiceFactory.post('/api/find-invoice', data)
                            .success(function(response) {
                                if (response && response.result && response.result.invoiceCount) {

                                    if(response.result.invoiceCount === 1){
                                        
                                        $scope.invoice = response.result.invoice;
                                        
                                        $timeout(function() {
                                            $state.go('invoice.edit', { invoice_id : response.result.invoice.invoiceID});
                                        }, 500);   
                                        
                                        
                                    } else{
                                        invoiceFactory.searchData = data;

                                        $timeout(function() {
                                            $state.go('invoice.browse');
                                        }, 500);
                                    }
                                } else {
                                    $rootScope.showLoader(false);
                                    if (response && response.result && response.result.message) {
                                        Notification.error(response.result.message);
                                    }
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                }


            }]);
