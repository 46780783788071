angular.module('deitz')
    .controller('rolesController', [
        '$scope', 
        '$rootScope',
        'PermissionFactory' , 
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'RolesFactory',
        'commonFactory',
        '$compile',
        '$uibModal',
        '$filter',
        'apiUrl',
'$window',
    function (
        $scope,
        $rootScope,
        PermissionFactory,
        DTOptionsBuilder,
        DTColumnBuilder,
        RolesFactory,
        commonFactory,
        $compile,
        $uibModal,
        $filter,
        apiUrl,
$window) {


        $scope.dtInstance = {};
        
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };

        $scope.showPermissions = function (data, type, full) {
            return "<a href='javascript:void(0);' ng-click='getPermissions(" + full.id + ")'>See Permissions</a>";
            /*return data + " [<a href='javascript:void(0);' ng-click='getPermissions(" + full.id + ")'>Show Permissions</a>]";*/
        };

        $scope.getPermissions = function (roleId) {
            $uibModal.open({
                templateUrl: 'modules/resources/templates/show_permission_template.html',
                scope: $scope,
                controller: ['$scope', 'RolesFactory', 'id','$uibModalInstance', function ($scope, RolesFactory, id,$uibModalInstance) {
                        $scope.role = {};
                        $scope.uibModalInstance = $uibModalInstance;
                        RolesFactory.getRolePermisssions(id).success(function (response) {
                            $scope.role.permissions = response;
                        }).error(function (error) {

                        });
                }],
                resolve: {
                    id: function depFactory() {
                        return roleId;
                    }
                }
            });
        };

        $scope.roleListExcel = function () {
            $rootScope.showLoader(true);
            commonFactory.get('/api/roles/get-role-permission-excel').success(function(response){
				
				$rootScope.showLoader(false);
				
				// if(response.status == 200){
					

					
						$window.location.href = apiUrl +'/api/download-role-list';
					

				// }else{
                //     $rootScope.showLoader(false);
				// 	Notification.error(response.result.message);
				// }

			});
        };

        $scope.actionRoles = function (data, type, full) {
            if(full.is_editable != false) {
                return "<a class='btn btn-primary btn-circle' ui-sref='roles.edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";
            }
            else {
                return "-";
            }
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                 // map your server's response to the DataTables format and pass it to
                commonFactory.showDataTable('/api/roles/get-roles-list', data).success(function (res) {
                    if (res.error)
                        $scope.reloadData();
                    else // DataTables' callback
                    callback(res);
                }).error(function (err) {
                    if (err.error)
                    $scope.reloadData();
                });
            })
            .withOption('processing', true)
            .withOption('stateSave', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('createdRow', $scope.createdRow);

        $scope.dtColumns = [

            DTColumnBuilder.newColumn('name').withTitle('Role Name'),
            DTColumnBuilder.newColumn('count').withTitle('Permissions').withOption('searchable', false).renderWith($scope.showPermissions).notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).renderWith($scope.actionRoles).notSortable()
        ];

    }]);