angular.module('deitz')
    .controller('ratesController', ['$scope', '$rootScope', '$state', 'commonFactory', 'Notification', '$stateParams', 'rateFactory', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$http', 'apiUrl', function($scope, $rootScope, $state, commonFactory, Notification, $stateParams, rateFactory, DTOptionsBuilder, DTColumnBuilder, $compile, $http, apiUrl) {

        $scope.include_active = false;
        $scope.include_inactive = false;

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.selectedRateGroupIDs = [];
        $scope.selectedEntityType = [];
        commonFactory.get('/api/all-active-entity-types')
        .success(function(response) {
            if(response.error){
                $scope.getEntityType = null;
            } else{
                $scope.getEntityType = response.result.entityType;
            }
        });
        //Getting Insurance Company by name.
        $scope.getInsuranceCompanybyname = function(val) { 
            return $http.get(apiUrl + '/api/rate/getInsuranceCompany', {
                params: {
                    name: val
                }
            }).then(function(response) { 
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.include_active = false;
            $scope.selectedRateGroupIDs = [];
            $scope.selectedEntityType = [];
            $scope.include_inactive = false;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        }
        // $scope.onInputChange = function(selectedEntityType) {
        //     if (!selectedEntityType) {
        //         // If input box is cleared (empty string), reload the data
        //         $scope.reloadData();
        //     }
        // };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withOption('stateSave',false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('initComplete', function(){
                // Do your stuff here, you could even fire your own event here with $rootScope.$broadcast('tableInitComplete');
                var myEl = angular.element( document.querySelector( '.dataTables_filter input' ) );
                myEl.attr('autofill',false);
            })
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                let activeOnly = false;
                let inactiveOnly = false;
                if($scope.include_active && !$scope.include_inactive){
                    activeOnly = true;
                }

                if($scope.include_inactive && !$scope.include_active){
                    inactiveOnly = true;
                }

                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    inactiveOnly: inactiveOnly,
                    activeOnly: activeOnly,
                    filter_selectedRateGroupIDs : JSON.stringify($scope.selectedRateGroupIDs.filter(Boolean)),
                    selectedEntityType: JSON.stringify($scope.selectedEntityType) 
                };
                $rootScope.showLoader(true);
                rateFactory.getAllRates('/api/location_rates', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withDisplayLength(50)
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'RepresentativeDetails' },
                { extend: 'pdf', title: 'RepresentativeDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('RateName').withOption('defaultContent', '').withTitle('Rate Name'),
            DTColumnBuilder.newColumn('rate_card_group_name').withOption('defaultContent', '').withTitle('Rate Card Group'),
            DTColumnBuilder.newColumn('In_APP_RATE').withOption('defaultContent', '').withTitle('Appear Rate'),
            DTColumnBuilder.newColumn('In_COPY_RATE').withOption('defaultContent', '').withTitle('Copy Rate'),
            DTColumnBuilder.newColumn('In_APPL_RATE').withOption('defaultContent', '').withTitle('Late Appear Rate'),
            DTColumnBuilder.newColumn('In_POSTAGE').withOption('defaultContent', '').withTitle('Postage'),
            DTColumnBuilder.newColumn('In_WEB_AMOUNT').withOption('defaultContent', '').withTitle('Exachange'),
            DTColumnBuilder.newColumn('In_INV_MINPG').withOption('defaultContent', '').withTitle('No Minutes Taken Charge'),
            DTColumnBuilder.newColumn('In_INV_MIN').withOption('defaultContent', '').withTitle('Minimum Invoice Charge'),
            DTColumnBuilder.newColumn('In_WordIndexingFlatRate').withOption('defaultContent', '').withTitle('Word indexing flat rate'),
            DTColumnBuilder.newColumn('In_WordIndexingPerPage').withOption('defaultContent', '').withTitle('Word indexing per page rate'),
            DTColumnBuilder.newColumn('In_MiniScriptFlatRate').withOption('defaultContent', '').withTitle('Mini script flat rate'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
                .renderWith(function(data, type, full, meta) {

                    if (data && data.InsuranceRateID) {
                        return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="rates.edit({id : ' + data.InsuranceRateID + '})" >' +
                            '<i class="fa fa-pencil"></i>' +
                            '</button> ';
                            // '<button class="btn btn-danger btn-circle" uib-tooltip="Delete" ng-click="deleteRates('+ data.InsuranceRateID +')"><i class="fa fa-trash-o"></i></button>';
                    }

                }),
            DTColumnBuilder.newColumn(null).withTitle('Status').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.InsuranceRateID) {
                    var tooltip = (data.status == '1') ? "Active" : "Inactive";
                    var newActive = (data.status == '1') ? "0" : "1";
                    var btn = (data.status == '1') ? "btn-success" : "btn-danger";
                    return '<button class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm '+btn+'" ng-click="changeActiveRateStatus(' + data.InsuranceRateID + ',\''+ newActive +'\')">' + tooltip + '</button>';
                }

            })

        ];

        commonFactory.get('/api/rateCardGroups').success(function (response) {
            if (response.result) {
                $scope.rateCardGroups = response.result.rateCardGroup;
            }
        }).error(function (err) {}); 

        $scope.changeActiveRateStatus = function (rate_id, newStatus) {
            var data = {id : rate_id, status : newStatus};
            rateFactory.post('/api/rate/update-active-rate-status', data).success(function(){
                Notification.success("Status Updated Successfilly.");
                $scope.reloadData();
            }).error(function(){
                $scope.reloadData();
            });
        };

        $scope.exportRates = function(){
            let activeOnly = false;
            let inactiveOnly = false;
            if($scope.include_active && !$scope.include_inactive){
                activeOnly = true;
            }

            if($scope.include_inactive && !$scope.include_active){
                inactiveOnly = true;
            }

            var options = {
                inactiveOnly: inactiveOnly,
                activeOnly: activeOnly,
                filter_selectedRateGroupIDs : JSON.stringify($scope.selectedRateGroupIDs.filter(Boolean)),
                selectedEntityType: JSON.stringify($scope.selectedEntityType)
            };
            $rootScope.showLoader(true);
            rateFactory.post('/api/rates-export', options)
            .success(function(response) { 
                $rootScope.showLoader(false);
                if(response.error){
                    Notification.error(response.result.message || 'Something went wrong!');
                } else {
                    var url= response.result.path;
                    var save = document.createElement('a');
                    save.href = url;
                    save.target = '_blank';
                    save.download = response.result.name;
                    var event = document.createEvent('Event');
                    event.initEvent('click', true, true);
                    save.dispatchEvent(event);
                    save.click()
                    window.URL.revokeObjectURL(url);
                }
            })
            .error(function(err){   
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false);
            });
        };

        //Delete Rates
        $scope.deleteRates = function($id) {
            var data = {};
            data.id = $id;
            swal({
                    title: "Are you sure?",
                    text: "do you want to delete this record?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!",
                    closeOnConfirm: false,
                    closeOnCancel: true
                },
                function(isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        rateFactory.post('/api/rates/delete', data)
                            .success(function(response) {
                                $rootScope.showLoader(false);
                                if (response && response.result && response.result.isDeleted) {
                                    if (response.result.isDeleted == 1) {
                                        swal('Deleted!', 'Rate deleted successfully', 'success');
                                        locatio.reload(true);
                                    }
                                    else{
                                        $rootScope.showLoader(false);
                                        swal('Cancelled', 'Something gone wrong', 'error');
                                    }
                                }
                                else{
                                    $rootScope.showLoader(false);
                                    swal('Cancelled', response.result.message || 'Something gone wrong', 'error');
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal('Cancelled', 'Something gone wrong', 'error');
                            });
                    }
                });


        }





    }]);
