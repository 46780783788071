angular.module('deitz')
    .controller('clonedOrVoidedInvoicesController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'invoiceFactory',
        'diaryFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  invoiceFactory,
                  diaryFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $filter,
                  $timeout,
                  $q,
                  $stateParams) {

            $scope.title = "Browse Invoice";
            $scope.editInvoiceData = {};
            $scope.dtInstance = {}; 

            $scope.resetSearch = function(){ 
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            }
            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            }; 
            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {           
                $compile(angular.element(row).contents())($scope);
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    
                    invoiceFactory.showDataTable('/api/invoice/get-clone-or-void-invoice-listing', data).success(function (res) {
                        if (res.error) {
                            $scope.reloadData();
                        }
                        else {  
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ INVOICES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING INVOICE FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                }) 
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500) 
                .withOption('order', [1, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '1': {
                        type: 'text',
                    },
                    '2': {
                        type: 'text'
                    },
                    '4': {
                        type: 'text'
                    },
                    '5': {
                        type: 'text'
                    },
                    '6': {
                        type: 'text'
                    }
                });

            $scope.dtColumns = [];
            $scope.dtColumns.push(
                 
                DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice No').withOption('searchable', true).withOption("width", "8%").renderWith(function (data, type, full, meta) {
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank">'+full.original_invoice_no+'</a>';
                }),
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('searchable', true).withOption("width", "6%"),
                DTColumnBuilder.newColumn('void_reason').withTitle('Reason').withOption("width", "6%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var reason = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                        return reason;
                    } else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('is_voided').withTitle('Type').withOption("width", "6%").renderWith(function (data, type, full, meta) {
                    if (data == '1') {
                        return 'Void';
                    } else {
                        return 'Clone';
                    }

                }),
                DTColumnBuilder.newColumn('attorney_firms.SHORT_NAME').withTitle('Billing Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'attorney_firms.SHORT_NAME').withOption('name', 'attorneyFirms.SHORT_NAME').renderWith(function (data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var billing_firm = "<div uib-tooltip='"+full.attorney_firms.NAME+ " | " +full.attorney_firms.PHONE+ " | " +full.attorney_firms.ADDR_LINE1+"' tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                        return billing_firm;
                    } else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('diary_data.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'diary_data.attorney_firm.SHORT_NAME').withOption('name', 'diaryData.attorneyFirm.SHORT_NAME').renderWith(function (data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var billing_firm = "<div uib-tooltip='"+full.diary_data.attorney_firm.NAME+ " | "+full.diary_data.attorney_firm.PHONE+ " | " +full.diary_data.attorney_firm.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                        return billing_firm;
                    } else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('job.CAPTION').withTitle('Case Name').withOption("width", "8%").withOption('searchable', true).withOption('data', 'job.CAPTION').withOption('name', 'job.CAPTION').renderWith(function (data, type, full, meta) {
                    if (data) {

                        var caption = escapeString(data);
                        var billing_firm = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+caption+"' | limitTocustom:13}}</div>";
                        return billing_firm;
                    } else {
                        return '---';
                    }

                }),
                /*DTColumnBuilder.newColumn('witness').withTitle('Witnesses').withOption('searchable', false).withOption('data', 'witness').withOption('name', 'witnessData.witness.NAME').notSortable().renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('uppercase')(data, true);;
                    } else {
                        return '---';
                    }

                }),*/
                DTColumnBuilder.newColumn('userDetails').withTitle('User').withOption('searchable', false).notSortable(),
                DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Date').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return $filter('formatDate')(data);
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn(null).withTitle('Balance Due').withOption('searchable', false).notSortable().withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    return '$' + $filter('number')(eval(full.INV_AMT - full.PAID_AMT), 2);

                }),
                DTColumnBuilder.newColumn('dte_invoice').withOption("width", "5%").withTitle('Created').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'INVOICE_tbl.dte_invoice').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('formatDate')(data);

                    } else if (full.dte_invoice) {
                        return $filter('formatDate')(full.dte_invoice);
                        ;
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('legacy_invoice_id').withTitle('Legacy No').withOption('searchable', true).withOption("width", "6%"),
                DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%"),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith(function (data, type, full, meta) {
                    if (data.is_voided == '1') {
                        var html = "<div class='text-center'>";
                            html += '<a ng-click="markAsValidInvoice(' + full.voided_for_invoice + ')" uib-tooltip="Undo a voided invoice" href="javascript:void(0);"> <i tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="fa fa-2x fa-undo text-danger" ></i></a>';
                            html += "</div>";
                        return html;
                    }
                    else {
                        return '';
                    }
                })
            );

            $scope.markAsValidInvoice = function (invoice_id) {
                $rootScope.showLoader(true);

                var invoiceModal = $uibModal.open({
                    templateUrl: "modules/invoice/void_invoice_popup.html",
                    controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','invoiceFactory','Notification', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,invoiceFactory,Notification) {
                        $rootScope.showLoader(false);
                        $scope.users = [{'id': null, name: "Please select the user"}];
                        $scope.users = $scope.users.concat(users.data);
                        $scope.void_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'valid', invoice_id: invoice_id};
                        $scope.uibModalInstance = $uibModalInstance;

                        $scope.voidFormSubmit = function ($valid) {
                            if ($valid) {
                                $rootScope.showLoader(true);
                                invoiceFactory.post('/api/invoice/mark-invoice-as-valid', $scope.void_invoice).success(function (response) {
                                    $rootScope.showLoader(false);
                                    Notification.success(response.message);
                                    $uibModalInstance.close();
                                    $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                                }).error(function (error) {
                                    $rootScope.showLoader(false);
                                    Notification.error(error.message);
                                });
                            }
                        }

                    }],
                    resolve: {
                        invoice_id: function () {
                            return invoice_id;
                        },
                        users: ['UsersFactory', function (UsersFactory) {
                            return UsersFactory.get('/api/users').success(function (response) {
                                return response.data;
                            });
                        }]
                    },
                    keyboard: true,
                    backdrop: true,
                    size: 'md',
                    windowClass: 'middle-width-model',
                    close: function () {

                    }
                });

                invoiceModal.result.then(function () {
                    $scope.reloadData();
                });             
            }
    }]);
