/**
 * Created by agile-54 on 12-Jun-17.
 */
/**
 *
 * @param number
 * @param digits
 * @returns {roundNumber}
 */
var round_to_number = function(number, digits) {
    if (digits === undefined) {
        digits = 2;
    }
    var amount = number || 0;
    var multiplicator = Math.pow(10, digits);
    amount = parseFloat((amount * multiplicator).toFixed(11));
    return parseFloat((Math.round(amount) / multiplicator).toFixed(digits));
}
