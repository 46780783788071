angular.module('deitz').controller('addReporterWorkTypesController', [
    '$state',
    '$rootScope',
    '$scope',
    '$http',
    'apiUrl',
    'Notification',
    function ($state,
        $rootScope,
        $scope,
        $http,
        apiUrl,
        Notification
    ) {

        $scope.formData = {};
        $scope.formSubmit = false;
        $scope.id = $state.params.id;
        $scope.title = ($scope.id) ? "EDIT" : "ADD";

        /* Use for get level data for update*/
        if ($scope.id) {
            $http.get(apiUrl + '/api/reporter-work-types/' + $scope.id + '/edit')
                .success(function (response) {
                    $scope.formData = response.result.reporter_work_types;
                }).error(function (err) {
                    if (err.error) {
                        console.log(err.error)
                    }
                });
        }

        /* Use for save data */
        $scope.save = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                if ($scope.id) {
                    /* API call for update data */
                    $http.put(apiUrl + '/api/reporter-work-types/'+ $scope.id, $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Reporter Work Type Updated Successfully!');
                            $state.go('code_tables.reporter_work_types');
                        }
                    });
                } else {
                    /* API call for add data */
                    $http.post(apiUrl + '/api/reporter-work-types', $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Reporter Work Type Added Successfully!');
                            $state.go('code_tables.reporter_work_types');
                        }
                    });
                }

            }
        }
    }]);