angular.module('deitz').controller('addrate_codetblController', [
    '$scope', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', '$state',

    function(
        $scope, $rootScope, codeFactory, commonFactory, Notification, $state
    ) {

        $scope.rate = {};
        $scope.title = "Add Rate";
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;

        $scope.addRate = function(rate, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/ratecrud', $scope.rate)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Rate added successfully");

                                $state.go("code_tables.rate_codetbl_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
    }]);
