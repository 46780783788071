angular.module('deitz')
    .controller('importController', ['$scope', '$rootScope', 'Upload', 'Notification', '$timeout', '$window', 'apiUrl', 'commonFactory', function ($scope, $rootScope, Upload, Notification, $timeout, $window, apiUrl, commonFactory) {

        $scope.formData = {};

        /* $scope.validations = loginFactory.validations;
         $scope.messages = loginFactory.messages;*/
        $scope.formSubmit = false;
        $scope.invalidFileType = 'false';
        $scope.importType = 'firms';
        $scope.log = "";

        $scope.submitForm = function (isValid) {
            $scope.formSubmit = true;
            if (isValid && $scope.importElawForm.lawFile.$valid && $scope.lawFile) {
                var tmpName = $scope.lawFile.name.split('.');
                var fileType = tmpName[tmpName.length - 1];
                if (fileType != 'xls' && fileType != 'xlsx') {
                    $scope.invalidFileType = 'true';
                } else {

                    $scope.log = "Uploading file...";
                    var loading = $timeout(function () {
                        $scope.log += "<br> Saving  information... ";
                        $scope.$apply();
                    }, 5000);
                    $rootScope.showLoader(true);
                    $scope.invalidFileType = 'false';

                    Upload.upload({
                        url: 'api/import-data',
                        data: { file: $scope.lawFile, type: $scope.importType }
                    }).then(function (resp) {
                        console.log('Success uploaded. Response: ' + resp.data);
                        $timeout.cancel(loading);
                        $scope.importElawForm.$setPristine(true);
                        $scope.importElawForm.$setUntouched(true);
                        $scope.lawFile = "";
                        $rootScope.showLoader(false);

                        $scope.log += "<br><br> <b> "+resp.data.message+". </b> ";

                        if (resp.data.error_log && resp.data.error_log.length > 0) {
                            $scope.log += "<br><br> <b> Some row getting error while creating </b> ";
                            angular.forEach(resp.data.error_log, function (value, key) {
                                $scope.log += "<p class='error-message animated fadeInDown red'> " + value + "</p> ";
                            });
                        }

                        Notification.success(resp.data.message);
                    }, function (resp) {
                        $timeout.cancel(loading);
                        console.log('Error: ', resp);
                        Notification.error(resp.data.message || "Error while importing data!");
                        $rootScope.showLoader(false);
                        if (resp.data.import_error) {
                            $scope.log += "<br><br> <b> Error while importing data. Please review excel sheet - "+resp.data.sheet+" </b> <br><br>";
                            angular.forEach(resp.data.error_message, function (value, key) {
                                $scope.log += "<p class='error-message animated fadeInDown red'> " + value[0] + "</p> ";
                            });
                        }
                        else {
                            $scope.log += "<br><br> <b>  Error while importing data : "+resp.data.message+" </b> <br><br>";

                        }

                    }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + progressPercentage + '% ');
                    });
                }

            }
        };
        $scope.downloadDemoExcel = function () {
            if ($scope.importType) {
                // $window.location.href = apiUrl + '/api/download-excel-demo?type=' + $scope.importType;
                $window.open(apiUrl + '/api/download-excel-demo?type=' + $scope.importType, '_blank');
            }
            else {
                Notification.error('Please Select Import type');
            }
        }
        $scope.importMissingAttorneys = function () {
            $rootScope.showLoader(true);
            if ($scope.importType == 'missing_attorneys') {
                commonFactory.post('/api/import-missing-attorneys')
                .success(function(response) {
                    if (response) {
                        if(response.error) {
                            Notification.error(response.result.message || "Something went wrong!");
                        } else {
                            Notification.success(response.result.message);
                        }
                        $rootScope.showLoader(false);
                    }
                })
            }
        }
    }]);