angular.module('deitz').factory('UsersFactory', ['$http', '$q', '$cookies', 'apiUrl', function($http, $q, $cookies, apiUrl) {
    var UsersFactory = {};

    UsersFactory.validation = {
        first_name: { required: "First name is required", minlength: "First name is too short", maxlength: "First name is too long" },
        last_name: { required: "Last name is required", minlength: "Last name is too short", maxlength: "Last name is too long" },
        email: { required: "Email is required", invalidEmail: "Email is not valid", minlength: "Email is too short", maxlength: "Email is too long" },
        phone_number: { required: "Phone number is required" },
        mobile_number: { required: "Mobile number is required", maxlength: "Mobile number is too long", minlength:"Mobile number is too short", invalid: "Invalid alternate phone number." },
        address: { required: "Address is required" },
        password: { required: "Password is required", minlength: "Password is too short", maxlength: "Password is too long" },
        current_password: { required: "Current Password is required", minlength: "Password is too short", maxlength: "Password is too long" },
        new_password: { required: "New Password is required", minlength: "Password is too short", maxlength: "Password is too long" },
        confirm_password: { required: "Confirm password is required" },
        role: { required: "Associate roles is required" },
        description: { required: "Description is required" },
        country_id: { required: "Country is required " },
        state_id: { required: "State is required " },
        city_id: { required: "City is required " },
        zip_code: { required: "Zip code is required " },
        limit: { required: "Limit is required", minlength: "Limit is too short", error: 'Please enter a valid limit' },
        pending: { error: 'You have already pending limit request. Do you want to update pending limit request?' },
    };

    UsersFactory.get = function(url) {
        return $http.get(apiUrl + url);
    };

    UsersFactory.addUser = function(data) {
        return $http.post(apiUrl + "/api/users", data);
    };

    UsersFactory.getUser = function(role_id) {
        return $http.get(apiUrl + "/api/users/" + role_id + "/edit");
    };

    UsersFactory.updateUser = function(id, data) {
        return $http.put(apiUrl + "/api/users/" + id, data);
    };

    UsersFactory.deleteUser = function(id) {
        return $http.delete(apiUrl + "/api/users/" + id);
    };


    UsersFactory.updateInvoiceQueue = function(data){
        return $http.post(apiUrl + "/api/users/update-user-invoice-queue",data);
    };

    UsersFactory.getMe = function() {
        return $http.get(apiUrl + "/api/users/me");
    };

    UsersFactory.getUserCount = function() {
        return $http.get(apiUrl + "/api/users/get-user-count");
    };

    UsersFactory.showDataTable = function(data) {
        return $http({
            method: 'POST',
            url: apiUrl + '/api/users/get-users-list',
            data: $.param(data),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
    };

    UsersFactory.updatePassword = function(id, data) {
        data._method = "PUT";
        return $http.post(apiUrl + "/api/users/change-password/" + id, data);
    };

    UsersFactory.updateNotification = function(id, data) {
        data._method = "PUT";
        return $http.post(apiUrl + "/api/users/update-notification/" + id, data);
    };


    /**
     * Translations History
     */
    UsersFactory.showTransactionDataTable = function(data) {
        return $http({
            method: 'POST',
            url: $config.api_url + 'users/get-translations-list',
            data: $.param(data),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        });
    };

    UsersFactory.addLimit = function(data) {
        return $http.post($config.api_url + "users/addlimit", data);
    };


    /**
     * Translations History in admin side
     */
    UsersFactory.updateStatus = function(data) {
        return $http.post($config.api_url + "users/change-request-status", data);
    };

    UsersFactory.changeActiveStatus = function(data) {
        return $http.post(apiUrl + "/api/users/update-active-status", data);
    };

    return UsersFactory;
}]);