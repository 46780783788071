angular.module('deitz').controller('addjobcategoryController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        $uibModalInstance
    ){

        $scope.jobcategory = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Job Category';
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $scope.addJobcategory = function(jobcategory, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/jobcategory', $scope.jobcategory)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Jobcategory added successfully");

                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.job_category.id);
                                }else{
                                   $state.go("code_tables.job_category_browse");
                                }
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
