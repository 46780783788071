angular.module('deitz').controller('addmodalityController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.modality = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Modality';
        $scope.modalTitle = 'Add plaintiff/defendant'
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        commonFactory.get('/api/connection-methods').then(function(response) { 
            $scope.connection_methods = response.data.result.resources;  
        });
        $scope.add = function(modality, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/modality', $scope.modality)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("Modality added successfully");

                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.modality.id);
                                }else{
                                    $state.go("code_tables.modality_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
