angular.module('deitz')
    .controller('mergeAttorneyFirmsController', [
        '$scope',
        '$rootScope',
        '$state',
        'firmFactory',
        'Notification',
        '$stateParams',
        'apiUrl',
        '$http',
        'commonFactory',
        function(
            $scope,
            $rootScope,
            $state,
            firmFactory,
            Notification,
            $stateParams,
            apiUrl,
            $http,
            commonFactory
            ) {

            $scope.title = 'Merge Attorney Firms';
            $scope.mergeFirms = {};
            $scope.isdeleteFirmSelected = false;
            $scope.iskeepFirmSelected = false;
            $scope.submitted = false;

            $scope.attorneyofficedata = function (val) {
                return $http.get(apiUrl + '/api/getattorneyfirm', {
                    params: {
                        name: val,
                        fromMergeAttorney : true,
                        selectedFirm : $scope.selectedFirmId
                    }
                }).then(function (response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            $scope.clearfield = function (key){
                  
                if(key == 'keepFirm' && !$scope.iskeepFirmSelected){
                    $scope.mergeFirms.keepFirm = null;
                }
                if(key == 'deleteFirm' && !$scope.isdeleteFirmSelected){
                    $scope.mergeFirms.deleteFirm = null;
                }
             

            };
            $scope.resetfield = function (key){
                if(key =='keepFirm'){ 

                    $scope.iskeepFirmSelected = false;
                    $scope.keepFirmId = null;  

                }else{     

                    $scope.isdeleteFirmSelected = false; 
                    $scope.deleteFirmId = null;  
                
                }

                if($scope.keepFirmId){

                    $scope.selectedFirmId = $scope.keepFirmId; 
                
                }else if($scope.deleteFirmId){
                
                    $scope.selectedFirmId = $scope.deleteFirmId;
                
                }else{
                
                    $scope.selectedFirmId = null;
                
                }

            };
            //reset form
            $scope.resetForm = function(){
                $scope.iskeepFirmSelected = false;
                $scope.keepFirmId = null;  
                $scope.mergeFirms.keepFirm = null;
                $scope.isdeleteFirmSelected = false; 
                $scope.deleteFirmId = null; 
                $scope.mergeFirms.deleteFirm = null; 
            } 
            $scope.onSelectAttorneyFirm = function ($item, $model, $label, $event,firmType) {

                if(firmType =='keepFirm'){
                    $scope.mergeFirms.keepFirm = angular.copy($item);
                    $scope.iskeepFirmSelected = true;
                    $scope.keepFirmId = $scope.mergeFirms.keepFirm.id;
                }else{
                    $scope.mergeFirms.deleteFirm = angular.copy($item);    
                    $scope.isdeleteFirmSelected = true; 
                    $scope.deleteFirmId = $scope.mergeFirms.deleteFirm.id;
                }
                
            };
            $scope.mergeAttorneyFirms = function(isValid){ 
                $scope.submitted = true;
                if(isValid){

                    swal({
                            title: "<h3>Merge these attorneys - are you SURE? Once this has begun you can not stop it..</h3>",
                            text: "<small style=' color:red';> Note : The merge will happen in the background and you'll be receive an email when complete.</small>",
                            type: "warning",
                            html: true,
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No, Cancel this merge",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) { 
                                $scope.deletefirm =  $scope.mergeFirms.deleteFirm || null;
                                $scope.keepfirm = $scope.mergeFirms.keepFirm || null;
                                $rootScope.showLoader(true);  
                                firmFactory.post('/api/firms/queue-merge-attorney-firms', {deletefirm:$scope.deletefirm,keepfirm:$scope.keepfirm}).success(function (response) {
                                     
                                    $rootScope.showLoader(false);
                                    $scope.resetForm();  
                                    if(response.status == 200){
                                        Notification.success(response.result.message); 
                                    }else{
                                        Notification.error(response.result.message);
                                    } 
                                    
                                }).error(function (error) {
                                    $rootScope.showLoader(false);
                                    Notification.error(error.message);
                                });
                            }
                        });
                }
            }
        }]);