angular.module('deitz')
    .factory('representativeFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var representativeFactory = {};

        var vh = validation_helper();

        representativeFactory.validations = {
            email: {
                patterns: vh.email.patterns,
                maxlength: vh.email.maxlength,
                required: vh.email.required,
            },
            zipcode: {
                required: vh.zipcode.required,
                patterns : vh.zipcode.patterns,
                patternRestrict : vh.zipcode.patternRestrict,
                maxlength: vh.zipcode.maxlength,
                minlength: vh.zipcode.minlength
            }
        };
        representativeFactory.messages = {
            email: {
                patterns: "Please enter valid email.",
                required: "Email is required.",
                maxlength: "Email Field not be upto 254 characters."
            },
            zipcode: {
                maxlength: "Zipcode not be upto 10 characters.",
                required: "Zipcode is required.",
                patterns : "Zipcode is invalid"
            },
        };
        function convertParams(params) {
            var reqData = {};
            if (params) {
                reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                reqData.limit = params.limit || 10; // Number of entries showed per page.
                reqData.search = params.search ? params.search.value : '';
                reqData.orderColumn = params.order ? params.order[0].column : '';
                reqData.orderDir = params.order ? params.order[0].dir : '';
                if (params.id) {
                    reqData.id = params.id ? params.id : '';
                }
            }
            return reqData;
        }

        representativeFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        representativeFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        representativeFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        return representativeFactory;
    }]);
