angular.module('deitz')
    .controller('jobReporterPaymentNotSetupController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'payrollFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  payrollFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $filter,
                  $timeout,
                  $q,
                  $stateParams) {

            $scope.title = "Job list for resource payment not setup";
            $scope.dtInstance = {};

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };

            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    payrollFactory.showDataTable('/api/job-list-reporter-payment-not-setup', data).success(function (res) {
                        if (res.error) {
                            $scope.reloadData();
                        }
                        else {
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO JOBS AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ JOBS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL JOBS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ JOBS",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING JOB FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500)
                // .withOption('searching', false)
                .withOption('order', [2, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '1': {
                        type: 'text',
                    },
                    '3': {
                        type: 'text',
                    },
                    '5': {
                        type: 'text',
                    }

                });

            $scope.dtColumns = [];


            $scope.dtColumns.push(

                DTColumnBuilder.newColumn('JOB_NO').withOption('name', 'JOB_tbl.JOB_NO').withOption('width', '8%').withOption('defaultContent', '').withTitle('Job No').renderWith(function(data,type,full,meta){
                    return '<a ui-sref="payroll.edit({id : ' + full.job_resource_id + ' })"  target="_blank"> '+ data +' </a>';
                }),
                DTColumnBuilder.newColumn('CAPTION').withOption('name', 'JOB_tbl.CAPTION').withOption('defaultContent', '').withTitle('Caption'),
                DTColumnBuilder.newColumn("dte_taken").withOption('name', 'JOB_tbl.dte_taken').withTitle('Job Date').withOption('width', '8%').renderWith(function (data) {
                    return (data && data != '0000-00-00')?moment(data).format('MM/DD/YYYY') : '-';
                }),
                DTColumnBuilder.newColumn("REP_NAME").withOption('name', 'REP_NAME').withOption('data', 'REP_NAME').withTitle('Resource').renderWith(function (data) {
                    return data || '-'
                }),
                DTColumnBuilder.newColumn('REP_PAYAMT').withOption('name', 'job_resources.REP_PAYAMT').withOption('defaultContent', '').withOption('width', '8%').withTitle('Rep_Payamt').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('currency')(data);
                    } else {
                        return $filter('currency')(0);
                    }
                }),
                DTColumnBuilder.newColumn("TYP_NAME").withOption('name', 'TYP_NAME').withOption('data', 'TYP_NAME').withTitle('Typist').renderWith(function (data) {
                    return data || '-'
                }),
                DTColumnBuilder.newColumn('TYP_PAYAMT').withOption('name', 'job_resources.TYP_PAYAMT').withOption('width', '8%').withTitle('Typ_Payamt').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('currency')(data);
                    } else {
                        return $filter('currency')(0);
                    }
                })


            );

        }
    ]);