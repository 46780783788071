angular.module('deitz').controller('manageCommissionController',[
    '$scope', '$http', 'apiUrl', '$rootScope', '$state', 'commonFactory', 'firmFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$uibModal', '$window',
    function(
        $scope, $http, apiUrl, $rootScope, $state, commonFactory, firmFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $compile, $uibModal, $window
	){
	   
        $scope.filterObj = {};
    	$scope.selectAll = false;
    	$scope.selectedFirmArray = [];
        $scope.totalQueueFirms = 0;
        
        $scope.isAuthenticated = false;
        if($rootScope.havePermission('allow_access_without_password','allow.attorney.commission')){
            $scope.isAuthenticated = true;
        }
        
        $scope.selectedFirmArray = JSON.parse(localStorage.getItem('firmCommissionBatch'));
        if($scope.selectedFirmArray){
            $scope.totalQueueFirms = $scope.selectedFirmArray.length;
        }

        commonFactory.get('/api/reptype/' + $state.params.id + '/edit')
            .success(function(response) {
                if(response && response.result && response.result.reptypeInfo){
                    $scope.salespersonName = response.result.reptypeInfo.name;
                }else{
                    console.log("Not get any response")
                }
            })
            .error(function(err) {
                console.log(err);
            });

        $scope.clearBatch = function(){
            $scope.selectedFirmArray = [];
            $scope.totalQueueFirms = 0; 
            localStorage.setItem('firmCommissionBatch',JSON.stringify($scope.selectedFirmArray));    
            $scope.reloadData();
        };

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }; 

        $scope.reloadData = function() { 
            $scope.dtInstance.rerender();
        }; 

        $scope.resetSearch = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.dtInstance.rerender();
        };

        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$0.00';
            }
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {

                data.salesperson_id = $state.params.id;

                commonFactory.showDataTable('/api/show-firm-commission-data-table', data)
                .success(function(res) {
                    if (res) {
                        $rootScope.showLoader(false); 
                        $scope.selectAll = true;
                        $scope.selectedFirm = {};
                        $scope.filterObj = data;
                        callback(res);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            })
            .withLanguage({
                "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

            })
            .withOption('stateSave',true) 
            .withOption('processing', true)
            .withOption('serverSide', true) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            // .withOption('lengthMenu',[[10, 25, 50, 100, 250, 500, -1], [10, 25, 50, 100, 250, 500, "All"]])
            .withOption('lengthMenu',[[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
            .withDisplayLength(10)  
            .withOption('order', [1, 'asc'])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            }) 
            .withLightColumnFilter({
                '1': {
                    type: 'text'
                },
                '2': {
                    type: 'text'
                },
                '3': {
                    type: 'text'
                },
            });
 

        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAll(selectAll)">';
        
        $scope.dtColumns = [];

        $scope.dtColumns.push( 
       		DTColumnBuilder.newColumn(null).withTitle(titleHtml).renderWith(function(data, type, full, meta){
       			
                var is_checked = false;
                var batchCommissionFirm = [];
                $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('firmCommissionBatch'));
                 
                if($scope.selectedFirmArray){
                    $scope.totalQueueFirms = $scope.selectedFirmArray.length;
                    batchCommissionFirm = $scope.selectedFirmArray.filter(function (item) {
                       return item == full.id ;
                    });
                }else{
                    $scope.totalQueueFirms = 0;
                } 

                if(batchCommissionFirm.indexOf(Number(full.id)) != -1 ){
                    is_checked = true; 
                }    

                $scope.selectedFirm[full.id] = full;
       			$scope.selectedFirm[full.id].is_checked = is_checked;
       			
                if (!$scope.selectedFirm[full.id].is_checked) {
                    $scope.selectAll = false;
                }     

       			return '<input type="checkbox" icheck ng-model="selectedFirm['+ full.id +'].is_checked" ng-change="toggleFirm(selectedFirm['+ full.id +'].is_checked,'+ full.id +')">';

       		}).withOption("width", "5%").notSortable(),
            
            DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('Firm Name').withOption("width", "15%").renderWith(function(data,type,full,meta){
                var isStatus = full.STATUS ? true : false; 
                return '<a ui-sref="firms.manage.step_one({id : ' + full.id + '})">'+data+'</a><br><small ng-if='+isStatus+'><b><i>'+full.STATUS+'</i></b></small>'
            }),
            DTColumnBuilder.newColumn('one_reporting_salesman.commission_percentage').withOption('defaultContent', '').withTitle('Commission (%)').withOption("name", "oneReportingSalesman.commission_percentage").renderWith(function(data, type, full, meta){                
                if(parseFloat(data) >= 0){
                    return parseFloat(data).toFixed(2) + '%';
                }else{
                    return '0.00%';
                }
            }),
            DTColumnBuilder.newColumn('one_reporting_salesman.overhead_amt').withOption('defaultContent', '').withTitle('Over Head Amt').withOption("name", "oneReportingSalesman.overhead_amt").renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('YTD_PAID').withOption('defaultContent', '').withTitle('YTD Paid').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('YTD_PAID').withOption('defaultContent', '').withTitle('YTD Paid').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('YTD_BILLED').withOption('defaultContent', '').withTitle('YTD Billed').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('PYR_BILLED').withOption('defaultContent', '').withTitle('PYR Billed').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('PYR2_BILLD').withOption('defaultContent', '').withTitle('PYR2 Billed').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('BAL_DUE').withOption('defaultContent', '').withTitle('Bal Due').renderWith($scope.currencyFormat)
        );
      
        $scope.toggleFirm = function(is_checked, id){
        	
        	$scope.selectedFirmArray = JSON.parse(localStorage.getItem('firmCommissionBatch'));

        	if($scope.selectedFirmArray == null){
                $scope.selectedFirmArray = [];
            }
        
            if(is_checked){
                if($scope.selectedFirmArray.indexOf(Number(id)) == -1){
                    $scope.selectedFirmArray.push(Number(id));
                }
            }else{
                $scope.selectedFirmArray =  $scope.selectedFirmArray.filter(function (item) {

                    return item != id; 
                
                });
            }

            $scope.selectAll = true;
            angular.forEach($scope.selectedFirm, function(value, key){
                if(!value.is_checked){
                    $scope.selectAll = false;
                }; 
            });
            
            $scope.totalQueueFirms = $scope.selectedFirmArray.length;
            localStorage.setItem('firmCommissionBatch',JSON.stringify($scope.selectedFirmArray)); 
        }

        $scope.toggleAll = function(isSelectAll){
        	angular.forEach($scope.selectedFirm, function(value, key){                
                $scope.selectedFirm[value.id].is_checked = isSelectAll;
                $scope.toggleFirm(isSelectAll,value.id);
            });  
        }

        $scope.changeCommissionForSelectedFirms = function(isAllButtonClick){
            
            if(!$scope.isAuthenticated){
               //passing isAllButtonClick flag to ask password function b'cus after authentication it will not return isAllButtonClick flag
               $scope.askPass(isAllButtonClick);

            }else{
 
                if(isAllButtonClick){
                    $scope.selectedFirmArray = [];
                    $scope.totalQueueFirms = 0; 
                    localStorage.setItem('firmCommissionBatch',JSON.stringify($scope.selectedFirmArray));    
                    $scope.reloadData();  
                    var txt = "<span style='color:red'> ALL </span> FIRMS ARE SELECTED";
                }else{

                    $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('firmCommissionBatch'));  

                    if($scope.selectedFirmArray == null){ 
                        $scope.selectedFirmArray = [];
                    }

                    if(!$scope.selectedFirmArray.length > 0){
                       Notification.error('THREE ARE NO FIRM SELECTED !');
                       return false; 
                    }
                    var txt = "THERE ARE <span style='color:red'> "+ $scope.selectedFirmArray.length +" </span> FIRMS SELECTED";
                }

                $rootScope.showLoader(true);  
                var modalInstance = $uibModal.open({

                    templateUrl : 'modules/resources/templates/change_commission.html',
                    controller : function($scope, $uibModalInstance){
                        
                        $scope.formData = {};
                        $scope.isSubmitted = false;

                        $scope.closeModal = function(){
                            $uibModalInstance.close();
                        }

                        $scope.save = function(isValid){
                            $scope.isSubmitted = true;
                            if(isValid){
                                $uibModalInstance.close($scope.formData);
                            }
                        }
                    }, 
                    backdrop:false
                });

                modalInstance.opened.then(function(){
                    $rootScope.showLoader(false);
                });

                modalInstance.result.then(function (new_commission) {
                    
                    if(new_commission && !angular.equals(new_commission, {})){ 
                        swal({
                            title: txt,
                            text: "Are You Sure Want To Change?",
                            html:true,
                            type: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) { 
                                $rootScope.showLoader(true);
                                var data = {};
                                if(isAllButtonClick){
                                    data = $scope.filterObj;
                                    data.length = -1;
                                }else{
                                    data.salesperson_id = $state.params.id;
                                    data.ids = $scope.selectedFirmArray;
                                }

                                data.commission_percentage = new_commission.commission_percentage;
                                data.overhead_amt = new_commission.overhead_amt;
                        
                                $http.post(apiUrl+'/api/update-firm-commission',data).success(function(res){
                                    $rootScope.showLoader(false);
                                    if(res.error){
                                        Notification.error(res.result.message);
                                    }else{
                                        Notification.success(res.result.message);
                                        $scope.reloadData();
                                    }
                                });
                            }
                        });  
                    }

                });
            }      
            
        }

        $scope.askPass = function(isAllButtonClick){

            swal({
                title: "PLEASE ENTER PASSWORD TO CHANGE COMMISSION",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) {
                
                if(inputValue === false){
                    return false;
                }else if(inputValue === ""){
                    Notification.error("You need to write something!");
                    return false
                }else{
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                        .success(function(response){
                            $rootScope.showLoader(false);
                            if(response['result']){
                                if(response['result']['auth']){
                                    $scope.isAuthenticated = true;
                                    $scope.changeCommissionForSelectedFirms(isAllButtonClick);
                                }else{
                                    Notification.error("Error !, Wrong Password!");
                                }
                            }else{
                                Notification.error("Oops !, Something went wrong!");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("Oops !, Something went wrong!");
                        });
                }
            });
        }
}]);

