angular.module('deitz')
    .controller('InvoiceBulkReprintByFirmController', [
        '$scope', 
        '$rootScope', 
        '$http',
        'apiUrl',
        'Notification',
        '$timeout',
        '$stateParams',
        '$state',
        'configFactory',
        'commonFactory',
        '$window',
        
        'DTOptionsBuilder',
        'DTColumnBuilder',
        '$q',
        '$uibModal',
        '$cookies',
        '$compile',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            Notification,
            $timeout,
            $stateParams,
            $state,
            configFactory,
            commonFactory,
            $window,
            DTOptionsBuilder,
            DTColumnBuilder,
            $q,
            $uibModal,
            $cookies,
            $compile,
        ) {
            
            $scope.invData= [];
            $scope.selectedAttorneysArray = []
            $scope.dateRange_invoice
        $scope.currentDate = moment().format('MM/DD/YYYY');
        $scope.isOnlyNYCTAGroupSelected = false;
        $scope.filterDate = false
        $scope.isPay = false;
        $scope.dtInstance = {};
        $scope.download_zip = apiUrl+'/api/reprint-invoices-by-firm-dowload-zip/';

        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));

        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.updateDate = function (data) {
           console.log(data)
           $scope.invoiceArray = {};
            // $scope.dtInstance.rerender();
            $scope.dateRange = {startDate:data.dateRange.startDate.toString(),endDate:data.dateRange.endDate.toString()}
            console.log($scope.dateRange);
            localStorage.setItem('dateRange_invoice_startDate',data.dateRange.startDate.toString());
            localStorage.setItem('dateRange_invoice_endDate',data.dateRange.endDate.toString());
           
            $scope.reloadData();
        };
        $scope.updateDateFaxEmail = function (data) {
            $scope.filterDateSent = true;
             
             localStorage.setItem('dateRange_fax_email_startDate',data.dateRange_fax_email.startDate.toString());
             localStorage.setItem('dateRange_fax_email_endDate',data.dateRange_fax_email.endDate.toString());
            
             $scope.reloadData();
        };
        $scope.toggleAllInvoices = function (isChecked) {
            angular.forEach($scope.invoiceArray, function (value, key) {
                $scope.invoiceArray[value.attorney_id].is_checked = isChecked;
                $scope.SelectAttorneys(isChecked, value.attorney_id);
            });
        };
         
        $scope.reloadData = function () {
            var table = $('#InvTable').DataTable();
            table.ajax.reload();
            // $scope.dtInstance.rerender();
        };
        $scope.changeExludeStatus = function (id,status) {
           
            console.log(status,id, '========')
            $rootScope.showLoader(true);
            var newStatus = status == 'Y' ? 'N' : 'Y';
            var page = $scope.dtInstance.DataTable.page();
            commonFactory.post('/api/updateFirm',{'id':id,'exclude_invoice_reprint':newStatus}).success(function (res) {
                $rootScope.showLoader(false);
                if (res.error)
                {
                    $scope.dtInstance.DataTable.page(page).draw(false);
                    // $scope.reloadData()
                }
                else{ 
                    $scope.dtInstance.DataTable.page(page).draw(false);
                    // $scope.reloadData()
                }
            }).error(function (err) {
                
             $rootScope.showLoader(false);
            }
                
            );
        };
        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                localStorage.setItem('status', $scope.statusFilter);
                
                if($scope.statusFilter){
                    data.STATUS = $scope.statusFilter;
                }
                // localStorage.setItem('dateRange_invoice', $scope.dateRange_invoice);
                function isValidDate(dateObject){
                    return new Date(dateObject).toString() !== 'Invalid Date';
                }
               
                $scope.dateRange = {startDate:localStorage.getItem('dateRange_invoice_startDate'),endDate:localStorage.getItem('dateRange_invoice_endDate')}
                
                if($scope.filterDate && $scope.dateRange.startDate){
                    
                    data.startDate = moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                    data.endDate = moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                    if(isValidDate($scope.dateRange.startDate) && isValidDate($scope.dateRange.endDate)){
                        data.filterDate = true
                    }else{
                        data.filterDate = false

                    }
                }

                $scope.dateRangeFaxEmail = {startDate:localStorage.getItem('dateRange_fax_email_startDate'),endDate:localStorage.getItem('dateRange_fax_email_endDate')}
                
                if($scope.filterDateSent && $scope.dateRangeFaxEmail.startDate){
                    
                    data.startDateFaxEmail = moment($scope.dateRangeFaxEmail.startDate).format('YYYY-MM-DD'), 
                    data.endDateFaxEmail = moment($scope.dateRangeFaxEmail.endDate).format('YYYY-MM-DD') 
                    if(isValidDate($scope.dateRangeFaxEmail.startDate) && isValidDate($scope.dateRangeFaxEmail.endDate)){
                        data.filterDateSent = true
                    }else{
                        data.filterDateSent = false

                    }
                }
               
                //Exists
                
                localStorage.setItem('EmailIsExists', $scope.EmailIsExists);
                if($scope.EmailIsExists){
                    
                    data.EmailIsExists = true;
                }

                localStorage.setItem('FaxExists', $scope.FaxExists);
                if($scope.FaxExists){
                    
                    data.FaxExists = true;
                }

                localStorage.setItem('EmailIsEmpty', $scope.EmailIsEmpty);
                if($scope.EmailIsEmpty){
                    
                    data.EmailIsEmpty = true;
                }
                //Empty
                localStorage.setItem('FaxEmpty', $scope.FaxEmpty);
                if($scope.FaxEmpty){
                    data.FaxEmpty = true;
                }
                localStorage.setItem('AlreadyEmail', $scope.AlreadyEmail);
                if($scope.AlreadyEmail){
                    data.AlreadyEmail = true;
                }
                localStorage.setItem('AlreadyFax', $scope.AlreadyFax);
                if($scope.AlreadyFax){
                    data.AlreadyFax = true;
                }
                localStorage.setItem('AlreadyPrint', $scope.AlreadyPrint);
                if($scope.AlreadyPrint){
                    data.AlreadyPrint = true;
                }

                localStorage.setItem('includeDisposedOnly', $scope.includeDisposedOnly);
                if($scope.includeDisposedOnly){
                    data.includeDisposedOnly = true;
                }

                localStorage.setItem('ExcludeBulk', $scope.ExcludeBulk);
                if($scope.ExcludeBulk){
                    data.ExcludeBulk = true;
                }
                localStorage.setItem('IncludeBulk', $scope.IncludeBulk);
                if($scope.IncludeBulk){
                    data.IncludeBulk = true;
                }
                localStorage.setItem('typeFilter', $scope.typeFilter);
                 
                if($scope.typeFilter){
                    if($scope.typeFilter == 'All Invoice'){
                        data.InvoiceGroup = 'All';
                    }
                    if($scope.typeFilter == 'Panel-Tag Invoice'){
                        data.InvoiceGroup = 'Tag';
                    }
                    if($scope.typeFilter == 'Staff Invoice'){
                        data.InvoiceGroup = 'UnTag';
                    }
                }
               
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); 

                // map your server's response to the DataTables format and pass it to
                if(data.search.value.length ){
                    $("#InvTable").addClass("blur-bg");
                }else{
                    $rootScope.showLoader(true);
                }
                commonFactory.showDataTable('/api/unpaid-invoices-by-firm', data).success(function (res) {
                    $("#InvTable").removeClass("blur-bg");
                   $rootScope.showLoader(false);
                   if (res.error)
                   {
                       $scope.reloadData();
                   }
                   else{ // DataTables' callback
                    $scope.invData = res.data
                    $scope.invoiceArray = {};
                    callback(res);
                   }
               }).error(function (err) {
                $("#InvTable").removeClass("blur-bg");
                $rootScope.showLoader(false);
                   if (err.error)
                   $scope.reloadData();
               });
            })
            .withOption('processing', true)
            .withOption('stateSave', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [ 4, 'asc' ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'CodetypeDetails' },
                { extend: 'pdf', title: 'CodetypeDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);
            var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllInvoices(selectAll)">';

            $scope.dtColumns = [];
            $scope.dtColumns = [
                DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable()
                .renderWith(function (data, type, full, meta) {
    
                    const index = $scope.selectedAttorneysArray.findIndex(o =>
                        o.attorney_id == full.attorney_id
                    );
                    
                    $scope.invoiceArray[full.attorney_id] = full;
                    $scope.invoiceArray[full.attorney_id].is_checked = index > -1 ? true : false;
                    
                    if (!$scope.invoiceArray[full.attorney_id].is_checked) {
                        $scope.selectAll = false;
                    }
    
                    return '<input type="checkbox" icheck ng-model="invoiceArray[' + full.attorney_id + '].is_checked" ng-change="SelectAttorneys(invoiceArray[' + full.attorney_id + '].is_checked,' + full.attorney_id + ')">';
                }).withOption('searchable', false),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.NAME').withOption('defaultContent', '').withOption('searchable', true).withTitle('Attorney Firm').renderWith(function(data,type,full){
                
                if(full.attorney_firms)   
                return '<a ui-sref="firms.manage.step_one({id : ' + full.attorney_firms.id + '})">'+full.attorney_firms.NAME+'</a>' 
                // return full.attorney_firms.NAME 
               
            }),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.exclude_invoice_reprint').withOption('defaultContent', '').withTitle('exclude').renderWith(function(data,type,full){
                var action = ''
                if(full.id){
                    // action += " <a class='btn btn-danger btn-circle' ng-if='" + !full.deleted_at + "' ng-click='deleteUser(" + full.id + ")'><i class='fa fa-trash'></i></a>";

                   
                    var tooltip = (full.attorney_firms.exclude_invoice_reprint == 'Y') ? "Yes" : "No";

                    action +=" <button ladda=\"loadingDemo['"+full.id+"'].loading\" class='ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm' ng-class=\"{'btn-success': '" +full.attorney_firms.exclude_invoice_reprint + "' == 'Y', 'btn-danger': '" + full.attorney_firms.exclude_invoice_reprint + "' == 'N'}\" ng-click='changeExludeStatus(\"" + full.id + "\",\"" + full.attorney_firms.exclude_invoice_reprint + "\")'>" + tooltip + "</button>";

                }
                return action;
            }),
            DTColumnBuilder.newColumn('count').withOption('defaultContent', '').withTitle('Job Counts').withOption('searchable', false).renderWith(function(data,type,full){
                
                    return data
            }),
            DTColumnBuilder.newColumn('dte_invoice').withOption('defaultContent', '').withTitle('Last Invoice Date').renderWith(function(data,type,full){
               
                return data 
            }),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.PHONE').withOption('defaultContent', '').withTitle('Phone ').renderWith(function(data,type,full){
                
                if(full.attorney_firms)
                return full.attorney_firms.PHONE 
            }),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.EmailAddress').withOption('defaultContent', '').withTitle('Email').renderWith(function(data,type,full){
                
                if(full.attorney_firms)    
                return full.attorney_firms.EmailAddress
            }),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.FAX').withOption('defaultContent', '').withTitle('Fax').withOption("width", "4%").renderWith(function(data,type,full){
              
                if(full.attorney_firms)
                return full.attorney_firms.FAX
            }),
             DTColumnBuilder.newColumn('ATTORNEY_tbl.business_unit_id').withOption("width", "6%").withOption('defaultContent', '').withTitle('Business Unit').renderWith(function(data,type,full){
                
                if(full.attorney_firms)
                return full.attorney_firms.businessunit.name
            }),
            DTColumnBuilder.newColumn('ATTORNEY_tbl.CITY').withOption('defaultContent', '').withTitle('City').renderWith(function(data,type,full){
                
                if(full.attorney_firms)
                return full.attorney_firms.CITY
            }),
            DTColumnBuilder.newColumn('dte_invoice').withOption('defaultContent', '').withTitle('Date Status').withOption('searchable', false).renderWith(function(data,type,full){
                if(full.attorney_firms && full.attorney_firms.reprint_invoice_status){
                    return full.attorney_firms.reprint_invoice_status.created_at
                }
                    return '' 
            }), 
            DTColumnBuilder.newColumn('dte_invoice').withOption('defaultContent', '').withTitle('Status').withOption('searchable', false).renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                   
                    if(full.attorney_firms && full.attorney_firms.reprint_invoice_status&& full.attorney_firms.reprint_invoice_status.status == 'print'){
                        return '<button class="btn btn-warning" >' +
                        'Printed' +
                        '</button> '+'&nbsp;<button class="btn btn-xs btn-default"  ng-click="showPop(' + full.attorney_id + ')" >' +
                        '<i class="fa fa-eye text-navy"></i></button>' ;                
                    }
                    if(full.attorney_firms && full.attorney_firms.reprint_invoice_status&& full.attorney_firms.reprint_invoice_status.status == 'email'){
                        return '<button class="btn btn-warning" >' +
                        'Email' +
                        '</button> '+'&nbsp;<button class="btn btn-xs btn-default"  ng-click="showPop(' + full.attorney_id + ')" >' +
                        '<i class="fa fa-eye text-navy"></i></button>' ;                
                    }
                    if(full.attorney_firms && full.attorney_firms.reprint_invoice_status&& full.attorney_firms.reprint_invoice_status.status == 'fax'){
                        return '<button class="btn btn-warning" >' +
                        'Fax' +
                        '</button> '+'&nbsp;<button class="btn btn-xs btn-default"  ng-click="showPop(' + full.attorney_id + ')" >' +
                        '<i class="fa fa-eye text-navy"></i></button>' ;                
                    }
                }
            }),
            DTColumnBuilder.newColumn('pdf_zip').withOption('defaultContent', '').withOption('searchable', false).withOption("width", "8%").withTitle('Print PDF(click to download)').notSortable().renderWith(function(data,type,full){
                
                    
                    return '<button class="btn btn-xs btn-default" ng-click="download_invoice_by_firm(' + full.attorney_id + ')" >'+'<i class="fa fa-file-archive-o text-navy" aria-hidden="true"></i>'+'</button>';
                  
                    // return '<a href='+$scope.download_zip+full.attorney_id+' download> <button class="btn btn-xs btn-default">'+'<i class="fa fa-file-o" aria-hidden="true"></i>'+'</button></a> &nbsp;';
               
            }),
         
            //DTColumnBuilder.newColumn('slug').withOption('defaultContent', '').withTitle('Slug').notSortable(),
            DTColumnBuilder.newColumn('id').withTitle(' Actions ').withOption('searchable', false).notSortable()
            .renderWith(function(data, type, full, meta) {
               
                     
                return  '<button class="btn btn-xs btn-default"  ng-click="updatePop(' + full.attorney_id + ')" >' +
                        '<i class="fa fa-pencil-square-o text-navy"></i>'
                        +
                        '</button> &nbsp;&nbsp;<button class="btn btn-xs btn-default"  ng-click="SendEmail(' + full.attorney_id + ')" >' +
                        '<i class="fa  fa-envelope-o text-navy"></i>' +
                        '</button> ' +
                        '</button> &nbsp;&nbsp;<button class="btn btn-xs btn-default"  ng-click="SendFax(' + full.attorney_id + ')" >' +
                        '<i class="fa  fa-fax text-navy"></i>' +
                        '</button> ';
                         

            })
        ];
        $scope.clearStatements = function (){
            $scope.formData = {
                includeInvoices : false,
                selectedAttorneys : [],
                excludeBilledToIds : [],
                filterDate : false,
                includeExcludeBilledToIds : 'include',
                dateRange : {
                    endDate : moment().format('MM-DD-YYYY'),
                    startDate : moment().format('MM-DD-YYYY')
                }
            };
            $scope.Statements = null;
            $scope.billToList = [];
            $scope.tagRemoved();
        };
       
        $scope.bulk_send_fax = function(id){
            swal({
                    title: "Are you sure ?",
                    text: "Are you sure you want to fax "+$scope.selectedAttorneysArray.length+" firm(s)?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1ab394",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/fax-email-multiple-firm-email',{"data": $scope.selectedAttorneysArray,"via":'fax'}).success(function (res) {
                            $rootScope.showLoader(false);
                            $scope.invoiceArray = {};
                            $scope.selectedAttorneysArray = []
                            if (res.error)
                            {
                                $scope.reloadData();
                            }
                            else{ 
                                $scope.dtInstance.rerender();
                                $scope.invoiceArray = {};
                                $scope.selectedAttorneysArray = []
                                Notification.success('Fax Sent Succusfully');// DataTables' callback
                                // location.reload(); 
                                $scope.reloadData();
                            }
                        }).error(function (err) {
                         $rootScope.showLoader(false);
                            if (err.error)
                            $scope.reloadData();
                        });
                    } else {
                        // $uibModalInstance.close(false);
                    }
                });
        };
        $scope.bulk_send_email = function(id){
            swal({
                    title: "Are you sure ?",
                    text: "Are you sure you want to email "+$scope.selectedAttorneysArray.length+" firm(s)?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#1ab394",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) { 
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/fax-email-multiple-firm-email',{"data": $scope.selectedAttorneysArray,"via":'email'}).success(function (res) {
                            $rootScope.showLoader(false);
                            $scope.invoiceArray = {};
                            $scope.selectedAttorneysArray = []
                            if (res.error)
                            {
                                $scope.reloadData();
                            }
                            else{ 
                                $scope.dtInstance.rerender();
                                $scope.invoiceArray = {};
                                $scope.selectedAttorneysArray = []
                                Notification.success('Email Sent Succusfully');// DataTables' callback
                                // location.reload(); 
                                $scope.reloadData();
                            }
                        }).error(function (err) {
                         $rootScope.showLoader(false);
                            if (err.error)
                            $scope.reloadData();
                        });
                    } else {
                        // $uibModalInstance.close(false);
                    }
                });
        };
         /* Getting statements data to display on the web page*/
      

         $scope.download_invoice_by_firm = function(id){
            $rootScope.showLoader(true);
            $http.get(apiUrl+'/api/reprint-invoices-by-firm-dowload-zip/'+id).success(function(response){
                $rootScope.showLoader(false);
                $scope.reloadData();
                // $window.location.href = response;
                $window.open(response, '_blank');
                // link.remove();
                // Window.open(response);
                // return  response;
             });
        }

        $scope.SelectAttorneys = function (is_checked, AttorneysNo) {
            console.log($scope.selectedAttorneysArray,'=');
            const AttorneysObject = {attorney_id: AttorneysNo};
            const index = $scope.selectedAttorneysArray.findIndex(o =>
                 o.attorney_id == AttorneysNo
            );


            if (is_checked) {
                if (typeof index == 'undefined' || index <= -1) {
                    $scope.selectedAttorneysArray.push(AttorneysObject);
                }
            }
            else {

                if (typeof index !== 'undefined' && index > -1) {
                    $scope.selectedAttorneysArray.splice(index, 1);
                }
            }

            var keepGoing = true;
            $timeout(function () {
                angular.forEach($scope.invoiceArray, function (value, key) {
                    if (keepGoing) {
                        if (!$scope.invoiceArray[key].is_checked) {
                            $scope.selectAll = false;
                            keepGoing = false;
                        }
                        else {
                            $scope.selectAll = true;
                        }
                    }

                });
            });
        }

      

        /* Sending statements via Email/FAX for both claimrep and attorney firm */
        $scope.emailOrFaxStatements = function(via){ 

            var data = angular.copy($scope.formData);
            data.via = via; 
  
            var sendStatementsModal = $uibModal.open({
                templateUrl: "modules/resources/templates/send_statements_modal.html",
                controller: function($scope, $uibModalInstance, $sce, $rootScope, $http, apiUrl){
                    $scope.formData = {};
                    $scope.formData = data;
                    $scope.Authuser = JSON.parse($cookies.get('authUser'));
                    $scope.formData.ccList = $scope.Authuser.email;

                    var fax = $scope.formData.selectedAttorneys[0].FAX;
                    var email = $scope.formData.selectedAttorneys[0].EmailAddress;
                    var name = $scope.formData.selectedAttorneys[0].NAME 

                    $scope.business_unit_id = null;
                    $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                        $scope.BusinessUnitsList = response.data.result.data;  
                    });
                    
                    $scope.buChange = function(){
                        $http.post(apiUrl+'/api/get-fax-email-messages', {'selectedBusinessUnitIDs' : $scope.formData.business_unit_id}).success(function(response){
                            $scope.formData.messages = response.data; 
                        });
                    }
                     
                    $scope.allowOverWrite = false;
                    $scope.title =  $scope.formData.via.toUpperCase() + " STATEMENTS ";
                    $scope.via =  $scope.formData.via == 'email' ? "E-MAIL " : "FAX #";
                    $scope.attOrClmrp =  'ATTORNEY FIRM';

                    /*Check if the only one selection for the claim rep / attorney then we are allowing to overwrite the email/ fax number.*/
                    if($scope.formData.selectedAttorneys.length === 1){
                        $scope.allowOverWrite = true;
                        $scope.formData.inputValue = $scope.formData.via === 'fax' ? fax : email;
                    }
                    $scope.selectMessage = function(messages){
                        $scope.formData.selectedMessage = messages;
                    }
                    $scope.send = function(isValid){
                        if(isValid){
                            $uibModalInstance.close({isSend : true, formData : $scope.formData});  
                        }
                    };

                    $scope.closePopupWindow = function(a){
                        $uibModalInstance.close({isSend : false, formData : {}});
                    };
                },

                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            sendStatementsModal.result.then(function (resp) {
                if(resp.isSend){
                    $scope.sendStatements(resp.formData);
                }
            }, function () {
                
            });
        };

        
        $scope.update_popup = function () {
            Notification.success('invoice updated succesfully');// DataTables' callback
            $scope.dtInstance.rerender();
        };
        $scope.send_mail_popup = function () {
            Notification.success('email sent succesfully');
            $scope.dtInstance.rerender();
        
        };
        $scope.send_fax_popup = function () {
            Notification.success('fax  sent succesfully');
            $scope.dtInstance.rerender();
        
        };
        $scope.showPop= function (invoice) {

            invoice = $scope.invData.filter(function(e) {
                return e.attorney_id == invoice;
              });
             
              invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/statements/invoice_bulk_reprint_by_firm/view_popup.html",
                controller: 'viewPopupController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.updatePop= function (attorney_id) {

            invoice = $scope.invData.filter(function(e) {
                return e.attorney_id == attorney_id;
              });
              console.log("aa",invoice)
              invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/statements/invoice_bulk_reprint_by_firm/update_popup.html",
                controller: 'viewPopupController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.SendEmail= function (attorney_id) {
            
            invoice = $scope.invData.filter(function(e) {
                return e.attorney_id == attorney_id;
              }); 
              
             
              invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/statements/invoice_bulk_reprint_by_firm/email_popup.html",
                controller: 'viewPopupController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.SendFax= function (attorney_id) {
            
            invoice = $scope.invData.filter(function(e) {
                return e.attorney_id == attorney_id;
              }); 
              
             
              invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/statements/invoice_bulk_reprint_by_firm/fax_popup.html",
                controller: 'viewPopupController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }

        $scope.sendStatements = function(data){
            
            swal({
                title: "Are you sure ?",
                text: "You are about to send the statements.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Send it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);

                    var ids = []; 
                    angular.forEach(data.selectedAttorneys, function(value, key) {
                       ids[key] = value.id;
                    }); 

                    commonFactory.post('/api/send-group-statements', {
                        ids : ids,
                        via: data.via,
                        inputValue : data.inputValue,
                        business_unit_id:data.business_unit_id,
                        ccList : data.ccList,
                        includeInvoices : data.includeInvoices ? data.includeInvoices : false,
                        needToUpdateProfile : data.needToUpdateProfile ? data.needToUpdateProfile : false,
                        additionalComment : data.selectedMessage,
                        excludeBilledToIds : data.excludeBilledToIds,
                        includeExcludeBilledToIds : data.includeExcludeBilledToIds,
                        isOnlyNYCTAGroupSelected : $scope.isOnlyNYCTAGroupSelected,
                        filterDate : data.filterDate,
                        startDate : moment(data.dateRange.startDate).format('YYYY-MM-DD'), 
                        endDate : moment(data.dateRange.endDate).format('YYYY-MM-DD') 
                    })
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message);
                        } else{
                            Notification.success(response.result.message);
                        }

                    })
                    .error(function(err) {
                        Notification.error("Something went wrong!");
                        $rootScope.showLoader(false);
                    });
                }
            });
        };
    }
]);
