angular.module('deitz')
    .controller('AddTimeOffsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'reptyp_availability_date_id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  reptyp_availability_date_id,
                  $uibModalInstance,
                  configFactory) {
            $scope.timeOff = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Add Timeoff';

            $scope.timeOff.reptyp_id = $state.params.id;
            $scope.timeOff.start_date = '';
            $scope.timeOff.start_time = '';
            $scope.timeOff.end_date = '';
            $scope.timeOff.end_time = '';
            $scope.timeOff.notes = '';
            
            $scope.reptyp_availability_date_id = reptyp_availability_date_id;

        if(reptyp_availability_date_id){
            $scope.title = 'Edit Timeoff';

            var id = reptyp_availability_date_id;
            commonFactory.get('/api/reporter-timeoffs/' + id + '/edit')
            .success(function(response) {
                $rootScope.showLoader(false);
                if (response.status == 200 && response.result) {
                    $scope.timeOff.reptyp_id = response.result.timeOffs.reptyp_id || '';
                    $scope.timeOff.start_date = response.result.timeOffs.start_date || '';
                    $scope.timeOff.end_date = response.result.timeOffs.end_date || '';
                    $scope.timeOff.notes = response.result.timeOffs.notes || '';
                    $scope.timeOff.start_time = new Date(response.result.timeOffs.start_date+' '+response.result.timeOffs.start_time)|| '';
                    $scope.timeOff.end_time = new Date(response.result.timeOffs.end_date+' '+response.result.timeOffs.end_time)|| '';
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });
        }


        $scope.addReporterTimeOff = function(data, isValid) {
            $scope.formSubmit = true;  
            let timeOff = Object.assign({}, data);
            
            let startdate = '';
            if(typeof timeOff.start_date != "undefined" && timeOff.start_date != ""){
                startdate = timeOff.start_date = moment(timeOff.start_date).format('YYYY-MM-DD');                    
            }

            /*var after7days = moment().add(7, 'days').format('YYYY-MM-DD');

            if(startdate < after7days){
                Notification.error('The date(s) you’re entering are less than one week from today.');
                return false;
            }*/

            let enddate = '';
            if(typeof timeOff.end_date != "undefined" && timeOff.end_date != ""){
                enddate = timeOff.end_date = moment(timeOff.end_date).format('YYYY-MM-DD');                    
            }
            let starttime = '';
            if(typeof timeOff.start_time != "undefined" && timeOff.start_time != ""){
                starttime = timeOff.start_time = moment(timeOff.start_time).format('HH:mm:ss');                    
            }
            let endtime = '';
            if(typeof timeOff.end_time != "undefined" && timeOff.end_time != ""){
                endtime = timeOff.end_time = moment(timeOff.end_time).format('HH:mm:ss');                    
            }

            startdate = moment(startdate+' '+starttime).format('YYYY-MM-DD HH:mm:ss');
            enddate = moment(enddate+' '+endtime).format('YYYY-MM-DD HH:mm:ss');
            
            if(startdate >= enddate){
                Notification.error('End date will be greater than start date.');
                return false;
            }
            
            if (isValid) {
                $rootScope.showLoader(true);

                if(reptyp_availability_date_id){
                    var id = reptyp_availability_date_id;

                    commonFactory.put('/api/reporter-timeoffs/' + id, timeOff)
                        .success(function(response) {
                            $rootScope.showLoader(false);
                            if (response) {
                                if (response.status == 200) {
                                    Notification.success("Timeoff updated successfully");
                                    $scope.closePopupWindowSimple();
                                }else if(response.status == 400){
                                    Notification.error(response.result.message);
                                }
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                }else{
                    commonFactory.post('/api/reporter-timeoffs', timeOff)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("TimeOff added successfully");
                                $scope.closePopupWindowSimple();
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
                }
            }
        }
        
        $scope.closePopupWindowSimple = function(){
            $uibModalInstance.close(true);
            setTimeout(function () {
                document.getElementById('reloadTimeOffs').click();
            }, 400);
        };
        
    }]);
