angular.module('deitz')
    .controller('sendWipToResourceController', [
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        '$compile',
        'codeFactory',
        'jobNo',
        'resources',
        'caption',
        function (
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            $compile,
            codeFactory,
            jobNo,
            resources,
            caption) {
            $scope.title = "Send WIP to Resource"
            $scope.resourceType = { 'R': 'Reporter', 'T': 'Typist', 'SC': 'Scopist', 'S': 'Account Executive', 'V': 'Videographer' };
            $scope.resources = resources;
            $scope.caption = caption;
            $scope.formData = {};
            $scope.selectedResource = null;
           
            $scope.showMail = false;
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;

            $scope.cancel = function (isChanged) {
                $uibModalInstance.dismiss({ isChanged: isChanged });
            };

           
            $scope.changeResource = function () {
                // find selected resource
                $scope.formData={};
                $scope.formData.JOB_NO = jobNo;
                const index = $scope.resources.findIndex(function (o) {
                    return o.resource_id == $scope.jobSmsForm.resource_id;
                });
                $scope.selectedResource = $scope.resources[index].resource;
                if (!$scope.selectedResource.CELL) {
                    $scope.formData.email_address = $scope.selectedResource.Emai_Address;
                    $scope.showMail = true;
                }
                else{
                    $scope.formData.CELL = $scope.selectedResource.CELL;
                    $scope.showMail = false;
                }
                $scope.formData.message= $scope.caption+"  : ATTENTION - your transcript is late! Please upload ASAP!";
                $scope.formData.resource_id = $scope.selectedResource.id;
            };

            $scope.sendJobConfirmation = function (isValid) {
                if(isValid){
                    swal({
                        title: "Are you sure",
                        text: "You want to send?",
                        type: "warning",
                        html: true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) { 
                            console.log($scope.formData);
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/send-work-in-progress-reminder', $scope.formData).success(function (response) {
                                $rootScope.showLoader(false);
                                if (!response.error) {
                                    Notification.success(response.result.message);
                                    $uibModalInstance.dismiss({ isChanged: true });
                                }else{
                                    Notification.error(response.result.message);
                                }
                            });
                        }
                    });
                   
                }
            }

        }]);

