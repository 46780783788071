angular.module('deitz').controller('AddInvTransController', [
    '$state',
    '$rootScope',
    '$scope',
    '$http',
    'apiUrl',
    'Notification',
    function ($state,
        $rootScope,
        $scope,
        $http,
        apiUrl,
        Notification
    ) {

        $scope.formData = {};
        $scope.formSubmit = false;
        $scope.title = ($state.params.id) ? "EDIT" : "ADD";

        /* Use for get level data for update*/
        if ($state.params.id) {
            $http.get(apiUrl + '/api/invoice-and-transcript-handling/' + $state.params.id + '/edit')
                .success(function (response) {
                    $scope.formData = response.result.inv_trans_detail;
                }).error(function (err) {
                    if (err.error) {
                        console.log(err.error)
                    }
                });
        }

        /* Use for save data */
        $scope.addInvoiceAndTranscriptHandlingMethod = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                if ($state.params.id) {
                    /* API call for update data */
                    $http.put(apiUrl + '/api/invoice-and-transcript-handling/'+ $state.params.id, $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Record updated Successfully');
                            $state.go('code_tables.invoice_and_transcript_handling');
                        }
                    });
                } else {
                    /* API call for add data */
                    $http.post(apiUrl + '/api/invoice-and-transcript-handling', $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Record Inserted Successfully');
                            $state.go('code_tables.invoice_and_transcript_handling');
                        }
                    });
                }

            }
        }
    }]);