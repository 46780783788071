angular.module('deitz')
    .controller('progressiveReportController', [
        '$scope', 
        '$rootScope', 
        '$http',
        'apiUrl',
        'Notification',
        '$timeout',
        '$stateParams',
        '$state',
        'configFactory',
        'commonFactory',
        '$window',
        '$uibModal',
        '$cookies',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            Notification,
            $timeout,
            $stateParams,
            $state,
            configFactory,
            commonFactory,
            $window,
            $uibModal,
            $cookies
        ) {

        $scope.formData = {
            selectedAttorneys : [],
            third_party_name : '',
            dateRange : {
                endDate : moment().format('MM-DD-YYYY'),
                startDate : moment().format('MM-DD-YYYY')
            },
            includeInvoiceType : 'all'
        };

        // get firms groups
        $rootScope.showLoader(true);
        commonFactory.get('/api/get-firm-groups')
        .success(function(response) {
            $rootScope.showLoader(false);
            $scope.firmGroups = response.result;
        })
        .error(function(err) {
            $rootScope.showLoader(false);
        });

        $scope.groupChange = function(){

            var groups = [];    
            angular.forEach($scope.formData.selectedGroups, function(value, key) {
               groups[key] = value.group_name;
            });
            
            // get firms of selected groups
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.formData.selectedAttorneys = response.result;
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        };

        $scope.clear = function (){
            $scope.formData = {
                selectedAttorneys : [],
                third_party_name : '',
                dateRange : {
                    endDate : moment().format('MM-DD-YYYY'),
                    startDate : moment().format('MM-DD-YYYY')
                },
                includeInvoiceType : 'all'
            };
        };

        //Getting Attorneys list
        $scope.attorneysList = function(val) {
            $scope.searchingAtt = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $scope.searchingAtt = false;
                return response.data.result.map(function(item) {
                    var department = item.department_name ? ' || '+ item.department_name : '';
                    item.NAME = item.NAME + department;
                    return item;
                });
            });
        };

        //get business units
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});        
        });        

        $scope.download = function(valid){            
            if(valid){
                
                $rootScope.showLoader(true);
                
                var ids = [];    
                angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
                   ids[key] = value.id;
                });

                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                commonFactory.post('/api/generate-progressive-report', { 
                    ids : ids,
                    third_party_name : $scope.formData.third_party_name,
                    startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'),
                    endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD'),
                    includeInvoiceType : $scope.formData.includeInvoiceType,
                    selectedBusinessUnitIDs : $scope.selectedBusinessUnitIDs.filter(Boolean)
                }).success(function(response) {
                    $rootScope.showLoader(false);
                    if(!response.error){
                        // $window.location.href = apiUrl +'/api/download-progressive-report';
                        $window.open(apiUrl +'/api/download-progressive-report', '_blank');  
                    }else{
                        Notification.error(response.result.message);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                    Notification.error(err);
                });
            }
        };
    }
]);
