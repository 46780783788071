angular.module('deitz')
    .factory('locationFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var locationFactory = {};        

        // Browse all firms
        locationFactory.locations = function(data) {
            return $http.get(apiUrl + "/api/locations", data);
        };        
        return locationFactory;
    }]);
