angular.module('deitz')
    .controller('addDeliverableDocumentsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'JOB_NO',
        'depoUserId',
        'job_transcripts',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
            $rootScope,
            codeFactory,
            $http,
            Upload,
            apiUrl,
            $state,
            commonFactory,
            Notification,
            $stateParams,
            $uibModal,
            JOB_NO,
            depoUserId,
            job_transcripts,
            $uibModalInstance,
            configFactory) {
            $scope.deliverableDocument = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'MANAGE DELIVERABLES';
            $scope.isPdfUploaded = false;
            $scope.deliverableDocument.JOB_NO = JOB_NO;
            $scope.deliverableDocument.depoUserId = depoUserId;
            $scope.deliverableDocument.allFiles = [];
            $scope.fileTypes = {
                'mp3': 'Audio',
                'linked_pdf': 'Linked Pdf',
                'linkedmini_pdf': 'Linked Mini Pdf',
                'pdf': 'Pdf',
                'mini': 'Pdf Mini',
                'ptx': 'Ptx',
                'summary': 'Summary',
                'txt': 'Text',
                'mp4': 'Video',
                'rtf': 'Word',
                'zip': 'Zip',
                'png': 'Png'
            }
            $scope.fileTypeSupportedExtension = {
                'mp3': ['wav', 'mp3'],
                'linked_pdf': ['pdf'],
                'linkedmini_pdf': ['pdf'],
                'pdf': ['pdf'],
                'mini': ['pdf'],
                'ptx': ['ptx'],
                'summary': ['pdf'],
                'txt': ['txt'],
                'mp4': ['webm', 'mkv', 'flv', 'avi', 'mp4', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'm2v', 'mpeg1', 'mpeg2', 'wav', 'zip'],
                'rtf': ['rtf', 'doc', 'docx'],
                'zip': ['zip'],
                'png': ['png']
            }
            $scope.clickUpload = function () {
                $("#file").trigger('click');
            }



            $scope.removeFile = function (index) {
                var attachments = document.getElementById("file").files;
                var fileBuffer = new DataTransfer();

                for (let i = 0; i < attachments.length; i++) {
                    if (index !== i) {
                        fileBuffer.items.add(attachments[i]);
                    }
                }

                document.getElementById("file").files = fileBuffer.files;
                $scope.deliverableDocument.file.splice(index, 1);
                $scope.deliverableDocument.allFiles.splice(index, 1);

                $scope.dropboxText();
            }

            $scope.fileChange = function () {
                var file = $('#file')[0].files;
                let filenames = [];
                let allFiles = [];
                for (j = 0; j < file.length; j++) {
                    filenames.push(file[j].name);
                    let obj = {};
                    obj.filename = file[j].name;
                    obj.file_type = '';
                    obj.witness_id = '';
                    obj.witness_name = '';
                    allFiles.push(obj);
                }
                $scope.deliverableDocument.allFiles = allFiles;
                $scope.dropboxText();
            }

            $scope.dropboxText = function () {
                var file = $('#file')[0].files;
                if (file.length > 0) {
                    $(".upload_h1").html(file.length + " file(s) selected. <br> Provide a below information and press the 'save' button.");
                } else {
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            commonFactory.get('/api/getwitnessfordiaryinvoice/' + JOB_NO)
                .success(function (response) {
                    if (response.result && response.result.witnessDetails) {
                        $rootScope.showLoader(false);
                        $scope.witness_info = response.result.witnessDetails;
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

            $scope.checkFileExtension = function (key) {
                var fileType = $scope.deliverableDocument.allFiles[key].file_type;
                var fileName = $scope.deliverableDocument.allFiles[key].filename;
                var fileExtension = $scope.deliverableDocument.allFiles[key].filename.split('.').pop();                    
                if (!$scope.fileTypeSupportedExtension[fileType].includes(fileExtension.toLowerCase())) {
                    Notification.error('Please select valid file type for ' + fileName);
                    $scope.deliverableDocument.allFiles[key].file_type = '';
                }
            }

            $scope.job_transcripts = job_transcripts;

            $scope.uploadProgress = 0;
            $scope.showProgress = false;

            $scope.addDeliverableDocument = function (deliverableDocument, isValid) {
                $scope.formSubmit = true;
                if (isValid) {
                    swal({
                        title: "Are you sure want to add this Files?",
                        text: "<b>This PROCEEDURE will REPLACE the existing deliverable.</b>",
                        type: "warning",
                        html:true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it!",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                        function (isConfirm) {
                            if (isConfirm) {
                                $rootScope.showLoader(true);
                                $scope.showProgress = true;

                                angular.forEach($scope.deliverableDocument.file,function(value, key){
                                    const chunkSize = 1024 * 1024 * 4; // size of each chunk (5MB)
                                    let start = 0;
                                    let i = 1;
                                    while (start < value.size) {
                                        let partName = value.name + '_part'+i;
                                        chunk = value.slice(start, start + chunkSize);
                                        start += chunkSize;
                                        if(start > value.size)
                                        {
                                            $scope.uploadChunks(chunk, JOB_NO, value.name, partName, value.size, i, true);
                                        }
                                        else
                                        {
                                            $scope.uploadChunks(chunk, JOB_NO, value.name, partName, value.size, i, false);
                                        }
                                        i++;
                                    }
                                });
                            }
                        })

                } else {
                    if ($scope.deliverableDocumentCreateForm.$error.pattern) {
                        Notification.error('Please upload accepted file only.');
                    } else {
                        Notification.error('File Type, Witness - must be required.');
                    }
                }
            }


            $scope.uploadChunks = async function(chunk, job_no, fileName, partName, size, i, is_last = false, retries = 3) {

                const formData = {};
                formData.file = chunk;
                formData.job_no = job_no;
                formData.part_name = partName;
                formData.totalSize = size;
                formData.chunkSize = chunk.size * i;

                Upload.upload({
                    url: apiUrl + '/api/save-file-chunk-to-storage',
                    data: formData
                }).then(function (response) {
                    if(is_last)
                    {
                        const data = {};
                        data.job_no = job_no;
                        data.file = fileName;
                        data.total_parts = i;

                        commonFactory.post( '/api/merge-file-chunks' , data )
                            .success(function(response) {
                                if (response) {
                                    if (response.status == 200) {
                                        const saveData = {};
                                        saveData.file = fileName;
                                        saveData.JOB_NO = job_no;
                                        commonFactory.post('/api/deliverable-documents', $scope.deliverableDocument).success(function(response) {
                                            if (response) {
                                                if (response.status == 200) {
                                                    Notification.success(response.result.message);
                                                    $scope.closePopupWindowSimple();
                                                } else if (response.status == 400) {
                                                    Notification.error(response.result.message);
                                                }
                                            }
                                        })
                                        .error(function(err) {
                                            $rootScope.showLoader(false);
                                        });
                                    } else{
                                        $rootScope.showLoader(false);
                                        Notification.error("Somthing went wrong. Please try again.");
                                    }
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                }, function (err) {
                    if (retries > 0) {
                        $scope.uploadChunk(chunk, retries - 1);
                    } else {
                        console.error('Failed to upload chunk: ', err);
                    }
                }, function (evt) {
                    if (evt.lengthComputable && !is_last) {
                        $scope.uploadProgress = parseInt(100.0 * evt.config.data.chunkSize / evt.config.data.totalSize);
                    }
                    else
                    {
                        $scope.uploadProgress = 100;
                    }
                });
            };

            $scope.closePopupWindowSimple = function () {
                $uibModalInstance.dismiss({ isChanged: true });
            };
        }]);