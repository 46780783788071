angular.module('deitz').controller('editCertificateTypesController', [
    '$scope', '$state', '$rootScope', 'codeFactory', '$http', 'commonFactory', 'Notification', 'apiUrl','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, $http, commonFactory, Notification,apiUrl,fromModal,$uibModalInstance
    ) {
        $scope.certificateTypes = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Certificate Type';
        $scope.isEdit = true;
        
        $scope.StateLists = [];
        $http.get(apiUrl + '/api/getStates').then(function(response) { 
            $scope.StateLists = response.data.result.data;
        });

        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/certificate-types/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result) {
                        $scope.certificateTypes.type = response.result.types.type || '';
                        $scope.certificateTypes.state_code = response.result.types.state_code || '';
                        state_code_ids=[];
                        angular.forEach( response.result.types.states,function(value, key){
                            state_code_ids[key] = value.state_code;
                        });
                        $scope.certificateTypes.state_code_ids = state_code_ids;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.addCertificateTypes = function(certificateTypes, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/certificate-types/' + id, $scope.certificateTypes)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Certificate Type updated successfully");
                                $state.go("code_tables.certificate_types_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
