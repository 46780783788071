angular.module('deitz')
    .controller('editRolesController', ['$scope', '$rootScope', 'codeFactory', 'commonFactory', '$state', '$stateParams', '$timeout', 'Notification', function ($scope, $rootScope, codeFactory, commonFactory, $state, $stateParams, $timeout, Notification) {

        $scope.page_title = $state.current.data.pageTitle;
        $scope.role = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.id = $stateParams.id;
        $scope.btnText = "Update";


        $rootScope.showLoader(true);
        commonFactory.get("/api/roles/" + $scope.id + "/edit").success(function (response) {
            $scope.role = response.data;
            $rootScope.showLoader(false);
        }).error(function (error) {
            $rootScope.showLoader(false);
            Notification.error(error.error);
            $state.go('roles');
        });

        $scope.otherAwesomeCallback = function(node, isSelected, tree) {
            // console.log(tree);
        };

        // clone the existing role to new one
        $scope.cloneRole = function () {
            swal({
                title: "Clone this role, Are you sure ?",
                text: "Are you sure want to clone this role?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    $scope.loadingDemo = true;
                    let data = {};
                    data.description = 'Copied from ' + $scope.role.name + ' role.';
                    data.name = 'Copy ' + $scope.role.name;
                    data.permissions = $scope.role.permissions;

                    commonFactory.post("/api/roles", data).success(function (response) {
                        $rootScope.showLoader(false);
                        $scope.loadingDemo = false;
                        Notification.success("Role Cloned successfully.");
                        $state.go('roles.edit', {id: response.role.id});
                    }).error(function (error) {
                        Notification.success(error.error || "Something went wrong.");
                        $rootScope.showLoader(false);
                        $scope.loadingDemo = false;
                    });
                }

            });
            
        }
        
        $scope.submitRole = function (form, is_valid) {

            if(is_valid){
                $rootScope.showLoader(true);

                $scope.loadingDemo = true;
                commonFactory.put("/api/roles/" + $scope.role.id, $scope.role).success(function (response) {
                    $rootScope.showLoader(false);
                    $scope.loadingDemo = false;

                    Notification.success(response.success);
                    $state.go('roles.browse');
                }).error(function (error) {
                    $rootScope.showLoader(false);
                    Notification.error(error.error);
                    $scope.loadingDemo = false;

                });
            }else{
                Notification.error("Please enter valid form data.");
            }
        };


    }]);