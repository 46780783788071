angular.module('deitz').controller('billingByFirmController.js', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory','$timeout','$filter',
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory,$timeout,$filter
    ){

        $scope.report = {
            startDate : moment(),
            endDate : moment()
        };
                //get business units
                $scope.selectedBusinessUnitIDs = [];
                var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
                if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                    $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
                }else{
                    $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                    });
                }
        
                $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                    $scope.BusinessUnitsList = response.data.result.data;  
                    $scope.BusinessUnitsList.unshift({name:'All'});
        
                });
        
        $scope.title = "Summary Billing By Firm";


        $scope.reset = function(){
            $scope.filter = {
                orderByField : 'NAME',  
                reverseSort : false,    
                currentPage : 1,    
                itemsPerPage : 10,  
                totalItems : 0, 
                searchKeyword : '' 
            };

            $scope.report = {
                startDate : moment(),
                endDate : moment()
            };

           
        }

        $scope.searchData = function(){
            // Reset filter params
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
             

            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');
            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');

            $rootScope.showLoader(true);
            commonFactory.post('/api/get-billing-by-firm-with-details', $scope.report)
                .success(function(response) {

                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else{
                    
                        $scope.reportData =  response.result.data;
                       
                        
                        var link = document.createElement('a');
                        link.href = response.result.path;
                        link.click();
                        // if($scope.reportData.length == 0){
                        //     Notification.error('No records found!');
                        // }else{
                            
                        // }
                    }
                })
                .error(function(err)
                {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        $scope.pageChange = function(currpg){
            $scope.show_loader = true;
            $scope.filter.currentPage = currpg;
            if($scope.filter.searchKeyword.length){
                $scope.searchFilter();return;
            }
            $scope.filterdReportData = angular.copy($scope.reportData.slice((($scope.filter.currentPage-1) * $scope.filter.itemsPerPage), (($scope.filter.currentPage)*$scope.filter.itemsPerPage)));
            $rootScope.showLoader(false);
            $timeout(function() {
                $scope.show_loader = false;
            }, 2000);
        }

        $scope.lengthChanged = function(length){
            $scope.show_loader = true;
            $scope.filter.itemsPerPage = length;
            $timeout(function() {
                $scope.pageChange(1)
            }, 100);
        }

        $scope.orderByFilter = function(orderBy) {
            $scope.show_loader = true;
            $scope.filter.reverseSort = (orderBy !== null && $scope.filter.orderByField === orderBy) ? !$scope.filter.reverseSort : false;
            $scope.filter.orderByField = orderBy;
            $timeout(function() {
                $scope.reportData = $filter('orderBy')($scope.reportData, $scope.filter.orderByField, $scope.filter.reverseSort);
                $scope.pageChange(1)
            }, 100);
        };

        $scope.searchFilter = function(){
            $scope.show_loader = true;
            $timeout(function() {
                $scope.filterdReportData = angular.copy($filter('filter')($scope.reportData, $scope.filter.searchKeyword));
                $scope.filter.totalItems = $scope.filterdReportData.length;
                $scope.filterdReportData = $scope.filterdReportData.slice((($scope.filter.currentPage-1) * $scope.filter.itemsPerPage), (($scope.filter.currentPage)*$scope.filter.itemsPerPage));
            }, 100);    
            $timeout(function() {
                $scope.show_loader = false;
            }, 2000);
        }
}]);
