(function() {
  'use strict';

	angular
	.module('deitz')
	.run(runBlock);

  // // angular.module('deitz').constant("apiUrl", "http://202.131.117.92:7003/admin");
  // angular.module('deitz').constant("apiUrl", "http://192.168.2.201:3000/admin");

  /** @ngInject */
  function runBlock($log, $rootScope, $state) {

    $log.debug('runBlock end');
    $rootScope.$state = $state;
  }

})();
