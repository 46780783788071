angular.module('deitz').controller('addServiceLevelController', [
    '$state',
    '$rootScope',
    '$scope',
    '$http',
    'apiUrl',
    'Notification',
    function ($state,
        $rootScope,
        $scope,
        $http,
        apiUrl,
        Notification
    ) {

        $scope.formData = {};
        $scope.formSubmit = false;
        $scope.id = $state.params.id;
        $scope.title = ($scope.id) ? "Save" : "Add";

        /* Use for get level data for update*/
        if ($scope.id) {
            $http.post(apiUrl + '/api/edit_service_level/' + $scope.id)
                .success(function (response) {
                    $scope.formData = response;
                }).error(function (err) {
                    if (err.error) {
                        console.log(err.error)
                    }
                });
        }

        /* Use for save data */
        $scope.save = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                if ($scope.id) {
                    /* API call for update data */
                    $http.post(apiUrl + '/api/update_service_level', $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Delivery Method updated Successfully');
                            $state.go('code_tables.service_level_browse');
                        }
                    });
                } else {
                    /* API call for add data */
                    $http.post(apiUrl + '/api/add_service_level', $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Delivery Method Inserted Successfully');
                            $state.go('code_tables.service_level_browse');
                        }
                    });
                }

            }
        }
    }]);