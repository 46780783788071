angular.module('deitz').controller('createchargesController', [
    '$scope', '$rootScope', '$state', 'Notification', '$stateParams', 'chargeFactory', 'commonFactory',
    function(
        $scope, $rootScope, $state, Notification, $stateParams, chargeFactory, commonFactory
    ) {

        $scope.charge = {};
        $scope.charge.attorney_id = $state.params ? parseInt($state.params.id) : '';
        $scope.validations = chargeFactory.validations;
        $scope.messages = chargeFactory.messages;
        $scope.formSubmit = false;

        $scope.addCharge = function(charge, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/charges', $scope.charge)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Charge added successfully");
                                $state.go("charges.browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
    }]);
