angular.module('deitz').controller('nyctaSetupController', [
    '$scope', '$rootScope', '$http', 'apiUrl', 'Notification', '$timeout', '$stateParams', '$state', 'configFactory', 'commonFactory','codeFactory', '$window',
    function (
        $scope, $rootScope, $http, apiUrl, Notification, $timeout, $stateParams, $state, configFactory, commonFactory,codeFactory, $window
    ) {

        $scope.formData = {
        	statement_date : moment(),
        };

        $rootScope.showLoader(true);
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        commonFactory.get('/api/get-NYCTA-setup-data').success(function (resp) { 
        	$rootScope.showLoader(false);  
            $scope.formData = resp.result;
        },function(err){
        	$rootScope.showLoader(false);  
        	Notification.error(err);
        }); 

        $scope.save = function(valid){
        	if(valid){
        	 	$rootScope.showLoader(true);  
        	 	$scope.formData.statement_date = moment($scope.formData.statement_date).format('MM/DD/YYYY');
        		commonFactory.post('/api/save-NYCTA-setup-data', $scope.formData).success(function (resp) { 
                	if(resp.status==200){
                		$rootScope.showLoader(false);  
                        Notification.success('Data Updated Successfully'); 
                	}
                },function(err){
                	$rootScope.showLoader(false);  
                	Notification.error(err);
                });
        	
        	}else{
                swal ( "Oops" ,  "Please fill out all required fields." ,  "warning" );
        	}
        }

    }
]);
