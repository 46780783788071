angular.module('deitz')
    .controller('addInternalDocumentsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'JOB_NO',
        'depoUserId',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  JOB_NO,
                  depoUserId,
                  $uibModalInstance,
                  configFactory) {
            $scope.internalDocument = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'MANAGE INTERNAL DOCUMENTS';
            $scope.isPdfUploaded = false;
            $scope.internalDocument.JOB_NO = JOB_NO;
            $scope.internalDocument.depoUserId = depoUserId;
          
            $scope.clickUpload = function(){
                $("#fileInternalDoc").trigger('click');
            }

            $scope.fileChange = function(){
                var files = $('#fileInternalDoc')[0].files[0];
                if(files && files.name){
                    $(".upload_h1").html(files.name+" file selected. <br> Provide a below information and press the 'save' button.");
                }else{
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            $scope.addInternalDocument = function(internalDocument, isValid) {
                $scope.formSubmit = true;  
                if (isValid) {
                    $rootScope.showLoader(true);

                    Upload.upload({
                        url: apiUrl+'/api/internal-documents',
                        data: $scope.internalDocument
                    }).then(function (response) { 
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.data.status == 200) {
                                Notification.success(response.data.result.message);
                                $scope.closePopupWindowSimple();
                            }else if(response.data.status == 400){
                                Notification.error(response.data.result.message);
                            }
                        }
                    },function(err){
                        $rootScope.showLoader(false);
                    });
                    
                }else{
                    if($scope.internalDocumentCreateForm.file.$invalid){
                        Notification.error('Please upload accepted file only.');
                    }else{
                        Notification.error('File and Note - must be required.');
                    }
                }
            }
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.dismiss({ isChanged : true});
            };
        }]);