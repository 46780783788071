angular.module('deitz')
    .controller('rateFirmsController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'Notification', '$stateParams', '$uibModal', 'rateFactory', '$timeout', 'commonFactory', 'DTOptionsBuilder',
        'DTColumnBuilder', '$compile',
        function ($scope, $rootScope, $http, apiUrl, $state, Notification, $stateParams, $uibModal, rateFactory, $timeout, commonFactory,
            DTOptionsBuilder,
            DTColumnBuilder, $compile) {

            $scope.rateData = {};
            //$scope.locations = [];
            // $scope.rates = [];
            $scope.formSubmit = false;
            $scope.bread_title = "Edit";

            $scope.validations = rateFactory.validations;
            $scope.messages = rateFactory.messages;

            $scope.selectedFirmsIds = [];
            $scope.firmSearching = false;
            $scope.searchFilter = {};
            $scope.filterObj = {};
            $scope.selectedBusinessUnitIDs = [];
            $scope.inv_age = "";
            $scope.inv_age_filter = "";
            $scope.filterByGoldenIds = "";

            $scope.compileDatatable = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };
            $scope.currencyFormat = function (data, type, meta, meta) {
                if (parseFloat(data) >= 0) {
                    return '$' + parseFloat(data).toFixed(2);
                }
                if (parseFloat(data) < 0) {
                    return '-$' + parseFloat(Math.abs(data)).toFixed(2);
                }
            };

            if ($state.params && $state.params.id) {
                $scope.dtOptions = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {

                        data.selectedFirmsIds = $scope.selectedFirmsIds.map(function (a) {
                            return a.id;
                        });

                        data.selectedSalesPersonsIds = $scope.selectedSalesPersonsIds;

                        data.selectedTermsIds = $scope.selectedTermsIds;

                        data.excludeZeroBalDue = $scope.excludeZeroBalDue ? $scope.excludeZeroBalDue : false;

                        data.inv_age = $scope.inv_age ? $scope.inv_age : '';

                        data.inv_age_filter = $scope.inv_age_filter ? $scope.inv_age_filter : '';

                        data.selectedSourcesId = $scope.selectedSourcesId;

                        data.selectedStatus = $scope.selectedStatus;

                        data.selectedClientLevel = $scope.selectedClientLevel;

                        data.selectedGroups = $scope.selectedGroups;

                        data.selectedCollectors = $scope.selectedCollectors;

                        data.excludeSalesPerson = $scope.excludeSalesPerson ? $scope.excludeSalesPerson : false;

                        data.excludeCollecter = $scope.excludeCollecter ? $scope.excludeCollecter : false;

                        data.filterByGoldenIds = $scope.filterByGoldenIds;

                        data.filterByCreatedDate = $scope.filterByCreatedDate ? $scope.filterByCreatedDate : false;

                        data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                        localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));

                        angular.forEach(data.columns, function (value, key) {
                            data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                        });

                        if ((data.selectedFirmsIds && data.selectedFirmsIds.length > 0) || (data.selectedSalesPersonsIds && data.selectedSalesPersonsIds.length > 0) || (data.selectedTermsIds && data.selectedTermsIds.length > 0) || data.filterByCreatedDate || data.filterByGoldenIds || (data.selectedSourcesId && data.selectedSourcesId.length > 0) || (data.selectedStatus && data.selectedStatus.length > 0) || (data.selectedClientLevel && data.selectedClientLevel.length > 0) || (data.selectedGroups && data.selectedGroups.length) > 0 || (data.selectedCollectors && data.selectedCollectors.length > 0)) {
                            $scope.isFilterApplied = true;
                        }
                        data.rateId = $state.params.id;
                        data.rateScreen = 'EntityRate';
                        commonFactory.showDataTable('/api/rates/show-frim-data-table', data)
                            .success(function (res) {
                                if (res) {
                                    $rootScope.showLoader(false);
                                    $scope.filterObj = data;
                                    callback(res);
                                    $('#ratefirm').find('thead input').attr('autocomplete', 'off');
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    })
                    .withLanguage({
                        "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                        "sProcessing": "<img src='img/loading_bar.gif'/>",
                        "sSearch": "",
                        "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

                    })
                    .withOption('processing', true)
                    .withOption('serverSide', true)
                    .withOption('stateSave', false)
                    .withOption('lengthMenu', [[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500)
                    .withOption('order', [0, 'asc'])
                    .withOption('createdRow', $scope.compileDatatable)
                    .withLightColumnFilter({
                        '0': {
                            type: 'text',
                            time: 600
                        },
                        '1': {
                            type: 'text',
                            time: 600
                        },
                        '2': {
                            type: 'text',
                            time: 600
                        },
                        '3': {
                            type: 'text',
                            time: 600
                        },
                        '12': {
                            type: 'text',
                            time: 600
                        }

                    });

                $scope.dtColumns = [
                    DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('Name').withOption("width", "15%").renderWith(function (data, type, full, meta) {
                        return '<a target="_blank" ui-sref="firms.manage.step_one({id : ' + full.id + '})">'+data+'</a>';
                    }),
                    DTColumnBuilder.newColumn('ADDR_LINE1').withOption('defaultContent', '').withTitle('Address').withOption("width", "10%").withOption('name', 'ATTORNEY_tbl.ADDR_LINE1'),
                    DTColumnBuilder.newColumn('reportingSalesman').withOption('defaultContent', '').withTitle('Account Executive <span class="small">(s)</span>')
                        .renderWith(function (data, type, full, meta) {
                            if (full.reporting_salesman.length) {
                                var name = [];
                                angular.forEach(full.reporting_salesman, function (value, key) {
                                    if (value.reptype && value.reptype.name) {
                                        name[key] = '<u>' + escapeString(value.reptype.name) + '</u>';
                                    }
                                });
                                return name.join('<br>');
                            } else {
                                return '<a href="update collecter" >';
                            }
                        }).withOption("width", "10%").withOption('name', 'reportingSalesman.reptype.FIRST_NAME'),
                    DTColumnBuilder.newColumn('collecter').withOption('defaultContent', '').withTitle('Collector Person <span class="small">(s)</span>')
                        .renderWith(function (data, type, full, meta) {
                            var id = full.id
                            if (full.collecter.length) {
                                var name = [];
                                angular.forEach(full.collecter, function (value, key) {
                                    if (value.id == full.collector_id) {
                                        name[key] = '<u>' + escapeString(value.name) + '</u>';
                                    }
                                });
                                return name.join('<br>');
                            } else {
                                return '-';
                            }
                        }).withOption("width", "10%").withOption('name', 'collecter.name'),
                    DTColumnBuilder.newColumn('YTD_PAID').withOption('defaultContent', '').withTitle('YTD Paid').renderWith($scope.currencyFormat),
                    DTColumnBuilder.newColumn('YTD_BILLED').withOption('defaultContent', '').withTitle('YTD Billed').renderWith($scope.currencyFormat),
                    DTColumnBuilder.newColumn('PYR_BILLED').withOption('defaultContent', '').withTitle('PYR Billed').renderWith($scope.currencyFormat),
                    DTColumnBuilder.newColumn('PYR2_BILLD').withOption('defaultContent', '').withTitle('PYR2 Billed').renderWith($scope.currencyFormat),
                    DTColumnBuilder.newColumn('BAL_DUE').withOption('defaultContent', '').withTitle('Bal Due').renderWith($scope.currencyFormat),
                    DTColumnBuilder.newColumn('PHONE').withOption('defaultContent', '').withTitle('Tel.No').notSortable().withOption("width", "9%"),
                    DTColumnBuilder.newColumn('CITY').withOption('defaultContent', '').withTitle('City').withOption("width", "4%"),
                    DTColumnBuilder.newColumn('STATE').withOption('defaultContent', '').withTitle('State').withOption("width", "4%"),
                    DTColumnBuilder.newColumn('businessunit.name').withOption('defaultContent', '').withTitle('Business Unit').withOption("width", "3%").withOption('name', 'businessunit.name'),
                ];
            }

            $(window).bind('keydown', function (event) {
                if ($rootScope.havePermission('rates', 'update')) {
                    if (event.ctrlKey || event.metaKey) {
                        switch (String.fromCharCode(event.which).toLowerCase()) {

                            case 's':

                                event.preventDefault();

                                $scope.rateCreateForm.$setSubmitted();
                                $scope.$apply();

                                $scope.rateCreateSubmit($scope.rateData, $scope.rateCreateForm.$valid);

                                break;
                        }
                    }
                }
            });

        }]);
