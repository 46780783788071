
angular.module('deitz').controller('editwitnessController', [
    '$scope', '$rootScope', 'diaryFactory', 'commonFactory', '$uibModal', 'witness_value', 'index', 'witness_data', '$uibModalInstance', '$http', 'apiUrl',
    function(
        $scope, $rootScope, diaryFactory, commonFactory, $uibModal, witness_value, index, witness_data, $uibModalInstance, $http, apiUrl
    ){

        $scope.Witnesstitle = "Edit Witness Detail";
        $scope.formSubmit = false;
        $scope.witness = { Title : -1,Suffix:null };
        $scope.witness_name = [];
        $scope.witness_title = [{ id: -1, label: ''}, { id: 0, label: 'Dr' }, { id: 1, label: 'Mr' }, { id: 2, label: 'Mrs' }, { id: 3, label: 'Ms' }];
        $scope.witness_suffix = [];
        $scope.diarywitnesstype = [];

        $scope.validations = diaryFactory.validations;
        $scope.messages = diaryFactory.messages;

        if (witness_value && witness_value.data && witness_value.data.result) {
            setTimeout(function() {
                $scope.witness = witness_value.data.result.witness;
                $scope.witness.Title = witness_value.data.result.witness.Title ? parseInt(witness_value.data.result.witness.Title) : -1;
                if(witness_value.data.result.witness.Title == 0){
                    $scope.witness.Title = 0
                    
                }
                $scope.witness.WitnessType = parseInt(witness_value.data.result.witness.WitnessType);
                $scope.witness.Suffix = witness_value.data.result.witness.Suffix ? parseInt(witness_value.data.result.witness.Suffix): null;
                $scope.witness.WitnessResponse = parseInt(witness_value.data.result.witness.WitnessResponse);
                // console.log(  $scope.witness.Title,'title',witness_value);
            }, 10);
        }

        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting all witnessname.
        commonFactory.get('/api/getWitnessname')
            .success(function(response) {
                if (response.result && response.result.witness_name) {
                    $scope.witness_name = response.result.witness_name || '';
                }
            })
            .error(function(err) {});

        //Getting all diary witnesstypes.
        commonFactory.get('/api/witnesstypes')
            .success(function(response) {
                if (response.result && response.result.witnesstype) {
                    $scope.diarywitnesstype = response.result.witnesstype || '';
                }
            })
            .error(function(err) {});
            
        //Getting all Suffixs.
        commonFactory.get('/api/getsuffixs')
            .success(function(response) {
                if (response.result && response.result.suffixs) {
                    $scope.witness_suffix = response.result.suffixs || '';
                }
            })
            .error(function(err) {});

        $scope.addWitness = function(witness, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/updatewitnesstodiary', $scope.witness)
                    .success(function(response) {
                        if (response.status == 200 && response.result && response.result.witnessUpdate) {
                            witness_data[index] = response.result.witnessUpdate;
                            $rootScope.showLoader(false);
                            $uibModalInstance.close();
                            // swal('Success!', 'Witness updated successfully.', 'success');
                        }
                    })
                    .error(function(err) {});
            }
        }

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
