 angular.module('deitz')
    .controller('doneCallsController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  commonFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $filter,
                  $timeout,
                  $q,
                  $stateParams) {

             
            // get current month start and end date
            $scope.completedCallsData = { selectedValue : 'attorney'};
            $scope.completedCallsData.start_date = moment().startOf('month');
            $scope.completedCallsData.end_date = moment().endOf('month');

            $scope.searchData = function(){
                $scope.attDtInstance.DataTable.state.clear();
                if($scope.completedCallsData.selectedValue == 'claimrep'){

                    $scope.reloadClaimRepData();
                }else{

                    $scope.reloadAttorneyData();
                }
            }
            $scope.resetType = function(type){ 
                $scope.completedCallsData.selectedValue = type;
                if(type == 'claimrep'){  
                    $scope.reloadClaimRepData();
                }else{ 
                    $scope.reloadAttorneyData();  
                } 
            }
                // ====================================================================================
                        
                                        // ATTORNEY COMPLETED CALLS DATATABLE START

                // =====================================================================================


            $scope.attDtInstance = {};
            $scope.reloadAttorneyData = function () {
                $scope.attDtInstance.rerender();
            }; 

            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.attDtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    data.start_date = moment($scope.completedCallsData.start_date).format('MM/DD/YYYY');
                    data.end_date = moment($scope.completedCallsData.end_date).format('MM/DD/YYYY');
                    commonFactory.showDataTable('/api/AccountsReceivableAttorneyNotes/get-completed-calls', data).success(function (res) {
                        if (res.error) {
                            $scope.reloadAttorneyData();
                        }
                        else { 
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadAttorneyData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({ 
                    "sProcessing": "<img src='img/loading_bar.gif'/>"
                })
                .withOption('serverSide', true) 
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500) 
                .withOption('order', [4, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '3': {
                        type: 'text',
                    }

                });

            $scope.attDtColumns = [];

            $scope.attDtColumns.push(

                DTColumnBuilder.newColumn('att_firm.NAME').withOption('width', '20%').withOption('defaultContent', '-').withTitle('Firm Name').withOption('name','attFirm.NAME').renderWith(function(data,type,full,meta){

                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="accountReceivableNotes.browse({attId : ' + full.att_firm.id + ' })">'+data+'</a>';

                }),

                DTColumnBuilder.newColumn('ARAFNote').withOption('defaultContent', '-').withOption('width', '60%').withTitle('Notes').renderWith(function(data,type,full,meta){
                    var row = '';
                    if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                    return "<div style='word-break: break-all'>"+ row + "" + full.ARAFNote+"</div>"; 
                
                }),
                
                DTColumnBuilder.newColumn('ARAFReminderDate').withOption('defaultContent', '-').withOption('width', '10%').withTitle('Reminder Date').renderWith(function(data,type,full,meta){
                
                    return moment(full.ARAFReminderDate).format('MM/DD/YYYY'); 
                
                }).withOption('searchable', false),
                
                DTColumnBuilder.newColumn('created_by.name').withOption('defaultContent', '-').withTitle('Created By').withOption('width', '10%').withOption('name','CreatedBy.name'),
                DTColumnBuilder.newColumn('ARAFNoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return moment(data).format('MM/DD/YYYY'); 
                    } else {
                        return '-'
                    }

                })
            );

                //====================================================================================//
                        
                                        // ATTORNEY COMPLETED CALLS DATATABLE END//

                //====================================================================================//





                //====================================================================================//
                        
                                        // CLAIM REPRESENTATIVE COMPLETED CALLS DATATABLE START//

                //====================================================================================//



        $scope.clmDtInstance = {};
            $scope.reloadClaimRepData = function () {
                $scope.clmDtInstance.rerender();
            };
 
            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.clmDtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    data.start_date = moment($scope.completedCallsData.start_date).format('MM/DD/YYYY');
                    data.end_date = moment($scope.completedCallsData.end_date).format('MM/DD/YYYY');
                    commonFactory.showDataTable('/api/AccountsReceivableClaimRepNotes/get-completed-calls', data).success(function (res) {
                        if (res.error) {
                            $scope.reloadClaimRepData();
                        }
                        else { 
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadClaimRepData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({ 
                    "sProcessing": "<img src='img/loading_bar.gif'/>"
                })
                .withOption('serverSide', true) 
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500) 
                .withOption('order', [4, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '3': {
                        type: 'text',
                    }

                });

            $scope.clmDtColumns = [];

            $scope.clmDtColumns.push(

                DTColumnBuilder.newColumn('clm_rep.ClaimRepFirstName').withOption('width', '20%').withOption('defaultContent', '-').withTitle('Claim Representative').renderWith(function(data,type,full,meta){
                     
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="accountReceivableNotes.browse({clmId : ' + full.clm_rep.ClaimRepID + ' })">'+full.clm_rep.claimRepName+'</a>';

                }).withOption('name','clmRep.ClaimRepFirstName'),

                DTColumnBuilder.newColumn('ARCRNote').withOption('defaultContent', '-').withOption('width', '60%').withTitle('Notes').renderWith(function(data,type,full,meta){
                    var row = '';
                    if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                    return "<div style='word-break: break-all'>"+ row + ''+ full.ARCRNote+"</div>"; 
                
                }),
                
                DTColumnBuilder.newColumn('ARCRReminderDate').withOption('defaultContent', '-').withOption('width', '10%').withTitle('Reminder Date').renderWith(function(data,type,full,meta){
                    
                    return moment(data).format('MM/DD/YYYY'); 
                
                }).withOption('searchable', false),
                
                DTColumnBuilder.newColumn('created_by.name').withOption('defaultContent', '-').withTitle('Created By').withOption('width', '10%').withOption('name','CreatedBy.name'),
                DTColumnBuilder.newColumn('ARCRNoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return moment(data).format('MM/DD/YYYY'); 
                    } else {
                        return '-'
                    }

                })
            );



                //====================================================================================//
                        
                                        // CLAIM REPRESENTATIVE COMPLETED CALLS DATATABLE END //

                //====================================================================================//



}]);