angular.module('deitz')
	   .controller('messageController',[
	   	'$scope',
		'$rootScope',
        'Notification',
		'DTOptionsBuilder',
		'DTColumnBuilder',
		'$compile', 
		'commonFactory',
		'$interval',
        'apiUrl',
        '$stateParams',
        '$uibModal',
        'pusher_instance_id',
        'pusher_secret',
        'TextmessagesFactory',
        '$localStorage',
        '$timeout',
		function(
			$scope,
			$rootScope,
            Notification,
			DTOptionsBuilder,
			DTColumnBuilder,
			$compile, 
			commonFactory,
			$interval,
            apiUrl,
            $stateParams,
            $uibModal,
            pusher_instance_id,
            pusher_secret,
            TextmessagesFactory,
            $localStorage,
            $timeout
			){

		$scope.dtInstance = {};
		$scope.sync = true;
        $rootScope.showLoader(true);
        $scope.formData = {};
        $scope.MessagesData = {};
        $scope.replyformData = {};
        $scope.reporterformData = {};
        $scope.reporters = [];
       
       

        
            $scope.getallmessage = function() {
                console.log('get messages');
                commonFactory.get('/api/get-twillio-messages')
                .success(function(response) {
                    if (response) {
                        if (response.status == 200) {
                            $scope.formData = response.result.data;
                        }
                        $rootScope.showLoader(false);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }
            $scope.textmessagecount = function() {
            TextmessagesFactory.textmessagecount().success(function(response){
    
                $localStorage.textMessageUnreadArray = response.data;                       
                $rootScope.showingTextMessageCounter = true;
                $timeout(function () {
                    $rootScope.showingTextMessageCounter = false;
                }, 500);
    
            }).error(function(error){
                Notification.error(error.message);
            });
        }


            $scope.getallmessage = function() {
                console.log('get messages');
                commonFactory.get('/api/get-twillio-messages')
                .success(function(response) {
                    if (response) {
                        if (response.status == 200) {
                            $scope.formData = response.result.data;
                        }
                        $rootScope.showLoader(false);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }


            $scope.getallmessage();
            $scope.formSubmit = false;
            $scope.submitTwillioForm = function(isValid) {
               
                $scope.formSubmit = true;
                if (isValid) {
                    $rootScope.showLoader(true);

                    id = $scope.replyformData.id;  
                    firstname = $scope.replyformData.firstname;  
                    lastname = $scope.replyformData.lastname;  
                    uid = $scope.replyformData.uid;  

                    resource_id = $scope.replyformData.resource_id;  
                    user_id = $scope.replyformData.user_id;  
                    to = $scope.replyformData.msgto;  
                    from = $scope.replyformData.msgfrom;


                    var data_send= {                    
                    resource_id : resource_id,
                    user_id : user_id,
                    to : to,
                    from : from,
                    body : $scope.replyformData.msgbody,
                    uname:$rootScope.auth_user.name
                };                
                
                commonFactory.post( '/api/reply-twillio-messages' , data_send )
                .success(function(response) {
                    if (response) {
                        if (response.status == 200) {
                            Notification.success("Reply addded successfully");
                            //$scope.cancel(true);
                            $scope.replyformData.msgbody = '';
                            $scope.getdetails(id,from,to,resource_id,user_id,firstname,lastname,uid);


                            $rootScope.showLoader(false);
                        } else{
                            $rootScope.showLoader(false);
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                }); 
            }
        };       
		  
        $scope.getfirstletter = function(text) { 
           return text.charAt(0);
        };

        $scope.cancel = function() {
            $scope.dtInstance.close();
        }
       
            const beamsClient = new PusherPushNotifications.Client({
                instanceId: pusher_instance_id,
              });    
              
              //code to fetch pusher token
                     var beamsTokenProvider = new PusherPushNotifications.TokenProvider({
                                url: apiUrl+"/api/generate-pusher-token",
                            });                                                   
                         // var currentUserId = 'USER_'+$rootScope.auth_user.id+Date.now();                          
                         var currentUserId = 'USER_'+$rootScope.auth_user.id;                          
                            beamsClient
                              .getUserId()
                              .then((userId) => {
                                console.log("PUSHER 1", userId)
                                if (userId){
                                    $('.disallowbtn').show();
                                }
                                // Check if the Beams user matches the user that is currently logged in
                                if (userId && userId !== currentUserId) {
                                  // Unregister for notifications
                                  beamsClient.stop()
                                  .then(() => {  
                                    console.log("PUSHER 2", userId)                                                        
                                    $scope.doallow1(beamsClient,beamsTokenProvider);
                                  });
                                } else if(!userId){
                                    beamsClient
                                      .getRegistrationState()
                                      .then((state) => {
                                        console.log("state", state)
                                        if(state != "PERMISSION_DENIED"){                               
                                        
                                          $scope.doallow1(beamsClient,beamsTokenProvider);

                                        }
                                      })
                                      .catch((e) => console.error("Could not get registration state", e));
                                }
                              })
                              .catch(console.error);
                              
        
        $scope.doallow1 = function(beamsClient,beamsTokenProvider) {
            $('.allowbtn').show();
            $('.disallowbtn').hide();           
        };

        $scope.doallow = function() {                 
                beamsClient
                .start()
                .then(() => beamsClient.setUserId(currentUserId, beamsTokenProvider))
                .catch(console.error);
                $('.allowbtn').hide();
                $('.disallowbtn').show();
        };
        
        $scope.disallow = function() {
            beamsClient.stop()
            .then(() => {  
              console.log("pusher stop")                                                                      
            });
            console.log('turn off notification');
            currentid = $rootScope.auth_user.id;

           /* commonFactory.post('/api/turnoff-notification',{userid:currentid})
            .success(function(response) {
                if (response) {
                    if (response.status == 200) {
                        Notification.success("Notification stop successfully");
                    }
                    $rootScope.showLoader(false);
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });*/
            $('.allowbtn').show();
            $('.disallowbtn').hide();
        }

        $scope.reloadData = function() { 
            $scope.dtInstance.rerender();
        }; 

        $scope.resetSearch = function(){ 
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };
        $scope.from = ''; 
        $scope.to   = '';   
        
        $scope.getdetails = function(id,from,to,resource_id,user_id,firstname,lastname,uid,ufname,ulname,pfirstname,plastname){    
            $scope.from = from;  
            $scope.to = to;   
            $scope.replyformData.msgfrom = from;              
            $scope.replyformData.msgto = to;
            $scope.replyformData.resource_id = resource_id;
            $scope.replyformData.user_id = user_id; 

            $scope.replyformData.id = id;  
            $scope.replyformData.firstname = firstname;  
            $scope.replyformData.lastname = lastname;  
            $scope.replyformData.uid = uid;  
            $scope.name = '';
            if(pfirstname != null){
                $scope.name = plastname+' '+pfirstname; 
            }else if(firstname != null && pfirstname == null){
                $scope.name = lastname+ ' '+firstname; 
            }else if(firstname == null && pfirstname != null && ufname != null){
                $scope.name = ulname+ ' '+ufname; 
            }           
            
            checkby_user = $rootScope.auth_user.id;    
                 
            commonFactory.get('/api/get-twillio-messages-id/' + id +'/'+resource_id +'/'+checkby_user +'/'+uid )
            .success(function(response) {
                if (response) {
                    if (response.status == 200) {
                        $scope.MessagesData = response.result.data;
                        $scope.getallmessage();
                        $scope.textmessagecount();
                        
                    }
                    $rootScope.showLoader(false);
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });         
        };

        if($stateParams.id){
            resource_id = $stateParams.id; 
            checkby_user = $rootScope.auth_user.id;
            commonFactory.post('/api/save-twillio-messages',{selectedResourceId:resource_id})
                    .success(function(response) {                        
                        id = response.result.mid; 
                        uid = response.result.user_id;
                        firstname = response.result.FIRST_NAME;
                        lastname = response.result.LAST_NAME;
                        to = response.result.to;
                        from = response.result.from;                       
                        
                        console.log('pppp'+response.result.mid+'uuuu'+response.result.user_id);

                        $scope.getdetails(id,from,to,resource_id,uid,firstname,lastname,uid);

                        /*commonFactory.get('/api/get-twillio-messages-id/' + id +'/'+resource_id +'/'+checkby_user +'/'+uid )
                        .success(function(response) {
                            if (response) {
                                if (response.status == 200) {
                                    $scope.MessagesData = response.result.data;
                                    $scope.getallmessage();
                                    $scope.textmessagecount();
                                    
                                }
                                $rootScope.showLoader(false);
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        }); */
                    });           
                    //console.log('pppp1'+$scope.id+'uuuu'+$scope.uid);
                         
               
        }
        $scope.addnewchat = function () {
            //$rootScope.showLoader(true);
            
            $uibModal.open({
                templateUrl: "modules/message/reporter.html",
                controller: function($scope,$uibModalInstance,commonFactory,parentscope){
                    $scope.closePopupWindow = function () {                        
                        $uibModalInstance.close();                     
                    }
                    $scope.submitreporter = function () {                       
                       $scope.reporterformData.reporter_reptyp_id = $scope.reporterformData.reporter_reptyp_id ? $scope.reporterformData.reporter_reptyp_id : null;
                       console.log( $scope.reporterformData.reporter_reptyp_id);

                       //Getting  resources by id.
                    commonFactory.post('/api/save-twillio-messages',{selectedResourceId:$scope.reporterformData.reporter_reptyp_id})
                    .success(function(response) {
                        if(response.error){
                            Notification.error(response.result.message || 'Something went wrong!');
                        } else{
                            $scope.salesPersonsList = response.result.resources;
                            $uibModalInstance.close(); 
                            parentscope.getallmessage(); 
                        }
                    });                   
                    }


            
            //Refreshing all reporters        
            $scope.fetchingReporter = true;

            commonFactory.post('/api/resource-list-by-type',{rep_typ:'R'})
                .success(function (response) {
                    if (response && response.result && response.result.resources) {
                        $scope.reporters = response.result.resources || '';
                    }
                    $scope.fetchingReporter = false;
                })
                .error(function (err) {
                    $scope.fetchingReporter = false;
                }); 

                },
                resolve:{
                    parentscope :function (){
                     return $scope;
                    }
                 },
            });
        };
        
		}]);

        angular.module('myApp', ['doowb.angular-pusher']).

config(['PusherServiceProvider',
  function(PusherServiceProvider) {
    PusherServiceProvider
    .setToken(pusher_secret)
    .setOptions({});
  }
]);