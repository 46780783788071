angular.module('deitz').controller('typistController', [
    '$scope','$rootScope','$state','representativeFactory', 'commonFactory', 'Notification','$filter','$sce','$http','apiUrl','SweetAlert','$uibModal',
    function(
        $scope,$rootScope,$state,representativeFactory, commonFactory, Notification,$filter,$sce,$http,apiUrl,SweetAlert,$uibModal
    ){

        //Model object that contains model data    
        $scope.validations = representativeFactory.validations;
        $scope.messages = representativeFactory.messages; 
        $scope.typistData = {};
        $scope.typistForm = false;
        $scope.reptypehistory = [];
        $scope.showJobsMenuActive = false;
        $scope.showPrivateTab = false;
        $scope.privateTabAuthenticated = false;
        $scope.typistData.start_date = moment().format('MM/DD/YYYY');
        $scope.alternateEmailValid = true;

        if (localStorage.getItem('representative_email')) {
            $scope.typistData.Emai_Address = localStorage.getItem('representative_email')
            localStorage.removeItem("representative_email");
        }
        if (localStorage.getItem('representative_name')) {
            $scope.typistData.first_name = localStorage.getItem('representative_name')
            localStorage.removeItem("representative_name");
        }
        if (localStorage.getItem('representative_last_name')) {
            $scope.typistData.last_name = localStorage.getItem('representative_last_name')
            localStorage.removeItem("representative_last_name");
        }
        if (localStorage.getItem('representative_phone')) {
            $scope.typistData.phone = localStorage.getItem('representative_phone')
            localStorage.removeItem("representative_phone");
        }

        $scope.validateEmails = function(data) {
            var emails = data.target.value.split(/,|;/);
            if(emails == ""){
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
                return
            }
            
            var isValid = true;
            for (var i = 0; isValid && i < emails.length; i++) {
              if (!validateEmail(emails[i])) {
                isValid = false;
                $scope.alternateEmailValid = false;
                $scope.alternate_emails_error = 'Email ' + emails[i] + ' does not appear to be a proper email';
                console.log($scope.alternate_emails_error,"E");
              }
              else{
                $scope.alternateEmailValid = true;
                $scope.alternate_emails_error = '';
              }
            }
          
          }
          function validateEmail(email) {
            var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
            return EMAIL_REGEXP.test(email.trim());
          }

        $scope.tooltip = $sce.trustAsHtml('In Order To Print A Physical Check Print Check Needs To be Set To - YES' + '<br>' + '-------------------' + '<br>' + 'In Order To Create An Electronic Payment Print Check Needs To be Set To - NO');

         //get all BussinesUnit 
         $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });
        if ($state.params && $state.params.id) {
            $scope.title = 'Edit Typist';
        } else {
            $scope.title = 'Add Typist';
            $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
                $scope.rolesWithBusinessUnits = response.data.data;  
                $scope.typistData.business_unit_id = $scope.rolesWithBusinessUnits[0].business_unit_id
            });
        }

        //Previous and Next page button control
        if ($state.params && $state.params.id) {
            $scope.showPrivateTab = true;
            $scope.goto_rate = function() {
                $state.go('representative.typist.step_two', { id: $state.params.id });
            }
            $scope.goto_other = function() {
                $state.go('representative.typist.step_three', { id: $state.params.id });
            }
            $scope.goto_address = function() {
                $state.go('representative.typist.step_one', { id: $state.params.id });
            }
        } else {
            $scope.goto_rate = function() {
                $state.go('representative.typist.step_two');
            }
            $scope.goto_other = function() {
                $state.go('representative.typist.step_three');
            }
            $scope.goto_address = function() {
                $state.go('representative.typist.step_one');
            }

            if($state.current.name !== 'representative.typist.step_one' && ( typeof $scope.typistData.first_name == 'undefined' || $scope.typistData.first_name=='' )){
                $state.go('representative.typist.step_one');
            }

        }

        //Save method for saving (TYPIST) data into database.
        //UPdate method for updating (TYPIST) data into DB if get ID.
        if ($state.params && $state.params.id) {
            $scope.save = function() {
                $scope.signupForm = true;
                //Add Representative type 'T' For Representative type TYPIST into DB
                $scope.typistData.rep_typ = 'T';
                $scope.typistData.notary_expiration_date = moment($scope.typistData.notary_expiration_date).format('YYYY-MM-DD');
                $scope.typistData.curr_paydt = moment($scope.typistData.curr_paydt).format('YYYY-MM-DD');

                var id = $state.params ? $state.params.id : '';
                $rootScope.showLoader(true);
                commonFactory.put('/api/reptype/' + id, $scope.typistData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptypeUpdate) {
                            $rootScope.showLoader(false);
                            $scope.privateTabAuthenticated = false;
                            Notification.success("Typist updated successfully.");
                            $state.go("representative.browse");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        }
        //Add method for adding (TYPIST) data into DB if not Getting ID.
        else {
            $scope.save = function() {
                $scope.salesForm = true;
                //Add Representative type 'T' For Representative type TYPIST into DB
                $scope.typistData.rep_typ = 'T';
                $scope.typistData.notary_expiration_date = moment($scope.typistData.notary_expiration_date).format('YYYY-MM-DD');
                $scope.typistData.curr_paydt = moment($scope.typistData.curr_paydt).format('YYYY-MM-DD');

                $rootScope.showLoader(true);
                commonFactory.post('/api/reptype', $scope.typistData)
                    .success(function(response) {
                        if (response && response.result && response.result.reptype) {
                            $rootScope.showLoader(false);
                            Notification.success("Typist added successfully.");
                            $state.go("representative.browse");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
        }

        //Get Method for Getting (TYPIST) data into EDIT MODE.
        var id = $state.params ? $state.params.id : '';
        if (id) // Make sure we are in edit mode.
        {
            commonFactory.get('/api/reptype/' + id + '/edit')
                .success(function(response) {
                    if (response && response.result && response.result.reptypeInfo) { 
                        $scope.typistData.title = response.result.reptypeInfo.TITLE;
                        $scope.typistData.NAME_KEY = response.result.reptypeInfo.NAME_KEY;
                        $scope.typistData.first_name = response.result.reptypeInfo.FIRST_NAME;
                        $scope.typistData.last_name = response.result.reptypeInfo.LAST_NAME;
                        $scope.setPageTitle();
                        $scope.typistData.person_first_name = response.result.reptypeInfo.person_first_name;
                        $scope.typistData.person_last_name = response.result.reptypeInfo.person_last_name;
                        $scope.typistData.Emai_Address = response.result.reptypeInfo.Emai_Address;
                        $scope.typistData.alternate_emails = response.result.reptypeInfo.alternate_emails;
                        $scope.typistData.phone = response.result.reptypeInfo.PHONE;
                        $scope.typistData.addr_line1 = response.result.reptypeInfo.ADDR_LINE1;
                        $scope.typistData.city = response.result.reptypeInfo.CITY;
                        $scope.typistData.state = response.result.reptypeInfo.STATE;
                        $scope.typistData.zip = response.result.reptypeInfo.ZIP;
                        $scope.typistData.soc_sec_no = response.result.reptypeInfo.SOC_SEC_NO;
                        $scope.typistData.cell = response.result.reptypeInfo.CELL;
                        $scope.typistData.addr_line2 = response.result.reptypeInfo.ADDR_LINE2;

                        $scope.typistData.rate_1 = response.result.reptypeInfo.RATE_1;
                        $scope.typistData.rate_2 = response.result.reptypeInfo.RATE_2;
                        $scope.typistData.medicalrate = response.result.reptypeInfo.MedicalRate;
                        $scope.typistData.copy_rt = response.result.reptypeInfo.COPY_RT;
                        $scope.typistData.appear = response.result.reptypeInfo.APPEAR;
                        $scope.typistData.override_appear_rate_fee = response.result.reptypeInfo.override_appear_rate_fee || 'No';

                        $scope.typistData.pay_stips = response.result.reptypeInfo.PAY_STIPS;
                        $scope.typistData.rate_e = response.result.reptypeInfo.RATE_E;
                        $scope.typistData.rate_d = response.result.reptypeInfo.RATE_D;
                        $scope.typistData.rate_i = response.result.reptypeInfo.RATE_I;
                        $scope.typistData.beeper = response.result.reptypeInfo.BEEPER;
                        $scope.typistData.late_app = response.result.reptypeInfo.LATE_APP;
                        $scope.typistData.payment_type = response.result.reptypeInfo.payment_type;

                        $scope.typistData.ns_internal_vendor_id = response.result.reptypeInfo.ns_internal_vendor_id;
                        $scope.typistData.gl_internal_vendor_id = response.result.reptypeInfo.gl_internal_vendor_id;

                        $scope.typistData.notary_number = response.result.reptypeInfo.NOTARY_NUMBER;
                        $scope.typistData.notary_expiration_date = response.result.reptypeInfo.NOTARY_EXPIRATION_DATE;
                        $scope.typistData.rate_l = response.result.reptypeInfo.RATE_L;
                        $scope.typistData.date_added = response.result.reptypeInfo.DATE_ADDED;
                        $scope.typistData.last_chgd = response.result.reptypeInfo.LAST_CHGD;
                        $scope.typistData.notes_1 = response.result.reptypeInfo.NOTES_1;
                        $scope.typistData.RoutingNumber = response.result.reptypeInfo.RoutingNumber;
                        $scope.typistData.AccountNumber = response.result.reptypeInfo.AccountNumber;
                        $scope.typistData.p1099 = response.result.reptypeInfo.P1099;
                        $scope.typistData.curr_pay = response.result.reptypeInfo.CURR_PAY;
                        $scope.typistData.curr_paydt = response.result.reptypeInfo.CURR_PAYDT;
                        $scope.typistData.prev_pay = response.result.reptypeInfo.PREV_PAY;
                        $scope.typistData.prev_paydt = response.result.reptypeInfo.PREV_PAYDT || '';
                        $scope.typistData.ytd_pay = response.result.reptypeInfo.YTD_PAY;
                        $scope.typistData.ytd_bill = response.result.reptypeInfo.YTD_BILL;
                        $scope.typistData.ytd_pgs = response.result.reptypeInfo.YTD_PGS;
                        $scope.typistData.status = response.result.reptypeInfo.STATUS;
                        $scope.typistData.notes_2 = response.result.reptypeInfo.NOTES_2;
                        $scope.typistData.rate_changed_by = response.result.reptypeInfo.RATE_CHANGED_BY;
                        $scope.typistData.rate_change_date = response.result.reptypeInfo.RATE_CHANGE_DATE;
                        $scope.typistData.rate_change_reason = response.result.reptypeInfo.RATE_CHANGE_REASON;
                        $scope.typistData.business_unit_id = response.result.reptypeInfo.business_unit_id;
                        $scope.typistData.start_date = response.result.reptypeInfo.created_at ? moment(response.result.reptypeInfo.created_at).format('MM/DD/YYYY hh:mm:ss a') : '--/--/---';
                        $scope.payment_date =  response.result.reptypeInfo.businessunit.pay_date
                        $scope.typistData.is_payment_required = response.result.reptypeInfo.is_payment_required.toString();
                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        }

        //Disabled Rate change Reason related fields.
        if ($state.params && $state.params.id) { //EDIT MODE
            $scope.isDisabled = true;
            if($rootScope.havePermission('allow_access_without_password','allow.resource.rate.change')){
                $scope.isDisabled = false;
            }
            $scope.$watch('typistData.rate_change_reason', function(newValue, oldValue) {
                if (newValue == null || newValue == '' || newValue == 'undefined' || newValue == undefined || (newValue && newValue.length == 0)) {
                    //$scope.isDisabled = true;

                } else {
                    $scope.typistData.rate_changed_by = $scope.Authuser.name;
                    $scope.typistData.rate_change_date = $filter('date')(new Date(), 'yyyy-MM-dd');
                    //$scope.isDisabled = false;
                }
            });
        } else {
            $scope.isDisabled = false;
            $scope.$watch('typistData.rate_change_reason', function(newValue, oldValue) { //ADD MODE
                if (newValue != null || newValue != '' || newValue != 'undefined' || newValue != undefined || (newValue && newValue.length != 0)) {
                    $scope.typistData.rate_changed_by = $scope.Authuser.name;
                    $scope.typistData.rate_change_date = $filter('date')(new Date(), 'yyyy-MM-dd');
                }
            });
        }

        //Get Method for Getting (SCOPIST) History data into EDIT MODE.
        var id = $state.params ? $state.params.id : '';
        if (id) // Make sure we are in edit mode.
        {
            commonFactory.get('/api/reptype/' + id)
                .success(function(response) {
                    if (response && response.result && response.result.reptypehistory) {
                        $scope.reptypehistory = response.result.reptypehistory || '';
                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        }

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {

                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        if($state.current.name == "representative.typist.step_one" && !$scope.typistForm.$invalid ){
                            $scope.goto_rate();
                        }
                        if($state.current.name == "representative.typist.step_two" && !$scope.typistForm.$invalid ){
                            $scope.goto_other();
                        }
                        if($state.current.name == "representative.typist.step_three" && !$scope.typistForm.$invalid ){
                            $scope.save();
                        }
                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                        break;

                    case 'p':
                        event.preventDefault();

                        if($state.current.name == "representative.typist.step_two" && !$scope.typistForm.$invalid ){
                            $scope.goto_address();
                        }

                        if($state.current.name == "representative.typist.step_three" && !$scope.typistForm.$invalid ){
                            $scope.goto_rate();
                        }

                        // $scope.invoiceGenerateForm.$setSubmitted();
                        $scope.$apply();
                        // $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                        break;
                }
            }
        });

        $scope.showUnpaidJobOfResource = function () {

            $scope.showJobsMenuActive = true;
            async.series([
                    
                    function(callback){
                        // do some more stuff ...
                        if($state.params && $state.params.id){
                            var jobModal = $uibModal.open({
                                templateUrl: "modules/payroll/view_resource_jobs.html",
                                controller: 'viewResourceUnpaidJobsController',
                                resolve: {
                                    resource_id: function () {
                                        return $state.params.id;
                                    },
                                    resource_type : function(){
                                        return 'T';
                                    },
                                    payment_date : function(){
                                        // return $scope.operation.payment_date;
                                        return moment($scope.payment_date).format('MM/DD/YYYY');
                                    },
                                    selectedBusinessUnitIDs : function(){
                                        return [];
                                    }
                                },
                                windowClass: 'full-width-model',
                                keyboard: false,
                                backdrop: false,
                                size: 'lg',
                                close: function () {

                                }
                            });


                            jobModal.result.then(function (is_date_changed) {
                                $scope.showJobsMenuActive = false; 
                                if (is_date_changed) {
                                    // var data = {invoice_ids : invoices};
                                    // console.log(data);
                                    // need to update the flag for print
                                    // $scope.getTotalPendingAmount();
                                }
                            }, function () {
                                console.log("Modal Dismissed!!!");
                            });
                        }
                        callback(null, 'two');
                    }
                ],
                // optional callback
                function(err, results){
                    // results is now equal to ['one', 'two']
                    console.log(results);
                }); 

        } 

        $scope.unlockTypist = function() {
            SweetAlert.swal({
                title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError("You need to write something!");
                    return false
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/authpayroll', { 'password': inputValue })
                        .success(function(response) {
                            if (response['result']) {
                                if (response['result']['auth']) {
                                    $scope.isDisabled = false;
                                    $rootScope.showLoader(false);
                                } else {
                                    $rootScope.showLoader(false);
                                    SweetAlert.swal("Error !", "Wrong Password!", "error");
                                }
                            } else {
                                $rootScope.showLoader(false);
                                SweetAlert.swal("Oops !", "Something went wrong!", "error");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            SweetAlert.swal("Oops !", "Something went wrong!", "error");
                        });

                }
            });
        };

        $scope.showPaidJobOfResource = function () {
            $rootScope.showLoader(true);
            $rootScope.showPaidJobsMenuActive = true;
            var id = $state.params ? $state.params.id : '';  
                $uibModal.open({
                    templateUrl: "modules/representative/addtypist/typist_paid_jobs.html",
                    controller: function($scope,resource_id, $uibModalInstance,commonFactory){

                        $scope.report = {
                            startDate : moment().startOf('year').format('YYYY-MM-DD'),
                            endDate : moment().endOf('year').format('YYYY-MM-DD')
                        };

                        $scope.viewPaidJob = function() {
                            $rootScope.showLoader(true);

                            var form_data = {};
                                form_data.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
                                form_data.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');
                                commonFactory.post('/api/reptype/get-typist-paid-job/' + resource_id,form_data).then(function (response) {
                                $rootScope.showLoader(false);
                                $scope.typeCheckData = response.data.results;
                                // Add the Charge of Complete additional Charge
                                $scope.totalTypistAmount = $scope.typeCheckData.reduce(function (pv, cv) {
                                    if (cv.TYP_PAYAMT) {
                                        return pv + parseFloat(cv.TYP_PAYAMT);
                                    }
                                    else {
                                        return pv;
                                    }
                                }, 0);


                                $scope.isRec = false;
                                if ($scope.typeCheckData.length > 0) {
                                    $scope.isRec = true;
                                }
                            });
                        }
                        $scope.closePopupWindow = function () {
                            $rootScope.showPaidJobsMenuActive = false;
                            $uibModalInstance.close();                     
                        }

                        // Added to sort the record by any field from the table.
                        $scope.predicate = 'TYP_PAYDT';
                        $scope.reverse = true;
                        $scope.order = function(predicate) {
                            $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
                            $scope.predicate = predicate;
                        };

                        $scope.viewPaidJob();

                        $scope.sendPaystubEmail = function(checkno, email){
                            $uibModal.open({
                                templateUrl: "modules/representative/SendEmail.html",
                                controller: function($scope, $uibModalInstance){
                 
                                    $scope.title = "Send Email";
                                    $scope.emails = '';
                                    $rootScope.showLoader(true);
    
                                    commonFactory.get('/api/reptype/' + id + '/edit')
                                        .success(function(response) {
                                        if (response && response.result && response.result.reptypeInfo) { 
                                            $scope.emails = response.result.reptypeInfo.Emai_Address;
                                        }
                                        $rootScope.showLoader(false);
                                    });
                                    
                                    $scope.cancel = function(isChanged) {
                                        $uibModalInstance.dismiss({ isChanged : isChanged});
                                    };
                 
                                    $scope.sendEmail = function(){
                                        if($scope.emails){
                                            function validateEmail(email) {
                                                var EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                return EMAIL_REGEXP.test(email.trim());
                                            }
                                            var isValid = true
                                            var emails = $scope.emails.split(/,|;/);
                                            for (var i = 0; isValid && i < emails.length; i++) {
                                                if (!validateEmail(emails[i])) {
                                                    isValid = false;
                                                }
                                            }
                                            if(isValid){
                                                $rootScope.showLoader(true);
                                                commonFactory.post('/api/check/auth_get_check_paid_job_print', {'resource_id': id, 'check_no': checkno, 'type': 'typist', 'emails': emails})
                                                    .success(function(response) {
                                                        $rootScope.showLoader(false);
                                                        Notification.success("Email sent successfully");
                                                        $scope.cancel();
                                                    })
                                                    .error(function(err) {
                                                        $rootScope.showLoader(false);
                                                        Notification.error("Oops !, Something went wrong!");
                                                    });
                                            }else{
                                                Notification.error('Please enter valid To Emails.');
                                            }
                                        }else{
                                            Notification.error('To Emails must be required.');
                                        }
                                    }
                                },
                                keyboard: false,
                                backdrop: false,
                                close: function () {
                 
                                }
                            });
                        }

                        $scope.paystub = function(checkno){
                      
                            $uibModal.open({
                                templateUrl: "modules/representative/addreporter/paystub.html",
                                controller: function($scope,resource_id, $uibModalInstance,commonFactory,parentscope){
    
                                    $rootScope.showLoader(true);
                                    commonFactory.post('/api/check/auth_get_check_paid_job_print', {'resource_id': id, 'check_no': checkno, 'type': 'typist'})
                                        .success(function(response) {
                                            $rootScope.showLoader(false);
                                            $scope.paystubs = response.result;
                                            $scope.resource_type = 'T';
                                            $scope.checkno = checkno;
                                            $scope.totalPaystubAmount = $scope.paystubs.reduce(function (pv, cv) {
                                                if(cv.TYP_PAYAMT){
                                                    return pv + parseFloat(cv.TYP_PAYAMT);
                                                }
                                                else{
                                                    return pv;
                                                }
                                            }, 0);
                                            $scope.closePopupWindowSimple = function(){
                                                $uibModalInstance.close(true);
                                            };
                                            $scope.sendPaystubEmail = function(){                
                                                parentscope.sendPaystubEmail(checkno);
                                            };
                                        })
                                        .error(function(err) {
                                            $rootScope.showLoader(false);
                                            SweetAlert.swal("Oops !", "Something went wrong!", "error");
                                        });
    
                                },
                                resolve : {
                                    resource_id : function(){
                                        return id;
                                    },
                                    parentscope :function (){
                                        return $scope;
                                    }  
                                },
                                windowClass: '',
                                keyboard: false,
                                backdrop: false,
                                size: 'lg',
                                close: function () {
                
                                }
                            });
                        }

                        $scope.last_check_number = 0;
                        $scope.isCheckChange = function(index){
                            var checkRec = $scope.typeCheckData[index];
                            if(index ==0){
                                $scope.last_check_number = checkRec.TYP_CHECK;
                                return false;
                            }
                            if($scope.last_check_number != checkRec.TYP_CHECK){
                                $scope.last_check_number = checkRec.TYP_CHECK;
                                return true;
                            }else{
                                return false;
                            }
                        }

                        $scope.printPaidJobs = function(divName){


                            $("#" + divName).show();
                            var printContents = $("#" + divName);
                            printContents.find('#nivoice_button').remove();
                            printContents = printContents.html();
                            var popupWin = window.open('', '_blank');
                            popupWin.document.open();
                            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"><style type="text/css" media="print"> @media print { div {height:100%;overflow:visible;}}</style></head><body onload="window.print()">' + printContents + '</body></html>');
                            popupWin.document.close();
                            $("#" + divName).hide();
                        }

                    },
                    size:'lg',
                    backdrop:false,
                    resolve : {
                        resource_id : function(){
                            return id;
                        }
                    }
                }); 
        }

        /**
         *
         */
        $scope.showCheckOfTypist = function(){
            $rootScope.showLoader(true);
            $rootScope.showCheckTypistMenuActive = true;

            $uibModal.open({
                templateUrl: "modules/representative/addtypist/typist_checks.html",
                controller: function($scope,resource_id, $uibModalInstance,commonFactory){


                    $scope.report = {
                        startDate : moment().startOf('year').format('YYYY-MM-DD'),
                        endDate : moment().endOf('year').format('YYYY-MM-DD')
                    };

                    $scope.searchCheck = function(){
                        $rootScope.showLoader(true);

                        var form_data = {};
                        form_data.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
                        form_data.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');
                        form_data.type = 'T';

                        commonFactory.post('/api/reptype/get-checks/'+resource_id,form_data).then(function (response) {
                            $rootScope.showLoader(false);
                            $scope.typeCheckData = response.data.result;
                            // Add the Charge of Complete additional Charge
                            $scope.totalTypistAmount = $scope.typeCheckData.reduce(function (pv, cv) {
                                if(cv.CHECK_AMT){
                                    return pv + parseFloat(cv.CHECK_AMT);
                                }
                                else{
                                    return pv;
                                }
                            }, 0);

                            $scope.isRec =false;
                            if($scope.typeCheckData.length > 0){
                                $scope.isRec = true;
                            }
                        });
                    }
                    $scope.closePopupWindow = function () {
                        $rootScope.showCheckTypistMenuActive = false;
                        $uibModalInstance.close();
                    }
                    // call for first time
                    $scope.searchCheck();

                    $scope.printChecks = function(divName){


                        $("#" + divName).show();
                        var printContents = $("#" + divName);
                        printContents.find('#nivoice_button').remove();
                        printContents = printContents.html();
                        var popupWin = window.open('', '_blank');
                        popupWin.document.open();
                        popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"><style type="text/css" media="print"> @media print { div {height:100%;overflow:visible;}}</style></head><body onload="window.print()">' + printContents + '</body></html>');
                        popupWin.document.close();
                        $("#" + divName).hide();
                    }

                },
                resolve : {
                    resource_id : function(){
                        return id;
                    }
                }
            });
        }

        $scope.setPageTitle = function () {
            if($scope.typistData?.first_name){
                $state.$current.data.pageTitle = $scope.typistData.first_name + " " + $scope.typistData.last_name;
                $rootScope.$broadcast('changeTitle', $state);
            }
        };
        $scope.stepOneInit = function () {
            $scope.setPageTitle();
        };
        $scope.stepTwoInit = function () {
            $scope.setPageTitle();
        };
        $scope.stepThreeInit = function () {
            $scope.setPageTitle();
        };
       
        $scope.askPrivateTabPass = function(){

            if($rootScope.havePermission('allow_access_without_password','allow.resource.private.info')){
                $scope.setPageTitle();
                $scope.privateTabAuthenticated = true;
                $state.go("representative.typist.step_four", {id : $state.params.id});
                return;

            }else if($scope.privateTabAuthenticated){
                $state.go("representative.typist.step_four", {id : $state.params.id});
                return;
            } else{
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                    // text: "Please enter password to unlock fields.",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                    // inputPlaceholder: "password"
                }, function(inputValue) {
                    if (inputValue === false){
                        $state.go("representative.typist.step_one", {id : $state.params.id});
                        return false;
                    }
                    if (inputValue === "") {
                        swal.showInputError("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {
                                        $state.go("representative.typist.step_four", {id : $state.params.id});
                                        $scope.privateTabAuthenticated = true;
                                        $rootScope.showLoader(false);
                                    } else {
                                        $rootScope.showLoader(false);
                                        $state.go("representative.typist.step_one", {id : $state.params.id});
                                        swal("Error !", "Wrong Password!", "error");
                                    }
                                } else {
                                    $rootScope.showLoader(false);
                                    swal("Oops !", "Something went wrong!", "error");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal("Oops !", "Something went wrong!", "error");
                            });
                    }
                });
            }
        }
 

    }]);
