angular.module('deitz')
            .controller('multiReassignCollectorController',
                ['$scope',
                 '$rootScope',
                 '$state',
                 'commonFactory',
                 '$uibModalInstance',
                 'invoiceFactory',
                 'Notification',
                 'DTOptionsBuilder',
                 'DTColumnBuilder',
                 '$q',
                 'data',
                 '$compile',
                 '$uibModal',
                 '$http',
                 'apiUrl',
                 '$window',
                    function($scope,
                             $rootScope,
                             $state,
                             commonFactory,
                             $uibModalInstance,
                             invoiceFactory,
                             Notification,
                             DTOptionsBuilder,
                             DTColumnBuilder,
                             $q,
                             data,
                             $compile,
                             $uibModal,
                             $http,
                             apiUrl,
                             $window
                                ){

         $scope.attorneyList = data;
         $scope.title = 'Multi Re-Assign Collector';
         $scope.collector_id = null;
         // Getting all Collectors 
        $http.get(apiUrl + '/api/collector').success(function(res){
            $scope.collectorsList = res.result;
        });

        $scope.removeResource = function(key) {
            delete $scope.attorneyList[key];
        };

        $scope.updateResource= function($valid){
            var error=0;
            if($valid && (error==0)){

                swal({
                        title: "Are you sure ?",
                        text: "You are going to Reassign a collectors",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader(true);
                            $scope.data = [];
                            angular.forEach($scope.attorneyList, function(value, key) {
                                $scope.data.push({
                                    id: value.firmId,
                                    collectorId: $scope.collector_id
                                });
                            });
                            $http.post(apiUrl+'/api/multi-update-collector',{data : $scope.data}).success(function(res){                    
                                $rootScope.showLoader(false);
                                if (!res.error) {
                                    $uibModalInstance.dismiss({ isChanged : true, resource : $scope.filterObj});
                                   
                                    Notification.success(res.result.message);
                                } else {
                                    Notification.error(res.result.message);
                                }
                            });
                        }
                    });
            }
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

}]);