angular.module('deitz')
    .controller('cashReceiptController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'cashReceiptFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$state',
        '$timeout',
        '$q',
        'commonFactory',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  cashReceiptFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $filter,
                  $state,
                  $timeout,
                  $q,
                  commonFactory) {

            $scope.dtInstance = {};

            $scope.headerCompiled = false;

            $scope.selectedBusinessUnitIDs=[];
            var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            }else{
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }

            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});
            });

            $scope.reloadData = function () {

                $scope.dtInstance.rerender();
            };

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            if(Object.keys(cashReceiptFactory.findData).length === 0){
                $state.go('cash_receipt.find');
            }

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {

                    data.server_side = true;
                    data.selectedValue = cashReceiptFactory.findData.selectedValue;
                    data.searchValue = cashReceiptFactory.findData.searchValue;
                    data.includePaidInvoices = cashReceiptFactory.findData.includePaidInvoices;               
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); 
                    angular.forEach(data.columns,function(value, key){
                        data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                    });

                    commonFactory.showDataTable('/api/find-cash-reciept', data)
                    .success(function(res) {
                        if (res.error) {
                            $rootScope.showLoader(false);
                        }
                        else {
                            $rootScope.showLoader(false);
                            callback(res);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
                })
                .withLanguage({
                    "sEmptyTable": "NO CASH RECEIPTS AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ CASH RECEIPTS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL CASH RECEIPTS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ INVOICES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING CASH RECEIPT FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('processing', true)
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500)
                .withOption('order', [2, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text'
                    },
                    '1': {
                        type: 'text'
                    },
                    '2': {
                        type: 'text'
                    },
                    '3': {
                        type: 'text'
                    },
                    '4': {
                        type: 'text'
                    },
                    '5': {
                        type: 'text'
                    },
                    '6': {
                        type: 'text'
                    },
                    '7': {
                        type: 'text'
                    },
                    '8': {
                        type: 'text'
                    },
                     '9': {
                        type: 'text'
                    },
                    '10': {
                        type: 'text'
                    }
                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(

                DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amount').withOption('searchable', true).withOption("width", "6%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }
                }),
                DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice Number').withOption('searchable', true).withOption("width", "6%"),
                DTColumnBuilder.newColumn('dte_invoice').withTitle('Invoice Date').withOption('searchable', true).withOption("width", "6%").renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return $filter('formatDate')(data);
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('job.CAPTION').withTitle('Caption').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "22%"),
                DTColumnBuilder.newColumn('attorneyfirms.NAME').withTitle('Billing Firm').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "22%"),
                DTColumnBuilder.newColumn('FILE_NO').withTitle('File Number').withOption('searchable', true).withOption("width", "8%"),
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job Number').withOption('searchable', true).withOption("width", "8%"),
                DTColumnBuilder.newColumn('job.dte_taken').withTitle('Job Date').withOption('searchable', true).withOption("width", "8%").renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return $filter('formatDate')(data);
                    }
                    else {
                        return '-'
                    }
                }),
                DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Date').withOption('searchable', true).withOption("width", "8%").renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return $filter('formatDate')(data);
                    }
                    else {
                        return '-'
                    }

                }),
               
                DTColumnBuilder.newColumn('legacy_invoice_id').withTitle('Legacy No').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%"),
                DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%"),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption("width", "6%").notSortable().renderWith(function (data, type, full, meta) {

                    var data = "<div class='text-center'>"+
                                '<a class="pull-left" href="javascript:void(0);" ui-sref="cash_receipt.singleInvoicePayment({inv_no : ' + full.INV_NO + ' })"> Pay Invoice</a>';
                    data += "</div>";
                    return data;
                })
            );

        }
    ]);