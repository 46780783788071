angular.module('deitz')
    .controller('globalSearchController', ['$scope', '$rootScope','$http', 'apiUrl', 'commonFactory', 'configFactory', 'Notification', '$cookies', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile', '$state', function($scope, $rootScope, $http, apiUrl, commonFactory, configFactory, Notification, $cookies, DTOptionsBuilder, DTColumnBuilder, $q, $compile, $state) {

        $scope.recentSearch = typeof $cookies.get("global-search") != "undefined" ? JSON.parse($cookies.get("global-search")) : []; 
        
        $scope.q = $state.params.q || '';
        $scope.type = $state.params.type;
        $scope.wildcard = $state.params.wildcard || 0;
        $scope.numeric = $state.params.numeric || 0;
    
        $scope.globalSearchForm = {
            q : $scope.q,
            type : $scope.type,
            wildcard : $scope.wildcard,
            numeric : $scope.numeric,
        }
        if($scope.q != ''){
            let newopt = {};
            newopt.q = $scope.q;
            newopt.type = $scope.type;
            newopt.wildcard = $scope.wildcard;
            newopt.numeric = $scope.numeric;

            var i = -1;
            var aa = $scope.recentSearch.forEach(function(item, index) {
                if (item.q == newopt.q && item.type == newopt.type && item.wildcard == newopt.wildcard && item.numeric == newopt.numeric) {
                    i = index;
                }
            });

            // if already exist in list, remove it
            if (i > -1) {
                $scope.recentSearch.splice(i, 1);
            }

            /*var alreadyHave = $scope.recentSearch.filter(function(item) {
				return item.q == newopt.q && item.type == newopt.type;
			});

            if(alreadyHave.length == 0){
                $scope.recentSearch.unshift(newopt);
            }*/
            $scope.recentSearch.unshift(newopt);
            $scope.recentSearch = $scope.recentSearch.slice(0,10);
            $cookies.put('global-search', JSON.stringify($scope.recentSearch));
        }
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.submitGlobalSearch = function(){
            $state.go("global.search", {q: $scope.globalSearchForm.q, type: $scope.globalSearchForm.type, wildcard: $scope.globalSearchForm.wildcard, numeric: $scope.globalSearchForm.numeric});
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };

        $scope.highlightedText = function(data){
            if(typeof data == "undefined" || data == '' || data == null){
                return '';
            }
            data = typeof data == "number" ? ""+data : data;
            if($scope.wildcard == 1){
                var searched = $scope.q.replace(/[+\-\*]/g,' ').toLowerCase();
                let allwords = searched.split(' ');
                allwords = allwords.filter(function (el) {
                    return el != null && el != '';
                });
                for (let i = 0; i < allwords.length; i++) {
                    if(allwords[i] != ""){
                        let lowercase = data.toLowerCase();
                        if(data.includes(allwords[i]) || lowercase.includes(allwords[i])){
                            var reg = new RegExp('('+allwords[i]+')', 'gi');
                            data = data.replace(reg, "<span style='background-color:yellow;'>"+allwords[i]+"</span>");
                            return data;
                        }
                    }
                }
                return data;
            }
            var reg = new RegExp('('+$scope.q+')', 'gi');
            return data.replace(reg, "<span style='background-color:yellow;'>"+$scope.q+"</span>");
        }

        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        if($scope.q != ''){
            if($scope.type == 'all' || $scope.type == 'calendars'){
                $scope.dtOptionsCalendar = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;
                        data.wildcard = $scope.wildcard;
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-calendars', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO CALENDAR FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    }).withOption('searchable', false)
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsCalendar = [
                    DTColumnBuilder.newColumn('JOB_NO').withOption('defaultContent', '').withOption("width", "10%").withTitle('Job No').renderWith(function(data, type, full, meta) {
                        
                        return '<a target="_blank" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + '})" >' + $scope.highlightedText(data) + '</a>';
                    }),
                    DTColumnBuilder.newColumn('CAPTION').withOption('defaultContent', '').withTitle('Caption').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('attorney_short_name').withTitle('Scheduling Firm').withOption('name', 'attorneyFirm.SHORT_NAME').withOption('defaultContent', '').withOption('searchable', true).withOption("width", "6%").renderWith(function(data, type, full, meta){
                        if (data) {
                            data = escapeString(data);
                            var attorney_firm = "<div uib-tooltip='"+full.attorney_short_name+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:15}}</div>";
                            return '<a target="_blank" uib-tooltip="Attorney" ui-sref="firms.manage.step_one({id : ' + full.attorney_id + '})" >' + attorney_firm + '</a>';
                        } else {
                            return '';
                        }
                    }),
                    DTColumnBuilder.newColumn('reporter_name').withOption('defaultContent', '').withTitle('Resource').renderWith(function(data, type, full, meta) {
                        return '<a target="_blank" ui-sref="representative.reporter.step_one({id : ' + full.reporter_reptyp_id + '})" >' + $scope.highlightedText(data) + '</a>';
                    }),
                    DTColumnBuilder.newColumn('typist_name').withOption('defaultContent', '').withTitle('Typist').renderWith(function(data, type, full, meta) {
                        return '<a target="_blank" ui-sref="representative.reporter.step_one({id : ' + full.typist_reptyp_id + '})" >' + $scope.highlightedText(data) + '</a>';
                    }),
                    DTColumnBuilder.newColumn('TIME_TAKEN').withTitle('Job Time').withOption('defaultContent', '').withOption('searchable', true),
                    DTColumnBuilder.newColumn('DATE_TAKEN').withTitle('Job Date').withOption('defaultContent', '').withOption('searchable', true),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.JOB_NO) {
                            return '<a class="btn btn-primary btn-circle" target="_blank" uib-tooltip="Edit" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + '})" >' +
                                '<i class="fa fa-pencil"></i>' +
                                '</a>';
                        }
    
                    })
                ];
            }
    
            /*if($scope.type == 'all' || $scope.type == 'jobs'){
                $scope.dtOptionsJob = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;                        
                        commonFactory.showDataTable('/api/global-search-jobs', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    })
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });/
    
                $scope.dtColumnsJob = [
                    DTColumnBuilder.newColumn('JOB_NO').withOption('defaultContent', '').withTitle('#').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('TIME_TAKEN').withTitle('TIME TAKEN').withOption('defaultContent', '').withOption('searchable', true),
                        DTColumnBuilder.newColumn('DATE_TAKEN').withTitle('DATE TAKEN').withOption('defaultContent', '').withOption('searchable', true),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.id) {
                            return '<a target="_blank" class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="diary.editcase.step_one({id : ' + full.id + '})" ><i class="fa fa-pencil"></i></a>';
                        }
    
                    })
                ];  
            }*/
    
            if($scope.type == 'all' || $scope.type == 'users'){
                $scope.dtOptionsUser = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;                    
                        data.wildcard = $scope.wildcard;    
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-users', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO USERS FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    })
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsUser = [
                    DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('NAME').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('email').withTitle('EMAIL').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.id) {
                            return '<a class="btn btn-primary btn-circle" target="_blank" uib-tooltip="Edit" ui-sref="users.edit({id : ' + full.id + '})" ><i class="fa fa-pencil"></i></a>';
                        }
    
                    })
                ]; 
            }
            if($scope.type == 'all' || $scope.type == 'contacts'){
                $scope.dtOptionsContact = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;                    
                        data.wildcard = $scope.wildcard;    
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-contacts', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO USERS FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    })
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsContact = [
                    DTColumnBuilder.newColumn('ATTORNEY').withOption('defaultContent', '').withTitle('ATTORNEY').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('attname').withTitle('ATTORNEY NAME').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('name').withTitle('NAME').withOption('defaultContent', '').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('email').withTitle('EMAIL').withOption('defaultContent', '').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.id)
                            return '<a class="btn btn-primary btn-circle" target="_blank" uib-tooltip="Edit" ui-sref="attorney.editattorneytofirm({id : ' + full.id + '})" ><i class="fa fa-pencil"></i></a>';
                        }
                    )
                ]; 
            }
    
            if($scope.type == 'all' || $scope.type == 'invoices'){
                $scope.dtOptionsInvoice = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;
                        data.wildcard = $scope.wildcard;
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-invoices', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO INVOICES FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    })
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsInvoice = [
                    DTColumnBuilder.newColumn('INV_NO').withOption("width", "10%").withOption('defaultContent', '').withTitle('Inv No').renderWith(function(data, type, full, meta) {
                        return '<a target="_blank" ui-sref="invoice.edit({invoice_id : ' + full.JOB_NO + '})" >'+ $scope.highlightedText(data) +'</a>';
                    }),
                    DTColumnBuilder.newColumn('JOB_NO').withOption("width", "10%").withOption('defaultContent', '').withTitle('Job No').renderWith(function(data, type, full, meta) {
                        return '<a target="_blank" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + '})" >'+ $scope.highlightedText(data) +'</a>';
                    }),
                    DTColumnBuilder.newColumn('created_at').withOption("width", "8%").withTitle('Date Taken').withOption('searchable', false).withOption('data', 'created_at').withOption('name', 'created_at').renderWith(function (data, type, full, meta) {
                        if(data) {
                            return data;
                        } 
                        else {
                            return '-';
                        }
                    }),
                    DTColumnBuilder.newColumn('dte_invoice').withOption("width", "6%").withTitle('Invoice Date').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'dte_invoice').renderWith(function (data, type, full, meta) {
                        if(data) {
                            var dte = moment(full.dte_invoice).format('MM/DD/YYYY');
                            if(dte == 'Invalid date'){
                                return '-';
                            }else{
                                return dte;
                            }
                        } 
                        else {
                            return '-';
                        }
                    }),
                    DTColumnBuilder.newColumn('CAPTION').withOption('defaultContent', '').withTitle('Caption').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('ATT_NAME').withOption('defaultContent', '').withTitle('Att Name').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('INV_AMT').withOption("width", "10%").withTitle('Inv Amt').withOption('defaultContent', ''),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.invoiceID) {
                            return '<a class="btn btn-primary btn-circle" target="_blank" uib-tooltip="Edit" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" >' +
                                '<i class="fa fa-pencil"></i>' +
                                '</a>';
                        }
    
                    })
                ]; 
            }
    
            if($scope.type == 'all' || $scope.type == 'accounts'){
                $scope.dtOptionsAccount = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;
                        data.wildcard = $scope.wildcard;
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-accounts', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO ACCOUNTS FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    })
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsAccount = [
                    DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('NAME').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('EmailAddress').withTitle('EMAIL').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('rep_name').withOption('defaultContent', '').withTitle('Account Executive(s)')
                    .renderWith(function(data,type,full,meta){ 
                        return $scope.highlightedText(data);
                    }).withOption("width", "10%"),
    
    
                    /*DTColumnBuilder.newColumn('contact').withOption('defaultContent', '').withTitle('Contact(s)')
                    .renderWith(function(data,type,full,meta){ 
                        if(full.contact.length){
                            var name = [];
                            angular.forEach(full.contact,function(value, key){
                                if(value.contact && value.reptype.First_Name){
                                    name[key] = '<u>'+escapeString(value.First_Name)+'</u>';
                                }
                            });
                            return $scope.highlightedText(name.join(', '));
                        }
                        return '';
                    }).withOption("width", "10%").withOption('First_Name'),*/
    
    
    
                    DTColumnBuilder.newColumn('collector_name').withOption('defaultContent', '').withTitle('Collector Person(s)')
                    .renderWith(function(data,type,full,meta){ 
                        return $scope.highlightedText(data);
                    }).withOption("width", "10%"),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.id) {
                            return '<a class="btn btn-primary btn-circle" target="_blank" uib-tooltip="Edit" ui-sref="firms.manage.step_one({id : ' + full.id + '})" ><i class="fa fa-pencil"></i></a>';
                        }
    
                    })
                ]; 
            }
       
            if($scope.type == 'all' || $scope.type == 'transcripts'){
                $scope.dtOptionsTranscripts = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;
                        data.wildcard = $scope.wildcard;
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-transcripts', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO TRANSCRIPTS FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    })
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsTranscripts = [
                    DTColumnBuilder.newColumn('job_num').withOption("width", "10%").withTitle('JOB NO').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return '<a target="_blank" ui-sref="diary.editcase.step_one({id : ' + full.job_num + '})" >'+ $scope.highlightedText(data) +'</a>';

                    }),
                    DTColumnBuilder.newColumn('dte_upload').withOption("width", "13%").withTitle('Date Taken').withOption('searchable', false).withOption('data', 'dte_upload').withOption('name', 'dte_upload').renderWith(function (data, type, full, meta) {
                        if(data) {
                            var dte = moment(full.dte_upload).format('MM/DD/YYYY');
                            if(dte == 'Invalid date'){
                                return '-';
                            }else{
                                return dte;
                            }
                        } 
                        else {
                            return '-';
                        }
    
                    }),
                    DTColumnBuilder.newColumn('CAPTION').withOption('defaultContent', '').withTitle('CAPTION').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('description').withTitle('Description').withOption('defaultContent', '').withOption('searchable', true).renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                        if (full && full.job_num) {
                            // return '<button class="btn btn-primary btn-circle" ng-click="viewTranscripts(' + full.job_num + ')"><i class="fa fa-eye"></i></button>';
                            return '<a target="_blank" href="{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=matchingjobs.php?job_no={{' + full.job_num + '}}"><button class="btn btn-primary btn-circle" ><i class="fa fa-eye"></i></button></a>';
                        }
    
                    })
                ]; 
            }
            if($scope.type == 'all' || $scope.type == 'resources'){
                $scope.dtOptionsResource = DTOptionsBuilder.newOptions()
                    .withDataProp('data')
                    .withOption('ajax', function (data, callback, settings) {
                        
                        data.q = $scope.q;
                        data.wildcard = $scope.wildcard;
                        data.numeric = $scope.numeric;
                        commonFactory.showDataTable('/api/global-search-resources', data).success(function (res) {
                            if (res.error) {
                                $scope.reloadData();
                            }
                            else { 
                                callback(res);
                            }
                        }).error(function (err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadData();
                            }
                        });
                    })
                    .withOption('processing', true)
                    .withLanguage({ 
                        "sZeroRecords": "NO CALENDAR FOUND",
                        "sProcessing": "<img src='img/loading_bar.gif'/>"
                    }).withOption('searchable', false)
                    .withOption('serverSide', true) 
                    .withPaginationType('simple_numbers')
                    .withOption('searchDelay', 500) 
                    .withOption('order', [0, 'desc'])
                    .withOption('createdRow', $scope.createdRow)
                    .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    });
    
                $scope.dtColumnsResource = [
                    DTColumnBuilder.newColumn('FIRST_NAME').withOption('defaultContent', '').withOption("width", "10%").withTitle('FIRST NAME').renderWith(function(data, type, full, meta) {
                        
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('LAST_NAME').withOption('defaultContent', '').withTitle('LAST NAME').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn('PHONE').withOption('defaultContent', '').withTitle('PHONE').renderWith(function(data, type, full, meta) {
                        return $scope.highlightedText(data);
                    }),
                    DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().withOption('searchable', false)
                    .renderWith(function(data, type, full, meta) {
                            return '<a class="btn btn-primary btn-circle" target="_blank" uib-tooltip="Edit" ui-sref="representative.reporter.step_one({id : ' + full.id + '})" >' +
                                '<i class="fa fa-pencil"></i>' +
                                '</a>';
                    })
                ];
            }
        }
    }]);