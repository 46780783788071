angular.module('deitz')
    .controller('homeController', ['$scope', '$rootScope','$http', 'apiUrl','configFactory', '$uibModal','commonFactory',
    function($scope, $rootScope, $http, apiUrl,configFactory,$uibModal,commonFactory) {
    
    /*$scope.formData = '';
    $scope.notaryexpirecheck = function(params){
        var notaryexpirecheckModal = $uibModal.open({
            templateUrl: "modules/home/notaryexpire.html",
            controller: function($scope,params,$uibModalInstance){   
                    $scope.formData = params;               
                    $scope.cancel = function(isChanged) {
                        $uibModalInstance.dismiss({ isChanged : isChanged});
                    };                                     
            },
            resolve: {  
                params : function(){
                    return params;
                }
            },
            keyboard: false,
            backdrop: false,
            close: function () {
            }    
        });
    };
    commonFactory.post('/api/get-expiration-days',{ email: $rootScope.auth_user.email })
    .success(function(response) {
        if (response) {
            if (response.status == 200 &&  response.result.data != 0 &&  response.result.data != '') {                                
                $scope.formData = response.result.data;                
                $scope.notaryexpirecheck(response.result.data);                              
            }
            $rootScope.showLoader(false);
        }
    })*/

    }]);