angular.module('deitz').factory('TextmessagesFactory', ['$http', '$q', '$cookies', 'apiUrl', function($http, $q, $cookies, apiUrl) {
    var TextmessagesFactory = {}; 


 
   
   

    TextmessagesFactory.textmessagecount = function(data){
        return $http.post(apiUrl + "/api/unread-message-count",data);
    };
   

   

    return TextmessagesFactory;
}]);