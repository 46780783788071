angular.module('deitz').controller('editbusinessunitController', [
    '$scope', '$http', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'apiUrl', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance', 'SweetAlert',
    function(
    $scope, $http, $state, $rootScope, codeFactory, commonFactory, apiUrl, Notification, fromModal, plaintiffId, $uibModalInstance, SweetAlert
    ) {

        $scope.businessunit = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Business Unit';
        $scope.modalTitle = 'Edit plaintiff/defendant';
        $scope.modalId = plaintiffId;

        $scope.mobile_number_fields = [];
        $scope.mobile_numbers = [];

        $scope.brands_fields = [];
        $scope.brands = [];

        $scope.zoom_email_locked = true;
        if($rootScope.havePermission('allow_access_without_password','allow.update.zoom.master.email')){
            $scope.zoom_email_locked = false;
        }

        $scope.timeList = [''];
        let x = {
            slotInterval: 15,
            openTime: '00:00',
            closeTime: '23:59'
        };
            
        let startTime = moment(x.openTime, "hh:mm A");
        let endTime = moment(x.closeTime, "hh:mm A");  
        
        while (startTime < endTime) {
            $scope.timeList.push(startTime.format("h:mm A")); 
            startTime.add(x.slotInterval, 'minutes');
        }
        $scope.timeList.push(endTime.format("hh:mm A"));

        $scope.isSubmitDisabled = false;
        $scope.isSubmitDisabled1 = false;
        $scope.isSubmitDisabled2 = false;
        $scope.editor = '';
        $scope.editor1 = '';
        $scope.editor2 = '';
        $scope.scheduling_email_footer = '';
        $scope.client_announcement = '';
        $scope.resource_announcement = '';
        $scope.maxCharacters = 5000;
        $scope.maxCharacters_announce = 350;
        $scope.removePlugins = [
            'ExportPdf',
            'ExportWord',
            'AIAssistant',
            'CKBox',
            'CKFinder',
            'EasyImage',
            'MultiLevelList',
            'RealTimeCollaborativeComments',
            'RealTimeCollaborativeTrackChanges',
            'RealTimeCollaborativeRevisionHistory',
            'PresenceList',
            'Comments',
            'TrackChanges',
            'TrackChangesData',
            'RevisionHistory',
            'Pagination',
            'WProofreader',
            'MathType',
            'SlashCommand',
            'Template',
            'DocumentOutline',
            'FormatPainter',
            'TableOfContents',
            'PasteFromOfficeEnhanced',
            'CaseChange'
        ];

        $scope.toolbarItems = [
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo'
        ];

        $scope.headingOptions = [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
        ];

        $scope.fontFamilyOptions = [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
        ];

        $scope.initEditor = function(){
            const container = document.getElementById('htmleditor');
            const progressCircle = document.getElementById('htmleditor__chart__circle');
            const charactersBox = document.getElementById( 'htmleditor__chart__characters' );
            const wordsBox = document.getElementById('htmleditor__words');
            const circleCircumference = Math.floor( 2 * Math.PI * progressCircle.getAttribute( 'r' ) );

            CKEDITOR.ClassicEditor.create(document.getElementById("htmleditor__editor"), {
                wordCount: {
                    onUpdate: stats => {
                        const charactersProgress = stats.characters / $scope.maxCharacters * circleCircumference;
                        const isLimitExceeded = stats.characters > $scope.maxCharacters;
                        const isCloseToLimit = !isLimitExceeded && stats.characters > $scope.maxCharacters * .8;
                        const circleDashArray = Math.min( charactersProgress, circleCircumference );
                        progressCircle.setAttribute( 'stroke-dasharray', `${ circleDashArray },${ circleCircumference }` );
                        if ( isLimitExceeded ) {
                            charactersBox.textContent = `-${ stats.characters - $scope.maxCharacters }`;
                            $scope.isSubmitDisabled = true;
                        } else {
                            charactersBox.textContent = stats.characters;
                            $scope.isSubmitDisabled = false;
                        }
                        wordsBox.textContent = `Words in the post: ${ stats.words }`;
                        container.classList.toggle( 'htmleditor__limit-close', isCloseToLimit );
                        container.classList.toggle( 'htmleditor__limit-exceeded', isLimitExceeded );
                    }
                },
                toolbar: {
                    items: $scope.toolbarItems,
                    shouldNotGroupWhenFull: true
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                heading: {
                    options: $scope.headingOptions
                },
                placeholder: 'Type!',
                fontFamily: {
                    options: $scope.fontFamilyOptions,
                    supportAllValues: true
                },
                fontSize: {
                    options: [ 10, 12, 14, 'default', 18, 20, 22 ],
                    supportAllValues: true
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ]
                },
                htmlEmbed: {
                    showPreviews: true
                },
                link: {
                    decorators: {
                        addTargetToExternalLinks: true,
                        defaultProtocol: 'https://',
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                removePlugins: $scope.removePlugins
            })
            .then(newEditor => {
                $scope.editor = newEditor;
                if(!$rootScope.havePermission('business_unit','allow.to.update.email.defaults')){
                    newEditor.enableReadOnlyMode("editor");
                }
            })
            .catch(error => {});
        }

        $scope.initEditor1 = function(){
            const container = document.getElementById('htmleditor1');
            const progressCircle = document.getElementById('htmleditor1__chart__circle' );
            const charactersBox = document.getElementById('htmleditor1__chart__characters' );
            const wordsBox = document.getElementById('htmleditor1__words');
            const circleCircumference = Math.floor( 2 * Math.PI * progressCircle.getAttribute( 'r' ) );

            CKEDITOR.ClassicEditor.create(document.getElementById("htmleditor1__editor"), {
                wordCount: {
                    onUpdate: stats => {
                        const charactersProgress = stats.characters / $scope.maxCharacters_announce * circleCircumference;
                        const isLimitExceeded = stats.characters > $scope.maxCharacters_announce;
                        const isCloseToLimit = !isLimitExceeded && stats.characters > $scope.maxCharacters_announce * .8;
                        const circleDashArray = Math.min( charactersProgress, circleCircumference );
                        progressCircle.setAttribute( 'stroke-dasharray', `${ circleDashArray },${ circleCircumference }` );
                        if ( isLimitExceeded ) {
                            charactersBox.textContent = `-${ stats.characters - $scope.maxCharacters_announce }`;
                            $scope.isSubmitDisabled1 = true;
                        } else {
                            charactersBox.textContent = stats.characters;
                            $scope.isSubmitDisabled1 = false;
                        }
                        wordsBox.textContent = `Words in the client announcements (Header): ${ stats.words }`;
                        container.classList.toggle( 'htmleditor__limit-close', isCloseToLimit );
                        container.classList.toggle( 'htmleditor__limit-exceeded', isLimitExceeded );
                    }
                },
                toolbar: {
                    items: $scope.toolbarItems,
                    shouldNotGroupWhenFull: true
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                heading: {
                    options: $scope.headingOptions
                },
                placeholder: 'Type!',
                fontFamily: {
                    options: $scope.fontFamilyOptions,
                    supportAllValues: true
                },
                fontSize: {
                    options: [ 10, 12, 14, 'default', 18, 20, 22 ],
                    supportAllValues: true
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ]
                },
                htmlEmbed: {
                    showPreviews: true
                },
                link: {
                    decorators: {
                        addTargetToExternalLinks: {
                            mode: 'automatic',
                            callback: url => /^(https?:)?\/\//.test( url ),
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        },
                        defaultProtocol: 'https://',
                        toggleDownloadable: {
                            mode: 'auto',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                removePlugins: $scope.removePlugins
            })
            .then(newEditor => {
                $scope.editor1 = newEditor;
            })
            .catch(error => {});
        }

        $scope.initEditor2 = function(){
            const container = document.getElementById('htmleditor2');
            const progressCircle = document.getElementById('htmleditor2__chart__circle' );
            const charactersBox = document.getElementById('htmleditor2__chart__characters' );
            const wordsBox = document.getElementById('htmleditor2__words');
            const circleCircumference = Math.floor( 2 * Math.PI * progressCircle.getAttribute( 'r' ) );

            CKEDITOR.ClassicEditor.create(document.getElementById("htmleditor2__editor"), {
                wordCount: {
                    onUpdate: stats => {
                        const charactersProgress = stats.characters / $scope.maxCharacters_announce * circleCircumference;
                        const isLimitExceeded = stats.characters > $scope.maxCharacters_announce;
                        const isCloseToLimit = !isLimitExceeded && stats.characters > $scope.maxCharacters_announce * .8;
                        const circleDashArray = Math.min( charactersProgress, circleCircumference );
                        progressCircle.setAttribute( 'stroke-dasharray', `${ circleDashArray },${ circleCircumference }` );
                        if ( isLimitExceeded ) {
                            charactersBox.textContent = `-${ stats.characters - $scope.maxCharacters_announce }`;
                            $scope.isSubmitDisabled2 = true;
                        } else {
                            charactersBox.textContent = stats.characters;
                            $scope.isSubmitDisabled2 = false;
                        }
                        wordsBox.textContent = `Words in the resource announcements: ${ stats.words }`;
                        container.classList.toggle( 'htmleditor__limit-close', isCloseToLimit );
                        container.classList.toggle( 'htmleditor__limit-exceeded', isLimitExceeded );
                    }
                },
                toolbar: {
                    items: $scope.toolbarItems,
                    shouldNotGroupWhenFull: true
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                heading: {
                    options: $scope.headingOptions
                },
                placeholder: 'Type!',
                fontFamily: {
                    options: $scope.fontFamilyOptions,
                    supportAllValues: true
                },
                fontSize: {
                    options: [ 10, 12, 14, 'default', 18, 20, 22 ],
                    supportAllValues: true
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ]
                },
                htmlEmbed: {
                    showPreviews: true
                },
                link: {
                    decorators: {
                        addTargetToExternalLinks: {
                            mode: 'automatic',
                            callback: url => /^(https?:)?\/\//.test( url ),
                            attributes: {
                                target: '_blank',
                                rel: 'noopener noreferrer'
                            }
                        },
                        defaultProtocol: 'https://',
                        toggleDownloadable: {
                            mode: 'auto',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                removePlugins: $scope.removePlugins
            })
            .then(newEditor => {
                $scope.editor2 = newEditor;
            })
            .catch(error => {});
        }
        
        $scope.unlockZoomEmail = function(){

            // If already unlock then, just lock that.
            if(!$scope.zoom_email_locked){
                $scope.zoom_email_locked = true;
                return;
            }


            SweetAlert.swal({
                title: "Unlock Zoom Master Email",
                text: "Please enter password to change the zoom master email.",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) {
                if (inputValue === false) return false;
                if (inputValue === "") {
                    swal.showInputError("You need to write something!");
                    return false
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/unlock-zoom-master-email', { 'password': inputValue })
                        .success(function(response) {
                            if (response['result']) {
                                if (response['result']['auth']) {
                                    $scope.zoom_email_locked = false;
                                    $rootScope.showLoader(false);
                                } else {
                                    $rootScope.showLoader(false);
                                    SweetAlert.swal("Error !", "Wrong Password!", "error");
                                }
                            } else {
                                $rootScope.showLoader(false);
                                SweetAlert.swal("Oops !", "Something went wrong!", "error");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            SweetAlert.swal("Oops !", "Something went wrong!", "error");
                        });

                }
            });
        };

        $scope.businessunit.payment_confirmation_email = 'remittance@lexitaslegal.com';

        $http.post(apiUrl + '/api/getBrandsForAssignBU', {"id" : plaintiffId? plaintiffId:$state.params.id}).then(function(response) { 
            $scope.BrandList = response.data.result.data;  
        });
        $http.get(apiUrl + '/api/get-salesmanger-list').success(function(response) {
            $scope.SalesManagerList=response.result
        });
        commonFactory.get('/api/connection-methods')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.connectionMethods = response.result.resources;
            }
        });
        commonFactory.get('/api/getLocation')
        .success(function (response) {
            if (response.result && response.result.data) {
                $scope.locations = response.result.data || '';
            }
        })
        .error(function (err) {
        });
        commonFactory.get('/api/jobcategories')
        .success(function (response) {
            if (response.result && response.result.jobcategories) {
                $scope.jobcategories = response.result.jobcategories || '';
            }
        })
        .error(function (err) {
        });
        commonFactory.get('/api/casetypes')
        .success(function (response) {
            $rootScope.showLoader(false);
            if (response && response.result && response.result.casetype) {
                $scope.casetype = response.result.casetype || '';
            }
        })
        .error(function (err) {
            $rootScope.showLoader(false);
        });
        commonFactory.get('/api/witnesstypes')
        .success(function (response) {
            $rootScope.showLoader(false);
            if (response.result && response.result.witnesstype) {
                $scope.witnesstype = response.result.witnesstype || '';
            }
        })
        .error(function (err) {
            $rootScope.showLoader(false);
        });
        //get Timezone list
        commonFactory.get('/api/timezoneListUsa').success(function (response) {
            if (response.result) {
                $scope.timezoneList = response.result ;
            }
        })
        .error(function (err) {
        });
        //get Turn Around list
        commonFactory.get('/api/all-active-turn-around').success(function (response) {
            if (response.result && response.result.turnAround) {
                $scope.turn_around = response.result.turnAround.map(function (item) {
                    return {
                        id: item.days,     
                        label: item.name 
                    };
                }) || [];
            }
        }).error(function (err) {});
        /* $scope.turn_around = [
            { id: 11, label: '11th' },
            { id: 10, label: '10th' },
            { id: 0, label: 'Same Day' },
            { id: 1, label: 'Next Day' },
            { id: 2, label: '2nd' },
            { id: 3, label: '3rd' },
            { id: 4, label: '4th' },
            { id: 5, label: '5th' },
            { id: 6, label: '6th' },
            { id: 7, label: '7th' },
            { id: 8, label: '8th' },
            { id: 9, label: '9th' }
        ]; */
        $scope.job_business_days = [
            { id: 1, label: 'Next Day' },
            { id: 2, label: '2nd' },
            { id: 3, label: '3rd' },
            { id: 4, label: '4th' },
        ];

        $scope.remove_brand = function(index){                
            $scope.brands_fields.splice(index, 1);
            var i = 0; 
            var reindex_brands = []
            angular.forEach($scope.brands,function(value, key){
                if(key != index){  
                    reindex_brands[i] = value;
                    i++;
                }
            });
            $scope.brands = reindex_brands;
        }

        $scope.remove_mobile_number = function(index){                
            $scope.mobile_number_fields.splice(index, 1);
            var i = 0; 
            var reindex_mobile_numbers = {};
            angular.forEach($scope.mobile_numbers,function(value, key){
                if(key != index){  
                    reindex_mobile_numbers[i] = value;
                    i++;
                }
                
            });
            $scope.mobile_numbers = reindex_mobile_numbers;
        }

        //close modal
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        //Edit Mode of businessunit.
        if ($state.params && $state.params.id) {
            var id = plaintiffId? plaintiffId:$state.params.id;
            commonFactory.get('/api/businessunit/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.business_unit_detail) {
                        $scope.businessunit.name = response.result.business_unit_detail.name || '';
                        $scope.businessunit.payroll_bu_id = response.result.business_unit_detail.payroll_bu_id || '';
                        $scope.businessunit.confirmation_from_email = response.result.business_unit_detail.confirmation_from_email || '';
                        $scope.businessunit.meeting_cc_email = response.result.business_unit_detail.meeting_cc_email || '';

                        if(response.result.business_unit_detail.payment_confirmation_email){
                            $scope.businessunit.payment_confirmation_email = response.result.business_unit_detail.payment_confirmation_email;
                        }

                        $scope.businessunit.billing_from_email = response.result.business_unit_detail.billing_from_email || '';
                        $scope.businessunit.bu_manager_email = response.result.business_unit_detail.bu_manager_email || '';
                        $scope.businessunit.ticketing_system_email = response.result.business_unit_detail.ticketing_system_email || '';
                        // $scope.businessunit.slug = response.result.businessunit_detail.slug || '';
                        // $scope.businessunit.notification_mobile_numbers = response.result.business_unit_detail.notification_mobile_numbers || '';

                        if(response.result.business_unit_detail.notification_mobile_numbers){
                            $scope.mobile_numbers = response.result.business_unit_detail.notification_mobile_numbers.split(',');
                        }
                        $scope.mobile_number_fields = angular.copy($scope.mobile_numbers);

                        $scope.businessunit.technician_cell = response.result.business_unit_detail.technician_cell || '';
                        $scope.businessunit.technician_email = response.result.business_unit_detail.technician_email || '';
                        $scope.businessunit.exhibit_technician_cell = response.result.business_unit_detail.exhibit_technician_cell || '';
                        $scope.businessunit.exhibit_technician_email = response.result.business_unit_detail.exhibit_technician_email || '';
                        $scope.businessunit.sales_manager_email = response.result.business_unit_detail.sales_manager_email || '';
                        $scope.businessunit.production_manager_email = response.result.business_unit_detail.production_manager_email || '';
                        $scope.businessunit.zoom_master_account = response.result.business_unit_detail.zoom_master_account || '';
                        $scope.businessunit.sales_manager_user_id = response.result.business_unit_detail.sales_manager_user_id || '';

                        $scope.businessunit.remit_address_1 = response.result.business_unit_detail.remit_address_1 || '';
                        $scope.businessunit.remit_address_2 = response.result.business_unit_detail.remit_address_2 || '';
                        $scope.businessunit.remit_address_3 = response.result.business_unit_detail.remit_address_3 || '';
                        $scope.businessunit.remit_phone = response.result.business_unit_detail.remit_phone || '';
                        $scope.businessunit.remit_email = response.result.business_unit_detail.remit_email || '';

                        $scope.businessunit.production_address_1 = response.result.business_unit_detail.production_address_1 || '';
                        $scope.businessunit.production_address_2 = response.result.business_unit_detail.production_address_2 || '';
                        $scope.businessunit.production_address_3 = response.result.business_unit_detail.production_address_3 || '';
                        $scope.businessunit.production_phone = response.result.business_unit_detail.production_phone ? parseInt(response.result.business_unit_detail.production_phone) : '';

                        $scope.businessunit.location_action = response.result.business_unit_detail.location_action || '';
                        $scope.businessunit.held_at_flag = response.result.business_unit_detail.held_at_flag || '';
                        $scope.businessunit.location_id = response.result.business_unit_detail.location_id || '';
                        $scope.businessunit.job_category_id = response.result.business_unit_detail.job_category_id || '';
                        $scope.businessunit.case_type_id = response.result.business_unit_detail.case_type_id || '';
                        $scope.businessunit.witness_type_id = response.result.business_unit_detail.witness_type_id || '';
                        $scope.businessunit.turn_around_info = response.result.business_unit_detail.turn_around_info || '';
                        $scope.businessunit.job_date_business_day = response.result.business_unit_detail.job_date_business_day || '';
                        $scope.businessunit.gm_value = response.result.business_unit_detail.gm_value || '';
                        $scope.businessunit.cogs_value = response.result.business_unit_detail.cogs_value || '';
                        $scope.businessunit.auto_ack_confirmations = response.result.business_unit_detail.auto_ack_confirmations ? response.result.business_unit_detail.auto_ack_confirmations : 'N';
                        $scope.businessunit.hold_invoice_for_review = response.result.business_unit_detail.hold_invoice_for_review ? response.result.business_unit_detail.hold_invoice_for_review : '1';
                        $scope.businessunit.automate_deliverables_notifications = response.result.business_unit_detail.automate_deliverables_notifications ? response.result.business_unit_detail.automate_deliverables_notifications : '1';
                        $scope.businessunit.send_attorney_reset_email = response.result.business_unit_detail.send_attorney_reset_email ? response.result.business_unit_detail.send_attorney_reset_email.toString() : '';
                        $scope.businessunit.confirmation_from_email_resource = response.result.business_unit_detail.confirmation_from_email_resource;
                        $scope.businessunit.invoice_billing_type = response.result.business_unit_detail.invoice_billing_type || 'SB';
                        $scope.businessunit.append_errata = response.result.business_unit_detail.append_errata.toString();
                        $scope.businessunit.di_job_summary = response.result.business_unit_detail.di_job_summary;
                        $scope.businessunit.is_zoom_api_isv_style = response.result.business_unit_detail.is_zoom_api_isv_style;
                        $scope.businessunit.zoom_api_group_id = response.result.business_unit_detail.zoom_api_group_id;


                        $scope.businessunit.job_acceptance_help_buttons = response.result.business_unit_detail.job_acceptance_help_buttons;
                        $scope.businessunit.cancellation_time = response.result.business_unit_detail.cancellation_time || '';
                        $scope.businessunit.confirmation_send_time = response.result.business_unit_detail.confirmation_send_time || '';
                        $scope.businessunit.confirmation_timezone = response.result.business_unit_detail.confirmation_timezone || '';
                        $scope.businessunit.confirmation_send_day = response.result.business_unit_detail.confirmation_send_day.toString() || '';

                        $scope.businessunit.scheduling_email_footer = response.result.business_unit_detail.scheduling_email_footer || '';
                        $scope.scheduling_email_footer = $scope.businessunit.scheduling_email_footer;

                        $scope.businessunit.client_announcement = response.result.business_unit_detail.client_announcement || '';
                        $scope.client_announcement = $scope.businessunit.client_announcement;
                        $scope.businessunit.resource_announcement = response.result.business_unit_detail.resource_announcement || '';
                        $scope.resource_announcement = $scope.businessunit.resource_announcement;
                        
                        setTimeout(function(){ $scope.initEditor();$scope.initEditor1();$scope.initEditor2(); }, 500);

                        
                        
                        var brands = response.result.business_unit_detail.brands;
                        if(brands.length > 0){
                            var reindex_brands = [];
                            for (var i = 0; i < brands.length; i++) {
                                reindex_brands[i] = brands[i].id;
                            }
                            $scope.brands = reindex_brands;
                        }
                        $scope.brands_fields = angular.copy($scope.brands);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }
        $scope.ValidateEmail = function (mail) {
            mail = mail.trim();
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
                return (true)
            }
            return (false)
        }
        //Update Mode of businessunit
        $scope.addBusinessunit = function(businessunit, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = plaintiffId? plaintiffId:$state.params.id;
                if($scope.isSubmitDisabled){
                    Notification.error("Character Limit exceed for Scheduling Email Footer");
                    $rootScope.showLoader(false);
                    return;
                }
                if($scope.isSubmitDisabled1){
                    Notification.error("Character Limit exceed for Client Announcement (Header)");
                    $rootScope.showLoader(false);
                    return;
                }
                if($scope.isSubmitDisabled2){
                    Notification.error("Character Limit exceed for Resource Announcement");
                    $rootScope.showLoader(false);
                    return;
                }
                if(businessunit.technician_email){
                    var emails = businessunit.technician_email.split(/,|;/);
                    for (var i = 0; isValid && i < emails.length; i++) {
                        if (!$scope.ValidateEmail(emails[i])) {
                            Notification.error("Please enter valid technician emails");
                            $rootScope.showLoader(false);
                            return;
                        }
                    }
                }
                if(businessunit.exhibit_technician_email){
                    var emails = businessunit.exhibit_technician_email.split(/,|;/);
                    for (var i = 0; isValid && i < emails.length; i++) {
                        if (!$scope.ValidateEmail(emails[i])) {
                            Notification.error("Please enter valid exhibit technician emails");
                            $rootScope.showLoader(false);
                            return;
                        }
                    }
                }
                if(businessunit.sales_manager_email){
                    var emails = businessunit.sales_manager_email.split(/,|;/);
                    for (var i = 0; isValid && i < emails.length; i++) {
                        if (!$scope.ValidateEmail(emails[i])) {
                            Notification.error("Please enter valid sales manager emails");
                            $rootScope.showLoader(false);
                            return;
                        }
                    }
                }
                if(businessunit.production_manager_email){
                    var emails = businessunit.production_manager_email.split(/,|;/);
                    for (var i = 0; isValid && i < emails.length; i++) {
                        if (!$scope.ValidateEmail(emails[i])) {
                            Notification.error("Please enter valid production manager emails");
                            $rootScope.showLoader(false);
                            return;
                        }
                    }
                }
                var submit_data = $scope.businessunit;
                submit_data.notification_mobile_numbers = $scope.mobile_numbers;
                submit_data.brand_ids = $scope.brands;
                submit_data.scheduling_email_footer = $scope.editor.getData();
                submit_data.client_announcement = $scope.editor1.getData();
                submit_data.resource_announcement = $scope.editor2.getData();
                
                commonFactory.put('/api/businessunit/' + id, submit_data)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Business Unit updated successfully");
                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.businessunit_update.id);
                                }else{
                                    $state.go("code_tables.business_unit_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
