angular.module('deitz')
    .controller('jobStatusReportController', [
        '$scope',
        '$rootScope',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        function (
            $scope,
            $rootScope,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
        ) {

            $scope.dtInstance = {};
            $scope.dateRange
            $scope.title = $state.current.data.pageTitle;
            $scope.downloadExcel = false;
            $scope.status = [];
            $scope.job_status = '';

            $scope.resetSearch = function () {
                $scope.dateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.report = {
                dateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                }
            }


            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            } else {
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }

            //Getting all status from statues table.
            commonFactory.get('/api/getStatus').success(function (response) {
                if (response.result && response.result.data) {
                    $scope.status = response.result.data || '';
                }
            }).error(function (err) {
            });

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };
            
            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.downloadExcelFile = function () {
                $scope.downloadExcel = true;
                $scope.reloadData();
            }

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    if ($scope.downloadExcel) {
                        $rootScope.showLoader(true);
                    }
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); $scope.inProcess = true;
                    data.status = $scope.job_status;
                    data.downloadExcel = $scope.downloadExcel;
                    data.startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
                    commonFactory.showDataTable('/api/get-job-status-report', data).success(function (res) {
                        if (!res.error) {
                            $rootScope.showLoader(false);
                            $scope.selectAll = true;
                            $scope.invoiceArray = {};
                            callback(res);
                        }
                        if ($scope.downloadExcel) {
                            // $window.location.href = apiUrl + '/api/download-job-status-report';
                            $window.open(apiUrl + '/api/download-job-status-report', '_blank');    
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        Notification.error(err.error)
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO JOB AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ JOBS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 JOBS",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL JOBS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ JOBS",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING JOBS FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searching', false)
                .withOption('searchDelay', 500)
                .withOption('order', [2, 'asc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('business_unit').withTitle('Business Unit').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_number').withTitle('Job Number').withOption('searchable', true).renderWith(function(data,type,full,meta){
                    return '<a target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'<a>';
                }),
                DTColumnBuilder.newColumn('job_date').withTitle('Job Date').withOption('searchable', false).withOption('data', 'job_date').withOption('name', 'DIARY_tbl.dte_taken').renderWith(function (data, type, full, meta) {
                    if (data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if (dte == 'Invalid date') {
                            return '-';
                        } else {
                            return dte;
                        }
                    }
                    else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('witness_name').withTitle('Witness Name').withOption('searchable', true),
                DTColumnBuilder.newColumn('pages').withTitle('Pages').withOption('searchable', true),
                DTColumnBuilder.newColumn('invoice_number').withTitle('Invoice Number').withOption('searchable', true).renderWith(function(data,type,full,meta){
                        return ( data) ? '<a target="_blank" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })">'+data+'<a>' : '';
                }),
                DTColumnBuilder.newColumn('billing_note_internal').withTitle('Billing Note - Internal').withOption('searchable', true),
                DTColumnBuilder.newColumn('billing_note_invoice').withTitle('Billing Note - Seen On Invoice').withOption('searchable', true)
            );
        }
    ]);
