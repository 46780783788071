angular.module('deitz')
	   .controller('auditLoginController',[
	   			'$scope',
	   			'commonFactory',
	   			'$rootScope', 
	   			'DTColumnBuilder',
	   			'DTOptionsBuilder',
	   			'$compile',
	   			function(
	   				$scope,
	   				commonFactory,
	   				$rootScope, 
	   				DTColumnBuilder,
	   				DTOptionsBuilder,
	   				$compile
	   				){ 

	        $scope.dtInstance = {};
	        
	        $scope.reloadData = function () {
	            $scope.dtInstance.rerender();
	        };

	        $scope.createdRow = function (row, data, dataIndex) {
	            // Recompiling so we can bind Angular directive to the DT
	            $compile(angular.element(row).contents())($scope);
	        };
  

	        $scope.dtOptions = DTOptionsBuilder.newOptions()
	            .withDataProp('data')
	            .withOption('ajax', function (data, callback, settings) {
	                 // map your server's response to the DataTables format and pass it to
	                commonFactory.showDataTable('/api/get-audit-login', data).success(function (res) {
	                    if (res.error){
	                        $scope.reloadData();
	                    }
	                    else{  
		                    // DataTables' callback
		                    callback(res);
	                    }
	                }).error(function (err) {
	                    if (err.error)
	                    $scope.reloadData();
	                });
	            })
	            .withOption('processing', true)
	            .withLanguage({ 
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>" 
                })
	            // .withOption('stateSave', true)
	            .withOption('serverSide', true) 
                .withOption('order', [2, 'desc'])
	            .withPaginationType('simple_numbers')
	            .withOption('searchDelay', 500)
	            .withOption('createdRow', $scope.createdRow);
	             

	        $scope.dtColumns = [

	            DTColumnBuilder.newColumn('email').withTitle('Email').withOption('searchable',true), 
	            DTColumnBuilder.newColumn('ipaddress').withTitle('Ip Address').withOption('searchable',true),
	            DTColumnBuilder.newColumn('dte_login').withTitle('Login Date').withOption('searchable',true).renderWith(function(data,type,full,meta){
	            	return moment(data).format('MM/DD/YYYY hh:mm a');
	            }),
	            DTColumnBuilder.newColumn('status').withTitle('Status').withOption('searchable',false).renderWith(function(data,type,full,meta){
	            	if(data == 'S'){
	            		return "<span style='color:green'>Successful</span>"
	            	}else if(data == 'F'){
	            		return "<span style='color:red'>Unsuccessful</span>"
	            	}else{
	            		return '-';
	            	}
	            }),
	        ]; 

}]);