angular.module('deitz')
    .factory('rateFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var rateFactory = {};

        function convertParams(params) {
            var reqData = {};
            if (params) {
                reqData = params;
                reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                reqData.limit = params.limit || 10; // Number of entries showed per page.
                reqData.search = params.search ? params.search.value : '';
                reqData.orderColumn = params.order ? params.order[0].column : '';
                reqData.orderDir = params.order ? params.order[0].dir : '';
                if (params.id) {
                    reqData.id = params.id ? params.id : '';
                }
            }
            return reqData;
        }

        rateFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        rateFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        rateFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        rateFactory.delete = function(url) {
            return $http.delete(apiUrl + url);
        };

        //browse all Representative Type
        rateFactory.getAllRates = function(url, params) {
            var reqData = convertParams(params);
            return $http({ "url": apiUrl + url, "method": "GET", "params": reqData });
        };

        var vh = validation_helper();

        rateFactory.validations = {
            appear_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            copy_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            postage: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            web_amount: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            cd_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            late_appear: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            no_min_taken_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            min_invoice_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            SOR: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            SORr: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            Room: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            word_indexing_flat_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            word_indexing_page_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            mini_script_flat_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            mini_script_per_page_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            e_transcript: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },

            med_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            exp_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            daily_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            immed_rate: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            charge_2: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            location_id: {
                required: true,
            },
            rateName: {
                required: true,
            },
            EntityType: {
                required: true,
            }, 
            admin_fee: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            web_charge_per_witness: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            video_conference_handling_and_connection_fee: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            reminder_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            legalview_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            deposition_insights_charge1: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            deposition_insights_charge2: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            deposition_insights_charge3: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            digitization_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            exhibit_charge: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
            common_validate_number: {
                patterns: vh.numeric.patterns,
                maxlength: vh.numeric.maxlength,
                required: vh.numeric.required,
            },
        };

        rateFactory.messages = {
            appear_rate: {
                patterns: "Please enter valid Appear Rate.",
                required: "Appear Rate is required.",
                maxlength: "Appear Rate Field not be upto 25 characters."
            },
            rate: {
                patterns: "Please enter valid Rate.",
                required: "Rate is required.",
                maxlength: "Rate Field not be upto 25 characters."
            },
            copy_rate: {
                patterns: "Please enter valid Copy Rate.",
                required: "Copy Rate is required.",
                maxlength: "Copy Rate Field not be upto 25 characters."
            },
            postage: {
                patterns: "Please enter valid Postage.",
                required: "Postage is required.",
                maxlength: "Postage Field not be upto 25 characters."
            },
            web_amount: {
                patterns: "Please enter valid Exchange.",
                required: "Exchange is required.",
                maxlength: "Exchange Field not be upto 25 characters."
            },
            cd_charge: {
                patterns: "Please enter valid  CDcharge.",
                required: "CDcharge is required.",
                maxlength: "CDcharge Field not be upto 25 characters."
            },
            late_appear: {
                patterns: "Please enter valid Late Rate.",
                required: "Late Rate is required.",
                maxlength: "Late Rate Field not be upto 25 characters."
            },
            no_min_taken_charge: {
                patterns: "Please enter numeric value.",
                required: "Charge is required.",
                maxlength: "Charge not be upto 25 characters."
            },
            min_invoice_charge: {
                patterns: "Please enter numeric value.",
                required: "Charge is required.",
                maxlength: "Charge not be upto 25 characters."
            },
            word_indexing_flat_rate: {
                patterns: "Please enter numeric value.",
                required: "Rate is required.",
                maxlength: "Rate not be upto 25 characters."
            },
            word_indexing_page_rate: {
                patterns: "Please enter numeric value.",
                required: "Rate is required.",
                maxlength: "Rate not be upto 25 characters."
            },
            mini_script_flat_rate: {
                patterns: "Please enter numeric value.",
                required: "Rate is required.",
                maxlength: "Rate not be upto 25 characters."
            },
            SOR: {
                patterns: "Please enter numeric value.",
                required: "SOR is required.",
                maxlength: "SOR not be upto 25 characters."
            },
            SORr: {
                patterns: "Please enter numeric value.",
                required: "SORr is required.",
                maxlength: "SORr not be upto 25 characters."
            },
            Room: {
                patterns: "Please enter numeric value.",
                required: "Room is required.",
                maxlength: "Room not be upto 25 characters."
            },
            mini_script_per_page_rate: {
                patterns: "Please enter numeric value.",
                required: "Rate is required.",
                maxlength: "Rate not be upto 25 characters."
            },
            e_transcript: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },

            med_rate: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            exp_rate: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            daily_rate: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            immed_rate: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            charge_2: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            location_id: {
                required: "Location id required.",
            },
            rateName: {
                required: "Rate Name required.",
            },
            EntityType: {
                required: "Entity Type required.",
            },
            admin_fee: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            web_charge_per_witness: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            video_conference_handling_and_connection_fee: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            reminder_charge: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            legalview_charge: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            deposition_insights_charge1: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            deposition_insights_charge2: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            deposition_insights_charge3: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            digitization_charge: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },
            exhibit_charge: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            },  
            common_validate_number: {
                patterns: "Please enter numeric value.",
                required: "Field is required.",
                maxlength: "Field not be upto 25 characters."
            }
        };
        return rateFactory;
    }]);
