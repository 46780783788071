angular.module('deitz').controller('editClaimrepController', [
    '$scope', '$rootScope', '$state', 'codeFactory', 'commonFactory', 'Notification',

    function($scope, $rootScope, $state, codeFactory, commonFactory, Notification
    ){

        $scope.claim_rep = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Claim Representative';
        $scope.bread_title = "Edit insurance company";
        $scope.claim_rep.auto_touch = 'N';

        //Edit Mode of claim_rep.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/claimrepresentative/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.claim_rep) {
                        $scope.claim_rep = response.result.claim_rep || "";
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of claim_rep
        $scope.addClaimrep = function(claim_rep, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/claimrepresentative/' + id, $scope.claim_rep)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {

                                Notification.success('Claim Representative updated successfully');
                                // $state.go("code_tables.claim_rep_browse");
                                $state.go("insurance.claim_rep_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
    }]);
