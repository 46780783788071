angular.module('deitz').controller('invoiceProfitReportController', [
    '$scope', '$state', '$rootScope', '$http', 'apiUrl', 'Notification', 'commonFactory', '$window',
    function (
        $scope, $state, $rootScope, $http, apiUrl, Notification, commonFactory, $window
    ) {

        $scope.report = {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            billerIds: []
        };
        $scope.selectedFirms = [];
        $scope.selectedJobs = [];
        $scope.invoices = [];

        //get business units
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        } else {
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }


        $http.get(apiUrl + '/api/getBusinessUnit').then(function (response) {
            $scope.BusinessUnitsList = response.data.result.data;
            $scope.BusinessUnitsList.unshift({ name: 'All' });

        });

        $scope.report.totalItems = 0;
        $scope.report.currentPage = 1;
        $scope.report.limit = 10;
        $scope.report.skip = 0;

        $scope.searchData = function () {
            $rootScope.showLoader(true);

            $scope.report.skip = ($scope.report.currentPage - 1) * $scope.report.limit;

            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function (a) {
                return a.id;
            });

            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.jobNos = $scope.selectedJobs.map(function (a) {
                return a.JOB_NO;
            });

            $rootScope.showLoader(true);
            commonFactory.post('/api/get_invoice_profit_report', $scope.report)
                .success(function (response) {

                    $rootScope.showLoader(false);
                    if (response.error) {
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else {
                        $scope.invoices = response.invoices;
                        $scope.report.totalItems = response.count;
                        if ($scope.invoices.length == 0) {
                            Notification.error('No records found!');
                        }
                        else {
                            $scope.calculateData();
                        }

                    }
                })
                .error(function (err) {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        $scope.calculateData = function (invoice) {

            angular.forEach($scope.invoices, function (invoice, key) {

                // calculate witness bill amount
                invoiceWitnessCharge = {};
                invoiceAddCharges = {};
                resourcePayrollCharges = [];
                totalPayAmount = 0;

                angular.forEach(invoice.invoice_line_items, function (line_item, line_item_key) {
                    if (line_item.CHG_TYPE == 'T') {
                        var index = invoice.diary.witness.findIndex(function (o) {
                            return (o.id == line_item.witness_id)
                        });
                        invoiceWitnessCharge[line_item.witness_id] = {
                            'units': line_item.TOTAL_PAGES,
                            'billing_rate': line_item.charge,
                            'bill_amount': line_item.AMT,
                            'pay_rate': 0,
                            'pay_amount': 0,
                            'desc': invoice.diary.witness[index].NAME
                        }
                    }
                    if (line_item.CHG_TYPE == 'X') {
                        invoiceAddCharges[line_item.invchgID] = {
                            'units': line_item.QTY,
                            'billing_rate': line_item.AMT,
                            'bill_amount': line_item.charge,
                            'pay_rate': 0,
                            'pay_amount': 0,
                            'desc': line_item.DESC
                        }
                    }
                });

                // calculate witness pay amount

                angular.forEach(invoice.job_payroll, function (job_payroll, job_payroll_key) {

                    if (job_payroll.resource_payroll_witnesses) {

                        angular.forEach(job_payroll.resource_payroll_witnesses, function (resource_witness, resource_witness_key) {
                            if (invoiceWitnessCharge[resource_witness.witness_id]) {
                                invoiceWitnessCharge[resource_witness.witness_id]['pay_amount'] += parseFloat(resource_witness.line_pay_amt);
                                invoiceWitnessCharge[resource_witness.witness_id]['pay_rate'] += parseFloat(resource_witness.line_rate);
                            }
                            else {
                                var index = invoice.diary.witness.findIndex(function (o) {
                                    return (o.id == resource_witness.witness_id)
                                });
                                invoiceWitnessCharge[resource_witness.witness_id] = {
                                    'desc': invoice.diary.witness[index].NAME,
                                    'units': 0,
                                    'billing_rate': 0,
                                    'bill_amount': 0,
                                    'pay_rate': 0,
                                    'pay_amount': resource_witness.line_pay_amt
                                }
                            }
                        });
                    }

                    if (job_payroll.typist_payroll_witnesses) {
                        angular.forEach(job_payroll.typist_payroll_witnesses, function (typist_witness, typist_witness_key) {
                            if (invoiceWitnessCharge[typist_witness.witness_id]) {
                                invoiceWitnessCharge[typist_witness.witness_id]['pay_amount'] += parseFloat(typist_witness.line_pay_amt);
                            }
                            else {
                                var index = invoice.diary.witness.findIndex(function (o) {
                                    return (o.id == typist_witness.witness_id)
                                });
                                invoiceWitnessCharge[typist_witness.witness_id] = {
                                    'desc': invoice.diary.witness[index].NAME,
                                    'units': 0,
                                    'billing_rate': 0,
                                    'bill_amount': 0,
                                    'pay_rate': 0,
                                    'pay_amount': typist_witness.line_pay_amt
                                }
                            }
                        });
                    }

                    if (job_payroll.REP_APPEAR > 0) {
                        resourcePayrollCharges.push({
                            'units': 0,
                            'billing_rate': 0,
                            'bill_amount': 0,
                            'pay_rate': 0,
                            'pay_amount': job_payroll.REP_APPEAR,
                            'desc': 'Total Appearance Fee'
                        })
                    }
                    if (job_payroll.Reimbursement > 0) {
                        resourcePayrollCharges.push({
                            'units': 0,
                            'billing_rate': 0,
                            'bill_amount': 0,
                            'pay_rate': 0,
                            'pay_amount': job_payroll.Reimbursement,
                            'desc': 'Adjustment'
                        })
                    }

                    if (job_payroll.ded_per_wit > 0 && !job_payroll.typist_id) {

                        var deduction = $scope.roundOff(job_payroll.ded_per_wit * job_payroll.resource_payroll_witnesses.length, 2);

                        resourcePayrollCharges.push({
                            'units': 0,
                            'billing_rate': 0,
                            'bill_amount': 0,
                            'pay_rate': 0,
                            'pay_amount': '-' + deduction,
                            'desc': 'Deduction'
                        })
                    }
                    totalPayAmount += parseFloat(job_payroll.REP_PAYAMT + job_payroll.TYP_PAYAMT)

                });

                $scope.invoices[key].invoiceWitnessCharge = invoiceWitnessCharge;
                $scope.invoices[key].invoiceAddCharges = invoiceAddCharges;
                $scope.invoices[key].resourcePayrollCharges = resourcePayrollCharges;
                $scope.invoices[key].totalPayAmount = totalPayAmount

            });
        }

        $scope.roundOff = function (input, limit) {
            var output = "";
            if (!limit || (limit < 0)) {
                limit = 0;
            }
            var fraction = Math.pow(10, limit);
            var output = Math.round(fraction * Number(input)) / fraction;
            return output;
        };

        //Getting Attorneys list
        $scope.attorneyslist = function (val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function (response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        //Getting Job list
        $scope.jobList = function (val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function (response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };
    }]);
