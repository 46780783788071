angular.module('deitz').controller('claimrepnameController', [
    '$scope', '$rootScope', 'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', 'invoiceFactory', '$compile', '$uibModal', '$state', '$filter',
    function(
        $scope, $rootScope, DTOptionsBuilder, DTColumnBuilder, commonFactory, invoiceFactory, $compile, $uibModal, $state, $filter
    ){

        $scope.dtInstance = {};

        $scope.claimrepModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/insurance/claim_rep_name/browse/claim_rep_modal.html',
                controller: 'addClaimrepnameController',
                resolve: {

                }
            });
        };

        $scope.editclaimrepModal = function(data, index) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/insurance/claim_rep_name/add/claim_rep_modal.html',
                controller: 'editClaimrepnameController',
                resolve: {
                    claim_rep_data: function claimrepData() {
                        return data;
                    },
                    loadPlugin:function($ocLazyLoad){
                        return $ocLazyLoad.load([
                            {
                                name: 'ngPatternRestrict',
                                files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                            }]);
                    }
                },
                size: 'md',
                windowClass: 'middle-width-model',
            });
        };

        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.excludeZeroBalDue = null;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('stateSave',true)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip   : aoData[3].value,
                    limit  : aoData[4].value,
                    order  : aoData[2].value,
                    search : aoData[5].value,
                    excludeZeroBalDue : $scope.excludeZeroBalDue,
                    where : ($state.params.id) ? $state.params.id : null
                };

                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/claimrepresentativename', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'ClaimrepnameDetails' },
                { extend: 'pdf', title: 'ClaimrepnameDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');
                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('ClaimRepFirstName').withOption('defaultContent', '').withTitle('First Name'),
            DTColumnBuilder.newColumn('ClaimRepLastName').withOption('defaultContent', '').withTitle('Last Name').notSortable(),
            DTColumnBuilder.newColumn('CR_CompanyName').withOption('defaultContent', '').withTitle('Company Name'),
            DTColumnBuilder.newColumn('CR_Address1').withOption('defaultContent', '').withTitle('Address1').notSortable(),
            DTColumnBuilder.newColumn('CR_City').withOption('defaultContent', '').withTitle('City').notSortable(),
            DTColumnBuilder.newColumn('CR_State').withOption('defaultContent', '').withTitle('State').notSortable(),
            DTColumnBuilder.newColumn('CR_ZipCode').withOption('defaultContent', '').withTitle('Zipcode').notSortable(),
            DTColumnBuilder.newColumn('CLM_BAL_DUE').withOption('defaultContent', '').withTitle('Bal Due').renderWith(function(data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
               if (data && data.ClaimRepID) {
                    var button = '<div class="btn-group btn-sm" uib-dropdown>'+
                                '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle >Action<span class="caret"></span></button>'+
                                '<ul role="menu" uib-dropdown-menu="" style="left: -86px!important;">'+
                                 '<li uib-tooltip="Edit">'+
                                    '<a class="btn btn-xs btn-default" ui-sref="insurance.claim_rep_name_edit({id :'+ data.ClaimRepID +'})"><i class="fa fa-pencil"></i> &nbsp; Edit </a>'+
                                '</li>'+
                                '<li uib-tooltip="INVOICES">'+
                                    '<a class="btn btn-xs btn-default" ng-click="showClaimRepInvoices(\''+data.ClaimRepID+'\' ,\''+escape(data.claimRepName)+'\')"><i class="fa fa-envelope-o"></i> &nbsp; Invoices </a>'+
                                '</li>'+
                                '<li uib-tooltip="Statements">'+
                                    '<a class="btn btn-xs btn-default"  ui-sref="statements.print_statements({clmId:'+data.ClaimRepID+'})"><i class="fa fa-book"></i> &nbsp; Statements </a>'+
                                '</li>'+ 
                                '<li uib-tooltip="A/R Notes">' +
                                    '<a class="btn btn-xs btn-default" ui-sref="accountReceivableNotes.browse({clmId:'+data.ClaimRepID+'})"><i class="fa fa-pencil-square-o"></i> &nbsp; A/R Notes </a>'+
                                '</li>'+
                                '</ul></div>';
                    return button;            
                    
                }

            })
        ];

        $scope.showClaimRepInvoices = function(claim_rep,claim_rep_name){ 
            invoiceFactory.searchData = {
                searchValue : claim_rep,
                selectedValue : "ClaimRepID",
                claimRepName: unescape(claim_rep_name)
            };

            $state.go('invoice.browse');
        };
    }]);