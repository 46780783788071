angular.module('deitz').
	controller('statementMessageController',[
		'$scope',
		'$rootScope',  
		'$state',
        '$http',
        'apiUrl',
        'Notification', 
		function(
			$scope,
			$rootScope, 
			$state,
            $http,
            apiUrl,
            Notification
				){
        
        $scope.formData = {};
        $scope.title = $state.params.id ? 'Edit': 'Add';
        $scope.formSubmit = false;
        if($state.params.id){
            $rootScope.showLoader(true);
            $http.post(apiUrl+'/api/edit-statement-message',{id:$state.params.id}).success(function(res){
                
                $rootScope.showLoader(false);
                if(res.error){
                    Notification.error(res.result.message);
                }else{ 
                    $scope.formData = res.result.data;
                }
            });
        }
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });
        $scope.save = function(valid){
            $scope.formSubmit = true; 
            if(valid){
                $rootScope.showLoader(true);
                if($state.params.id){
                    $http.post(apiUrl+'/api/update-statement-message',$scope.formData).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            $state.go('statement_message.browse'); 
                            Notification.success(res.result.message);
                        }
                    });
                }else{
                    $http.post(apiUrl+'/api/store-statement-message',$scope.formData).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            $state.go('statement_message.browse'); 
                            Notification.success(res.result.message);
                        }
                    });
                }
            }
        } 
}]);