angular.module('deitz')
    .controller('assignServiceRequestController',[
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        'JOB',
        '$filter',
        'formDate',
        function(
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            JOB,
            $filter,
            formDate
            ) {

            //$scope.title = "Assign "+ resource_type +" for JOB "+ JOB;
            $scope.title = "Assign Service Request for JOB "+ JOB;
            $scope.resource_type_ids = [];
            $scope.cancel = function(isChanged) {
                $uibModalInstance.dismiss({ isChanged : isChanged, job_status_id : $scope.job_status_id});
            };
            commonFactory.get('/api/additionalinfo_list').then(function(response) { 
                $scope.additionalinfo_list = response.data.result.data;  
            });
            $scope.submit= function(){

                if ( $scope.resource_type_ids.length) {
                    $rootScope.showLoader(true);

                    commonFactory.post('/api/assignServiceRequest', {JOB_NO:JOB, resource_type_ids: $scope.resource_type_ids})
                        .success(function (response) {

                            if(!response.error){
                                $scope.saveAnyways = false;
                                $uibModalInstance.dismiss({ isChanged : true, service_request:response.service_request});
                                Notification.success('Service Request has been assigned successfully.');
                            } else{
                                
                                    $rootScope.showLoader(false);
                                    Notification.error(response.message || 'Something went wrong!');
                                
                            }

                        })
                        .error(function (err) {
                            Notification.error(response.message || 'Something went wrong!');
                            $rootScope.showLoader(false);
                        });
                }
            };

        }]);
        
