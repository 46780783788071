angular.module('deitz').controller('addprioragencyController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.prioragency = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add PriorAgency';
        $scope.modalTitle = 'Add plaintiff/defendant'
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $scope.addPriorAgency = function(prioragency, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/prioragency', $scope.prioragency)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("PriorAgency added successfully");

                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.prioragency.id);
                                }else{
                                    $state.go("code_tables.prioragency_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
