angular.module('deitz').controller('ReportModalController', function ($scope, reportData) {
    $scope.report = reportData;
    
    $scope.getTableOptions = function(unit) {
        return {
            paging: true,
            // Add other DataTables options as needed
        };
    };
    $scope.getGrandTotal = function () {
        var grandTotal = 0;
        angular.forEach($scope.report, function (unit) {
            grandTotal += parseFloat(unit.total_amount);
        });
        return grandTotal.toFixed(2); // Round to 2 decimal places
    };

    
});
