angular.module('deitz').controller('addsuitesController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',
    function(
        $scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ) {

        $scope.our_suite = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Our Location';
        //Getting all locations from locations table.
        commonFactory.get('/api/getLocation')
        .success(function(response) {
            if (response.result && response.result.data) {
                $scope.locations = response.result.data || '';
            }
        })
        .error(function(err) {});
        commonFactory.get('/api/connection-methods')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.connectionMethods = response.result.resources;
            }
        });

        $scope.addOursuite = function(our_suite, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/suites', $scope.our_suite)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Oursuite added successfully");
                                $state.go("code_tables.suites_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
