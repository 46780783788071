/**
 * Created by agile-54 on 26-Jun-17.
 */
angular.module('deitz')
    .factory('invoiceFactory', ['$http', 'apiUrl', function($http, apiUrl) {
        var invoiceFactory = {};

        invoiceFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        invoiceFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        invoiceFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        invoiceFactory.delete = function(url) {
            return $http.delete(apiUrl + url);
        };

        invoiceFactory.searchInvoiceNumber = function(url,query){
            const  data = {search : query};
            return $http.post(apiUrl + url, data);
        };

        invoiceFactory.showDataTable = function(url,data){
            return $http({
                method: 'POST',
                url: apiUrl + url,
                data: $.param(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
        };

        invoiceFactory.sendInvoice = function(url, data){
            return $http.post(apiUrl + url, data);
        }

        invoiceFactory.validations = {
            selectedValue: {
                required: true,
            },
            searchValue: {
                maxlength: 254,
                required: true,
            }
        };

        invoiceFactory.messages = {

            selectedValue: {
                required: "Select option to find an invoice.",
            },
            searchValue: {
                required: "Value is required.",
                maxlength: "Value Field not be upto 254 characters."
            }
        };

        invoiceFactory.searchData = [];

        return invoiceFactory;
    }]);