angular.module('deitz').controller('editsuitesController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',
    function(
        $scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ){

        $scope.our_suite = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Our Location';
        //Getting all locations from locations table.
        commonFactory.get('/api/connection-methods')
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.connectionMethods = response.result.resources;
            }
        });
        commonFactory.get('/api/getLocation')
        .success(function(response) {
            if (response.result && response.result.data) {
                $scope.locations = response.result.data || '';
            }
        })
        .error(function(err) {});
        //Edit Mode of our_suite.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/suites/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.our_suites) {
                        $scope.our_suite.held_at = response.result.our_suites.held_at || '';
                        $scope.our_suite.city = response.result.our_suites.city || '';
                        $scope.our_suite.state = response.result.our_suites.state || '';
                        $scope.our_suite.zipcode = response.result.our_suites.zipcode || '';
                        $scope.our_suite.phone_number = response.result.our_suites.phone_number || '';
                        $scope.our_suite.address1 = response.result.our_suites.address1 || '';
                        $scope.our_suite.address2 = response.result.our_suites.address2 || '';
                        $scope.our_suite.location_id = response.result.our_suites.location_id || '';
                        $scope.our_suite.held_at_flag = response.result.our_suites.held_at_flag || '';
                        $scope.our_suite.is_lexitas_location = response.result.our_suites.is_lexitas_location || '0';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of our_suite
        $scope.addOursuite = function(our_suite, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/suites/' + id, $scope.our_suite)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Oursuite updated successfully");
                                $state.go("code_tables.suites_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
