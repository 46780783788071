angular.module('deitz').factory('RolesFactory', ['$http', '$q', '$cookies', 'apiUrl', function ($http, $q, $cookies, apiUrl) {
        var RolesFactory = {};

        RolesFactory.validation = {
            name: {required: "Role name is required", minlength : "Role name is too short", maxlength : "Role name is too long"},
            description: {required: "Role description is required",minlength : "Role description is too short", maxlength : "Role description is too long"}
        };

        RolesFactory.getAllRole = function () {
            return $http.get(apiUrl + "/api/roles");
        };

        RolesFactory.getRole = function (role_id) {
            return $http.get(apiUrl + "/api/roles/" + role_id + "/edit");
        };

        RolesFactory.getRolePermisssions = function (id) {
            return $http.post(apiUrl + "/api/get-role-permission", {id: id});
        };

        RolesFactory.addRole = function (data) {
            return $http.post(apiUrl + "/api/roles", data);
        };

        RolesFactory.updateRole = function (id, data) {
            return $http.put(apiUrl + "/api/roles/" + id, data);
        };


        return RolesFactory;
    }]);