angular.module('deitz').factory('chargeFactory', ['$http', 'apiUrl', function($http, apiUrl) {

    var chargeFactory = {};

    //Include validation helper
    var vh = validation_helper();

    chargeFactory.validations = {
        label: {
            maxlength: 100,
            required: true,
        },
        description: {
            maxlength: 254,
            required: true
        }
    };

    chargeFactory.messages = {
        label: {
            required: "Title is required.",
            maxlength: "Title not be upto 100 characters."
        },
        description: {
            maxlength: "Description must be at least 6 characters.",
            required: "Description is required."
        }
    };

    return chargeFactory;
}]);
