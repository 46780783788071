angular.module('deitz')
    .controller('usersController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'PermissionFactory',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'UsersFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        'RolesFactory',
        function ($scope,
            $rootScope,
            $http,
            apiUrl,
            PermissionFactory,
            DTOptionsBuilder,
            DTColumnBuilder,
            UsersFactory,
            $compile,
            $uibModal,
            Notification,
            $filter,
            RolesFactory
        ) {

            $scope.userData = [];
            $scope.loadingDemo = [];
            $scope.dtInstance = {};
            $scope.roles = [];
            $scope.selectedRoleIDs = [];
            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            }else{
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }
           
            $scope.includeActive = true
            RolesFactory.getAllRole().success(function (res) {
                if (res) {
                    $scope.roles = res;
                }
            }).error(function (err) {
                $scope.roles = [];
            });

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);
            };
            // $http.get(apiUrl + '/api/get-user-roles-businessunit').then(function(response) { 
            //     response.data.data.forEach(function (e) {
            //         $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            //         });
            // });
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});
    
            });
            $scope.roleRender = function (data, type, full) {
                // console.log(full.role)
                data = full.role
                if (data) {
                    var role = data.split('||');
                    return "<a href='javascript:void(0);' ng-click='getPermissions(" + role[0] + ")'>" + role[1] + "</a>";
                } else {
                    return "-";
                }
            };

            $scope.getPermissions = function (roleId) {
                $uibModal.open({
                    templateUrl: 'modules/resources/templates/show_permission_template.html',
                    scope: $scope,
                    controller: ['$scope', 'RolesFactory', 'id', '$uibModalInstance', function ($scope, RolesFactory, id, $uibModalInstance) {
                        $scope.role = {};
                        $scope.uibModalInstance = $uibModalInstance;
                        RolesFactory.getRolePermisssions(id).success(function (response) {
                            $scope.role.permissions = response;
                        }).error(function (error) {

                        });
                    }],
                    resolve: {
                        id: function depFactory() {
                            return roleId;
                        }
                    }
                });
            };

            $scope.actionRoles = function (data, type, full) {

                var action= "<a class='btn btn-primary btn-circle' ui-sref='users.edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";
                if($scope.Authuser.id != full.id){
                    // action += " <a class='btn btn-danger btn-circle' ng-if='" + !full.deleted_at + "' ng-click='deleteUser(" + full.id + ")'><i class='fa fa-trash'></i></a>";

                    $scope.userData[full.id] = full;
                    $scope.loadingDemo[full.id]={};
                    $scope.loadingDemo[full.id].loading = false;
                    var tooltip = (full.active == 'Y') ? "Active" : "Inactive";

                    action +=" <button ng-disabled='!havePermission(\"users\",\"update\")' ladda=\"loadingDemo['"+full.id+"'].loading\" class='ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm' ng-class=\"{'btn-success': '" + full.active + "' == 'Y', 'btn-danger': '" + full.active + "' == 'N'}\" ng-click='changeActiveStatus(\"" + full.id + "\")'>" + tooltip + "</button>";

                }
                return action;
            };

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };

            $scope.clear = function(){
                $scope.selectedRoleIDs = [];
                $scope.reloadData();
            }

            $scope.resetSearch = function(){
                $scope.selectedRoleIDs = [];
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('lengthMenu', [100, 150, 200])
                .withDisplayLength(100) 
                .withOption('ajax', function (data, callback, settings) {
                    
                    data.includeActive = $scope.includeActive ? $scope.includeActive : false;
                    data.includeInactive = $scope.includeInactive ? $scope.includeInactive : false;
                    data.selectedRoleIDs = $scope.selectedRoleIDs;
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                    // map your server's response to the DataTables format and pass it to
                    UsersFactory.showDataTable(data).success(function (res) {
                        // DataTables' callback 
                        callback(res);
                    }).error(function (err) {
                        if (err.error)
                            $scope.reloadData();
                    });

                })
                .withOption('processing', true)
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500)
                .withOption('order', [0, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withDOM('<"html5buttons"B>lTfgitp')
                .withButtons([
                    { extend: 'copy' },
                    { extend: 'csv' },
                    { extend: 'excel', title: 'UserDetails' },
                    { extend: 'pdf', title: 'UserDetails' },
                    { extend: 'print',
                        customize: function(win) {
                            $(win.document.body).addClass('white-bg');
                            $(win.document.body).css('font-size', '10px');
                            $(win.document.body).find('table').addClass('compact').css('font-size', 'inherit');
                        }
                    }
                ]);

            $scope.dtColumns = [
                DTColumnBuilder.newColumn('name').withTitle('Name').withOption('searchable', true).withOption('createdCell', function (cell, cellData, rowData, row, col) { 
                    $(cell).text(cellData);
                }),
                DTColumnBuilder.newColumn('email').withTitle('Email').withOption('searchable', true),
                DTColumnBuilder.newColumn('created_at').withTitle('Created Date').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return moment(data).format('MM/DD/YYYY');
                    }
                    else {
                        return '-' 
                    } 
                }),
                DTColumnBuilder.newColumn('roles.slug').withTitle('Default Role').withOption('searchable', true).renderWith($scope.roleRender),
                DTColumnBuilder.newColumn('businessunit').withTitle('Default Business Unit').withOption('searchable', false).notSortable(),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles)
            ];

            $scope.deleteUser = function(user_id){
                swal({
                    title: "Are you sure ?",
                    text: "Are you sure want to delete this user",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    // Clear the Batch
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        UsersFactory.deleteUser(user_id).success(function (res) {
                            $rootScope.showLoader(false);
                            // DataTables' callback
                            Notification.success(res.message);
                            $scope.reloadData();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.message);
                            $scope.reloadData();

                        });
                    }
                });
            }

            $scope.changeActiveStatus = function (user_id) {
                $scope.loadingDemo[user_id].loading = true;
                var newStatus = $scope.userData[user_id].active == 'Y' ? 'N' : 'Y';
                var data = {id : user_id, active : newStatus};
                UsersFactory.changeActiveStatus(data).success(function(){
                    $scope.loadingDemo[user_id].loading = false;
                    $scope.reloadData();
                }).error(function(){
                    $scope.reloadData();
                });
            };

        }]);