angular.module('deitz').controller('editdepartmentController', [
    '$scope', '$http', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'apiUrl', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function($scope, $http, $state, $rootScope, codeFactory, commonFactory, apiUrl, Notification, fromModal, plaintiffId, $uibModalInstance) {

        $scope.businessunit = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Business Unit';

        $scope.department = {}; 

        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/department/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.departmentid) {
                        $scope.department.departmentid = response.result.departmentid || '';
                        $scope.region = response.result.region || '';
                        $scope.legacy_name = response.result.legacy_name || '';
                        $scope.department.business_unit = response.result.business_id || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        // Update Mode of businessunit
        $scope.addDepartment = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = plaintiffId ? plaintiffId : $state.params.id;

                var submit_data = {
                    departmentId: $scope.department.departmentid,
                    businessUnit: $scope.department.business_unit,
                    region: $scope.region,
                    legacyName: $scope.legacy_name
                };

                commonFactory.put('/api/department/' + id, submit_data)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.status == 200) {
                            Notification.success("Department updated successfully");
                            $state.go("code_tables.department_browse");
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }
]);
