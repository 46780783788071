angular.module('deitz')
    .controller('nyctaStatementController', [
        '$scope', 
        '$rootScope', 
        '$http',
        'apiUrl',
        'Notification',
        '$timeout',
        '$stateParams',
        '$state',
        'configFactory',
        'commonFactory',
        '$window',
        '$uibModal',
        '$cookies',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            Notification,
            $timeout,
            $stateParams,
            $state,
            configFactory,
            commonFactory,
            $window,
            $uibModal,
            $cookies
        ) {

        $scope.currentDate = moment().format('MM/DD/YYYY');
        $scope.isOnlyNYCTAGroupSelected = false;
        $scope.formData = {
            includeInvoices : false,
            selectedAttorneys : [],
            excludeBilledToIds : [],
            filterDate : false,
            includeExcludeBilledToIds : 'include',
            dateRange : {
                endDate : moment().format('MM-DD-YYYY'),
                startDate : moment().format('MM-DD-YYYY')
            }
        };
        $scope.isPay = false;

        //get configuration for tax id
        configFactory.getConfiguration({ keys: ['tax_id'] }).then(function (response) {
            $scope.tax_id = response.data.configurations.tax_id;
        });
        
        // get nycta setup data   
        commonFactory.get('/api/get-NYCTA-setup-data').success(function (resp) { 
            $scope.nyctaSetUp = resp.result;
            $scope.nyctaSetUp.statement_date = moment(resp.result.statement_date).format('MM/DD/YYYY');
        },function(err){
            Notification.error(err);
        }); 

        // get firms groups
        $rootScope.showLoader(true);
        commonFactory.get('/api/get-firm-groups')
        .success(function(response) {
            $rootScope.showLoader(false);
            $scope.firmGroups = response.result;
        })
        .error(function(err) {
            $rootScope.showLoader(false);
        });

        $scope.clearStatements = function (){
            $scope.formData = {
                includeInvoices : false,
                selectedAttorneys : [],
                excludeBilledToIds : [],
                filterDate : false,
                includeExcludeBilledToIds : 'include',
                dateRange : {
                    endDate : moment().format('MM-DD-YYYY'),
                    startDate : moment().format('MM-DD-YYYY')
                }
            };
            $scope.Statements = null;
            $scope.billToList = [];
            $scope.tagRemoved();
        };

        //Getting Attorneys list
        $scope.attorneysList = function(val) {
            $scope.searchingAtt = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $scope.searchingAtt = false;
                return response.data.result.map(function(item) {
                    var department = item.department_name ? ' || '+ item.department_name : '';
                    item.NAME = item.NAME + department;
                    return item;
                });
            });
        };

        /* On select / Remove any claimrep/claim representative, set flag to false. It will hide print, fax, email buttons.*/
        $scope.tagRemoved = function(){
            $scope.isStatmentSearched = false;
        };

        $scope.dateFormat = function(date){
            return  date ? moment(date).format('MM/DD/YYYY hh:mm:ss a') : '--/--/----';
        };

        $scope.includeInvoices = function(){
            $rootScope.showLoader(true);

            $timeout(function() {
                $rootScope.showLoader(false);
            }, 1000);   
        };

        $scope.selectAllBillTo = function(){
            $rootScope.showLoader(true);
            $scope.formData.excludeBilledToIds = [];
            angular.forEach($scope.billToList,function(value, key){
                $scope.formData.excludeBilledToIds.push(value.id);
            });
            $rootScope.showLoader(false);
        };

        $scope.getInvoiceNumber = function(summary){
            var monthYear = moment($scope.nyctaSetUp.statement_date).format('MM-YYYY');
            var invoice_no = summary.invoice_no_prefix ? summary.invoice_no_prefix : ' ';
            return invoice_no+monthYear;
        };

        $scope.getTotalSummaryBalanceDue = function(){
            var summaryBalDue = 0;
            angular.forEach($scope.Statements, function(value, key){
                summaryBalDue += value.aging.balDue;
            });
            return summaryBalDue;
        };

        $scope.getBilledFromDate = function(){
            return moment($scope.formData.dateRange.startDate).format('MM/DD/YYYY')+' - '+ moment($scope.formData.dateRange.endDate).format('MM/DD/YYYY');
        };

         /* Getting statements data to display on the web page*/
        $scope.searchStatementData = function(valid){
            if(valid){

                $scope.tagRemoved();
                var ids = [];    
                angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
                   ids[key] = value.id;
                });

                $rootScope.showLoader(true);
                $http.post(apiUrl + '/api/get-NYCTA-statement-data', { 
                        ids: ids , 
                        excludeBilledToIds : $scope.formData.excludeBilledToIds,
                        filterDate : $scope.formData.filterDate,
                        includeExcludeBilledToIds : $scope.formData.includeExcludeBilledToIds,
                        startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'), 
                        endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD') 
                    }).then(function(response) {

                    $rootScope.showLoader(false);
                    $scope.Statements = response.data.result;
                    $scope.billToList = response.data.billToList;                        
                    
                    /*Display pay button based on condition */                    
                    if($scope.Statements.length > 0 && ids.length === 1){
                        $scope.isPay = true;
                    } else {
                        $scope.isPay = false;
                    } 

                    if($scope.Statements.length){
                        angular.forEach($scope.Statements, function(value,key){ 
                            if(value.invoice.length){ 
                                $scope.isStatmentSearched = true;
                            }
                        });                         
                    }
                    if(!$scope.isStatmentSearched){ 
                        Notification .error('Statements Not Found');
                    } 
                });
            }
        };

        $scope.groupChange = function(){
            
            if($scope.formData.selectedGroups.length == 1 && $scope.formData.selectedGroups[0].group_name.toLowerCase().indexOf('nyct') != -1){
                $scope.isOnlyNYCTAGroupSelected = true;
            }else{
                $scope.isOnlyNYCTAGroupSelected = false;
            }

            $scope.tagRemoved();
            var groups = [];    
            angular.forEach($scope.formData.selectedGroups, function(value, key) {
               groups[key] = value.group_name;
            });
            
            // get firms of selected groups
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.formData.selectedAttorneys = response.result;
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        };

        $scope.printStatements = function (divName) {

            var printContents = $("#" + divName);
            printContents = printContents.html();
            var popupWin = window.open('', '_blank');
            popupWin.document.open();
            popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"></head><body onload="window.print()">' + printContents + '</body></html>');
            popupWin.document.close();
        };

        $scope.downloadExcel = function(){  

            $rootScope.showLoader(true);

            var ids = [];    
            angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
               ids[key] = value.id;
            });

            commonFactory.post('/api/generate-statements-excel-for-groups', {
                ids : ids,
                isOnlyNYCTAGroupSelected : $scope.isOnlyNYCTAGroupSelected,
                excludeBilledToIds : $scope.formData.excludeBilledToIds,
                includeExcludeBilledToIds : $scope.formData.includeExcludeBilledToIds,
                filterDate : $scope.formData.filterDate,
                startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'),
                endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD')
            })
            .success(function(response) {
                $rootScope.showLoader(false);
                if(!response.error){
                    // $window.location.href = apiUrl +'/api/download-statement-for-groups';
                    $window.open(apiUrl +'/api/download-statement-for-groups', '_blank');
                }else{
                    Notification.error(response.result.message);
                }
            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.error(err);
            });
        };

        /* Sending statements via Email/FAX for both claimrep and attorney firm */
        $scope.emailOrFaxStatements = function(via){ 

            var data = angular.copy($scope.formData);
            data.via = via; 
  
            var sendStatementsModal = $uibModal.open({
                templateUrl: "modules/resources/templates/send_statements_modal.html",
                controller: function($scope, $uibModalInstance, $sce, $rootScope, $http, apiUrl){
                    $scope.formData = {};
                    $scope.formData = data;
                    $scope.Authuser = JSON.parse($cookies.get('authUser'));
                    $scope.formData.ccList = $scope.Authuser.email;

                    var fax = $scope.formData.selectedAttorneys[0].FAX;
                    var email = $scope.formData.selectedAttorneys[0].EmailAddress;
                    var name = $scope.formData.selectedAttorneys[0].NAME 

                    $scope.business_unit_id = null;
                    $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                        $scope.BusinessUnitsList = response.data.result.data;  
                    });
                    
                    $scope.buChange = function(){
                        $http.post(apiUrl+'/api/get-fax-email-messages', {'selectedBusinessUnitIDs' : $scope.formData.business_unit_id}).success(function(response){
                            $scope.formData.messages = response.data; 
                        });
                    }

                    $scope.allowOverWrite = false;
                    $scope.title =  $scope.formData.via.toUpperCase() + " STATEMENTS ";
                    $scope.via =  $scope.formData.via == 'email' ? "E-MAIL " : "FAX #";
                    $scope.attOrClmrp =  'ATTORNEY FIRM';

                    /*Check if the only one selection for the claim rep / attorney then we are allowing to overwrite the email/ fax number.*/
                    if($scope.formData.selectedAttorneys.length === 1){
                        $scope.allowOverWrite = true;
                        $scope.formData.inputValue = $scope.formData.via === 'fax' ? fax : email;
                    }
                    $scope.selectMessage = function(messages){
                        $scope.formData.selectedMessage = messages;
                    }
                    $scope.send = function(isValid){
                        if(isValid){
                            $uibModalInstance.close({isSend : true, formData : $scope.formData});  
                        }
                    };

                    $scope.closePopupWindow = function(a){
                        $uibModalInstance.close({isSend : false, formData : {}});
                    };
                },

                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            sendStatementsModal.result.then(function (resp) {
                if(resp.isSend){
                    $scope.sendStatements(resp.formData);
                }
            }, function () {
                
            });
        };

        $scope.sendStatements = function(data){
            
            swal({
                title: "Are you sure ?",
                text: "You are about to send the statements.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Send it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);

                    var ids = []; 
                    angular.forEach(data.selectedAttorneys, function(value, key) {
                       ids[key] = value.id;
                    }); 

                    commonFactory.post('/api/send-group-statements', {
                        ids : ids,
                        via: data.via,
                        inputValue : data.inputValue,
                        business_unit_id:data.business_unit_id,
                        ccList : data.ccList,
                        includeInvoices : data.includeInvoices ? data.includeInvoices : false,
                        needToUpdateProfile : data.needToUpdateProfile ? data.needToUpdateProfile : false,
                        additionalComment : data.selectedMessage,
                        excludeBilledToIds : data.excludeBilledToIds,
                        includeExcludeBilledToIds : data.includeExcludeBilledToIds,
                        isOnlyNYCTAGroupSelected : $scope.isOnlyNYCTAGroupSelected,
                        filterDate : data.filterDate,
                        startDate : moment(data.dateRange.startDate).format('YYYY-MM-DD'), 
                        endDate : moment(data.dateRange.endDate).format('YYYY-MM-DD') 
                    })
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message);
                        } else{
                            Notification.success(response.result.message);
                        }

                    })
                    .error(function(err) {
                        Notification.error("Something went wrong!");
                        $rootScope.showLoader(false);
                    });
                }
            });
        };
    }
]);
