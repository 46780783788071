angular.module('deitz')
    .controller('importAttorneyFirmUsersController', ['$scope', '$rootScope', 'Upload', 'Notification', '$timeout', '$window', 'apiUrl', 'commonFactory', function ($scope, $rootScope, Upload, Notification, $timeout, $window, apiUrl, commonFactory) {
        $scope.formData = {};
        $scope.formSubmit = false;
        $scope.invalidFileType = 'false';
        $scope.log = "";
        $scope.isCloseButton = false;

        $scope.clickUpload = function () {
            $("#import_attorney_firm_users").trigger('click');
        }

        $scope.fileChange = function () {
            $scope.isCloseButton = false;
            $scope.uploadProgress = 0;
            $scope.showProgress = false;
            $scope.log = "";
            var files = $('#import_attorney_firm_users')[0].files[0];
            if (files && files.name) {
                $(".upload_h1").html(files.name + " file selected. <br> press the 'save' button.");
            } else {
                $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
            }
        }

        $scope.uploadProgress = 0;
        $scope.showProgress = false;
        $scope.submitForm = function (isValid) {
            $scope.formSubmit = true;
            $scope.uploadProgress = 0;
            $scope.showProgress = false;
            $scope.log = "";
            if (isValid && $scope.importUsersForm.import_attorney_firm_users.$valid && $scope.import_attorney_firm_users) {
                $scope.showProgress = true;
                var tmpName = $scope.import_attorney_firm_users.name.split('.');
                var fileType = tmpName[tmpName.length - 1];
                if (fileType != 'xls' && fileType != 'xlsx') {
                    $scope.invalidFileType = 'true';
                } else {
                    var loading = $timeout(function () {
                        $scope.$apply();
                    }, 5000);
                    $rootScope.showLoader(true);
                    $scope.invalidFileType = 'false';

                    Upload.upload({
                        url: 'api/import-attorney-firm-users',
                        data: { file: $scope.import_attorney_firm_users }
                    }).then(function (resp) {
                        console.log('Success uploaded. Response: ' + resp.data);
                        $timeout.cancel(loading);
                        $scope.importUsersForm.$setPristine(true);
                        $scope.importUsersForm.$setUntouched(true);
                        $scope.import_attorney_firm_users = "";
                        $rootScope.showLoader(false);

                        /* $scope.log += "<br><br> <b> " + resp.data.message + ". </b> ";
                        if (resp.data.error_log && resp.data.error_log.length > 0) {
                            $scope.log += "<br><br> <b> Some row getting error while creating </b> ";
                            angular.forEach(resp.data.error_log, function (value, key) {
                                $scope.log += "<p class='error-message animated fadeInDown red'> " + value + "</p> ";
                            });
                        } */

                        Notification.success(resp.data.message);
                        // $scope.closePopup();
                    }, function (resp) {
                        $timeout.cancel(loading);
                        console.log('Error: ', resp);
                        $scope.isCloseButton = true;
                        Notification.error(resp.data.message || "Error while importing data!");
                        $rootScope.showLoader(false);
                        if (resp.data.import_error) {
                            angular.forEach(resp.data.error_message, function (value, key) {
                                $scope.log += "<p class='error-message animated fadeInDown red'> Row # " + value.row + ": " + value.errors.map(error => `${error.message}`).join(', ') + "</p>";
                            });
                        } else {
                            $scope.log += "<br><br> <b>  Error while importing data : " + resp.data.message + " </b> <br><br>";
                        }
                    }, function (evt) {
                        $scope.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + $scope.uploadProgress + '% ');
                    });
                }
            }
        };
        $scope.downloadDemoExcel = function () {
            $window.open(apiUrl + '/api/download-import-users-excel-demo', '_blank');
        }

    }]);