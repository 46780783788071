angular.module('deitz')
    .controller('salesPersonController', ['$scope', '$rootScope', '$http', 'apiUrl', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', function($scope, $rootScope, $http, apiUrl, DTOptionsBuilder, DTColumnBuilder, $compile) {
        var url = "/api/salesperson";
        $scope.salesPersonData = {};
        $scope.validations = {
            fname: {
                maxlength: 100,
                required: true,
            },
            lname: {
                maxlength: 100,
                required: true,
            },
            position: {
                maxlength: 100,
                required: true,
            }
        };

        $scope.messages = {
            fname: {
                required: "First Name is required.",
                maxlength: "First Name not be upto 100 characters."
            },
            lname: {
                required: "Last Name is required.",
                maxlength: "Last Name not be upto 100 characters."
            },
            position: {
                required: "Position is required.",
                maxlength: "Position not be upto 100 characters."
            }
        };

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                $rootScope.showLoader(true);
                $http.get(apiUrl + url)
                    .then(function(response) {
                        if (response) {
                            fnCallback({
                                recordsTotal: Object.keys(response.data).length,
                                recordsFiltered: Object.keys(response.data).length,
                                data: response.data
                            });
                        }
                        $rootScope.showLoader(false);
                        $scope.salesData = response.data;
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fn PreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                // { extend: 'copy' },
                // { extend: 'csv' },
                // { extend: 'excel', title: 'JobtypeDetails' },
                // { extend: 'pdf', title: 'JobtypeDetails' }, {
                //     extend: 'print',
                //     customize: function(win) {
                //         $(win.document.body).addClass('white-bg');
                //         $(win.document.body).css('font-size', '10px');

                //         $(win.document.body).find('table')
                //             .addClass('compact')
                //             .css('font-size', 'inherit');
                //     }
                // }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('fname').withOption('defaultContent', '').withTitle('First Name'),
            DTColumnBuilder.newColumn('lname').withOption('defaultContent', '').withTitle('Last Name'),
            DTColumnBuilder.newColumn('position').withOption('defaultContent', '').withTitle('Position'),
            //DTColumnBuilder.newColumn('slug').withOption('defaultContent', '').withTitle('Slug').notSortable(),
            // DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            // .renderWith(function(data, type, full, meta) {
            //     if (data && data.id) {
            //         return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="code_tables.job_type_edit({id : ' + data.id + '})" >' +
            //             '<i class="fa fa-pencil"></i>' +
            //             '</button> &nbsp;';
            //     }

            // })
        ];
    }]);
