angular.module('deitz')
    .factory('firmFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var firmFactory = {};

        function convertParams(params) {
            var reqData = {};
            if (params) {
                reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                reqData.limit = params.limit || 10; // Number of entries showed per page.
                reqData.search = params.search ? params.search.value : '';
                reqData.orderColumn = params.order ? params.order[0].column : '';
                reqData.orderDir = params.order ? params.order[0].dir : '';
                if (params.id) {
                    reqData.id = params.id ? params.id : '';
                }
            }
            return reqData;
        }

        //Include validation helper
        var vh = validation_helper();

        firmFactory.validations = {
            firm_name: {
                maxlength: 254,
                required: true,
            },
            short_name: {
                maxlength: 254,
                required: true,
            },
            address1: {
                patterns: vh.alpha_numeric_spaces.patterns,
                maxlength: 254,
                required: true,
            },
            city: {
                patterns: vh.alpha_spaces.patterns,
                maxlength: 50,
                required: true,
            },
            state: {
                patterns: vh.alpha_spaces.patterns,
                maxlength: 2,
                required: true,
            },
            zip: {
                patterns: vh.zipcode.patterns,
                patternRestrict : vh.zipcode.patternRestrict,
                maxlength: vh.zipcode.maxlength,
                required: true,
            },
            contact: {
                patterns: vh.single_address_line.patterns,
                maxlength: 254,
                required: true,
            },
            business_unit_id: {
                required: true,
            },
            regular_other: {
                required: true,
            },
            appear_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            late_appear: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            copy_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            validate_decimal: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: false,
            },
            min_invoice_charge: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            postage: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            mini_script_flat_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            word_indexing_page_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            location: {
                required: true
            },
            name: {
                maxlength: 50,
                required: true,
            },
            description: {
                maxlength: 180,
                required: true,
            },
            first_name: {
                maxlength: 50,
                required: true,
            },
            last_name: {
                maxlength: 50,
                required: true,
            },
            Email_Address: {
                patterns: vh.email.patterns,
                maxlength: vh.email.maxlength,
                required: false
            },
            phone: {
                patterns: vh.integerwithspe.patterns,
                maxlength: 15,
                required: true
            },
            fax: {
                patterns: vh.integerwithspe.patterns,
                maxlength: 15,
                required: true
            },
            role_id: {
                required: false
            },
            First_Name: {
                maxlength: 50,
                required: true,
            },
            Last_Name: {
                maxlength: 50,
                required: false,
            },

            med_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            exp_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            daily_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            immed_rate: {
                patterns: vh.numwith2decimal.patterns,
                maxlength: 50,
                required: true,
            },
            sales_man: {
                maxlength: 254,
                required: true,
            },
            card_number:{
                maxlength: 254,
                required: true
            },
            cvv_code:{
                required: true
            },
            card_holder_name:{
                required:true
            }

        };

        firmFactory.messages = {
            firm_name: {
                patterns: "Please enter alphabetic value as firm name.",
                required: "Firm name is required.",
                maxlength: "Firm name field not be upto 254 characters."
            },
            short_name: {
                patterns: "Please enter alphabetic value as short name.",
                required: "Short name is required.",
                maxlength: "Short name field not be upto 254 characters."
            },
            address1: {
                patterns: "Please enter alphabetic numeric value as address.",
                required: "Address is required.",
                maxlength: "Address field not be upto 254 characters."
            },
            city: {
                patterns: "Please enter alphabetic value as city name.",
                required: "City name is required.",
                maxlength: "City name field not be upto 50 characters."
            },
            state: {
                patterns: "Please enter alphabetic value as state name.",
                required: "State name is required.",
                maxlength: "State name field not be upto 2 characters."
            },
            zip: {
                patterns: "Zipcode is invalid.",
                required: "Zipcode is required.",
                maxlength: "Zipcode field not be upto 15 characters."
            },
            contact: {
                patterns: "Please enter alpha numeric value as contact.",
                required: "Contact is required.",
                maxlength: "Contact field not be upto 254 characters."
            },
            regular_other: {
              
                required: "Regular/other is required.",
               
            },
            appear_rate: {
                patterns: "Please enter numeric value as appear rate.",
                required: "appear rate is required.",
                maxlength: "appear rate field not be upto 5 characters."
            },
            late_appear: {
                patterns: "Please enter numeric value as late appear rate.",
                required: "Late appear rate is required.",
                maxlength: "Late appear rate field not be upto 5 characters."
            },
            rate: {
                patterns: "Please enter numeric value.",
                required: "Rate is required.",
                maxlength: "Rate field not be upto 5 characters."
            },
            copy_rate: {
                patterns: "Please enter numeric value.",
                required: "Copy rate is required.",
                maxlength: "Copy rate field not be upto 5 characters."
            },
            validate_decimal: {
                patterns: "Please enter numeric value.",
                required: "Copy rate is required.",
                maxlength: "Copy rate field not be upto 5 characters."
            },
            min_invoice_charge: {
                patterns: "Please enter numeric value as minimum invoice charge.",
                required: "Minimum invoice charge is required.",
                maxlength: "Minimum invoice charge field not be upto 5 characters."
            },
            postage: {
                patterns: "Please enter numeric value as postage.",
                required: "Postage is required.",
                maxlength: "Postage field not be upto 5 characters."
            },
            mini_script_flat_rate: {
                patterns: "Please enter numeric value as minimum script flat rate.",
                required: "Minimum script flat rate is required.",
                maxlength: "Minimum script flat rate field not be upto 5 characters."
            },
            word_indexing_page_rate: {
                patterns: "Please enter numeric value as word indexing page rate.",
                required: "Word indexing page rate is required.",
                maxlength: "Word indexing page rate field not be upto 5 characters."
            },
            location: {
                required: "Location is required."
            },
            name: {
                maxlength: "Title not be upto 50 characters.",
                required: "Title is required.",
            },
            description: {
                maxlength: "Description not be upto 180 characters.",
                required: "Description is required.",
            },
            first_name: {
                maxlength: "Firstname not be upto 50 characters.",
                required: "Firstname is required.",
            },
            last_name: {
                maxlength: "Lastname not be upto 50 characters.",
                required: "Lastname is required.",
            },
            Email_Address: {
                patterns: "Enter proper email.",
                maxlength: "Email not be upto 30 characters.",
                required: "Email is required."
            },
            phone: {
                patterns: "Enter only numeric value as phone number.",
                maxlength: "Phone not be upto 15 characters.",
                required: "Phone is required."
            },
            fax: {
                patterns: "Enter only numeric value as Fax number.",
                maxlength: "Fax not be upto 15 characters.",
                required: "Fax is required."
            },
            role_id: {
                required: "Role is required."
            },
            First_Name: {
                maxlength: "Firstname not be upto 50 characters.",
                required: "Firstname is required.",
            },
            Last_Name: {
                maxlength: "Lastname not be upto 50 characters.",
                required: "Lastname is required.",
            },

            med_rate: {
                patterns: "Please enter numeric value as word indexing page rate.",
                required: "Medical charge is required.",
                maxlength: "Medical charge field not be upto 5 characters."
            },
            exp_rate: {
                patterns: "Please enter numeric value as word indexing page rate.",
                required: "Exp charge is required.",
                maxlength: "Exp charge field not be upto 5 characters."
            },
            daily_rate: {
                patterns: "Please enter numeric value as word indexing page rate.",
                required: "Daily charge is required.",
                maxlength: "Daily charge field not be upto 5 characters."
            },
            immed_rate: {
                patterns: "Please enter numeric value as word indexing page rate.",
                required: "Immediate charge is required.",
                maxlength: "Immediate charge field not be upto 5 characters."
            },
            sales_man: {
                patterns: "Please enter alphabetic value as Account Executive.",
                required: "Account Executive Field is required.",
                maxlength: "Account Executive field not be upto 254 characters."
            },
            card_number:{
                required: "Card Number is required.",
                maxlength: "Card Number not be upto 254 characters." 
            },
            cvv_code:{
                required: "Cvv Code is required.",
            },
            card_holder_name:{
                required:"Card Holder Name is required."
            }

        };

        // Save firm information
        firmFactory.save = function(data) {
            return $http.post(apiUrl + "/api/attorneys", data);
        };

        firmFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        firmFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        firmFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        // select all locations
        firmFactory.locations = function(data) {
            return $http.get(apiUrl + "/api/locations", data);
        };

        //browse all attorney firms
        firmFactory.getAttorneys = function(url, params) {
            var reqData = convertParams(params);
            return $http({ "url": apiUrl + url, "method": "GET", "params": reqData });
        };

        //select all locations
        firmFactory.rates = function(url, params) {
            return $http({ "url": apiUrl + url, "method": "GET", "params": params });
        };

        //browse all attorney from firm id
        firmFactory.getAttorneysfromfirm = function(url, params) {
            var reqData = convertParams(params);
            return $http({ "url": apiUrl + url, "method": "GET", "params": reqData });
        };

        //browse all attorney notes from firm id
        firmFactory.getattorneynotes = function(url, params) {
            var reqData = convertParams(params);
            return $http({ "url": apiUrl + url, "method": "GET", "params": reqData });
        };

        //browse all ebt clerk
        firmFactory.getclerk = function(url, params) {
            return $http({ "url": apiUrl + url + params, "method": "GET" });
        };

        //Get E-law data
        firmFactory.getAttorneyFromElaw = function(url, params) {
            return $http({ "url": apiUrl + url, "method": "GET", "params": params });
        };

        firmFactory.showDataTable = function(url,data){
            return $http({
                method: 'POST',
                url: apiUrl + url,
                data: $.param(data),
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });
        };

        firmFactory.firmRates = [
            { key : 'APP_RATE', rate : 'Appear Rate' },
            { key : 'APPL_RATE', rate : 'Late Appear' },
            { key : 'Charge2', rate : 'Lit Charge' },
            { key : 'Room', rate : 'Room' },
            { key : 'SOR', rate : 'SOR' },
            { key : 'RATE', rate : 'Rate' },
            { key : 'COPY_RATE', rate : 'Copy Rate' },
            { key : 'INV_MIN', rate : 'No Minutes Taken Charge' },
            { key : 'WordIndexingFlatRate', rate : 'Word Indexing Flat Rate' },
            { key : 'WordIndexingPerPage', rate : 'Word Indexing Per Page Rate' },
            { key : 'SORr', rate : 'SORr' },
            { key : 'MiniScriptPerPage', rate : 'Mini Script Per Page' },
            { key : 'MiniScriptFlatRate', rate : 'Mini Script Flat Rate' },
            { key : 'POSTAGE', rate : 'Postage' },
            { key : 'WEB_AMOUNT', rate : 'Exchange-Serve' },
            { key : 'CDCharge', rate : 'Cd Charge' },
            { key : 'INV_MINPG', rate : 'Minimum Invoice Charge' },  
            { key : 'med_rate', rate : 'Med Charges' },
            { key : 'exp_rate', rate : 'Exp Charges' },
            { key : 'daily_rate', rate : 'Daily Charge' },
            { key : 'immed_rate', rate : 'Immed Charge' },
            { key : 'ETranscript', rate : 'E-Transcript' },
            { key : 'admin_fee', rate : 'Admin Fee' },
            { key : 'web_charge_per_witness', rate : 'Web Charge Per Witness' },
            { key : 'video_conference_handling_and_connection_fee', rate : 'Video Conference Handling And Connection Fee' },
            { key : 'reminder_charge', rate : 'Reminder Charge' },
            { key : 'legalview_charge', rate : 'LegalView Charge' },
            { key : 'deposition_insights_charge1', rate : 'Deposition Insights Charge 1' },
            { key : 'deposition_insights_charge2', rate : 'Deposition Insights Charge 2' },
            { key : 'deposition_insights_charge3', rate : 'Deposition Insights Charge 3' },
            { key : 'digitization_charge', rate : 'Digitization Charge' },
            { key : 'exhibit_charge', rate : 'B/W Exhibits' },
            { key : 'appearance_hourly', rate : 'Appearance Hourly - Steno Remote' },
            { key : 'appearance_hourly_remote', rate : 'Appearance Hourly - Steno In-Person' },
            { key : 'appearance_hourly_nonsteno', rate : 'Appearance Hourly - Digital Remote' },
            { key : 'appearance_hourly_arbitration', rate : 'Appearance Hourly - Arbitration / Hearing / Trial' },
            { key : 'appearance_hourly_euo', rate : 'Appearance Hourly - EUO' },

            { key : 'appear_rate_remote', rate : 'Appearance Rate - Steno In-Person' },
            { key : 'appear_rate_nonsteno', rate : 'Appearance Rate - Digital Remote' },
            { key : 'appear_rate_arbitration', rate : 'Appearance Rate - Arbitration / Hearing / Trial' },
            { key : 'appear_rate_euo', rate : 'Appearance Rate - EUO' },
            { key : 'rate_remote', rate : 'Page Rate - Steno In-Person' },
            { key : 'rate_nonsteno', rate : 'Page Rate - Digital Remote' },
            { key : 'rate_arbitration', rate : 'Page Rate - Arbitration / Hearing / Trial' },
            { key : 'rate_euo', rate : 'Page Rate - EUO' },
            { key : 'copy_rate_remote', rate : 'Copy Rate - Steno In-Person' },
            { key : 'copy_rate_nonsteno', rate : 'Copy Rate - Digital Remote' },
            { key : 'copy_rate_arbitration', rate : 'Copy Rate - Arbitration / Hearing / Trial' },
            { key : 'copy_rate_euo', rate : 'Copy Rate - EUO' },
            { key : 'word_indexing_page_rate_remote', rate : 'Word Indexing Per Page Rate - Steno In-Person' },
            { key : 'word_indexing_page_rate_nonsteno', rate : 'Word Indexing Per Page Rate - Digital Remote' },
            { key : 'word_indexing_page_rate_arbitration', rate : 'Word Indexing Per Page Rate - Arbitration / Hearing / Trial' },
            { key : 'word_indexing_page_rate_euo', rate : 'Word Indexing Per Page Rate - EUO' },
            { key : 'no_transcript_depo_appear_rate', rate : 'Deposition Appearance Fee (No Transcript Ordered)' },
            { key : 'no_transcript_arbitration_appear_rate', rate : 'Arbitration / Hearing / Trial Appearance Fee (No Transcript Ordered)' },
            { key : 'no_transcript_euo_appear_rate', rate : 'EUO Appearance Fee (No Transcript Ordered)' },
            { key : 'non_appearance', rate : 'Non-Appearance - Steno Remote' },
            { key : 'non_appearance_nonsteno', rate : 'Non-Appearance - Digital Remote' },
            { key : 'cancellation_fee', rate : 'Cancellation - Steno Remote' },
            { key : 'cancellation_fee_nonsteno', rate : 'Cancellation - Digital Remote' },
            { key : 'video_cancellation', rate : 'Video Cancellation' },
            { key : 'medical_technical_expert', rate : 'Medical/Technical/Expert - Steno Remote' },
            { key : 'medical_technical_expert_nonsteno', rate : 'Medical/Technical/Expert - Digital Remote' },
            { key : 'cna', rate : 'CNA' },
            { key : 'no_write', rate : 'No Write' },
            { key : 'rough_ascii', rate : 'Rough ASCII' },
            { key : 'rough_ascii_nonsteno', rate : 'Rough ASCII - Digital Remote' },
            { key : 'realtime', rate : 'Realtime' },
            { key : 'exhibits_per_page', rate : 'Exhibits Flat Fee' },
            { key : 'expedite_percentage_per_day', rate : 'Expedite Percentage Per Day' },
            { key : 'transcript_repository_storage', rate : 'Transcript Repository Storage' },
            { key : 'videographer_fee', rate : 'Videographer Fee' },
            { key : 'video_archiving', rate : 'Video Archiving' },
            { key : 'equipment_rental', rate : 'Equipment Rental' },
            { key : 'video_sync', rate : 'Video Sync' },
            { key : 'processing_fee', rate : 'Processing Fee' },
            { key : 'color_exhibit_charge', rate : 'Color Exhibits' },
            { key : 'hyperlinked_exhibit_charge', rate : 'Hyperlinked Exhibits' },
        ];

        firmFactory.sharedPhonenumber = "";
        firmFactory.sharedAddress = "";
        firmFactory.sharedName = "";
        return firmFactory;
    }]);
