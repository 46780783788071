angular.module('deitz').controller('checkReplacementController', [
    '$scope', '$rootScope', '$state', 'Notification', 'checkFactory', 'commonFactory',

    function(
        $scope, $rootScope, $state, Notification, checkFactory, commonFactory
    ){

        $scope.title = $state.current.data.pageTitle;
        $scope.validations = checkFactory.validations;
        $scope.messages = checkFactory.messages;
        $scope.checkReplacementData = {
            CHECK_DT : moment().format('MM/DD/YYYY')
        };

        // Call for populating the attorney values for drop down while user on witness information tab
        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                if (toState.name == 'payroll.check_replacement.step_two' ) {
                    if(!toParams.check_number || !$scope.checkReplacementData.CHECK_NO){
                        $state.go('payroll.check_replacement.step_one');
                    }
                }

                if(toState.name !== 'payroll.check_replacement.step_one' && ( typeof $scope.checkReplacementData.CHECK_NO == 'undefined' || $scope.checkReplacementData.CHECK_NO=='' )){
                    $state.go('payroll.check_replacement.step_one');
                }

            });


        $scope.changePaymentDate = function (model,value) {
            $scope.checkReplacementData.CHECK_DT = moment(value).format('MM/DD/YYYY');
        };

        $scope.goto_selectCheck = function() {
            $state.go('payroll.check_replacement.step_one');
        };

        $scope.goto_confirmCheck = function() {
            $rootScope.showLoader(true);
            commonFactory.post('/api/check/search-for-check',$scope.checkReplacementData)
                .success(function(response){
                    $rootScope.showLoader(false);
                    $scope.checkReplacementData = response.result.check_data;
                    $scope.jobs = response.result.jobs;
                    $state.go('payroll.check_replacement.step_two',{'check_number':response.result.check_data.CHECK_NO});
                }).error(function(error){
                    $rootScope.showLoader(false);
                    Notification.error(error.message);
                });

        };

        $scope.goto_replaceCheck = function() {
            $state.go('payroll.check_replacement.step_three');
        } ;

        $scope.replaceCheck = function(){
            console.log($scope.checkReplacementData);
            commonFactory.post('/api/check/replace-check-number',$scope.checkReplacementData)
                .success(function(response){
                    Notification.success(response.message);
                    $state.go('payroll.check_replacement.step_one');
                }).error(function(error){
                    Notification.error(error.message);
                });
        };

}]);
