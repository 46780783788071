angular.module('deitz')
    .controller('findfirmController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'firmFactory', function($scope, $rootScope, $http, apiUrl, $state, firmFactory) {

        $scope.title = "Add Firm"
        $scope.findfirm = {};
        $scope.findfirm.searchBy = "PHONE";
        //Getting Attorneys Phone List
        $scope.attorneysfirmlist = function(val) {
            return $http.get(apiUrl + '/api/getattorneysbyphonelist', {
                params: {
                    phone: val,
                    searchBy : $scope.findfirm.searchBy
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.addFirmbtn = function(phone) {
            firmFactory.sharedAddress = "";
            firmFactory.sharedPhonenumber = "";
            firmFactory.sharedName = "";
            if($scope.findfirm.searchBy == 'PHONE'){
                firmFactory.sharedPhonenumber = phone;
            }
            else if($scope.findfirm.searchBy == 'NAME'){
                firmFactory.sharedName = phone;
            }else{
                firmFactory.sharedAddress = phone;
            }
            $state.go('firms.add.step_one_add');
        }

    }]);
