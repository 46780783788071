angular.module('deitz')
    .controller('attendeeNotifyController',[
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        'JOB_NO',
        function(
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            JOB_NO
            ) {

            $scope.title = "Notify Attendees for JOB #"+ JOB_NO;
            $scope.subtitle = "";
            $scope.cancel = function(isChanged) {
                $uibModalInstance.dismiss({ isChanged : isChanged});
            };
            $scope.selectAll = false;
        	$scope.selectedEmailArray = [];
            $scope.selectedEmail = {};
            $scope.additionalEmails = '';

            $scope.attendeeList = {};
            commonFactory.post('/api/get-attendee-list-by-job/'+JOB_NO).then(function(response) { 
                if(response.data && response.data.success){
                    $scope.attendeeList = response.data.all_attendess;
                    $scope.subtitle = response.data.email_type;

                    angular.forEach($scope.attendeeList, function(value, key){
                        $scope.selectedEmail[key] = {};
                        $scope.selectedEmail[key].is_checked = $scope.selectAll;
                    });
                }
            });
            $scope.submit= function(){
                var error = 0;
                var final_array = [];
                var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if($scope.additionalEmails){
                    $scope.additionalEmails = $scope.additionalEmails.replaceAll(/,/g, ";");
                    $scope.additionalEmails = $scope.additionalEmails.replaceAll(/\;$/g, '');
                    var additional_emails = $scope.additionalEmails.split(';');
                    angular.forEach(additional_emails, function(value, key) {
                        if(!value.trim().match(regex)){
                            error++;
                        }
                    });
                    final_array = additional_emails;
                }

                if(error > 0){
                    Notification.error('Please enter valid additional email(s).');
                }else if($scope.selectedEmailArray.length == 0 && final_array.length == 0){
                    Notification.error('Please select atleast one choice OR enter an additional email.');
                }else{
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/SendJobConfirmationTriggerToEmail', {'JOB_NO': JOB_NO,'email_address': $scope.selectedEmailArray,'additional_email_address': final_array})
                        .success(function(response) {
                            $rootScope.showLoader(false);
                            if(response.error){
                                Notification.error(response.result.message || "Something went wrong while sending Email!");
                            }else{
                                Notification.success(response.result.message || "Email successfully sent.");
                                $uibModalInstance.close(response);
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("Oops !", "Something went wrong!", "error");
                        });
                }
            };

            $scope.changeEmailAction = function(checked,email){
            
                if($scope.selectedEmailArray == null){
                    $scope.selectedEmailArray = [];
                }
                if(checked){       
                    if($scope.selectedEmailArray.indexOf(email) == -1){
                        $scope.selectedEmailArray.push(email);
                    } 
                }else{ 
                    $scope.selectedEmailArray =  $scope.selectedEmailArray.filter(function (item) {
                        return item != email; 
                    });          
                }
                $scope.selectAll = true;
                angular.forEach($scope.selectedEmail, function(value, key){
                    if(!value.is_checked){
                        $scope.selectAll = false;
                    }; 
                });
            }
    
            $scope.toggleAction = function(isSelectAll){
                angular.forEach($scope.selectedEmail, function(value, key){        
                    $scope.selectedEmail[key].is_checked = isSelectAll;
                    $scope.changeEmailAction(isSelectAll,key);
                });    
            }
    }]);
        
