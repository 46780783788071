angular.module('deitz')
    .controller('assignSingleResourceController',[
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        'JOB',
        'resources',
        '$filter',
        'formDate',
        function(
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            JOB,
            resources,
            $filter,
            formDate
            ) {

            //$scope.title = "Assign "+ resource_type +" for JOB "+ JOB;
            $scope.title = "Assign Resource for JOB "+ JOB;
            $scope.resourceType = {};
            $scope.diary = {};
            $scope.assignedResource = false;
            $scope.reporterList = []
            $scope.removeResourceServiceRequestId = []
            $scope.reporterHide = false;
            $scope.searchText = '';
            $scope.curruntYear = new Date().getFullYear();
            $scope.resourceCollections= [{
                resource_type:'',
                resource:null,
                assigned:false
            }];
            $scope.defaultResourceType=null;
            $scope.additional_resource_types=[];
            $scope.activeResourceRow=null;
            $scope.job_status_id = null;
            $scope.selectedBusinessUnitIDs = [];
            $scope.days_working = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
            $scope.saveAnyways = false;

        $scope.mainSelectedBusinessUnitIDs = [];
        var mainSelectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(mainSelectedBusinessUnitIDs && mainSelectedBusinessUnitIDs.length){
            $scope.mainSelectedBusinessUnitIDs = mainSelectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.mainSelectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }


            // Initialize selectedBusinessUnitIDs
            for (var i = 0; i < 4; i++) {
                $scope.selectedBusinessUnitIDs.push([]);
            }

            $scope.clearFilter = function (index) {
                $scope.selectedBusinessUnitIDs[index] = [];
            };

            $scope.updateBusinessUnit = function (index) {
                var selectedValue = $scope.selectedBusinessUnitIDs[index];
                if (selectedValue && selectedValue.length) {
                    $scope.selectedBusinessUnitIDs[index] = selectedValue;
                } else {
                    $scope.selectedBusinessUnitIDs.splice(index, 1);
                }
            };

            $scope.addFilter = function () {
                $scope.selectedBusinessUnitIDs.push([]);
            };

            $scope.removeFilter = function (index) {
                $scope.selectedBusinessUnitIDs.splice(index, 1);
            };

            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});
    
            });

            $scope.checkWaitListed = function(status_id) {
                $scope.isWaitListed = (status_id == 14);
            }

            $scope.isWaitListed = false;
            $http.get(apiUrl + '/api/findDiaryByJobNo/'+JOB).then(function(response) { 
                let result = response.data.result;
                if(!result.error){
                    $scope.job_status_id = result.diary.job_status_id;
                    $scope.checkWaitListed($scope.job_status_id);
                }
            });
            
            $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
                $scope.JobStatusList = response.data.result.data;  
            });

            $scope.changeJobStatus = function(){
                var status_id = $scope.job_status_id;
                $rootScope.showLoader(true);
                commonFactory.post('/api/save-job-status', { JOB_NO : JOB, job_status_id : status_id })
                    .success(function (response) {
                        if (response && response.result) {
                            $scope.job_status_id = response.result.diary.job_status_id;
                            $scope.checkWaitListed($scope.job_status_id);

                            $rootScope.showLoader(false);
                            Notification.success("Job Status Updated successfully");
                        } else {
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                        console.log(err);
                    });
            }
            
            $scope.getAdditionResourceTypes = function(val) {
                $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
                   angular.forEach( response.data.result.data, function (value, key) {
                        $scope.resourceType[value.id] =value.name;
                        if(value.is_default==1){
                            $scope.defaultResourceType=value.id.toString();
                        }
                    });
                  // add seperate type for typist
                  $scope.resourceType['T'] ='Typist';
                });
            };
            $scope.getAdditionResourceTypes();

            $scope.arrangeTimeAvailability = function(day){
                let d = parseInt(day.day_of_week) - 1;
                if(typeof $scope.days_working[d] != "undefined"){
                    return $scope.days_working[d] + ' ' + day.open_time + ' ' + day.close_time;
                } 
                return '';
            }
            // handle action
            $scope.resourceList = function(val) {
                if(!$scope.resourceCollections[$scope.activeResourceRow].resource_type){
                    searchParams ={
                        search: val                       
                    }
                    return $http.get(apiUrl + '/api/listRepoterWithDetailsAll',{
                        params:searchParams
                    }).then(function(response){
                        $scope.reporterHide = false;
                        $scope.reporterList = response.data.result;
                        return response.data.result.map(function(item) {
                          if(!$scope.selectedBusinessUnitIDs[$scope.activeResourceRow].length || $scope.selectedBusinessUnitIDs[$scope.activeResourceRow][0] === undefined ){
                            return item;
                          }else{
                            $scope.filterReportersByBusinessUnit = function(item){
                                return $scope.selectedBusinessUnitIDs[$scope.activeResourceRow].indexOf(item.businessunit.id) !== -1;
                            }
                          }
                           
                        }).filter(Boolean);
                        
                        
                    });
                }

                $scope.reporterHide = true;
                if($scope.resourceCollections[$scope.activeResourceRow].resource_type=='T'){
                    searchParams ={
                        search: val,
                        rep_typ:'T',                        
                    }
                }
                else{
                    searchParams ={
                        search: val,
                        rep_typ:'R',
                        additional_resource_type_id:$scope.resourceCollections[$scope.activeResourceRow].resource_type
                    }
                }
                return $http.get(apiUrl + '/api/listRepoterWithDetails', {
                    params: searchParams
                }).then(function(response) {
                    $scope.reporterHide = false
                    $scope.reporterList = response.data.result;
                    return response.data.result.map(function(item) {
                        if(!$scope.selectedBusinessUnitIDs[$scope.activeResourceRow].length || $scope.selectedBusinessUnitIDs[$scope.activeResourceRow][0] === undefined){
                            return item;
                          }else{
                            $scope.filterReportersByBusinessUnit = function(item){
                                return $scope.selectedBusinessUnitIDs[$scope.activeResourceRow].indexOf(item.businessunit.id) !== -1;
                            }
                          }
                        
                    }).filter(Boolean);
                });
            };

            
            $scope.typistList = function(val) {
              
                return $http.get(apiUrl + '/api/listRepoterWithDetails', {
                    params: {
                        search: val,
                        rep_typ:'T',
                    }
                }).then(function(response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };

            $scope.addNewResourceInForm = function(valid){
                if(valid){
                $scope.resourceCollections.push({
                    resource_type:'',
                    resource:null,
                    assigned:false,
                    assigned_types:$scope.resourceType
                });
                $scope.selectedBusinessUnitIDs[$scope.resourceCollections.length-1]=$scope.mainSelectedBusinessUnitIDs;
                $scope.reporterHide = true;
                $scope.setDefaultPrimaryResource();
                }
            };
            $scope.removeResource =function(index){
                if($scope.resourceCollections[index].service_request_id){
                    $scope.removeResourceServiceRequestId.push($scope.resourceCollections[index].service_request_id)
                }
                $scope.resourceCollections.splice(index, 1);
                $scope.setDefaultPrimaryResource();
            };
            $scope.changeActiveResourceRow =function(key){
                $scope.activeResourceRow=key;
            }
            
            $scope.getJobResourceList = function (){
                $rootScope.showLoader(true);
                commonFactory.get('/api/getJobResources/'+JOB)
                .success(function (response) {
                    
                    if(response.success){
                        if(response.job_resources.length>0){
                           $scope.assignExistingResources(response.job_resources,response.service_request);
                        }
                        else{
                        if(response.service_request.length>0){
                            var job_resources= [];
                            angular.forEach(response.service_request, function (value, key) {
                                job_resources.push({
                                    resource_type: value.resource_type_id.toString(),
                                    resource:null,
                                    assigned:false,
                                    is_primary_resource:1,
                                    assigned_types:$scope.resourceType,
                                    service_request_id:value.id
                                })
                                $scope.selectedBusinessUnitIDs[key]=$scope.mainSelectedBusinessUnitIDs;
                            });
                            $scope.resourceCollections=job_resources;
                        }else{
                            $scope.resourceCollections= [{
                                resource_type:'',
                                resource:null,
                                assigned:false,
                                is_primary_resource:1,
                                assigned_types:$scope.resourceType
                            }];
                            $scope.selectedBusinessUnitIDs[0]=$scope.mainSelectedBusinessUnitIDs;
                            }
                        }
                        $rootScope.showLoader(false);
                    }
                    else{
                        Notification.error('Something went wrong!');
                    }
                })
                .error(function (err) {
                    Notification.error(err.message || 'Something went wrong!');
                    $rootScope.showLoader(false);
                });
            }
            $scope.assignExistingResources = function(resources,service_request = null){
               var job_resources= [];
                
                    job_resources.push({
                        resource_type: resources.resource_type_id.toString(),
                        resource:null,
                        assigned:false,
                        is_primary_resource:1,
                        assigned_types:$scope.resourceType,
                        service_request_id:resources.id
                    })
                    $scope.selectedBusinessUnitIDs[0]=$scope.mainSelectedBusinessUnitIDs;
              
              
                $scope.resourceCollections=job_resources;
            }
           
            if(!resources){
                $scope.getJobResourceList();
            }
            else{
               $scope.assignExistingResources(resources);
            }


            $scope.unSelectResource = function(index){
                // check if value is assigned or not
                if(!$scope.resourceCollections[index].resource_type){
                    $scope.resourceCollections[index].resource_type='';
                }
                if($scope.resourceCollections[index].assigned){
                    $scope.resourceCollections[index].resource = null;
                    $scope.resourceCollections[index].assigned = false;
                }
                $scope.resourceCollections[index].assigned_types =$scope.resourceType;
                $scope.reporterHide = true;
            };

            $scope.closePopupWindow =function(){
                $scope.reporterHide = true;
            }

            $scope.selectResourceData = function(repoter,key){
                console.log(repoter,key);
                // if reporter is marked as DO NOT USE
                if(repoter.do_not_use == '1'){                
                    swal('Please Choose Another Resource.', repoter.reason_do_not_use, 'error');
                    return false;
                }
                const index = $scope.resourceCollections.findIndex(function (o) {
                    if(o.resource){
                    return (o.resource.id == repoter.id && o.resource_type == $scope.resourceCollections[key].resource_type)
                    }else{
                        return null
                    } ;
                });
                if (index>=0){
                    Notification.error('Resource already assigned!');
                    return false;
                }
                $scope.assignedResource = true
                $scope.resourceCollections[key].resource =repoter
                $scope.resourceCollections[key].assigned=true;
                $scope.reporterHide = true;
                var assignedResourceTypes={};
                if(repoter.REP_TYP=='R'){
                    angular.forEach( repoter.additionalinfo, function (value, key) {
                        assignedResourceTypes[value.additional_info_name.id] =value.additional_info_name.name;
                    });
                }else{
                    assignedResourceTypes['T'] ='Typist';
                }
                $scope.resourceCollections[key].assigned_types = assignedResourceTypes;
            };
            $scope.changePrimaryResource =function(index){
                angular.forEach($scope.resourceCollections, function (value, key) {
                    if(key != index){
                        $scope.resourceCollections[key].is_primary_resource=0;
                    }
                });
            }

            $scope.setDefaultPrimaryResource = function(){
                var havePrimery=0;
                angular.forEach($scope.resourceCollections, function (value, key) {
                    if(value.is_primary_resource==1){
                        havePrimery=1;
                    }
                });
               
                if(havePrimery==0 && $scope.resourceCollections.length !=0){
                    $scope.resourceCollections[0].is_primary_resource=1;
                }
            }
            $scope.updateResourceType = function(index){
                if($scope.resourceCollections[index].resource_type=='T'){
                    $scope.resourceCollections[index].typist =null;
                }
            }

            $scope.updateResource= function($valid){

                var error=0;
                var params=[];
                if($valid){
                    angular.forEach($scope.resourceCollections, function (value, key) {
                        if(!value.assigned){
                            error++;
                        }
                        typist_id=null;
                        typist_name_key=null;
                        if(value.typist){
                            typist_id=value.typist.id;
                            typist_name_key=value.typist.NAME_KEY;
                        }
                        params.push({
                                jobNo:JOB,
                                NAME_KEY: value.resource.NAME_KEY,
                                resourceId: value.resource.id,
                                resource_type : value.resource_type,
                                typist_id : typist_id,
                                typist_name_key : typist_name_key,
                                is_primary_resource:value.is_primary_resource,
                                service_request_id:value.service_request_id?value.service_request_id:null
                        })
                    });
                }
                if($valid && (error==0)){

                    swal({
                            title: "Are you sure ?",
                           // text: "You are going to assign a new "+ $scope.resource_type,
                            text: "You are going to assign a resources",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $rootScope.showLoader(true);

                                commonFactory.post('/api/assignResourceMultiple', {resources:params,JOB_NO:JOB, saveAnyways: $scope.saveAnyways,removeResourceServiceRequestId:$scope.removeResourceServiceRequestId,isSingle:true})
                                    .success(function (response) {

                                        if(!response.error){
                                            $scope.saveAnyways = false;
                                            $uibModalInstance.dismiss({ isChanged : true, service_request:response.service_request, job_resources:response.job_resources, job_status_id : $scope.job_status_id});
                                            Notification.success(response.message || $scope.resource_type +' has been assigned successfully.');
                                        } else{
                                            if(typeof response.warning != "undefined"){
                                                $rootScope.showLoader(false);
                                                Notification.error(response.message || 'Something went wrong!');
                                                swal.close();
                                                setTimeout(function () {
                                                    swal({
                                                        title: "Alert",
                                                        text: response.message,
                                                        type: "warning",
                                                        showCancelButton: true,
                                                        confirmButtonColor: "#09375f",
                                                        confirmButtonText: "Save anyways!",
                                                        cancelButtonText: "No, choose another!",
                                                        closeOnConfirm: true,
                                                        closeOnCancel: true
                                                    },
                                                    function (isConfirm) {
                                                        if (isConfirm) {
                                                            $scope.saveAnyways = true;  
                                                            swal.close();
                                                            setTimeout(function () {
                                                                $scope.updateResource($scope.changeResourceForm.$valid);
                                                            },500);
                                                        }
                                                    });
                                                }, 500);
                                            }else{
                                                $rootScope.showLoader(false);
                                                Notification.error(response.message || 'Something went wrong!');
                                            }
                                        }

                                    })
                                    .error(function (err) {
                                        Notification.error(response.message || 'Something went wrong!');
                                        $rootScope.showLoader(false);
                                    });
                            }
                        });
                }
            };

            $scope.cancel = function(isChanged) {
                $uibModalInstance.dismiss({ isChanged : isChanged, job_status_id : $scope.job_status_id});
            };

            $scope.getAvailableResources = function(resourceType,resource,index) {
                $rootScope.showLoader(true);
                $scope.activeResourceRow=index;
                if(resource==null){
                    $scope.resourceCollections[index].resource=[];
                }
                if(resourceType != 'T'){
                    var repType = 'R';
                    var resourceId = resourceType;
                }else{
                    var repType = 'T';
                    var resourceId = '';
                }
                $http.get(apiUrl + '/api/reporterWithDetails',{params:{
                    repType : repType,
                    resourceId : resourceId,
                    jobNo:JOB,
                    time:formDate
                }})
                    .then(function(response) {
                        $rootScope.showLoader(false);
                        $scope.reporterHide = false
                        $scope.reporterList = response.data.result;
                        if(response.data.result.length == 0){
                            Notification.error('No Resource Exists');
                            return false;
                        }
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    })
                    .catch(function(error) {
                        console.error('Error fetching resources:', error);
                    });
            };

            $scope.selectAllResourceType = function(resourceType,resource,index) {
                $rootScope.showLoader(true);
                $scope.activeResourceRow=index;
                if(resource==null){
                    $scope.resourceCollections[index].resource=[];
                }
                if(resourceType != 'T'){
                    var repType = 'R';
                    var resourceId = resourceType;
                }else{
                    var repType = 'T';
                    var resourceId = '';
                }
                $http.get(apiUrl + '/api/availableReporterDetails',{params:{
                    repType : repType,
                    resourceId : resourceId,
                    jobNo:JOB,
                    time:formDate
                }})
                    .then(function(response) {
                        $rootScope.showLoader(false);
                        $scope.reporterHide = false
                        $scope.reporterList = response.data.result;
                        if(response.data.result.length == 0){
                            Notification.error('No Available Resource Exists');
                            return false;
                        }
                        return response.data.result.map(function(item) {
                            return item;
                        });
                    })
                    .catch(function(error) {
                        console.error('Error fetching all resources:', error);
                    });
            };

        }]);
        
