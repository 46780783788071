angular.module('deitz')
    .controller('resetPasswordController', ['$scope', '$rootScope', 'loginFactory', 'Notification', '$stateParams', '$state', function($scope, $rootScope, loginFactory, Notification, $stateParams, $state) {

        $scope.reset = {};

        $scope.validations = loginFactory.validations;
        $scope.messages = loginFactory.messages;
        $scope.formSubmit = false;

        $scope.resetPasswordSubmit = function(isValid) {
            $scope.formSubmit = true;            
            if (isValid) {
                $rootScope.showLoader(true);
                $scope.reset.token = $stateParams.token;
                $scope.reset.email = $stateParams.email;

                loginFactory.updatePassword($scope.reset)
                    .success(function(result) {
                        if ((result && result.response == "passwords.reset") && (result.status == 200)) {
                            Notification.success("Your password has been reset successfully.");
                            // notify({
                            //     message: 'Your password has been reset successfully.',
                            //     classes: 'alert alert-success',
                            //     templateUrl: 'views/common/notify.html',
                            //     duration: 100
                            // });
                            $state.go("login");
                        } else if ((result && result.response == "passwords.token") && (result.status == 404)) {
                            Notification.error("Token is invalid or expired.");
                            // notify({
                            //     message: 'Token is invalid or expired.',
                            //     classes: 'alert alert-danger',
                            //     templateUrl: 'views/common/notify.html'
                            // });
                            $state.go("login");
                        } else if ((result && result.response == "passwords.email") && (result.status == 404)) {
                            Notification.error("Invalid e-mail address.");
                            // notify({
                            //     message: 'Invalid e-mail address.',
                            //     classes: 'alert alert-danger',
                            //     templateUrl: 'views/common/notify.html'
                            // });
                            $state.go("login");
                        }else if (result && result.status == 403) {
                            Notification.error(result.message);
                            $state.go("login");

                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function(err) {
                        Notification.error(err.message ? err.message : 'Invalid login detail!');
                        $scope.reset.password = null;
                        $scope.reset.password_confirmation = null;
                        // notify({
                        //     message: err.message ? err.message : 'Invalid login detail!',
                        //     classes: 'alert alert-danger',
                        //     templateUrl: 'views/common/notify.html'
                        // });
                        $rootScope.showLoader(false);
                    });
            }
        };
    }]);

//Angular Directive for password comparison.
var compareTo = function() {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareTo"
        },
        link: function(scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
};

angular.module('deitz').directive("compareTo", compareTo);
