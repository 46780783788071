angular.module('deitz').
	controller('autoSendInsuranceCompanyStatementsController',[
		'$scope',
		'$rootScope',
		'DTOptionsBuilder',
		'DTColumnBuilder',
        '$http', 
        'apiUrl',
		'$compile',
		'$state',
		'commonFactory',
        'Notification',
        '$uibModal',
		function(
			$scope,
			$rootScope,
			DTOptionsBuilder,
			DTColumnBuilder,
			$http,
            apiUrl,
            $compile,
			$state,
			commonFactory,
            Notification,
            $uibModal
				){
        $scope.formData = { lookingFor : 'dte_invoice' };
        $scope.formData.dateRange = {
            endDate : moment().format('MM-DD-YYYY'),
            startDate : moment().format('MM-DD-YYYY')
        };
        $scope.formData.lastSentDateRange = {
            endDate : moment().format('MM-DD-YYYY'),
            startDate : moment().format('MM-DD-YYYY')
        } 
        
        $scope.insurance_email = {};
        $scope.insurance_fax = {};
        $scope.claimrep_email = {};
        $scope.claimrep_fax = {};

        $scope.dtInstance = {};
        $scope.sentInvoiceDtInstance = {};
        $scope.dateRangeTypes = [ {'type':'dte_invoice','name':'Invoice Date'}, {'type':'last_sent','name':'Last Sent'}]; 
        $scope.selectedClaimRepArray = [];
        $scope.totalQueueInvoices = 0; 

        $scope.selectedClaimRepArray = JSON.parse(localStorage.getItem('batchClaimRepInvoices'));
        if($scope.selectedClaimRepArray){

            $scope.totalQueueInvoices = $scope.selectedClaimRepArray.length;

        }

        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));

        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        // ================ DataTable for claim rep 'all' and 'today' invoices ================//

        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            $scope.selectedClaimRep = {}; 
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){
            $scope.dtInstance.DataTable.state.clear(); 
            $scope.reloadData();
        }
        $scope.clearBatch = function(){

            $scope.selectedClaimRepArray = [];
            $scope.totalQueueInvoices = 0; 
            localStorage.setItem('batchClaimRepInvoices',JSON.stringify($scope.selectedClaimRepArray));
            $scope.reloadData();    
        }
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };  

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data') 
            .withOption('ajax', function (data, callback, settings) {
                $rootScope.showLoader(true);
                data.includeDateRange = $scope.formData.includeDateRange;
                // data.includeNoAutoTouch = $scope.formData.includeNoAutoTouch;
                
                data.excludeNone = $scope.formData.excludeNone;
                //Filter for insurance compnay.
                data.excludeCompanyEmail = $scope.formData.excludeCompanyEmail;
                data.excludeCompanyNoEmail = $scope.formData.excludeCompanyNoEmail;
                data.excludeCompanyFax = $scope.formData.excludeCompanyFax;
                data.excludeCompanyNoFax = $scope.formData.excludeCompanyNoFax;

                //Filter for claim representative.
                data.excludeClaimRepEmail = $scope.formData.excludeClaimRepEmail;
                data.excludeClaimRepNoEmail = $scope.formData.excludeClaimRepNoEmail;
                data.excludeClaimRepFax = $scope.formData.excludeClaimRepFax;
                data.excludeClaimRepNoFax = $scope.formData.excludeClaimRepNoFax;

                data.type = $scope.formData.lookingFor;
                data.startDate =  moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD');
                data.endDate =  moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD');
                data.excludeLastSent = $scope.formData.excludeLastSent;
                
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); 

                if($scope.formData.excludeLastSent){

                    data.lastSentStartDate = moment($scope.formData.lastSentDateRange.startDate).format('YYYY-MM-DD');
                    data.lastSentEndDate = moment($scope.formData.lastSentDateRange.endDate).format('YYYY-MM-DD');
                
                }  
                commonFactory.showDataTable('/api/get-claim-rep-invoices', data)
                .success(function(res) {
                    $rootScope.showLoader(false);
                    if (res) {
                        $scope.selectAll = true;
                        $scope.selectedClaimRep = {};   
                        callback(res);
                    }
                })
                .error(function(err) { 
                });
            })   
            .withOption('stateSave',true) 
            .withOption('processing', true)
            .withOption('serverSide', true) 
            .withOption('createdRow', $scope.compileDatatable)  
            .withLanguage({
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "", 
            }) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('lengthMenu',[[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]])
            .withDisplayLength(100) 
            .withOption('order', [0, 'asc']) 
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            }) 
            .withLightColumnFilter({
                '0': {
                    type: 'text'
                },
                '1': {
                    type: 'text'
                },
                '2': {
                    type: 'text'
                }
            });
        $scope.dtColumns = [];     
        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAction(selectAll)">';

        $scope.dtColumns.push(  
            DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice #').withOption('searchable',true), 
            
            DTColumnBuilder.newColumn('claim_representative.ClaimRepFirstName').withTitle('Claim Rep').renderWith(function(data, type, full, meta){
                
                if(full.claim_representative){

                    return full.claim_representative.ClaimRepFirstName +' '+full.claim_representative.ClaimRepLastName;
                }

            }).withOption('name','ClaimRepresentative.ClaimRepFirstName').withOption('searchable',true),

            DTColumnBuilder.newColumn('claim_representative.insurance_comp.CompanyName').withTitle('Company').withOption('name','ClaimRepresentative.insuranceComp.CompanyName').withOption('searchable',true),
            
            DTColumnBuilder.newColumn('claim_representative.insurance_comp.MainEmailAddress').withOption('defaultContent', '-').withTitle('Company Email').renderWith(function(data, type, full, meta){
                
                $scope.insurance_email[full.claim_representative.insurance_comp.id] = data;
                var type = 'email_address';
                var editable_txt = data ? '' : 'ADD EMAIL ADDRESS';

                return '<a href="javascript:void(0)" style="font-size:10px;" editable-text = insurance_email[' +full.claim_representative.insurance_comp.id +'] onaftersave="change('+ full.claim_representative.insurance_comp.id +',\''+type+'\',\'insurance_comp\')" ng-bind="insurance_email['+full.claim_representative.insurance_comp.id+'] || \''+ editable_txt +'\'"></a>';


            }).withOption('name','ClaimRepresentative.insuranceComp.MainEmailAddress').withOption('searchable',false),
            DTColumnBuilder.newColumn('claim_representative.insurance_comp.MainFaxNumber').withOption('defaultContent', '-').withTitle('Company Fax').renderWith(function(data, type, full, meta){
                
                $scope.insurance_fax[full.claim_representative.insurance_comp.id] = data;
                var type = 'fax_number';
                var editable_txt = data ? '' : 'ADD FAX NUMBER';

                return '<a href="javascript:void(0)" editable-text = insurance_fax[' +full.claim_representative.insurance_comp.id +'] onaftersave="change('+ full.claim_representative.insurance_comp.id +',\''+type+'\',\'insurance_comp\')" ng-bind="insurance_fax['+full.claim_representative.insurance_comp.id+'] || \''+ editable_txt +'\'"></a>';


            }).withOption('name','ClaimRepresentative.insuranceComp.MainFaxNumber').withOption('searchable',false),
           
            DTColumnBuilder.newColumn('claim_representative.CR_EmailAddress').withOption('defaultContent', '-').withTitle('Claimrep Email').renderWith(function(data, type, full, meta){
                
                $scope.claimrep_email[full.claim_representative.ClaimRepID] = data;
                var type = 'email_address';
                var editable_txt = data ? '' : 'ADD EMAIL ADDRESS';

                return '<a href="javascript:void(0)" style="font-size:10px;" editable-text = claimrep_email[' +full.claim_representative.ClaimRepID +'] onaftersave="change('+ full.claim_representative.ClaimRepID +',\''+type+'\',\'claim_representative\')" ng-bind="claimrep_email['+full.claim_representative.ClaimRepID+'] || \''+ editable_txt +'\'"></a>';


            }).withOption('name','ClaimRepresentative.CR_EmailAddress').withOption('searchable',false),
            DTColumnBuilder.newColumn('claim_representative.CR_Fax').withOption('defaultContent', '-').withTitle('Claimrep Fax').renderWith(function(data, type, full, meta){
                
                $scope.claimrep_fax[full.claim_representative.ClaimRepID] = data;
                var type = 'fax_number';
                var editable_txt = data ? '' : 'ADD FAX NUMBER';

                return '<a href="javascript:void(0)" editable-text = claimrep_fax[' +full.claim_representative.ClaimRepID +'] onaftersave="change('+ full.claim_representative.ClaimRepID +',\''+type+'\',\'claim_representative\')" ng-bind="claimrep_fax['+full.claim_representative.ClaimRepID+'] || \''+ editable_txt +'\'"></a>';


            }).withOption('name','ClaimRepresentative.CR_Fax').withOption('searchable',false),
            DTColumnBuilder.newColumn('claim_representative.statement_send_via').withOption('defaultContent', '-').withTitle('Send Via').renderWith(function(data, type, full, meta){
                var style = "";
                var send_to = full.claim_representative.statement_send_to ? full.claim_representative.statement_send_to.replace('_',' ') :"";

                if(full.claim_representative.statement_send_to == null || full.claim_representative.statement_send_to == 'none'){
                    style = "color:#d14;border-bottom:dashed;border-width:thin;";
                    data = "NONE";
                    send_to = "";
                }

                return '<a href="javascript:void(0)" style="'+style+'" ng-click="changeFrequency('+full.claim_representative.ClaimRepID+',\''+full.claim_representative.statement_send_to+'\',\''+full.claim_representative.statement_send_via+'\')" title="Click to change frequency">'+data+'</a><br><small>'+send_to+'<small>';

            }).withOption('name','ClaimRepresentative.statement_send_via'),
            DTColumnBuilder.newColumn('INV_BAL_DUE').withOption('defaultContent', '-').withTitle('Bal Due').renderWith($scope.currencyFormat).withOption('searchable',false),
            
            DTColumnBuilder.newColumn('dte_invoice').withOption('defaultContent', '-').withTitle('Invoice Date').renderWith(function(data, type, full, meta){
                if(data){ 
                    
                    return moment(data).format('MM/DD/YYYY');
                
                }else{
                    return '-';
                }
            }).withOption('searchable',false),
            
            // DTColumnBuilder.newColumn('claim_representative.insurance_comp.auto_touch').withOption('defaultContent', '-').withTitle('AUTO TOUCH').renderWith(function(data, type, full, meta){
            //     var type = "auto_touch";
            //     return '<div style="text-align:center;"><a href="javascript:void(0)" ng-click="change('+ full.claim_representative.insurance_comp.id +',\''+type+'\',\''+ data +'\')"> '+ data +' </a></div>';
            
            // }).withOption('name','ClaimRepresentative.insuranceComp.auto_touch').withOption('searchable',false),
            DTColumnBuilder.newColumn('last_invoice_sent_date').withOption('defaultContent', '-').withTitle('Last Sent').renderWith(function(data, type, full, meta){
                if(data){ 
                    
                    return moment(data).format('MM/DD/YYYY hh:mm a');
                
                }else{
                    return '-';
                }
            }).withOption('searchable',false),
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).renderWith(function(data, type, full, meta) {

                var is_checked = false;
                var batchInvoices = [];
                $scope.selectedClaimRepArray = JSON.parse(localStorage.getItem('batchClaimRepInvoices'));
                
                
                if($scope.selectedClaimRepArray){
                    $scope.totalQueueInvoices = $scope.selectedClaimRepArray.length;
                    batchInvoices = $scope.selectedClaimRepArray.filter(function(item){

                        return item == full.INV_NO;
                    
                    });   
                }else{

                    $scope.totalQueueInvoices = 0;

                } 
                
                if(batchInvoices.indexOf(Number(full.INV_NO)) != -1){
                    is_checked = true;
                } 

                var showToggle = false;
                if(full.claim_representative.statement_send_to == 'insurance_company'){
                    if(full.claim_representative.statement_send_via == 'email' && full.claim_representative.insurance_comp.MainEmailAddress){
                        showToggle = true;
                    }else if(full.claim_representative.statement_send_via == 'fax' && full.claim_representative.insurance_comp.MainFaxNumber){
                        showToggle = true;
                    }
                }else if(full.claim_representative.statement_send_to == 'claim_representative'){
                    if(full.claim_representative.statement_send_via == 'email' && full.claim_representative.CR_EmailAddress){
                        showToggle = true;
                    }else if(full.claim_representative.statement_send_via == 'fax' && full.claim_representative.CR_Fax){
                        showToggle = true;
                    }
                }
                if(showToggle){
                    $scope.selectedClaimRep[full.INV_NO] = full;
                    $scope.selectedClaimRep[full.INV_NO].is_checked = is_checked;  
                    
                    if(!$scope.selectedClaimRep[full.INV_NO].is_checked){
                        $scope.selectAll = false;
                    }

                    if($scope.selectedClaimRepArray){

                        localStorage.setItem('batchClaimRepInvoices',JSON.stringify($scope.selectedClaimRepArray));
                    } 
                    return  '<toggle ng-disabled="false" size="btn-xs" ng-model="selectedClaimRep['+ full.INV_NO +'].is_checked" ng-change="changeClaimRepStatus(selectedClaimRep['+ full.INV_NO +'].is_checked,'+full.INV_NO+')" on="Yes" off="No" onstyle="btn-success" offstyle="btn-danger"></toggle>';

                }else{
                    
                    if(is_checked){ 

                        $scope.selectedClaimRepArray =  $scope.selectedClaimRepArray.filter(function (item) {

                            return item != full.INV_NO; 
                        
                        });

                        localStorage.setItem('batchClaimRepInvoices',JSON.stringify($scope.selectedClaimRepArray));
                    }
                    
                    return '-';
             
                }
              
            }).withOption('searchable',false).notSortable()
            
        );

        $scope.changeClaimRepStatus = function(checked,INV_NO){

            $scope.selectedClaimRepArray =  JSON.parse(localStorage.getItem('batchClaimRepInvoices')); 
            if($scope.selectedClaimRepArray == null){
                $scope.selectedClaimRepArray = [];
            }
            if(checked){   
                
                if($scope.selectedClaimRepArray.indexOf(Number(INV_NO)) == -1){

                    $scope.selectedClaimRepArray.push(Number(INV_NO));  
                
                }

            }else{ 
              
                $scope.selectedClaimRepArray =  $scope.selectedClaimRepArray.filter(function (item) {

                    return item != INV_NO; 
                
                }); 
                      
            }
            $scope.selectAll = true;
            angular.forEach($scope.selectedClaimRep, function(value, key){
                
                if(!value.is_checked){
                    $scope.selectAll = false;
                }; 
            });
            $scope.totalQueueInvoices = $scope.selectedClaimRepArray.length;
            localStorage.setItem('batchClaimRepInvoices',JSON.stringify($scope.selectedClaimRepArray)); 
        }

        $scope.toggleAction = function(isSelectAll){

            angular.forEach($scope.selectedClaimRep, function(value, key){
                
                $scope.selectedClaimRep[value.INV_NO].is_checked = isSelectAll;
                $scope.changeClaimRepStatus(isSelectAll,value.INV_NO);  
            });    
        }

        $scope.change = function(id, type, mode){
            
            var data = {};
            if(type == 'email_address'){
                if(mode == 'insurance_comp'){
                    data.MainEmailAddress = $scope.insurance_email[id];
                    $rootScope.showLoader(true);
                    commonFactory.put('/api/claimrepresentative/' + id, data).success(function(res){
                        $scope.reloadData();
                    });
                }else{

                    data.CR_EmailAddress = $scope.claimrep_email[id];
                    $rootScope.showLoader(true);
                    commonFactory.put('/api/claimrepresentativename/' + id, data).success(function(res){
                        $scope.reloadData();
                    });
                }   
            }else if(type == 'fax_number'){
                if(mode == 'insurance_comp'){
                    data.MainFaxNumber = $scope.insurance_fax[id];
                    $rootScope.showLoader(true);
                    commonFactory.put('/api/claimrepresentative/' + id, data).success(function(res){           
                        $scope.reloadData();
                    });
                }else{
                    data.CR_Fax = $scope.claimrep_fax[id];
                    $rootScope.showLoader(true);
                    commonFactory.put('/api/claimrepresentativename/' + id, data).success(function(res){           
                        $scope.reloadData();
                    });
                }
            }else{
                swal({
                    title: 'Are You Sure Want To Change?',
                    // text: "",
                    html: true,
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {      
                        data.auto_touch = (mode == 'Y') ? 'N' : 'Y';
                        $rootScope.showLoader(true);
                        commonFactory.put('/api/claimrepresentative/' + id, data ).success(function(res){
                             
                            $scope.reloadData();
                            
                        });
                    }
                }); 
            }

        }

        $scope.sendSelectedInvoices = function(){
            
            $scope.selectedClaimRepArray =  JSON.parse(localStorage.getItem('batchClaimRepInvoices'));

            if($scope.selectedClaimRepArray == null){ 
                $scope.selectedClaimRepArray = [];
                
            }
            if(!$scope.selectedClaimRepArray.length > 0){

               Notification.error('THREE ARE NO INVOICES SELECTED !');
               return false; 
            
            }else{

                var txt = "THERE ARE <span style='color:red'> "+ $scope.selectedClaimRepArray.length +" </span> INVOICES IN THE QUEUE";
            
            }

            swal({
                    title: txt,
                    text: "Are You Sure Want To Send ?",
                    html: true,
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        var data = [];
                        $rootScope.showLoader(true);   

                        commonFactory.post('/api/queue-claimrep-invoices',{ selectedClaimRepArray : $scope.selectedClaimRepArray }).success(function(res){
                            
                            $rootScope.showLoader(false);
                            if(res.error){

                                Notification.error(res.result.message);

                            }else{
                                
                                Notification.success(res.result.message);

                            }
                        });
                    }
                }); 
        }

        $scope.changeFrequency = function(claimRepId, send_to, send_via){
    
            $rootScope.showLoader(true);
            $scope.old_frequency = { 
                ef_send_to : send_to != 'null' ? send_to : 'none',
                email_fax : send_via != 'null' ? send_via :'email'
            };
            
            var modalInstance = $uibModal.open({
                templateUrl : 'modules/resources/templates/set_insurance_claim_rep_frequency.html',
                controller : function($scope, old_frequency, $uibModalInstance){
                    $scope.frequency = {
                        ef_send_to : old_frequency.ef_send_to,
                        email_fax : old_frequency.email_fax,
                    };

                    $scope.closeModal = function(){
                        $uibModalInstance.close();
                    }
                    $scope.save = function(){
                        $uibModalInstance.close($scope.frequency);
                    }
                },
                resolve:{
                    old_frequency : function(){
                        return $scope.old_frequency;
                    } 
                },
                backdrop:false
            });

            modalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (new_frequency) {
                if(new_frequency){
                    if($scope.old_frequency.ef_send_to != new_frequency.ef_send_to || $scope.old_frequency.email_fax != new_frequency.email_fax){
                        $rootScope.showLoader(true);
                        var data = {};
                        data.statement_send_to = new_frequency.ef_send_to;
                        data.statement_send_via = new_frequency.email_fax;
                        commonFactory.put('/api/claimrepresentativename/' + claimRepId, data).success(function(res){           
                            $scope.reloadData();
                        });
                    }
                }
            });  
        }

        //=============== DataTable for sent invoices ===============//

        $scope.sentInvoiceReloadData = function() {
            $scope.selectedClaimRep = {}; 
            $scope.sentInvoiceDtInstance.rerender();
        };
        $scope.sentInvoiceResetSearch = function(){
            $scope.sentInvoiceDtInstance.DataTable.state.clear();  
            $scope.sentInvoiceReloadData();
        }
        $scope.sentInvoiceDtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data') 
            .withOption('ajax', function (data, callback, settings) { 

                commonFactory.showDataTable('/api/get-claim-rep-sent-invoices', data)
                .success(function(res) {
                    if (res) {
                        callback(res);
                    }
                })
                .error(function(err) { 
                });
            })   
            .withOption('stateSave',true) 
            .withOption('processing', true)
            .withOption('serverSide', true) 
            .withOption('createdRow', $scope.compileDatatable)  
            .withLanguage({
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "", 
            }) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(10) 
            .withOption('order', [0, 'desc'])  
            .withLightColumnFilter({
                '1': {
                    type: 'text'
                },
                '2': {
                    type: 'text'
                },
                '3': {
                    type: 'text'
                }, 
                '4': {
                    type: 'text'
                },
                '5': {
                    type: 'text'
                }
            });
        $scope.sentInvoiceDtColumns = [];     
    
        $scope.sentInvoiceDtColumns.push(  

            DTColumnBuilder.newColumn('created_at').withTitle('Sent Date').renderWith(function(data, type, full, meta){
                if(data){ 
                    
                    return moment(data).format('MM/DD/YYYY hh:mm a');
                
                }else{
                    return '-';
                }
            }).withOption('name','auto_touch_claim_logs.created_at').withOption('searchable',false).withOption('width','10%'),
            DTColumnBuilder.newColumn('INV_NO').withTitle('Invoice #').withOption('searchable',true).withOption('width','10%').withOption('name','auto_touch_claim_logs.INV_NO'),
            DTColumnBuilder.newColumn('invoice.claim_representative.ClaimRepFirstName').withTitle('Claim Rep').renderWith(function(data, type, full, meta){
                
                return full.invoice.claim_representative.ClaimRepFirstName +' '+full.invoice.claim_representative.ClaimRepLastName;

            }).withOption('name','invoice.ClaimRepresentative.ClaimRepFirstName').withOption('searchable',true).withOption('width','15%'),
            DTColumnBuilder.newColumn('invoice.claim_representative.insurance_comp.CompanyName').withTitle('Company').withOption('name','invoice.ClaimRepresentative.insuranceComp.CompanyName').withOption('searchable',true).withOption('width','20%'),
            DTColumnBuilder.newColumn('action').withTitle('Sent Via').withOption('searchable',true).withOption('name','auto_touch_claim_logs.action').withOption('width','10%'),
            DTColumnBuilder.newColumn('action_value').withTitle('Sent On').withOption('searchable',true).withOption('name','auto_touch_claim_logs.action_value').withOption('width','15%'),
            DTColumnBuilder.newColumn('subject').withTitle('Subject').withOption('searchable',false).notSortable().withOption('width','20%')
            
        );
}]);