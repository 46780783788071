angular.module('deitz')
    .controller('notesController', ['$scope', '$rootScope', 'firmFactory', '$state', 'Notification', '$uibModalInstance', 'notesdata', function($scope, $rootScope, firmFactory, $state, Notification, $uibModalInstance, notesdata) {

        $scope.note = {};
        $scope.title = "Add Note";
        $scope.formSubmit = false;

        $scope.validations = firmFactory.validations;
        $scope.messages = firmFactory.messages;
        $scope.note.attorney_id = $state.params ? parseInt($state.params.id) : '';

        $scope.addNote = function(note, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                firmFactory.post('/api/addattorneynote', note)
                    .success(function(response) {
                        console.log(response.result);
                        if (response.status == 200 && response.result && response.result.note) {
                            $rootScope.showLoader(false);
                            notesdata.push(response.result.note);
                            $uibModalInstance.close()
                        }
                        swal('success!', 'Note added successfully', 'success');
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
