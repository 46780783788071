angular.module('deitz')
    .controller('legacyInvoiceReportController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        '$filter',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
            $filter
        ) {

            $scope.dtInstance = {};
            $scope.dateRange
            $scope.title = $state.current.data.pageTitle;
            $scope.downloadExcel = false;
            $scope.disableDownload=false;


            $scope.resetSearch = function () {
                $scope.dateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.report = {
                dateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                }
            }


            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            } else {
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }


            $scope.reloadData = function () {

                $scope.dtInstance.rerender();
            };
            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.downloadExcelFile = function () {
                window.onbeforeunload = null;
                $scope.downloadExcel = true;
                $rootScope.showLoader(true);
                $scope.reloadData();
            }



            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); $scope.inProcess = true;
                    data.downloadExcel = $scope.downloadExcel;
                    data.startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');

                    commonFactory.showDataTable('/api/invoice/get-legacy-invoice-report', data).success(function (res) {

                        if (res.error) {
                            // $scope.reloadData();
                        }
                        else {
                            if(res.recordsFiltered==0){
                                $scope.disableDownload=true;
                            }
                            else{
                                $scope.disableDownload=false;
                            }
                            $scope.selectAll = true;
                            $scope.invoiceArray = {};
                            callback(res);
                        }
                        if ($scope.downloadExcel) {
                            $rootScope.showLoader(false);
                            $window.location.href = apiUrl + '/api/download-legacy-invoice-report';
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            //$scope.reloadData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ INVOICES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "Search :",
                    "sZeroRecords": "NO MATCHING INVOICE FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searchDelay', 500)
                .withOption('order', [1, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });


            $scope.dtColumns = [];
            $scope.ori_invoice = {};

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('legacy_invoice_id').withTitle('Legacy Invoice no').withOption('searchable', true),
                DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice No').withOption('searchable', true),
                DTColumnBuilder.newColumn('firm_name').withOption('name','ATTORNEY_tbl.NAME').withTitle('Billing Firm').withOption('searchable', true),
                DTColumnBuilder.newColumn('firm_contact').withOption('name','ATTORNEY_tbl.PHONE').withTitle('Billing Firm Phone').withOption('searchable', true),
                DTColumnBuilder.newColumn('firm_address').withOption('name','ATTORNEY_tbl.ADDR_LINE1').withTitle('Billing Firm Address').withOption('searchable', true),
                DTColumnBuilder.newColumn('dte_invoice').withOption("width", "5%").withTitle('Invoice Date').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'INVOICE_tbl.dte_invoice').renderWith(function (data, type, full, meta) {
                    if(data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if(dte == 'Invalid date'){
                            return '-';
                        }else{
                            return dte;
                        }
                    } 
                    else {
                        return '-';
                    }

                }),
                DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('INV_BAL_DUE').withTitle('Balance Due').withOption('searchable', false).notSortable().withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }
                }),
                DTColumnBuilder.newColumn('business_unit_name').withOption('name','business_units.name').withTitle('Business Unit').withOption('searchable', true),

            );

        }
    ]);
