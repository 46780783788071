angular.module('deitz').controller('browseAssignedBatchController', [
    '$scope', 'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', '$compile', '$uibModal', 'Notification', '$rootScope',
    function (
        $scope, DTOptionsBuilder, DTColumnBuilder, commonFactory, $compile, $uibModal, Notification, $rootScope
    ) {

        $scope.title = "Browse Batch";

        $scope.dtInstance = {};

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function () {

            $scope.reloadData();
        };
        $scope.viewBatch = function (id, batch_no) {

            var batchModal = $uibModal.open({
                templateUrl: "modules/production/batch_popup.html",
                controller: "viewBatchController",
                resolve: {
                    id: function () {
                        return id;
                    },
                    batch_no: function () {
                        return batch_no;
                    },
                    dtInstance: function () {
                        return $scope.dtInstance;
                    },
                    showAction:function (){
                        return true
                    }
                },
                windowClass: 'small-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
            });


        };

        $scope.headerCompiled = false;

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
        $scope.changeStatus = function (id, status) {
            const allEqual = (arr, key) =>
            arr.every((v) => v[key] === arr[0][key] || v[key] === null);

            if(allEqual($scope.currentPageJobArray[id].batch_details, "status")){
            var swltext = (status == 1) ? 'You are going to check in this branch.' : 'You are going to checkout this branch.';
            swal({
                title: "Are you sure ?",
                text: swltext,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        var requestUrl = (status == 1) ? '/api/update_batch_checkin' : '/api/update_batch_checkout';
                        commonFactory.post(requestUrl, { id: id, status: status })
                            .success(function (response) {
                                if (response && response.result && !response.error) {
                                    if (status == 1) {
                                        Notification.success("Batch check in successfully");
                                    }
                                    else {
                                        Notification.success("Batch check out successfully");

                                    }
                                    $scope.reloadData();
                                    $rootScope.showLoader(false);

                                } else {
                                    Notification.error(response.result.message);
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
            }
            else{
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: "Can't checkout batch ! \n please do manually checkout",
                  });
            }

        }
        $scope.actionRoles = function (data, type, full) {

            if (full.status == 1) {
                return '<a  ng-click="changeStatus(' + full.id + ', ' + full.status + ')" class="btn btn-danger btn-xs">ASSIGNED</a>';
            }
            else if (full.status == 2) {
                return '<a  ng-click="changeStatus(' + full.id + ', ' + full.status + ')" class="btn btn-warning btn-xs">IN-PROGRESS</a>';
            }
            else if (full.status == 3) {
                return '<a class="btn btn-success btn-xs">Completed</a>';
            }
        };

        $scope.tableOrder = 4;

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to

                data.production_person_id = $scope.auth_user.id;
                data.datatable=true;
                commonFactory.showDataTable('/api/get_all_batches', data).success(function (res) {
                    if (res.error) {
                        // $scope.reloadData();
                    }
                    else {
                        // console.log(res.data);
                        callback(res);
                    }
                }).error(function (err) {
                    console.log(err);
                    if (err.error !== "token_not_provided") {
                        //$scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sProcessing": "<img src='img/loading_bar.gif'/>"
            })
            // .withOption('stateSave', true)
            .withOption('serverSide', true)
            .withOption('searchDelay', 500)
            .withOption('order', [$scope.tableOrder, 'asc'])
            .withPaginationType('simple_numbers')
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [5, 10, 25, 50],
                [5, 10, 25, 50]
            ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('drawCallback', function (settings, json) {
                $scope.currentPageJobArray = [];
                this.api().data().each(function (item, index) {
                    $scope.currentPageJobArray[item.id] = item;
                });
            });

        $scope.dtColumns = [];


        $scope.dtColumns.push(
            DTColumnBuilder.newColumn('batch_no').withTitle('Batch No').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                return '<a   ng-click="viewBatch(' + full.id + ', \'' + full.batch_no + '\')">' + full.batch_no + '</a>';
            }),
            DTColumnBuilder.newColumn('assign_by.name').withTitle('Assign By').withOption('searchable', false),
            DTColumnBuilder.newColumn('assign_date').withTitle('Assign Date').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                if (data) {
                    var dte = moment(full.assign_date).format('MM/DD/YYYY');
                    if (dte == 'Invalid date') {
                        return '-';
                    } else {
                        return dte;
                    }
                }
                else {
                    return '-';
                }

            }),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles),
            DTColumnBuilder.newColumn("status").withTitle("Status").withOption('visible', false)
        );


    }
])

