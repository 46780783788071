angular.module('deitz').controller('browseBatchController', [
    '$scope',  'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', '$http', 'apiUrl', '$compile', '$uibModal', 'Notification','$rootScope',
    function (
        $scope, DTOptionsBuilder, DTColumnBuilder, commonFactory, $http, apiUrl, $compile, $uibModal, Notification,$rootScope
    ) {

        $scope.title = "Browse Batch";

        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.dtInstance = {};

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function () {

            $scope.reloadData();
        };
        $scope.viewBatch= function(id,batch_no){

            var batchModal = $uibModal.open({
                templateUrl: "modules/production/batch_popup.html",
                controller: "viewBatchController",
                resolve: {
                    id: function () {
                        return id;
                    },
                    batch_no: function () {
                        return batch_no;
                    },
                    dtInstance : function (){
                        return $scope.dtInstance;
                    },
                    showAction:function (){
                        return false
                    }
                },
                windowClass: 'small-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
            });
    
    
        };
    
        $scope.headerCompiled = false; 

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
        $scope.changeStatus =  function(id,status){
            var swltext = (status == 1) ? 'You are going to check in this branch.' : 'You are going to checkout this branch.';
            swal({
                    title: "Are you sure ?",
                    text: swltext,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                         $rootScope.showLoader(true);
                         var requestUrl = (status== 1) ? '/api/update_batch_checkin' : '/api/update_batch_checkout';
                         commonFactory.post(requestUrl,{id:id,status:status})
                            .success(function(response) {
                                if (response && response.result && !response.error) {
                                    if(status==1){
                                        Notification.success("Batch check in successfully");
                                    }
                                    else{
                                        Notification.success("Batch check out successfully");

                                    }
                                    $scope.reloadData();
                                    $rootScope.showLoader(false);
                        
                                }else{
                                    Notification.error(response.result.message);
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        }

        $scope.deleteBatch = function(batchId,batchCount) {
          
            if(batchCount==0){
                swal({
                        title: "Are you sure?",
                        text: "you want to delete this batch!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Yes, delete it!",
                        cancelButtonText: "No, cancel it!",
                        closeOnConfirm: false,
                        closeOnCancel: false
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/delete_batch/'+batchId)
                                .success(function(response) {
                                        $rootScope.showLoader(false);
                                        console.log(response);
                                        swal('Deleted!', response.message||'Batch deleted successfully', 'success');
                                        $scope.reloadData();
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                    swal('Cancelled', err.message||'Something gone wrong', 'error');
                                });
                        } else {
                            $rootScope.showLoader(false);
                            swal("Cancelled", "Your Batch is safe :)", "error");
                        }
                    });
            }
            else{
                swal({
                    type: 'error',
                    title: 'Oops...',
                    text: "Can't delete batch ! \n please delete job manually",
                  });
            }
    };

        $scope.actionRoles = function (data, type, full) {
        
                var action='';
                if(full.status==1 || full.status==2){
                    action+= "<a ng-if='havePermission(\"production_metrics\",\"delete\")' style='margin-right:10px'  class='btn btn-danger btn-circle' ng-click='deleteBatch(" + full.id + ","+full.batch_details_count+")'><i class='fa fa-trash'></i></a>";
                    action += "<a ng-if='havePermission(\"production_metrics\",\"edit\")'  class='btn btn-primary btn-circle' ui-sref='production.edit_batch({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";
                }
                else{
                    action+= '';
                }
                // add delete button
                return action;
        };
        $scope.batchStatus =function (data, type, full) { 
            if(full.status==1){
                return "<label class='label label-danger'>Pending</label>";
            }
            else if(full.status==2){
                return "<label class='label label-warning'>In Progress</label>";

            }
            else if(full.status==3){
                return "<label class='label label-success'>Completed</label>";

            }
        }


        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to
                data.datatable=true;
                data.business_unit_ids = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); 

                commonFactory.showDataTable('/api/get_all_batches', data).success(function (res) {
                    if (res.error) {
                        // $scope.reloadData();
                    }
                    else {
                        // console.log(res.data);
                        callback(res);
                    }
                }).error(function (err) {
                    console.log(err);
                    if (err.error !== "token_not_provided") {
                        //$scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sProcessing": "<img src='img/loading_bar.gif'/>"
            })
            // .withOption('stateSave', true)
            .withOption('serverSide', true)
            .withOption('searchDelay', 500)
            .withOption('order', [[4, 'asc'],[0, 'desc']])
            .withPaginationType('simple_numbers')
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [5, 10, 25, 50],
                [5, 10, 25, 50]
            ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });
      
        $scope.dtColumns = [];


            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('batch_no').withTitle('Batch No').withOption('searchable', true).renderWith(function (data, type, full, meta) {
                    return '<a   ng-click="viewBatch('+full.id+', \''+full.batch_no+'\')">'+full.batch_no+'</a>';
                }),
                DTColumnBuilder.newColumn('assign_to.name').withTitle('Assign To').withOption('searchable', true),
                DTColumnBuilder.newColumn('assign_by.name').withTitle('Assign By').withOption('searchable', true),
                DTColumnBuilder.newColumn('assign_date').withTitle('Assign Date').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    if (data) {
                        var dte = moment(full.assign_date).format('MM/DD/YYYY');
                        if (dte == 'Invalid date') {
                            return '-';
                        } else {
                            return dte;
                        }
                    }
                    else {
                        return '-';
                    }
    
                }),
                DTColumnBuilder.newColumn("status").withTitle("Status").withOption('searchable', false).renderWith($scope.batchStatus),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles),
            );
    
        
    }
])

