angular.module('deitz')
    .controller('editMarkedExhibitsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'id',
        'JOB_NO',
        'depoUserId',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  id,
                  JOB_NO,
                  depoUserId,
                  $uibModalInstance,
                  configFactory) {
            $scope.markedExhibit = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'MANAGE MARKED EXHIBITS';
            $scope.isPdfUploaded = false;
            $scope.markedExhibit.JOB_NO = JOB_NO;
            $scope.markedExhibit.depoUserId = depoUserId;
            $scope.markedExhibit.allFiles = [];

            if (id) {
                $http.get(apiUrl + '/api/marked-exhibits/' + id + '/edit')
                    .success(function (response) {
                        if (response.status !== 200){
                            Notification.error(response.result.message);
                            $scope.closePopupWindowSimple();
                            return false;
                        }
                        $scope.markedExhibit.exhibit_type = response.result.job_exhibit_document.exhibit_type;
                        $scope.markedExhibit.exhibit_number = response.result.job_exhibit_document.exhibit_number;
                        $scope.markedExhibit.filename = response.result.job_exhibit_document.filename;
                        $scope.markedExhibit.witness = response.result.job_exhibit_document.witnesses.map((witness) => witness.id)

                    }).error(function (err) {
                    if (err.error) {
                        Notification.error(err.error);
                    }
                });
            }

            $scope.clickUpload = function () {
                $("#file").trigger('click');
            }

            $scope.fileChange = function () {
                $scope.dropboxText();
            }

            $scope.dropboxText = function () {
                var file = $('#file')[0].files;
                if (file.length > 0) {
                    $(".upload_h1").html(file.length + " file(s) selected. <br> Provide a below information and press the 'save' button.");
                } else {
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            commonFactory.get('/api/getwitnessfordiary/' + JOB_NO)
                .success(function (response) {
                    if (response.result && response.result.witnessDetails) {
                        $rootScope.showLoader(false);
                        $scope.witness_info = response.result.witnessDetails;
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

            $scope.editMarkedExhibit = function (markedExhibit, isValid) {
                $scope.formSubmit = true;
                if (isValid) {
                    $rootScope.showLoader(true);
                    Upload.upload({
                        url: apiUrl + '/api/marked-exhibits/' + id + '/update',
                        data: $scope.markedExhibit
                    }).success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success(response.result.message);
                                $scope.closePopupWindowSimple();
                            } else if (response.status == 400) {
                                Notification.error(response.result.message);
                            }
                        }
                    });

                } else {
                    if ($scope.markedExhibitsCreateForm.$error.pattern) {
                        Notification.error('Please upload accepted file only.');
                    } else {
                        Notification.error('Exhibit Type, Exhibit Number, Witness(es) - must be required.');
                    }
                }
            }

            $scope.closePopupWindowSimple = function () {
                $uibModalInstance.dismiss({isChanged: true});
            };
        }]);