angular.module('deitz').controller('editmodalityController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromMod, $uibModalInstance
    ) {

        $scope.modality = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Modality';
     

        commonFactory.get('/api/connection-methods').then(function(response) { 
            $scope.connection_methods = response.data.result.resources;  
        });
       

        //Edit Mode of modality.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/modality/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.modality_detail) {
                        $scope.modality.name = response.result.modality_detail.name || '';
                        // $scope.modality.slug = response.result.modality_detail.slug || '';
                        if(response.result.modality_detail.connection_method.length){
                           
                           
                            
                            $scope.modality.connection_method_ids = []
                            inx = 0
                            response.result.modality_detail.connection_method.forEach(element => {
                                $scope.modality.connection_method_ids[inx] =element.connection_method_id
                                inx++
                            });
                            
                            
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of modality
        $scope.add = function(modality, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/modality/' + id, $scope.modality)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Modality updated successfully");
                                
                                    $state.go("code_tables.modality_browse");
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
