angular.module('deitz').controller('addServiceTypeController', ['$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.service_type = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Service Type';
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        commonFactory.get('/api/additionalinfo_list').then(function(response) { 
            $scope.additionalinfo_list = response.data.result.data;  
        });
        $scope.add = function(service_type, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/service-type', $scope.service_type)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("Service Type added successfully");

                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.service_type.id);
                                }else{
                                    $state.go("code_tables.service_type_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
