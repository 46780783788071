angular.module('deitz').controller('addSourceController',[
	'$state',
  '$rootScope',
	'$scope',
	'$http',
	'codeFactory',
	'Upload',
	'apiUrl',
	'Notification',
	'$uibModal', 
	function($state,
       $rootScope,
			 $scope,
			 $http,
			 codeFactory,
			 Upload,
			 apiUrl,
			 Notification,
			 $uibModal
       ){ 
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.title= $state.current.data.pageTitle;
        $scope.source={};
        $scope.formSubmit = false;  
        $scope.isImage=false;
        $scope.editImage=true; 
        function readURL(input) { 
          if (input) {  
              var reader = new FileReader();
              reader.onload = function (e) {
                  $scope.imagePreview = e.target.result; 
              } 
              $scope.isImage=true; 
              reader.readAsDataURL(input);
          }
        }
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
          $scope.BusinessUnitsList = response.data.result.data;  
          $scope.BusinessUnitsList.unshift({id:'',name:''});
        });
        $scope.$watch('source.logo',function(newValue, oldValue){ 
            readURL(newValue);
        }); 
        
        $scope.addSource = function(isValid){  
        $scope.formSubmit = true;  
        	if(isValid){  
          $rootScope.showLoader(true); 
        		Upload.upload({
                        url: apiUrl+'/api/add_source',
                        data: {sourceData:$scope.source}
                    }).then(function (resp) { 
                    	if(resp.status == 200){
 							          Notification.success('Source Inserted Successfully');
                        $state.go('code_tables.source_browse');
                    	}
                    },function(err){
                    	Notification.error(err);
                    });
        	}
        } 
}]);