angular.module('deitz').controller('addClaimrepController', [
    '$scope', '$rootScope', '$state', 'codeFactory', 'commonFactory', 'Notification', '$uibModalInstance', 'fromModal',

    function($scope, $rootScope, $state, codeFactory, commonFactory,  Notification, $uibModalInstance, fromModal
    ){

        $scope.claim_rep = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Claim Representative';
        $scope.bread_title = "Add insurance company";
        $scope.claim_rep.auto_touch = 'N';


        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        $scope.addClaimrep = function(claim_rep, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/claimrepresentative', $scope.claim_rep)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Claim Representative added successfully");
                                if(fromModal){
                                    $uibModalInstance.close(response.result.claim_rep);
                                    return
                                }
                                $state.go("insurance.claim_rep_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };
    }]);
