angular.module('deitz').controller('adddepartmentController', [
    '$scope', '$http', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'apiUrl', 'Notification', '$uibModalInstance',
    function($scope, $http, $state, $rootScope, codeFactory, commonFactory, apiUrl, Notification, $uibModalInstance) {

        $scope.businessunit = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Business Unit';
        $scope.department = {};
       

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data; 
        });
        

        $scope.addDepartment = function(isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                
                var submit_data = {
                    departmentId: $scope.department.departmentid,
                    businessUnit: $scope.department.business_unit,
                    region: $scope.region,
                    legacyName: $scope.legacy_name
                };
                console.log(submit_data);
                $rootScope.showLoader(true);
                commonFactory.post('/api/department', submit_data)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.status == 200) {
                            Notification.success("Department added successfully");
                            $state.go("code_tables.department_browse")
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
