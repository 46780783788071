angular.module('deitz')
            .controller('firmSalesForceController',
                ['$scope',
                 '$rootScope',
                 '$state',
                 'commonFactory',
                 'invoiceFactory',
                 'Notification',
                 'DTOptionsBuilder',
                 'DTColumnBuilder',
                 '$q',
                 '$compile',
                 '$uibModal',
                 '$http',
                 'apiUrl',
                    function($scope,
                             $rootScope,
                             $state,
                             commonFactory,
                             invoiceFactory,
                             Notification,
                             DTOptionsBuilder,
                             DTColumnBuilder,
                             $q,
                             $compile,
                             $uibModal,
                             $http,
                             apiUrl
                                ){

        
        $scope.firm_salesforce = {};
    

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }; 
        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };
        $scope.searchData = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };
        $scope.clear = function(){
            $scope.selectedFirms = [];
            $scope.salesPersonsIds = [];
            $scope.termIds = [];
            $scope.resetSearch();
        }
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {

                data.excludeSalesForceId = $scope.excludeSalesForceId ? $scope.excludeSalesForceId : false;
                data.includeSalesForceId = $scope.includeSalesForceId ? $scope.includeSalesForceId : false;

                angular.forEach(data.columns,function(value, key){
                    data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                });

                commonFactory.showDataTable('/api/firms/show-data-table', data)
                .success(function(res) {
                    if (res) {
                        $rootScope.showLoader(false);
                        callback(res);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            })
            .withLanguage({
                "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

            })

            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            .withOption('lengthMenu',[[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]])
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'asc'])
            .withOption('createdRow', $scope.compileDatatable)
            .withLightColumnFilter({
                '0': {
                    type: 'text'
                },
                '1': {
                    type: 'text'
                }

            });

  
        $scope.currencyFormat = function(data, type, meta, meta){
            if(data == null || data == ''){
                return '$0.00';
            }
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };

        $scope.dtColumns = [ 
            DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('Name').withOption("width", "15%").renderWith(function(data,type,full,meta){
                var isStatus = full.STATUS ? true : false; 
                return '<a ui-sref="firms.manage.step_one({id : ' + full.id + '})">'+data+'</a><br><small ng-if='+isStatus+'><b><i>'+full.STATUS+'</i></b></small>'
            }),
            DTColumnBuilder.newColumn('ADDR_LINE1').withOption('defaultContent', '').withTitle('Address').withOption("width", "10%").withOption('name', 'ATTORNEY_tbl.ADDR_LINE1'),
            // DTColumnBuilder.newColumn('reptype.FIRST_NAME').withOption('defaultContent', '').withTitle('Account Executive')
            // .renderWith(function(data,type,full,meta){ 
            //     if (full.reptype) {
            //         var name = escapeString(full.reptype.name);
            //         return sales_person = "<div>{{'"+name+"'}}</div>";  
            //     } else {
            //         return '-';
            //     }
            // }).withOption("width", "10%"),
            // DTColumnBuilder.newColumn('YTD_PAID').withOption('defaultContent', '').withTitle('YTD Paid').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('YTD_BILLED').withOption('defaultContent', '').withTitle('YTD Billed').renderWith($scope.currencyFormat).withOption("width", "6%"),
            DTColumnBuilder.newColumn('PYR_BILLED').withOption('defaultContent', '').withTitle('PYR Billed').renderWith($scope.currencyFormat).withOption("width", "6%"),
            DTColumnBuilder.newColumn('PYR2_BILLD').withOption('defaultContent', '').withTitle('PYR2 Billed').renderWith($scope.currencyFormat).withOption("width", "6%"),
            // DTColumnBuilder.newColumn('BAL_DUE').withOption('defaultContent', '').withTitle('BAL DUE').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('PHONE').withOption('defaultContent', '').withTitle('Tel.No').notSortable().withOption("width", "10%"),
            DTColumnBuilder.newColumn('CITY').withOption('defaultContent', '').withTitle('City').withOption("width", "5%"),
            DTColumnBuilder.newColumn('STATE').withOption('defaultContent', '').withTitle('State').withOption("width", "5%"),
            DTColumnBuilder.newColumn('saleforce_id').withOption('defaultContent', '').withTitle('Salesforce Id').withOption("width", "10%").renderWith(function(data, type, full, meta){
                $scope.firm_salesforce[full.id] = data;
                var editable_txt = data ? data : 'ADD SALESFORCE ID';

                return '<a href="javascript:void(0)" editable-text = firm_salesforce[' +full.id +'] onaftersave="changeSalesForceId('+ full.id +')" ng-bind="firm_salesforce['+full.id+'] || \''+ editable_txt +'\'"></a>';

            }),
            // DTColumnBuilder.newColumn(null).withTitle('').notSortable().withOption("width", "5%")
            // .renderWith(function(data, type, full, meta) {
            //     var firmName = escape(data.NAME);

            //     bindButtons = '<div class="btn-group btn-sm" uib-dropdown>' +
            //         '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
            //         '<ul role="menu" uib-dropdown-menu="" style="left: -100px!important;">'+
            //             '<li uib-tooltip="Edit Firm">'+
            //                 '<a class="btn btn-xs btn-default" ui-sref="firms.manage.step_one({id : ' + data.id + '})"><i class="fa fa-pencil"></i> &nbsp; Edit </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Admins">'+
            //                 '<a class="btn btn-xs btn-default" ui-sref="firms.manage.ebt({id : ' + data.id + '})"><i class="fa fa-user"></i> &nbsp; Admins </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Browse Attorneys">'+
            //                 '<a class="btn btn-xs btn-default" ui-sref="attorney.browse({firmId : ' + data.id + '})"><i class="fa fa-eye"></i> &nbsp; Attorneys </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Show Statements">'+
            //                 '<a class="btn btn-xs btn-default" ui-sref="statements.print_statements({attId:'+ data.id +'})"><i class="fa fa-book"></i> &nbsp; Statements </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Pay">'+
            //                 '<a class="btn btn-xs btn-default" ng-click="autoLogin(\''+data.NAME_KEY+'\')"><i class="fa fa-money"></i> &nbsp; Pay </a>'+
            //             '</li>'+

            //             // '<li uib-tooltip="A/R Fax Utility">'+
            //             //     '<a class="btn btn-xs btn-default" ng-click="open_A_R_Fax_window(\''+data.NAME_KEY+'\')"><i class="fa fa-fax"></i> &nbsp; A/R Fax Utility </a>'+
            //             // '</li>'+

            //             '<li uib-tooltip="Browse Firm Invoices">'+
            //                 '<a class="btn btn-xs btn-default" ng-click="showFirmInvoices(' + data.id + ',\''+firmName+'\')"><i class="fa fa-envelope-o"></i> &nbsp; Invoices </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Work In Progress Report">'+
            //                 '<a class="btn btn-xs btn-default" ui-sref="representative.work_in_progress_by_reporter({attId:'+data.id+'})"><i class="fa fa-line-chart"></i> &nbsp; Work In Progress </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="A/R Notes">' +
            //                 '<a class="btn btn-xs btn-default" ui-sref="accountReceivableNotes.browse({attId:'+data.id+'})"><i class="fa fa-pencil-square-o"></i> &nbsp; A/R Notes </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Stats Report">' +
            //                 '<a class="btn btn-xs btn-default"  ng-click="open_STATS_report(' + data.id + ')"><i class="fa fa-file"></i>&nbsp; Stats Report </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Job Profit Report">' +
            //                 '<a class="btn btn-xs btn-default"  ui-sref="index.job_profit({attId:' + data.id + '})"><i class="fa fa-pie-chart"></i>&nbsp; Job Profit </a>'+
            //             '</li>'+

            //             '<li uib-tooltip="Account Credits Report">' +
            //                 '<a class="btn btn-xs btn-default"  ui-sref="index.account_credits_report({attId:' + data.id + '})"><i class="fa fa-money"></i>&nbsp; Account Credits </a>'+
            //             '</li>';

            //             if(data.saleforce_id){ 
            //                bindButtons +=  '<li uib-tooltip="SalesForce">' +
            //                         '<a class="btn btn-xs btn-default" href="https://nextgen.my.salesforce.com/'+data.saleforce_id+'" target="_blank"><i class="fa fa-cloud"></i>&nbsp; SalesForce </a>'+
            //                     '</li>';
            //             }  
            //             bindButtons += '</ul></div>';

            //     return bindButtons;


            // })
        ];
         

        $scope.changeSalesForceId = function(firmId){
            $rootScope.showLoader(true); 
            
            var data = { firmId : firmId };
            data.saleforce_id = $scope.firm_salesforce[firmId];
            data.type = 'saleforce_id'; 

            commonFactory.post('/api/update-firm-profile',data).success(function(res){
                $rootScope.showLoader(false);
                if(res.error){
                    
                    Notification.error(res.result.message);
                
                }else{ 
                     
                    $scope.reloadData(); 
            
                }
            });
        }
    }]);
