angular.module('deitz')
    .controller('viewInvoiceController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        '$filter',
        'commonFactory',
        'diaryFactory',
        '$stateParams',
        '$uibModal',
        'invoiceFactory',
        'invoice_id',
        '$uibModalInstance',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  $state,
                  $timeout,
                  $filter,
                  commonFactory,
                  diaryFactory,
                  $stateParams,
                  $uibModal,
                  invoiceFactory,
                  invoice_id,
                  $uibModalInstance) {

            $scope.invoice = {'orderingAttorneyFirms': {}};
            $scope.toEmail;
            $scope.is_bad_claim_number = false;
            $scope.emailSubject;
            
            diaryFactory.get('/api/get-charge-codes').success(function (response) {
                //console.log(response.result);
                //$scope.additional_charges =  $scope.additional_charges.concat(response.result);
                $scope.additional_charges = response.result;
                //console.log($scope.additional_charges);
            });

            $rootScope.showLoader(true);
            invoiceFactory.get('/api/invoice/' + invoice_id).success(function (response) {

                $timeout(function(){
                    $scope.invoice = response.result.invoice;
                    console.log(" invoice ", $scope.invoice);
                    $scope.invoice.JOB_NO = response.result.invoice.job.JOB_NO;
                    $scope.invoice.CAPTION = response.result.invoice.job.CAPTION;
                    //debugger;
                    $scope.invoice.total_rep_pay_amt=0;
                    $scope.invoice.typ_pay_amt=0;
                    
                    $scope.getResourceWiseJobPayments(response.result.invoice.diary_data.job_resources);

                    
                    $scope.invoice.DATE_TAKEN = response.result.invoice.job.DATE_TAKEN;

                    $scope.invoice.INV_DT = $filter('date')($scope.invoice.dte_invoice, "MM/dd/yyyy");
                    $scope.invoice.PAID_DT = $filter('date')($scope.invoice.PAID_DT, "MM/dd/yyyy");

                    // console.log(response.result.diaryDetails);
                    $scope.diaryData = response.result.invoice.diary_data;

                    //Assign the variable which will save directly into database table
                    $scope.invoice.IndexNumber = angular.copy($scope.diaryData.IndexNumber || "");

                    $scope.invoice.orderingAttorneyFirms = {};

                    $scope.invoice.orderingAttorneyFirms.NAME = $scope.diaryData.attorney_firm.NAME;

                    $scope.invoice.orderingAttorneyFirms.attorney_name = {};
                    $scope.invoice.orderingAttorneyFirms.attorney_name.NAME = $scope.diaryData.attorney_name ? $scope.diaryData.attorney_name.NAME : '';

                    //console.log($scope.invoice, $scope.invoice.orderingAttorneyFirms.NAME);
                    $scope.invoice.ordering_attname_id = angular.copy($scope.diaryData.attname_id);
                    //$scope.invoice.order_firm_id = angular.copy($scope.diaryData.attorney_id);

                    $scope.invoice.HELD_AT1 = angular.copy($scope.diaryData.HELD_AT1);
                    $scope.invoice.total_job_invoices_amount = angular.copy($scope.diaryData.total_job_invoices_amount);
                    $scope.invoice.total_job_amount = angular.copy($scope.diaryData.total_job_invoices_amount);
                    $scope.invoice.CLM_NOTE = angular.copy($scope.diaryData.CLM_NOTE);
                    $scope.invoice.direct_billing_notes = angular.copy($scope.diaryData.direct_billing_notes);

                    $scope.invoice.ordering_claim_representative = angular.copy($scope.diaryData.claim_representative || {});
                    $scope.invoice.billing_claim_representative = angular.copy($scope.diaryData.claim_representative || {});

                    $scope.invoice.ORDERING_CLM_NO = angular.copy($scope.diaryData.CLM_NO);
                    $scope.invoice.ORDERING_FILE_NO = angular.copy($scope.diaryData.FILE_NO);

                    $scope.invoice.BILLING_CLM_NO = $scope.invoice.ClaimNumber;
                    $scope.invoice.BILLING_FILE_NO = $scope.invoice.FILE_NO;

                    var reporting_salesman = [];
                    angular.forEach($scope.diaryData.diary_reporting_salesman,function(value, key){
                        if(value.reptype && value.reptype.name){
                            reporting_salesman[key] = value.reptype.name;
                        }
                    });
                    $scope.diaryData.reporting_salesman = reporting_salesman;

                    var records_salesman = [];
                    angular.forEach($scope.diaryData.diary_records_salesman,function(value, key){
                        if(value.reptype && value.reptype.name){
                            records_salesman[key] = value.reptype.name;
                        }
                    });
                    $scope.diaryData.records_salesman = records_salesman;

                    //console.log(response.result.invoice.attorney_firms);
                    $scope.attorneyFirmData = response.result.invoice.attorney_firms;
                    $scope.invoice.attorneyFirms = angular.copy($scope.attorneyFirmData);

                    $scope.invoice.Room = parseFloat($scope.invoice.Room || 0.00);
                    $scope.invoice.RATE = parseFloat($scope.invoice.RATE || 0.00);
                    $scope.invoice.med_rate = parseFloat($scope.invoice.med_rate || 0.00);
                    $scope.invoice.MiniScriptPerPage = parseFloat($scope.invoice.MiniScriptPerPage || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.COPY_RATE || 0.00);
                    $scope.invoice.exp_rate = parseFloat($scope.invoice.exp_rate || 0.00);
                    $scope.invoice.WEB_AMOUNT = parseFloat($scope.invoice.WEB_AMOUNT || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.WordIndexingPerPage || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.APP_RATE || 0.00);
                    $scope.invoice.daily_rate = parseFloat($scope.invoice.daily_rate || 0.00);
                    $scope.invoice.CDCharge = parseFloat($scope.invoice.CDCharge || 0.00);
                    $scope.invoice.MiniScriptFlatRate = parseFloat($scope.invoice.MiniScriptFlatRate || 0.00);
                    $scope.invoice.INV_MINPG = parseFloat($scope.invoice.INV_MINPG || 0.00);
                    $scope.invoice.immed_rate = parseFloat($scope.invoice.immed_rate || 0.00);
                    $scope.invoice.ETranscript = parseFloat($scope.invoice.ETranscript || 0.00);
                    $scope.invoice.WordIndexingFlatRate = parseFloat($scope.invoice.WordIndexingFlatRate || 0.00);
                    $scope.invoice.INV_MIN = parseFloat($scope.invoice.INV_MIN || 0.00);
                    $scope.invoice.SOR = parseFloat($scope.invoice.SOR || 0.00);
                    $scope.invoice.rate_id = $scope.invoice.rate_id || null;
                    $scope.invoice.SHOW_CALCS = ($scope.invoice.SHOW_CALCS || 'No').toLowerCase();
                    $scope.invoice.Charge2 = parseFloat($scope.invoice.Charge2 || 0.00);
                    $scope.invoice.POSTAGE = parseFloat($scope.invoice.POSTAGE || 0.00);

                    //console.log($scope.invoice.attorneyFirms);
                    // $scope.invoice.orderingAttorneyFirms = angular.copy($scope.attorneyFirmData);

                    $scope.invoice.isEmail = true
                    if ($scope.invoice && $scope.invoice.isEmail) {
                        if (!$scope.invoice.ClaimNumber) {
                            $scope.emailSubject = "Missing Information Claim Number Empty For " + $scope.invoice.INV_NO + " Invoice"
                        } else if (!$scope.invoice.FILE_NO) {
                            $scope.emailSubject = "Missing Information File Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                        } else {
                            $scope.emailSubject = "Missing Information For "+$scope.invoice.INV_NO+" Invoice"
                        }
                    
                        if ($scope.invoice.invoice_submission_email_log) {
                            $scope.toEmail = $scope.invoice.invoice_submission_email_log.to_email
                        }
                    }
                }, 1000);



                //console.log($scope.invoices);
                $rootScope.showLoader(false);
            }).error(function () {

            });


            $scope.calculationAdditionalCharge = function ($index) {
                var $item = $scope.invoice.additional_charges[$index];
                console.log($item);
                // var am   ount = $item.AMT;

                //amount = Number(($item.amount / 2).toFixed(2));

                // $item.charge = Number(($item.QTY * amount / $item.SPLIT).toFixed($rootScope.round_to_decimal_number));

                $scope.invoice.additional_charges[$index] = $item;

                // Add the Charge of Complete additional Charge
                $scope.invoice.additional_charge_amount = $scope.invoice.additional_charges.reduce(function (pv, cv) {
                    if(cv.charge){
                        return pv + parseFloat(cv.charge);
                    }
                    else{
                        return pv;
                    }
                }, 0);


                $scope.calculateInvoiceAmount();
            };

            $scope.calculateRowWitnessCharge = function ($index, is_split_change) {

                var $item = $scope.invoice.witnessData[$index];

                // set default value for witness data
                $item.PAGE_START = parseFloat($item.PAGE_START || 0);
                $item.PAGE_END = parseFloat($item.PAGE_END || 0);
                $item.PAGES = parseFloat($item.PAGES || 0);
                $item.IndexPages = parseFloat($item.IndexPages || 0);
                $item.MiniScriptPages = parseFloat($item.MiniScriptPages || 0);
                $item.SPLIT = parseFloat($item.SPLIT || 0);
                $item.Enclosed = parseFloat($item.Enclosed || 0);
                $item.COPIES = parseFloat($item.COPIES || 0);
                $item.rate_type = $item.rate_type || 'rate';
                $item.addrate = $item.addrate || 'norm';
                $item.rate_flag = '-';


                if ($item.is_add) {

                    if (typeof is_split_change != "undefined" && is_split_change) {
                        $item.COPIES = $item.SPLIT + 1;
                    }


                    var medical_charge = 0;
                    var additional_rate_charge = 0;
                    var rate_value = 0;

                    // Calculate all Total Page while changing the Page number
                    // consider Indexpage and MininScrirtPage only when MS Per Page and WI Per Page have value greater then zero
                    $item.TOTAL_PAGES = eval(eval($item.PAGES || 0) + eval(($scope.invoice.WordIndexingPerPage > 0) ? $item.IndexPages : 0) + eval(($scope.invoice.MiniScriptPerPage > 0)?$item.MiniScriptPages : 0)) || 0;

                    // check the medical charge is checked
                    if ($item.medical_charge) {
                        medical_charge = round_to_number($scope.invoice.med_rate);
                        // console.log(medical_charge);
                    }


                    //Total = ((Copy-Rate* (split -1) + Rate) / split

                    //console.log("TRACK-2",$scope.invoice.COPY_RATE,$item.SPLIT,$scope.invoice.RATE);
                    $item.rate_flag = ($scope.invoice.STIPS == 'S' || $scope.invoice.STIPS == 'Y')? ($item.Enclosed > 1) ? 'o+'+$item.Enclosed : $item.Enclosed+'cc' : 'o+'+$item.Enclosed;

                    // Mark give the changes for STIP and Split, So detail please visit https://dtopinc.atlassian.net/browse/DEITZBO-209
                    // We need to consider only copy rate instead of calculating according formula
                    if((($scope.invoice.STIPS=='Y' || $scope.invoice.STIPS=='S') && $item.SPLIT == 1 && $item.Enclosed == 1 && $item.COPIES == 1) ||
                        ($scope.invoice.STIPS=='F' && $item.SPLIT == 1 && $item.Enclosed == 1 && $item.COPIES == 0)){
                        $item.total = round_to_number(eval($scope.invoice.COPY_RATE || 0));
                        $item.rate_flag = '1cc';

                    }else{
                        if($item.SPLIT > 0){
                            $item.total = round_to_number((eval($scope.invoice.COPY_RATE || 0) * (eval($item.SPLIT || 0) - 1) + eval($scope.invoice.RATE || 0)) / eval($item.SPLIT || 0));
                        }
                        else{
                            // Total = Copies * rate;
                            $item.total = round_to_number((eval($item.COPIES || 0) *  eval($scope.invoice.RATE || 0)));
                        }
                    }
                    // As Mark Suggested https://dtopinc.atlassian.net/browse/DEITZBO-446
                    if($item.SPLIT == 1 && $item.Enclosed == 1 && $item.COPIES > 1){
                        $item.total = round_to_number(eval($scope.invoice.COPY_RATE || 0) * $item.COPIES);
                        $item.rate_flag = $item.COPIES+'cc';
                    }

                    // As Mark Suggested https://dtopinc.atlassian.net/browse/DEITZBO-344
                    if($item.SPLIT == 0 && $item.Enclosed == 1 && $item.COPIES == 1){
                        $item.rate_flag = 'o';
                    }

                    //console.log($item.COPIES);

                    // If Additional Rate Type is selected
                    if (typeof $item.addrate !== 'undefined') {

                        if ($item.addrate == 'exp_rate') {
                            additional_rate_charge = $scope.invoice.exp_rate;
                        }
                        else if ($item.addrate == 'daily_rate') {
                            additional_rate_charge = $scope.invoice.daily_rate;
                        }
                        else if ($item.addrate == 'immed_rate') {
                            additional_rate_charge = $scope.invoice.immed_rate;
                        }
                        else {
                            additional_rate_charge = 0.00;
                        }
                    }


                    // When user select bust in row then we need to remove all charge from line pay

                    if ($item.rate_type && ($item.rate_type == 'bust' || $item.rate_type == 'sor' || $item.rate_type == 'invoice_min')) {
                        medical_charge = 0;
                        $item.medical_charge = false;
                        $item.addrate = 0;

                        $item.charge = 0;

                        if ($item.rate_type == 'bust') {
                            $item.line_pay = round_to_number($scope.invoice.INV_MIN);
                        }
                        if ($item.rate_type == 'sor') {
                            $item.line_pay = round_to_number($scope.invoice.SOR);
                        }

                        if ($item.rate_type == 'invoice_min') {
                            $item.line_pay = round_to_number($scope.invoice.INV_MINPG);
                        }
                    }
                    else {


                        //Charge = (Med or 0) + (Exp or Daily or immed) + (Total or 0)
                        $item.charge =  round_to_number(eval(medical_charge || 0) + eval(additional_rate_charge || 0) + eval($item.total || 0));
                        //$item.charge = round_to_number(eval(medical_charge || 0) + eval(additional_rate_charge || 0) + eval($item.total || 0), $rootScope.round_to_decimal_number);
                        //Line charge = Charge * Total Page.
                        $item.line_pay = round_to_number($item.charge * $item.TOTAL_PAGES);
                        //$item.line_pay = round_to_number($item.charge * eval($item.TOTAL_PAGES), $rootScope.round_to_decimal_number);

                    }
                    //console.log($item.med);


                    //$scope.invoice.witnessData.splice( $index, 0, $item );
                }
                else {

                    $item.charge = $filter('number')(0, 2);
                    $item.line_pay = $filter('number')(0, 2);
                }

                $scope.invoice.witnessData[$index] = $item;

                //console.log("here");
                // Calculate the Total Invoice Amount
                $scope.calculateInvoiceAmount();
            }

            $scope.calculateInvoiceAmount = function () {

                $scope.invoice.copy_fees = 0;

                angular.forEach($scope.invoice.witness_data, function ($item, index) {
                        $scope.invoice.copy_fees += eval($item.line_pay);
                });


                // $scope.invoice.total_invoice_amount = eval($scope.invoice.total_rep_pay_amt || 0) +
                //     eval($scope.invoice.copy_fees || 0) +
                //     eval($scope.invoice.additional_charge_amount || 0) +
                //     eval($scope.invoice.typ_pay_amt || 0);

                $scope.invoice.total_invoice_amount = eval($scope.invoice.copy_fees || 0) + eval($scope.invoice.additional_charge_amount || 0);

                $scope.invoice.total_job_invoices_amount = eval($scope.diaryData.total_job_invoices_amount || 0 ) + eval($scope.invoice.total_invoice_amount || 0);
            }

            $scope.closePopupWindow = function(){
                swal({
                        title: "Are you sure ?",
                        text: "Are you sure want to close this invoice?",
                        type: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            //$close(JOB_NO);
                            $uibModalInstance.close(true);
                        }
                    });
            };

            $scope.getResourceWiseJobPayments = function(resources){
                $scope.invoice.total_rep_pay_amt=0;
                $scope.invoice.typ_pay_amt=0;
                angular.forEach(resources, function (value, key) {
                    $scope.invoice.total_rep_pay_amt+= value.REP_PAYAMT;
                    $scope.invoice.typ_pay_amt+=value.TYP_PAYAMT;
                });
               $scope.invoice.typ_pay_amt= round_to_number($scope.invoice.typ_pay_amt);
               $scope.invoice.total_rep_pay_amt= round_to_number($scope.invoice.total_rep_pay_amt);
           }

            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
            };
            
            $scope.reloadSubject = function () {
                if ($scope.is_bad_claim_number) {
                    $scope.emailSubject = "Missing Information Invalid Claim Number For " + $scope.invoice.INV_NO + " Invoice"
                } else {
                    if (!$scope.invoice.ClaimNumber) {
                        $scope.emailSubject = "Missing Information Claim Number Empty For " + $scope.invoice.INV_NO + " Invoice"
                    } else if (!$scope.invoice.FILE_NO) {
                        $scope.emailSubject = "Missing Information File Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                    } else {
                        $scope.emailSubject = "Missing Information For "+$scope.invoice.INV_NO+" Invoice"
                    }
                }
            };

            $scope.ValidateEmail = function (mail) {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                    return (true)
                }
                return (false)
            }

            $scope.submit_invoice_send_mail = function () {
                if ($scope.ValidateEmail($scope.toEmail)) {
                    $rootScope.showLoader(true);
                    var wintness = ''
                    if($scope.invoice.witness_data) {
                        wintness = $scope.invoice.witness_data
                            .map(obj => obj.witness && obj.witness.NAME)
                            .join(", ");
                    }
                    var req_data = {
                        'toEmail': $scope.toEmail,
                        'INV_NO': $scope.invoice.INV_NO,
                        'is_bad_claim_number': $scope.is_bad_claim_number,
                        'subject': $scope.emailSubject,
                        'ClaimNumber': $scope.invoice.ClaimNumber,
                        'FILE_NO': $scope.invoice.FILE_NO,
                        'dte_invoice': $scope.invoice.dte_invoice,
                        'INV_AMT': $scope.invoice.INV_AMT,
                        'FirmName': $scope.invoice.attorney_firms.NAME,
                        'IndexNumber': $scope.invoice.IndexNumber,
                        'CaseName': $scope.invoice.job.CAPTION,
                        'Witness': wintness,
                        'DateOfDepo': $scope.invoice.job.DATE_TAKEN
                    }
                    commonFactory.post('/api/send-email-get-claim-number', req_data).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {

                        } else {
                            $scope.closePopupWindowSimple();
                            setTimeout(function () {
                                document.getElementById('reloadClaimNumberSuccesPopupUpdate').click();
                            }, 400);
                        }
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                    });
                }
            };


        }]);