angular.module('deitz').controller('editjobtypeController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    'jobtypeId',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        jobtypeId,
        $uibModalInstance
    ){

        $scope.jobtype = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Job Type';
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        $scope.modalId = jobtypeId;
        
        //Edit Mode of jobtype.
        if ($state.params && $state.params.id) {
            var id = jobtypeId ? jobtypeId : $state.params.id;
            commonFactory.get('/api/jobtype/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.jobtype_detail) {
                        $scope.jobtype.name = response.result.jobtype_detail.name || '';
                        $scope.jobtype.active = response.result.jobtype_detail.active || '';
                        $scope.jobtype.sort_order = response.result.jobtype_detail.sort_order || null;
                        $scope.jobtype.isDefault = response.result.jobtype_detail.isDefault || '0';
                        // $scope.jobtype.slug = response.result.jobtype_detail.slug || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of Jobtype
        $scope.addJobtype = function(jobtype, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = jobtypeId ? jobtypeId : $state.params.id;
                commonFactory.put('/api/jobtype/' + id, $scope.jobtype)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Jobtype updated successfully");

                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.jobtype_update.id);
                                }else{
                                    $state.go("code_tables.job_type_browse");
                                }
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
