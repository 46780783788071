angular.module('deitz').controller('editeuoFirmController', [
    '$scope', '$state', '$rootScope', '$http', 'Upload', 'apiUrl', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, $http, Upload, apiUrl, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.display_euo = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Euo Firm';
        $scope.isPdfUploaded = false;
        $scope.id = $state.params.id;

        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/display-euo-firms/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.euo_firms) {
                        $scope.display_euo.name = response.result.euo_firms.name || '';
                        $scope.display_euo.user_id = response.result.euo_firms.user_id || '';

                        if(response.result.euo_firms.help_file){
                            $scope.isPdfUploaded = true;
                        }

                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $http.get(apiUrl + '/api/get-users-list').success(function(response) {
            $scope.UsersList = response.result;
        });

        $scope.viewFile = function(id){
            if(id){
                $http.get(apiUrl + '/api/get-file-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.deleteFile = function(id){
            if(id){
                $rootScope.showLoader(true);
                swal({
                    title: "Are you sure want to remove this help file?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $http.get(apiUrl + '/api/remove-help-file', {
                            params: {
                                id: id
                            }
                        }).then(function(response) {
                            $rootScope.showLoader(false);
                            if(!response.data.error){
                                $scope.isPdfUploaded = false;
                                Notification.success(response.data.message);
                            }else{
                                Notification.error(response.data.message);
                            }
                        });
                    }
                });

            }
        }

        $scope.addEuoFirms = function(display_euo, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                Upload.upload({
                    url: apiUrl+'/api/display-euo-firms/' + id + '/update',
                    data: $scope.display_euo
                }).then(function (response) { 
                    $rootScope.showLoader(false);
                    if (response) {
                        if (response.data.status == 200) {
                            Notification.success("Euo Firm updated successfully");
                            $state.go("code_tables.euo_firm_browse");
                        }else if(response.data.status == 400){
                            Notification.error(response.data.result.message);
                        }
                    }
                },function(err){
                    $rootScope.showLoader(false);
                });
            }
        }

    }]);
