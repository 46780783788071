angular.module('deitz')
    .controller('certificationExpirationController', [
        '$scope',
        '$rootScope',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        function (
            $scope,
            $rootScope,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
        ) {

            $scope.dtInstance = {};
            $scope.dateRange
            $scope.title = $state.current.data.pageTitle;
            $scope.selectedCertificateIds = [];

            $scope.resetSearch = function () {
                $scope.dateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.report = {
                dateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                }
            }
            $scope.CertificateLists = [];
            commonFactory.get('/api/getCertificateLists').success(function (response) {
                if (response.result && response.result.data) {
                    $scope.CertificateLists = response.result.data;
                }
            }).error(function (err) {
            });

            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            } else {
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };
            
            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    data.selectedCertificateIds = $scope.selectedCertificateIds.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); $scope.inProcess = true;
                    data.startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
                    commonFactory.showDataTable('/api/get-certification-expiration-report', data).success(function (res) {
                        if (!res.error) {
                            $rootScope.showLoader(false);
                            $scope.selectAll = true;
                            $scope.invoiceArray = {};
                            callback(res);
                        }
                    }).error(function (err) {
                        Notification.error(err.error)
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO CERTIFICATE AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ CERTIFICATES",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 CERTIFICATES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL CERTIFICATES)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ CERTIFICATES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING CERTIFICATES FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searching', false)
                .withOption('searchDelay', 500)
                .withOption('order', [4, 'asc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('certificate_type.type').withTitle('Certificate Type').withOption('searchable', true).renderWith(function(data,type,full,meta){
                    return full.certificate_type.type + ' - '+ full.certificate_type.state_code;
                }),
                
                DTColumnBuilder.newColumn('reporter.FIRST_NAME').withTitle('Resource Name').withOption('searchable', true).renderWith(function(data,type,full,meta){
                    return '<a target="_blank" uib-tooltip="Edit" ui-sref="representative.reporter.step_one({id : ' + full.reporter.id + '})" >' +full.reporter.name +'</a>';
                }),
                
                DTColumnBuilder.newColumn('certificate_number').withTitle('Certificate Number').withOption('searchable', true),

                DTColumnBuilder.newColumn('achieved_date').withTitle('Achieved Date').withOption('searchable', true),
                
                DTColumnBuilder.newColumn('expiration_date').withTitle('Expiration Date').withOption('searchable', true),
                
                DTColumnBuilder.newColumn('notes').withTitle('Notes').withOption('searchable', true),

                DTColumnBuilder.newColumn('expiration_date').withTitle('Status').withOption('searchable', false).notSortable().renderWith(function(data,type,full,meta){
                    let today = new Date();
                    let expired = new Date(data);
                    if(full.deleted_at){
                        return '<span style="color:red;">Inactivated</span>';
                    }{
                        return today <= expired ? 'Active' : ' <span style="color:red;">Expired</span>';
                    }
                }),
            );
        }
    ]);
