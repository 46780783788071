angular.module('deitz')
    .controller('findFirmsController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'firmFactory', '$window', '$uibModal', '$uibModalInstance', '$parent', 'elawId',
        function ($scope, $rootScope, $http, apiUrl, $state, firmFactory, $window, $uibModalInstance, $uibModal, $parent, elawId) {

            $scope.title = "Add Firm"
            $scope.findfirm = {};
            $scope.findfirm.searchBy = "PHONE";
            //Getting Attorneys Phone List
            $scope.attorneysfirmlist = function (val) {
                return $http.get(apiUrl + '/api/getattorneysbyphonelist', {
                    params: {
                        phone: val,
                        searchBy: $scope.findfirm.searchBy
                    }
                }).then(function (response) {
                    return response.data.result.map(function (item) {
                        return item;
                    });
                });
            };

            $scope.onSelectAttorneyFirm = function ($item, $model, $label, $event) {
                $scope.findfirm.PHONE = $item;
                $scope.findfirm.field = $scope.findfirm.PHONE.PHONE+' | '+$scope.findfirm.PHONE.NAME+' | ' +$scope.findfirm.PHONE.ADDR_LINE1;
            };

            $scope.addFirmbtn = function (phone) {
                firmFactory.sharedAddress = "";
                firmFactory.sharedPhonenumber = "";
                firmFactory.sharedName = "";
                if ($scope.findfirm.searchBy == 'PHONE') {
                    firmFactory.sharedPhonenumber = $scope.findfirm.PHONE ? $scope.findfirm.PHONE.PHONE : phone;
                } else if ($scope.findfirm.searchBy == 'NAME') {
                    firmFactory.sharedName = $scope.findfirm.PHONE ? $scope.findfirm.PHONE.NAME : phone;
                } else {
                    firmFactory.sharedAddress = $scope.findfirm.PHONE ? $scope.findfirm.PHONE.ADDR_LINE1 : phone;
                }
                $uibModal.close(true);
                var addAttorneyFirmModal = $uibModalInstance.open({
                    templateUrl: "modules/firms/add_attorney_firm_modal.html",
                    data: {pageTitle: 'Add Firm'},
                    controller: 'addfirmsController',
                    windowClass: 'full-width-model',
                    keyboard: false,
                    backdrop: false,
                    size: 'lg',
                    close: function () {
                        console.log("function called");
                    },
                    resolve: {
                        elawId: function () {
                            return elawId || null;
                        }
                    }
                });

                addAttorneyFirmModal.result.then(function (firmData) {

                    if (!angular.equals({}, firmData)) {
                        $parent.foundFromElaw = false;

                        if (firmData.claimrep_name_id) {

                            commonFactory.get('/api/claimrepresentativename/' + firmData.claimrep_name_id + '/edit')
                                .success(function (response) {
                                    $rootScope.showLoader(false);
                                    if (response.status == 200 && response.result && response.result.claim_rep_name) {
                                        firmData.claim_rep_name = response.result.claim_rep_name || "";
                                        $parent.onSelectAttorneyFirm(firmData);
                                    }
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });
                        } else {
                            $parent.onSelectAttorneyFirm(firmData);
                        }
                    }
                }, function () {
                    console.log("Modal Dismissed!!!");
                });

                addAttorneyFirmModal.opened.then(function () {
                    $rootScope.showLoader(false);
                });
            };

            $scope.editFirmbtn = function (id) {
                $uibModal.close(true);
                const url = $state.href('firms.manage.step_one', {id});
                $window.open(url, '_blank');
            };

            $scope.closePopupWindow = function () {
                swal({
                        title: "Are you sure ?",
                        text: "Are you sure want to close this window?",
                        type: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $uibModal.close(true);
                        }
                    });
            };

        }]);
