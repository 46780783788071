angular.module('deitz')
    .controller('addadjustmentController', ['$scope', '$rootScope', 'payrollFactory', 'diaryFactory', 'representativeFactory', '$state', '$http', 'apiUrl', '$timeout', '$filter', 'SweetAlert', '$cookies', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile', function($scope, $rootScope, payrollFactory, diaryFactory, representativeFactory, $state, $http, apiUrl, $timeout, $filter, SweetAlert, $cookies, DTOptionsBuilder, DTColumnBuilder, $q, $compile) {
        $scope.title = "Payroll Adjustment";
        $scope.editInvoiceData = {};
        $scope.lock_screen = true;
        $scope.showResourceAssign = false;


        // function to calculate date diff

        // predefined Resource type for Adjustment
        $scope.resourceTypesArray = {'R': 'Reporter','T':'Typist','S':'Salesmen','SC':'Scopist'};

        $scope.DateDiff = {
            inDays: function(d1, d2) {
                var t2 = d2.getTime();
                var t1 = d1.getTime();

                return parseInt((t2 - t1) / (24 * 3600 * 1000));
            },
            inWeeks: function(d1, d2) {
                var t2 = d2.getTime();
                var t1 = d1.getTime();

                return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
            },
            inMonths: function(d1, d2) {
                var d1Y = d1.getFullYear();
                var d2Y = d2.getFullYear();
                var d1M = d1.getMonth();
                var d2M = d2.getMonth();

                return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
            },
            inYears: function(d1, d2) {
                return d2.getFullYear() - d1.getFullYear();
            }
        };
        $scope.roundOff = function(input, limit) {
            return parseFloat(Math.round(input * Math.pow(10, limit)) / Math.pow(10, limit)).toFixed(limit);
            // var output = "";
            // if (!limit || (limit < 0)) {
            //     limit = 0;
            // }
            // var fraction = Math.pow(10, limit);
            // var output = Math.round(fraction * Number(input)) / fraction;
            // return output;
        };
        $scope.calculateAge = function(date) {
            var d1 = date ? new Date(date) : new Date();
            var d2 = new Date();
            // $scope.today = $filter('date')(new Date(), "dd/MM/yyyy");
            $scope.today = $filter('date')(new Date(), "MM/dd/yyyy");
            $scope.editInvoiceData.Age = $scope.DateDiff.inDays(d1, d2);
        };

        $scope.rateInformation = function(id, cb) {
            var nullData = {};
            if (id && id != null) {
                representativeFactory.get('/api/reptype/' + id + '/edit')
                    .success(function(response) {
                        if (response && response.result && response.result.reptypeInfo) {
                            var reporterData = {};
                            reporterData.title = response.result.reptypeInfo.TITLE;
                            reporterData.first_name = response.result.reptypeInfo.FIRST_NAME;
                            reporterData.last_name = response.result.reptypeInfo.LAST_NAME;
                            reporterData.full_name = reporterData.first_name + " " + reporterData.last_name;
                            reporterData.email_address = response.result.reptypeInfo.Email_Address;
                            reporterData.phone = response.result.reptypeInfo.PHONE;
                            reporterData.addr_line1 = response.result.reptypeInfo.ADDR_LINE1;
                            reporterData.city = response.result.reptypeInfo.CITY;
                            reporterData.state = response.result.reptypeInfo.STATE;
                            reporterData.zip = response.result.reptypeInfo.ZIP;
                            reporterData.soc_sec_no = response.result.reptypeInfo.SOC_SEC_NO;
                            reporterData.cell = response.result.reptypeInfo.CELL;
                            reporterData.addr_line2 = response.result.reptypeInfo.ADDR_LINE2;

                            reporterData.rate_1 = response.result.reptypeInfo.RATE_1;
                            reporterData.rate_2 = response.result.reptypeInfo.RATE_2;
                            reporterData.medicalrate = response.result.reptypeInfo.MedicalRate;
                            reporterData.copy_rt = response.result.reptypeInfo.COPY_RT;
                            reporterData.appear = response.result.reptypeInfo.APPEAR;
                            reporterData.pay_stips = response.result.reptypeInfo.PAY_STIPS;
                            reporterData.rate_e = response.result.reptypeInfo.RATE_E;
                            reporterData.rate_d = response.result.reptypeInfo.RATE_D;
                            reporterData.rate_i = response.result.reptypeInfo.RATE_I;
                            reporterData.beeper = response.result.reptypeInfo.BEEPER;
                            reporterData.late_app = response.result.reptypeInfo.LATE_APP;
                            reporterData.payment_type = response.result.reptypeInfo.payment_type;

                            reporterData.notary_number = response.result.reptypeInfo.NOTARY_NUMBER;
                            reporterData.notary_expiration_date = response.result.reptypeInfo.NOTARY_EXPIRATION_DATE;
                            reporterData.rate_l = response.result.reptypeInfo.RATE_L;
                            reporterData.curr_pay = response.result.reptypeInfo.CURR_PAY;
                            reporterData.prev_pay = response.result.reptypeInfo.PREV_PAY;
                            reporterData.date_added = response.result.reptypeInfo.DATE_ADDED;
                            reporterData.last_chgd = response.result.reptypeInfo.LAST_CHGD;
                            reporterData.notes_1 = response.result.reptypeInfo.NOTES_1;
                            reporterData.routing_number = response.result.reptypeInfo.ROUTING_NUMBER;
                            reporterData.account_number = response.result.reptypeInfo.ACCOUNT_NUMBER;
                            reporterData.p1099 = response.result.reptypeInfo.P1099;
                            reporterData.curr_paydt = response.result.reptypeInfo.CURR_PAYDT;
                            reporterData.ytd_pay = response.result.reptypeInfo.YTD_PAY;
                            reporterData.status = response.result.reptypeInfo.STATUS;
                            reporterData.ytd_pgs = response.result.reptypeInfo.YTD_PGS;
                            reporterData.notes_2 = response.result.reptypeInfo.NOTES_2;
                            reporterData.rate_changed_by = response.result.reptypeInfo.RATE_CHANGED_BY;
                            reporterData.rate_change_date = response.result.reptypeInfo.RATE_CHANGE_DATE;
                            reporterData.rate_change_reason = response.result.reptypeInfo.RATE_CHANGE_REASON;
                            reporterData.SORn = Number(response.result.reptypeInfo.SORn) > 1 ? Number(response.result.reptypeInfo.SORn) : 2;
                            reporterData.SORr = response.result.reptypeInfo.SORr;
                            reporterData.SOR = response.result.reptypeInfo.SOR;
                            reporterData.bust_fee = response.result.reptypeInfo.bust_fee;
                            cb(reporterData);
                        } else {
                            console.log("Not get any response");
                            cb(nullData);
                        }
                    })
                    .error(function(err) {
                        console.log(err);
                        cb(nullData);
                    });
            } else {
                cb(nullData);
            }
        };

        $scope.getPaymentData = function() {
            $rootScope.showLoader(true);
            $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: $state.params.id,
                    is_payment_done: 'y',
                    with_resource_details:true
                }
            }).then(function(response) {
                $scope.editInvoiceData = response.data.result[0];
                $state.$current.data.pageTitle = $scope.editInvoiceData.JOB_NO;
                $rootScope.$broadcast('changeTitle', $state);
                
                //$scope.editInvoiceData.avg_rep_rate = $scope.roundOff($scope.editInvoiceData.avg_rep_rate, 2);
                //$scope.editInvoiceData.avg_typ_rate = $scope.roundOff($scope.editInvoiceData.avg_typ_rate, 2);
                //$scope.editInvoiceData.page_rate_fees = $scope.roundOff($scope.editInvoiceData.page_rate_fees, 2);
                //$scope.editInvoiceData.total_copy_fees = $scope.roundOff($scope.editInvoiceData.total_copy_fees, 2);
                //$scope.editInvoiceData.ded_per_wit = $scope.roundOff($scope.editInvoiceData.ded_per_wit, 2);
                $scope.editInvoiceData.reimbursement = $scope.roundOff($scope.editInvoiceData.job_payments.reduce((prev,next)=>prev+next.reimbursement,0), 2);
                // adjustment total
                if($scope.editInvoiceData.total_adjustment.length !=0){
                    $scope.job_adjustment=$scope.editInvoiceData.total_adjustment[0].adjustmentAmount;
                }
                else{
                    $scope.job_adjustment=0;
                }
                $scope.editInvoiceData.actual_rep_pay_amt = $scope.roundOff($scope.editInvoiceData.job_payments.reduce((prev,next)=>prev+next.REP_PAYAMT,0)+  parseFloat($scope.job_adjustment), 2);

                $scope.editInvoiceData.total_rep_pay_amt = $scope.roundOff($scope.editInvoiceData.actual_rep_pay_amt-$scope.editInvoiceData.reimbursement,2);
                //$scope.editInvoiceData.total_adjustment[0].adjustmentAmount

                $scope.editInvoiceData.typ_pay_amt = $scope.roundOff($scope.editInvoiceData.job_payments.reduce((prev,next)=>prev+next.TYP_PAYAMT,0), 2);
                //$scope.editInvoiceData.total_job_pay_amt = $scope.roundOff(parseFloat($scope.editInvoiceData.total_rep_pay_amt)+parseFloat($scope.editInvoiceData.typ_pay_amt)+parseFloat($scope.editInvoiceData.total_adjustment[0].adjustmentAmount), 2);
                $scope.editInvoiceData.total_job_pay_amt = $scope.roundOff(parseFloat($scope.editInvoiceData.total_rep_pay_amt)+parseFloat($scope.editInvoiceData.typ_pay_amt), 2);
                //$scope.editInvoiceData.REP_PAYDT = moment($scope.editInvoiceData.REP_PAYDT, 'YYYY-MM-DD').format('MM/DD/YYYY');
                
                //$scope.editInvoiceData.TYP_PAYDT = moment($scope.editInvoiceData.TYP_PAYDT, 'YYYY-MM-DD').format('MM/DD/YYYY');
                $scope.calculateAge($scope.editInvoiceData.DATE_TAKEN);

                // get the payment date
                // $http.post(apiUrl + '/api/config/get-configurations', {
                //     keys : ['pay_date']
                // }).then(function(response) { 
                //     $scope.editInvoiceData.payment_date = response.data.configurations.pay_date; 
                // });
                // if($scope.editInvoiceData.diary.businessunit.pay_date){
                    // check if cbu job
                    if($scope.editInvoiceData.diary.production_queue=='CBU'){
                        $scope.editInvoiceData.payment_date = $scope.editInvoiceData.diary.covering_businessunit.pay_date
                    }
                    else{
                        $scope.editInvoiceData.payment_date = $scope.editInvoiceData.diary.businessunit.pay_date
                    }
                // }

                if ($scope.editInvoiceData.reporter_id && $scope.editInvoiceData.reporter_id != '' && $scope.editInvoiceData.reporter_id != null) {
                    $rootScope.showLoader(true);
                    $scope.rateInformation($scope.editInvoiceData.reporter_id, function(reporterData) {
                        $scope.reporterData = reporterData;
                        if ($scope.editInvoiceData.typist_id && $scope.editInvoiceData.typist_id != '' && $scope.editInvoiceData.typist_id != null) {
                            $scope.rateInformation($scope.editInvoiceData.typist_id, function(typistData) {
                                $scope.typistData = typistData;
                            });
                        }
                        $rootScope.showLoader(false);
                    });
                } else {
                    $rootScope.showLoader(false);
                }
                $scope.adjustment = $scope.roundOff(0, 2);
                $scope.adjusted_amt = $scope.roundOff(Number($scope.editInvoiceData.total_job_pay_amt), 2);
            });
        };

        // $scope.getConfig = function() {
        //     $rootScope.showLoader(true);
        //     $http.get(apiUrl + '/api/config', {
        //         params: {
        //             'test': 'test'
        //         }
        //     }).then(function(response) {
        //         $scope.configs = response.data.result.config;
        //         angular.forEach($scope.configs, function(value, key) {
        //             if (value.key == "rep_pay_date") {
        //                 $scope.repPayDate = value.value;
        //                 var parts = $scope.repPayDate.split('/');
        //                 $scope.repPayDate = $filter('date')(new Date(parts[2], parts[1] - 1, parts[0]), "MM/dd/yyyy");
        //             }
        //             if (value.key == "typ_pay_date") {
        //                 $scope.typPayDate = value.value;
        //                 var parts = $scope.typPayDate.split('/');
        //                 $scope.typPayDate = $filter('date')(new Date(parts[2], parts[1] - 1, parts[0]), "MM/dd/yyyy");
        //             }
        //         });
        //         $rootScope.showLoader(false);
        //     });
        // };
        $scope.getAdjustmentHistory = function() {
            $rootScope.showLoader(true);
            $http.get(apiUrl + '/api/payroll-adjustment/' + $state.params.id).then(function(response) {
                $scope.adjustment_history = [];
                if (response.data && response.data['result']) {
                    results = response.data['result'];
                    if (results.hasOwnProperty('adjustment_detail')) {
                        $scope.adjustment_history = results['adjustment_detail'];
                    }
                }
                $rootScope.showLoader(false);
            });
        };
        $scope.getDiaryData = function() {
            //Getting edit data from DIARY_tbl and CASE_tbl
            var diaryDetails = {};
            diaryDetails.id = $state.params.id;
            $timeout(function() {
                $rootScope.showLoader(true);
                diaryFactory.post('/api/getdiarydetails', diaryDetails)
                    .success(function(response) {
                        // $rootScope.showLoader(false);
                        if (response && response.result && response.result.diaryDetails) {
                            $scope.DiaryData = response.result.diaryDetails;
                            // Replace fake date in TIME_TAKEN OF Diary data
                            $scope.DiaryData.TEMP_TIME_TAKEN = $scope.DiaryData.TIME_TAKEN.split(" ");
                            $scope.DiaryData.TIME_TAKEN = $scope.DiaryData.TEMP_TIME_TAKEN[$scope.DiaryData.TEMP_TIME_TAKEN.length - 1];
                            $scope.calculateAge($scope.DiaryData.DATE_TAKEN);
                            var parts = $scope.DiaryData.DATE_TAKEN.split('/');
                            $scope.DiaryData.DATE_TAKEN = $filter('date')(new Date(parts[2], parts[1] - 1, parts[0]), "MM/dd/yyyy");
                            $scope.repPayDate = $scope.DiaryData.businessunit.pay_date
                            $scope.typPayDate = $scope.DiaryData.businessunit.pay_date
                            $scope.generalData = {};
                            diaryFactory.get('/api/jobcategories')
                                .success(function(response) {
                                    if (response.result && response.result.jobcategories) {
                                        $scope.jobcategories = response.result.jobcategories || '';
                                        for (var i = 0; i < $scope.jobcategories.length; i++) {
                                            if ($scope.jobcategories[i].isDefault == 1) {
                                                $scope.generalData.JobCategory = $scope.jobcategories[i].name;
                                            }
                                        }
                                    }
                                    //Getting All caseType
                                    diaryFactory.get('/api/casetypes')
                                        .success(function(response) {
                                            if (response && response.result && response.result.casetype) {
                                                $scope.casetype = response.result.casetype || '';
                                                for (var i = 0; i < $scope.casetype.length; i++) {
                                                    if ($scope.casetype[i].id == $scope.DiaryData.case_type_id) {
                                                        $scope.generalData.CaseType = $scope.casetype[i].name;
                                                    }
                                                }
                                            }
                                            // $scope.getAdjustmentHistory();
                                        })
                                        .error(function(err) {
                                            $rootScope.showLoader(false);
                                        });
                                })
                                .error(function(err) {});
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }, 100);
        };

        $scope.getPaymentData();
        // $scope.getConfig();
        // $scope.getAdjustmentHistory();
        $scope.getDiaryData();

        // ---------------------------------------------------------------------------------------

        $scope.dtInstance = {};
        $scope.dtIntanceCallback = function(instance) {
            $scope.dtInstance = instance;
        };
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.DataTable.draw();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('bFilter', false)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                $rootScope.showLoader(true);
                payrollFactory.getAdjustmentHistory('/api/payroll-adjustment/' + $state.params.id, options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            });

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn(null).withOption('defaultContent', '0').withTitle('Adjustment (+/-)')
            .renderWith(function(data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data.adjustment, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn(null).withOption('defaultContent', '0').withTitle('Adjusted Amount')
            .renderWith(function(data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data.adjusted_amt, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn('resource.name').withTitle('Resource Name').withOption('name', 'resource.name').withOption('defaultContent', '').notSortable().withOption('searchable', false).renderWith(function(data, type, full, meta){
                if (data) {
                    return data;
                } 
            }),
            DTColumnBuilder.newColumn(null).withOption('defaultContent', 'RK').withTitle('Adjusted By').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data) {
                    return data.user.name;
                }
            }),
            DTColumnBuilder.newColumn('adjustment_note').withOption('defaultContent', 'RK').withTitle('Note').notSortable(),
            DTColumnBuilder.newColumn('check_number').withOption('defaultContent', 'null').withTitle('Check Number').notSortable(),
            DTColumnBuilder.newColumn('payment_date').withOption('defaultContent', '00/00/0000').withTitle('Payment Date')
            .renderWith(function(data, type, full, meta) {
                if (data) { 
                    return moment(data).format('MM/DD/YYYY');
                }
            }),
            DTColumnBuilder.newColumn('date').withOption('defaultContent', '00/00/0000').withTitle('Date of Adjustment')
        ];

        // ---------------------------------------------------------------------------------------
        $scope.calculateAdjustedAmt = function() {
            //Return zero if adjustment amount is non numeric value.
            if(isNaN($scope.adjustment)){
                return $scope.adjustment = $filter('number')(0, 2);
            }else{
                $scope.adjusted_amt = $scope.roundOff(Number($scope.editInvoiceData.total_job_pay_amt) + Number($scope.adjustment ? $scope.adjustment : 0), 2);
                $scope.total_rep_pay_amt = $scope.roundOff(Number($scope.editInvoiceData.total_rep_pay_amt) + Number($scope.adjustment ? $scope.adjustment : 0), 2);
                $scope.actual_rep_pay_amt = $scope.roundOff(Number($scope.editInvoiceData.actual_rep_pay_amt) + Number($scope.adjustment ? $scope.adjustment : 0), 2);
                $scope.total_job_pay_amt = $scope.roundOff(Number($scope.editInvoiceData.total_job_pay_amt) + Number($scope.adjustment ? $scope.adjustment : 0), 2);
            }

        };
        $scope.payrollAdjustment = function() {
            $rootScope.showLoader(true);
            var data = {}; 
            data['payment_date'] = moment($scope.editInvoiceData.payment_date).format('YYYY-MM-DD');
            data['job_id'] = $scope.editInvoiceData.id;
            data['JOB_NO'] = $scope.editInvoiceData.JOB_NO ? $scope.editInvoiceData.JOB_NO : $state.params.id;
            data['adjustment'] = $scope.adjustment;
            data['adjusted_amt'] = $scope.adjusted_amt;
            data['adjusted_by'] = JSON.parse($cookies.get("authUser")).id ? JSON.parse($cookies.get("authUser")).id : 0;
            data['adjustment_note'] = $scope.adjustment_note;
            data['date'] = $scope.today;
            data['resource'] = $scope.editInvoiceData.resource;
            data['change_in_diary'] = $scope.editInvoiceData.changeToDiary;

            var jobData = {};
            jobData['total_job_pay_amt'] = $scope.total_job_pay_amt;
            jobData['total_rep_pay_amt'] = $scope.total_rep_pay_amt;
            jobData['actual_rep_pay_amt'] = $scope.actual_rep_pay_amt;

            payrollFactory.adjustPayment("/api/adjustment/" + $state.params.id, jobData)
                .success(function(response) {
                    if (response && response.result) {
                        $scope.editInvoiceData.total_job_pay_amt = $scope.total_job_pay_amt;
                        $scope.editInvoiceData.total_rep_pay_amt = $scope.total_rep_pay_amt;
                        $scope.editInvoiceData.actual_rep_pay_amt = $scope.actual_rep_pay_amt;
                    }
                    payrollFactory.payrollAdjustment("/api/payroll-adjustment", data)
                        .success(function(response) {
                            if (response && response.result) {
                                $scope.reloadData();
                                $scope.adjustment = 0;
                                $scope.adjusted_amt = Number($scope.editInvoiceData.total_job_pay_amt);
                                $scope.adjustment_note = " ";
                            }
                            $rootScope.showLoader(false);
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });
                    $rootScope.showLoader(false);
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });

        };

        $scope.getResourceList = function($keyword){
            return $http.get(apiUrl + '/api/search-resource', {
                params: {
                    keyword:$keyword
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        }

        $scope.selectResource = function($item, $model, $label, $event){
            $item.resource_name = $item.first_name + ' '+$item.last_name;
            $scope.editInvoiceData.resource = $item;
            $scope.showResourceAssign = true;
            $scope.editInvoiceData.resource_id = $item.id;
        };

        $scope.unSelectResource = function(){
            if($scope.showResourceAssign){
                $scope.editInvoiceData.resource = {};
                $scope.showResourceAssign = false;
                $scope.editInvoiceData.resource_id = null;
            }
        }


        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {

                switch (String.fromCharCode(event.which).toLowerCase()) {


                    case 's':
                        event.preventDefault();
                        $scope.invoiceAdjustmentForm.$setSubmitted();
                        $scope.$apply();
                        if($scope.invoiceAdjustmentForm.$valid){
                            $scope.payrollAdjustment();
                        }
                        break;
                }
            }
        });

    }]);
