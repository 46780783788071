angular.module('deitz').controller('browseReporterWorkTypesController', [
    '$scope','$rootScope','DTOptionsBuilder','DTColumnBuilder','$compile','commonFactory',
    function (
        $scope,$rootScope,DTOptionsBuilder,DTColumnBuilder,$compile, commonFactory 
    ) {

        $scope.dtInstance = {};

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        $scope.actionRoles = function (data, type, full) {
            return "<a class='btn btn-primary btn-circle' ui-sref='code_tables.reporter_work_types_edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";
        };

        $scope.headerCompiled = false;

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
            // map your server's response to the DataTables format and pass it to

            commonFactory.showDataTable('/api/getAllReporterWorkTypes', data).success(function (res) {
                if (res.error) {
                    $scope.reloadData();
                }
                else {
                    callback(res);
                }
            }).error(function (err) {
                if (err.error !== "token_not_provided") {
                    $scope.reloadData();
                }
            });
        })
        .withOption('processing', true)
        .withLanguage({
            "sProcessing": "<img src='img/loading_bar.gif'/>"
        })
        .withOption('serverSide', true)
        .withOption('searchDelay', 500)
        .withOption('order', [0, 'asc'])
        .withPaginationType('simple_numbers')
        .withDisplayLength(10)
        .withOption('lengthMenu', [
            [5, 10, 25, 50],
            [5, 10, 25, 50]
        ])
        .withOption('createdRow', $scope.createdRow)
        .withOption('headerCallback', function (header) {
            // Use this headerCompiled field to only compile header once
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        });

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Reporter Work Type').withOption('searchable', true),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles)
        ];


    }]);