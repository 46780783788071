angular.module('deitz').controller('operationMetricsController', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory', 'apiUrl', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$window',
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory, apiUrl, DTOptionsBuilder, DTColumnBuilder, $compile, $window
    ){

        $scope.report = {
            jobDateRange : {
                startDate : moment(),
                endDate : moment()
            },
            attorneyFirmIds : []
        };

        $scope.selectedFirms = [];
        $scope.selectedExcludeFirms = [];

        $scope.report.startDate = moment($scope.report.jobDateRange.startDate).format('YYYY-MM-DD');
        $scope.report.endDate= moment($scope.report.jobDateRange.endDate).format('YYYY-MM-DD');
    
        $scope.calculatedData = [];

        $scope.title = "Operation Metrics";        

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        /* Notes browse datatable*/
        $scope.dtInstanceJobs = {};

        $scope.reloadJobData = function () {
            $scope.dtInstanceJobs.rerender();
        };

        $scope.resetSearch = function(){
            $scope.dtInstanceJobs.DataTable.state.clear();
            $scope.reloadJobData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtJobsOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to
                $scope.searchingData = true;
                $scope.inProcess = true;
                data.startDate = $scope.report.startDate;
                data.endDate = $scope.report.endDate;
                data.attorneyFirmIds = $scope.report.attorneyFirmIds;
                data.excludeAttorneyFirmIds = $scope.report.excludeAttorneyFirmIds;

                commonFactory.showDataTable('/api/operation-metrics-data', data)
                    .success(function(response) {
                        $scope.inProcess = false;
                        if (response.error) {
                            $scope.reloadJobData();
                        }
                        else {
                            $scope.calculatedData = {
                                avg_dayTurnIn : response.calculatedData.totalTurnInDay ? parseFloat(response.calculatedData.totalTurnInDay/response.calculatedData.totalJobs).toFixed(2) : 0, 
                                avg_dayToBill : response.calculatedData.totalUploadToBillDay ? parseFloat(response.calculatedData.totalUploadToBillDay/response.calculatedData.totalJobs).toFixed(2): 0,
                                avg_dayToShip : response.calculatedData.totalBillToShipDay ? parseFloat(response.calculatedData.totalBillToShipDay/response.calculatedData.totalJobs).toFixed(2): 0,
                                avg_totalTurnIn : response.calculatedData.allTotalTurnInDay ? parseFloat(response.calculatedData.allTotalTurnInDay/response.calculatedData.totalJobs).toFixed(2): 0
                            }
                            $scope.searchingData = false;
                            callback(response);
                        }
                    })
                    .error(function(err) {
                        $scope.inProcess = false;
                        if (err.error !== "token_not_provided") {
                            $scope.reloadJobData();
                        }
                    });

            })
            .withOption('processing', true)
            .withLanguage({

                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 1000)
            .withDisplayLength(50)
            .withOption('lengthMenu', [
                [10, 25, 50, 100],
                [10, 25, 50, 100]
            ])
            .withOption('order', [0, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtJobColumns = [];

        $scope.dtJobColumns.push(
            DTColumnBuilder.newColumn('JOB_NO').withOption('defaultContent', '').withTitle('Job No').withOption('searchable',true).withOption("width", "5%").renderWith(function(data,type,full,meta){
                return  '<a class="pull-left" href="javascript:void(0);" ui-sref="diary.editcase.step_one({id : ' + data + ' })">'+data+'</a>';
            }),
            DTColumnBuilder.newColumn('diary.resources').withOption('defaultContent', '').withTitle('Resources').withOption('searchable',true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                html='';
                data.forEach(resource => {
                    html+= '<p><a uib-tooltip="'+resource.name+'-'+$scope.reporterType[resource.REP_TYP]+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" >'+  resource.FIRST_NAME +'</a></p>';    
                });
                full.diary.resource_typists.forEach(resource => {
                    html+= '<p><a uib-tooltip="'+resource.name+'-'+$scope.reporterType[resource.REP_TYP]+'"  tooltip-trigger="focus mouseenter"  tooltip-placement="top" >'+  resource.FIRST_NAME +'</a></p>';    
                });

                return html;
            }),
            DTColumnBuilder.newColumn('orderingAttShortName').withOption('defaultContent', '').withTitle('Ord Att').withOption('searchable',true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                return '<span uib-tooltip="'+full.orderingAtt+'">'+data+'</span>'
            }),
            DTColumnBuilder.newColumn('CAPTION').withOption('defaultContent', '').withTitle('Caption').withOption('searchable', true).withOption("width", "10%"),
            DTColumnBuilder.newColumn('dte_taken').withOption('defaultContent', '').withTitle('Job DT').withOption('searchable', true).withOption("width", "5%").renderWith(function(data, type, full, meta){
                if(data){
                    return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
                }
                return '---';
            }),
            DTColumnBuilder.newColumn('week_dte_uploaded').withOption('defaultContent', '').withTitle('Upload DT').withOption('searchable', false).withOption("width", "5%").renderWith(function(data, type, full, meta){
                if(data){
                    return moment(data, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY');
                }
                return '---';
            }),
            DTColumnBuilder.newColumn('dte_invoice').withOption('defaultContent', '').withTitle('Inv DT').withOption('searchable', false).withOption("width", "5%").renderWith(function(data, type, full, meta){
                if(data){
                    return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
                }
                return '---';
            }),
            DTColumnBuilder.newColumn('shipDate').withOption('defaultContent', '').withTitle('Ship DT').withOption('searchable', false).withOption("width", "5%").renderWith(function(data, type, full, meta){
                if(data){
                    return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
                }
                return '---';
            }),
            DTColumnBuilder.newColumn('turnInDay').withOption('defaultContent', '').withTitle('Days To Turn In').withOption('searchable', false).withOption("width", "5%"),
            DTColumnBuilder.newColumn('uploadToBillDay').withOption('defaultContent', '').withTitle('Days To Bill').withOption('searchable', false).withOption("width", "5%"),
            DTColumnBuilder.newColumn('billToShipDay').withOption('defaultContent', '').withTitle('Day Billed To Ship').withOption('searchable', false).withOption("width", "5%"),
            DTColumnBuilder.newColumn('totalTurnInDay').withOption('defaultContent', '').withTitle('Avg Total Turned').withOption('searchable', false).withOption("width", "5%")
        );
        
        $scope.searchData = function(){
            $scope.report.startDate = moment($scope.report.jobDateRange.startDate).format('YYYY-MM-DD');
            $scope.report.endDate= moment($scope.report.jobDateRange.endDate).format('YYYY-MM-DD');
            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function(a){
                return  a.id;
            });
            $scope.report.excludeAttorneyFirmIds = $scope.selectedExcludeFirms.map(function(a){
                return  a.id;
            });

            $scope.title = "OPERATION METRICS REPORT : "+ moment($scope.report.startDate).format('MM/DD/YYYY') + " to " + moment($scope.report.endDate).format('MM/DD/YYYY');
            $scope.reloadJobData(); 
        };


        /* This function is use for prinnt OPERATION METRICS REPORT */
        $scope.printData = function(type){
            var printData = {};
            printData.startDate = moment($scope.report.jobDateRange.startDate).format('YYYY-MM-DD');
            printData.endDate= moment($scope.report.jobDateRange.endDate).format('YYYY-MM-DD');
            printData.attorneyFirmIds = $scope.selectedFirms.map(function(a){
                return  a.id;
            });
            printData.excludeAttorneyFirmIds = $scope.selectedExcludeFirms.map(function(a){
                return  a.id;
            }); 

            printData.type = type; 

            // this.btnDisabled = true;
            $rootScope.showLoader(true);
            commonFactory.post('/api/operation-metrics-print-data', printData)
            .success(function(response) { 
                $rootScope.showLoader(false);
                if(response.error){
                    Notification.error(response.result.message || 'Something went wrong!');
                } else {
                    var url= response.result.path;
                    var save = document.createElement('a');
                    save.href = url;
                    save.target = '_blank';
                    save.download = response.result.name;
                    var event = document.createEvent('Event');
                    event.initEvent('click', true, true);
                    save.dispatchEvent(event);
                    save.click()
                    window.URL.revokeObjectURL(url);     
                   
                }
            })
            .error(function(err)
            {   
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false);
            });
        };
}]);
