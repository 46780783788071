angular.module('deitz').controller('cashappReceiptDetailController',[
    '$scope', '$rootScope', 'commonFactory', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$window','$http', 'apiUrl', 'Notification','$uibModal',
    function(
        $scope, $rootScope, commonFactory, DTOptionsBuilder, DTColumnBuilder, $compile, $window, $http,apiUrl, Notification,$uibModal,
    ){
        $scope.formData = {};
        // $scope.sourcesIDS = [];
        $scope.dateRange = '';     
        $scope.reportType = 'details';
        $scope.report = {
            dateRange: {
                endDate: moment().format('MM-DD-YYYY'),
                startDate: moment().format('MM-DD-YYYY')
            }
        }

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });

        // sources
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.sourceList = response.data.data;  

        });

        $scope.getReportExcel = function () {
            $rootScope.showLoader(true);
            var requestData = {
                selectedBusinessUnitIDs: $scope.selectedBusinessUnitIDs,                
                startDate : moment($scope.report.dateRange.startDate).format('YYYY-MM-DD'),
                endDate : moment($scope.report.dateRange.endDate).format('YYYY-MM-DD'),
                type : $scope.reportType
            };            
            commonFactory.post('/api/cash-receipt-detail-report-excel', requestData)
            .success(function(response) { 
                $rootScope.showLoader(false);
                if(response.error){
                    Notification.error('No data available.');
                } else {
                    var url= response.result.path;
                    var save = document.createElement('a');
                    save.href = url;
                    save.target = '_blank';
                    save.download = response.result.name;
                    var event = document.createEvent('Event');
                    event.initEvent('click', true, true);
                    save.dispatchEvent(event);
                    save.click()
                    window.URL.revokeObjectURL(url);
                    Notification.success(response.result.message);
                }
            })
            .error(function(err){   
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false);
            });
        }
        $scope.formatDate = function(date){
            return date.split(' ')[0];
        }
        $scope.formatTime = function(date){
            return date.split(' ')[1];
        }       
        $scope.getReport = function () {
            $rootScope.showLoader(true);
            var requestData = {
                selectedBusinessUnitIDs: $scope.selectedBusinessUnitIDs,
                startDate : moment($scope.report.dateRange.startDate).format('YYYY-MM-DD'),
                endDate : moment($scope.report.dateRange.endDate).format('YYYY-MM-DD'),
                type : $scope.reportType
            };
            
            $http.post(apiUrl + '/api/cash-receipt-detail-report', requestData)           
                .then(function (response) {                    
                    if (response.data && response.data.data && $scope.reportType=="details" && !response.data.error) {
                        $rootScope.showLoader(false);                      
                        $scope.reportDatasummary = '';                       
                        $scope.getTableOptions = function(unit) {
                            return {
                                paging: true,
                                // Add other DataTables options as needed
                            };
                        };
                        $scope.reportDatadetails = response.data.data;   
                        $scope.getGrandTotal = function () {
                            var grandTotal1 = 0;
                            angular.forEach($scope.reportDatadetails, function (unit) {
                            grandTotal1 += parseFloat(unit.total_amount);
                            });
                            return grandTotal1; // Round to 2 decimal places
                        };                    
                      
                    }else if(response.data && response.data.data && $scope.reportType=="summary" && !response.data.error){
                        $rootScope.showLoader(false);
                        $scope.reportDatasummary = response.data.data;
                        $scope.reportDatadetails = ''; 
                        $scope.getsummaryGrandTotal = function () {
                            var grandTotal = 0;
                            angular.forEach($scope.reportDatasummary, function (unit) {
                            grandTotal += parseFloat(unit.total_amount);
                            });                            
                            return grandTotal; // Round to 2 decimal places
                        };
                                              
                    } else {
                        $scope.reportDatasummary = ''; 
                        $scope.reportDatadetails = '';    
                        
                        // Show error notification
                        $rootScope.showLoader(false);
                        Notification.error('No data available.');
                    }
                })
                .catch(function (error) {
                    // Show error notification
                    $rootScope.showLoader(false);
                    console.error('Request failed:', error);
                    Notification.error('Failed to fetch data. Please try again...');
                });
        };
    }]);