angular.module('deitz').controller('editRepoterAvailabilityTypesController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.repoter_availability_types = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Resource Availability Types';
        $scope.modalTitle = 'Edit plaintiff/defendant';
        $scope.modalId = plaintiffId;

        //close modal
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        //Edit Mode of repoter_availability_types.
        if ($state.params && $state.params.id) {
            var id = plaintiffId? plaintiffId:$state.params.id;
            commonFactory.get('/api/repoter_availability_types/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.repoter_availability_types_detail) {
                        $scope.repoter_availability_types.name = response.result.repoter_availability_types_detail.name || '';
                     
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }
 
        //Update Mode of repoter_availability_types
        $scope.addRepoterAvailabilityTypes = function(repoter_availability_types, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = plaintiffId? plaintiffId:$state.params.id;
                commonFactory.put('/api/repoter_availability_types/' + id, $scope.repoter_availability_types)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("RepoterAvailabilityTypes updated successfully");
                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.repoter_availability_types_update.id);
                                }else{
                                    $state.go("code_tables.repoter_availability_types_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
