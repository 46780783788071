angular.module('deitz')
    .controller('addProofOfRequestDocumentsController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'JOB_NO',
        'depoUserId',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  JOB_NO,
                  depoUserId,
                  $uibModalInstance,
                  configFactory) {
            $scope.proofOfRequestDocument = {};
            $scope.diaryData = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'MANAGE PROOF OF ORDER';
            $scope.isPdfUploaded = false;
            $scope.proofOfRequestDocument.JOB_NO = JOB_NO;
            $scope.proofOfRequestDocument.depoUserId = depoUserId;

            $scope.clickUpload = function () {
                $("#fileProofOfRequestDoc").trigger('click');
            }

            $scope.fileChange = function () {
                var fileInput = $('#fileProofOfRequestDoc')[0];
                var files = fileInput.files[0];
                var filePath = fileInput.value;
                // Allowing file type
                var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.doc|\.docx|\.xls|\.xlsx|\.csv|\.txt)$/i;
                if (!allowedExtensions.exec(filePath)) {
                    Notification.error("Invalid file type. \nPlease select Image, PDF, Doc, Excel, CSV, Text file.");
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                    fileInput.value = '';
                    return false;
                }
                if (files && files.name) {
                    $(".upload_h1").html(files.name + " file selected. <br> Provide a below information and press the 'save' button.");
                } else {
                    $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
                }
            }

            //Getting Attorney Firm office_code
            $scope.attorneyofficedata = function (val) {
                return $http.get(apiUrl + '/api/getattorneyfirm', {
                    params: {
                        name: val
                    }
                }).then(function (response) {
                    $scope.foundFromElaw = false;
                    if (response.data.foundFromElaw) {
                        $scope.foundFromElaw = true;
                    }
                    return response.data.result.map(function (item) {
                        return item;
                    });
                });
            };

            $scope.onSelect = function ($item, $model, $label, tab) {
                $scope.diaryData = $item;
                $scope.proofOfRequestDocument.attorney_id = $item.id;
            };

            $scope.addProofOfRequestDocument = function (proofOfRequestDocument, isValid) {
                $scope.formSubmit = true;
                if (isValid) {
                    $rootScope.showLoader(true);

                    Upload.upload({
                        url: apiUrl + '/api/proof-of-request-documents',
                        data: $scope.proofOfRequestDocument
                    }).then(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.data.status == 200) {
                                Notification.success(response.data.result.message);
                                $scope.closePopupWindowSimple();
                            } else if (response.data.status == 400) {
                                Notification.error(response.data.result.message);
                            }
                        }
                    }, function (err) {
                        $rootScope.showLoader(false);
                    });

                } else {
                    if ($scope.proofOfRequestDocumentCreateForm.file.$invalid) {
                        Notification.error('Please upload accepted file only.');
                    } else {
                        Notification.error('File, Attorney Firm and Note - must be required.');
                    }
                }
            }

            $scope.closePopupWindowSimple = function () {
                $uibModalInstance.dismiss({isChanged: true});
            };
        }]);