angular.module('deitz')
    .controller('assignResourceController',[
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        'firmID',
        'resource_type',
        function(
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            firmID,
            resource_type) {

            $scope.title = "Assign "+ resource_type;
            $scope.resourceType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};
            $scope.diary = {};
            $scope.assignedResource = false;
            $scope.resource_type = resource_type;

            $http.get(apiUrl + '/api/collector').success(function(res){
                console.log(firmID,"dd")
                $scope.collectors= res.result;
            });

            $scope.unSelectResource = function(){
                if($scope.assignedResource){
                    $scope.diary.resource = '';
                    $scope.assignedResource = false;
                }
            };
            $scope.updateCollector = function(newCollectorID){
            
                swal({
                    title: "Are you sure",
                    text: "You want to change collector for this firm ?",
                    type: "warning",
                    html: true,
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) { 
                        $rootScope.showLoader(true); 
                        $http.post(apiUrl+'/api/update-collector',{firmID : firmID, newCollectorID : newCollectorID}).success(function(res){                    
                            $rootScope.showLoader(false);
                            if (!res.error) {
                                $uibModalInstance.dismiss({ isChanged : true, resource : $scope.diary.resource});
                                $scope.oldCollectorID = newCollectorID;
                                Notification.success(res.result.message);
                            }else{
                                Notification.error(res.result.message);
                            }
                        
                        });
                    }else{
                        $scope.formData.collector_id = $scope.oldCollectorID;
                        $scope.$apply();
                    }
                });
            }
          

            $scope.cancel = function(isChanged) {
                $uibModalInstance.dismiss({ isChanged : isChanged});
            };

        }]);
