angular.module('deitz')
    .controller('viewPrintInvoiceController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        '$filter',
        'diaryFactory',
        '$stateParams',
        '$uibModal',
        'invoiceFactory',
        'invoice_id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  $state,
                  $timeout,
                  $filter,
                  diaryFactory,
                  $stateParams,
                  $uibModal,
                  invoiceFactory,
                  invoice_id,
                  $uibModalInstance,
                  configFactory) {

            $rootScope.showLoader(true);
            $scope.invoices = [];
            $scope.uibModalInstance = $uibModalInstance;
            $scope.invoiceBatchOrder = '';

            $scope.is_popup = angular.isArray(invoice_id);
            $scope.is_print_invoice = false;

            // If request comes from invoice module screen then invoice_id will the array otherwise we can fetch the invoice from stateParams.
            $scope.invoice_id = invoice_id;
            $scope.invoice_number = 'LEXITAS-' + $scope.invoice_id;

            var invoiceIds = [];
            angular.forEach($scope.invoice_id, function(value, index){
                invoiceIds.push(value.text);
            });

            //get configuration for tax id  
            configFactory.getConfiguration({ keys: ['tax_id'] }).then(function (response) { 
                
                $scope.tax_id = response.data.configurations.tax_id; 

            }, function (error) {  
            });
            $scope.getInvoiceByOrder = function(invoiceBatchOrder){
                $rootScope.showLoader(true);
                $scope.invoiceGetByInvoiceNumbers(invoiceBatchOrder);
            }
            // fetch the invoice information for all the invoices
            $scope.invoiceGetByInvoiceNumbers = function(invoiceBatchOrder){

                invoiceBatchOrder = invoiceBatchOrder ? invoiceBatchOrder : 'INV_NO';
                invoiceFactory.post('/api/invoice-get-by-invoice-numbers', { 
                
                    invoiceIds : invoiceIds , 
                    invoiceBatchOrder : invoiceBatchOrder

                }).success(function (response) {
                    $scope.invoices = response.result.invoices; 
                    $rootScope.showLoader(false);
                }).error(function () {

                });
    
            }
            $scope.invoiceGetByInvoiceNumbers();
            

            $scope.closePopupWindow = function(){
                swal({
                        title: "Are you sure ?",
                        text: "Have you printed invoices from the list?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, I have printed successfully",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            //$close(JOB_NO);
                            $uibModalInstance.close(true);
                        } else {
                            $uibModalInstance.close(false);
                        }
                    });
            };
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
            };

            $scope.printDiv = function (divName) {
                $scope.is_print_invoice = true;
                var printContents = $("#" + divName);
                printContents.find('#nivoice_button').remove();
                printContents = printContents.html();
                var popupWin = window.open('', '_blank');
                popupWin.document.open();
                popupWin.document.write('<html><head><style>table {line-height: 1 !important; } @media print{*{-webkit-print-color-adjust: exact;}}</style><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"></head><body onload="window.print()">' + printContents + '</body></html>');
                popupWin.document.close();
            }
        }]);
