angular.module('deitz')
                .controller('editebtController', [
                    '$scope',
                    '$rootScope',
                    'firmFactory',
                    '$state',
                    '$uibModal',
                    'ebt_value',
                    'index',
                    'ebt_data',
                    '$uibModalInstance',
                    'rateFactory',
                    'Notification',
                        function(
                            $scope,
                            $rootScope,
                            firmFactory,
                            $state,
                            $uibModal,
                            ebt_value,
                            index,
                            ebt_data,
                            $uibModalInstance,
                            rateFactory,
                            Notification
                    ) {

        $scope.title = "Edit Admin";
        $scope.formSubmit = false;

        $scope.validations = firmFactory.validations;
        $scope.messages = firmFactory.messages;

        if (ebt_value && ebt_value.status == 200 && ebt_value.data && ebt_value.data.result) {
            setTimeout(function() {
                $scope.addebt = ebt_value.data.result.ebt;
                $scope.addebt.role_id = ebt_value.data.result.ebt.role_id;
            }, 10);
        }

        rateFactory.get('/api/roles')
            .success(function(response) {
                $scope.roles = response;
            })
            .error(function(err) {});

        $scope.addEbtclerk = function(ebt, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                firmFactory.post('/api/updateebtclerk', $scope.addebt)
                    .success(function(response) {
                        if (response.status == 200 && response.result && response.result.ebtUpdate) {
                            $rootScope.showLoader(false);
                            ebt_data[index] = response.result.ebtUpdate;
                            ebt_data[index].role = response.result.ebtUpdate.role ? response.result.ebtUpdate.role.name : '';
                            Notification.success("Ebt clerk updated successfully");
                            $uibModalInstance.close(response.result.ebtUpdate);
                        }else{
                            $rootScope.showLoader(false);
                            Notification.error(response.result.message); 
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
