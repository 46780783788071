angular.module('deitz').factory('configFactory', ['$http', 'apiUrl', function ($http, apiUrl) {

    var configFactory = {};

    configFactory.getConfiguration = function (keys) {
        return $http.post(apiUrl + '/api/config/get-configurations',keys);
    };

    configFactory.saveConfiguration = function (data) {
        return $http.post(apiUrl + '/api/config/save-configurations', data);
    };

    configFactory.updatepaymentPassword = function (data) {
        return $http.post(apiUrl + '/api/config/update-payment-password', data);
    };

    configFactory.saveMobileNumbers = function (data) {
        return $http.post(apiUrl + '/api/config/save-mobile-numbers', data);
    };

    configFactory.updateElawCredentials = function (data) {
        return $http.post(apiUrl + '/api/config/update-elaw-credentials', data);
    };

    configFactory.updateTechniciansContacts = function (data) {
        return $http.post(apiUrl + '/api/config/update-technicians-contacts', data);
    };

    configFactory.updateFailedPasswordNotificationEmail = function (data) {
        return $http.post(apiUrl + '/api/config/update-failed-password-notification-email', data);
    };

    configFactory.updateSalesManagerEmail = function (data) {
        return $http.post(apiUrl + '/api/config/update-sales-manger-email', data);
    };

    configFactory.updateArCronEmail = function (data) {
        return $http.post(apiUrl + '/api/config/update-ar-report-cron-email', data);
    };

    configFactory.updateUserTimezone = function (data) {
        return $http.post(apiUrl + '/api/config/update-user-timezone', data);
    };

    configFactory.updateZoomMasterEmail = function (data) {
        return $http.post(apiUrl + '/api/config/update-zoom-master-email', data);
    };
    configFactory.updateCostPercentage = function (data) {
        return $http.post(apiUrl + '/api/config/update-cost-percentage', data);
    };

    configFactory.getOktaApiToken = function (data) {
        return $http.post(apiUrl + '/api/config/get-okta-api-token', data);
    };

    //Include validation helper
    var vh = validation_helper();

    configFactory.configuration = {
        payment_date: {   key: 'pay_date'},
        payment_password : { key : "pay_password"},
        routing_number : { key : "routing_number"},
        account_number : { key : "account_number"},
        cash_close_date : { key : "cash_close_date"}
    };

    return configFactory;
}]);
