angular.module('deitz').controller('serviceLevelController', [
    '$scope',
    '$rootScope',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$compile',
    '$uibModal',
    'Notification',
    '$filter',
    '$http',
    'apiUrl',
    function ($scope,
        $rootScope,
        DTOptionsBuilder,
        DTColumnBuilder,
        $compile,
        $uibModal,
        Notification,
        $filter,
        $http,
        apiUrl
    ) {

        $scope.dtInstance = {};
        $scope.loadingDemo = [];

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };

        $scope.actionRoles = function (data, type, full) {
            return "<a class='btn btn-primary btn-circle' ui-sref='code_tables.service_level_edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";

        };

        $scope.renderStatusAction = function (data, type, full, meta) {
            $scope.loadingDemo[full.id]= full;
            $scope.loadingDemo[full.id].loading = false;
            var status_tooltip = (full.status == '1') ? "Active" : "Deactive";

            return "<button ng-disabled='!havePermission(\"service_level\",\"update\")' ladda=\"loadingDemo['"+full.id+"'].loading\" class='ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm' ng-class=\"{'btn-success': '" + full.status + "' == '1', 'btn-danger': '" + full.status + "' == '0'}\" ng-click='changeStatus(\"" + full.id + "\")'>" + status_tooltip + "</button>";
        };

        $scope.changeStatus = function(id){
            
            var data = {};
            data.id = id;
            data.status = ($scope.loadingDemo[id].status == '1') ? '0' : '1';
            $scope.loadingDemo[id].loading = true;

            $http.post(apiUrl + '/api/update_service_level', data).success(function (res) {
                $rootScope.showLoader(false);
                if (res.error) {
                    Notification.error(res.result.message);
                } else {
                    $scope.reloadData();
                    Notification.success('Status updated Successfully');
                    
                }
            });
        }
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                $rootScope.showLoader(true);
                // map your server's response to the DataTables format and pass it to
                $http.post(apiUrl + '/api/get_service_level')
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        callback(response);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        if (err.error)
                            $scope.reloadData();
                    });

            })
            .withOption('stateSave', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'asc'])
            .withOption('createdRow', $scope.createdRow)

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('name').withOption('searchable', true),            
            DTColumnBuilder.newColumn('status').withTitle('status').withOption('searchable', false).renderWith($scope.renderStatusAction),            
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles)
        ];


    }]);