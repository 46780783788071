angular.module('deitz').controller('browseArPredefinedNoteController', [
    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile',
    function(
        $scope, $rootScope, $state, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $q, $compile
    ){

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var table = $('#ar_predefined_notes_list').DataTable();
            table.ajax.reload();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/ar-predefined-notes', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'A/R Predefined Notes' },
                { extend: 'pdf', title: 'A/R Predefined Notes' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('label').withOption('defaultContent', '').withTitle('Label'),
            DTColumnBuilder.newColumn('note').withOption('defaultContent', '').withTitle('Note'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ng-if="havePermission(\'ar_predefined_notes\',\'edit\')" ui-sref="code_tables.ar_predefined_note_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button>&nbsp;&nbsp'+
                        '<a class="btn btn-circle btn-danger" ng-click="delete('+full.id+')" ng-if="havePermission(\'ar_predefined_notes\',\'delete\')"><i class="fa fa-trash"></i></a>';
                }

            })
        ];

        $scope.delete = function(id){
            swal({
                    title: "Alert",
                    text: "Are you sure want to delete this?",
                    type: "error",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/ar-predefined-notes/'+id).success(function (res) {
                            $rootScope.showLoader(false); 
                            if(res.error){
                                Notification.error(res.result.message);    
                            }else{
                                Notification.success(res.result.message);
                            }
                            $scope.reloadData();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $scope.reloadData(); 
                        });
                    }
                });
        }
    }]);
