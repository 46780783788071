angular.module('deitz').controller('changeInvoiceFirmController', [
    '$scope', '$rootScope', '$http', 'apiUrl', 'DTOptionsBuilder', 'DTColumnBuilder', 'invoiceFactory', 'commonFactory', '$compile', '$uibModal', 'Notification', '$filter', '$timeout', '$q', '$stateParams', '$state', '$localStorage',
    function (
        $scope, $rootScope, $http, apiUrl, DTOptionsBuilder, DTColumnBuilder, invoiceFactory, commonFactory, $compile, $uibModal, Notification, $filter, $timeout, $q, $stateParams, $state, $localStorage
    ) {

        $scope.title = "Change Invoice Firm";
        $scope.searchInvoiceById = function (val) {
            return $http.post(apiUrl + '/api/invoice/search-invoice-number', {
                search: val
            }).then(function (response) {
                return response.data.invoices.map(function (item) {
                    return item;
                });
            });
        };

        $scope.onSelectInvoice = function (item, model, label) {
            $scope.invoice = item;
            $scope.assignedInvNo = true;
            $scope.invoice.attorneyFirms = $scope.invoice.attorney_firms;
        };

        $scope.onSelectAttorneyFirm = function (item, model, label) {
            $scope.assignedNewAttorneyFirm = true;
        };

        $scope.attorneyofficedata = function (val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.updateInvoiceFirm = function ($valid) {
            $scope.formSubmit = true;
            if ($valid && $scope.assignedInvNo) {
                $rootScope.showLoader(true);
                var invoiceData = {
                    invoiceID: $scope.invoice.invoiceID,
                    INV_NO: $scope.invoice.INV_NO,
                    oldAttorneyFirms: $scope.invoice.attorneyFirms,
                    newAttorneyFirms: $scope.attorneyFirmsNew,
                    changeFromNewScreen:true
                };
                commonFactory.post('/api/invoice/change-ordering-firm', invoiceData).success(function (response) {
                    if (!response.error) {
                        Notification.success(response.result.message);
                    }
                    $scope.invoice=null;
                    $scope.attorneyFirmsNew=null;
                    $rootScope.showLoader(false);
                }).error(function (error) {
                    $rootScope.showLoader(false);
                });
            }
        }

    }
]);
