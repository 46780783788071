angular.module('deitz').controller('addwitnessResponseController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',
    function(
        $scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ){

        $scope.our_suite = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Witness Response';


        $scope.submitWitnessResponse = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/witness_name', $scope.witnessResponse)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Witness Response added successfully");

                                $state.go("code_tables.witness_responses_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
