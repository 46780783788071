 angular.module('deitz')
    .controller('scheduledCallsController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$window',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  commonFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $window,
                  $filter,
                  $timeout,
                  $q,
                  $stateParams) {

             

            // get current month start and end date
            $scope.scheduledCallsData = { selectedValue : 'attorney'};
            $scope.scheduledCallsData.start_date = moment().startOf('month');
            $scope.scheduledCallsData.end_date = moment().endOf('month');
            $scope.selectedOption = {selectedValue : 'Both'};
            $scope.collector_id = localStorage.getItem('collector')??'All';
            $scope.downloadExcel = false;

            $scope.searchData = function(){
                $scope.attDtInstance.DataTable.state.clear();
                if($scope.scheduledCallsData.selectedValue == 'claimrep'){

                    $scope.reloadClaimRepData();
                }else{

                    $scope.reloadAttorneyData();
                }
            }
            $scope.resetType = function(type){ 
                $scope.scheduledCallsData.selectedValue = type;
                if(type == 'claimrep'){  
                    $scope.reloadClaimRepData();
                }else{ 
                    $scope.reloadAttorneyData();  
                } 
            }

            $scope.showDoneChekbox = function(type){
                $scope.selectedOption.selectedValue = type;
            }
                // ====================================================================================
                        
                                        // ATTORNEY SCHEDULED CALLS DATATABLE START

                // =====================================================================================


            $scope.attDtInstance = {};
            $scope.reloadAttorneyData = function () {
                $scope.attDtInstance.rerender();
            }; 

            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.reloadData = function() {
                $scope.attDtInstance.rerender();
            };
            $scope.resetSearch = function(){
                localStorage.removeItem('collector'); 
                $scope.scheduledCallsData.start_date = moment().startOf('month');
                $scope.scheduledCallsData.end_date = moment().endOf('month');
                $scope.collector_id='All';
                $scope.attDtInstance.DataTable.state.clear();
                $scope.reloadData();
            };
            $scope.attDtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    if ($scope.downloadExcel) {
                        $rootScope.showLoader(true);
                    }
                    data.start_date = moment($scope.scheduledCallsData.start_date).format('MM/DD/YYYY');
                    data.end_date = moment($scope.scheduledCallsData.end_date).format('MM/DD/YYYY');
                    data.selectedOption = $scope.selectedOption.selectedValue;
                    data.downloadExcel = $scope.downloadExcel;
                    data.collector = $scope.collector_id;
                    localStorage.setItem('collector',$scope.collector_id);
                    
                    commonFactory.showDataTable('/api/AccountsReceivableAttorneyNotes/get-scheduled-calls', data).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            $scope.reloadAttorneyData();
                        }
                        else { 
                            callback(res);
                            $scope.collector_id =localStorage.getItem('collector');
                        }
                        if ($scope.downloadExcel) {
                            $window.open(apiUrl + '/api/download-scheduled-calls-report', '_blank');    
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadAttorneyData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({ 
                    "sProcessing": "<img src='img/loading_bar.gif'/>"
                })
                .withOption('serverSide', true) 
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500) 
                .withOption('order', [4, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '4': {
                        type: 'text',
                    }

                });

            $scope.attDtColumns = [];
            $scope.toggleModel = {};

            $scope.attDtColumns.push(

                DTColumnBuilder.newColumn('att_firm.NAME').withOption('width', '20%').withOption('defaultContent', '-').withTitle('Firm Name').withOption('name','attFirm.NAME').renderWith(function(data,type,full,meta){

                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="accountReceivableNotes.browse({attId : ' + full.att_firm.id + ' })">'+data+'</a>';

                }),

                DTColumnBuilder.newColumn('ARAFNote').withOption('defaultContent', '-').withOption('width', '60%').withTitle('Notes').renderWith(function(data,type,full,meta){
                    var row = '';
                    if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                	return "<div style='word-break: break-all'>"+ row + ''+  full.ARAFNote+"</div>"; 
                
                }),
                
                DTColumnBuilder.newColumn('Done').withOption('defaultContent','-').withOption('width', '10%').withTitle('Done').renderWith(function(data,type,full,meta){
                    

                    $scope.toggleModel[meta.row] = full.HideNote === 'Y' ? true : false;

                    var toggleModel = 'toggleModel[' + meta.row + ']'; // Generate toggleModel variable name dynamically
                    

                    // Return toggle button HTML
                    var toggleButton = '<toggle ' +
                        'ng-model="' + toggleModel + '" ' +
                        'ng-disabled="inProcess" ' +
                        'ng-true-value="\'Y\'" ' +
                        'on="Yes"'+
                        'off="No"'+
                        'ng-false-value="\'N\'" ' +
                        'onstyle="btn-success" ' +
                        'offstyle="btn-danger" ' +
                        'ng-change="toggleChanged(' + full.id + ', ' + toggleModel + ')">' +
                        '</toggle>';

                    return toggleButton;
                }).withOption('searchable',false),
                DTColumnBuilder.newColumn('ARAFReminderDate').withOption('defaultContent', '-').withOption('width', '10%').withTitle('Next Action').renderWith(function(data,type,full,meta){
                
                	return moment(full.ARAFReminderDate).format('MM/DD/YYYY'); 
                
                }).withOption('searchable', false),
                
                DTColumnBuilder.newColumn('created_by.name').withOption('defaultContent', '-').withTitle('Created By').withOption('width', '10%').withOption('name','CreatedBy.name'),
                DTColumnBuilder.newColumn('ARAFNoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {                        
                        return moment(data).format('MM/DD/YYYY'); 
                    } else {
                        return '-'
                    }

                })
            );

            $scope.downloadExcelFile = function () {
                $scope.downloadExcel = true;
                $scope.searchData();
            }    

            // update via toggle button
            $scope.toggleChanged = function(id , mode){
                $rootScope.showLoader(true);
                var modData = mode == true ? 'Y':'N';
                var requestUrl = ($scope.scheduledCallsData.selectedValue == 'attorney') ? '/api/AccountsReceivableAttorneyNotes/hide-note' : '/api/AccountsReceivableClaimRepNotes/hide-note';
                $http.post(apiUrl+requestUrl, {id:id,mode:modData})
                    .success(function(response) {
                        if (response && response.result) {
                            $rootScope.showLoader(false);
                            Notification.success("Note updated successfully");

                        }else{
                            Notification.error('Something went wrong!');
                            $rootScope.showLoader(false);
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });  
            };

                //====================================================================================//
                        
                                        // ATTORNEY SCHEDULED CALLS DATATABLE END//

                //====================================================================================//





                //====================================================================================//
                        
                                        // CLAIM REPRESENTATIVE SCHEDULED CALLS DATATABLE START//

                //====================================================================================//


        $scope.clmDtInstance = {};
            $scope.reloadClaimRepData = function () {
                $scope.clmDtInstance.rerender();
            };
 
            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.clmDtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    if ($scope.downloadExcel) {
                        $rootScope.showLoader(true);
                    }
                    // map your server's response to the DataTables format and pass it to
                    data.start_date = moment($scope.scheduledCallsData.start_date).format('MM/DD/YYYY');
                    data.end_date = moment($scope.scheduledCallsData.end_date).format('MM/DD/YYYY');
                    data.selectedOption = $scope.selectedOption.selectedValue;
                    data.downloadExcel = $scope.downloadExcel;
                    data.collector = $scope.collector_id;
                    commonFactory.showDataTable('/api/AccountsReceivableClaimRepNotes/get-scheduled-calls', data).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            $scope.reloadClaimRepData();
                        }
                        else { 
                            callback(res);
                        }
                        if ($scope.downloadExcel) {
                            $window.open(apiUrl + '/api/download-scheduled-calls-report', '_blank');    
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadClaimRepData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({ 
                    "sProcessing": "<img src='img/loading_bar.gif'/>"
                })
                .withOption('serverSide', true) 
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500) 
                .withOption('order', [4, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
                })
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '4': {
                        type: 'text',
                    }

                });

            $scope.clmDtColumns = [];

            $scope.clmDtColumns.push(

                DTColumnBuilder.newColumn('clm_rep.ClaimRepFirstName').withOption('width', '20%').withOption('defaultContent', '-').withTitle('Claim Representative').renderWith(function(data,type,full,meta){
                     
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="accountReceivableNotes.browse({clmId : ' + full.clm_rep.ClaimRepID + ' })">'+full.clm_rep.claimRepName+'</a>';

                }).withOption('name','clmRep.ClaimRepFirstName'),

                DTColumnBuilder.newColumn('ARCRNote').withOption('defaultContent', '-').withOption('width', '60%').withTitle('Notes').renderWith(function(data,type,full,meta){

                    var row = '';
                    if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                    return "<div style='word-break: break-all'>"+ row + '' + full.ARCRNote+"</div>"; 
                
                }),
                DTColumnBuilder.newColumn('Done').withOption('defaultContent','-').withOption('width', '10%').withTitle('Done').renderWith(function(data,type,full,meta){
                    

                    $scope.toggleModel[meta.row] = full.HideNote === 'Y' ? true : false;

                    var toggleModel = 'toggleModel[' + meta.row + ']'; // Generate toggleModel variable name dynamically
                    

                    // Return toggle button HTML
                    var toggleButton = '<toggle ' +
                        'ng-model="' + toggleModel + '" ' +
                        'ng-disabled="inProcess" ' +
                        'ng-true-value="\'Y\'" ' +
                        'ng-false-value="\'N\'" ' +
                        'on="Yes"'+
                        'off="No"'+
                        'onstyle="btn-success" ' +
                        'offstyle="btn-danger" ' +
                        'ng-change="toggleChanged(' + full.id + ', ' + toggleModel + ')">' +
                        '</toggle>';

                    return toggleButton;
                }).withOption('searchable',false),
                DTColumnBuilder.newColumn('ARCRReminderDate').withOption('defaultContent', '-').withOption('width', '10%').withTitle('Next Action').renderWith(function(data,type,full,meta){
                    
                    return moment(data).format('MM/DD/YYYY'); 
                
                }).withOption('searchable', false),
                
                DTColumnBuilder.newColumn('created_by.name').withOption('defaultContent', '-').withTitle('Created By').withOption('width', '10%').withOption('name','CreatedBy.name'),
                DTColumnBuilder.newColumn('ARCRNoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return moment(data).format('MM/DD/YYYY'); 
                    } else {
                        return '-'
                    }

                })
            );


            $http.get(apiUrl + '/api/collector').success(function(res){
                $scope.collectorsList = res.result;
            });
                //====================================================================================//
                        
                                        // CLAIM REPRESENTATIVE SCHEDULED CALLS DATATABLE END //

                //====================================================================================//



}]);