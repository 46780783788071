angular.module('deitz')
    .controller('viewPopupController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        '$filter',
        'commonFactory',
        '$stateParams',
        '$uibModal',
        'invoiceFactory',
        'invoice_id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  $state,
                  $timeout,
                  $filter,
                  commonFactory,
                  $stateParams,
                  $uibModal,
                  invoiceFactory,
                  invoice_id,
                  $uibModalInstance,
                  configFactory) {

           

            // If request comes from invoice module screen then invoice_id will the array otherwise we can fetch the invoice from stateParams.
            $scope.invoice = invoice_id[0];
            $scope.toEmail =  $scope.invoice.EmailAddress
            $scope.toFax =  $scope.invoice.FAX ;
            $scope.is_bad_claim_number;
            $scope.emailSubject = 'Your action required - late unpaid invoice from Lexitas'
            $scope.dateRange
            $scope.filterDate=false
            

            $scope.dateRange = {startDate:localStorage.getItem('dateRange_invoice_startDate'),endDate:localStorage.getItem('dateRange_invoice_endDate')}
            if($scope.dateRange.startDate){
                $scope.filterDate=true
                // $scope.emailSubject = 'Unpaid Invoice Bettwen '+moment($scope.dateRange.startDate).format('YYYY-MM-DD')+" To "+moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
            }else{
                // $scope.emailSubject = 'Unpaid Invoice Which Are More Then 60 Day Old'
            }
           console.log($scope.uibModalInstance,invoice_id)
            // fetch the invoice information for all the invoices
         
         
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
            };
            $scope.submit_invoice_send_fax = function(){
               
             if($scope.toFax){
                swal({
                        title: "Are you sure ?",
                        text: "Are you sure you want to fax this "+$scope.invoice.count+" invoice?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#1ab394",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            if($scope.filterDate){
                                var req_data = {
                                'via':'fax',
                                'toFax':$scope.toFax,
                                
                                'attorney_id':$scope.invoice.attorney_id,
                                'filterDate':$scope.filterDate,
                                "startDate" : moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                                    "endDate" : moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                                }
                            }else{
                                var req_data = {
                                    'via':'fax',
                                    'toFax':$scope.toFax,
                                    
                                    'attorney_id':$scope.invoice.attorney_id,
                                    'filterDate':$scope.filterDate,
                                    
                                    }
                            }
                            $rootScope.showLoader(true);
                            //$close(JOB_NO);
                            commonFactory.post('/api/ReprintInvoiceSendEmailFax',req_data).success(function (res) {
                                $rootScope.showLoader(false);
                                if (res.error)
                                {
                                    
                                }
                                else{ 
                                    $scope.closePopupWindowSimple();
                                    setTimeout(function () {
                                        document.getElementById('sendFaxSuccesPopup').click();
                                    }, 400);
                                }
                            }).error(function (err) {
                             $rootScope.showLoader(false);
                            }
                                
                            );
                        } else {
                            // $uibModalInstance.close(false);
                        }
                    });
                }
            };
            $scope.submit_update = function(id){
                $rootScope.showLoader(true);
                //$close(JOB_NO);
                commonFactory.post('/api/updateFirm',{'id':$scope.invoice.id,'EmailAddress':$scope.invoice.EmailAddress,'FAX':$scope.invoice.FAX}).success(function (res) {
                    $rootScope.showLoader(false);
                    if (res.error)
                    {
                        
                    }
                    else{ 
                        $scope.closePopupWindowSimple();
                        setTimeout(function () {
                            document.getElementById('reloadSuccesPopupUpdate').click();
                        }, 400);
                    }
                }).error(function (err) {
                 $rootScope.showLoader(false);
                }
                    
                );
            };

            
            $scope.reloadSubject = function(){
                if(!$scope.is_bad_claim_number){
                    $scope.is_bad_claim_number = true
                }else{
                    $scope.is_bad_claim_number=false
                }
                if($scope.is_bad_claim_number){
                    if(!$scope.invoice.FILE_NO){
                        $scope.emailSubject = "Missing Information File Number Empty And Invalid Claim Number For "+$scope.invoice.INV_NO+" Invoice"
                    }   
                    else{
                        $scope.emailSubject = "Missing Information Invalid Claim Number For "+$scope.invoice.INV_NO+" Invoice"
                    }

                }else{
                    if(!$scope.invoice.FILE_NO){
                        $scope.emailSubject = "Missing Information File Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                    }   
                    else{
                        $scope.emailSubject = "Missing Information For "+$scope.invoice.INV_NO+" Invoice"
                    }
                }
            };
            $scope.reloadDateRange = function(){
                if(!$scope.filter_date){
                    $scope.filter_date = true
                }else{
                    $scope.filter_date=false
                }
            }

            $scope.ValidateEmail=function (mail) 
            {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
                {
                    return (true)
                }
                    return (false)
            }

            $scope.submit_invoice_send_mail = function(){
            if( $scope.toEmail){
                $rootScope.showLoader(true);
                
                if($scope.filterDate){
                    var req_data = {
                    'via':'email',
                    'toEmail':$scope.toEmail,
                    'subject':$scope.emailSubject,
                    'attorney_id':$scope.invoice.attorney_id,
                    'filterDate':$scope.filterDate,
                    "startDate" : moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                        "endDate" : moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                    }
                }else{
                    var req_data = {
                        'via':'email',
                        'toEmail':$scope.toEmail,
                        'subject':$scope.emailSubject,
                        'attorney_id':$scope.invoice.attorney_id,
                        'filterDate':$scope.filterDate,
                        
                        }
                }
                commonFactory.post('/api/ReprintInvoiceSendEmailFax',req_data).success(function (res) {
                    $rootScope.showLoader(false);
                    if (res.error)
                    {
                        
                    }
                    else{ 
                        $scope.closePopupWindowSimple();
                        setTimeout(function () {
                            document.getElementById('sendMailSuccesPopup').click();
                        }, 400);
                    }
                }).error(function (err) {
                 $rootScope.showLoader(false);
                }
                    
                );
            }
            };

            $scope.send_mail_transcript = function(){
                if( $scope.ValidateEmail($scope.toEmail)){
                    $rootScope.showLoader(true);
                    //$close(JOB_NO);
                    var req_data = {
                        'toEmail':$scope.toEmail,
                        }
                    if($scope.dateRange){
                        req_data.filterDate = true
                        req_data.startDate = moment($scope.dateRange.startDate).format('YYYY-MM-DD'), 
                        req_data.endDate = moment($scope.dateRange.endDate).format('YYYY-MM-DD') 
                       
                    }    
                   
                    commonFactory.post('/api/send-email-geico-invoce-missing-transcript',req_data).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error)
                        {
                            
                        }
                        else{ 
                            $scope.closePopupWindowSimple();
                            setTimeout(function () {
                                document.getElementById('sendMailTrancriptSuccesPopup').click();
                            }, 400);
                        }
                    }).error(function (err) {
                     $rootScope.showLoader(false);
                    }
                        
                    );
                }
                };
    
           
            $scope.copyData= function(data){
           
                console.log(data)
                 navigator.clipboard.writeText(data)
     
                 $('#copied-success').fadeIn(800);
                 $('#copied-success').fadeOut(800);
             }
             $scope.download= function(fileUrl){
           
                var a = document.createElement("a");
                a.href = fileUrl;
                a.download = fileUrl.substring(fileUrl.lastIndexOf('/')+1);
                a.click();
             }
             $scope.downloadTranscript= function(fileUrl){
           
                fileUrl ="https://depositionnet.com/"+ fileUrl 
                var a = document.createElement("a");
                a.href = fileUrl;
                a.target = "_blank"; 
                a.download = fileUrl.substring(fileUrl.lastIndexOf('/')+1);
                a.click();
             }
    
          
        }]);
