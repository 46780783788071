angular.module('deitz')
    .controller('mergeClaimRepController', [
        '$scope',
        '$rootScope',
        '$state',
        'firmFactory',
        'Notification',
        '$stateParams',
        'apiUrl',
        '$http',
        'commonFactory',
        function(
            $scope,
            $rootScope,
            $state,
            firmFactory,
            Notification,
            $stateParams,
            apiUrl,
            $http,
            commonFactory
            ) {

            $scope.title = 'Merge Claim Representatives';
            $scope.mergeClaimRep = {};
            $scope.isdeleteClaimRepSelected = false;
            $scope.iskeepClaimRepSelected = false;
            $scope.submitted = false;
            $scope.claimrepresentativeaddress_id = null;
            
            $scope.allClaimRep = function(amt){
                commonFactory.get('/api/getallClaimrep')
                .success(function(response) {
                    if (response.result && response.result.claimrepcompany) {
                        $scope.claimrepcompany = response.result.claimrepcompany || '';
                    }
                }).error(function(err) {});
            }

            $scope.allClaimRep();

            $scope.changedInsuaranceCompany = function(){
                $scope.resetForm();
                if($scope.claimrepresentativeaddress_id == 'null'){
                    $scope.claimrepresentativeaddress_id = null;
                }
            }

            $scope.currencyFormat = function(amt){
                if(amt == null){
                    return '$0.00';
                }
                if(parseFloat(amt) >= 0){
                    return '$'+parseFloat(amt).toFixed(2);
                }
                if(parseFloat(amt) < 0){
                    return '-$'+ parseFloat(Math.abs(amt)).toFixed(2);
                }
            };

            $scope.ClaimRepData = function (val) {
                var claimrepresentativeaddress_id = $scope.claimrepresentativeaddress_id;
                
                if(claimrepresentativeaddress_id == "null" || claimrepresentativeaddress_id == 'NaN'){
                    claimrepresentativeaddress_id = null;
                }

                return $http.get(apiUrl + '/api/claimRepSearchByName', {
                    params: {
                        name: val,
                        claimrepresentativeaddress_id: claimrepresentativeaddress_id,
                        selectedClaimRepId : $scope.selectedClaimRepId
                    }
                }).then(function (response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            $scope.clearfield = function (key){
                  
                if(key == 'keepClaimRep' && !$scope.iskeepClaimRepSelected){
                    $scope.mergeClaimRep.keepClaimRep = null;
                }
                if(key == 'deleteClaimRep' && !$scope.isdeleteClaimRepSelected){
                    $scope.mergeClaimRep.deleteClaimRep = null;
                }
             

            };
            $scope.resetfield = function (key){
                if(key =='keepClaimRep'){ 

                    $scope.iskeepClaimRepSelected = false;
                    $scope.keepClaimRepId = null;

                }else{     

                    $scope.isdeleteClaimRepSelected = false; 
                    $scope.deleteClaimRepId = null; 
                }

                if($scope.keepClaimRepId){

                    $scope.selectedClaimRepId = $scope.keepClaimRepId; 
                
                }else if($scope.deleteClaimRepId){
                
                    $scope.selectedClaimRepId = $scope.deleteClaimRepId;
                
                }else{
                
                    $scope.selectedClaimRepId = null;
                
                }

            };
            //reset form
            $scope.resetForm = function(){
                $scope.iskeepClaimRepSelected = false;
                $scope.keepClaimRepId = null;  
                $scope.mergeClaimRep.keepClaimRep = null;
                $scope.isdeleteClaimRepSelected = false; 
                $scope.deleteClaimRepId = null; 
                $scope.mergeClaimRep.deleteClaimRep = null;
                $scope.mergeClaimRepForm.$setPristine(true);
                $scope.mergeClaimRepForm.$setUntouched(true);
            } 
            $scope.onSelectClaimRep = function ($item, $model, $label, $event,firmType) {
                if(firmType =='keepClaimRep'){
                    $scope.mergeClaimRep.keepClaimRep = angular.copy($item);
                    $scope.iskeepClaimRepSelected = true;
                    $scope.keepClaimRepId = $scope.mergeClaimRep.keepClaimRep.ClaimRepID;
                }else{
                    $scope.mergeClaimRep.deleteClaimRep = angular.copy($item);    
                    $scope.isdeleteClaimRepSelected = true; 
                    $scope.deleteClaimRepId = $scope.mergeClaimRep.deleteClaimRep.ClaimRepID;
                }
                
            };
  
            $scope.mergeClaimRep = function(isValid){ 
                $scope.submitted = true;
                if(isValid){

                    if($scope.mergeClaimRep.keepClaimRep.claimrepresentativeaddress_id != $scope.mergeClaimRep.deleteClaimRep.claimrepresentativeaddress_id){
                        Notification.error('You have selected claim representatives of different insurance companies, please select claim representatives of same insurance company.');
                        return false;   
                    }

                    swal({
                            title: "<h3>MERGE THESE CLAIM REPRESENTATIVE(S) - ARE YOU SURE? ONCE THIS HAS BEGUN YOU CAN'T STOP IT..</h3>",
                            text: "<small style='color:red;font-size:16px;'> Note : The merge will take some time. Please wait...</small>",
                            type: "warning",
                            html: true,
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No, Cancel this merge",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) { 
                                $rootScope.showLoader(true);  
                                var data = {}
                                data.deleteClaimRep =  $scope.mergeClaimRep.deleteClaimRep || null;
                                data.keepClaimRep = $scope.mergeClaimRep.keepClaimRep || null;
                                firmFactory.post('/api/merge-claim-rep',data).success(function (response) {
                                     
                                    $rootScope.showLoader(false);
                                    $scope.claimrepresentativeaddress_id = null;
                                    $scope.resetForm();  
                                    $scope.allClaimRep();
                                    
                                    if(response.result.status == 200){
                                        Notification.success(response.result.message); 
                                    }else{
                                        Notification.error(response.result.message);
                                    } 
                                    
                                }).error(function (error) {
                                    $rootScope.showLoader(false);
                                    Notification.error(error.message);
                                });
                            }
                        });
                }
            }
        }]);