angular.module('deitz').controller('checkOperationController',[
    '$scope', '$rootScope', 'commonFactory', '$http', 'apiUrl', '$uibModal', 'Notification','$location','$timeout',
    function(
        $scope, $rootScope, commonFactory, $http, apiUrl, $uibModal, Notification,$location,$timeout
    ){

        $scope.title = "Check Operations";
        $scope.showResourceAssignJobs = false;
        $scope.formSubmit = false;
        $scope.hideResourceDropDown = false;
        $scope.checkListResp = {};
        $scope.isCheckUpdated = false;

        $scope.operation = {
            payment_type: 'print_check',
            payment_mode : 'single',
            total_pending_amount : 0,
            resource_type : 'R'
        };

        //array search
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $scope.BusinessUnitsList = [];
        $scope.getBusinessUnitsLists = function(){
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});
                $scope.selectedBusiness();
            });
        }
        
        /*if($scope.selectedBusinessUnitIDs.length){
            console.log($scope.BusinessUnitsList)
            $scope.BusinessUnitsList.forEach(data => {
                if(data.id ==$scope.selectedBusinessUnitIDs[0]){
                    $scope.operation.payment_date = data.pay_date 
                    $scope.operation.print_date = data.pay_date  
                }
            });
        }*/
        

        $http.get(apiUrl + '/api/check/get-current-payroll-check-session').then(function(response) { 
            if(response.data.session.length==0){
                swal({
                    title: "Are you sure ?",
                    text: "<small>You are running Payroll Operations (PO) and will have exclusive control of PO for a 30 minute session to complete the process.<br> Before accepting control, be sure your reports tie-out to the Batch Totals. <br>  Your User information will be tracked and any OTHER USER attempting to run payroll during your session will see your User information.<br> Once your update is completed your exclusive session will automatically be released.<small>",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    html:true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $http.post(apiUrl+'/api/check/start-payroll-check-session',{businessUnitIds:$scope.selectedBusinessUnitIDs})
                        .success(function(response){
                           if(!response.success){
                                swal({
                                    title:response.user.name +"  is already using the payroll operation. If you need to contact them, please email "+response.user.email,
                                    html:true
                                },function(isConfirm){
                                    $location.path('/home');
                                });
                           }
                           else{
                                Notification.success("Your payroll session start successfully");
                                $timeout(function () {
                                    Notification.error("Your payroll session is expired");
                                    $location.path('/home');
                                }, response.session.session_remain_time);
                           }
                        });
                    }
                    else{
                        $location.path('/home');
                    }
                });
            }
            else{
                if(response.data.session[0].user_id!=$rootScope.auth_user.id){
                    swal({
                        title:response.data.session[0].user.name +"  is already using the payroll operation. If you need to contact them, please email "+response.data.session[0].user.email,
                        html:true
                    });
                    $location.path('/home');
                }
                else{
                    $timeout(function () {
                        Notification.error("Your payroll session is expired");
                        $location.path('/home');
                    }, response.data.session[0].session_remain_time);
                }
            }
        });
        $scope.closePayrollSession = function(){
            $http.post(apiUrl+'/api/check/stop-payroll-check-session')
            .success(function(response){
                Notification.success("Payroll session end successfully");
                $location.path('/home');
            });
        }
        // get the last check number
        $scope.getLastCheckNumber =function(){
            $http.get(apiUrl + '/api/config').then(function(response) {
                $scope.configs = response.data.result.config;
                angular.forEach($scope.configs, function(value, key) {
                    if (value.key === "last_check_number") {
                        $scope.operation.last_check = value.value;
                        $scope.operation.start_check_number = parseInt(value.value)+1;
                        $scope.validateCheckNumber();
                    }
                });
            });
        }
        $scope.getLastCheckNumber();
        


        $scope.$watch('operation.resource_type',function(newValue,oldValue){
            if(newValue === 'B'){
                $scope.hideResourceDropDown = true;
            }
            $scope.populateTotal();
            $scope.getLastCheckNumber();
        });

        $scope.$watch('operation.payment_mode',function(newValue,oldValue){
            if(newValue === 'single' && $scope.operation.resource_type !== 'B'){
                $scope.hideResourceDropDown = false;
            }else{
                $scope.hideResourceDropDown = true;
                $scope.operation.resource_id = null;
            }
            $scope.populateTotal();
        });

        $scope.$watch('operation.payment_type',function(newValue,oldValue){
            $scope.populateTotal();
        });

        $scope.getResourceList = function($keyword){
            return $http.get(apiUrl + '/api/search-resource', {
                params: {
                    keyword:$keyword
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.selectResource = function($item, $model, $label, $event){
            $item.resource_name = $item.first_name + ' '+$item.last_name;
            $scope.operation.resource = $item;
            $scope.showResourceAssignJobs = true;
            $scope.operation.resource_id = $item.id;

            $scope.getTotalPendingAmount();
        };

        $scope.unSelectResource = function(){
            if($scope.showResourceAssignJobs){
                $scope.operation.resource = {};
                $scope.showResourceAssignJobs = false;
                $scope.operation.resource_id = null;
            }
        };
        $scope.selectedBusiness = function(){
           
            $scope.BusinessUnitsList.forEach(data => {
                if(data.id ==$scope.selectedBusinessUnitIDs[0]){
                    $scope.operation.payment_date = data.pay_date 
                    $scope.operation.print_date = data.pay_date  
                }
            });
            
        };
        $scope.setPayDate = function(){
            $rootScope.BusinessUnitList();
        }
        $scope.getTotalPendingAmount = function(){
            var resource_id = $scope.operation.resource_id;
            var payment_date = moment($scope.operation.payment_date).format('MM/DD/YYYY');
            $scope.showLoader(true);

            commonFactory.post('/api/check/get-unpaid-job-amount',{
                resource_id: resource_id,
                resource_type:$scope.operation.resource_type,
                payment_date : payment_date,
                payment_type: $scope.operation.payment_type,
                selectedBusinessUnitIDs: $scope.selectedBusinessUnitIDs.filter(Boolean)

            }).success(function(response){
                $scope.operation.total_pending_amount = response.totalAmount;
                $scope.showLoader(false);
            }).error(function(error){
                $scope.showLoader(false);
            })
        };

        $scope.populateTotal= function(){
            if($scope.selectedBusinessUnitIDs.length){
                $scope.BusinessUnitsList.forEach(data => {
                    if(data.id ==$scope.selectedBusinessUnitIDs[0]){
                        $scope.operation.payment_date = data.pay_date 
                        $scope.operation.print_date = data.pay_date 
                    }
                });
            }
            // get total payment when resource is selected
            if($scope.operation.payment_mode === 'batch'){
                $scope.getTotalPendingAmount();
            }else{
                $scope.operation.total_pending_amount = null;
                if($scope.operation.resource_id){                    
                    $scope.getTotalPendingAmount();
                }
            }
        };

        $scope.validateCheckNumber = function(){
            commonFactory.post('/api/check/validate-check-number',{check_number: $scope.operation.start_check_number }).success(function(response){
                $scope.errCheckNo = response.result.isCheckExist;
            }).error(function(error){
                $scope.errCheckNo = true;
            });
        };


        $scope.showJobOfResource = function () {
            if($scope.operation.resource_id){
                var jobModal = $uibModal.open({
                    templateUrl: "modules/payroll/view_resource_jobs.html",
                    controller: 'viewResourceUnpaidJobsController',
                    resolve: {
                        resource_id: function () {
                            return $scope.operation.resource_id;
                        },
                        resource_type : function(){
                            return $scope.operation.resource_type;
                        },
                        payment_date : function(){
                            // return $scope.operation.payment_date;
                            return moment($scope.operation.payment_date).format('MM/DD/YYYY');
                        },
                        selectedBusinessUnitIDs : function(){
                            return $scope.selectedBusinessUnitIDs.filter(Boolean);
                        }
                    },
                    windowClass: 'full-width-model',
                    keyboard: false,
                    backdrop: false,
                    size: 'lg',
                    close: function () {

                    }
                });


                jobModal.result.then(function (is_date_changed) {
                    if (is_date_changed) {
                        // need to update the flag for print
                        $scope.getTotalPendingAmount();
                    }
                }, function () {
                    console.log("Modal Dismissed!!!");
                });
            }
        };

        $scope.viewCheckPrint= function($valid){
             $rootScope.showLoader(true); 
                var payment_dt = moment($scope.operation.payment_date).format('MM/DD/YYYY');
                commonFactory.post('/api/check/get-check-print-list',{
                    'selectedBusinessUnitIDs': $scope.selectedBusinessUnitIDs.filter(Boolean),
                    'resource_id' : $scope.operation.resource_id,
                    'resource_type': $scope.operation.resource_type,
                    'payment_date':payment_dt,
                    'payment_mode':$scope.operation.payment_mode,
                    'payment_type': $scope.operation.payment_type,
                    'check_number':$scope.operation.start_check_number
                }).success(function(response){
                    $scope.checkListResp = response;
                    $scope.check_filename = response.data.check_filename;
                    $scope.isCheckUpdated = false;
                    $scope.url = apiUrl;

                    $rootScope.showLoader(false);

                }).error(function(error){
                    $rootScope.showLoader(false);
                });
        };

        $scope.updateCheck = function(){
            swal({
                    title: "Are you sure ?",
                    text: "You are about to update the reference number.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes! Update It.",
                    cancelButtonText: "No, do not update record",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {

                        $rootScope.showLoader(true);
                        $scope.checkListResp.data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean)
                        var params = {
                            data : $scope.checkListResp.data,
                            result : $scope.checkListResp.result
                        };
                        commonFactory.post('/api/updateJobWithCheckNumber',params).success(function (responseData) {

                            Notification.success("Reference number(s) successfully updated.");
                            $scope.isCheckUpdated = true;
                            $scope.checkListResp = {};

                            $scope.populateTotal();

                            $rootScope.showLoader(false);


                        }).error(function (error) {

                            Notification.error("Something went wrong!");
                            $rootScope.showLoader(false);

                        });
                    } else {

                    }
                });
            };

            $scope.hideDiv = function(){ 
                $scope.check_filename = false;
            }

    }]);
