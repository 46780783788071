angular.module('deitz')
    .controller('addUserToDiaryController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        '$filter',
        'commonFactory',
        '$stateParams',
        '$uibModal',
        'invoiceFactory',
        'Notification',
        'configFactory',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  $state,
                  $timeout,
                  $filter,
                  commonFactory,
                  $stateParams,
                  $uibModal,
                  invoiceFactory,
                  Notification,
                  configFactory) {


            // If request comes from invoice module screen then attorney_id will the array otherwise we can fetch the invoice from stateParams.
            $scope.attorney_id = $state.params.attorney_id;
            $scope.user = {};
            $scope.perms_list = [{"label": "EBT Coordinator", "value": "attorney"},
                {"label": "Paralegal", "value": "attorney"},
                {"label": "Secretary", "value": "attorney"},
                {"label": "Attorney", "value": "attorney"},
                {"label": "Court Reporter", "value": "reporter"},
                {"label": "Agency", "value": "agency"},
                {"label": "Witness/Deponent", "value": "witness"},
                {"label": "Audio Processor", "value": "audioperson"},
                {"label": "Interpreter", "value": "attorney"},
                {"label": "Other/don't know", "value": "attorney"}];

            $scope.formSubmit = false;
            $scope.title = 'Add User';

            // Get attorney types
            commonFactory.get('/api/get-attorney-type')
                .success(function (response) {
                    $scope.attorney_type = response.result.attorneyType || '';
                    $rootScope.showLoader(false);
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });

            $scope.submit_user = function (isValid) {
                $scope.formSubmit = true;
                console.log($scope.addAttorneyToDiaryForm)
                if ($scope.user.perms === 'reporter') {
                    localStorage.setItem('representative_email', $scope.user.email);
                    localStorage.setItem('representative_name', $scope.user.first_name);
                    localStorage.setItem('representative_last_name', $scope.user.last_name);
                    localStorage.setItem('representative_phone', $scope.user.phone);
                    if ($scope.user.resource_type === 'R') {
                        $state.go('representative.reporter.step_one');
                    } else if ($scope.user.resource_type === 'S') {
                        $state.go('representative.sales.step_one');
                    } else if ($scope.user.resource_type === 'T') {
                        $state.go('representative.typist.step_one');
                    } else {
                        $state.go('representative.reporter.step_one');
                    }
                    return false;
                }
                if (isValid) {
                    $rootScope.showLoader(true);
                    $scope.user.attorney_id = $scope.attorney_id;

                    commonFactory.post('/api/users/create-depo-user', $scope.user).success(function (response) {
                        $scope.addAttorneyToDiaryForm.$setUntouched();
                        $scope.addAttorneyToDiaryForm.$setPristine();
                        $scope.user = {};
                        $scope.formSubmit = false;
                        $rootScope.showLoader(false);
                        Notification.success(response.success);
                    }).error(function (error) {
                            $rootScope.showLoader(false);
                            Notification.error(error.error);
                        }
                    );
                }
            }
        }]);