angular.module('deitz')
    .controller('createUserController', ['$scope', '$rootScope','$http','apiUrl', 'UsersFactory', 'RolesFactory', '$state', 'Notification', 'loginFactory', function ($scope, $rootScope, $http,apiUrl,UsersFactory, RolesFactory, $state, Notification, loginFactory) {
        
        $scope.validations = loginFactory.validations;
        $scope.messages = loginFactory.messages;
        $scope.selectedBusinessUnitIDs
        $scope.page_title = $state.current.data.pageTitle;
        $scope.user = {
            role: null
        };
        $scope.selectedBusinessUnitIDs = [];
        $scope.roles = [{ id: null, name: "Select Role" }];
        $scope.models = {
            selected: null,
            BussinesUnit: [],
            Role: [],
            SelectedBussinesUnit: [],
            SelectedRole: []
            
        };   
         
        // Generate initial model
        // for (var i = 1; i <= 3; ++i) {
        //     $scope.models.lists.A.push({label: "Item A" + i});
        //     $scope.models.lists.B.push({label: "Item B" + i});
        // }
        
        // Model to JSON for demo purpose
        $scope.$watch('models', function(model) {
            $scope.modelAsJson = angular.toJson(model, true);
        }, true);
  


        RolesFactory.getAllRole().success(function (res) {
            if (res) {
                res.forEach(function (e) {
                    $scope.roles.push(e);
                    $scope.models.Role.push(e)
                });
            }
        }).error(function (err) {
            $scope.roles = [];
        });

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnits = response.data.result.data;  
            $scope.models.BussinesUnit =  $scope.BusinessUnits         
            // console.log($scope.BusinessUnits,response);           
         }); 
        $scope.submitRole = function (valid) {
            if (valid && $scope.models.SelectedBussinesUnit.length != 0 && $scope.models.SelectedBussinesUnit.length == $scope.models.SelectedRole.length) {
                $scope.loadingDemo = true;
                $scope.user.SelectedBussinesUnit = $scope.models.SelectedBussinesUnit
                $scope.user.SelectedRole = $scope.models.SelectedRole
                UsersFactory.addUser($scope.user).success(function (response) {
                    Notification.success(response.success);
                    // notify({
                    //     message: response.success,
                    //     classes: 'alert alert-success',
                    //     templateUrl: 'views/common/notify.html',
                    //     duration: 100
                    // });
                    $state.go('users.browse');
                }).error(function (error) {
                    $scope.loadingDemo = false;
                    Notification.error(error.error);
                    // notify({
                    //     message: error.error,
                    //     classes: 'alert alert-danger',
                    //     templateUrl: 'views/common/notify.html',
                    //     duration: 100
                    // });
                });
            }
            else if($scope.models.SelectedBussinesUnit.length == 0){
                Notification.error('Please Add At least 1 Business Unit');
            }
            else if($scope.models.SelectedBussinesUnit.length < $scope.models.SelectedRole.length){
                Notification.error('Please Assign Business Unit For Role');
            }
            else if($scope.models.SelectedBussinesUnit.length > $scope.models.SelectedRole.length){
                Notification.error('Please Assign Role For Business Unit');
            }
        };
        $scope.removeBussinesUnit = function (index) {
            console.log(index);
           
            if($scope.models.SelectedBussinesUnit.length > index){
                $scope.models.BussinesUnit.push($scope.models.SelectedBussinesUnit[index])
            $scope.models.SelectedBussinesUnit.splice(index,1)}
            $scope.models.SelectedRole.splice(index,1)
        };
        

//


    }]);