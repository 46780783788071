angular.module('deitz')
    .controller('assignsResourceController',[
        '$scope',
        '$rootScope',
        '$state',
        '$http',
        'apiUrl',
        '$timeout',
        '$uibModal',
        'commonFactory',
        '$uibModalInstance',
        'Notification',
        'JOB',
        'resource_type',
        function(
            $scope,
            $rootScope,
            $state,
            $http,
            apiUrl,
            $timeout,
            $uibModal,
            commonFactory,
            $uibModalInstance,
            Notification,
            JOB,
            resource_type) {

            $scope.title = "Assign "+ resource_type +" for JOB "+ JOB;
            $scope.resourceType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};
            $scope.diary = {};
            $scope.assignedResource = false;
            $scope.resource_type = resource_type;

            $scope.resourceList = function(val) {
                return $http.get(apiUrl + '/api/listRepByType', {
                    params: {
                        search: val,
                        rep_typ: (resource_type == 'reporter') ? 'R' : 'T'
                    }
                }).then(function(response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };

            $scope.unSelectResource = function(){
                if($scope.assignedResource){
                    $scope.diary.resource = '';
                    $scope.assignedResource = false;
                }
            };

            $scope.updateResource= function($valid){

                if($valid && $scope.assignedResource){

                    swal({
                            title: "Are you sure ?",
                            text: "You are going to assign a new "+ $scope.resource_type,
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes, Do it",
                            cancelButtonText: "No!",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) {
                                $rootScope.showLoader(true);
                                var params = {
                                    jobNo:JOB,
                                    NAME_KEY: $scope.diary.resource.NAME_KEY,
                                    resourceId: $scope.diary.resource.id,
                                    resource_type : $scope.resource_type
                                };

                                commonFactory.post('/api/assignResource', params)
                                    .success(function (response) {

                                        if(!response.error){
                                            $uibModalInstance.dismiss({ isChanged : true, resource : $scope.diary.resource});
                                            Notification.success(response.message || $scope.resource_type +' has been assigned successfully.');
                                        } else{
                                            Notification.error(response.message || 'Something went wrong!');
                                        }

                                    })
                                    .error(function (err) {
                                        Notification.error(response.message || 'Something went wrong!');
                                        $rootScope.showLoader(false);
                                    });
                            }
                        });
                }
            };

            $scope.cancel = function(isChanged) {
                $uibModalInstance.dismiss({ isChanged : isChanged});
            };

        }]);
        
