angular.module('deitz').controller('additionalChargesController', [
    '$scope',
    '$rootScope',
    'commonFactory',
    'Notification',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$compile',
    '$http',
    'apiUrl',
    function (
        $scope,
        $rootScope,
        commonFactory,
        Notification,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $compile,
        $http,
        apiUrl
    ) {
        $scope.selectedAddChargeGroupIDs = [];
        $scope.dtInstance = {};
        $scope.mergeCharge = {};
        $scope.mergeChargesList = {};
        $scope.compileDatatable = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.additionalChargeData = [];
        $scope.loadingDemo = [];
        
        $scope.includeActive = localStorage.getItem('includeActive');
        $scope.includeDeactive = localStorage.getItem('includeDeactive');        
        
        $scope.reloadAdditionalChargeData = function () {
            //$scope.dtInstance.DataTable.draw();
            $scope.dtInstance.rerender();
        };

        $scope.reloadData = function () {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };

        commonFactory.get('/api/additionalChargesGroups').success(function (response) {
            if (response.result) {
                $scope.additionalChargesGroups = response.result.additionalChargesGroup;
            }
        }).error(function (err) {
        });

        $scope.changeChargeStatus = function (charge_id) {
                $scope.loadingDemo[charge_id].loading = true;
                var page = $scope.dtInstance.DataTable.page();
                var change_status = $scope.additionalChargeData[charge_id].status=='1'?'0':'1';
                var data = {status : change_status};
                commonFactory.put('/api/additional-charges/'+charge_id,data).success(function(){
                    $scope.loadingDemo[charge_id].loading = false;
                    // $scope.reloadAdditionalChargeData();
                    $scope.dtInstance.DataTable.page(page).draw(false);
                }).error(function(){

                });
        };

        $scope.validateMergeCharge = function(){
            if(typeof $scope.mergeCharge.keepCharge != "undefined" && typeof $scope.mergeCharge.deleteCharge != "undefined" && $scope.mergeCharge.keepCharge != null && $scope.mergeCharge.deleteCharge != null){
                var kf = $scope.mergeCharge.keepCharge;
                var df = $scope.mergeCharge.deleteCharge;
                
                if(kf.ChargeID == df.ChargeID){
                    Notification.error('You have selected the same charge for both choices. Please select different charges!');
                    return false;
                }
                return true;
            }
            return false;
        }

        $scope.onCheckCharge = function (id, chargeType) {
            if(chargeType =='keepCharge'){
                $scope.mergeCharge.keepChargeId = parseInt(id);
            }else{
                $scope.mergeCharge.deleteChargeId = parseInt(id);
            }
            $scope.onSelectCharge(chargeType);    
        };

        $scope.onSelectAdditionalCharge = function (id, chargeType) {
            let $item = $scope.mergeChargesList.filter(function (item){
                return item.ChargeID == id ;                  
            });

            if(chargeType == 'keepCharge'){
                $scope.mergeCharge.keepCharge = angular.copy($item[0]);
            }else{
                $scope.mergeCharge.deleteCharge = angular.copy($item[0]);    
            }
            $scope.validateMergeCharge();
        };

        $scope.onSelectCharge = function(chargeType){
            if(chargeType == 'keepCharge'){
                $scope.onSelectAdditionalCharge($scope.mergeCharge.keepChargeId, chargeType);
            }else{
                $scope.onSelectAdditionalCharge($scope.mergeCharge.deleteChargeId, chargeType);
            }
        };

        //Getting all dupes additional CHARGES
        $http.post(apiUrl + '/api/additional/all-charges').then(function(response) { 
            $scope.mergeChargesList = response.data.data;       
        });

        $scope.mergeChargeSubmit = function(isValid){ 
            $scope.submitted = true;
            if(isValid && $scope.validateMergeCharge()){
                swal({
                        title: "<h3>Merge these charges - are you SURE? Once this has begun you can not stop it..</h3>",
                        type: "warning",
                        html: true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No, Cancel this merge",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) { 
                            $scope.deleteCharge =  $scope.mergeCharge.deleteCharge || null;
                            $scope.keepCharge = $scope.mergeCharge.keepCharge || null;
                            $rootScope.showLoader(true);  

                            commonFactory.post('/api/additional/merge-charges', {deletecharge:$scope.deleteCharge,keepcharge:$scope.keepCharge}).success(function (response) { 
                                $rootScope.showLoader(false);
                                $scope.resetForm();  
                                if(response.status == 200){
                                    Notification.success(response.result.message); 
                                }else{
                                    Notification.error(response.result.message);
                                }
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    });
            }
        }

        $scope.resetForm = function(){
            $scope.mergeCharge.keepChargeId = null;  
            $scope.mergeCharge.keepCharge = null;
            $scope.mergeCharge.deleteChargeId = null; 
            $scope.mergeCharge.deleteCharge = null; 
            $('input[name=destinationId]').attr('checked',false);
            $('input[name=deleteId]').attr('checked',false);
        } 

        $scope.renderStatusAction = function (data, type, full, meta) {
            $scope.additionalChargeData[full.ChargeID] = full;
            $scope.loadingDemo[full.ChargeID]={};
            $scope.loadingDemo[full.ChargeID].loading = false;
            var status_tooltip = (full.status == '1') ? "Active" : "Deactive";

            //return "<button ladda=\"loadingDemo['"+full.id+"'].loading\" class='ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm' ng-class=\"{'btn-success': '" + full.status + "' == '1', 'btn-danger': '" + full.status + "' == '0'}\" ng-click='changeChargeStatus(\"" + full.id + "\")'>" + status_tooltip + "</button>";
            return "<button ng-disabled='!havePermission(\"additional_charges\",\"update\")' ladda=\"loadingDemo['"+full.ChargeID+"'].loading\" class='ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm' ng-class=\"{'btn-success': '" + full.status + "' == '1', 'btn-danger': '" + full.status + "' == '0'}\" ng-click='changeChargeStatus(\"" + full.ChargeID + "\")'>" + status_tooltip + "</button>";
        };

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function (sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    includeActive: $scope.includeActive ? $scope.includeActive : false,
                    includeDeactive: $scope.includeDeactive ? $scope.includeDeactive : false,
                    haveMergePermission: $rootScope.havePermission('additional_charges','merge'),
                    filter_selectedAddChargeGroupIDs : JSON.stringify($scope.selectedAddChargeGroupIDs.filter(Boolean)),
                };
                localStorage.setItem('includeActive', $scope.includeActive);
                localStorage.setItem('includeDeactive', $scope.includeDeactive);
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/additional-charges', options)
                    .success(function (response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [100, 250, 500, -1],
                [100, 250, 500, 'All']
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function (settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function () {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            //.withOption('order', [1, 'asc'])
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'CodetypeDetails' },
                { extend: 'pdf', title: 'CodetypeDetails' }, {
                    extend: 'print',
                    customize: function (win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);



        $scope.dtColumns = [
            DTColumnBuilder.newColumn('group_name').withOption('defaultContent', '').withTitle('Group Charge').withOption('data', 'name').withOption('sortable', true),
            DTColumnBuilder.newColumn('additional_charge_group').withOption('defaultContent', '').withTitle('Additional Charge Group').renderWith(function (data, type, full, meta) {
                let display = data.map((content)=>content.name);
                return display.join(', ');
            }).withOption('sortable', false),
            DTColumnBuilder.newColumn('CHG_CODE').withOption('defaultContent', '').withTitle('Code Type').renderWith(function (data, type, full, meta) {
                let display = data;
                if (full.do_not_merge) {
                    display += '<br/><span class="text-danger">(DO NOT MERGE)</span>';
                }
                return display;
            }),
            DTColumnBuilder.newColumn('DESC').withOption('defaultContent', '').withTitle('Description'),
            DTColumnBuilder.newColumn('AMT').withOption('defaultContent', '').withTitle('Amount'),
            DTColumnBuilder.newColumn('SPLIT').withOption('defaultContent', '').withTitle('Split'),
            DTColumnBuilder.newColumn('commissionable').withOption('defaultContent', '').withTitle('Commissionable').renderWith(function (data, type, full, meta) {
                if (data == 'Y') {
                    return '<a href="javascript:void(0);" class="btn btn-success btn-xs" ng-click="changeCommissionable('+ full.ChargeID +',\'Yes\','+ full.status +')"> Yes </a>';
                } else if(data == 'N'){
                    return '<a href="javascript:void(0);" class="btn btn-danger btn-xs" ng-click="changeCommissionable('+ full.ChargeID +',\'No\','+ full.status +')"> No </a>';
                } else{
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().renderWith(function (data, type, full, meta) {
                if (data && data.ChargeID) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="rate_tables.additional_charges_edit({id : ' + data.ChargeID + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;';
                }

            }),
            DTColumnBuilder.newColumn('status').withTitle('Status').renderWith($scope.renderStatusAction)
        ];
        
        if($rootScope.havePermission('additional_charges','merge')){
            $scope.dtColumns.unshift(
                DTColumnBuilder.newColumn('ChargeID').withOption('defaultContent', '').withTitle('Merge').notSortable().withOption("width", "20%").renderWith(function(data,type,full,meta){
                    let html = '<input ng-click="onCheckCharge(\''+full.ChargeID+'\',\'keepCharge\')" type="radio" id="dest-'+full.ChargeID+'" name="destinationId"><label for="dest-'+full.ChargeID+'" class="text-success" style="margin-left:5px;">Destination</label><br/>';

                    if (!full.do_not_merge) {
                        html += '<input id="del-'+full.ChargeID+'" ng-click="onCheckCharge(\''+full.ChargeID+'\',\'deleteCharge\')" type="radio" name="deleteId"><label for="del-'+full.ChargeID+'" class="text-danger" style="margin-left:5px;">Merge</label>';
                    }else{
                        html += '<span class="text-danger">(DO NOT MERGE)</span>';
                    }

                    return html;
                }
            ));
        }

        $scope.changeCommissionable = function(ChargeID, commissionable, status){
            var commissionable = commissionable == "Yes" ? "No" : "Yes";
            swal({
                title: "Are You Sure?",
                text: "You Want To Change It To <b>"+ commissionable + "</b>?",
                html:true,
                type: "success",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true);
                    commonFactory.put('/api/additional-charges/'+ChargeID,{commissionable : commissionable, status: status}).success(function(res){
                        $rootScope.showLoader(false);
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            $scope.reloadAdditionalChargeData();
                        }
                    });
                }
            }); 
        }

    }]);
