angular.module('deitz').controller('findCashReceiptController',[
    '$scope',
    '$rootScope',
    '$http',
    'apiUrl',
    '$timeout',
    '$state',
    'cashReceiptFactory',
    'commonFactory',
    'Notification',
    function (
        $scope,
        $rootScope,
        $http,
        apiUrl,
        $timeout,
        $state,
        cashReceiptFactory,
        commonFactory,
        Notification
    ){

        $scope.validations = cashReceiptFactory.validations;
        $scope.messages = cashReceiptFactory.messages;
        $scope.formData = {
            selectedValue : "INV_NO"
        };

        $scope.getcasebycaption = function(val) {
            return $http.get(apiUrl + '/api/getcasebycaption', {
                params: {
                    caption: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.onSelectOption = function(){
            $scope.formData.searchValue = '';
            if($scope.formData.selectedValue == 'CHECK_NUMBER'){
                $scope.formData.includePaidInvoices = true;
            }
        }

        //Getting witness name in witness_tbl.
        $scope.getcasebywitnessname = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/getcasebywitnessname', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.findInvoice = function(isValid){
            $scope.formSubmit=true;
            if(isValid){
                $rootScope.showLoader(true);
                var data = $scope.formData;
                if($scope.formData.selectedValue === "PAID_DT"){
                    data.searchValue = moment($scope.formData.searchValue).format('MM/DD/YYYY')
                }

                cashReceiptFactory.findData = data;
                $state.go('cash_receipt.browse');
            }
        }

    }]);
