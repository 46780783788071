angular.module('deitz').controller('edittranslatorController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification',

    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification
    ) {

        $scope.translator = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Interpreter';

        //Edit Mode of translator.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/translator/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.translator) {
                        $scope.translator.company_name = response.result.translator.company_name || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        };

        //Update Mode of translator
        $scope.addTranslator = function(translator, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/translator/' + id, $scope.translator)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Interpreter updated successfully");
                                $state.go("code_tables.translator_browse");
                            }else{
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
