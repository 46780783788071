angular.module('deitz').controller('browseAttorneyController', [
    '$scope',
    '$rootScope',
    '$stateParams',
    'commonFactory',
    '$http',
    'apiUrl',
    '$window',
    '$state',
    'Notification',
    function(
        $scope,
        $rootScope,
        $stateParams,
        commonFactory,
        $http,
        apiUrl,
        $window,
        $state,
        Notification
    ) {

        $scope.pagination = {
            totalItems: 0,
            currentPage: 1,
            limit: 12,
            skip:0,
            order: '',
            search: '',
            orderColumn:'',
            orderDir: ''
        };

       

        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
               $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
        if(localStorage.getItem('at_search')){
            $scope.pagination.search = localStorage.getItem('at_search');
        }
        
        $scope.fetchAttorneys = function(){
            $rootScope.showLoader(true);
            localStorage.setItem('at_search',$scope.pagination.search);
           /* if($scope.pagination.search){
                $scope.search = $scope.pagination.search;
            } 
            if($scope.search){
                $scope.pagination.search = $scope.search;
            }          
            console.log($scope.pagination.search);*/


            /*localStorage.setItem('attronery_search',$scope.pagination.search);         
            if(localStorage.getItem('attronery_search')){
                $scope.a_search= localStorage.getItem('attronery_search');
                localStorage.setItem('attronery_search','');
            }
            if($scope.a_search){
                $scope.pagination.search = $scope.a_search;
            }*/
            $scope.pagination.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;

            delete $scope.pagination.attorney_id;
            if($scope.attorney_id){
                $scope.pagination.attorney_id= $scope.attorney_id ? $scope.attorney_id.id : '';
            }
            $scope.pagination.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.pagination.selectedBusinessUnitIDs.filter(Boolean)));   

            $scope.attorneys = [];
            commonFactory.get('/api/getattorneylist?'+  $.param($scope.pagination || {}))
                .success(function(response) {
                    if (response) {
                        $rootScope.showLoader(false);
                        $scope.attorneys = response.result;
                        if(!$scope.attorneys.length){
                            Notification.error("No Attorney found!");
                        }

                        $scope.pagination.totalItems = response.count;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        };      
        if($stateParams.firmId){
            commonFactory.get('/api/attorneys/' + $stateParams.firmId + '/edit').success(function(response){
                $scope.attorney_id = response.result.data;               
                $scope.onSelect();
            });
        }
        else{           
            $scope.fetchAttorneys();
        }

        $scope.attorneyFirmSelected = false;
        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function (val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function (response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.diselectAttorneyFirm = function(){
            $scope.attorneyFirmSelected = false;
            $scope.attorney_id = '';
            $scope.fetchAttorneys();
        };

        //After selecting Firm assign value to this field.
        $scope.onSelect = function ($item, $model, $label, tab) {
            $scope.attorneyFirmSelected = true;           
            //$scope.attorney_id = $item;
            $scope.fetchAttorneys();
        };
        $scope.getsearchdata = function (id,firm_id){            
            localStorage.setItem('at_search',$scope.pagination.search);            
            $state.go('attorney.editattorneytofirm' ,{id: id,firm_id:firm_id});
        };
        
        


        $scope.welcomeAtt = function(email){
            var enscript  = encodeURIComponent("send_active.php?from=acceptance&email="+email);
            var auth_user = $rootScope.auth_user;
            var givenUrl  = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){});
        };

        $scope.launchAtt = function(uid, email){
            $http.post(apiUrl + '/api/auth-user-get-token', {uid : uid}).then(function(response) { 
                if(response.data.status){
                    var givenUrl = $rootScope.depoHost + "/launch_user.dtop?uid="+ uid + "&actionfrom=backend&token="+ btoa(email)+"&access_token="+response.data.token;
                    console.log(givenUrl)
                    // $rootScope.launchIFrame(givenUrl, function(){});
                    $window.open(givenUrl, '_blank');
                }else{
                    Notification.error('Something went wrong!'); 
                }
            });
        };

        $scope.addAtt = function(attorney){            
            if(!attorney.NAME){
                attorney.NAME = '';
            }
            if(!attorney.ATTORNEY){
                attorney.ATTORNEY = '';
            }
            var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&name="+ attorney.NAME + "&office_code="+ attorney.ATTORNEY);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){
                $scope.fetchAttorneys();
            });
        };

        $scope.editAtt = function(uid){
            var enscript = encodeURIComponent("attorney_edit.dtop?from=acceptance&key="+ uid);

            var auth_user = $rootScope.auth_user;
            var givenUrl = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;

            $rootScope.launchIFrame(givenUrl, function(){
                $scope.fetchAttorneys();
            });
        };

        


        $scope.deleteAtt = function(uid){
            swal({
                title: "Are you sure ?",
                text: "You are going to delete the Attorney.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    var enscript  = encodeURIComponent("attorney_edit.dtop?from=acceptance&delete=1&key="+ uid);
                    var auth_user = $rootScope.auth_user;
                    var givenUrl  = $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script="+enscript;
                    $rootScope.launchIFrame(givenUrl, function () {
                        $scope.ebtlist();
                    });
                }
            });
        };

        $scope.deleteAtt_user = function(id,atid){
            swal({
                title: "Are you sure ?",
                text: "You are going to delete the Attorney.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {                 
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/deleteattname', {id:id})
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if(response && response.error == true){
                            Notification.error('Something went wrong while delete attorney!');
                        } else{
                            
                            Notification.success('Attorney has been deleted.');
                            if(atid){
                            commonFactory.get('/api/attorneys/' + atid + '/edit').success(function(response){
                                $scope.attorney_id = response.result.data;               
                                $scope.onSelect();
                            });
                            }else{
                                $scope.fetchAttorneys();
                            }
                            
                            // $scope.cashReceiptData.attorney_firms.credit_amount = response.result.newCurrentBalance;
                            //$scope.onSelectFirm($scope.cashReceiptData.attorney_firms);
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });

                }
            });
        };

}]);
