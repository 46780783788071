angular.module('deitz')
    .controller('exceptionReportController', [
        '$scope',
        '$rootScope',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$window',
        'Notification',
        '$http',
        '$filter',
        'invoiceFactory',
        function (
            $scope,
            $rootScope,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $window,
            Notification,
            $http,
            $filter,
            invoiceFactory
        ) {

            $scope.dtInstance = {};
            $scope.dateRange
            $scope.title = $state.current.data.pageTitle;
            $scope.downloadExcel = false;
            $scope.inProcess = true;

            $scope.resetSearch = function () {
                $scope.dateRange = null;
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.report = {
                dateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                },
                job_pay: true,
            }


            $http.get(apiUrl + '/api/getBusinessUnit').then(function (response) {
                $scope.BusinessUnitsList = response.data.result.data;
                $scope.BusinessUnitsList.unshift({ name: 'All' });
            });

            $scope.saveBuPercentage =function(){
                $rootScope.showLoader(true);
                var data= {
                    business_units:$scope.BusinessUnitsList
                }
                commonFactory.post('/api/save-bu-percentages', data).success(function (res) {
                    $scope.BusinessUnitsList = res.result;
                    $scope.BusinessUnitsList.unshift({ name: 'All' });
                    $rootScope.showLoader(false);
                    $scope.reloadData();
                }).error(function (err) {
                    $rootScope.showLoader(false);
                });
            }

            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            } else {
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }


            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);

            };

            $scope.downloadExcelFile = function () {
                $scope.downloadExcel = true;
                $scope.reloadData();
            }
            

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    if ($scope.downloadExcel) {
                        $rootScope.showLoader(true);
                    }
                    $scope.inProcess = true;
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); $scope.inProcess = true;
                    data.downloadExcel = $scope.downloadExcel;
                    data.report_type= ($scope.report.job_pay) ? 'job_pay' : 'resource_pay';
                    data.startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
                    commonFactory.showDataTable('/api/get-exception-report', data).success(function (res) {
                        $scope.inProcess = false;
                        
                        if (!res.error) {
                            $rootScope.showLoader(false);
                            callback(res);
                        }
                        if ($scope.downloadExcel) {
                            $window.open(apiUrl + '/api/download-exception-report', '_blank');
                        }
                        $scope.downloadExcel = false;
                    }).error(function (err) {
                        $scope.inProcess = false;
                        Notification.error(err.error)
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO JOB AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ JOBS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 JOBS",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL JOBS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ JOBS",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING JOB FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searching', false)
                .withOption('searchDelay', 500)
                .withOption('order', [0, 'asc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    if($scope.report.job_pay){
                        $(header).find('th').eq(8).html('GM %');
                    }
                    else{
                        $(header).find('th').eq(8).html('COGS %');
                    }
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                    
                });

            $scope.dtColumns = [];

            $scope.findInvoice = function(jobNo){

                $rootScope.showLoader(true);
                var data = {
                    selectedValue:'INVOICE_tbl.JOB_NO',
                    searchValue:jobNo
                };
                invoiceFactory.post('/api/find-invoice', data)
                    .success(function(response) {
                        if (response && response.result && response.result.invoiceCount) {
                            $scope.invoice = response.result.invoice;
                            var url = $state.href('invoice.edit',{ invoice_id : response.result.invoice.invoiceID});
                            $window.open(url,'_blank');
                            $rootScope.showLoader(false);

                        } else {
                            $rootScope.showLoader(false);
                            if (response && response.result && response.result.message) {
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
        }
            
            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job #').withOption('searchable', true).withOption('name', 'JOB_tbl.JOB_NO').renderWith(function(data){
                    return '<a href="javascript:void(0)" ng-click="findInvoice('+data+')" >'+data+'</a>';
                }),
                DTColumnBuilder.newColumn('SHORT_NAME').withTitle('Firm').withOption('searchable', true),
                DTColumnBuilder.newColumn('CAPTION').withTitle('Case Name').withOption('searchable', true),
                DTColumnBuilder.newColumn('job_cost').withTitle('Job Cost Amt').withOption('searchable', true).renderWith(function (data, type, full, meta) {
                    if (data) {

                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('job_inv_amt').withTitle('Job Inv Amt').withOption('searchable', true).renderWith(function (data, type, full, meta) {
                    if (data) {

                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('job_inv_paid_amt').withTitle('Job Inv Paid Amt').withOption('searchable', true).renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('job_inv_due').withTitle('Job Inv Due Amt').withOption('searchable', false).notSortable().renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }
                }),
                DTColumnBuilder.newColumn('dte_taken').withTitle('Job Date').withOption('searchable', true).renderWith(function (data, type, full, meta) {
                    if(data) {
                        var dte = moment(data).format('MM/DD/YYYY');
                        if(dte == 'Invalid date'){
                            return '-';
                        }else{
                            return dte;
                        }
                    } 
                    else {
                        return '-';
                    }

                }),
                
                DTColumnBuilder.newColumn('grossMargin').withTitle('GM %').withClass('gm').withOption('searchable', false).renderWith(function (data, type, full, meta) {
                    return data+' %';
                }),
                DTColumnBuilder.newColumn('business_unit').withTitle('Business Unit').withOption('searchable', true)
            );
            
        }
    ]);
