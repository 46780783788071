angular.module('deitz')
    .controller('dashboardController', 
        ['$scope',
         '$rootScope',
         '$http',
         'apiUrl',
         'configFactory',
         '$filter',
         'Notification',
         '$cookies',
         '$state',
         '$uibModal',
                function($scope,
                         $rootScope,
                         $http,
                         apiUrl,
                         configFactory,
                         $filter,
                         Notification,
                         $cookies,
                         $state,
                         $uibModal
                        ){
        
        $scope.formSubmit = false;
        $scope.viewDashboard = false;
        $scope.dashboard = {};
        $scope.currentDate = moment().format('YYYY-MM-DD');
        $rootScope.isSalesLogin = localStorage.getItem('isSalesLogin')

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));

        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs;
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        
        $scope.filterBUIds = $scope.selectedBusinessUnitIDs.length > 0 ? $scope.selectedBusinessUnitIDs.join(',') : '';
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.applyFilter = function(){
            $scope.filterBUIds = $scope.selectedBusinessUnitIDs.filter(Boolean);
            $scope.filterBUIds = $scope.filterBUIds.length > 0 ? $scope.filterBUIds.join(',') : '';
            $scope.clearCache();
        }

        $scope.getOperationMetrics = function(clear_cache=false){
            $scope.searchingMetricsAverage = true;
            $scope.searchingMetricsMonthlyAverage = true;
            //get operation metrics average calculation data
            $http.get(apiUrl+'/api/get-operation-metrics-average',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.operationMetricsData = res.result; 
                $scope.searchingMetricsAverage = false;
            });
            
            //get operation metrics monthly average
            $http.get(apiUrl+'/api/get-operation-metrics-monthly-average',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.operationMetricsMonthlyData = res.result;
                $scope.searchingMetricsMonthlyAverage = false;
            });
        }
        
        //get top firm list
        $scope.getTopFirms = function(clear_cache=false){
            $scope.searchingTopTenFirm = true; 
            $scope.firm_pyr_config_amt = [];
            $http.get(apiUrl+'/api/get-top-firms-list',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    isSalesLogin:$rootScope.isSalesLogin
                }
            }).success(function(res){ 
                $scope.topFirms = res.result.topFirms;
                $scope.firm_pyr_config_amt[0] = res.result.percentage +'%';
                $scope.listTopFirms();
                $scope.searchingTopTenFirm = false;
            });
        }

        //get revenue year over year v/s budget chart data
        $scope.getRevenueData = function(clear_cache=false){
            $scope.searchingRevenueData = true;
            $http.get(apiUrl+'/api/get-revenue-data',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.revenueData = res.result; 
                $scope.listRevenueChart();
                $scope.searchingRevenueData = false;
            });

            $http.get(apiUrl+'/api/get-revenue-data-current-month',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.revenueDataMonth = res.result; 
                $scope.listRevenueChartMonth();
                // $scope.searchingRevenueData = false;
            });
        }

        //get account receivable - invoice - cash summary data
        $scope.getAccountReceivableSummary = function(clear_cache=false){
            $scope.searchingSummaryData = true;
            $http.get(apiUrl+'/api/get-account-receivable-invoice-cash-summary',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.accountReceivableSummary = res.result;
                $scope.searchingSummaryData = false;
            });  
        }

        //get invoices data 
        $scope.getInvoices = function(clear_cache=false){
            $scope.searchingInvoicesData = true;
            $http.get(apiUrl+'/api/get-invoices',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    isSalesLogin:$rootScope.isSalesLogin
                }
            }).success(function(res){
                $scope.invoicesOfDays = res.result.invoices.invoicesOfDays;
                $scope.invoicesOfMonths = res.result.invoices.invoicesOfMonths;
                $scope.invoicesOfYears = res.result.invoices.invoicesOfYears;

                // moment(StartDate).format('MM/DD/YYYY');
                $scope.invoicesReportUrl = "&MANUAL_daterange_TODATE="+ moment($scope.invoicesOfDays[0].dte_invoice).format('YYYY-MM-DD')+"&MANUAL_daterange_FROMDATE="+ moment($scope.invoicesOfDays[ $scope.invoicesOfDays.length-1].dte_invoice).format('YYYY-MM-DD');
                $scope.searchingInvoicesData = false;  
            });
        }

        //get invoices data 
        $scope.getInvoicesBiller = function(clear_cache=false){
            $scope.searchingInvoicesBillerData = true;
            $http.get(apiUrl+'/api/get-invoices-biller',
            {
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    isSalesLogin:$rootScope.isSalesLogin
                }
            }).success(function(res){
                $scope.invoicesBillerOfDays = res.result.invoices.invoicesOfDays;
                $scope.invoicesBillerOfMonths = res.result.invoices.invoicesOfMonths;
                // moment(StartDate).format('MM/DD/YYYY');
                // $scope.invoicesReportUrl = "&MANUAL_daterange_TODATE="+ moment($scope.invoicesOfDays[0].dte_invoice).format('MM/DD/YYYY')+"&MANUAL_daterange_FROMDATE="+ moment($scope.invoicesOfDays[ $scope.invoicesOfDays.length-1].dte_invoice).format('MM/DD/YYYY');
                 $scope.searchingInvoicesBillerData = false;  
            });
        }

        //get order source count
        $scope.getOrderSource = function(clear_cache=false){
            $scope.searchingOrderSourceData = true;
            $http.get(apiUrl+'/api/get-order-source-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.sources = res.result.sources;
                $scope.renameSources = res.result.renameSources;
                $scope.scheduleDataCurrentMonth = res.result.scheduleDataCurrentMonth;
                $scope.scheduleDataLastWeek = res.result.scheduleDataLastWeek;
                $scope.scheduleDataToday = res.result.scheduleDataToday;
                $scope.searchingOrderSourceData = false;  
            });
        }

          //get invoices biller count data 
        $scope.getInvoicesBillerCount = function(clear_cache=false){
            $scope.searchingInvoicesBillerCount = true;
            $http.get(apiUrl+'/api/get-invoices-biller-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
               
                $scope.invoicesBillerCountsOfToday = res.result.invoicesBillerCountsOfToday;
                $scope.invoicesBillerCountsOfLast7Days = res.result.invoicesBillerCountsOfLast7Days;
                $scope.invoicesBillerCountsOfLast30Days = res.result.invoicesBillerCountsOfLast30Days;
                $scope.allBiller= res.result.allBiller;
                $scope.invoicesBillerCountsOfLast12Months = res.result.invoicesBillerCountsOfLast12Months;
                $scope.searchingInvoicesBillerCount = false;
                // moment(StartDate).format('MM/DD/YYYY');
                // $scope.invoicesReportUrl = "&MANUAL_daterange_TODATE="+ moment($scope.invoicesOfDays[0].dte_invoice).format('MM/DD/YYYY')+"&MANUAL_daterange_FROMDATE="+ moment($scope.invoicesOfDays[ $scope.invoicesOfDays.length-1].dte_invoice).format('MM/DD/YYYY');
                // $scope.searchingInvoicesData = false;  
            });
        }
        $scope.billerPopup ;
        $scope.changeBillerPopup = function (id){
            $scope.billerPopup =$scope.invoicesBillerCountsOfLast12Months[id];
        }

        $scope.productionPersonPopup ;
        $scope.changeProductionPopup = function(id){
            $scope.productionPersonPopup=$scope.batchDataLast12Month[id];
        }

        //get invoices collector count data 
        $scope.getInvoicesCollector = function(clear_cache=false){
          $scope.searchingInvoicesCollectorCount = true;
          $http.get(apiUrl+'/api/get-invoices-collector-count',{
            params: {
                clear_cache: clear_cache,
                selectedBusinessUnitIDs : $scope.filterBUIds
            }
        }).success(function(res){
             
            $scope.invoicesCollectorCountsOfToday = res.result.invoicesCollectorCountsOfToday;
            $scope.invoicesCollectorCountsOfLast7Days = res.result.invoicesCollectorCountsOfLast7Days;
            $scope.invoicesCollectorCountsOfLast30Days = res.result.invoicesCollectorCountsOfLast30Days;
            $scope.arCollectorData = res.result.arData;
            $scope.allCollector= res.result.allCollector;
            $scope.invoicesCollectorCountsOfLast12Months = res.result.invoicesCollectorCountsOfLast12Months;
            $scope.searchingInvoicesCollectorCount = false;
          });
        }

        $scope.findInvoiceCount = function (collector) {
            if($scope.invoicesCollectorCountsOfLast12Months[collector])
            {
                return $scope.invoicesCollectorCountsOfLast12Months[collector].reduce((sum, e) => {
                    sum += e.count
                    return sum;
                }, 0);
            }
        }

        $scope.findfirmsCount = function (collector) {
            if($scope.invoicesCollectorCountsOfLast12Months[collector])
            {
                return $scope.invoicesCollectorCountsOfLast12Months[collector].reduce((sum, e) => {
                    sum += e.firm_assigned
                    return sum;
                }, 0);
            }
        }

        $scope.arRevenue = function (collector) {
            if($scope.invoicesCollectorCountsOfLast12Months[collector])
            {
                return $scope.invoicesCollectorCountsOfLast12Months[collector].reduce((sum, e) => {
                    sum += parseInt(e.INV_AMT, 10)
                    return sum;
                }, 0);
            }
        }

        $scope.collectorPopup;
        $scope.changeCollectorPopup = function (id){
            $scope.collectorPopup =$scope.invoicesCollectorCountsOfLast12Months[id];
        }
        
		$scope.hideSalesCommission=true;
		
        $scope.authShowSalesCommission = function(){
            if($rootScope.havePermission('allow_access_without_password','allow.sales.rep.metrics')){
                $scope.hideSalesCommission=false;
            }else{
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK SALES METRIC",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) {
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        $http.post(apiUrl+'/api/config/get-sales-auth-info', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {
                                        $rootScope.showLoader(false);
                                        $scope.hideSalesCommission=false;
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });
    
                    }
                });
            }
		}

        //get invoices Commission data 
        $scope.getInvoicesSalesCommission = function(clear_cache=false){
          	$scope.searchingInvoicesCommissionCount = true;
          	$http.get(apiUrl+'/api/get-invoices-salesperson',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    isSalesLogin:$rootScope.isSalesLogin
                }
            }).success(function(res){
				$scope.salesPersonCommissionOfCurrentMonth = res.result.salesPersonCommissionOfCurrentMonth;
				$scope.salesPersonCommissionOfLast12Months = res.result.salesPersonCommissionOfLast12Months;
				//$scope.salesPersonCommissionOfLast7Days = res.result.salesPersonCommissionOfLast7Days;
				$scope.allSalesPersons= res.result.allSalesPersons;
				$scope.searchingInvoicesCommissionCount = false;
          	});
        }
        $scope.salesCommissionPopup;
        $scope.changesalesCommissionPopup = function (id){
            $scope.salesCommissionPopup =$scope.salesPersonCommissionOfLast12Months[id];
        }

        // get connection methods
        $scope.getConnectionMethods = function (clear_cache=false) {
            $scope.searchingConnectionMethodsData = true;
            $http.get(apiUrl+'/api/get-connection-methods-job-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.connectionMethods = res.result.connectionMethods;
                $scope.givenMonthsDate = res.result.givenMonthsDate;
                $scope.connectionMethodsJobCounts = res.result.connectionMethodsJobCounts;
                $scope.searchingConnectionMethodsData = false;
            });
        }

        // get connection methods places
        $scope.getConnectionMethodsPlaces = function (clear_cache=false) {
            $scope.searchingConnectionMethodsPlacesData = true;
            $http.get(apiUrl+'/api/get-connection-methods-job-places',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.jobHeldAtCurrentMonth = res.result.currentMonth;
                $scope.connectionMethodsPlaces = res.result.connectionMethods;
                $scope.jobHeldAtLocationMonthly = res.result.jobHeldAtLocationMonthly;
                $scope.jobHeldAtLocationTotal = res.result.jobHeldAtLocationTotal;
                $scope.searchingConnectionMethodsPlacesData = false;
            });
        }

        $scope.connectionMethodsPlacesPopup ;
        $scope.changeConnectionMethodPlaces = function(place){
            $scope.connectionMethodsPlacesPopup = $scope.jobHeldAtLocationMonthly[place];
        }

        //get invoices Cost 
        $scope.getInvoicesCost = function(clear_cache=false){
            $scope.searchingInvoicesDataCost = true;
            $http.get(apiUrl+'/api/get-invoices-cost',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.invoicesCostOfDays = res.result.invoices.invoicesOfDays;
                $scope.invoicesCostOfMonths = res.result.invoices.invoicesOfMonths;
                $scope.invoicesCostOfLastWeek = res.result.invoices.invoicesOfLastWeek;

                // moment(StartDate).format('MM/DD/YYYY');
                // $scope.invoicesReportUrl = "&MANUAL_daterange_TODATE="+ moment($scope.invoicesOfDays[0].dte_invoice).format('MM/DD/YYYY')+"&MANUAL_daterange_FROMDATE="+ moment($scope.invoicesOfDays[ $scope.invoicesOfDays.length-1].dte_invoice).format('MM/DD/YYYY');
                 $scope.searchingInvoicesDataCost = false;  
            });
}

        //get cash receipts data
        $scope.getCashReceipts = function(clear_cache=false){
            $scope.searchingCashReceiptsData = true;
            $http.get(apiUrl+'/api/get-cash-receipts',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.cashReceiptsOfDays = res.result.cashReceipts.cashReceiptsOfDays;
                $scope.cashReceiptsOf12Month = res.result.cashReceipts.cashReceiptsOf12Month;
                 // moment(StartDate).format('MM/DD/YYYY');
                $scope.cashReceiptReportUrl = "&MANUAL_daterange_TODATE="+ moment($scope.cashReceiptsOfDays[0].PAID_DT).format('MM/DD/YYYY')+"&MANUAL_daterange_FROMDATE="+ moment($scope.cashReceiptsOfDays[ $scope.cashReceiptsOfDays.length-1].PAID_DT).format('MM/DD/YYYY');
                $scope.searchingCashReceiptsData = false;
            });
        }

        //get transcript data
        $scope.getTranscripts = function(clear_cache=false){
            $scope.searchingTranscriptsData = true;
            $http.get(apiUrl+'/api/get-transcripts',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.transcript = res.result.transcript;
                $scope.searchingTranscriptsData = false;
            });
        }

         

           //get transcript job details data
          
        $scope.getTranscriptsJobDetails = function (date,is_monthly=false) {

            console.log('working',date)
            $rootScope.showLoader(true);
            var viewJobModel= $uibModal.open({
                templateUrl: "modules/dashboard/transcripts-jobs.html",
                controller: function($scope, date,$uibModalInstance){
                    $scope.job_transcript = []
                    $scope.transcript_date = date
                  

                    $scope.viewJob = function() {
                        $rootScope.showLoader(true);

                        $http.get(apiUrl+'/api/get-transcripts-job-details',{
                            params: {
                                date: date,
                                selectedBusinessUnitIDs : $scope.filterBUIds,
                                is_monthly:is_monthly
                            }
                        }).success(function(res){
                            $rootScope.showLoader(false);
                            $scope.job_transcript = res.result;
                           
                        });
                    }
                    $scope.closePopupWindow = function () {               
                        $uibModalInstance.dismiss({ isChanged : true});
                    }

                    // Added to sort the record by any field from the table.
                  
                    $scope.viewJob();

                 

                },
                size:'lg',
                backdrop:false,
                resolve : {
                    date : function(){
                        return date;
                    }
                }
            });
            viewJobModel.result.then(function () {
            }, function () {
                if(!is_monthly){
                    setTimeout(function () {
                        document.getElementById('TranscriptsData').click()
                    },500);
                }else{
                    setTimeout(function () {
                        document.getElementById('TranscriptsDataMonthly').click()
                    },500);
                }

            });
             
        } 

        //get logins data
        $scope.getLogins = function(clear_cache=false){
            $scope.searchingLoginsData = true;
            $http.get(apiUrl+'/api/get-logins',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.todayDepoLogins = res.result.logins.todayDepoLogins;
                $scope.todayBackOfficeLogins = res.result.logins.todayBackOfficeLogins;
                $scope.last12MonthsLogins = res.result.logins.last12MonthsLogins;
                $scope.listTodayLogins();
                $scope.searchingLoginsData = false;
            });
        }

        //get fax and email sent data 
        $scope.getFaxEmailData = function(clear_cache=false){
            $scope.searchingFaxAndEmailData = true;
            $http.get(apiUrl+'/api/get-fax-and-email-data',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.faxAndEmailOfDays = res.result.faxAndEmail.faxAndEmailOfDays;
                $scope.faxAndEmailOf12Months = res.result.faxAndEmail.faxAndEmailOf12Months; 
                $scope.searchingFaxAndEmailData = false;
            });
        }

        //get pending jobs count
        $scope.getPendingJobsCount = function(clear_cache=false){   
            $scope.searchingPendingJobsCount = true;
            $http.get(apiUrl+'/api/get-pending-jobs-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.pendingJobsCountOfDays = res.result.pendingJobsCount.pendingJobsCountOfDays;
                $scope.pendingJobsCountOf12Months = res.result.pendingJobsCount.pendingJobsCountOf12Months; 
                $scope.searchingPendingJobsCount = false;
                 
            });
        }

        //get jobs count
        $scope.getJobsCount = function(clear_cache=false){
            $scope.searchingJobsCount = true;
            $http.get(apiUrl+'/api/get-jobs-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    isSalesLogin:$rootScope.isSalesLogin
                }
            }).success(function(res){
                $scope.jobsCountOfDays = res.result.jobsCount.jobsCountOfDays;
                $scope.jobsCountOf60Weeks = res.result.jobsCount.jobsCountOf60Weeks;                
                $scope.jobsCountOf12Months = res.result.jobsCount.jobsCountOf12Months; 
                $scope.searchingJobsCount = false;
            });
        }

        $scope.listTodayLogins = function(){

            $scope.successLoginsOfDepo = 0;
            $scope.unSuccessLoginsOfDepo = 0;

            $scope.successLoginsOfDeitz = 0;
            $scope.unSuccessLoginsOfDeitz = 0;

            angular.forEach($scope.todayDepoLogins,function(value, key){
                if(value.status =='Y'){

                    $scope.successLoginsOfDepo++; 

                }else{
                    $scope.unSuccessLoginsOfDepo++;
                }
            });
            angular.forEach($scope.todayBackOfficeLogins,function(value, key){
                if(value.status =='S'){

    				$scope.successLoginsOfDeitz++; 

    			}else{
    				$scope.unSuccessLoginsOfDeitz++;
    			}
    		}); 
    	}

        $scope.getTranslatorsCount = function(clear_cache=false){

            $scope.searchingTranslatorsData = true;
            $http.get(apiUrl+'/api/get-translators-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.translatorsOfDays = res.result.translatorsOfDays;
                $scope.translatorsOf12Month = res.result.translatorsOf12Month;
                $scope.searchingTranslatorsData = false;
            });
        }

        // get production matrics count
        $scope.getProductionMatricsCount=function(clear_cache=false){
            $scope.searchingProductionMatricsData = true;
            $http.get(apiUrl+'/api/get-production-metrics-count',{
                params: {
                    clear_cache: clear_cache,
                    selectedBusinessUnitIDs : $scope.filterBUIds
                }
            }).success(function(res){
                $scope.productionPersons = res.result.productionPersons;
                $scope.batchDataCurrentMonth = res.result.batchDataCurrentMonth;
                $scope.batchDataLast12Month = res.result.batchDataLast12Month;
                $scope.batchDataLastWeek = res.result.batchDataLastWeek;
                $scope.batchDataToday = res.result.batchDataToday;
                $scope.searchingProductionMatricsData = false;
            });
        }
       	$scope.listTopFirms = function(){

            $scope.firm_YTD_BILLED = ['YTD BILLED'];
            $scope.firm_PYR_BILLED = ['PYR BILLED']; 
            $scope.x_axis = ["x"]; 

       		angular.forEach($scope.topFirms , function(value,key){
	 				$scope.firm_YTD_BILLED[key+1] = value.YTD_BILLED;
	 				$scope.firm_PYR_BILLED[key+1] = value.PYR_BILLED;
	 				$scope.firm_pyr_config_amt[key+1] = value.pyr_config_amt;
	 				
	 				// if(value.SHORT_NAME.length > 10){
	 					
	 				// 	$scope.x_axis[key+1] = value.SHORT_NAME.slice(0, 10) + '...';
	 				
	 				// }else{
	 					
	 					$scope.x_axis[key+1] = value.SHORT_NAME;
	 					
	 				// }
	 			}); 
	 			
                var percentageObj = { percentage : $scope.firm_pyr_config_amt[0]};
                var colorObj = {  'YTD BILLED' : '#1ab394', 'PYR BILLED' : '#ff9999' }; 
                colorObj[percentageObj["percentage"]] = "#BABABA";

 

		       	var chart = c3.generate({ 
                    bindto : '#top-ten-firm', 
				    data: {
				    	x: "x",
				        columns: [
				        	$scope.x_axis,
                            $scope.firm_pyr_config_amt,
                            $scope.firm_YTD_BILLED,
				            $scope.firm_PYR_BILLED
				        ],
				        type: 'bar',
					    colors: colorObj

				    },
				    tooltip: {

                        contents :function (data){  
                            var tFirms = $scope.topFirms[data[0].index];
                            let business_unit = '';
                            if(tFirms.businessunit != null){
                                business_unit = '('+tFirms.businessunit.name+')';
                            }
                            return  '<div style="background:white;width:auto;min-width:300px;color:black;border:1px solid black;margin-top :200px">'+
                                        '<table class="table table-bordered" style="padding:12px;">'+
                                            '<tr><th colspan="2">' + tFirms.NAME + ' ' +business_unit+ '</th></tr>'+
                                            '<tr><td><div class="pull-left">PRORATA PRIOR YEAR</div><div class="pull-right" style="background-color:#BABABA;width:20px;height:20px"></div></td><td>' + '$' + tFirms.pyr_config_amt + '</td></tr>'+
                                            '<tr><td><div class="pull-left">CURRENT YEAR BILLED</div><div class="pull-right" style="background-color:#1ab394;width:20px;height:20px"></div></td><td>' + '$' + tFirms.YTD_BILLED + '</td></tr>'+
                                            '<tr><td><div class="pull-left">PRIOR FULL YEAR</div><div class="pull-right" style="background-color:#ff9999;width:20px;height:20px"></div></td><td>' + '$' + tFirms.PYR_BILLED + '</td></tr>'+
                                            '<tr><td>% AHEAD OR BEHIND</td><td>' + tFirms.current_year_profit +'%</td></tr>'+
                                        '</table>'+
                                    '</div>';           
                        }  
                    },
                    legend: { 
                        show: true,
                        position: 'inset',
                        inset: { 
                            x: 300,
                            y: 560,
                            step: 1
                        }
                    },
                    bar: {
                        width: {
                            ratio: 0.8  
                        } 
                    },
                    axis: {
                        x: {
                            type: 'category',
                            tick: {
                                // rotate: 75,
                                multiline: true
                            },
                            height: 175
                        }
                    },
                    size: {
                        height: 600,
                        width: 1500
                    }

				});
       	}	 

        $scope.listRevenueChart = function(){

            $scope.currentYear = ['CURRENT YEAR'];
            $scope.previousYear = ['PREVIOUS YEAR'];
            $scope.budget = ['BUDGET'];
            $scope.x = ['x']; 
            
            var i = 0;

            angular.forEach($scope.revenueData.data , function(value,key){ 
                $scope.currentYear[i+1] = value['current']['invoices']['INVOICE_AMT']; 
                $scope.previousYear[i+1] = value['previous']['invoices']['INVOICE_AMT'];
                $scope.budget[i+1] = value['current']['budget']['budget_amt'];
                $scope.x[i+1] = value['current']['invoices']['MONTH'] +' '+ value['current']['invoices']['YEAR']; 
                i++;
            }); 
        
            colorObjForRevenue = {'CURRENT YEAR':'#1a75ff','PREVIOUS YEAR':' #ff9933','BUDGET':'#1ab394'};
        
            var revenue_chart = c3.generate({
                bindto : '#revenue-chart', 
                data: {
                    x: "x",
                    columns: [
                        $scope.x,
                        $scope.previousYear,
                        $scope.currentYear, 
                        $scope.budget
                    ],
                    type: 'bar',
                    types: {
                        BUDGET: 'spline',  
                    }, 
                    colors: colorObjForRevenue

                },
                tooltip: {

                    contents :function (data, defaultTitleFormat, defaultValueFormat, color){ 

                        var monthYear = $scope.revenueData.last12Months[data[0].index]; 
                        var data = $scope.revenueData.data[monthYear];
                        
                        var budget = (data.current.budget.budget_amt != 0) ? '$'+$filter('number')(data.current.budget.budget_amt, 2) : 'N/A';
                        var comp_budget = (data.current.comp_budget != 0) ? data.current.comp_budget+'%' : 'N/A';
                        var previous_budget = (data.previous.budget.budget_amt != 0) ? '$'+$filter('number')(data.previous.budget.budget_amt, 2) : 'N/A';

                        return  '<div style="background:white;width:auto;min-width:300px;color:black;border:1px solid black;margin-top :200px">'+
                                    '<table class="table table-bordered" style="padding:12px;">'+ 
                                        '<tr><td></td><td style="color:#ff9933;font-weight:800;font-size:15px;">'+data.previous.invoices.MONTH+' '+data.previous.invoices.YEAR+'</td><td style="color:#1a75ff;font-weight:800;font-size:15px;">'+data.current.invoices.MONTH+' '+data.current.invoices.YEAR+'</td></tr>'+
                                        '<tr><td><div class="pull-left">INVOICE AMT</div></td><td>$'+ $filter('number')(data.previous.invoices.INVOICE_AMT, 2)+'</td><td>$'+$filter('number')(data.current.invoices.INVOICE_AMT, 2)+'</td></tr>'+
                                        '<tr><td><div class="pull-left">RECEIPTS</div></td><td>$'+$filter('number')(data.previous.cashReceipts.CASH_RECEIPT_AMT, 2)+'</td><td>$'+$filter('number')(data.current.cashReceipts.CASH_RECEIPT_AMT, 2)+'</td></tr>'+
                                        '<tr><td><div class="pull-left">JOBS BILLED</div></td><td>'+data.previous.invoices.JOBS_BILLED+'</td><td>'+data.current.invoices.JOBS_BILLED+'</td></tr>'+
                                        '<tr><td><div class="pull-left">INVOICES BILLED</div></td><td>'+data.previous.cashReceipts.INV_BILLED+'</td><td>'+data.current.cashReceipts.INV_BILLED+'</td></tr>'+
                                        '<tr><td><div class="pull-left">JOBS SCHEDULED</div></td><td>'+data.previous.scheduledJobs.JOBS_SCHEDULED+'</td><td>'+data.current.scheduledJobs.JOBS_SCHEDULED+'</td></tr>'+
                                        '<tr><td><div class="pull-left">JOBS TAKEN</div></td><td>'+data.previous.jobsTaken.JOBS_TAKEN+'</td><td>'+data.current.jobsTaken.JOBS_TAKEN+'</td></tr>'+
                                        '<tr><td><div class="pull-left">BUDGET</div></td><td>'+previous_budget+'</td><td>'+budget+'</td></tr>'+
                                        '<tr><td><div class="pull-left">COMPARED TO PRIOR YEAR</div></td><td colspan = 2 style="font-weight:800;text-align:center;font-size:12px">'+data.comp_pyr+'%</td></tr>'+
                                        '<tr><td><div class="pull-left">COMPARED TO BUDGET</div></td><td colspan = 2 style="font-weight:800;text-align:center;font-size:12px">'+comp_budget+'</td></tr>'+
                                    '</table>'+
                                '</div>';           
                    }  
                },
                legend: { 
                    show: true,
                    position: 'inset',
                    inset: { 
                        x: 50,
                        y: 220,
                        step: 1
                    }
                },
                bar: {
                    width: {
                        ratio: 0.5  
                    } 
                },
                axis: {
                    x: {
                        type: 'category', 
                        height: 130
                    }
                },
                size: {
                    height: 300,
                    width: 550
                } 
            });
        }
        $scope.listRevenueChartMonth = function(){
           

            $scope.currentMonth = ['CURRENT MONTH'];
            $scope.previousMonth = ['PREVIOUS MONTH'];
            $scope.monthBudget = ['BUDGET'];
            $scope.x = ['x']; 
            
            var i = 0;

            angular.forEach($scope.revenueDataMonth.data , function(value,key){ 
                $scope.currentMonth[i+1] = value['current']['invoices']['INVOICE_AMT']; 
                $scope.previousMonth[i+1] = value['previous']['invoices']['INVOICE_AMT'];
                $scope.monthBudget[i+1] = value['current']['budget']['budget_amt'];
                $scope.x[i+1] = value['current']['invoices']['MONTH'] +' '+ value['current']['invoices']['YEAR']; 

                // $scope.x[0] = 'MA' 
                i++;
            });
            colorObjForMonthRevenue = {'CURRENT MONTH':'#1a75ff','PREVIOUS MONTH':'#ff9933','BUDGET':'#1ab394'};
        	
            var revenue_chart_month = c3.generate({
                bindto : '#revenue-chart-month', 
                data: {
                    x: "x",
                    columns: [
                        $scope.x,
                        $scope.previousMonth,
                        $scope.currentMonth, 
                        $scope.monthBudget
                    ],
                    // columns: [
                    //     [$scope.revenueDataMonth.lastMonths[0],  $scope.previousMonth[0]],
                    //     // $scope.budget
                    // ],
                    type: 'bar',
                    types: {
                        BUDGET: 'spline',  
                    },
                    colors: colorObjForMonthRevenue

                },
                tooltip: {

                    contents :function (data, defaultTitleFormat, defaultValueFormat, color){ 

                        var monthYear = $scope.revenueDataMonth.lastMonths[0]; 
                        var data = $scope.revenueDataMonth.data[monthYear];
                        
                        var budget = (data.current.budget.budget_amt != 0) ? '$'+$filter('number')(data.current.budget.budget_amt, 2) : 'N/A';
                        var comp_budget = (data.current.comp_budget != 0) ? data.current.comp_budget+'%' : 'N/A';
                        var previous_budget = (data.previous.budget.budget_amt != 0) ? '$'+$filter('number')(data.previous.budget.budget_amt, 2) : 'N/A';
                        return  '<div style="background:white;width:auto;min-width:300px;color:black;border:1px solid black;margin-top :200px">'+
                                    '<table class="table table-bordered" style="padding:12px;">'+ 
                                        '<tr><td></td><td style="color:#ff9933;font-weight:800;font-size:15px;">'+data.previous.invoices.MONTH+' '+data.previous.invoices.YEAR+'</td><td style="color:#1a75ff;font-weight:800;font-size:15px;">'+data.current.invoices.MONTH+' '+data.current.invoices.YEAR+'</td></tr>'+
                                        '<tr><td><div class="pull-left">INVOICE AMT</div></td><td>$'+ $filter('number')(data.previous.invoices.INVOICE_AMT, 2)+'</td><td>$'+$filter('number')(data.current.invoices.INVOICE_AMT, 2)+'</td></tr>'+
                                        '<tr><td><div class="pull-left">RECEIPTS</div></td><td>$'+$filter('number')(data.previous.cashReceipts.CASH_RECEIPT_AMT, 2)+'</td><td>$'+$filter('number')(data.current.cashReceipts.CASH_RECEIPT_AMT, 2)+'</td></tr>'+
                                        '<tr><td><div class="pull-left">JOBS BILLED</div></td><td>'+data.previous.invoices.JOBS_BILLED+'</td><td>'+data.current.invoices.JOBS_BILLED+'</td></tr>'+
                                        '<tr><td><div class="pull-left">INVOICES BILLED</div></td><td>'+data.previous.cashReceipts.INV_BILLED+'</td><td>'+data.current.cashReceipts.INV_BILLED+'</td></tr>'+
                                        '<tr><td><div class="pull-left">JOBS SCHEDULED</div></td><td>'+data.previous.scheduledJobs.JOBS_SCHEDULED+'</td><td>'+data.current.scheduledJobs.JOBS_SCHEDULED+'</td></tr>'+
                                        '<tr><td><div class="pull-left">JOBS TAKEN</div></td><td>'+data.previous.jobsTaken.JOBS_TAKEN+'</td><td>'+data.current.jobsTaken.JOBS_TAKEN+'</td></tr>'+
                                        '<tr><td><div class="pull-left">BUDGET</div></td><td>'+previous_budget+'</td><td>'+budget+'</td></tr>'+
                                        '<tr><td><div class="pull-left">COMPARED TO PRIOR YEAR</div></td><td colspan = 2 style="font-weight:800;text-align:center;font-size:12px">'+data.comp_pyr+'%</td></tr>'+
                                        '<tr><td><div class="pull-left">COMPARED TO BUDGET</div></td><td colspan = 2 style="font-weight:800;text-align:center;font-size:12px">'+comp_budget+'</td></tr>'+
                                    '</table>'+
                                '</div>';           
                    }  
                },
                legend: { 
                    show: true,
                    position: 'inset',
                    inset: { 
                        x: 50,
                        y: 220,
                        step: 1
                    }
                },
                bar: {
                    width: {
                        ratio: 0.5  
                    } 
                },
                axis: {
                    x: {
                        type: 'category', 
                        height: 130
                    }
                },
                size: {
                    height: 300,
                    width: 550
                } 
            });
        }
        $scope.askDashboardPass = function(isValid){ 
            $scope.formSubmit = true;
            if(isValid){
                $rootScope.showLoader(true);
                $http.post(apiUrl+'/api/auth-dashboard-pass',{'password' : $scope.dashboard.password}).success(function(res){
                    $rootScope.showLoader(false);
                    if(res.error){
                        Notification.error(res.result.message);
                    }else{
                        if(res.result.auth){

                            $cookies.put('dashboardUser', JSON.stringify($scope.dashboard.password));
                            $scope.viewDashboard = true;
                            $scope.fetchData();
                        
                        }else{
                            Notification.error('Something went wrong !');
                        } 
                    }
                });
            }
        }

        $scope.getSalesPersonComparationMetrics = function(){
            $scope.searchingComparationData = true;
            $http.get(apiUrl+'/api/get-salesperson-comparation-matrics',{
                params: {
                    selectedBusinessUnitIDs : $scope.filterBUIds,
                    isSalesLogin:$rootScope.isSalesLogin
                }
            }).success(function(res){
                $scope.currentSalesData = res.result.currentSalesData;
                $scope.otherSalesData = res.result.otherSalesData;
                $scope.searchingComparationData = false;
            });
        }

        $scope.fetchData = function(){
            if($rootScope.havePermission('dashboard','view.trailing.days')){
                $scope.getOperationMetrics(true);
            }
            if($rootScope.havePermission('dashboard','view.invoices')){
                $scope.getInvoices(true);
            }
            if($rootScope.havePermission('dashboard','view.reporter.pay')){
                $scope.getInvoicesCost(true);
            }
            if($rootScope.havePermission('dashboard','view.top.summary')){
                $scope.getInvoicesBiller(true);
            }
            if($rootScope.havePermission('dashboard','view.invoice.biller')){
                $scope.getInvoicesBillerCount(true);
            }
            if($rootScope.havePermission('dashboard','view.collectors')){
                $scope.getInvoicesCollector(true);
            }
            if($rootScope.havePermission('dashboard','view.sales.rep.metrics')){
                $scope.getInvoicesSalesCommission(true);
                if($rootScope.isSalesLogin){
                    $scope.getSalesPersonComparationMetrics();
                }
            }
            if($rootScope.havePermission('dashboard','view.connection.methods')){
                $scope.getConnectionMethods(true);
            }
            if($rootScope.havePermission('dashboard','view.job.held.at.locations')){
                $scope.getConnectionMethodsPlaces(true);
            }
            if($rootScope.havePermission('dashboard','view.cash.receipts')){
                $scope.getCashReceipts(true);
            }
            if($rootScope.havePermission('dashboard','view.calendar')){
                $scope.getJobsCount(true);
            }
            if($rootScope.havePermission('dashboard','view.ar.fax.email.utility')){
                $scope.getFaxEmailData(true);
            }
            if($rootScope.havePermission('dashboard','view.wip')){
                $scope.getPendingJobsCount(true);
            }
            if($rootScope.havePermission('dashboard','view.transcripts.in.process')){
                $scope.getTranscripts(true);
            }
            if($rootScope.havePermission('dashboard','view.logins')){
                $scope.getLogins(true);
            }
            if($rootScope.havePermission('dashboard','view.revenue.year.over.year.vs.budget')){
                $scope.getRevenueData(true);  
            }
            if($rootScope.havePermission('dashboard','view.account.receivable.invoice.cash.summary')){
                $scope.getAccountReceivableSummary(true);
            }
            if($rootScope.havePermission('dashboard','view.top.firms')){
                $scope.getTopFirms(true); 
            }
            if($rootScope.havePermission('dashboard','view.translators')){
                $scope.getTranslatorsCount(true);            
            }
            if($rootScope.havePermission('dashboard','view.intake.method')){
                $scope.getOrderSource(true);     
            }
            if($rootScope.havePermission('dashboard','view.production')){
                $scope.getProductionMatricsCount(true);            
            }
        }

        if($state.params.token){
            configFactory.getConfiguration({ keys: ['dashboard_notification_token'] })
                .then(function (response) {
                    if($state.params.token === response.data.configurations.dashboard_notification_token){
                        $scope.viewDashboard = true;
                        $scope.fetchData();      
                    }
                }); 
        }
        //check password is available for dashboard or not
        var dashboardUser = $cookies.get('dashboardUser');

        if(dashboardUser || $rootScope.havePermission('allow_access_without_password','allow.selective.metrics')){
            $scope.viewDashboard = true;
            $scope.fetchData();
        }


        $scope.clearCache = function(){
            $rootScope.showLoader(true);
            $http.get(apiUrl+'/api/clear-dashboard-cache').success(function(res){
                $rootScope.showLoader(false);
                if(res.error){
                    Notification.error(res.result.message);
                }else{
                    $scope.fetchData();
                }
            });
        }

    }]);
