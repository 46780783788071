angular.module('deitz').controller('addUpsHistoryController', [
    '$state',
    '$rootScope',
    '$scope',
    '$http',
    'apiUrl',
    'commonFactory',
    'Notification',
    function ($state,
        $rootScope,
        $scope,
        $http,
        apiUrl,
        commonFactory,
        Notification
    ) {

        $scope.historyForm = {delivery_date : moment()};
        $scope.formSubmit = false;
        $scope.id = $state.params.id;
        $scope.title = "Enter Shipment Tracking";
        $scope.btnTitle = "Save";
        $scope.isJobSelected = false;
        $scope.serviceLevelList = [];

        /* This function us use for get service Level list*/
        $http.post(apiUrl + '/api/get_service_level',{ 'type' : 'shipment_traking' })
        .success(function (response) { 
            $scope.serviceLevelList = response.data;            
        }).error(function (err) {
           
        });
        
        /* This function is use for get job table list */
        $scope.joblist = function(val) {
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        /* This function is use for select job from list */
        $scope.selectJobFromList = function ($item, $model, $label, $event) {
            $scope.historyForm.JobNo = $item.JOB_NO;
            $scope.isJobSelected = true;
        };

        /*This function is use when user change job number */
        $scope.onChangeOfJobNumber = function () {
            $scope.isJobSelected = false;
        };

        /* This function is use for clear job number  */
        $scope.clearJobNumber = function(){            
            if(!$scope.isJobSelected){
                 $scope.historyForm.JobNo = '';
            }
        };

        /* Use for save data */
        $scope.save = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                if($scope.historyForm.ServiceLevel == 'Messenger'){
                    $scope.historyForm.delivery_date = moment($scope.historyForm.delivery_date).format('YYYY-MM-DD HH:mm:ss');
                }else{
                    delete $scope.historyForm.delivery_date;
                    delete $scope.historyForm.delivery_control_no;
                    delete $scope.historyForm.service_name;
                    delete $scope.historyForm.signed_by;
                }
                /* API call for add data */
                $http.post(apiUrl + '/api/add_ups_history', $scope.historyForm).success(function (res) {
                    $rootScope.showLoader(false);
                    if (res.error) {
                        Notification.error(res.result.message);
                    } else {
                        Notification.success('UPS History Inserted Successfully');                            
                    }
                });
            }
        }
    }]);