angular.module('deitz')
	.controller('financialValuesController',
		['$state',
	    '$rootScope',
	    '$scope',
	    '$http',
	    'apiUrl',
	    'Notification',
		    function ($state,
		        $rootScope,
		        $scope,
		        $http,
		        apiUrl,
		        Notification
		    ) {

		$scope.title = $state.params.id ? 'Edit' : 'Add';
		$scope.formSubmit = false;
		
		$scope.formData = {
			budget_date : moment()
		}; 

		$http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });

		//get data for edit screen
		if($state.params.id){

			$http.get(apiUrl+'/api/financial_values/'+$state.params.id+'/edit').success(function(response){
				if(response.error){
					
					Notification.error(response.result.message);

				}else{

					var resValue = response.result.data;
					$scope.formData.budget_date = resValue.date;
					$scope.formData.budget_amt = Number(resValue.budget_amt);	
					$scope.formData.ar_total = Number(resValue.ar_total);
					$scope.formData.ar_180 = Number(resValue.ar_180);
					$scope.formData.business_unit_id = resValue.business_unit_id;
				}

			});

		}
		$scope.save = function(isValid){
			$scope.formSubmit = true; 
			if(isValid){
				$rootScope.showLoader(true);
				$scope.formData.budget_date = moment($scope.formData.budget_date).format('MM/DD/YYYY');
				if($state.params.id){
					$http.put(apiUrl+'/api/financial_values/'+$state.params.id, $scope.formData).success(function(response){
						
						$rootScope.showLoader(false);
						if(response.error){

							Notification.error(response.result.message);

						}else{

							Notification.success(response.result.message);
							$state.go('code_tables.financial_values_browse');
						
						}
					});

				}else{

					$http.post(apiUrl+'/api/financial_values', $scope.formData).success(function(response){
						$rootScope.showLoader(false);
						if(response.error){

							Notification.error(response.result.message);
						
						}else{
						
							Notification.success(response.result.message);
							$state.go('code_tables.financial_values_browse');
						
						}
					
					});
					
				}

			}
		} 

}]);