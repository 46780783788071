angular.module('deitz').controller('editcasetypeController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    '$uibModalInstance',
    'casetypeId',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        $uibModalInstance,
        casetypeId
    ){

        $scope.casetype = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Case Type';
        $scope.modalTitle = 'Edit Case Type';
        $scope.modalId = casetypeId;
        
        //Edit Mode of casetype.
        if ($state.params && $state.params.id) {
            var id = casetypeId?casetypeId:$state.params.id;
            commonFactory.get('/api/casetype/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.casetype_detail) {
                        $scope.casetype.name = response.result.casetype_detail.name || '';
                        $scope.casetype.active = response.result.casetype_detail.active || '';
                        $scope.casetype.sort_order = response.result.casetype_detail.sort_order || null;
                        $scope.casetype.isDefault = response.result.casetype_detail.isDefault ||'0';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        //Update Mode of Casetype
        $scope.addCasetype = function(casetype, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = casetypeId?casetypeId:$state.params.id;
                commonFactory.put('/api/casetype/' + id, $scope.casetype)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Casetype updated successfully.");

                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.casetype_update.id);
                                }else{
                                    $state.go("code_tables.case_type_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
