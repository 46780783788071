angular.module('deitz')
    .controller('archiveController', [
        '$scope',
        '$rootScope',
        '$stateParams',
        'commonFactory',
        '$http',
        'apiUrl',
        '$window',
        '$state',
        'Notification',
    function (
        $scope,
        $rootScope,
        $stateParams,
        commonFactory,
        $http,
        apiUrl,
        $window,
        $state,
        Notification
        ) {

        $scope.type = '';
        $scope.jobDueDate = '';
        $scope.format = 'MM/dd/yyyy'; 
        $scope.dtInstance = {};
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;
        });

        $scope.typeError = false;
        $scope.businessError = false;
        $scope.jobDueDateError = false;

        $scope.downloadExcel = function(){
            $scope.typeError = false;
            if (!$scope.type) {
                $scope.typeError = true;
                Notification.error("Please select a Report Type.");
                return;
            }
    
            $scope.businessError = false;
            if ($scope.selectedBusinessUnitIDs.length === 0) {
                $scope.businessError = true;
                Notification.error("Please select at least one Business Unit.");
                return;
            }
            $scope.jobDueDateError = false;
            if (!$scope.jobDueDate) {
                $scope.jobDueDateError = true;
                Notification.error("Please select a Date.");
                return;
            }

            $rootScope.showLoader(true);
            $http.get(apiUrl+'/api/downloadArchive',{
                params: {
                    businessUnitId: [$scope.selectedBusinessUnitIDs],
                    reportType: $scope.type,
                    selectedDate: moment($scope.jobDueDate).format('MM/DD/YYYY')
                }
            }).then(function (response) {
                $rootScope.showLoader(false);
                if(response.data.result){
                    
                    var link = document.createElement('a');
                        link.href = response.data.result;
                        link.click();

                    Notification.success("File Download Successfully");
                }else{
                    $rootScope.showLoader(false);
                    Notification.error("Data Not Found");
                }
                
            });
        }


        $scope.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs;
       
        
        $scope.jobDueDateOpened = false;
            $scope.openDatePicker = function (field) {
                 if(field === 'jobDueDate'){
                    $scope.jobDueDateOpened = !$scope.jobDueDateOpened;
                }
            };


    }]);