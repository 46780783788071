angular.module('deitz').controller('businessunitController', [
    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile',
    function(
        $scope, $rootScope, $state, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $q, $compile
    ){

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/businessunit', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'BusinessUnitDetails' },
                { extend: 'pdf', title: 'BusinessUnitDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Business Unit'),
            //DTColumnBuilder.newColumn('slug').withOption('defaultContent', '').withTitle('Slug').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="code_tables.business_unit_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;';
                }

            })
        ];

    }]);
