
/**
 * Deitz court reporting
 *
 */
var api_url = env_config.apiUrl;
var pusher_instance_id = '2200e712-0912-4d8e-826e-12073c26f270';
var pusher_secret = '313826A4C01C00B49842840AEAD65482F88E0504386FDF4A40CC31D504AF7C38';
var base_href = env_config.baseHref;
var cookie_path = env_config.cookiePath;
(function () {
    angular.module('deitz', [
        'ui.router', // Routing
        'oc.lazyLoad', // ocLazyLoad
        'ui.bootstrap', // Ui Bootstrap
        'ngAnimate',
        'ngCookies',
        'cgNotify',
        'datePicker',
        'ui.bootstrap.datetimepicker',
        'ui.dateTimeInput',
        'oitozero.ngSweetAlert',
        'xeditable',
        'ivh.treeview',
        'ui-notification',
        'datatables',
        'datatables.columnfilter',
        'datatables.buttons',
        'datatables.light-columnfilter',
        // 'ngDragDrop',
        //'ngTouch',
        // 'ngSanitize',
        // 'ngMessages',
        //'ngAria',
        'ngResource',
        'ngStorage',
        'ngSanitize',
        'ngFileUpload',
        'ui.timepicker',
        'angularMoment' ,
        'angular-ladda',
        'ngIdle',
        'credit-cards'
    ]).value('c',{
        step: 5,
        timeFormat: 'h:i A',
        useSelect: false,
        forceRoundTime: false
    }).value('angularMomentConfig', {
            timezone: 'Europe/London' // e.g. 'Europe/London'
        })
        .constant("pusher_instance_id", pusher_instance_id)
        .constant("pusher_secret", pusher_secret)        
        .constant("apiUrl", api_url)
        .constant("cookie_path", cookie_path)
        .constant("base_href", base_href);
})();
