angular.module('deitz').controller('representativeaddController', [
    '$scope', '$rootScope', '$state',

    function(
        $scope, $rootScope, $state
    ) {

        $scope.representative = '';

        $scope.selectRepresentative = function(){

            if($scope.representative === 'R'){
                $state.go('representative.reporter.step_one');
            }
            else if ($scope.representative === 'S') {
                $state.go('representative.sales.step_one');
            }
            else if ($scope.representative === 'T') {
                $state.go('representative.typist.step_one');
            }
            // else if ($scope.representative === 'SC') {
            //     $state.go('representative.scopist.step_one');
            // }
            else{
                $state.go('representative.reporter.step_one');
            }
        }

    }]);
