angular.module('deitz')
			.controller('sfdcReportController',[
				'$state',
				'$scope',
				'$rootScope',
				'$http',
				'apiUrl',
				'Notification',
				'commonFactory',
				'$window',
				function(
					$state,
					$scope,
					$rootScope,
					$http,
					apiUrl,
					Notification,
					commonFactory,
					$window
				){

		$scope.title = $state.current.data.pageTitle;
		$scope.itemsPerPage = 10;
        $scope.currentPage = 1;

		$scope.report= {
			dateRange : {
				endDate : moment().format('MM-DD-YYYY'),
				startDate : moment().format('MM-DD-YYYY')
			}
		}
		$scope.resetPagination = function (number=10){
            $scope.currentPage = 1; //reset to first paghe
            $scope.itemsPerPage = number;
            $scope.search();
        };

		$scope.numberWithCommas = function(x) {
            return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }
		$scope.contacts = [];
		$scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
		$http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });
		$scope.skuData = [];

		$scope.search = function(download=false){
			
			$rootScope.showLoader(true);
			
			var startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
			var endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
			var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
			var offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
            var limit = $scope.itemsPerPage;

			localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));  
			commonFactory.post('/api/get-sdfc-report',
			{
				startDate : startDate,
				endDate : endDate, 
				download: download,
				selectedBusinessUnitIDs:selectedBusinessUnitIDs,
				offset:offset,
				limit:limit
			}).success(function(response){
				$rootScope.showLoader(false);
					
                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else if(!download){
                        
                        $scope.contacts =  response.result.contacts;
                        $scope.totalRecords = response.result.totalRecords;

                        if($scope.contacts.length == 0){
                            Notification.error('No records found!');
                        }
                    }
					if(download && response.result.fileName){
						// $window.location.href = apiUrl +'/api/download-sfdc-report';
						$window.open(apiUrl +'/api/download-sfdc-report', '_blank');  
					}
			})
			.error(function(err)
                {
                    $scope.contacts = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
		} 

}]);