angular.module('deitz').
	controller('browseMessageController',[
		'$scope',
		'$rootScope',
		'DTOptionsBuilder',
		'DTColumnBuilder',
        '$http',
        'apiUrl',
		'$compile',
		'$state',
		'commonFactory',
		function(
			$scope,
			$rootScope,
			DTOptionsBuilder,
			DTColumnBuilder,
			$http,
            apiUrl,
            $compile,
			$state,
			commonFactory
				){
 
        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
                        
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }; 
        $scope.reloadData = function() { 
            $scope.dtInstance.rerender();
        }; 
        $scope.searchData = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };
           
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                commonFactory.showDataTable('/api/get-fax-email-messages', data)
                .success(function(res) {
                    if (res) {
                        $rootScope.showLoader(false); 
                        callback(res);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }) 

            .withOption('processing', true)  
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'asc'])
            .withOption('createdRow', $scope.compileDatatable); 
 
        $scope.dtColumns = [  
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Name').withOption('width','15%').withOption('searchable',true),
            DTColumnBuilder.newColumn('content').withOption('defaultContent', '').withTitle('Content').notSortable(),
            DTColumnBuilder.newColumn('business_unit_name').withOption('defaultContent', '').withTitle('Business Unit').notSortable().withOption('searchable',false),
            DTColumnBuilder.newColumn(null).withTitle('').notSortable()
            .renderWith(function(data, type, full, meta) {
                return  '<button class="btn btn-primary btn-xs" uib-tooltip="Edit" ui-sref="statement_message.edit({id:'+ data.id+'})" >' +
                            '<i class="fa fa-pencil"></i>' +
                        '</button>';


            }).withOption('width','10%')
        ];


}]);