angular.module('deitz').controller('addGroupChargeController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','$http','apiUrl',
    function(
        $scope, $state, $rootScope, codeFactory, commonFactory, Notification,$http,apiUrl
    ){

        $scope.our_suite = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Group Charge';

        $http.get(apiUrl + '/api/get-rate-codes').then(function(response) { 
            $scope.ratecodeList = response.data.result;                      
          });


        $scope.submitGroupCharge = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/group-charge', $scope.groupCharge)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Group Charge added successfully");
                                $state.go("code_tables.group_charge_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

    }]);
