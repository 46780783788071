angular.module('deitz').controller('chargesController', [
    '$scope',
    '$rootScope',
    'commonFactory',
    function(
        $scope,
        $rootScope,
        commonFactory
    ){

        $scope.charges = {};

        // Get all charges (Browse)
        $scope.charges = function() {
            $rootScope.showLoader(true);
            commonFactory.get('/api/charges')
                .success(function(response) {
                    console.log(response);
                    if (response && response.result && response.result.data) {
                        $scope.charges = response.result.data;
                    }
                    $rootScope.showLoader(false);
                })
                .error(function(err) {
                    console.log(err);
                    $rootScope.showLoader(false);
                });
        }

    }]);
