angular.module('deitz')
            .controller('openInvoiceReportController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$state',
                    '$window',
                    'Notification',
                    '$filter',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $state,
                            $window,
                            Notification,
                            $filter
                        ) {
        
        $scope.dtInstance = {};
        $scope.dateRange
        $scope.title = $state.current.data.pageTitle;
        $scope.downloadExcel=false;

        
        $scope.resetSearch = function(){
            $scope.dateRange = null;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.report= {
			dateRange : {
				endDate : moment().format('MM-DD-YYYY'),
				startDate : moment().format('MM-DD-YYYY')
			}
		}

        
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        
        $scope.reloadData = function () {
            
            $scope.dtInstance.rerender();
        };
        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
       
        $scope.downloadExcelFile = function(){ 
            $scope.downloadExcel=true;
            $scope.reloadData();
        }

  

        $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withDataProp('data')
        .withOption('ajax', function (data, callback, settings) {
            data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            console.log($scope.downloadExcel);
            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));                $scope.inProcess = true;
            data.exClosed=true;
            if($scope.status=='paid'){
                data.exUnPaid=true

            }
            if($scope.status=='unpaid'){
                data.exPaid=true
            }
            data.downloadExcel=$scope.downloadExcel;
             data.startDate = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
			 data.endDate = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
            
          
            commonFactory.showDataTable('/api/invoice/get-open-invoice-report', data).success(function (res) {

                if (res.error) {
                   // $scope.reloadData();
                }
                else {
                    $scope.selectAll = true;
                    $scope.invoiceArray = {};
                    callback(res);
                }
                if($scope.downloadExcel){
                    //  $window.location.href = apiUrl +'/api/download-open-invoice-report';
                     $window.open(apiUrl +'/api/download-open-invoice-report', '_blank');    
                }
                $scope.downloadExcel=false;
            }).error(function (err) {
                if (err.error !== "token_not_provided") {
                    //$scope.reloadData();
                }
            });
        })
        .withOption('processing', true)
        .withLanguage({
            "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
            "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
            "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
            "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
            "sInfoPostFix": "",
            "sInfoThousands": ",",
            "sLengthMenu": "SHOW _MENU_ INVOICES",
            "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
            "sProcessing": "<img src='img/loading_bar.gif'/>",
            "sSearch": "",
            "sZeroRecords": "NO MATCHING INVOICE FOUND",
            "oPaginate": {
                "sFirst": "FIRST",
                "sLast": "LAST",
                "sNext": "NEXT",
                "sPrevious": "PREVIOUS"
            },
            "oAria": {
                "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
            }
        })
        .withOption('serverSide', true)
        .withOption('stateSave', true)
        .withPaginationType('simple_numbers')
        .withOption('searchDelay', 500)
        // .withOption('searching', false)
        .withOption('order', [1, 'desc'])
        .withOption('createdRow', $scope.createdRow)
        .withOption('headerCallback', function (header) {
            // Use this headerCompiled field to only compile header once
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        });
        

    $scope.dtColumns = [];
   $scope.ori_invoice = {};
        
    $scope.dtColumns.push(
        DTColumnBuilder.newColumn(null).withTitle('Status').withOption('searchable', false).notSortable().renderWith(function (data, type, full, meta) {
            console.log();
            if (full.INV_BAL_DUE > 0) {
                return 'Unpaid';
            } else {
                return 'Paid';
            }
        }),
        DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice No').withOption('searchable', true).renderWith(function (data, type, full, meta) {
            return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank">'+full.original_invoice_no+'</a>';
        }),
        DTColumnBuilder.newColumn('diary.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'diary.attorney_firm.SHORT_NAME').withOption('name', 'diaryData.attorneyFirm.SHORT_NAME').renderWith(function (data, type, full, meta) {
            if (data) {
                data = escapeString(data);
                var billing_firm = "<div uib-tooltip='"+full.diary.attorney_firm.NAME+ " | "+full.diary.attorney_firm.PHONE+ " | " +full.diary.attorney_firm.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                return billing_firm;
            } else {
                return '---';
            }

        }),
        DTColumnBuilder.newColumn('job.CAPTION').withTitle('Case Name').withOption('searchable', true).withOption('data', 'job.CAPTION').withOption('name', 'job.CAPTION').renderWith(function (data, type, full, meta) {
            if (data) {
                return data;
            } else {
                return '---';
            }
        }),
        DTColumnBuilder.newColumn('ClaimNumber').withTitle('Claim Number').withOption('searchable', true),
        DTColumnBuilder.newColumn('FILE_NO').withTitle('File Number').withOption('searchable', true),
        DTColumnBuilder.newColumn('IndexNumber').withTitle('Court Assigned / Index Number').withOption('searchable', true),
        DTColumnBuilder.newColumn('witness').withTitle('Witnesses').withOption('searchable', true).withOption('data', 'witness').notSortable().withOption('name', 'witnessData.witness.NAME').renderWith(function (data, type, full, meta) {
            if (data) {
                return $filter('uppercase')(data, true);
            } else {
                return '---';
            }
        }),
        DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-'),
        DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('searchable', true),
        DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
            if (data) {
                return '$' + $filter('number')(data, 2);
            } else {
                return '$' + $filter('number')(0, 2);
            }

        }),
        DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('searchable', true).withClass('font-bold').renderWith(function (data, type, full, meta) {
            if (data) {
                return '$' + $filter('number')(data, 2);
            } else {
                return '$' + $filter('number')(0, 2);
            }

        }),
        DTColumnBuilder.newColumn('INV_BAL_DUE').withTitle('Balance Due').withOption('searchable', false).withClass('font-bold').renderWith(function (data, type, full, meta) {
            if (data) {
                return '$' + $filter('number')(data, 2);
            } else {
                return '$' + $filter('number')(0, 2);
            }
        }),
        DTColumnBuilder.newColumn('attorney_name.NAME').withTitle('Attorney-Person / Attention Billed').withOption('searchable', true).withOption('defaultContent', '-'),
        //DTColumnBuilder.newColumn('line_items').withTitle('Job Details').withOption('searchable', false).notSortable().withOption("width", "15%").withOption('defaultContent', '-'),
        DTColumnBuilder.newColumn('lax_user').withTitle('Lexitas User').withOption('searchable', false).withOption('defaultContent', '-'),

    );

    }
]);
