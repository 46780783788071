angular.module('deitz')
			.controller('skuLegecyReportController',[
				'$state',
				'$scope',
				'$rootScope',
				'$http',
				'apiUrl',
				'Notification',
				'commonFactory',
				'$window',
				function(
					$state,
					$scope,
					$rootScope,
					$http,
					apiUrl,
					Notification,
					commonFactory,
					$window
				){

		$scope.title = $state.current.data.pageTitle;		
		$scope.skuCharge = [];
		$scope.selectedFirms = [];
		$scope.min_date = moment('2018-01-01');

		$scope.report = {
			startDate : moment().subtract(12, 'month'),
			endDate : moment()
		};
		 // get firms groups
		 $rootScope.showLoader(true);
		 commonFactory.get('/api/get-firm-groups')
		 .success(function(response) {
			 $rootScope.showLoader(false);
			 $scope.firmGroups = response.result;
		 })
		 .error(function(err) {
			 $rootScope.showLoader(false);
		 });
 
		  //Getting all Salesperson .
		  commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
		  .success(function(response) {
			  if(response.error){
				  Notification.error(response.result.message || 'Something went wrong!');
			  } else{
				  $scope.salesPersonsList = response.result.resources;
			  }
			  $rootScope.showLoader(false);
		  });

		 $scope.groupChange = function(){
 
			 var groups = [];    
			 angular.forEach($scope.formData.selectedGroups, function(value, key) {
				groups[key] = value.group_name;
			 });
			 
			 // get firms of selected groups
			 $rootScope.showLoader(true);
			 commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
			 .success(function(response) {
				 $rootScope.showLoader(false);
				 $scope.selectedFirms = response.result;
			 })
			 .error(function(err) {
				 $rootScope.showLoader(false);
			 });
 
		 };
		$scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
		$scope.selectedBusinessUnitIDs = [];
		$scope.skuChargeSelect = [];
		$scope.salesPersonsIds = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
		$http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });
		$scope.skuData = [];
		
		
        //Getting Attorneys list
       
		$scope.search = function(download=false){
			
			$rootScope.showLoader(true);
			if($scope.selectedFirms.length){
				var attorneyFirmIds = $scope.selectedFirms.map(function(a){
					return  a.id;
				});
			}
			else{
				var attorneyFirmIds = [];
			}

			var startDate = moment($scope.report.startDate).format('YYYY-MM');
			var endDate = moment($scope.report.endDate).format('YYYY-MM');
			var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
			var skuChargeSelect = $scope.skuChargeSelect.filter(Boolean);
			var salesPersonsIds = $scope.salesPersonsIds.filter(Boolean);
			localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));  
			commonFactory.post('/api/get-sku-report-legacy',{startDate : startDate, endDate : endDate, download: download,selectedBusinessUnitIDs:selectedBusinessUnitIDs,chargeids:skuChargeSelect,attorneyFirmIds:attorneyFirmIds,salesPersonsIds:salesPersonsIds}).success(function(response){
				
				$rootScope.showLoader(false);
				
				if(response.status == 200){
					$scope.skuData = {
						months : response.result.months,
						pages : response.result.pages,
						charges : response.result.charges,
					}

					if(download && response.result.fileName){
						$window.location.href = apiUrl +'/api/download-sku-report';
					}

				}else{
					Notification.error(response.result.message);
				}

			});
		}


		
		$scope.getsku = function(){
			$rootScope.showLoader(true);
			$('#loaderid').removeClass('pause-loader');
			$('#loaderid').addClass('play-loader');
			
			commonFactory.get('/api/get-sku-charge')
			.success(function(response) {			
				if (response) {
					if (response.status == 200) {						
						$scope.skuCharge = response.result.charges;
						$scope.skuCharge.unshift({DESC:'All'});						
					}					
					//$('#invtbl_processing').hide();
					$('#loaderid').removeClass('play-loader');
					$('#loaderid').addClass('pause-loader');
					$rootScope.showLoader(false);
				}
			})
			.error(function(err) {
				//$('#invtbl_processing').hide();
				$('#loaderid').removeClass('play-loader');
					$('#loaderid').addClass('pause-loader');
				$rootScope.showLoader(false);
			});
		}
		$scope.getsku();	
		
		$scope.bgskurun = function(){
			
			var startDate = moment($scope.report.startDate).format('YYYY-MM');
			var endDate = moment($scope.report.endDate).format('YYYY-MM');
			var selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
			var skuChargeSelect = $scope.skuChargeSelect.filter(Boolean);
			var salesPersonsIds = $scope.salesPersonsIds.filter(Boolean);		
			var user_email = $rootScope.auth_user.email;
			if($scope.selectedFirms.length){
				var attorneyFirmIds = $scope.selectedFirms.map(function(a){
					return  a.id;
				});
			}
			else{
				var attorneyFirmIds = [];
			}
			commonFactory.post('/api/add-reportsku-payload',{user_email:user_email ,startDate : startDate, endDate : endDate,selectedBusinessUnitIDs:selectedBusinessUnitIDs,chargeids:skuChargeSelect,attorneyFirmIds:attorneyFirmIds,salesPersonsIds:salesPersonsIds}).success(function(response){
				$rootScope.showLoader(false);
				if(response.status == 200){
					Notification.success(response.result);
				}else{
					Notification.error(response.result);
				}
			});

			
			/*$rootScope.showLoader(true);
			commonFactory.get('/api/run-reportsku-background-cron')
			.success(function(response) {	
				console.log(response);		
			})
			.error(function(err) {
				console.log(response);
				$rootScope.showLoader(false);
			});*/

		}
	
	}]);
