angular.module('deitz').controller('editwitnesstypeController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.witnesstype = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Witness Type';
        $scope.modalTitle = 'Edit plaintiff/defendant';
        $scope.modalId = plaintiffId;

        //close modal
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        //Edit Mode of witnesstype.
        if ($state.params && $state.params.id) {
            var id = plaintiffId? plaintiffId:$state.params.id;
            commonFactory.get('/api/witnesstype/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.witnesstype_detail) {
                        $scope.witnesstype.name = response.result.witnesstype_detail.name || '';
                        $scope.witnesstype.sort_order = response.result.witnesstype_detail.sort_order || null;
                        $scope.witnesstype.active = response.result.witnesstype_detail.active || '';
                        // $scope.witnesstype.slug = response.result.witnesstype_detail.slug || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of witnesstype
        $scope.addWitnesstype = function(witnesstype, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = plaintiffId? plaintiffId:$state.params.id;
                commonFactory.put('/api/witnesstype/' + id, $scope.witnesstype)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Witnesstype updated successfully");
                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.witnesstype_update.id);
                                }else{
                                    $state.go("code_tables.witness_type_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
