angular.module('deitz')
    			.controller('findCommissionController', [
    					'$scope', 
    					'$rootScope', 
    					'$http', 
    					'apiUrl', 
    					'$state', 
    					'firmFactory', 
    					'commonFactory', 
    						function(
    							$scope, 
    							$rootScope, 
    							$http, 
    							apiUrl, 
    							$state, 
    							firmFactory,
    							commonFactory
    					) {

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

    }]);
