angular.module('deitz')
    .controller('WorkingHourPopupController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        '$filter',
        'commonFactory',
        '$stateParams',
        '$uibModal',
        'invoiceFactory',
        'Notification',
        'reptyp_data_id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  $state,
                  $timeout,
                  $filter,
                  commonFactory,
                  $stateParams,
                  $uibModal,
                  invoiceFactory,
                  Notification,
                  reptyp_data_id,
                  $uibModalInstance,
                  configFactory) {

            $scope.reptyp_data = reptyp_data;
            var open_time = moment().set({h: 9, m: 00}).format("h:mm A");
            var close_time = moment().set({h: 17, m: 00}).format("h:mm A");

            $scope.time = ['09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM', '12:30 PM', '01:00 PM', '01:30 PM', '02:00 PM', '02:30 PM', '03:00 PM', '03:30 PM', '04:00 PM', '04:30 PM', '05:00 PM'];

            $scope.timeList = [];
            let x = {
                slotInterval: 30,
                openTime: '00:00',
                closeTime: '23:59'
            };
              
            let startTime = moment(x.openTime, "hh:mm A");
            let endTime = moment(x.closeTime, "hh:mm A");  
            
            while (startTime < endTime) {
                $scope.timeList.push(startTime.format("h:mm A")); 
                startTime.add(x.slotInterval, 'minutes');
            }
            $scope.timeList.push(endTime.format("hh:mm A"));
            
            $scope.workingDays = [
                {
                    day:'Monday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:1,id:''
                },
                {
                    day:'Tuesday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:2,id:''
                },
                {
                    day:'Wednesday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:3,id:''
                },
                {
                    day:'Thursday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:4,id:''
                },
                {
                    day:'Friday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:5,id:''
                },
                {
                    day:'Saturday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:6,id:''
                },
                {
                    day:'Sunday',is_working:false,open_time:open_time,close_time:close_time,day_of_week:7,id:''
                },
            ]
        
            if($scope.reptyp_data.woking_days && $scope.reptyp_data.woking_days.length) {
                $scope.reptyp_data.woking_days.forEach(element => {
                    var index = 0
                    $scope.workingDays.forEach(data => {
                        if(element.day_of_week == data.day_of_week){
                            $scope.workingDays[index].is_working = true;
                            $scope.workingDays[index].open_time = element.open_time;
                            $scope.workingDays[index].close_time = element.close_time;
                        }
                        index++ 
                    });
                });
            }
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
            };
            $scope.submit_update = function(){ 
                let is_error = false;
                $scope.workingDays.forEach(data => {
                    if(data.is_working){
                        if(moment(data.open_time, "hh:mm A") < moment(data.close_time, "hh:mm A")){
                        }else{
                            Notification.error(data.day + ' start time always less than end time');
                            is_error = true;
                            return false;
                        }
                    }
                });
                if(is_error){
                    return false;
                }
                $rootScope.showLoader(true);

                commonFactory.post('/api/reptype/add-working-hours',{'id':$scope.reptyp_data.id,'data': $scope.workingDays,'timezone':moment.tz.guess()}).success(function (res) {
                    $rootScope.showLoader(false);
                    if (res.error){
                        
                    }else{ 
                        $scope.closePopupWindowSimple();
                        setTimeout(function () {
                            document.getElementById('reloadSuccesPopup').click();
                        }, 400);
                    }
                }).error(function (err) {
                    $rootScope.showLoader(false);
                });
            };
            $scope.submit_updates = function(){
                $rootScope.showLoader(true);
                commonFactory.post('/api/reptype/add-working-hours',{'id':$scope.reptyp_data.id,'data':$scope.workingDays}).success(function (res) {
                    $rootScope.showLoader(false);
                    if (res.error)
                    {
                        
                    }
                    else{ 
                        $scope.closePopupWindowSimple();
                        setTimeout(function () {
                            document.getElementById('reloadSuccesPopupUpdate').click();
                        }, 400);
                    }
                }).error(function (err) {
                 $rootScope.showLoader(false);
                }
                    
                );
            };
        }]);