angular.module('deitz').controller('witnessJobController', [
    '$scope', '$rootScope', '$http', 'apiUrl',
    function(
        $scope, $rootScope, $http, apiUrl) {
        // $scope.title = "Browse Witness";
        $scope.editInvoiceData = {};
        //Getting Invoices List
        $scope.invoicelist = function(val) {
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function(response) { 
                return response.data.result.map(function(item) {
                    return item; 
                });
            });
        };

    }]);
