angular.module('deitz').controller('editTurnAroundController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, 
    ) {
        $scope.turn_around = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Turn Around';
        
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/turn-around/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.turnAround) {
                        $scope.turn_around.days = response.result.turnAround.days;
                        $scope.turn_around.name = response.result.turnAround.name || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.add_turn_around = function(turn_around, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                $scope.turn_around.id = id;
                commonFactory.put('/api/turn-around/' + id, $scope.turn_around)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Turn Around updated successfully");
                                $state.go("code_tables.turn_around_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
