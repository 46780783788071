angular.module('deitz').controller('editSourceController',[
	'$state',
  '$rootScope',
	'$scope',
	'$http',
	'codeFactory',
	'Upload',
	'apiUrl',
	'Notification',
	'$uibModal', 
	function($state,
       $rootScope,
			 $scope,
			 $http,
			 codeFactory,
			 Upload,
			 apiUrl,
			 Notification,
			 $uibModal 
       ){ 
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.title= $state.current.data.pageTitle;
        $scope.source={}; 
        $scope.formSubmit = false; 
        $scope.isImage=false;
        $scope.editImage = false;
        $scope.isEdit=true; 
        $id=$state.params.id;  
        $http.post(apiUrl + '/api/edit_source/'+$id+'/edit')
          .success(function(response) { 
              if(response.logo != '' && response.logo != null){
                $scope.imagePreview = "sourceLogos/"+response.logo; 
                $scope.isImage=true; 
              }else{
                $scope.imagePreview = "";
                $scope.isImage=false; 
              }
              $scope.source.hidden = response.logo;
              $scope.source.name = response.name;
              $scope.source.name2 = response.name_2;
              $scope.source.address1 = response.address_1;
              $scope.source.address2 = response.address_2;
              $scope.source.isDefault = response.isDefault; 
              $scope.source.ein = response.ein; 
              $scope.source.entity = response.entity; 
              $scope.source.label = response.label; 
              $scope.source.dba = response.dba; 
              $scope.source.remit_no = response.remit_no; 
              $scope.source.phone = response.phone != null ? response.phone : ''; 
              $scope.source.fax = response.fax != null ? response.fax : '';
              $scope.source.business_unit_id = response.business_unit_id != null ? response.business_unit_id : '';
          }).error(function (err) {
              if (err.error) {
                console.log(err.error)
              }
          });   
        $scope.showFileUpload = function(){ 
            $scope.editImage = true; 
            $scope.isEdit=false; 
        }
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
          $scope.BusinessUnitsList = response.data.result.data;  
          $scope.BusinessUnitsList.unshift({id:'',name:''});
        });
    
        function readURL(input) { 
          if (input) {  
              var reader = new FileReader();
              reader.onload = function (e) {
                  $scope.imagePreview = e.target.result; 
              } 
              $scope.isImage=true; 
              reader.readAsDataURL(input);
          }
        }
        $scope.$watch('source.logo',function(newValue, oldValue){ 
            readURL(newValue);
        });

        $scope.addSource = function(isValid){
        $scope.formSubmit = true; 
        	if(isValid){ 
            $rootScope.showLoader(true);  
        		Upload.upload({
                        url: apiUrl+'/api/update_source/'+$id+'/update',
                        data: {sourceData:$scope.source}
                    }).then(function (resp) { 
                    	if(resp.data.status==200){
                        Notification.success('Source updated Successfully');
                        $state.go('code_tables.source_browse');
                    	}
                    },function(err){
                    	Notification.error(err);
                    });
        	}
        } 
}]);