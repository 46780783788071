angular.module('deitz').controller('additionalChargesMergeController', [
    '$scope',
    '$rootScope',
    'commonFactory',
    'Notification',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$compile',
    '$http',
    'apiUrl',
    function (
        $scope,
        $rootScope,
        commonFactory,
        Notification,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $compile,
        $http,
        apiUrl
    ) {

        $scope.dtInstance = {};

        $scope.compileDatatable = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.additionalChargeData = [];
        $scope.loadingDemo = [];

        $scope.reloadData = function () {
            //$scope.dtInstance.DataTable.draw();
            $scope.dtInstance.rerender();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                commonFactory.showDataTable('/api/additional/merge-charges-log', data).success(function (res) {
                    if (res.error) {
                        $scope.reloadData();
                    } else { 
                        callback(res);
                    }
                }).error(function (err) {
                    if (err.error !== "token_not_provided") {
                        $scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({ 
                "sZeroRecords": "NO LOGS FOUND",
                "sProcessing": "<img src='img/loading_bar.gif'/>"
            })
            .withOption('serverSide', true) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500) 
            .withOption('order', [3, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('merge_charge.DESC').withTitle('Merge (From)').withOption('defaultContent', '').withOption('searchable', true).notSortable().renderWith(function(data, type, full, meta) {
                return data + " | " + full.merge_charge.CHG_CODE;
            }),
            DTColumnBuilder.newColumn('destination_charge.DESC').withOption('defaultContent', '').withTitle('Destination (To)').withOption('searchable', true).notSortable().renderWith(function(data, type, full, meta) {
                return data + " | " + full.destination_charge.CHG_CODE;
            }),
            DTColumnBuilder.newColumn('created_by.name').withTitle('Merged By').withOption('defaultContent', '').withOption('searchable', false).notSortable().renderWith(function(data, type, full, meta) {
                return data;
            }),
            DTColumnBuilder.newColumn('created_at').withTitle('Date').withOption('defaultContent', '').withOption('searchable', false).renderWith(function(data, type, full, meta) {
                return data;
            }),
        ]; 

    }]);
