angular.module('deitz').controller('browseFinancialValuesController', [
    '$scope',
    '$rootScope',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$compile',  
    '$filter',
    '$http',
    'apiUrl',
    'commonFactory',
    'Notification', 
    function (
        $scope,
        $rootScope,
        DTOptionsBuilder,
        DTColumnBuilder,
        $compile,  
        $filter,
        $http,
        apiUrl,
        commonFactory,
        Notification
    ) {

        $scope.dtInstance = {};

        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }else if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }else{
                return '$'+ '0.00';
            }
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                }; 
                options.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                options.selectedBusinessUnitIDs = options.selectedBusinessUnitIDs.length > 0 ? options.selectedBusinessUnitIDs.join(',') : '';
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
               commonFactory.getWithPaginate('/api/financial_values', options)
                .success(function(response) {
                    if (response) {
                        $rootScope.showLoader(false);
                        fnCallback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.count,
                            data: response.result
                        });
                    }
                }) 
            }) 
            .withOption('processing', true)
            .withLanguage({ 
                "sProcessing": "<img src='img/loading_bar.gif'/>",   
            })
            .withOption('serverSide', true)          
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'desc'])
            .withOption('createdRow', $scope.createdRow)  
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('date').withTitle('Month & Year').withOption('searchable', true).renderWith(function(data, type, full, meta){
                return moment(data).format('MM/YYYY');
            }),
            DTColumnBuilder.newColumn('budget_amt').withTitle('Budget').withOption('searchable', true).renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('ar_total').withTitle('A/R Total').withOption('searchable', true).renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('ar_180').withTitle('A/R 180').withOption('searchable', true).renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('business_unit.name').withTitle('Business Unit').notSortable().withOption('searchable', false),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).renderWith(function(data, type, full, meta){
                return  '<a class="btn btn-circle btn-primary" ui-sref="code_tables.financial_values_edit({id:'+full.id+'})"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp'+
                        '<a class="btn btn-circle btn-danger" ng-click="delete('+full.id+')" ng-if="havePermission(\'financial_values\',\'delete\')"><i class="fa fa-trash"></i></a>';
            }).notSortable()
        ];


        $scope.delete = function(budget_id){
                swal({
                        title: "Are you sure ?",
                        text: "you want to delete",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        
                        if (isConfirm) {

                            $rootScope.showLoader(true);
                            commonFactory.delete('/api/financial_values/'+budget_id).success(function (res) {
                                $rootScope.showLoader(false); 
                                if(res.error){

                                    Notification.error(res.result.message);    
                                }else{

                                    Notification.success(res.result.message);
                                
                                }
                                $scope.reloadData();
                            }).error(function (err) {
                                $rootScope.showLoader(false);
                                Notification.error(err.result.message);
                                $scope.reloadData(); 
                            });
                        }
                    });
            }
    }]);

