angular.module('deitz')
    .controller('timeOffsController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'commonFactory',
        '$compile',
        '$state',
        '$uibModal',
        '$window',
        'Notification',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            commonFactory,
            $compile,
            $state,
            $uibModal,
            $window,
            Notification,
        ) {
            $scope.downloadExcel = false;
            $scope.dtInstance = {};
            $scope.title = $state.current.data.pageTitle;
            $scope.selectedReporters = [];
            $scope.timeOffRecords;
            $scope.resetSearch = function () {
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            };

            $scope.report = {
                dateRange: {
                    endDate: moment().format('MM-DD-YYYY'),
                    startDate: moment().format('MM-DD-YYYY')
                }
            }
            
            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if (selectedBusinessUnitIDs && selectedBusinessUnitIDs.length) {
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            } else {
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                    $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }

            $scope.reporterList = function(val) {
                return $http.get(apiUrl + '/api/listRepByType', {
                    params: {
                        search: val,
                        rep_typ: 'R'
                    }
                }).then(function(response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            
            $scope.conflictsJobParamsModal = function(id){
                var result = $.grep($scope.timeOffRecords, function(e){ return e.id == id; });
                $rootScope.showLoader(true);
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/representative/addreporter/conflicts_jobs.html',
                    controller: function($scope, params, $uibModalInstance){
                        $scope.modal_title = 'Conflicted Jobs';
                        $scope.diaries = {};
                        if(params.length > 0){
                            $scope.diaries = params[0].scheduled_job
                        }
                        $scope.cancel = function(isChanged) {
                            $uibModalInstance.dismiss({ isChanged : isChanged});
                        };
                    },
                    resolve : {
                        params : function(){
                            return result;
                        }
                    },
                    size: 'md',
                });
                modalInstance.opened.then(function () {
                    $rootScope.showLoader(false);
                });
            };

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };
            
            $scope.createdRow = function (row, data, dataIndex) {
                $compile(angular.element(row).contents())($scope);
            };

            $scope.downloadExcelFile = function () {
                $scope.downloadExcel = true;
                $scope.reloadData();
            }

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); $scope.inProcess = true;
                    data.start_date = moment($scope.report.dateRange.startDate).format('YYYY-MM-DD');
                    data.end_date = moment($scope.report.dateRange.endDate).format('YYYY-MM-DD');
                    data.downloadExcel = $scope.downloadExcel;
                    data.reporterIds = $scope.selectedReporters.map(function(a){
                        return  a.id;
                    });

                    commonFactory.showDataTable('/api/get-timeoff-report', data).success(function (res) {
                        if (!res.error) {
                            $rootScope.showLoader(false);
                            if ($scope.downloadExcel) {
                                $window.open(apiUrl + '/api/download-timeoff-report', '_blank');    
                                $scope.downloadExcel = false;
                            }
                            // else{
                                callback(res);
                                $scope.timeOffRecords = res.data;
                            // }
                        }
                    }).error(function (err) {
                        Notification.error(err.error)
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO TIMEOFF AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ TIMEOFFS",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 TIMEOFFS",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL TIMEOFFS)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ TIMEOFFS",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING TIMEOFFS FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                })
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('lengthMenu', [
                    [10, 25, 50, 100, 200, 250, 300, 500],
                    [10, 25, 50, 100, 200, 250, 300, 500]
                ])
                .withOption('searching', false)
                .withOption('searchDelay', 500)
                .withOption('order', [1, 'asc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                });

            $scope.dtColumns = [];

            $scope.dtColumns.push(            
                DTColumnBuilder.newColumn('reporter.FIRST_NAME').withTitle('Resource Name').withOption('searchable', true).renderWith(function(data,type,full,meta){
                    return '<a target="_blank" uib-tooltip="Edit" ui-sref="representative.reporter.step_one({id : ' + full.reporter.id + '})" >' +full.reporter.name +'</a>';
                }),
            
                DTColumnBuilder.newColumn('startdate').withTitle('Start Date').withOption('searchable', true),

                DTColumnBuilder.newColumn('enddate').withTitle('End Date').withOption('searchable', true),
                
                DTColumnBuilder.newColumn('notes').withTitle('Notes / Reason').withOption('searchable', true),

                DTColumnBuilder.newColumn('totalperiod').withTitle('Duration').withOption('searchable', true).notSortable(),

                DTColumnBuilder.newColumn('scheduled_job').withTitle('Conflict').withOption('searchable', false).notSortable().renderWith(function(data,type,full,meta){
                    html = '';
                    if(full.scheduled_job.length > 0){
                        html += '<a style="background: bisque;padding: 5px;border-radius: 5px;" ng-click="conflictsJobParamsModal('+full.id+')" class="ng-binding ng-scope">'+full.scheduled_job.length+ ' JOB(S) CONFLICTED</a>';
                    }
                    return html;
                }),
            );
        }
    ]);
