angular.module('deitz').controller('knowledgebaseController', [
    '$scope', '$rootScope', '$http', 'apiUrl','Upload', 'Notification', '$timeout', '$stateParams', '$state', 'configFactory', 'commonFactory','codeFactory', '$window',
    function (
        $scope, $rootScope, $http, apiUrl, Upload, Notification, $timeout, $stateParams, $state, configFactory, commonFactory,codeFactory, $window
    ) {

        $scope.formData = {};
        $scope.guid = {};    

        $rootScope.showLoader(true);      
      
        $scope.clickUpload = function () {
            $("#knowledgebase_doc_name").trigger('click');
        }

        $scope.fileChange = function(){           
            var files = $('#knowledgebase_doc_name')[0].files[0];
            if(files && files.name){
                $(".upload_h1").html(files.name+" file selected. <br> press the 'save' button.");
            }else{
                $(".upload_h1").html("Drag and Drop file here<br/>Or<br/>Click to select file");
            }
        }

        $scope.addguide = function(guid,isValid){              
            $scope.formSubmit = true;
             if($scope.guid.knowledgebase_doc_name){  
              $rootScope.showLoader(true);
              Upload.upload({
                            url: apiUrl+'/api/save-knowledgebase-guide-data',
                            data: $scope.guid
                        }).then(function (resp) {  
                            $('#guidSetupForm')[0].reset();  
                            $scope.fileChange();   
                                                
                            if(resp.data.status == 200){                                
                                $rootScope.showLoader(false);
                                $scope.guid.knowledgebase_doc_name = '';                            
                                Notification.success('Knowledge Base Guide PDF Uploaded Successfully');
                                $state.go('index.knowledgebase-guide');
                                $scope.getknowledgebase();   
                            }else{                               
                                $rootScope.showLoader(false); 
                                Notification.error('Something went wrong!'); 
                            }
                        },function(err){
                            $rootScope.showLoader(false); 
                            Notification.error('Something went wrong!');                      
                        });
            }else{        
                $('#guidSetupForm')[0].reset();  
                $scope.fileChange();
                $scope.getknowledgebase();
                    if($scope.guidSetupForm.knowledgebase_doc_name.$invalid){
                        Notification.error('Please Upload PDF File Only.');
                    }else{
                        Notification.error('File must be required.');
                    }
                }            
        }

        $scope.removeknowledgebase = function(){

            swal({
                title: "Are you sure want to remove this File?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Do it!",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                $http.get(apiUrl + '/api/remove-knowledgebase-guide-data').then(function(response) { 
                    $rootScope.showLoader(false);
                    $scope.getknowledgebase();
                    if(response.data.status){
                        Notification.success('Removed Knowledge Base Guide PDF Successfully.');
                    }else{
                        Notification.error('File Not Exist.'); 
                    }
                });  
            }
        });
    }


        $scope.getknowledgebase = function(){
        $http.get(apiUrl + '/api/get-knowledgebase-guide-data',{
            params: {
                fileexist: true
            }
            }).then(function(response) { 
            $rootScope.showLoader(false);
            if(response.data.status){
                $scope.isfilepathexist = true;
                $scope.filename = response.data.result;            
                
            }else{
                $scope.isfilepathexist = false;
                $scope.filename = '';
            }
        });
    }

    $scope.getknowledgebase();


    }
]);