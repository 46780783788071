angular.module('deitz').controller('addtimezonesController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.timezones = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Timezone';
        $scope.isEdit = false;

        $scope.addTimezones = function(timezones, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/timezones', $scope.timezones)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("Timezone added successfully");
                                $state.go("code_tables.timezones_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
