angular.module('deitz')
    .factory('loginFactory', ['$http', 'apiUrl', '$cookies', function ($http, apiUrl, $cookies) {

        var loginFactory = {};

        //Include validation helper
        var vh = validation_helper();

        loginFactory.validations = {
            email: {
                patterns: vh.email.patterns,
                maxlength: vh.email.maxlength,
                required: vh.email.required,
            },
            password: {
                patterns: vh.password.patterns,
                minlength: vh.password.minlength,
                maxlength: vh.password.maxlength,
                required: vh.password.required
            },
            password_confirmation: {
                required: true
            }
        };

        var passwordPatternMessage = "<h5>Your password should include:</h5>";
            passwordPatternMessage += "<ul>";
                passwordPatternMessage += "<li>At least 10 characters</li>";
                passwordPatternMessage += "<li>At least one number</li>";
                passwordPatternMessage += "<li>At least one upper case letter</li>";
                passwordPatternMessage += "<li>At least one lower case letter</li>";
                passwordPatternMessage += "<li>At least one special character</li>";
                passwordPatternMessage += "<li>You may not re-use passwords</li>";
            passwordPatternMessage += "</ul>";

        loginFactory.messages = {
            email: {
                patterns: "Please enter valid email.",
                required: "Email is required.",
                maxlength: "Email Field not be upto 254 characters."
            },
            password: {
                patterns: passwordPatternMessage,
                minlength: "Password field must be at least 10 characters.",
                required: "Password is required."
            }
        };

        loginFactory.login = function (data) {
            return $http.post(apiUrl + "/api/login", data);
        };
        loginFactory.loginSaml = function (data) {
            return $http.post(apiUrl + "/api/login_saml", data);
        };
        loginFactory.logout = function (data) {
            return $http.post(apiUrl + "/api/logout", data);
        };

        loginFactory.forgotPassword = function (data) {
            return $http.post(apiUrl + "/api/password/email", data);
        };

        loginFactory.resetPassword = function () {
            return $http.post(apiUrl + "/api/password/reset/{token}/{email}");
        };

        loginFactory.updatePassword = function (data) {
            return $http.post(apiUrl + "/api/password/reset", data);
        };

        loginFactory.refreshToken = function () {
            // console.log("loginfactory", $cookies.get("token"));
            return $http.post("api/checktokenexists", {}, {
                headers: {
                    Authorization: $cookies.get("token")
                }
            });

            //return $http.get("api/checktokenexists");
        }

        /**
         * Check Permission of Current User
         */
        loginFactory.checkPermission = function (permission) {
            return $http.post(apiUrl + "/api/check-permission", { permission: permission });
        }


        loginFactory.checkLogin = function () {
            return $http.get(apiUrl + "/api/check-for-login");
        }

        return loginFactory;
    }]);
