angular.module('deitz').controller('editServiceStatusController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.service_status = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Service Status';
        
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/service-status/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.service_status) {
                        $scope.service_status.name = response.result.service_status.name || '';
                        $scope.service_status.sort_order = response.result.service_status.sort_order || '';
                        $scope.service_status.service_type_ids = []
                        if(response.result.service_status.service_type.length){
                           
                           
                            
                            $scope.service_status.service_type_ids = []
                            inx = 0
                            response.result.service_status.service_type.forEach(element => {
                                $scope.service_status.service_type_ids[inx] =element.service_type_id
                                inx++
                            });
                            
                            
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }
        commonFactory.get('/api/service-type-list').then(function(response) { 
            $scope.service_type_list = response.data.result.data;  
        });
        $scope.add_service_status = function(service_status, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/service-status/' + id, $scope.service_status)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Service Status updated successfully");
                                $state.go("code_tables.service_status_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
