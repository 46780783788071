angular.module('deitz')
            .controller('notMarkedJobsController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$uibModal',
                    'Notification',
                    '$filter',
                    '$timeout',
                    '$q',
                    'SweetAlert',
                    '$state',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $uibModal,
                            Notification,
                            $filter,
                            $timeout,
                            $q,
                            SweetAlert,
                            $state
                        ) {

        $scope.totalRecords = 0;       
        $scope.lookingFor = $state.params.lookingFor && $state.params.lookingFor == 'audio' ? 'audio' : 'reporter';
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive','V':'Videographer'};

        $scope.report = {
            startDate : null,
            endDate : null
        };
        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
            $scope.JobStatusList.unshift({name:'All'});
        });
        $http.get(apiUrl + '/api/get-biller-list').success(function(response) {
            $scope.BillerList=response.result
        });
        $scope.selectedBillerIDs = [];
        $scope.selectedJobStatusIDs = [];

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
    
        $scope.assignmentTypes = [
            {'type':'','name':'-- ALL JOBS --'},
            {'type':'audio','name':'AUDIO JOBS'},
            {'type':'geico','name':'GEICO JOBS'},
            {'type':'reporter','name':'RESOURCE JOBS'} 
        ];
        
        /* Notes browse datatable*/
        $scope.dtInstance = {};

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.lookingFor = 'reporter';
            $scope.report = {
                startDate : null,
                endDate : null
            };
            $scope.reloadData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to
                data.assignmentType = $scope.lookingFor;
                data.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
                data.startDate = data.startDate == 'Invalid date' ? null : data.startDate;
                data.endDate = moment($scope.report.endDate).format('YYYY-MM-DD');
                data.endDate = data.endDate == 'Invalid date' ? null : data.endDate;
                data.selectedJobStatusIDs = $scope.selectedJobStatusIDs.filter(Boolean);
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                data.selectedBillerIDs = $scope.selectedBillerIDs.filter(Boolean);  
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));

                commonFactory.post('/api/notMarkedReadyToInvoice', data)
                    .success(function(response) {
                        if (response.error) {
                            $scope.reloadData();
                        }
                        else {
                            $scope.totalRecords = response.recordsFiltered;
                            callback(response);
                        }
                    })
                    .error(function(err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadData();
                        }
                    });

            })
            .withOption('processing', true)
            .withLanguage({

                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [10, 25, 50, 100, 200, 250, 300, 500],
                [10, 25, 50, 100, 200, 250, 300, 500]
            ])
            .withOption('order', [2, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtColumns = [];

        $scope.dtColumns.push(

            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job #').withOption('searchable', true).withOption("width", "6%").renderWith(function(data,type,full,meta){
                return '<span style="padding:5px 10px 7px 10px;font-size:14px;"><a target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'</a></span>'
            }),
            DTColumnBuilder.newColumn('TIME_TAKEN').withOption('defaultContent', '').withTitle('Job Time').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('timezone').withOption('defaultContent', '').withTitle('Timezone').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('DATE_TAKEN').withOption('defaultContent', '').withTitle('Job Date').withOption('searchable', true).withOption("width", "5%").renderWith(function(data, type, full, meta){
                if(data){
                    return moment(data, 'YYYY-MM-DD').format('MM/DD/YYYY');
                }
                return '<center>-</center>';
            }),
            DTColumnBuilder.newColumn('job_status_name').withTitle('Job Status').withOption('searchable', false),
            DTColumnBuilder.newColumn('client_level').withOption('defaultContent', '').withTitle('Client Level').withOption('searchable', false).withOption("width", "5%").renderWith(function(data, type, full, meta){
                if (data) {
                    var client_level = data.substring(0, 8);
                    return "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>"+client_level+"</div>";
                    
                } else {
                    return '<center>-</center>';
                }
            }),
            DTColumnBuilder.newColumn('FIRM_SHORT_NAME').withOption('defaultContent', '').withTitle('Scheduling Firm').withOption('name', 'ATTORNEY_tbl.SHORT_NAME').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var attorney_firm = "<div uib-tooltip='"+full.FIRM_NAME+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                    return attorney_firm;
                } else {
                    return '<center>-</center>';
                }
            }),
            DTColumnBuilder.newColumn('CAPTION').withOption('defaultContent', '').withTitle('Caption').withOption('name', 'CASE_tbl.CAPTION').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var caption = "<div uib-tooltip='"+data+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                        caption += '<i style="color: black;">';
                        full.audio_files.forEach(function(item){
                            if(item.filetype == "mp3" || item.filetype == "mp4"){
                                caption += '<h5>'+item.duration +' - '+ $scope.niceBytes(item.filesize)+'</h5>';
                            }
                        });
                        caption +='<i>';
                        if(full.IndexNumber){
                            caption += "<a href='{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=display_on_elaw.php?indexNumber="+full.IndexNumber+"' target='_blank' title='Display On Elaw'>Elaw</a></div>";
                        }
                    return caption;
                } else {
                    return '<center>-</center>';
                }

            }),
            DTColumnBuilder.newColumn('euo_statement_of').withTitle('EUO / Statement').withOption('defaultContent', '').withOption('searchable', false).notSortable().withOption("width", "5%").renderWith(function(data, type, full, meta){
                if(data){
                    return  '<a href="javascript:void(0);" uib-tooltip="Toggle" ng-click="toggleJobType('+full.JOB_NO+',\''+data+'\')">'+data+'</a>';
                }
            }),
            DTColumnBuilder.newColumn('bu_name').withOption('defaultContent', '').withTitle('Business Unit').withOption('name', 'original_bu.name').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var caption = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                    return caption;
                } else {
                    return '<center>-</center>';
                }
            }),
            DTColumnBuilder.newColumn('cbu_name').withOption('defaultContent', '').withTitle('Covering Business Unit').withOption('name', 'covering_bu.name').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var caption = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                    return caption;
                } else {
                    return '<center>-</center>';
                }
            }),
            DTColumnBuilder.newColumn('HELD_AT').withOption('defaultContent', '').withTitle('Job Location').withOption('name','DIARY_tbl.HELD_AT1').withOption('searchable', true).withOption("width", "10%").renderWith(function(data, type, full, meta) {
                if (data) {
                    data = escapeString(data);
                    var HELD_AT = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:30}}</div>";
                    return HELD_AT;
                } else {
                    return '<center>-</center>';
                }
            }),
            DTColumnBuilder.newColumn('resources').notSortable().withOption('defaultContent', '').withTitle('Resources').withOption('name','resources.FIRST_NAME').withOption('searchable', true).withOption("width", "10%").withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){
                
                html='';
                data.forEach(resource => {
                    if(resource){
                        rep = escapeString(resource.name);
                        html+= "<p  uib-tooltip='"+rep+ "-"+ $scope.reporterType[resource.REP_TYP]+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+rep+"' | limitTocustom:20}}</p>";
                    }else{
                        html+= '<center>-</center>';
                    }
                });

                full.resource_typists.forEach(resource => {
                    if(resource){
                        rep = escapeString(resource.name);
                        html+= "<p  uib-tooltip='"+rep+ "-"+ $scope.reporterType[resource.REP_TYP]+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+rep+"' | limitTocustom:20}}</p>";
                    }else{
                        html+= '<center>-</center>';
                    }
                });
            
                return html;
                
            }),
            /*DTColumnBuilder.newColumn('reporter_name').withOption('defaultContent', '').withTitle('REPORTER').withOption('name','reporter.FIRST_NAME').withOption('searchable', true).withOption("width", "10%").withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){
                if(data){
                    rep = escapeString(data);
                    return "<div  uib-tooltip='"+rep+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+rep+"' | limitTocustom:20}}</div>";
                }else{
                    return '<center>-</center>';
                }
            }),
            DTColumnBuilder.newColumn('typist_name').withOption('defaultContent', '').withTitle('TYPIST').withOption('name','typist.FIRST_NAME').withOption('searchable', true).withOption("width", "10%").withOption('class', 'reporter-remove-td').renderWith(function(data, type, full, meta){

                if(data){
                    typ = escapeString(data);
                    return "<div  uib-tooltip='"+typ+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+typ+"' | limitTocustom:13}}</div>";
                } else{
                    return '<center>-</center>';
                }
            }),*/
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption("width", "5%").notSortable().renderWith(function (data, type, full, meta) {
                
                var action = '<div class="btn-group btn-sm" uib-dropdown>' +
                        '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                        '<ul role="menu" uib-dropdown-menu="" style="left: -100px!important;">'+
                            '<li uib-tooltip="Edit Job">'+
                                '<a class="btn btn-xs btn-default" ui-sref="diary.editcase.step_one({id: '+ full.JOB_NO +'})" target="_blank"><i class="fa fa-pencil"></i> &nbsp; Edit Job</a>'+
                            '</li>'+    
                            '<li uib-tooltip="Bill & Pay">'+
                                '<a class="btn btn-xs btn-default" ui-sref="invoice.create({job_id: '+ full.JOB_NO +'})" target="_blank"><i class="fa fa-money"></i> &nbsp; Bill & Pay </a>'+
                            '</li>'+
                        '</ul>'+
                    '</div>';

                return action;

            })
        );         
    
        $scope.toggleJobType = function(JOB_NO, euo_statement_of){
            
            SweetAlert.swal({
                title: "Are you sure want to change?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Change it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function(callback){
                if(callback){
                    $rootScope.showLoader(true);
                    return $http.post(apiUrl + '/api/toggle-job-type', {JOB_NO : JOB_NO, euo_statement_of : euo_statement_of})
                    .then(function(response) {
                        $scope.reloadData();
                        $rootScope.showLoader(false);
                    });
                }
            });
        };

        $scope.niceBytes = function (x){
            
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if(x === 1) return '1 byte';

              let l = 0, n = parseInt(x, 10) || 0;

              while(n >= 1000 && ++l){
                  n = n/1000;
              }
              //include a decimal point and a tenths-place digit if presenting
              //less than ten of KB or greater units
              return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        };
    }
]);
