angular.module('deitz')
    .controller('elawController', ['$scope', '$rootScope', 'Upload', 'Notification', '$timeout', function($scope, $rootScope, Upload, Notification, $timeout) {

        $scope.formData = {};

       /* $scope.validations = loginFactory.validations;
        $scope.messages = loginFactory.messages;*/
        $scope.formSubmit = false;
        $scope.invalidFileType = 'false';
        $scope.log = "";

        $scope.submitForm = function(isValid) {
            $scope.formSubmit = true;
            if (isValid && $scope.importElawForm.lawFile.$valid && $scope.lawFile) {
                var tmpName = $scope.lawFile.name.split('.');
                var fileType = tmpName[tmpName.length-1];
                if(fileType != 'xls' && fileType != 'xlsx'){
                    $scope.invalidFileType = 'true';
                } else{

                    $scope.log = "Uploading file...";
                    var loading = $timeout(function(){
                        $scope.log += "<br> Saving case information... ";
                        $scope.$apply();
                    }, 5000);
                    $rootScope.showLoader(true);
                    $scope.invalidFileType = 'false';

                    Upload.upload({
                        url: 'api/elaw/import',
                        data: {elawFile: $scope.lawFile}
                    }).then(function (resp) {
                        console.log('Success uploaded. Response: ' + resp.data);
                        $timeout.cancel(loading);
                        $scope.importElawForm.$setPristine(true);
                        $scope.importElawForm.$setUntouched(true);
                        $scope.lawFile = "";
                        $rootScope.showLoader(false);

                        $scope.log += "<br><br> <b> E-law data has been imported successfully. </b> ";


                        Notification.success(resp.data.message);
                    }, function (resp) {
                        $timeout.cancel(loading);
                        console.log('Error: ', resp);
                        Notification.error("Error while importing E-law data!");
                        $rootScope.showLoader(false);

                        $scope.log += "<br><br> <b> Error while importing E-law data. Please try again. </b> ";

                    }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + progressPercentage + '% ');
                    });
                }

            }
        };
    }]);