
angular.module('deitz').controller('notarywitnessController', [
    '$scope','$sce', '$rootScope', 'diaryFactory', 'commonFactory', '$uibModal', 'witness_value', 'index', 'witness_data', '$uibModalInstance', '$http', 'apiUrl',
    function(
        $scope, $sce,$rootScope, diaryFactory, commonFactory, $uibModal, witness_value, index, witness_data, $uibModalInstance, $http, apiUrl
    ){
        $scope.witness
        $scope.Witnesstitle = "Notary Detail";
        commonFactory.get('/api/editwitnesstodiary/' + witness_data[index].id)
        .success(function (response) {
            $scope.witness = response.result.witness;
            console.log($scope.witness);
            console.log($scope.depoHost,  response.result.witness.verify_document_data.document_field_list);

        })
        .error(function (err) {
        });
        $scope.depoHost =$rootScope.depoHost
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.trustUrl =function(Url) {
            console.log(Url,'trusted');
            return $sce.trustAsResourceUrl(Url);
        };

    }]);
