angular.module('deitz')
    .controller('mergeInsuranceCompanyController', [
        '$scope',
        '$rootScope',
        '$state',
        'firmFactory',
        'Notification',
        '$stateParams',
        'apiUrl',
        '$http',
        'commonFactory',
        function(
            $scope,
            $rootScope,
            $state,
            firmFactory,
            Notification,
            $stateParams,
            apiUrl,
            $http,
            commonFactory
            ) {

            $scope.title = 'Merge Insurance Company(s)';
            $scope.mergeInsuranceCompany = {};
            $scope.isdeleteInsuranceCompanySelected = false;
            $scope.iskeepInsuranceCompanySelected = false;
            $scope.submitted = false;
            $scope.deleteInsuranceCompanyCodes = [];
            $scope.keepInsuranceCompanyCodes = '';
            
            $scope.currencyFormat = function(amt){
                if(amt == null){
                    return '$0.00';
                }
                if(parseFloat(amt) >= 0){
                    return '$'+parseFloat(amt).toFixed(2);
                }
                if(parseFloat(amt) < 0){
                    return '-$'+ parseFloat(Math.abs(amt)).toFixed(2);
                }
            };

            $scope.insuranceCompanyData = function (val) {
                return $http.get(apiUrl + '/api/insuranceCompanySearchByName', {
                    params: {
                        name: val,
                        selectedInsuranceCompanyId : $scope.selectedInsuranceCompanyId
                    }
                }).then(function (response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            $scope.clearfield = function (key){
                  
                if(key == 'keepInsuranceCompany' && !$scope.iskeepInsuranceCompanySelected){
                    $scope.mergeInsuranceCompany.keepInsuranceCompany = null;
                }
                if(key == 'deleteInsuranceCompany' && !$scope.isdeleteInsuranceCompanySelected){
                    $scope.mergeInsuranceCompany.deleteInsuranceCompany = null;
                }
             

            };
            $scope.resetfield = function (key){
                if(key =='keepInsuranceCompany'){ 

                    $scope.iskeepInsuranceCompanySelected = false;
                    $scope.keepInsuranceCompanyId = null; 
                    $scope.keepInsuranceCompanyCodes = ''; 

                }else{     

                    $scope.isdeleteInsuranceCompanySelected = false; 
                    $scope.deleteInsuranceCompanyId = null;  
                    $scope.deleteInsuranceCompanyCodes = [];
                
                }

                if($scope.keepInsuranceCompanyId){

                    $scope.selectedInsuranceCompanyId = $scope.keepInsuranceCompanyId; 
                
                }else if($scope.deleteInsuranceCompanyId){
                
                    $scope.selectedInsuranceCompanyId = $scope.deleteInsuranceCompanyId;
                
                }else{
                
                    $scope.selectedInsuranceCompanyId = null;
                
                }

            };
            //reset form
            $scope.resetForm = function(){
                $scope.iskeepInsuranceCompanySelected = false;
                $scope.keepInsuranceCompanyId = null;  
                $scope.mergeInsuranceCompany.keepInsuranceCompany = null;
                $scope.isdeleteInsuranceCompanySelected = false; 
                $scope.deleteInsuranceCompanyId = null; 
                $scope.mergeInsuranceCompany.deleteInsuranceCompany = null;
                $scope.deleteInsuranceCompanyCodes = [];
                $scope.keepInsuranceCompanyCodes = '';
                $scope.mergeInsuranceCompanyForm.$setPristine(true);
                $scope.mergeInsuranceCompanyForm.$setUntouched(true);
            } 
            $scope.onSelectInsuranceCompany = function ($item, $model, $label, $event,firmType) {

                if(firmType =='keepInsuranceCompany'){
                    $scope.mergeInsuranceCompany.keepInsuranceCompany = angular.copy($item);
                    $scope.iskeepInsuranceCompanySelected = true;
                    $scope.keepInsuranceCompanyId = $scope.mergeInsuranceCompany.keepInsuranceCompany.id;
                }else{
                    $scope.mergeInsuranceCompany.deleteInsuranceCompany = angular.copy($item);    
                    $scope.isdeleteInsuranceCompanySelected = true; 
                    $scope.deleteInsuranceCompanyId = $scope.mergeInsuranceCompany.deleteInsuranceCompany.id;
                    angular.forEach($scope.mergeInsuranceCompany.deleteInsuranceCompany.claim_rep,function(value, key){
                        $scope.deleteInsuranceCompanyCodes = $scope.deleteInsuranceCompanyCodes.concat(value.CR_Beeper);
                    });
                }
                
            };
  
            $scope.mergeInsuranceCompany = function(isValid){ 
                $scope.submitted = true;
                if(isValid){

                    swal({
                            title: "MERGE THESE INSURANCE COMPANIES - ARE YOU SURE?",
                            text: "<span style='color:#e08639;'>Warning</span> - The Insurance merge program should be run during off hours. While it is running, some invoice totals may not be calculated correctly if other people are doing billing at the same time. Would you like to continue?",
                            type: "warning",
                            html: true,
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No, Cancel this merge",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) { 
                                $rootScope.showLoader(true);  
                                var data = {}
                                data.deleteInsuranceCompany =  $scope.mergeInsuranceCompany.deleteInsuranceCompany || null;
                                data.keepInsuranceCompany = $scope.mergeInsuranceCompany.keepInsuranceCompany || null;
                                data.deleteInsuranceCompanyCodes =  $scope.deleteInsuranceCompanyCodes || null;
                                data.keepInsuranceCompanyCodes = $scope.keepInsuranceCompanyCodes || null;
                                data.isManuallyInputCode = $scope.mergeInsuranceCompany.keepInsuranceCompany.claim_rep.length ? false: true;
                                firmFactory.post('/api/merge-insurance-companys',data).success(function (response) {
                                     
                                    $rootScope.showLoader(false);
                                    $scope.resetForm();  
                                    if(response.result.status == 200){
                                        Notification.success(response.result.message); 
                                    }else{
                                        Notification.error(response.result.message);
                                    } 
                                    
                                }).error(function (error) {
                                    $rootScope.showLoader(false);
                                    Notification.error(error.message);
                                });
                            }
                        });
                }
            }
            $scope.authMergeInsuranceCompany = function(){  
                if($state.current.name == 'insurance.merge_insurance_company'){
                    return false;
                }else if($rootScope.havePermission('allow_access_without_password','allow.insurance.company.merge')){
                    $state.go('insurance.merge_insurance_company');
                }else{
                
                    swal({
                        title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                        type: "input",
                        inputType: "password",
                        showCancelButton: true,
                        closeOnConfirm: true
                    }, function(inputValue) { 
                        if (inputValue === false){
                            return false;
                        } 
                        if (inputValue === "") {
                            Notification.error("You need to write something!");
                            return false
                        }
                        else {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                                .success(function(response) {
                                    if (response['result']) {
                                        if (response['result']['auth']) {

                                            $rootScope.showLoader(false);
                                            $state.go('insurance.merge_insurance_company');
                                            
                                        }else {
                                            $rootScope.showLoader(false);
                                            Notification.error("Error ! Wrong Password");
                                        }
                                    }else{
                                        $rootScope.showLoader(false);
                                        Notification.error("Oops ! Something went wrong");
                                    }
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                });

                        }
                    });
                }        
            } 

        }]);