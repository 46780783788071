angular.module('deitz').controller('accountCreditsReportController', 
		['$scope',
		 '$state',
		 '$http',
		 'apiUrl',
		 '$rootScope',
		 'Notification', 
		 '$filter',
		 'commonFactory',
		 function($scope,
		 		  $state,
		 		  $http, 
		 		  apiUrl, 
		 		  $rootScope, 
		 		  Notification,
		 		  $filter,
		 		  commonFactory
		 		  ){

	$scope.searchingAtt = false;
	$scope.selectedAttorneys = [];
	$scope.accountCreditsData = [];
	$scope.searchKeyword = '';
	//Getting Attorneys list
    $scope.attorneysList = function(val) {
        $scope.searchingAtt = true;
        return $http.get(apiUrl + '/api/attorneySearchByName', {
            params: {
                name: val
            }
        }).then(function(response) {
            $scope.searchingAtt = false;
            return response.data.result.map(function(item) {
                return item;
            });
        });
    };
    $scope.tagRemoved = function(){
    	$scope.accountCreditsData = [];
    }
    $scope.getReportData = function(){
        $scope.accountCreditsData = [];
    	$scope.searchKeyword = '';
		$rootScope.showLoader(true);

		var ids = [];            
        angular.forEach($scope.selectedAttorneys, function(value, key) {
           ids[key] = value.id;
        });
      
		$http.post(apiUrl+'/api/get-account-credits-report',{ids : ids}).success(function(res){
	    	$rootScope.showLoader(false);
	    	if(res.error || res.result.data.length == 0){
	    		Notification.error(res.result.message);
	    	}else{
	        	$scope.accountCreditsData = res.result.data;
	    	}
	    });	
    	
    }
    $scope.getTransactionHistory = function(data){
   
    	var str = '';	
        if (data.transaction_type === 'over_paid') {
            str = 'Over Paid ';
        }else if(data.transaction_type === 'batch_over_paid'){
        	str = 'Batch Over Paid';
        }else if(data.transaction_type === 'batch_under_paid'){
        	str = 'Batch Under Paid';
        }else if (data.transaction_type === 'credit_used') {
            str = "On account $" + $filter('number')(Math.abs(data.amt), 2) + " from <strong>" + data.org_inv_no_used + "</strong> used to pay <strong>" + data.original_invoice_no + "</strong>";
        }else if (data.transaction_type === 'batch_credit_used') {
            str = "On account $" + $filter('number')(Math.abs(data.amt), 2) + " from Batch payment <strong>["+data.batch_no_used+"]</strong> used to pay <strong>" + data.original_invoice_no + "</strong>";
        } else if (data.transaction_type === 'refund') {
            str = "<strong style='color:#ed5565'>Refund </strong>";
        }
        else {
            return '-'
        }

        if(data.check_number){
            str += " [<strong> CHK/REF: "+ data.check_number+ "<strong>]";
        }
        return str;
    }

    if($state.params.attId){
        $rootScope.showLoader(true);
        commonFactory.get('/api/attorneys/'+$state.params.attId)
            .success(function(response) {
            $rootScope.showLoader(false);
            $scope.selectedAttorneys.push(response.result.data);
            $scope.getReportData();
        })
        .error(function(err) {
            $rootScope.showLoader(false);

        });
    }


}]);