angular.module('deitz')
    .controller('mergeAttorneysController', [
        '$scope',
        '$rootScope',
        '$state',
        'firmFactory',
        'Notification',
        '$stateParams',
        'apiUrl',
        '$http',
        'commonFactory',
        function(
            $scope,
            $rootScope,
            $state,
            firmFactory,
            Notification,
            $stateParams,
            apiUrl,
            $http,
            commonFactory
            ) {

            $scope.title = 'Merge Attorneys';
            $scope.mergeAttorneys = {}; 
            $scope.submitted = false; 

            $scope.attorneyofficedata = function (val) {
                return $http.get(apiUrl + '/api/getattorneyfirm', {
                    params: {
                        name: val,
                        fromMergeAttorney : true
                    }
                }).then(function (response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };
            $scope.clearfield = function (key){
                  
                if(!$scope.isAttorneyFirmSelected){
                    $scope.mergeAttorneys = {};
                    $scope.attorney = [];
                }
             
            };
            $scope.resetfield = function (key){
                if($scope.isAttorneyFirmSelected){ 
                    $scope.isAttorneyFirmSelected = false;
                }

            };
            //reset form
            $scope.resetForm = function(){
                $scope.isAttorneyFirmSelected = false;
                $scope.mergeAttorneys = {};
                $scope.attorney = [];
            } 
            
            $scope.onSelectAttorneyFirm = function ($item, $model, $label, $event) {
                $scope.isAttorneyFirmSelected = true;
                $scope.mergeAttorneys.selectedFirm = angular.copy($item); 

                var data = { firm_id: $scope.mergeAttorneys.selectedFirm.id }
                $http.post(apiUrl + '/api/attorneybyfirm', data).success(function (response) {    
                        if (response && response.result && response.result.attorney_data) {
                            if(response.result.attorney_data.length){
                                $scope.attorney = response.result.attorney_data;
                            }else{
                                $scope.attorney = [];
                                Notification.error('There is no attorney found for this firm!');
                            }

                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
                
            };
  
  
            $scope.mergeAttorneySubmit = function(isValid){ 
                $scope.submitted = true;
                if(isValid && $scope.mergeAttorneys.selectedFirm.id && $scope.mergeAttorneys.deleteAttorneyId && $scope.mergeAttorneys.keepAttorneyId){
                    if($scope.mergeAttorneys.deleteAttorneyId == $scope.mergeAttorneys.keepAttorneyId){
                        swal ("Oops" , "You have selected the same attorney for both choices. Please select different attorneys.", "error");
                        return false;
                    }

                    swal({
                            title: "MERGE THESE ATTORNEYS - ARE YOU SURE?",
                            text: "<span style='color:#e08639;'>Warning</span> - The Attorney merge program should be run during off hours. While it is running, some invoice totals may not be calculated correctly if other people are doing billing at the same time. Would you like to continue?",
                            type: "warning",
                            html: true,
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No, Cancel this merge",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) { 
                                $rootScope.showLoader(true);  
                                var data = {};

                                angular.forEach($scope.attorney, function(value, key){
                                    if(value.id == $scope.mergeAttorneys.deleteAttorneyId){
                                        data.deleteAttorney = value;
                                    }
                                    if(value.id == $scope.mergeAttorneys.keepAttorneyId){
                                        data.keepAttorney = value;
                                    }
                                });
                                
                                $http.post(apiUrl + '/api/merge-attorneys',data).success(function (response) {
                                     
                                    $rootScope.showLoader(false);
                                    $scope.resetForm();  
                                    if(response.result.status == 200){
                                        Notification.success(response.result.message); 
                                    }else{
                                        Notification.error(response.result.message);
                                    } 
                                    
                                }).error(function (error) {
                                    $rootScope.showLoader(false);
                                    Notification.error(error.message);
                                });
                            }
                        });
                }else{
                    swal ( "Oops" ,  "Please fill out all required fields." ,  "warning" );
                }
            }
            $scope.authMergeAttorney = function(){  
                
                if($state.current.name == 'attorney.merge_attorneys'){

                    return false;
                    
                }else if($rootScope.havePermission('allow_access_without_password','allow.attorney.merge')){

                    $state.go('attorney.merge_attorneys');

                }else{
                
                    swal({
                        title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                        type: "input",
                        inputType: "password",
                        showCancelButton: true,
                        closeOnConfirm: true
                    }, function(inputValue) { 
                        if (inputValue === false){
                            return false;
                        } 
                        if (inputValue === "") {
                            Notification.error("You need to write something!");
                            return false
                        }
                        else {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                                .success(function(response) {
                                    if (response['result']) {
                                        if (response['result']['auth']) {

                                            $rootScope.showLoader(false);
                                            $state.go('attorney.merge_attorneys');
                                            
                                        }else {
                                            $rootScope.showLoader(false);
                                            Notification.error("Error ! Wrong Password");
                                        }
                                    }else{
                                        $rootScope.showLoader(false);
                                        Notification.error("Oops ! Something went wrong");
                                    }
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                });

                        }
                    }); 
                }       
            } 

        }]);