angular.module('deitz')
    .controller('createRolesController', ['$scope', '$rootScope', 'PermissionFactory', 'codeFactory', 'RolesFactory', '$state','Notification', function ($scope, $rootScope, PermissionFactory, codeFactory, RolesFactory, $state,Notification) {
        $scope.page_title = $state.current.data.pageTitle;
        $scope.role = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;


        PermissionFactory.getAllPermisssion().success(function (response) {
            $scope.role.permissions = response;
        }).error(function (error) {

        });

        $scope.submitRole = function (form,is_valid) {
            if(is_valid){                    
                $scope.loadingDemo = true;
                RolesFactory.addRole($scope.role).success(function (response) {
                    Notification.success(response.success || "Role created successfully.");
                    $state.go('roles.browse');
                }).error(function (error) {
                    Notification.success(error.error || "Something went wrong.");
                    $scope.loadingDemo = false;
                });
            }else{
                Notification.error("Please enter valid form data.");
            }
        };

    }]);