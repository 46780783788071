angular.module('deitz')
    .controller('addFirmNotesController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'attorney_id',
        'id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
            $rootScope,
            codeFactory,
            $http,
            Upload,
            apiUrl,
            $state,
            commonFactory,
            Notification,
            $stateParams,
            $uibModal,
            attorney_id,
            id,
            $uibModalInstance,
            configFactory) {
            $scope.firmNote = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Add Note';
            $scope.firmNote.note_date = moment();
            $scope.firmNote.notes = '';
            $scope.firmNote.attorney_id = attorney_id;
            if(id){
                $scope.title = 'Edit Note';
            }

            if(id){
                var id = id;
                commonFactory.get('/api/firm-note/' + id)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result) {
                        $scope.firmNote=response.result.note;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }

            $scope.validateDate = function () {
                if ($scope.firmNote.note_date) {
                    is_valid_check = moment($scope.firmNote.note_date).isValid();
                    if (!is_valid_check) {
                        $scope.firmNote.note_date = '';
                    }
                }
            }
            
            $scope.editFirmNote = function (firmNote, isValid) {

                $scope.formSubmit = true;

                if (typeof firmNote.note_date != "undefined" && firmNote.note_date != "") {
                    firmNote.note_date = moment(firmNote.note_date).format('YYYY-MM-DD');
                }

                if (isValid) {
                    $rootScope.showLoader(true);

                    if(id){
                        commonFactory.put('/api/firm-note/'+id,$scope.firmNote)
                        .success(function (response) {
                            $rootScope.showLoader(false);
                            if (response.status == 200 && response.result) {
                                Notification.success("Note update Sucessfully");
                                $scope.closePopupWindowSimple(true);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                    }
                    else{
                        commonFactory.post('/api/firm-note',$scope.firmNote)
                        .success(function (response) {
                            $rootScope.showLoader(false);
                            if (response.status == 200 && response.result) {
                                Notification.success("Note Add Sucessfully");
                                $scope.closePopupWindowSimple(true);
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });
                    }
                }
            }

            $scope.closePopupWindowSimple = function (isChanged) {
                $uibModalInstance.dismiss({ isChanged: isChanged });
            };

        }]);
