
angular.module('deitz').controller('editGroupChargeController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','$http','apiUrl',

    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,$http,apiUrl
    ){

        $scope.our_suite = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Group Charge';       
        $scope.groupCharge = {};

        $http.get(apiUrl + '/api/get-rate-codes').then(function(response) { 
            $scope.ratecodeList = response.data.result;                      
          });


        //Edit Mode of our_suite.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/group-charge/' + id + '/edit')
                .success(function(response) {

                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.groupcharge_detail) {
                        $scope.groupCharge = response.result.groupcharge_detail;                                                                                             
                        var selected_rate_code =  [];                    
                        $scope.groupCharge.rate_codes.split(',').forEach(function (e) {                               
                           selected_rate_code.push(parseInt(e))
                        }); 
                        $scope.groupCharge.rate_codes = selected_rate_code;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of our_suite
        $scope.submitGroupCharge = function(isValid) {           
        $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/group-charge/' + id, $scope.groupCharge)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Group Charge updated successfully");

                                $state.go("code_tables.group_charge_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
