angular.module('deitz').controller('jobSchedulesController', [
    '$scope', '$rootScope', '$http', 'apiUrl', 'Notification', '$timeout', '$stateParams', '$state', 'configFactory', 'commonFactory', '$window',
    function (
        $scope, $rootScope, $http, apiUrl, Notification, $timeout, $stateParams, $state, configFactory, commonFactory, $window
    ) {

        var addTomorrowDay = 1;

        if(moment().format('dddd') === 'Friday')
            addTomorrowDay = 3;

        if(moment().format('dddd') === 'Saturday')
            addTomorrowDay = 2;

        $scope.formData = { 
            selectedAttorneys : [], 
            dateRange : ''
            /*dateRange : {
                startDate : moment().add(addTomorrowDay, 'days').format('MM-DD-YYYY'),
                endDate :  moment().add(addTomorrowDay, 'days').format('MM-DD-YYYY')
            }*/
        }; 

        // get firms groups
        $rootScope.showLoader(true);
        commonFactory.get('/api/get-firm-groups')
        .success(function(response) {
            $rootScope.showLoader(false);
            $scope.firmGroups = response.result;
        })
        .error(function(err) {
            $rootScope.showLoader(false);
        });

        $scope.clearSearch = function (){
            $scope.tagRemoved();
            $scope.formData = { 
                selectedAttorneys : [],
                dateRange : {
                    startDate : moment().add(addTomorrowDay, 'days').format('MM-DD-YYYY'),
                    endDate :  moment().add(addTomorrowDay, 'days').format('MM-DD-YYYY')
                }
            }; 
        };

        //Getting Attorneys list
        $scope.attorneysList = function(val) {
            $scope.searchingAtt = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $scope.searchingAtt = false;
                return response.data.result.map(function(item) {
                    var department = item.department_name ? ' || '+ item.department_name : '';
                    item.NAME = item.NAME + department;
                    return item;
                });
            });
        };

        /* On select / Remove any claimrep/claim representative, set flag to false. It will hide print, fax, email buttons.*/
        $scope.tagRemoved = function(){
            $scope.isSearched = false;
        };

        $scope.dateFormat = function(date){
            return  moment(date).format('MM/DD/YYYY');
        }

        $scope.searchJobs = function(valid){
            if(valid){
                $scope.tagRemoved();
                var ids = [];    
                angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
                   ids[key] = value.id;
                });

                $rootScope.showLoader(true);
                commonFactory.post('/api/get-job-schedules-list', { 
                        ids: ids ,
                        startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'), 
                        endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD') 
                    }).then(function(response) {

                    $rootScope.showLoader(false);
                    $scope.jobList = response.data.result;   

                    if($scope.jobList.length){
                        $scope.isSearched = true;                          
                    }

                    if(!$scope.isSearched){ 
                        Notification .error('Jobs Not Found!');
                    } 
                });
            }
        }

        $scope.print = function(type){
            $rootScope.showLoader(true);
            var ids = [];    
            angular.forEach($scope.formData.selectedAttorneys, function(value, key) {
               ids[key] = value.id;
            });

            var params = {    
                ids : ids,
                startDate : moment($scope.formData.dateRange.startDate).format('YYYY-MM-DD'), 
                endDate : moment($scope.formData.dateRange.endDate).format('YYYY-MM-DD'),
                type : type 
            }
            
            commonFactory.post('/api/generate-job-schedules-report',params).success(function(resp){
                if(!resp.error){
                    $rootScope.showLoader(false);
                    // $window.location.href = apiUrl +'/api/download-job-schedules-report/'+type;
                    $window.open(apiUrl +'/api/download-job-schedules-report/'+type, '_blank'); 
                }else{
                    $rootScope.showLoader(false);
                    Notification.error(resp.result.message);
                }
            });
        }

        $scope.groupChange = function(){
            
            $scope.tagRemoved();
            var groups = [];    
            angular.forEach($scope.formData.selectedGroups, function(value, key) {
               groups[key] = value.group_name;
            });
            
            // get firms of selected groups
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.formData.selectedAttorneys = response.result;
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        }
    }
]);
