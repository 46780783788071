angular.module('deitz').controller('addeuoFirmController', [

    '$scope', '$http', '$state', '$rootScope', 'Upload', 'apiUrl', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $http, $state, $rootScope, Upload, apiUrl, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.display_euo = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Euo Firm';
        $scope.isPdfUploaded = false;

        $http.get(apiUrl + '/api/get-users-list').success(function(response) {
            $scope.UsersList = response.result
        });

        $scope.addEuoFirms = function(display_euo, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                Upload.upload({
                    url: apiUrl+'/api/display-euo-firms/addnew',
                    data: $scope.display_euo
                }).then(function (response) { 
                    $rootScope.showLoader(false);
                    if (response) {
                        if (response.data.status == 200) {
                            Notification.success("Euo Firm added successfully");
                            $state.go("code_tables.euo_firm_browse");
                        }else if(response.data.status == 400){
                            Notification.error(response.data.result.message);
                        }
                    }
                },function(err){
                    $rootScope.showLoader(false);
                });
            }
        }

    }]);
