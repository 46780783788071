angular.module('deitz')
            .controller('reassignCollector',
                ['$scope',
                 '$rootScope',
                 '$state',
                 'commonFactory',
                 'invoiceFactory',
                 'Notification',
                 'DTOptionsBuilder',
                 'DTColumnBuilder',
                 '$q',
                 '$compile',
                 '$uibModal',
                 '$http',
                 'apiUrl',
                 '$window',
                    function($scope,
                             $rootScope,
                             $state,
                             commonFactory,
                             invoiceFactory,
                             Notification,
                             DTOptionsBuilder,
                             DTColumnBuilder,
                             $q,
                             $compile,
                             $uibModal,
                             $http,
                             apiUrl,
                             $window
                                ){

        
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};
        $scope.selectedFirmsIds = []; 
        $scope.firmSearching = false;
        $scope.searchFilter = {};
        $scope.filterObj = {};
        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        $scope.filterByCreatedDate = false;
        $scope.dateObj = {
            createdDateRange : {
                startDate : moment(),
                endDate : moment()
            }
        };

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $scope.firmSearching = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                // $rootScope.showLoader(false);
                $scope.firmSearching = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

        //Getting all terms 
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.termsList = res.data;
        });

        //Getting all sources from source table.
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.sourcesList=response.data.data;       
        }); 

        //Getting all status of firm.
        $http.get(apiUrl + '/api/get-status-of-firm').success(function(response) { 
            $scope.statusList=response.result;       
        }); 

        /*Getting all client level */
        $http.get(apiUrl + '/api/get_client_level').then(function(response) {            
            $scope.clientLevelList = response.data.data;            
        });

        /*Getting all groups */
        $http.get(apiUrl + '/api/get-firm-groups').success(function(response) {            
            $scope.firmGroups = response.result;
        });

        // Getting all Collectors 
        $http.get(apiUrl + '/api/collector').success(function(res){
            $scope.collectorsList = res.result;
        });

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }; 
        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.searchData = function(){
            $scope.searchFilter = {
                selectedFirmsIds : $scope.selectedFirmsIds,
                selectedSalesPersonsIds : $scope.selectedSalesPersonsIds,
                selectedTermsIds : $scope.selectedTermsIds,
                selectedSourcesId : $scope.selectedSourcesId,
                selectedStatus : $scope.selectedStatus,
                selectedClientLevel : $scope.selectedClientLevel,
                selectedGroups : $scope.selectedGroups,
                selectedCollectors : $scope.selectedCollectors,
                filterByGoldenIds : $scope.filterByGoldenIds,
                filterByCreatedDate : $scope.filterByCreatedDate ? $scope.filterByCreatedDate : false,
                createdDateRange : {
                    startDate : moment($scope.dateObj.createdDateRange.startDate).format('MM-DD-YYYY'),
                    endDate : moment($scope.dateObj.createdDateRange.endDate).format('MM-DD-YYYY')
                }
            };
            localStorage.setItem('datatable_search_filter',JSON.stringify($scope.searchFilter));
            $scope.reloadData();
        };
        
        $scope.clear = function(){
            $scope.selectedFirmsIds = [];
            $scope.selectedSalesPersonsIds = [];
            $scope.selectedTermsIds = [];
            $scope.selectedSourcesId = [];
            $scope.selectedStatus = [];
            $scope.selectedClientLevel = [];
            $scope.selectedGroups = [];
            $scope.selectedCollectors = [];
            $scope.filterByGoldenIds = "";
            $scope.filterByCreatedDate = false;
            $scope.dateObj = {
                createdDateRange : {
                    startDate : moment(),
                    endDate : moment()
                }
            };
            $scope.searchData();
        }

        $scope.getSearchFilter = function(){
            var filter = JSON.parse(localStorage.getItem('datatable_search_filter'));
            if(filter){
                angular.forEach(filter.selectedFirmsIds,function(value, key){
                    $scope.selectedFirmsIds.push(value);
                });
                $scope.selectedSalesPersonsIds = filter.selectedSalesPersonsIds;
                $scope.selectedTermsIds = filter.selectedTermsIds;
                $scope.selectedSourcesId = filter.selectedSourcesId;
                $scope.selectedStatus = filter.selectedStatus;
                $scope.selectedClientLevel = filter.selectedClientLevel;
                $scope.selectedGroups = filter.selectedGroups;
                $scope.selectedCollectors = filter.selectedCollectors;
                $scope.filterByGoldenIds = filter.filterByGoldenIds;
                $scope.filterByCreatedDate = filter.filterByCreatedDate;
                if(filter.createdDateRange){
                    $scope.dateObj.createdDateRange = filter.createdDateRange;
                };
            }   
        }
        $scope.getSearchFilter();

        $scope.updateCollector = function(firmID, newCollectorID){
            swal({
                title: "Are you sure",
                text: "You want to change collector for this firm ?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 
                    console.log("firmID: " + firmID + ", newCollectorID: " + newCollectorID);
                    $http.post(apiUrl+'/api/update-collector',{firmID : firmID, newCollectorID : newCollectorID}).success(function(res){                    
                        $rootScope.showLoader(false);
                        if (!res.error) {
                           
                            $scope.oldCollectorID = newCollectorID;
                            Notification.success(res.result.message);
                        } else {
                            Notification.error(res.result.message);
                        }
                    });
                } else {
                    // Handle cancel action if needed
                }
            });
        }
      
        $scope.performAction = function() {            
            var assignResource = $uibModal.open({
                templateUrl: "modules/accountReceivableNotes/reassign_collector_form.html",
                controller: 'multiReassignCollectorController',
                resolve: {
                    data: function () {
                        return $scope.savedData;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResource.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.selectAll = true;
                    $scope.selected = []
                    $scope.savedData = {};
                    $scope.multiAssignBtn=false;
                    $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {

                data.selectedFirmsIds = $scope.selectedFirmsIds.map(function(a){
                    return  a.id;
                });

                data.selectedSalesPersonsIds = $scope.selectedSalesPersonsIds;
                
                data.selectedTermsIds = $scope.selectedTermsIds;

                data.excludeZeroBalDue = $scope.excludeZeroBalDue ? $scope.excludeZeroBalDue : false;

                data.selectedSourcesId = $scope.selectedSourcesId;
                
                data.selectedStatus = $scope.selectedStatus;
                
                data.selectedClientLevel = $scope.selectedClientLevel;

                data.selectedGroups = $scope.selectedGroups;

                data.selectedCollectors = $scope.selectedCollectors;
                
                data.excludeSalesPerson = $scope.excludeSalesPerson ? $scope.excludeSalesPerson : false;

                data.excludeCollecter = $scope.excludeCollecter ? $scope.excludeCollecter : false;

                data.filterByGoldenIds = $scope.filterByGoldenIds;

                data.filterByCreatedDate = $scope.filterByCreatedDate ? $scope.filterByCreatedDate : false;

                data.startDate = moment($scope.dateObj.createdDateRange.startDate).format('YYYY-MM-DD');
                data.endDate = moment($scope.dateObj.createdDateRange.endDate).format('YYYY-MM-DD');

                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                
                angular.forEach(data.columns,function(value, key){
                    data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                });

                commonFactory.showDataTable('/api/firms/show-data-table', data)
                .success(function(res) {
                    if (res) {
                        $scope.selectAll = true;
                        $scope.selected = {};
                        $rootScope.showLoader(false);
                        $scope.filterObj = data;
                        callback(res);
                        $('#attfirm').find('thead input').attr('autocomplete', 'off');
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            })
            .withLanguage({
                "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

            })

            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('stateSave', false)
            .withOption('lengthMenu',[[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [1, 'asc'])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withLightColumnFilter({
                '1': {
                    type: 'text'
                },
                '2': {
                    type: 'text'
                },
                '3': {
                    type: 'text'
                },
                '4': {
                    type: 'text'
                },
                '13': {
                    type: 'text'
                }

            });
        
            $scope.dtColumns = [];
            var titleHtml = '<input type="checkbox" ng-model="selectAll" class="icheckbox_square-blue" ng-change="toggleAllCollectors(selectAll)">';
    
            $scope.dtColumns.push(

                DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable()
                .renderWith(function (data, type, full, meta) {
                    $scope.selected[data.id] = full;
                    $scope.selected[data.id].is_checked = (typeof is_checked === 'undefined') ? false : true;
        
                    if (!$scope.selected[data.id].is_checked) {                
                        $scope.selectAll = false;
                    }
                    return '<input type="checkbox" class="icheckbox_square-blue" ng-model="selected[' + data.id + '].is_checked" ng-change="toggleSelection(' + data.id + ','+full.collector_id+',\''+full.SHORT_NAME.toString()+'\')">';
                }).withOption('searchable', false),
    
                DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('Name').withOption("width", "15%").renderWith(function(data,type,full,meta){
                    var isStatus = full.STATUS ? true : false; 
                    return '<a ui-sref="firms.manage.step_one({id : ' + full.id + '})">'+data+'</a><span ng-if='+$scope.filterByGoldenIds+'><br>'+full.goldenRecordId+'</span><br><small ng-if='+isStatus+'><b><i>'+full.STATUS+'</i></b></small>'
                }),
                DTColumnBuilder.newColumn('ADDR_LINE1').withOption('defaultContent', '').withTitle('Address').withOption("width", "10%").withOption('name', 'ATTORNEY_tbl.ADDR_LINE1'),
                DTColumnBuilder.newColumn('reportingSalesman').withOption('defaultContent', '').withTitle('Account Executive <span class="small">(s)</span>')
                .renderWith(function(data,type,full,meta){ 
                    if(full.reporting_salesman.length){
                        var name = [];
                        angular.forEach(full.reporting_salesman,function(value, key){
                            if(value.reptype && value.reptype.name){
                                name[key] = '<u>'+escapeString(value.reptype.name)+'</u>';
                            }
                        });
                        return name.join('<br>');
                    }else{
                        return '<a href="update collecter" >';
                    }
                }).withOption("width", "10%").withOption('name', 'reportingSalesman.reptype.FIRST_NAME'),
                DTColumnBuilder.newColumn('collecter').withOption('defaultContent', '').withTitle('Collector Person <span class="small">(s)</span>')
                .renderWith(function(data,type,full,meta){ 
                    var id = full.id;
                    if (full.collecter.length) {
                        var dropdown = '';
                        angular.forEach(full.collecter, function(collector, index) {
                            var uniqueId = index + '_' + full.id; 
                            $scope[`selectedCollectorId_${uniqueId}`] = full.collector_id; 
                    
                            dropdown += `<tr>
                                <td>
                                    <select class="form-control" ng-model="selectedCollectorId_${uniqueId}" 
                                            ng-options="collector.id as collector.name for collector in collectorsList" 
                                            ng-change="updateCollector(${full.id}, selectedCollectorId_${uniqueId})">
                                    </select>
                                </td>
                            </tr>`;
                        });
                        return dropdown;
                    }
                    else{
                        return '<i title="Assign Collector"  ng-click="assignResource('+ id +',\'collector\')" class="fa fa-2x fa-plus" style="cursor: pointer;"></i>';
                    }
                }).withOption("width", "10%").withOption('name', 'collecter.name'),
                DTColumnBuilder.newColumn('YTD_PAID').withOption('defaultContent', '').withTitle('YTD Paid').renderWith($scope.currencyFormat),
                DTColumnBuilder.newColumn('YTD_BILLED').withOption('defaultContent', '').withTitle('YTD Billed').renderWith($scope.currencyFormat),
                DTColumnBuilder.newColumn('PYR_BILLED').withOption('defaultContent', '').withTitle('PYR Billed').renderWith($scope.currencyFormat),
                DTColumnBuilder.newColumn('PYR2_BILLD').withOption('defaultContent', '').withTitle('PYR2 Billed').renderWith($scope.currencyFormat),
                DTColumnBuilder.newColumn('BAL_DUE').withOption('defaultContent', '').withTitle('Bal Due').renderWith($scope.currencyFormat),
                DTColumnBuilder.newColumn('PHONE').withOption('defaultContent', '').withTitle('Tel.No').notSortable().withOption("width", "9%"),
                DTColumnBuilder.newColumn('CITY').withOption('defaultContent', '').withTitle('City').withOption("width", "4%"),
                DTColumnBuilder.newColumn('STATE').withOption('defaultContent', '').withTitle('State').withOption("width", "4%"),
                DTColumnBuilder.newColumn('businessunit.name').withOption('defaultContent', '').withTitle('Business Unit').withOption("width", "3%").withOption('name', 'businessunit.name'),
                DTColumnBuilder.newColumn(null).withTitle('').notSortable().withOption("width", "5%")
                .renderWith(function(data, type, full, meta) {
                    var firmName = escape(data.NAME);
    
                    bindButtons = '<div class="btn-group btn-sm" uib-dropdown>' +
                        '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                        '<ul role="menu" uib-dropdown-menu="" style="left: -100px!important;">';
                        
                            if($rootScope.havePermission('attorney_firm','update',full.businessunit.id)){
                                bindButtons +=  '<li uib-tooltip="Edit Firm">'+
                                                    '<a class="btn btn-xs btn-default" ui-sref="firms.manage.step_one({id : ' + data.id + '})"><i class="fa fa-pencil"></i> &nbsp; Edit </a>'+
                                                '</li>';                              
                            }else{
                                bindButtons +=  '<li uib-tooltip="View Firm">'+
                                                    '<a class="btn btn-xs btn-default" ui-sref="firms.manage.step_one({id : ' + data.id + '})"><i class="fa fa-eye"></i> &nbsp; View </a>'+
                                                '</li>';                        
                            }
                            bindButtons += '<li uib-tooltip="Admins">'+
                                '<a class="btn btn-xs btn-default" ui-sref="firms.manage.ebt({id : ' + data.id + '})"><i class="fa fa-user"></i> &nbsp; Admins </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Browse Attorneys">'+
                                '<a class="btn btn-xs btn-default" ui-sref="attorney.browse({firmId : ' + data.id + '})"><i class="fa fa-eye"></i> &nbsp; Attorneys </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Show Statements">'+
                                '<a class="btn btn-xs btn-default" ui-sref="statements.print_statements({attId:'+ data.id +'})"><i class="fa fa-book"></i> &nbsp; Statements </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Pay">'+
                                '<a class="btn btn-xs btn-default" ng-click="autoLogin(\''+data.NAME_KEY+'\')"><i class="fa fa-money"></i> &nbsp; Pay </a>'+
                            '</li>'+
    
                            // '<li uib-tooltip="A/R Fax Utility">'+
                            //     '<a class="btn btn-xs btn-default" ng-click="open_A_R_Fax_window(\''+data.NAME_KEY+'\')"><i class="fa fa-fax"></i> &nbsp; A/R Fax Utility </a>'+
                            // '</li>'+
    
                            '<li uib-tooltip="Browse Firm Invoices">'+
                                '<a class="btn btn-xs btn-default" ng-click="showFirmInvoices(' + data.id + ',\''+firmName+'\')"><i class="fa fa-envelope-o"></i> &nbsp; Invoices </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Work In Progress Report">'+
                                '<a class="btn btn-xs btn-default" ui-sref="representative.work_in_progress_by_reporter({attId:'+data.id+'})"><i class="fa fa-line-chart"></i> &nbsp; Work In Progress </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="A/R Notes">' +
                                '<a class="btn btn-xs btn-default" ui-sref="accountReceivableNotes.browse({attId:'+data.id+'})"><i class="fa fa-pencil-square-o"></i> &nbsp; A/R Notes </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Stats Report">' +
                                '<a class="btn btn-xs btn-default"  ng-click="open_STATS_report(' + data.id + ')"><i class="fa fa-file"></i>&nbsp; Stats Report </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Job Profit Report">' +
                                '<a class="btn btn-xs btn-default"  ui-sref="index.job_profit({attId:' + data.id + '})"><i class="fa fa-pie-chart"></i>&nbsp; Job Profit </a>'+
                            '</li>'+
    
                            '<li uib-tooltip="Account Credits Report">' +
                                '<a class="btn btn-xs btn-default"  ui-sref="index.account_credits_report({attId:' + data.id + '})"><i class="fa fa-money"></i>&nbsp; Account Credits </a>'+
                            '</li>';
    
                            if(data.saleforce_id){ 
                               bindButtons +=  '<li uib-tooltip="SalesForce">' +
                                        '<a class="btn btn-xs btn-default" href="https://nextgen.my.salesforce.com/'+data.saleforce_id+'" target="_blank"><i class="fa fa-cloud"></i>&nbsp; SalesForce </a>'+
                                    '</li>';
                            }  
                            bindButtons += '</ul></div>';
    
                    return bindButtons;
    
    
                })
            );

        $scope.getStatements = function(firm_code){
            var reportModal = $uibModal.open({
                templateUrl: "modules/firms/browse/statement_modal.html", 
                controller: function($scope,firm_code,$uibModalInstance,$sce,$rootScope){
                    
                    var auth_user = $rootScope.auth_user;   
                    $scope.givenUrl = $sce.trustAsResourceUrl( $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script= mystatement.php?firm_code="+encodeURIComponent(firm_code));  

                    $scope.spinnerLoader= true;
                    $scope.closePopupWindow = function(){
                        $uibModalInstance.close(true);
                    };
                    $scope.iframeLoadedCallBack = function(){
                    // do stuff
                        $scope.spinnerLoader= false;
                        $scope.$apply();
                        console.log("loading Complete");
                    }
 
     
                },
                resolve: {
                    firm_code : function(){
                        return firm_code;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
 
            reportModal.result.then(function () {
            }, function () {
                console.log("Modal Dismissed!!!");
            }); 
        };
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };

        $scope.toggleAll = function() {
            angular.forEach($scope.dataTableData, function(item) {
                $scope.selected[item.id] = $scope.selectAll;
            });
        };

        //$scope.selectAll = false;
        $scope.savedData = {};
        $scope.multiAssignBtn=false;

        $scope.toggleSelection = function(itemId, collectorId, firmName) {
            if ($scope.selected[itemId].is_checked) {              
                $scope.savedData[itemId] = { firmId:itemId,firmName: firmName,collectorId: collectorId };
            } else {                
                delete $scope.savedData[itemId];
            }
            $scope.multiAssignBtn = Object.keys($scope.savedData).length > 0;
            $scope.selectAll = Object.keys($scope.selected).every(function(key) {
                return $scope.selected[key].is_checked;
            });
        };

        $scope.toggleAllCollectors = function toggleAllCollectors(isChecked) {
            angular.forEach($scope.selected, function (value, key) {
                $scope.selected[key].is_checked = isChecked;               
                $scope.toggleSelection($scope.selected[key].id, $scope.selected[key].collector_id,$scope.selected[key].SHORT_NAME.toString());
                
            });
        };

        $scope.showFirmInvoices = function(att_id, firm_name){  
            invoiceFactory.searchData = {
                searchValue : {
                    id : att_id,
                    NAME: unescape(firm_name)
                },
                selectedValue : "BILLING_FIRM"
            };
            $state.go('invoice.browse');
        };
         
        $scope.assignResource = function(firmID, resource_type){
            
            var assignResource = $uibModal.open({
                templateUrl: "modules/firms/browse/assignResource.html",
                controller: 'assignResourceController',
                resolve: {
                    firmID: function () {
                        return firmID;
                    },
                    resource_type : function(){
                        return resource_type;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResource.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){

                    $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };
        /* This function is use for prinnt OPERATION METRICS REPORT */
        $scope.printPDF = function(){
            $rootScope.showLoader(true);

            var data = $scope.filterObj;
            data.printPdf = true;
            data.length = -1;
            
            commonFactory.showDataTable('/api/firms/show-data-table', data)
            .success(function(response) { 
                $rootScope.showLoader(false);
                if(response.error){
                    Notification.error('Something went wrong!');
                } else {

                    // $window.location.href = apiUrl +'/api/download-attorneylist-report?fileName='+response.pdfFilename;
                    $window.open(apiUrl +'/api/download-attorneylist-report?fileName='+response.pdfFilename, '_blank'); 
                }
            })
            .error(function(err)
            {   
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false);
            });
        };
        $scope.showInvoicePop = function showInvoicePop(invoice) {
            console.log("aa")
            invoice = $scope.invData.filter(function(e) {
                return e.INV_NO == invoice;
              });
              
              invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup_geico.html",
                controller: 'viewGeicoInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.copyDatas= function($event){
           
           
            navigator.clipboard.writeText($event.target.innerText)

        $( "<div>Your text here </div>" ).dialog();
        }
    }]);
