angular.module('deitz')
    .controller('AddUserCallByControllers', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        '$filter',
        'commonFactory',
        '$stateParams',
        '$uibModal',
        'invoiceFactory',
        'attorney_id',
        '$uibModalInstance',
        'Notification',
        'configFactory',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  $state,
                  $timeout,
                  $filter,
                  commonFactory,
                  $stateParams,
                  $uibModal,
                  invoiceFactory,
                  attorney_id,
                  $uibModalInstance,
                  Notification,
                  configFactory) {

           

            // If request comes from invoice module screen then attorney_id will the array otherwise we can fetch the invoice from stateParams.
            $scope.attorney_id = attorney_id
            $scope.toEmail
            $scope.is_bad_claim_number;
            $scope.emailSubject
            $scope.user
            $scope.perms_list =[{"label":"EBT Coordinator","value":"attorney"},
            {"label":"Paralegal","value":"attorney"},
            {"label":"Secretary","value":"attorney"},
            {"label":"Attorney","value":"attorney"},
            {"label":"Court Reporter","value":"reporter"},
            {"label":"Agency","value":"agency"},
            {"label":"Witness/Deponent","value":"witness"},
            {"label":"Audio Processor","value":"audioperson"},
            {"label":"Interpreter","value":"attorney"},
            {"label":"Other/don't know","value":"attorney"}]
            
            // $scope.invoice.INV_AMT= $scope.invoice.INV_AMT.toFixed(2);
           console.log($scope.uibModalInstance,attorney_id)
            // fetch the invoice information for all the invoices
         
         
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
            };
            $scope.submit_user = function(){
                
                            $rootScope.showLoader(true);
                            //$close(JOB_NO);
                            $scope.user.attorney_id =attorney_id
                            commonFactory.post('/api/users/create-depo-user',$scope.user).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.success);
                                setTimeout(() => {
                                    $uibModalInstance.close(true);
                                }, 200);
                            }).error(function (error) {
                             $rootScope.showLoader(false);
                             Notification.error(error.error);

                            }
                                
                            );
                       
            };
           
    
          
        }]);
