angular.module('deitz').controller('editJobStatusController', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.job_status = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Job Status';
        
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/job-status/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.job_status) {
                        $scope.job_status.name = response.result.job_status.name || '';
                        $scope.job_status.sort_order = response.result.job_status.sort_order || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.add_job_status = function(job_status, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/job-status/' + id, $scope.job_status)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Job Status updated successfully");
                                $state.go("code_tables.job_status_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
