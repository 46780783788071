angular.module('deitz')
                .controller('ebtController', [
                    '$scope', 
                    '$rootScope', 
                    'firmFactory', 
                    '$state', 
                    'Notification', 
                    '$uibModalInstance', 
                    'ebtdata', 
                    'rateFactory', 
                        function(
                            $scope, 
                            $rootScope, 
                            firmFactory, 
                            $state, 
                            Notification, 
                            $uibModalInstance, 
                            ebtdata,
                            rateFactory
                    ) {

        $scope.addebt = {};
        $scope.roles = [];
        $scope.title = "Add Admin";
        $scope.formSubmit = false;

        $scope.validations = firmFactory.validations;
        $scope.messages = firmFactory.messages;

        if(ebtdata && ebtdata.attorneyFirmId){
            $scope.addebt.attorney_id = ebtdata.attorneyFirmId;
        }else{
            $scope.addebt.attorney_id = $state.params ? parseInt($state.params.id) : '';
        }

        //Getting all rates from rates table.
        rateFactory.get('/api/roles')
            .success(function(response) {
                $scope.roles = response;
            })
            .error(function(err) {});

        $scope.addEbtclerk = function(ebt, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                if($scope.addebt.First_Name){
                    $rootScope.showLoader(true);
                    if($scope.addebt.gender == undefined){
                        $scope.addebt.gender = 'm';
                    } 
                    firmFactory.post('/api/addebtclerk', $scope.addebt)
                        .success(function(response) {
                            if (response) {
                                if (response.status == 200 && response.result && response.result.ebt) {
                                    $rootScope.showLoader(false);
                                    Notification.success("Clerk added successfully");
                                    $uibModalInstance.close(response.result.ebt);
                                } else {
                                    $rootScope.showLoader(false);
                                    Notification.error(response.result.message);
                                }
                            }
                        })
                        .error(function(err) {});

                }
                else{
                    swal('Error!', 'First name is required', 'success');
                }
            }
        }
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }]);
