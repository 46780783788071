angular.module('deitz')
    .controller('codInvoicesController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'invoiceFactory',
        'diaryFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  DTOptionsBuilder,
                  DTColumnBuilder,
                  invoiceFactory,
                  diaryFactory,
                  $compile,
                  $uibModal,
                  Notification,
                  $filter,
                  $timeout,
                  $q,
                  $stateParams) {

            $scope.title = "COD Invoice";
            $scope.editInvoiceData = {};
            $scope.dtInstance = {}; 

            $scope.resetSearch = function(){ 
                $scope.dtInstance.DataTable.state.clear();
                $scope.reloadData();
            }
            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            }; 
            $scope.headerCompiled = false;

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                angular.element(row).addClass('voided-invoice');                
                $compile(angular.element(row).contents())($scope);
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data')
                .withOption('ajax', function (data, callback, settings) {
                    
                    invoiceFactory.showDataTable('/api/invoice/get-cod-invoice-listing', data).success(function (res) {
                        if (res.error) {
                            $scope.reloadData();
                        }
                        else {  
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadData();
                        }
                    });
                })
                .withOption('processing', true)
                .withLanguage({
                    "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                    "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                    "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                    "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                    "sInfoPostFix": "",
                    "sInfoThousands": ",",
                    "sLengthMenu": "SHOW _MENU_ INVOICES",
                    "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                    "sProcessing": "<img src='img/loading_bar.gif'/>",
                    "sSearch": "",
                    "sZeroRecords": "NO MATCHING INVOICE FOUND",
                    "oPaginate": {
                        "sFirst": "FIRST",
                        "sLast": "LAST",
                        "sNext": "NEXT",
                        "sPrevious": "PREVIOUS"
                    },
                    "oAria": {
                        "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                        "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                    }
                }) 
                .withOption('serverSide', true)
                .withOption('stateSave', true)
                .withPaginationType('simple_numbers')
                .withOption('searchDelay', 500) 
                .withOption('order', [1, 'desc'])
                .withOption('createdRow', $scope.createdRow)
                .withOption('headerCallback', function (header) {
                    // Use this headerCompiled field to only compile header once
                    if (!$scope.headerCompiled) {
                        $compile(angular.element(header).contents())($scope);
                    }
                })
                .withOption('fnRowCallback', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    if (aData.undo_void != "0") {
                        //cell background color
                        //$(nRow).css('background-color', '#ffc2c2');
                        $('td', nRow).css('background-color', '#b3b1b1');
                    }
                })
                
                .withLightColumnFilter({
                    '0': {
                        type: 'text',
                    },
                    '1': {
                        type: 'text',
                    },
                    '2': {
                        type: 'text'
                    },
                    '3': {
                        type: 'text'
                    },
                    '4': {
                        type: 'text'
                    }
                });

            $scope.dtColumns = [];
            //full.undo_void
            $scope.dtColumns.push(
                 
                DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice No').withOption('searchable', true).withOption("width", "8%"),
                DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('searchable', true).withOption("width", "6%"),
                DTColumnBuilder.newColumn('attorney_firms.SHORT_NAME').withTitle('Billing Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'attorney_firms.SHORT_NAME').withOption('name', 'attorneyFirms.SHORT_NAME').renderWith(function (data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var billing_firm = "<div uib-tooltip='"+full.attorney_firms.NAME+ " | " +full.attorney_firms.PHONE+ " | " +full.attorney_firms.ADDR_LINE1+"' tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                        return billing_firm;
                    } else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('diary_data.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'diary_data.attorney_firm.SHORT_NAME').withOption('name', 'diaryData.attorneyFirm.SHORT_NAME').renderWith(function (data, type, full, meta) {
                    if (data) {
                        data = escapeString(data);
                        var billing_firm = "<div uib-tooltip='"+full.diary_data.attorney_firm.NAME+ " | "+full.diary_data.attorney_firm.PHONE+ " | " +full.diary_data.attorney_firm.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                        return billing_firm;
                    } else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('job.CAPTION').withTitle('Case Name').withOption("width", "8%").withOption('searchable', true).withOption('data', 'job.CAPTION').withOption('name', 'job.CAPTION').renderWith(function (data, type, full, meta) {
                    if (data) {

                        var caption = escapeString(data);
                        var billing_firm = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+caption+"' | limitTocustom:13}}</div>";
                        return billing_firm;
                    } else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('witness').withTitle('Witnesses').withOption('searchable', false).withOption('data', 'witness').withOption('name', 'witnessData.witness.NAME').notSortable().renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('uppercase')(data, true);;
                    } else {
                        return '---';
                    }

                }),
                DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data) {
                        return '$' + $filter('number')(data, 2);
                    } else {
                        return '$' + $filter('number')(0, 2);
                    }

                }),
                DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Date').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    if (data && $filter('formatDate')(data) != 'Invalid Date') {
                        return $filter('formatDate')(data);
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn(null).withTitle('Balance Due').withOption('searchable', false).notSortable().withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                    return '$' + $filter('number')(eval(full.INV_AMT - full.PAID_AMT), 2);

                }),
                DTColumnBuilder.newColumn('dte_invoice').withOption("width", "5%").withTitle('Created').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'INVOICE_tbl.dte_invoice').renderWith(function (data, type, full, meta) {
                    if (data) {
                        return $filter('formatDate')(data);

                    } else if (full.dte_invoice) {
                        return $filter('formatDate')(full.dte_invoice);
                        ;
                    }
                    else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('legacy_invoice_id').withTitle('Legacy No').withOption('searchable', true).withOption("width", "6%"),
                DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%"),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith(function (data, type, full, meta) {
                   
                        var data = "<div class='text-center'>";                        
                    data += '<a ng-click="markAsRecoginzeInvoice(' + full.voided_for_invoice + ')" uib-tooltip="Recognize a cod invoice" href="javascript:void(0);"> <i tooltip-trigger="focus mouseenter"  tooltip-placement="top" class="fa fa-2x fa-undo text-danger" ></i></a>';
                    data += "</div>";
                   
                    
                    return data;
                })
            );

            $scope.markAsRecoginzeInvoice = function (invoice_id, clonedInvoiceId = '') {
                $rootScope.showLoader(true);

                var invoiceModal = $uibModal.open({
                    templateUrl: "modules/invoice/cod_invoice_popup.html",
                    controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','invoiceFactory','Notification', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,invoiceFactory,Notification) {
                        $rootScope.showLoader(false);
                        $scope.users = [{'id': null, name: "Please select the user"}]
                        $scope.users = $scope.users.concat(users.data);
                        $scope.cod_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'recognize', invoice_id: invoice_id, clonedInvoiceId:clonedInvoiceId};
                        $scope.uibModalInstance = $uibModalInstance;

                        $scope.codFormSubmit = function ($valid) {
                            if ($valid) {
                                $rootScope.showLoader(true);
                                invoiceFactory.post('/api/invoice/mark-invoice-as-cod-undo', $scope.cod_invoice).success(function (response) {
                                    $rootScope.showLoader(false);
                                    Notification.success(response.message);
                                    $uibModalInstance.close();
                                    $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                                }).error(function (error) {
                                    $rootScope.showLoader(false);
                                    Notification.error(error.message);
                                });
                            }
                        }

                    }],
                    resolve: {
                        invoice_id: function () {
                            return invoice_id;
                        },
                        users: ['UsersFactory', function (UsersFactory) {
                            return UsersFactory.get('/api/users').success(function (response) {
                                return response.data;
                            });
                        }],
                        clonedInvoiceId : function(){
                            return clonedInvoiceId;
                        }
                    },
                    keyboard: true,
                    backdrop: true,
                    size: 'md',
                    windowClass: 'middle-width-model',
                    close: function () {

                    }
                });

                invoiceModal.result.then(function () {
                    $scope.reloadData();
                });             
            }
    }]);
