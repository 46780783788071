angular.module('deitz').controller('editfirmaddresstypeController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    'firmaddresstypeId',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        firmaddresstypeId,
        $uibModalInstance
    ){

        $scope.firmaddresstype = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Firm Address Type';
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        $scope.modalId = firmaddresstypeId;
        
        //Edit Mode of firmaddresstype.
        if ($state.params && $state.params.id) {
            var id = firmaddresstypeId ? firmaddresstypeId : $state.params.id;
            commonFactory.get('/api/firmAddressType/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.firmAddressType_detail) {
                        $scope.firmaddresstype.name = response.result.firmAddressType_detail.name || '';
                        $scope.firmaddresstype.active = response.result.firmAddressType_detail.active || '';
                        $scope.firmaddresstype.sort_order = response.result.firmAddressType_detail.sort_order || null;
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of Firm Address Type
        $scope.addFirmAddressType = function(firmaddresstype, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = firmaddresstypeId ? firmaddresstypeId : $state.params.id;
                commonFactory.put('/api/firmAddressType/' + id, $scope.firmaddresstype)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Firm address type updated successfully");

                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.firmAddressType_update.id);
                                }else{
                                    $state.go("code_tables.firm_address_type_browse");
                                }
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
