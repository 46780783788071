angular.module('deitz').controller('representativeController',[
    '$scope', '$rootScope', 'commonFactory', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$window','$http', 'apiUrl', 'Notification','$uibModal',
    function(
        $scope, $rootScope, commonFactory, DTOptionsBuilder, DTColumnBuilder, $compile, $window, $http,apiUrl, Notification,$uibModal,
    ){
        $scope.formData = {};
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };
        $scope.includeAll = true;
        $scope.reloadData = function() {        
          var resetPaging = true;        
        //  $scope.dtInstance.rerender();
          $scope.dtInstance.DataTable.draw();
        };

        const removeElement = (arr, element) => {
            let index = arr.indexOf(element);
            if (index > -1) {
              arr.splice(index, 1);
            }
          };


        $scope.selectedResourceType = ['0'];
        $scope.fetch_selectedResourceType = ['0'];

        $scope.changeResourceType =function(){                       
            $scope.filter_reporter=false;
            $scope.filter_salesperson=false;
            $scope.filter_typist=false;

            $scope.is_salesperson=false;
            $scope.is_typist=false;


            $scope.fetch_selectedResourceType = Object.assign([], $scope.selectedResourceType);
            f_typist = $scope.fetch_selectedResourceType.indexOf("T");
            f_salesperson = $scope.fetch_selectedResourceType.indexOf("S");            
            if (f_typist > -1 && f_salesperson > -1) {                
                $scope.filter_typist=true;               
                $scope.filter_salesperson =true;     
                
                $scope.is_salesperson = true;
                $scope.is_typist = true;

                removeElement($scope.fetch_selectedResourceType, "T");                
                removeElement($scope.fetch_selectedResourceType, "S");                
            }else if(f_typist > -1 ){
                $scope.filter_typist=true;
                $scope.is_typist = true;
                removeElement($scope.fetch_selectedResourceType, "T");   
            }else if (f_salesperson > -1) {
                $scope.filter_salesperson =true;
                $scope.is_salesperson = true;
                removeElement($scope.fetch_selectedResourceType, "S");
            }else{
                $scope.filter_reporter = true;                
            }  
            if($scope.fetch_selectedResourceType.length > 0){                
                $scope.filter_reporter = true;   
            }
            console.log($scope.fetch_selectedResourceType);
            $scope.reloadData();
        }
        $scope.additional_resource_types=[];
        
        $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
            $scope.additional_resource_types['0'] = 'All';
            angular.forEach( response.data.result.data, function (value, key) {
                $scope.additional_resource_types[value.id] =value.name;
            });
          // add seperate type for typist and 
          $scope.additional_resource_types['T'] ='Typist'; 
          $scope.additional_resource_types['S'] ='Account Executive'; 
           
          
        });
        $scope.resetSearch = function(){              
            $scope.filter_reporter = null;
            $scope.filter_typist = null;
            $scope.filter_salesperson = null;
            $scope.selectedResourceType = [];
            // $scope.selectedBusinessUnitIDs = JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));            
            $scope.dtInstance.DataTable.state.clear();            
            $scope.reloadData();
        };
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('stateSave',false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('initComplete', function(){
                // Do your stuff here, you could even fire your own event here with $rootScope.$broadcast('tableInitComplete');
                var myEl = angular.element( document.querySelector( '.dataTables_filter input' ) );
                myEl.attr('autofill',false);
            })
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    filter_reporter: $scope.filter_reporter,
                    filter_typist: $scope.filter_typist,
                    
                    is_salesperson: $scope.is_salesperson,
                    is_typist: $scope.is_typist,

                    filter_salesperson: $scope.filter_salesperson,
                    filter_selectedBusinessUnitIDs : JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)),
                    filter_resource_type : JSON.stringify($scope.fetch_selectedResourceType.map(Number)),                    
                    includeActive: (!$scope.includeAll && $scope.includeActive) ? $scope.includeActive : false,
                    includeInactive: (!$scope.includeAll && $scope.includeInactive) ? $scope.includeInactive : false,
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/reptype', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'RepresentativeDetails' },
                { extend: 'pdf', title: 'RepresentativeDetails' }, 
                {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');
                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.currencyFormat = function(data, type, meta, meta){
            data = data ?? 0;
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };

        function renderType(data, type, full, meta){
            if(full.REP_TYP == 'R'){
               return REP_TYP = '<span class="label label-success">Resource</span>';
            }
            else if (full.REP_TYP == 'S') {
                return REP_TYP = '<span class="label label-info">Account Executive</span>';
            }
            else if (full.REP_TYP == 'SC') {
                return REP_TYP = '<span class="label label-warning">Scopist</span>';
            }
            else if (full.REP_TYP == 'T') {
                return REP_TYP = '<span class="label label-primary">Typist</span>';
            }
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('created_at').withTitle('S. No.').notVisible(),
            DTColumnBuilder.newColumn('FIRST_NAME').withOption('defaultContent', '').withTitle('First Name'),
            DTColumnBuilder.newColumn('LAST_NAME').withOption('defaultContent', '').withTitle('Last Name'),
            // DTColumnBuilder.newColumn('STATUS').withOption('defaultContent', '').withTitle('Status'),
            DTColumnBuilder.newColumn('upcoming_pay').withOption('defaultContent', '').withTitle('Upcoming Pay').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn(0).withOption('defaultContent', '').withTitle('Type').renderWith(renderType),
            DTColumnBuilder.newColumn('resource_types').withOption('defaultContent', '').withTitle('Resource Types').notSortable().renderWith(function(data, type, full, meta){
                if(data.length !=0){
                    return data.map((list) => '<span class="label label-primary">'+list.name+'</span>').join("<br>") 
                }
                else if(full.REP_TYP=='T'){
                    return REP_TYP = '<span class="label label-primary">Typist</span>';
                }
                else if(full.REP_TYP=='S'){
                    return REP_TYP = '<span class="label label-primary">Account Executive</span>';
                }
            }),
            DTColumnBuilder.newColumn('PHONE').withOption('defaultContent', '').withTitle('Phone'),
            DTColumnBuilder.newColumn('CITY').withOption('defaultContent', '').withTitle('City'),
            DTColumnBuilder.newColumn('STATE').withOption('defaultContent', '').withTitle('State'),
            DTColumnBuilder.newColumn('businessunit.name').notSortable().withTitle('Business Unit'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                //In EDIT MODE SET Representative type.
                var repType = 'reporter';
                if(data.REP_TYP == 'R'){
                    repType = 'reporter';
                }
                else if (data.REP_TYP == 'S'){
                    repType = 'sales';
                }
                else if(data.REP_TYP == 'T'){
                    repType = 'typist';
                }
                else if (data.REP_TYP == 'SC'){
                    repType = 'scopist';
                }
                else{
                    repType = 'reporter';
                }

                if (data && data.id) {
                    var actions = '';
                    if($rootScope.havePermission('diary','update',full.businessunit.id)){
                        actions += '<button class="btn btn-primary btn-xs" ng-if="havePermission(\'representative_types\',\'edit\')" uib-tooltip="Edit" ui-sref="representative.'+ repType +'.step_one({id : ' + data.id + '})" ><i class="fa fa-pencil"></i></button> &nbsp;'+
                            '<button class="btn btn-primary btn-xs" ng-if="havePermission(\'reports\',\'view.work.in.progress.by.reporter.or.attorney\')" uib-tooltip="Work in progress report" ui-sref="representative.work_in_progress_by_reporter({repId : ' + data.id + '})" ><i class="fa fa-line-chart"></i></button> &nbsp;'+
                            '<button class="btn btn-danger btn-xs" uib-tooltip="Delete" ng-if="havePermission(\'representative_types\',\'delete\')" ng-click="deleteResource('+data.id+')"><i class="fa fa-trash"></i></button>';
                    }else{
                        actions += '<button class="btn btn-primary btn-xs" ng-if="havePermission(\'representative_types\',\'edit\')" uib-tooltip="View" ui-sref="representative.'+ repType +'.step_one({id : ' + data.id + '})" ><i class="fa fa-eye"></i></button> &nbsp;'+
                        '<button class="btn btn-primary btn-xs" ng-if="havePermission(\'reports\',\'view.work.in.progress.by.reporter.or.attorney\')" uib-tooltip="Work in progress report" ui-sref="representative.work_in_progress_by_reporter({repId : ' + data.id + '})" ><i class="fa fa-line-chart"></i></button> &nbsp;'
                    }

                    var tooltip = (data.is_active == '1') ? "Active" : "Inactive";
                    var newActive = (data.is_active == '1') ? "0" : "1";
                    var btn = (data.is_active == '1') ? 'btn-success' : 'btn-danger';
                    actions += '<button class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm '+btn+'" ng-click="changeIsActiveStatus(' + data.id + ',\''+ newActive +'\')">' + tooltip + '</button>';
                    return actions;
                    
                }

            })
        ];

        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#reptbl').DataTable().page();
            swal({
                title: "Alert!",
                text: "Are you sure want to "+status+" this resource?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    var data = {id : id, is_active : newStatus};

                    commonFactory.post('/api/reptyp/update-is-active-status', data).success(function(res){
                        $rootScope.showLoader(false); 
                        if(res.error){
                            Notification.error(res.result.message);    
                        }else{
                            Notification.success(res.result.message);
                        }
                        $('#reptbl').DataTable().page(page).draw(false);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.result.message);
                        $('#reptbl').DataTable().page(page).draw(false);
                    });
                }
            });

        };

        $scope.resourceDataExcel = function(){
            if($rootScope.user_role_id == 11){
                $scope.downloadExcel();
            }else{

                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                    // text: "Please enter password to unlock fields.",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                    // inputPlaceholder: "password"
                }, function(inputValue) {
                    if (inputValue === false) return false;
                    if (inputValue === "") {
                        swal.showInputError("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {
                                        $scope.downloadExcel();
                                    } else {
                                        $rootScope.showLoader(false);
                                        swal("Error !", "Wrong Password!", "error");
                                    }
                                } else {
                                    $rootScope.showLoader(false);
                                    swal("Oops !", "Something went wrong!", "error");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                swal("Oops !", "Something went wrong!", "error");
                            });

                    }
                    
                });        
            }
        }

        $scope.downloadExcel = function(){
            $rootScope.showLoader(false);
            $uibModal.open({
                templateUrl: "modules/representative/resource_data_excel_business_unit_filter_popup.html",
                controller: ['$scope','commonFactory', '$uibModalInstance','$http','Notification', function ($scope,commonFactory, $uibModalInstance,$http,Notification) {
                    
            //get business units
            $scope.selectedBusinessUnitIDs = [null];
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({id:null,name:'All'});

            });

                $scope.filterByBusinessUnit = function (){

                $rootScope.showLoader(true);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                selectedBusinessUnitIDs=$scope.selectedBusinessUnitIDs.filter(Boolean);
                commonFactory.post('/api/resource-data-excel', {selectedBusinessUnitIDs: selectedBusinessUnitIDs})
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if(!response.error){
                    // $window.location.href =  apiUrl+"/api/download-resource-data-file";
                    $window.open(apiUrl+"/api/download-resource-data-file",'_blank');
                    }
                    else{
                        swal("Oops !", "Something went wrong!", "error");
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                    swal("Oops !", "Something went wrong!", "error");
                });
                }
                $scope.closePopupWindowSimple = function(){
                    $uibModalInstance.close(true);
                };
            }],
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([{
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },{
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },{
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                    },
                },
                // windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'md',
                close: function () {

                }
            });
        }

        $scope.deleteResource = function(id){
            
            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete this resource",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#e64942",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/reptype/'+id).success(function (res) {
                            $rootScope.showLoader(false);
                            Notification.success(res.message);
                            $scope.reloadData();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.message);
                            $scope.reloadData();
                        });
                    }
                });
        }

    }]);
