angular.module('deitz')
	   .controller('sandboxMessageController',[
	   	'$scope',
		'$rootScope',
        'Notification',
		'commonFactory',
		function(
			$scope,
			$rootScope,
            Notification,
			commonFactory
			){

        $scope.records=[];
        $scope.next=null;
        $scope.previous=null;
            $scope.loadPage = function(type=null){
                page=null;
                if(type=='next' ){
                    page=$scope.next;
                }
                else if(type=='previous'){
                    page=$scope.previous;
                }
            
                $rootScope.showLoader(true);
                commonFactory.post('/api/get-sandbox-twillio-messages',{page:page})
                    .success(function(response) {
                        $scope.records=response.records;
                        $scope.next=response.next;
                        $scope.previous=response.previous;
                        $rootScope.showLoader(false);
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.error);
                    });
            }
            $scope.loadPage();

		}]);

