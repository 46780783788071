angular.module('deitz')
    .controller('editattorneytofirmController', [
        '$scope',
        '$state',
        '$rootScope',
        'attorneyFactory',
        'commonFactory',
        'Notification',
        '$timeout',
        'attorneyId',
        'apiUrl',
        '$http',
        '$uibModalInstance',
        function(
            $scope,
            $state,
            $rootScope,
            attorneyFactory,
            commonFactory,
            Notification,
            $timeout,
            attorneyId,
            apiUrl,
            $http,
            $uibModalInstance
        ) {

        $scope.attorneydata = {};
        $scope.attorneyoffice = []; 
        $scope.title = $state.current.data.pageTitle; 
        $scope.validations = attorneyFactory.validations;
        $scope.messages = attorneyFactory.messages;
        $scope.btn_name = "Update";
        $scope.formSubmit = false;
        $scope.title = 'Edit Attorney';
        $scope.attorneyId = attorneyId;
        $scope.isFirmSelected = true;

        $scope.attorneydata.send_job_confirmation = 'N';
        $scope.attorneydata.scheduled_by = 'N';
        $scope.attorneydata.send_job_confirmation1 = 'N';
        $scope.attorneydata.send_job_confirmation_to = 'single';
        $scope.attorneydata.view_invoice = 'N';
        $scope.attorneydata.see_pending = 'N';
        $scope.attorneydata.allow_notification = 'N';
        $scope.attorneydata.auto_attach = 'N';
        $scope.attorneydata.auto_attach_invoice = 'N';
        $scope.attorneydata.view_statement = 'N';
        $scope.attorneydata.pay_cc = 'N';
        $scope.attorneydata.pay_check = 'N';

        commonFactory.get('/api/get-attorney-type')
            .success(function (response) {
                $scope.attorney_type = response.result.attorneyType || '';
                $rootScope.showLoader(false);
            })
            .error(function (err) {
                $rootScope.showLoader(false);
            });

        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Edit Mode of jobtype.
        if (($state.params && $state.params.id) || attorneyId) {

            var id =  attorneyId || $state.params.id;
            commonFactory.get('/api/geteditattorneydata_att_edit/' + id)
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.attorney_detail) {
                        $scope.attorneydata.NAME = response.result.attorney_detail.NAME || '';
                        $scope.attorneydata.Email_Address = response.result.attorney_detail.Email_Address || '';
                        $scope.attorneydata.attorney_type = response.result.attorney_detail.attorney_type || '';

                        $scope.attorneydata.scheduled_by = response.result.attorney_detail.scheduled_by || 'N';
                        $scope.attorneydata.send_job_confirmation = response.result.attorney_detail.send_job_confirmation || 'N';
                        $scope.attorneydata.send_job_confirmation1 =  response.result.attorney_detail.send_job_confirmation || 'N';
                        $scope.attorneydata.send_job_confirmation_to = response.result.attorney_detail.send_job_confirmation_to || 'single';


                        $scope.attorneydata.view_invoice = response.result.attorney_detail.view_invoice || 'N';
                        $scope.attorneydata.see_pending = response.result.attorney_detail.see_pending || 'N';
                        $scope.attorneydata.allow_notification = response.result.attorney_detail.allow_notification || 'N';
                        $scope.attorneydata.auto_attach = response.result.attorney_detail.auto_attach || 'N';
                        $scope.attorneydata.auto_attach_invoice = response.result.attorney_detail.auto_attach_invoice || 'N';
                        $scope.attorneydata.view_statement = response.result.attorney_detail.view_statement || 'N';
                        $scope.attorneydata.pay_cc = response.result.attorney_detail.pay_cc || 'N';
                        $scope.attorneydata.pay_check = response.result.attorney_detail.pay_check || 'N';                       


                        //$scope.attorneydata.attorney_id = response.result.attorney_detail.attorney_id || '';
                        $timeout(function(){
                            var data = { attorney_id: response.result.attorney_detail.attorney_id ? response.result.attorney_detail.attorney_id : '' }
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function(response) {
                                    $scope.attorneydata.attorney_id = response.result.attorney_data || '';
                                })
                                .error(function(err) {
                                    $rootScope.showLoader(false);
                                });
                        }, 100);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //exist email in other firm
        $scope.emailExists = false;
        $scope.existingEmails = [];

            
        $scope.checkEmailExistence = function() {        
            var data = {};
            data.Email_Address = $scope.attorneydata.Email_Address;
            if ( data.Email_Address) {                
                $rootScope.showLoader(true);   
                commonFactory.post('/api/addattorneytofirm_emailexist', data)
                .success(function(res) {
                    $rootScope.showLoader(false);
                    console.log(res.result);
                    if (res.status == 200 && res.result && res.result.Email_Address) {           
                        $scope.emailExists = true;
                        $scope.existingEmails = res.result.Email_Address;
                    } else {
                        $scope.emailExists = false;
                        $scope.existingEmails = [];
                    }
                })  
                .error(function(err) {
                    $rootScope.showLoader(false);
                    var message = res.result.message;
                    Notification.error(message);
                });
            }
        };

        //Update Mode of Attorney
        $scope.addAttorneyToFirmSubmit = function(isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id =  attorneyId || $state.params.id;
                var data = {};
                if($scope.attorneydata.NAME && $scope.attorneydata.NAME != null){
                    data.name = $scope.attorneydata.NAME;
                }
                data.attorney_id = $scope.attorneydata.attorney_id ? $scope.attorneydata.attorney_id.id : '';
                data.Email_Address = $scope.attorneydata.Email_Address;
                data.attorney_type = $scope.attorneydata.attorney_type;


                commonFactory.post('/api/updateattorney_att_edit/' + id, data)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Attorney updated successfully");
                                $scope.attorneydata = response.result.attorney_update;
                                if (($state.params && $state.params.from && $state.params.from == 'firms')) {
                                    $state.go('firms.manage.ebt', { id: $state.params.firm_id });
                                }else if ($state.params && $state.params.firm_id){
                                    $state.go("attorney.browse",{ firmId: $state.params.firm_id });
                                }
                                else if(angular.equals({}, $uibModalInstance)) {
                                    $state.go("attorney.browse");
                                }
                                else{
                                    $uibModalInstance.close($scope.attorneydata);
                                }
                            }else{
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {
                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();
                        if($rootScope.havePermission('attorney','update')){

                            $scope.addAttorneyToFirmForm.$setSubmitted();
                            $scope.$apply();

                            $scope.addAttorneyToFirmSubmit($scope.addAttorneyToFirmForm.$valid);
                        }

                        break;
                }
            }
        });

        $scope.closePopupWindow = function(){
            swal({
                title: "Are you sure ?",
                text: "Are you sure want to close this window?",
                type: "success",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $uibModalInstance.close($scope.attorneydata);
                }
            });
        };

        $scope.onSelectFirm = function($item, $model, $label){
            $scope.isFirmSelected = true;
        }

        $scope.change_callinby = function(){                 
            if($scope.attorneydata.attorney_type == 'SD'){

                $scope.attorneydata.send_job_confirmation = 'Y';
            }else{
                $scope.attorneydata.send_job_confirmation = $scope.attorneydata.send_job_confirmation1;
            }
        }

        $scope.changeFirm = function(){
            if($scope.isFirmSelected){
                $scope.attorneydata.attorney_id = null;
                $scope.isFirmSelected = false;
            }
        };

        $scope.clearFirm = function(){
            if(!$scope.isFirmSelected){
                $scope.attorneydata.attorney_id = null;
            }
        }
    }]);