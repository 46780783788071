angular.module('deitz')
            .controller('managePreDepositionExhibitsController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$uibModal',
                    'Notification',
                    '$filter',
                    '$timeout',
                    '$q',
                    'SweetAlert',
                    '$state',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $uibModal,
                            Notification,
                            $filter,
                            $timeout,
                            $q,
                            SweetAlert,
                            $state
                        ) {

        /* Notes browse datatable*/
        $scope.dtInstance = {};

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                
                $rootScope.showLoader(true);

                // map your server's response to the DataTables format and pass it to
                commonFactory.post('/api/get-pre-deposition-exhibits', data)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        callback(response);
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        if (err.error)
                            $scope.reloadData();
                    });

            })
            .withOption('processing', true)
            .withLanguage({

                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [10, 25, 50, 100, 200, 250, 300, 500],
                [10, 25, 50, 100, 200, 250, 300, 500]
            ])
            .withOption('order', [0, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtColumns = [
            //DTColumnBuilder.newColumn('JOB_NO').withTitle('JOB NO').withOption('searchable', true),
            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('searchable', true).renderWith(function(data,type,full,meta){
                return '<a target="_blank" ui-sref="diary.editcase.step_one({id: '+ data +'})">'+data+'</a>';
            }),
            DTColumnBuilder.newColumn('dte_taken').withTitle('Job Date').withOption('searchable', true).renderWith(function(data, type, full, meta){
                return moment(data).format("MM-DD-YYYY");
            }),
            DTColumnBuilder.newColumn('job_status_name').withTitle('Job Status').withOption('searchable', false),
            DTColumnBuilder.newColumn('firmname').withTitle('Firm Name').withOption('searchable', true),
            DTColumnBuilder.newColumn('filename').withTitle('File Name').withOption('searchable', true).withOption("width", "20%").withOption('autoWidth', false).renderWith(function(data, type, full, meta){
                if(full.bucket_name){
                return "<a href='javascript:void(0)' ng-click='downloadPreExhibitFile("+full.id+")'>"+data+"</a>";

               }
               else{
                    var filepath = "/upload/raw_exhibits/"+full.JOB_NO.toString().substr(0,4)+"/"+data;
                    return "<a href='"+$rootScope.depoHost+filepath+"' target='_blank' download>"+data+"</a>";
               }
            }),
            DTColumnBuilder.newColumn('note').withTitle('Exhibit Name').withOption('searchable', true),
            DTColumnBuilder.newColumn('uploaded_by').withTitle('Uploaded By').withOption('searchable', true).notSortable(),
            DTColumnBuilder.newColumn('created_at').withTitle('Uploaded Date').withOption('searchable', true).renderWith(function(data, type, full, meta){
                return moment(data).format("MM-DD-YYYY hh:mm:ss a");
            }),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith(function(data, type, full, meta){

                return "<a class='btn btn-primary btn-circle' href='"+$rootScope.depoHost+"/upload_exhibit_by_client.php?JOB_NO="+full.JOB_NO+"' target='_blank' title='Manage'><i class='fa fa-pencil'></i></a>";
            })
        ];

        $scope.downloadPreExhibitFile= function(id){
            $http.get(apiUrl + '/api/get-pre-deposition-exhibit-attechment/'+id
            ).then(function(response) {
                if(!response.data.error){
                    window.open(response.data.result,'_blank');
                }else{
                    Notification.error(response.data.message);
                }
            });
        }


    }
]);
