angular.module('deitz').controller('sourceController',[
	'$scope',
    '$rootScope', 
    'DTOptionsBuilder',
    'DTColumnBuilder', 
    '$compile',
    '$uibModal',
    'Notification',
    '$filter',
    '$http',
    'apiUrl',
	function($scope,
             $rootScope, 
             DTOptionsBuilder,
             DTColumnBuilder, 
             $compile,
             $uibModal,
             Notification,
             $filter,
             $http,
             apiUrl
			 ){    

		 $scope.dtInstance = {};

            $scope.createdRow = function (row, data, dataIndex) {
                // Recompiling so we can bind Angular directive to the DT
                $compile(angular.element(row).contents())($scope);
            };  
            $scope.actionRoles = function (data, type, full) { 
                    return "<a class='btn btn-primary btn-circle' ui-sref='code_tables.source_edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";
              
            };

            $scope.reloadData = function () {
                $scope.dtInstance.rerender();
            };

            $scope.dtOptions = DTOptionsBuilder.newOptions()
                .withDataProp('data') 
                    .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    $http.get(apiUrl + '/api/get_source')
                    .success(function(response) { 
                        callback(response);
                    }).error(function (err) {
                        if (err.error)
                            $scope.reloadData();
                    });

                })
                .withOption('processing', true) 
                .withPaginationType('simple_numbers') 
                .withOption('searchDelay', 500)
                .withOption('order', [0, 'desc'])
                .withOption('createdRow', $scope.createdRow) 
                 
            $scope.dtColumns = [ 
                DTColumnBuilder.newColumn('label').withTitle('Label').withOption('searchable', true),
                DTColumnBuilder.newColumn('name').withTitle('Name').withOption('searchable', true).renderWith(function(data,type,full){
                    
                    if(full.isDefault == "1"){
                        return full.name+" "+full.name_2 + '<span class="m-l-md label label-primary">Default</span>';
                    }
                    else{
                        return full.name+" "+full.name_2 ;
                    }
                }),
                DTColumnBuilder.newColumn('address_1').withTitle('Address1').withOption('searchable', true), 
                DTColumnBuilder.newColumn('address_2').withTitle('Address2').withOption('searchable', true),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles)
            ];
 
             
}]);