angular.module('deitz').controller('languageController', [
    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile',
    function(
        $scope, $rootScope, $state, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $q, $compile
    ){

        $scope.includeAll = true;
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var table = $('#language_list').DataTable();
            table.ajax.reload();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    includeActive: (!$scope.includeAll && $scope.includeActive) ? $scope.includeActive : false,
                    includeInactive: (!$scope.includeAll && $scope.includeInactive) ? $scope.includeInactive : false,
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/language', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50, 100],
                [10, 15, 20, 25, 50, 100]
            ])
            .withOption('order', [2, 'asc'])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            });
            
        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Language'),
            DTColumnBuilder.newColumn('sort_order').withOption('defaultContent', '').withTitle('Sort Order'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    var tooltip = (data.is_active == '1') ? "Active" : "Inactive";
                    var newActive = (data.is_active == '1') ? "0" : "1";
                    var btn = (data.is_active == '1') ? 'btn-success' : 'btn-danger';
                    return '<button class="btn btn-primary btn-circle" ng-if="havePermission(\'language\',\'edit\')" uib-tooltip="Edit" ui-sref="code_tables.language_edit({id : ' + data.id + '})" ><i class="fa fa-pencil"></i></button> &nbsp; <button class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm '+btn+'" ng-click="changeIsActiveStatus(' + data.id + ',\''+ newActive +'\')">' + tooltip + '</button>';
                }
            })
        ];

        $scope.resetForm = function(){
            $scope.mergeLanguage.delete_language = null;
            $scope.mergeLanguage.keep_language = null;
        }

        $scope.refreshLanguages = function(){
            commonFactory.get('/api/all-active-inactive-languages')
            .success(function (response) {
                $rootScope.showLoader(false);
                if (response.result && response.result.language) {
                    $scope.language = response.result.language || [];
                }
            })
            .error(function (err) {
                $rootScope.showLoader(false);
            });
        }

        $scope.refreshLanguages();

        $scope.mergeLanguages = function(isValid){ 
            if(isValid){

                swal({
                        title: "<h3>Merge these language(s) - are you sure? Once this has begun you can not stop it..</h3>",
                        text: "<small style=' color:red';> Note : The merge will take some time. Please wait...</small>",
                        type: "warning",
                        html: true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No, Cancel this merge",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) { 
                            $scope.deletelanguage =  $scope.mergeLanguage.delete_language || null;
                            $scope.keeplanguage = $scope.mergeLanguage.keep_language || null;

                            if($scope.deletelanguage && $scope.keeplanguage){
                                // if keep language not present in merge
                                if ($scope.deletelanguage.indexOf($scope.keeplanguage) < 0) {
                                    $rootScope.showLoader(true);  
                                    commonFactory.post('/api/languages/merge-languages', {deletelanguage:$scope.deletelanguage,keeplanguage:$scope.keeplanguage}).success(function (response) {
                                            
                                        $rootScope.showLoader(false);
                                        $scope.resetForm();
                                        $scope.refreshLanguages();
                                        $scope.reloadData();

                                        if(response.status == 200){
                                            Notification.success(response.result.message); 
                                        }else{
                                            Notification.error(response.result.message);
                                        } 
                                        
                                    }).error(function (error) {
                                        $rootScope.showLoader(false);
                                        Notification.error(error.message);
                                    });       
                                }else{
                                    Notification.error('You have selected keep language as a merge language as well, please select different.');
                                }
                            }
                        }
                    });
            }else{
                Notification.error('Please select keep and merge languages');
            }
        }

        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#language_list').DataTable().page();
            swal({
                title: "Alert!",
                text: "Are you sure want to "+status+" this language?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    var data = {id : id, is_active : newStatus};

                    commonFactory.post('/api/language/update-is-active-status', data).success(function(res){
                        $rootScope.showLoader(false); 
                        if(res.error){
                            Notification.error(res.result.message);    
                        }else{
                            Notification.success(res.result.message);
                        }
                        $('#language_list').DataTable().page(page).draw(false);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.result.message);
                        $('#language_list').DataTable().page(page).draw(false);
                    });
                }
            });

        };
    }]);
