angular.module('deitz')
    .controller('detailInvoiceChargesByClient', [
        '$scope', 
        '$rootScope',
        '$http',
        'apiUrl',
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'invoiceFactory',
        'commonFactory',
        '$compile',
        '$uibModal',
        'Notification',
        '$filter',
        '$timeout',
        '$q',
        '$stateParams',
        '$state',
        '$localStorage',
        '$window',
        function (
            $scope,
            $rootScope,
            $http,
            apiUrl,
            DTOptionsBuilder,
            DTColumnBuilder,
            invoiceFactory,
            commonFactory,
            $compile,
            $uibModal,
            Notification,
            $filter,
            $timeout,
            $q,
            $stateParams,
            $state,
            $localStorage,
            $window
        ) {

            $scope.report = {
                startDate : moment().format('YYYY-MM-DD'),
                endDate : moment().format('YYYY-MM-DD')
            };

            $scope.isFirmSelected = false;
            $scope.submitted = false;
            $scope.selectedBusinessUnitIDs = [];
            var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
            if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
                $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
            }else{
                $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
                });
            }
            $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
                $scope.BusinessUnitsList = response.data.result.data;  
                $scope.BusinessUnitsList.unshift({name:'All'});

            });
            //Getting Attorney Firm
            $scope.attorneyofficedata = function (val) {
                return $http.get(apiUrl + '/api/getattorneyfirm', {
                    params: {
                        name: val
                    }
                }).then(function (response) {
                    return response.data.result.map(function(item) {
                        return item;
                    });
                });
            };

            $scope.onSelect = function ($item, $model, $label, tab) {
                $scope.isFirmSelected = true; 
            }; 

            $scope.clearfield = function (){
                if(!$scope.isFirmSelected){
                    $scope.attorney_id = null;
                }
            };
            $scope.resetfield = function (){                
                $scope.isFirmSelected = false; 
            };

            $scope.downloadExcel = function(valid){
                
                $scope.submitted = true;
                if(valid){                    
                    $rootScope.showLoader(true);                    
                    data = {};                     
                    data.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
                    data.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');
                    data.selectedBusinessUnitIDs =$scope.selectedBusinessUnitIDs.filter(Boolean);;
                    localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));  
                    if($scope.attorney_id){
                        data.attorney_id= $scope.attorney_id ? $scope.attorney_id.id : '';
                    }

                    commonFactory.showDataTable('/api/invoice/get-detail-invoice-charges-by-client', data)
                    .success(function (res) {
                        
                        if(res.error){
                            Notification.error(res.result.message);
                        }else{
                            // $window.location.href = apiUrl +'/api/download-invoice-charges-excel';     
                            $window.open(apiUrl +'/api/download-invoice-charges-excel', '_blank');                       
                        }

                        $rootScope.showLoader(false);
                        
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                    });
                }
            };
        }
    ]);
