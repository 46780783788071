angular.module('deitz').controller('viewPrintCheckController', [

    '$scope', '$rootScope', '$http', 'apiUrl', 'commonFactory', '$uibModal', '$uibModalInstance', 'resource_id', 'resource_type', 'payment_date', 'start_check_number', 'payment_mode', 'Notification', 'configFactory',
    function (
        $scope, $rootScope, $http, apiUrl, commonFactory, $uibModal, $uibModalInstance, resource_id, resource_type, payment_date, start_check_number, payment_mode, Notification, configFactory
    ) {

        // $rootScope.showLoader(true);
        $scope.uibModalInstance = $uibModalInstance;
        $scope.is_popup = true;
        $scope.check_number = start_check_number;
        $scope.unPaidTotalAmount = 0.00;
        $scope.reporterData = {};
        $scope.payment_date= payment_date;
        $scope.resource_type = resource_type;

        // get the last check number and payment date
        $http.get(apiUrl + '/api/config', {
            params: {
                'test': 'test'
            }
        }).then(function(response) {
            $scope.configs = response.data.result.config;
            angular.forEach($scope.configs, function(value, key) {
                if (value.key == "routing_number") {
                    $scope.routing_number = value.value;
                }
                if(value.key == "account_number"){
                    $scope.account_number = value.value;
                }
            });
        });

        $scope.getResourceProfile = function (){
            commonFactory.get('/api/reptype/' + resource_id + '/edit')
                .success(function(response) {
                    if (response && response.result && response.result.reptypeInfo) {
                        $scope.reporterData = response.result.reptypeInfo;
                    } else {
                        console.log("Not get any response")
                    }
                })
                .error(function(err) {
                    console.log(err);
                });
        };

        $scope.closePopupWindow = function(){
            swal({
                    title: "Are you sure ?",
                    text: "Have you printed checks from the list?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes! I have printed successfully, update records now",
                    cancelButtonText: "No, do not update record",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        //$close(JOB_NO);

                        $rootScope.showLoader(true);
                        // update the check records is
                        var data = {};
                        data.jobs = $scope.result;
                        data.resource_type = resource_type;
                        data.payment_date = payment_date;
                        commonFactory.put('/api/check/update-check-number',data).success(function (responseData) {

                            // update last check number
                            var configuration = {};
                            configuration['last_check_number'] = $scope.check_number;
                            var data = { configuration : configuration };

                            configFactory.saveConfiguration(data).success(function (data) {
                                Notification.success(responseData.message);
                                $uibModalInstance.close(true);
                                $rootScope.showLoader(false);
                            }).error(function (error) {
                                Notification.success(responseData.message);
                                $uibModalInstance.close(true);
                                $rootScope.showLoader(false);
                            });



                        }).error(function (error) {

                            Notification.error(error.message);
                            $uibModalInstance.close(true);
                            $rootScope.showLoader(false);

                        });
                    } else {
                        $uibModalInstance.close(false);
                    }
                });
        };

        $scope.printDiv = function (divName) {

            swal({
                title: "Hold on!",
                text: "Don't forget to adjust your print settings!\n" +
                "\n" +
                "Set paper to LEGAL \n" +
                "Set orientation to PORTRAIT \n" +
                "Set size to 100%",
                type: "warning",
                showCancelButton: false,
                confirmButtonClass: "btn-warning",
                confirmButtonText: "Okay, I already did that!",
                closeOnConfirm: true
                },
                function(isConfirm){
                    $scope.is_print_invoice = true;
                    var printContents = $("#" + divName);
                    printContents.find('#nivoice_button').remove();
                    printContents = printContents.html();
                    var popupWin = window.open('', '_blank');
                    popupWin.document.open();
                    popupWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="css/common.css"><link rel="stylesheet" type="text/css" href="font-awesome/css/font-awesome.css"></head><body onload="window.print()">' + printContents + '</body></html>');
                    popupWin.document.close();
                });

        };

    }]);