angular.module('deitz').controller('addcapabilityController', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.capability = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Capability';
        $scope.modalTitle = 'Add plaintiff/defendant'
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        commonFactory.get('/api/jobtypes').then(function(response) { 
            $scope.jobtypes = response.data.result.jobtype;  
        });

        $scope.add = function(capability, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/capability', $scope.capability)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("Capability added successfully");

                                if(fromModal == true){
                                    $uibModalInstance.close(response.result.capability.id);
                                }else{
                                    $state.go("code_tables.capability_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
