var validation_helper = function() {
    return {
        email: {
            patterns: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            required: true,
            maxlength: 100
        },
        password: {
            // patterns: "^([a-zA-Z0-9!@#$%&*.]+)$",
            patterns: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$",
            required: true,
            minlength: 10,
            maxlength: 16
        },
        numeric: {
            patterns: /^[0-9]*\.?[0-9]+$/,
            required: true,
            maxlength: 25
        },
        integer: {
            patterns: /^[0-9]+$/
        },
        alpha: {
            patterns: /^[A-Z]+$/i
        },
        alpha_spaces: {
            patterns: /^[A-Z ]+$/i
        },
        alpha_numeric_spaces: {
            patterns: /^[A-Z0-9 ]+$/i
        },
        alpha_numeric_spaces_no_white: {
            patterns: /^[A-Z0-9]+( [A-Z0-9]+)*$/i
        },
        alpha_spaces_no_white: {
            patterns: /^[A-Z]+( [A-Z]+)*$/i
        },
        landline: {
            patterns: /^[0-9]{8,16}$/
        },
        mobile: {
            patterns: /^[789]\d{9}$/
        },
        single_address_line: {
            patterns: /^[A-Za-z0-9\/,. ]+(?:[\/,.:;-][A-Za-z0-9\/,. ]+)*$/
        },
        url: {
            patterns: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        },
        panNumber: {
            patterns: /[a-z]{4,5}\d{4,5}[a-z]/i
        },
        numwith2decimal: {
            patterns: /^[0-9]+(\.[0-9]{1,2})?$/
        },
        integerwithspe: {
            patterns: /^[0-9\-]+$/
        },
        zipcode: {
            patterns : /^(?:\d{5}(?:-\d{4})?)?$/,
            patternRestrict : '^(\\d{0,5}(-\\d{0,4})?|\\d{0,5})$',
            required: true,
            minlength:5,
            maxlength:10
        },
        toNum: function(str) {

            if (str) {
                return Number(str.match(/\d+/g) || 0);
            }

            return 0;
        }
    };
};
