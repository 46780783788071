angular.module('deitz')
    .controller('addClaimrepnameController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        'commonFactory',
        '$state',
        'Notification',
        '$timeout',
        '$uibModalInstance',
        '$uibModal',
        'fromModal',
        'apiUrl',
        '$http',
            function(
                $scope,
                $rootScope,
                codeFactory,
                commonFactory,
                $state,
                Notification,
                $timeout,
                $uibModalInstance,
                $uibModal,
                fromModal,
                apiUrl,
                $http
            ){

        $scope.claim_rep_name = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Claim Representative Name';
        $scope.claimrep_name = [];
        $scope.claimrepcompany = [];
        $scope.frequency = {
            ef_send_to : 'none',
            email_fax : 'fax'
        };
        
        $scope.closeModal = function(){
            $uibModalInstance.close();
        };

        
        //Getting all claim representative company addresses.
        commonFactory.get('/api/getallClaimrep')
            .success(function(response) {
                if (response.result && response.result.claimrepcompany) {
                    $scope.claimrepcompany = response.result.claimrepcompany || '';
                }
            })
            .error(function(err) {});

        //select claim rep company id and accordingly address information will auto fill.
        $scope.$watch('claim_rep_name.claimrepresentativeaddress_id', function(newValue, oldValue) {
            if (newValue && newValue !== 'undefined') {
                var id = newValue;
                commonFactory.get('/api/claimrepresentative/' + id + '/edit')
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        $timeout(function() {
                            $scope.claim_rep_name.CR_Address1 = response.result.claim_rep.Address1 || "";
                            $scope.claim_rep_name.CR_Address2 = response.result.claim_rep.Address2 || "";
                            $scope.claim_rep_name.CR_City = response.result.claim_rep.City || "";
                            $scope.claim_rep_name.CR_State = response.result.claim_rep.State || "";
                            $scope.claim_rep_name.CR_ZipCode = response.result.claim_rep.ZipCode || "";
                            $scope.claim_rep_name.CR_Phone = response.result.claim_rep.MainPhoneNumber || "";
                            $scope.claim_rep_name.CR_Fax = response.result.claim_rep.MainFaxNumber || "";
                            $scope.claim_rep_name.CR_CompanyName = response.result.claim_rep.CompanyName || "";

                            //assigning company email and fax.
                            $scope.claim_rep_name.company_fax = response.result.claim_rep.MainFaxNumber || "";
                            $scope.claim_rep_name.company_email = response.result.claim_rep.MainEmailAddress || "";
                        }, 500);
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        });

        $scope.claimrepModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/insurance/claim_rep_name/add/claim_rep_modal.html',
                controller: 'ebtController',
                resolve: {
                    ebtdata: function ebtFactory() {
                        return $scope.ebt;
                    },
                    loadPlugin:function($ocLazyLoad){
                        return $ocLazyLoad.load([
                            {
                                name: 'ngPatternRestrict',
                                files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                            }]);
                    }
                }
            });
        }

        //Save info into Claimrepnameandaddress table.
        $scope.addClaimrepname = function(claim_rep_name, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                
                //set frequency to claim rep name object.
                $scope.claim_rep_name.statement_send_to = $scope.frequency.ef_send_to;
                $scope.claim_rep_name.statement_send_via = $scope.frequency.email_fax;

                commonFactory.post('/api/claimrepresentativename', $scope.claim_rep_name)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.result && response.result.claim_rep_name) {
                            // $uibModalInstance.close();
                            Notification.success("Claim Representative added successfully");

                            if(fromModal == true){
                                $scope.claimrepresentative = response.result.claim_rep_name;   
                                $scope.claimrepresentative['ClaimRepID'] = $scope.claimrepresentative.id;
                                $uibModalInstance.close($scope.claimrepresentative);
                            }
                            else{
                                $state.go("insurance.claim_rep_name_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }
    }]);
