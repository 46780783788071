angular.module('deitz')
    .controller('payrollController', ['$scope', '$rootScope', '$http', 'apiUrl', 'payrollFactory', '$state','Notification', function ($scope, $rootScope, $http, apiUrl, payrollFactory, $state,Notification) {

        $scope.title = "Add Payment";
        $scope.editInvoiceData = {};
        //Getting Invoices List
        $scope.invoicelist = function (val) {
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val,
                    without_cancel_job: 'y',
                    legacy_search: 'true',
                    with_resource_details: true
                }
            }).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.redirectToPayment = function () {
            if ($scope.findfirm.JOB_NO.job_payments.length == 1) {
                $state.go('payroll.edit', { id: $scope.findfirm.JOB_NO.job_payments[0].id });
            }
            else if ($scope.findfirm.JOB_NO.job_payments.length == 0) {
                Notification.error("No resource assigned to this job");
            }
            else {
                $state.go('payroll.resourceslist', { job_no: $scope.findfirm.JOB_NO.JOB_NO });
            }
        }
    }]);
