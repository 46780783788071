angular.module('deitz').controller('browseConnectionMethodsController', [
    '$scope','$rootScope','DTOptionsBuilder','DTColumnBuilder','$compile','commonFactory', 'Notification',
    function (
        $scope,$rootScope,DTOptionsBuilder,DTColumnBuilder,$compile, commonFactory, Notification 
    ) {

        $scope.dtInstance = {};
        $scope.loading = [];

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };
        $scope.actionRoles = function (data, type, full) {
            return "<a class='btn btn-primary btn-circle' ui-sref='code_tables.connection_methods_edit({ id: " + full.id + " })'><i class='fa fa-pencil'></i></a>";
        };

        $scope.headerCompiled = false;

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
            // map your server's response to the DataTables format and pass it to

            commonFactory.showDataTable('/api/get-all-connection-methods', data).success(function (res) {
                if (res.error) {
                    $scope.reloadData();
                }
                else {
                    callback(res);
                }
            }).error(function (err) {
                if (err.error !== "token_not_provided") {
                    $scope.reloadData();
                }
            });
        })
        .withOption('processing', true)
        .withLanguage({
            "sProcessing": "<img src='img/loading_bar.gif'/>"
        })
        // .withOption('stateSave', true)
        .withOption('serverSide', true)
        .withOption('searchDelay', 500)
        .withOption('order', [0, 'asc'])
        .withPaginationType('simple_numbers')
        .withDisplayLength(10)
        .withOption('lengthMenu', [
            [5, 10, 25, 50],
            [5, 10, 25, 50]
        ])
        .withOption('createdRow', $scope.createdRow)
        .withOption('headerCallback', function (header) {
            // Use this headerCompiled field to only compile header once
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        });

        $scope.renderIsActiveAction = function (data, type, full, meta) {
            $scope.loading[full.id]= full;
            $scope.loading[full.id].loading = false;
            var is_active_tooltip = (full.is_active == '1') ? "Active" : "Deactive";

            return "<button ng-disabled='!havePermission(\"connection_methods\",\"update\")' ladda=\"loading['"+full.id+"'].loading\" class='ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm' ng-class=\"{'btn-success': '" + full.is_active + "' == '1', 'btn-danger': '" + full.is_active + "' == '0'}\" ng-click='changeStatus(\"" + full.id + "\")'>" + is_active_tooltip + "</button>";
        };

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Connection Name').withOption('searchable', true),
            DTColumnBuilder.newColumn('is_active').withTitle('status').withOption('searchable', false).renderWith($scope.renderIsActiveAction),     
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).notSortable().renderWith($scope.actionRoles)
        ];

        $scope.changeStatus = function(connection_method_id){
            let status = $scope.loading[connection_method_id].is_active == '1' ? 'Deactivate' : 'activate';
            var page = $scope.dtInstance.DataTable.page();

            swal({
                title: "Alert!",
                text: "Are you sure want to "+status+" this connection methods?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $scope.loading[connection_method_id].loading = true;
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/change-connection-method-status/'+connection_method_id).success(function (res) {
                        $rootScope.showLoader(false); 
                        if(res.error){
                            Notification.error(res.result.message);    
                        }else{
                            Notification.success(res.result.message);
                        }
                        $scope.dtInstance.DataTable.page(page).draw(false);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.result.message);
                        $scope.dtInstance.DataTable.page(page).draw(false)
                    });
                }
            });
        }
    }]);