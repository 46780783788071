angular.module('deitz').controller('adjustmentHasNoJobController',[
    '$scope', '$rootScope', '$http', 'apiUrl', 'commonFactory', '$q', 'Notification',
    function(
        $scope, $rootScope, $http, apiUrl, commonFactory, $q, Notification
    ) {

        $scope.title = "Add adjustment without a job number";
        $scope.showResourceAssignJobs = false;
        $scope.adjustmentHasNoJobForm = {};

        $scope.resetForm = function(isReset){

            $scope.formData = {
                adjustment : 0.00,
                resource_type : 'R',
                adjustment_date: moment().format('MM/DD/YYYY')
            };

            if(isReset){
                $scope.adjustmentHasNoJobForm.$setPristine();
            }


            // get the last check number and payment date
            $http.get(apiUrl + '/api/config', {
                params: {
                    'test': 'test'
                }
            }).then(function(response) {
                $scope.configs = response.data.result.config;
                angular.forEach($scope.configs, function(value, key) {
                    if (value.key == "pay_date") {
                        $scope.payment_date = value.value;
                    }

                });

            });
        };
          //get all BussinesUnit 
          $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
        });
        $scope.resetForm();

        $scope.getResourceList = function($keyword){
            return $http.get(apiUrl + '/api/search-resource', {
                params: {
                    keyword:$keyword
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.selectResource = function($item, $model, $label, $event){
            $item.resource_name = $item.first_name + ' '+$item.last_name;
            $scope.formData.resource = $item;
            $scope.showResourceAssignJobs = true;
            $scope.formData.resource_id = $item.id;
            $scope.formData.resource_type = $item.resource_type
            // if($item.businessunit.pay_date){
                $scope.formData.payment_date = $item.businessunit.pay_date
            // }else{
                // $scope.formData.payment_date = $scope.payment_date

            // }
        };

        $scope.unSelectResource = function(){
            if($scope.showResourceAssignJobs){
                $scope.formData.resource = {};
                $scope.showResourceAssignJobs = false;
                $scope.formData.resource_id = null;
            }
        };

        $scope.submitAdjustment= function($valid){

            if($valid && $scope.formData.adjustment != 0){

                swal({
                        title: "Are you sure ?",
                        text: "You are going to add an adjustment.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader(true);
                            $scope.formData.adjustment_date = moment($scope.formData.adjustment_date).format('MM/DD/YYYY')

                            commonFactory.post('/api/adjustmentHasNoJob', $scope.formData)
                                .success(function (response) {

                                    if(!response.error){
                                        $scope.resetForm(true);
                                        Notification.success(response.result.message || 'Adjustment has been added successfully.');
                                    } else{
                                        Notification.error(response.result.message || 'Something went wrong!');
                                    }
                                    $rootScope.showLoader(false);
                                })
                                .error(function (err) {
                                    Notification.error(response.result.message | 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                });
                        }
                    });


            }
        };

        $scope.checkJobNumberExist = function(data){
            var d = $q.defer();
            if(data){
                $http.get(apiUrl + '/api/check-job-exist', {
                    params: {
                        JOB_NO: data
                    }
                }).then(function (response) {
                    if (response.data.result) {
                        d.reject("Sorry, this adjustment number already generated");
                    }
                    else{
                        $scope.formData.JOB_NO = data;
                        d.resolve();
                    }
                }).then(function(){
                    d.reject('Server error!');
                });
            }
            else{
                d.reject('Please enter the adjustment number');
            }
            return d.promise;
        };

    }]);
