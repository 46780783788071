angular.module('deitz').controller('jobtypeController', [
    '$scope',
    '$rootScope',
    '$state',
    'commonFactory',
    'Notification',
    '$stateParams',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$compile',
    function(
        $scope,
        $rootScope,
        $state,
        commonFactory,
        Notification,
        $stateParams,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $compile
    ) {
        $scope.includeAll = true;
        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var table = $('#job_type_list').DataTable();
            table.ajax.reload();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    includeActive: (!$scope.includeAll && $scope.includeActive) ? $scope.includeActive : false,
                    includeInactive: (!$scope.includeAll && $scope.includeInactive) ? $scope.includeInactive : false,
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/jobtype', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            })
            .withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            })
            .withOption('order', [1, 'asc'])
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                { extend: 'copy' },
                { extend: 'csv' },
                { extend: 'excel', title: 'JobtypeDetails' },
                { extend: 'pdf', title: 'JobtypeDetails' }, {
                    extend: 'print',
                    customize: function(win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Job Type').renderWith(function(data,type,full){
                if(full.isDefault == "1"){
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                }
                else{
                    return data;
                }
            }),
            DTColumnBuilder.newColumn('sort_order').withOption('defaultContent', '').withTitle('Sort Order'),
            DTColumnBuilder.newColumn('active').withOption('defaultContent', '').withTitle('Active?').renderWith(function(data, type, full, meta) {
                if (full && full.id) {
                    var newActive = (full.active == 'YES') ? "0" : "1";
                    var btn = (full.active == 'YES') ? 'btn-success' : 'btn-danger';
                    return '<button class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm '+btn+'" ng-click="changeIsActiveStatus(' + full.id + ',\''+ newActive +'\')">' + full.active + '</button>';
                }
            }),
            //DTColumnBuilder.newColumn('slug').withOption('defaultContent', '').withTitle('Slug').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="code_tables.job_type_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;';
                }

            })
        ];


        $scope.resetForm = function(){
            $scope.mergeJobType.delete_job_type = null;
            $scope.mergeJobType.keep_job_type = null;
        }

        $scope.refreshJobTypes = function(){
            commonFactory.get('/api/all-active-inactive-job-types')
            .success(function (response) {
                $rootScope.showLoader(false);
                if (response.result && response.result.job_types) {
                    $scope.jobTypes = response.result.job_types || [];
                }
            })
            .error(function (err) {
                $rootScope.showLoader(false);
            });
        }

        $scope.refreshJobTypes();

        $scope.mergeJobTypes = function(isValid){ 
            if(isValid){

                swal({
                        title: "<h3>Merge these job types(s) - are you sure? Once this has begun you can not stop it..</h3>",
                        text: "<small style=' color:red';> Note : The merge will take some time. Please wait...</small>",
                        type: "warning",
                        html: true,
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No, Cancel this merge",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) { 
                            $scope.delete_job_type =  $scope.mergeJobType.delete_job_type || null;
                            $scope.keep_job_type = $scope.mergeJobType.keep_job_type || null;

                            if($scope.delete_job_type && $scope.keep_job_type){
                                // if keep job type not present in merge
                                if ($scope.delete_job_type.indexOf($scope.keep_job_type) < 0) {
                                    $rootScope.showLoader(true);  
                                    commonFactory.post('/api/jobtype/merge-job-types', {delete_job_type:$scope.delete_job_type,keep_job_type:$scope.keep_job_type}).success(function (response) {
                                            
                                        $rootScope.showLoader(false);
                                        $scope.resetForm();
                                        $scope.refreshJobTypes();
                                        $scope.reloadData();

                                        if(response.status == 200){
                                            Notification.success(response.result.message); 
                                        }else{
                                            Notification.error(response.result.message);
                                        } 
                                        
                                    }).error(function (error) {
                                        $rootScope.showLoader(false);
                                        Notification.error(error.message);
                                    });       
                                }else{
                                    Notification.error('You have selected keep job type as a merge job type as well, please select different.');
                                }
                            }
                        }
                    });
            }else{
                Notification.error('Please select keep and merge job types');
            }
        }

        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#job_type_list').DataTable().page();
            swal({
                title: "Alert!",
                text: "Are you sure want to "+status+" this job type?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    var data = {id : id, is_active : newStatus};

                    commonFactory.post('/api/jobtype/update-is-active-status', data).success(function(res){
                        $rootScope.showLoader(false); 
                        if(res.error){
                            Notification.error(res.result.message);    
                        }else{
                            Notification.success(res.result.message);
                        }
                        $('#job_type_list').DataTable().page(page).draw(false);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.result.message);
                        $('#job_type_list').DataTable().page(page).draw(false);
                    });
                }
            });

        };

    }]);
