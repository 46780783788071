angular.module('deitz').controller('editrate_codetblController', [

    '$scope', '$rootScope', 'codeFactory', 'commonFactory', '$state', 'Notification',
    function(
        $scope, $rootScope, codeFactory, commonFactory, $state, Notification
    ) {

        $scope.rate = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = "Edit Rate";

        //Edit Mode of rate.
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/ratecrud/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.rate_detail) {
                        $scope.rate.name = response.result.rate_detail.name || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of rate
        $scope.addRate = function(rate, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/ratecrud/' + id, $scope.rate)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Rate updated successfully");

                                $state.go("code_tables.rate_codetbl_browse");
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
