angular.module('deitz')
    .controller('adjustmentController', ['$scope', '$rootScope', '$http', 'apiUrl', 'payrollFactory', function($scope, $rootScope, $http, apiUrl, payrollFactory) {

    	$scope.title = "Add an Adjustment";
        $scope.editInvoiceData = {};
        //Getting Invoices List
        $scope.invoicelist = function(val) {
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val,
                    is_payment_done: 'y', 
                    without_cancel_job:'y'
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

    }]);
