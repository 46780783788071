angular.module('deitz')
    .controller('autoInvoicePaymentController', ['$scope', '$rootScope', 'Upload', 'Notification', '$timeout', 'apiUrl', function($scope, $rootScope, Upload, Notification, $timeout, apiUrl) {

        $scope.formSubmit = false;
        $scope.invalidFileType = false;
        $scope.log = "";
        $scope.file_name = "";
        $scope.url = apiUrl;
        $scope.formData = {};

        $scope.resetForm = function(){
            $scope.invoicePaymentForm.$setPristine(true);
            $scope.invoicePaymentForm.$setUntouched(true);
            $scope.formData.selectedFile = "";
            $scope.file_name = "";
            $scope.formData.application_date = moment();
        }

        $scope.submitForm = function(isValid) {
            $scope.formSubmit = true;
            $scope.file_name = "";
            
            if (isValid && $scope.invoicePaymentForm.selectedFile.$valid && $scope.formData.selectedFile) {
                
                var tmpName = $scope.formData.selectedFile.name.split('.');
                var fileType = tmpName[tmpName.length-1];
                if(fileType != 'xls' && fileType != 'xlsx'){
                    $scope.invalidFileType = true;
                    $timeout(function() {
                    	$scope.invalidFileType = false;
                    }, 3000);
                } else{
                    $rootScope.showLoader(true); 

                    $scope.invalidFileType = false;
                    var application_date = moment($scope.formData.application_date).format('MM/DD/YYYY');
                    Upload.upload({
                        url: 'api/autoInvoicePayment',
                        data: {import_file: $scope.formData.selectedFile, APP_DT : application_date}
                    }).then(function (resp) {
                        $rootScope.showLoader(false);
                        $scope.resetForm();
                        if(resp.data.error){ 
                        	Notification.error(resp.data.result.message);
                        }else{
                        	$scope.file_name = resp.data.result.fileName; 
                        	Notification.success(resp.data.result.message);
                        }
                    }, function (resp) { 
                        $scope.resetForm();
                        Notification.error("File data inappropriate..!");
                        $rootScope.showLoader(false); 
                    });
                }

            }
        };
    }]);