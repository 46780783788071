/**
 * Lexitas - Court Reporting
 *
 */


/**
 * pageTitle - Directive for set Page title - mata title
 */
function pageTitle($rootScope, $timeout) {
    return {
        link: function(scope, element) {
            var listener = function(event, toState, toParams, fromState, fromParams) {
                // Default title - load on Home 1
                var title = 'Lexitas - Court Reporting';
                // Create your own title pattern
                if (toState.data && toState.data.pageTitle) title = toState.data.pageTitle;
                if (toState?.$current?.data && toState?.$current?.data?.pageTitle) title = toState.$current.data.pageTitle;
                $timeout(function() {
                    element.text(title);
                });
            };
            $rootScope.$on('$stateChangeStart', listener);
            $rootScope.$on('changeTitle', listener);
        }
    };
}

/**
 * sideNavigation - Directive for run metsiMenu on sidebar navigation
 */
function sideNavigation($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element) {
            // Call the metsiMenu plugin and plug it to sidebar navigation
            $timeout(function(){
                element.metisMenu();
            });
        }
    };
}

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox
 */
function iboxTools($timeout) {
    return {
        restrict: 'A',
        scope: true,
        templateUrl: 'views/common/ibox_tools.html',
        controller: function ($scope, $element) {
            // Function for collapse ibox
            $scope.showhide = function () {
                var ibox = $element.closest('div.ibox');
                var icon = $element.find('i:first');
                var content = ibox.find('div.ibox-content');
                content.slideToggle(200);
                // Toggle icon from up to down
                icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                ibox.toggleClass('').toggleClass('border-bottom');
                $timeout(function () {
                    ibox.resize();
                    ibox.find('[id^=map-]').resize();
                }, 50);
            };
            // Function for close ibox
            $scope.closebox = function () {
                var ibox = $element.closest('div.ibox');
                ibox.remove();
            };
        }
    };
}

/**
 * iboxTools with full screen - Directive for iBox tools elements in right corner of ibox with full screen option
 */
function iboxToolsFullScreen($timeout) {
    return {
        restrict: 'A',
        scope: true,
        templateUrl: 'views/common/ibox_tools_full_screen.html',
        controller: function ($scope, $element) {
            // Function for collapse ibox
            $scope.showhide = function () {
                var ibox = $element.closest('div.ibox');
                var icon = $element.find('i:first');
                var content = ibox.find('div.ibox-content');
                content.slideToggle(200);
                // Toggle icon from up to down
                icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
                ibox.toggleClass('').toggleClass('border-bottom');
                $timeout(function () {
                    ibox.resize();
                    ibox.find('[id^=map-]').resize();
                }, 50);
            };
            // Function for close ibox
            $scope.closebox = function () {
                var ibox = $element.closest('div.ibox');
                ibox.remove();
            };
            // Function for full screen
            $scope.fullscreen = function () {
                var ibox = $element.closest('div.ibox');
                var button = $element.find('i.fa-expand');
                $('body').toggleClass('fullscreen-ibox-mode');
                button.toggleClass('fa-expand').toggleClass('fa-compress');
                ibox.toggleClass('fullscreen');
                setTimeout(function() {
                    $(window).trigger('resize');
                }, 100);
            };
        }
    };
}

/**
 * minimalizaSidebar - Directive for minimalize sidebar
 */
function minimalizaSidebar($timeout) {
    return {
        restrict: 'A',
        template: '<a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="minimalize()" title="collapse menu"><i class="fa fa-bars"></i></a><a class="navbar-minimalize minimalize-styl-2 btn btn-primary " href="" ng-click="pin_minimalize()" title="pin menu"><i class="fa fa-thumb-tack"></i></a>',
        controller: function ($scope, $element) {
            $scope.minimalize = function () {               
               // $("body").toggleClass("mini-navbar");
                localStorage.setItem('pinmenu',false);             
                if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {                  
                    if ($('body').hasClass('mini-navbar') && $('body').hasClass('fixed-sidebar')) { 
                        $('body').removeClass('fixed-sidebar');
                    }else if ($('body').hasClass('mini-navbar')){    
                        $('body').removeClass('mini-navbar');
                        $('body').removeClass('fixed-sidebar');
                    }else if ($('body').hasClass('fixed-sidebar') && !$('body').hasClass('mini-navbar')){  
                        $('body').addClass('mini-navbar');
                        $('body').removeClass('fixed-sidebar');
                    }else{                        
                        $('body').addClass('mini-navbar');
                    }
                    
                    // Hide menu in order to smoothly turn on when maximize menu
                    $('#side-menu').hide();
                    // For smoothly turn on menu
                    setTimeout(
                        function () {
                            $('#side-menu').fadeIn(400);
                        }, 200);
                } else if ($('body').hasClass('fixed-sidebar')){                                    
                    $('#side-menu').hide();
                    setTimeout(
                        function () {
                            $('#side-menu').fadeIn(400);
                        }, 100);
                } else {                                     
                    // Remove all inline style from jquery fadeIn function to reset menu state
                    $('#side-menu').removeAttr('style');
                }
            };
            $scope.pin_minimalize = function () {                
                $('body').removeClass('mini-navbar');                                   
                localStorage.setItem('pinmenu',true);
                $('body').addClass('fixed-sidebar'); 
            }
        }
    };
}

/**
 * icheck - Directive for custom checkbox icheck
 */
function icheck($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, element, $attrs, ngModel) {
            return $timeout(function() {
                var value;
                value = $attrs['value'];

                $scope.$watch($attrs['ngModel'], function(newValue){
                    $(element).iCheck('update');
                })

                return $(element).iCheck({
                    checkboxClass: 'icheckbox_square-blue',
                    radioClass: 'iradio_square-blue'

                }).on('ifChanged', function(event) {
                    value = $attrs['value'];
                    if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
                        $scope.$apply(function() {
                            return ngModel.$setViewValue(event.target.checked);
                        });
                    }
                    if ($(element).attr('type') === 'radio' && $attrs['ngModel']) {
                        return $scope.$apply(function() {
                            return ngModel.$setViewValue(value);
                        });
                    }
                });
            });
        }
    };
}

var icheckCustom = function($timeout, $parse) {
    'use strict';

    return {
        require: 'ngModel',

        link: function($scope, element, $attrs, modelCtrl) {
            var value = $parse($attrs['ngValue'])($scope);
            var ngModel = $attrs['ngModel'];
            var $element = $(element);

            return $timeout(function() {
                $element.iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green'
                }).on('ifChanged', function(event) {
                    if ($element.attr('type') === 'checkbox' && ngModel) {
                        $scope.$apply(function() {
                            modelCtrl.$setViewValue(Boolean(event.target.checked));
                        });
                    }
                    if ($element.attr('type') === 'radio' && ngModel) {
                        $scope.$apply(function() {
                            modelCtrl.$setViewValue(value);
                        });
                    }
                });

                $scope.$watch(ngModel, function() {
                    $element.iCheck('update');
                });
            });
        }
    };
};


/**
 * icheck - Directive for custom checkbox icheck
 */
function icheckPermission($timeout,ivhTreeviewMgr) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, element, $attrs, ngModel) {
            console.log($scope.$parent.role.permissions);
            return $timeout(function() {
                var value;
                value = $attrs['value'];

                $scope.$watch($attrs['ngModel'], function(newValue){
                    $(element).iCheck('update');
                })

                return $(element).iCheck({
                    checkboxClass: 'icheckbox_square-green',
                    radioClass: 'iradio_square-green'

                }).on('ifChanged', function(event) {
                    if ($(element).attr('type') === 'checkbox' && $attrs['ngModel']) {
                        $scope.$apply(function() {
                            return ngModel.$setViewValue(event.target.checked);
                        });
                    }
                    if ($(element).attr('type') === 'radio' && $attrs['ngModel']) {
                        return $scope.$apply(function() {
                            return ngModel.$setViewValue(value);
                        });
                    }
                    ivhTreeviewMgr.select(role.permissions, $scope.node, !$scope.node.selected);
                    $scope.$apply();
                });
            });
        }
    };
}
/**
 * clockPicker - Directive for clock picker plugin
 */
function clockPicker() {
    return {
        restrict: 'A',
        link: function(scope, element) {
            element.clockpicker();
        }
    };
};


function disallowSpaces() {
    return {
        restrict: 'A',

        link: function($scope, $element) {
            $element.bind('input', function() {
                $(this).val($(this).val().replace(/ /g, ''));
            });
        }
    };
};

function inputFormat($filter) {
    return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ctrl) {
            if (!ctrl) return;
            ctrl.$formatters.unshift(function (a) {
                return $filter(attrs.inputFormat)(ctrl.$modelValue)
            });

            elem.bind('blur', function(event) {
                var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, '');
                elem.val($filter(attrs.inputFormat)(plainNumber));
            });
        }
    };
};

function numberToString() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function (value) {
                return '' + value;
            });
            ngModel.$formatters.push(function (value) {
                return value+'';
            });
        }
    }
};

function stringToNumber() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(value) {
                return '' + value;
            });
            ngModel.$formatters.push(function(value) {
                return parseFloat(value, 10);
            });
        }
    };
};

function passwordVerify() {
    return {
        restrict: 'A', // only activate on element attribute
        require: '?ngModel', // get a hold of NgModelController
        link: function(scope, elem, attrs, ngModel) {
            if (!ngModel) return; // do nothing if no ng-model

            // watch own value and re-validate on change
            scope.$watch(attrs.ngModel, function() {
                validate();
            });

            // observe the other value and re-validate on change
            attrs.$observe('passwordVerify', function(val) {
                validate();
            });

            var validate = function() {
                // values
                var val1 = ngModel.$viewValue;
                var val2 = attrs.passwordVerify;

                // set validity
                ngModel.$setValidity('passwordVerify', val1 === val2);
            };
        }
    }
};

function limitTocustom() {
    'use strict';
    return function(input, limit) {
        if (input) {
            if (limit > input.length) {
                return input.slice(0, limit);
            } else {
                return input.slice(0, limit) + '...';
            }
        }
    };
}

function anchorSmoothScroll(){

    this.scrollTo = function(eID) {

        // This scrolling function
        // is from http://www.itnewb.com/tutorial/Creating-the-Smooth-Scroll-Effect-with-JavaScript

        var startY = currentYPosition();
        var stopY = elmYPosition(eID);
        var distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
            scrollTo(0, stopY); return;
        }
        var speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        var step = Math.round(distance / 25);
        var leapY = stopY > startY ? startY + step : startY - step;
        var timer = 0;
        if (stopY > startY) {
            for ( var i=startY; i<stopY; i+=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY += step; if (leapY > stopY) leapY = stopY; timer++;
            } return;
        }
        for ( var i=startY; i>stopY; i-=step ) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }

        function currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (self.pageYOffset) return self.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        }

        function elmYPosition(eID) {
            var elm = document.getElementById(eID);
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent != document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        }

    };

}

function splitCustom(){
    return function(input, splitChar, splitIndex) {
        // do some bounds checking here to ensure it has that index
        if(input){
            return input.split(splitChar)[splitIndex];
        }
    }
}

function capitalize() {
    return function(input) {
        return input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    }
}

function formatToAmount($filter,$timeout){
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {

            ngModel.$parsers.push(function(value) {
                value = value || 0;
                return '' + value;
            });
            ngModel.$formatters.push(function(value) {
                value = value || 0;
                value = value.toString().trim().replace(/\,/g,'');
                return parseFloat(value).toFixed( 2 );
            });

            element.bind('blur', function () {
                var value = ngModel.$viewValue || 0;
                value = value.toString().trim().replace(/\,/g,'');
                var newValue = parseFloat(value).toFixed( 2 );
                if(isNaN(newValue)){
                    newValue = 0.00;
                }
                ngModel.$setViewValue(newValue || 0.00);
                ngModel.$render();
            });
        }
    }

}

function selectOnClick($window) {
    // Linker function
    return function (scope, element, attrs) {
        element.bind('click', function () {
            if (!$window.getSelection().toString()) {
                this.setSelectionRange(0, this.value.length)
            }
        });
    };
};

function dateDiff() {
    return function(date){
        if(date != '' && date != null){
            var date1 = new Date(date);
            var date2 = new Date();
            var diffDays = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
            return diffDays;
        }
        return 0;   
    }
}

function firstLetterOfWords() {
    return function(input) {
        if(input){
            var name_array = input.split(' ');
            var short_name = "";
            name_array.forEach(function(item){
                short_name = short_name + item.charAt(0) + " ";
            });
            return short_name.trim();
        }
        else{
            return '';
        }
    }
}

function num2words($filter){
    return function(input){
        if(input){
            var options = [];
            var defaults = {
                units: [ "", "One", "Two", "Three", "Four", "Five", "Six","Seven", "Eight", "Nine", "Ten" ],
                teens: [ "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen","Sixteen", "Seventeen", "Eighteen", "Nineteen", "Twenty" ],
                tens: [ "", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty","Seventy", "Eighty", "Ninety" ],
                othersIntl: [ "Thousand", "Million", "Billion", "Trillion" ]
            };

            var options = $.extend(defaults, options);

            function NumberToWords() {
                var o = options;

                var units = o.units;
                var teens = o.teens;
                var tens = o.tens;
                var othersIntl = o.othersIntl;

                var getBelowHundred = function(n) {
                    if (n >= 100) {
                        return "greater than or equal to 100";
                    };
                    if (n <= 10) {
                        return units[n];
                    };
                    if (n <= 20) {
                        return teens[n - 10 - 1];
                    };
                    var unit = Math.floor(n % 10);
                    n /= 10;
                    var ten = Math.floor(n % 10);
                    var tenWord = (ten > 0 ? (tens[ten] + " ") : '');
                    var unitWord = (unit > 0 ? units[unit] : '');
                    return tenWord + unitWord;
                };

                var getBelowThousand = function(n) {
                    if (n >= 1000) {
                        return "greater than or equal to 1000";
                    };
                    var word = getBelowHundred(Math.floor(n % 100));

                    n = Math.floor(n / 100);
                    var hun = Math.floor(n % 10);
                    word = (hun > 0 ? (units[hun] + " Hundred ") : '') + word;

                    return word;
                };

                return {
                    numberToWords : function(n) {
                        if (isNaN(n)) {
                            return "Not a number";
                        };

                        var word = '';
                        var val;
                        var word2 = '';
                        var val2;
                        n =  n.toString();
                        var b = n.split(".");
                        n = b[0];
                        d = b[1];
                        d = String (d);
                        d = d.substr(0,2);

                        val = Math.floor(n % 1000);
                        n = Math.floor(n / 1000);

                        val2 = Math.floor(d % 1000);
                        d = Math.floor(d / 1000);

                        word = getBelowThousand(val);
                        word2 = getBelowThousand(val2);

                        othersArr = othersIntl;
                        divisor = 1000;
                        func = getBelowThousand;

                        var i = 0;
                        while (n > 0) {
                            if (i == othersArr.length - 1) {
                                word = this.numberToWords(n) + " " + othersArr[i] + " " + word;
                                break;
                            };
                            val = Math.floor(n % divisor);
                            n = Math.floor(n / divisor);
                            if (val != 0) {
                                word = func(val) + " " + othersArr[i] + " " + word;
                            };
                            i++;
                        };

                        var i = 0;
                        while (d > 0) {
                            if (i == othersArr.length - 1) {
                                word2 = this.numberToWords(d) + " " + othersArr[i] + " " + word2;
                                break;
                            };
                            val2 = Math.floor(d % divisor);
                            d = Math.floor(d / divisor);
                            if (val2 != 0) {
                                word2 = func(val2) + " " + othersArr[i] + " " + word2;
                            };
                            i++;
                        };
                        if (word!='') word = word.toUpperCase() + ' DOLLARS';
                        if (word2!='') word2 = ' AND ' + word2.toUpperCase() + ' CENTS';
                        return word+word2;

                    }
                }
            }

            var num2words = new NumberToWords();
            input = parseFloat(input).toFixed( 2 );
            return num2words.numberToWords(input);
        }
        else{
            return '';
        }
    }
}

function iframeOnload(){
    return {
        scope: {
            callBack: '&iframeOnload'
        },
        link: function(scope, element, attrs){
            element.on('load', function(){
                return scope.callBack();
            })
        }
    }
}


function dateFormatter() {
    return function(date, format) {
        if(!moment) {
            console.log('Error: momentJS is not loaded as a global');
            return '!momentJS';
        }
        moment.locale('de'); //needed if you want to change the locale globally
        if(!format) {
            return moment(date).fromNow();
        } else {
            return moment(date).format(format); //in absence of format parameter, return the relative time from the given date
        }
    }
}
function dateago() {
    return function(date, format) {  
       // console.log(format);
        //console.log(moment(date).format('hh:mm a | MMM D'));
        if(!moment) {
            console.log('Error: momentJS is not loaded as a global');
            return '!momentJS';
        }
        moment.locale('de'); //needed if you want to change the locale globally
        if(!format) {
            return moment(date).fromNow();
        } else {
            return moment(date).format(format); //in absence of format parameter, return the relative time from the given date
        }
    }
}



function checkDateFormat(){
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, elm, attrs, ctrl) {
                elm.bind('blur', function() {
                    if(angular.isString(ctrl.$modelValue)){
                        var dateSplitted = ctrl.$modelValue.split("/");
                        if(dateSplitted.length >2 ){
                            if(dateSplitted[2].length == 2){
                                var year_perfix = new Date().getFullYear().toString().substr(0,2);
                                dateSplitted[2] = year_perfix + dateSplitted[2];
                                ctrl.$viewValue = dateSplitted.join("/");
                                ctrl.$render();
                            }
                        }
                    }

                });
            }
        };
}

function delayRequest(){
    return {
        restrict: 'A',
        scope: {
            delay: "=",
            callback: "&"
        },
        link: function(scope, element, attrs) {
            var newTime;
            $(element).keyup(function(event) {
                clearTimeout(newTime);
                newTime = setTimeout(scope.callback, scope.delay);
            });

        }
    };
}


/**
 *
 * Pass all functions into module
 */
angular
    .module('deitz')
    .directive('pageTitle', pageTitle)
    .directive('sideNavigation', sideNavigation)
    .directive('iboxTools', iboxTools)
    .directive('minimalizaSidebar', minimalizaSidebar)
    .directive('iboxToolsFullScreen', iboxToolsFullScreen)
    .directive('clockPicker', clockPicker)
    .directive('icheck', icheck)
    .directive('icheckCustom', icheckCustom)
    .directive('icheckPermission', icheckPermission)
    .directive('disallowSpaces', disallowSpaces)
    .directive('numberToString', numberToString)
    .directive('stringToNumber', stringToNumber)
    .directive('passwordVerify', passwordVerify)
    .directive('inputFormat', inputFormat)
    .directive('formatToAmount', formatToAmount)
    .directive('selectOnClick', selectOnClick)
    .directive('iframeOnload', iframeOnload)
    .directive('checkDateFormat', checkDateFormat)
    .directive('delayRequest', delayRequest)
    .filter('limitTocustom', limitTocustom)
    .filter('capitalize', capitalize)
    .filter('firstLetterOfWords',firstLetterOfWords)
    .filter('splitCustom', splitCustom)
    .filter('dateFormatter',dateFormatter)
    .filter('dateago',dateago)
    .filter('num2words',num2words)
    .filter('dateDiff',dateDiff)
    .service('anchorSmoothScroll',anchorSmoothScroll);

