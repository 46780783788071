angular.module('deitz')
    .factory('attorneyFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var attorneyFactory = {};

        //Include validation helper
        var vh = validation_helper();

        function convertParams(params) {
            var reqData = {};
            if (params) {
                reqData.skip = params.skip || 0; // This is NOT the page number, but the index of item in the list that you want to use to display the table.
                reqData.limit = params.limit || 10; // Number of entries showed per page.
                reqData.search = params.search ? params.search.value : '';
                reqData.orderColumn = params.order ? params.order[0].column : '';
                reqData.orderDir = params.order ? params.order[0].dir : '';
                if(params.attorney_id){
                    reqData.attorney_id = params.attorney_id ? params.attorney_id : '';
                }
                if (params.id) {
                    reqData.id = params.id ? params.id : '';
                }
            }
            return reqData;
        }

        attorneyFactory.validations = {
            firm_id: {
                required: true,
            },
            firstname: {
                // patterns: vh.alpha_spaces.patterns,
                maxlength: 254,
                required: true,
            },
            middlename: {
                // patterns: vh.alpha_spaces.patterns,
                maxlength: 254,
                required: true,
            },
            lastname: {
                // patterns: vh.alpha_spaces.patterns,
                maxlength: 254,
                required: true,
            },
            Email_Address: {
                patterns: vh.email.patterns,
                maxlength: vh.email.maxlength,
                required: true
            }
        };

        attorneyFactory.messages = {
            firm_id: {
                required: "Firm is required.",
            },
            firstname: {
                // patterns: "Please enter alphabetic key as Firstname.",
                required: "Firstname is required.",
                maxlength: "Firstname not be upto 254 characters."
            },
            middlename: {
                // patterns: "Please enter alphabetic key as middlename.",
                required: "Middlename is required.",
                maxlength: "Middlename not be upto 254 characters."
            },
            lastname: {
                // patterns: "Please enter alphabetic key as lastname.",
                required: "Lastname is required.",
                maxlength: "Lastname not be upto 254 characters."
            },
            Email_Address: {
                patterns: "Enter proper email.",
                maxlength: "Email not be upto 30 characters.",
                required: "Email is required."
            }
        };

        return attorneyFactory;
    }]);
