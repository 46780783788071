angular.module('deitz')
	   .controller('systemNotificationController',[
	   	'$scope',
		'$rootScope',
		'DTOptionsBuilder',
		'DTColumnBuilder',
		'$compile', 
		'commonFactory',
		'$interval',
		function(
			$scope,
			$rootScope,
			DTOptionsBuilder,
			DTColumnBuilder,
			$compile, 
			commonFactory,
			$interval
			){

		$scope.dtInstance = {};
		$scope.sync = true;
		$scope.btnText = 'Start Watching';  
        
        $scope.notificationTypes = [
            {'type':'attorneyFirm','name':'Attorney Firm'},
            {'type':'claimRep','name':'Claim Representative'},
            {'type':'insuranceComp','name':'Insurance Company'},
            {'type':'reporter','name':'Resource'},
            {'type':'typist','name':'Typist'}
        ];
        
        $scope.lookingFor = 'attorneyFirm';

        $scope.toggleSync = function(sync){  
            if(sync){ 
                $scope.btnText = 'Stop Watching'; 
                $scope.stopeWatching = $interval(function(){
                    $scope.dtInstance.rerender();
                }, 10000);
                $scope.sync = false;
            
            }else{

                $scope.btnText = 'Start Watching'; 
                $scope.sync = true;
                $interval.cancel($scope.stopeWatching);
            }
        }
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };
        
        $scope.reloadData = function() { 
            $scope.dtInstance.rerender();
        }; 
        $scope.resetSearch = function(){ 
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data') 
            .withOption('ajax', function (data, callback, settings) {  

                commonFactory.showDataTable('/api/get-system-notifications',{data:data,type:$scope.lookingFor})
                .success(function(res) {
                    if (res) {  
                        callback(res);
                    }
                })
                .error(function(err) { 
                });
            })     
            .withOption('stateSave',true) 
            .withOption('processing', true)
            .withOption('serverSide', true) 
            .withOption('createdRow', $scope.compileDatatable)  
            .withLanguage({
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "", 
            }) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500) 
            .withOption('order', [0, 'desc'])  
            .withLightColumnFilter({
                '1': {
                    type: 'text'
                }
            }) 
        $scope.dtColumns = [
            DTColumnBuilder.newColumn('created_at').withOption('defaultContent', '-').withTitle('Sent Date').withOption('searchable',false).renderWith(function(data, type, full, meta){
                if(data){ 
                    
                    return created_at = moment(data).format('MM/DD/YYYY hh:mm a');
                
                }else{
                    return '-';
                }
                
            }).withOption('width','15%'), 
            DTColumnBuilder.newColumn(null).withOption('defaultContent', '-').withTitle('Name').withOption('searchable',true).renderWith(function(data, type, full, meta){
                var name = '';
                if(full.source == 'attorney'){
                    name = full.NAME;
                }else if(full.source == 'claimrep'){
                    name = full.ClaimRepFirstName +' '+full.ClaimRepLastName;
                }else if(full.source == 'invoice'){
                    name = full.CompanyName;
                }else if(full.source == 'reporter' || full.source == 'typist'){
                    name = full.FIRST_NAME +' '+full.LAST_NAME;
                }
                return name;
            }).withOption('width','25%'), 
             DTColumnBuilder.newColumn(null).withOption('defaultContent', '-').withTitle('Address').withOption('searchable',false).renderWith(function(data, type, full, meta){
                var address = '';
                if(full.source == 'attorney'){
                    address = full.ADDR_LINE1 +' '+full.ADDR_LINE2;
                }else if(full.source == 'claimrep'){
                    address = full.CR_Address1 +' '+full.CR_Address2;
                }else if(full.source == 'invoice'){
                    address = full.Address1 +' '+full.Address2;
                }else if(full.source == 'reporter' || full.source == 'typist'){
                    address = full.ADDR_LINE1 +' '+full.ADDR_LINE2;
                }
                return address;
            }).withOption('width','25%').notSortable(),  
            DTColumnBuilder.newColumn('action').withOption('defaultContent', '-').withTitle('Sent Via').withOption('searchable',false).withOption('width','10%'), 
            DTColumnBuilder.newColumn('action_value').withOption('defaultContent', '-').withTitle('Sent On').withOption('searchable',false).withOption('width','25%').notSortable() 
            
        ];  

		}]);
 